import { createElement, createUniqueId } from "../elements";
import ArrowDownFilledIcon from '../images/icons/arrow_down_filled.svg';
import ArrowDownIcon from '../images/icons/arrow_down.svg';
import AccountsIcon from '../images/icons/accounts.svg';
import DeviceIcon from '../images/icons/device.svg';
import MoreIcon from '../images/icons/more.svg'
import owner from '../../owner'
import assert from '../debug';
import View from "./view";
import './siteaccessview.css';
import Dropdown from "../components/dropdown";
import Dialog, { WritesEnabler } from '../dialog';
import ViewModal from "../viewmodal";
import { createGroup, getCompanyUsers, getGroups, updateUserInfo } from "../accountmanager";
import User from "../user";

/**
 * Site Access View
 * Created April 2021
 * by Patrick Hubbard
 *
 * The site access view allows administrators to modify group settings
 * and user permission settings. The view essentially serves two separate
 * purposes depending on whether or not it is passed a user on instantiation.
 *
 * If we are provided a user, we create a nested list of all the groups with
 * radio buttons corresponding to the user's respective settings for that group.
 *
 * If we are not provided a user, we create a nested list of all groups and all
 * of the users that have permission settings for those groups.
 *
 */
export default class SiteAccessView extends View {
    constructor(ldc, user, companyKey, deviceKey, callbackID) {
        super();
        this.ldc            = ldc;
        this.id	            = this.ldc.registerGraph(this);
        this.user           = user;
        this.deviceKey      = deviceKey;
        this.newUsers       = [];
        this.groups         = [];
        this.newGroups      = [];
        this.companyKey     = companyKey ?? owner.menuPanel.getCompanyKey();
        this.callbackID     = callbackID;
        this.pendingChanges = 0;
        this.groupMap       = new Map();
    };

    initialize(parent) {
        super.initialize(parent);
        this.wrapper    = createElement('div', 'site-access__wrapper', this.parent);
        this.header     = createElement('div', 'site-access__header', this.wrapper);
        let container   = createElement('div', 'site-access__container', this.wrapper);
        this.groupList  = createElement('div', 'site-access__item-list', container, null);
        if (!this.deviceKey) {
            let buttons     = createElement('div', 'site-access__buttons', this.wrapper);
            this.submitBut  = createElement('button', 'se-button site-access__buttons__submit', buttons, 'Submit');
            if (this.modal) {
                this.cancelBut  = createElement('button', 'se-button site-access__buttons__cancel', buttons, 'Cancel');
                this.cancelBut.onclick = () => this.modal.destroy();
            }

            // Set up our event listeners for our buttons
            this.submitBut.onclick = () => {
                new WritesEnabler(()=>{
                    this.pendingChanges = 0;
                    if (this.user) {
                        this.user.permissions = [];                 // start off with an empty array
                        for (const name of this.groupMap.keys()) {  // for each group that we have created
                            let group = this.groupMap.get(name);    // get the child group list
                            let index = 0;
                            if (group.previousSibling) {            // previous sibling should be the group's card
                                for (let i=0;i<group.previousSibling._buttons.length;i++) {
                                    if (group.previousSibling._buttons[i].checked)
                                        index = i
                                }
                                if (index > 0) {
                                    this.user.permissions.push({group:name == '' ? '*' : name, fWrites: index == 2});
                                }
                            }
                        }
                        this.pendingChanges++;
                        //             username,           first,     last,      email,     phone,     country,   permissions
                        updateUserInfo(this.user.userName, undefined, undefined, undefined, undefined, undefined, this.user.permissions).then(success => this.onAccountsManaged(success));
                    }
                    else { // If we don't have a user, the admin has the ability to add and remove groups and users
                        let groupsToAdd         = [];
                        let groupsToDelete      = [];
                        let usersToUpdate       = [];

                        groupsToAdd.push(...this.newGroups.filter((group) => { // add any groups that aren't in the original set
                            return !this.groups.some((exGroup) => exGroup.name == group.name)
                        }))
                        groupsToDelete.push(...this.groups.filter((exGroup) => { // delete any groups that are in the original set but not in our updated set
                            return !this.newGroups.some((group) => exGroup.name == group.name)
                        }))
                        for (let i=0;i < this.newUsers.length; i++) { // for each user in our updated list
                            if (this.newUsers[i].permissions.length != this.accounts[i].permissions.length) // quickest way to tell if something changed
                                usersToUpdate.push(this.newUsers[i]);
                            else if (this.newUsers[i].permissions.filter((permission) => { // check through all the user permissions to see if any changed
                                return this.accounts[i].permissions.some((exPermission) => exPermission.group != permission.group || exPermission.fWrites != permission.fWrites)
                            }).length > 0)
                                usersToUpdate.push(this.newUsers[i]);
                        }
                        this.pendingChanges += groupsToAdd.length > 0 ? 1 : 0;
                        this.pendingChanges += groupsToDelete.length > 0 ? 1 : 0;
                        this.pendingChanges += usersToUpdate.length;
                        for (let i=0; i < groupsToAdd.length; i++) {
                            let group = groupsToAdd[i];
                            createGroup(group.name, owner.menuPanel.getCompanyKey(), group.parentName).then(wasSuccessful => this.onAccountsManaged(wasSuccessful));
                        }
                        for (let i=0; i < groupsToDelete.length; i++) {
                            let group = groupsToDelete[i];
                            this.ldc.deleteGroup(this.id, group.name, this.companyKey);
                        }

                        // I don't super like this fix...so that we update ourselves last so that we don't get disconnected in the
                        // middle of modifying a bunch of permissions as a result of modifying our own permissions. This should also all be consolidated into a single command so that
                        // we don't rely on the client staying connected/sending messages to avoid permissions getting into a bad state on the backend.
                        // i.e. If a user deletes a group and then gets disconnected by closing the browser super fast or something, they will leave a bunch of dangling
                        // permissions referencing that group. Updating ourselves last does not fix this problem. Unfortunately user_permissions does not reference the
                        // groups table in any way other than a non-unique group name. We could write some migration code to add grab the group_ids and add them to the user_permissions
                        // table or do some expensive querying on the user_permissions and groups table when you delete group.
                        // Adding longer term reminder for myself to take a look at reorganizing groups/permissions/device groups. - Ben
                        let selfModification = usersToUpdate.splice(usersToUpdate.map(user => user.userName).indexOf(User.username), 1);
                        assert(selfModification.length < 2, "Attempting to update my own permissions more than once"); // Should be 0 or 1 in length

                        // Update everyone except for ourselves...
                        for (let i=0; i < usersToUpdate.length; i++) {
                            let user = usersToUpdate[i];
                            //             username,      first,     last,      email,     phone,     country,   permissions
                            updateUserInfo(user.userName, undefined, undefined, undefined, undefined, undefined, this.user.permissions).then(success => this.onAccountsManaged(success));
                        }

                        // Now we can modify ourselves and get disconnected because we don't have any plan to make any further modifications
                        if(selfModification[0] != undefined)
                            //             username,                     first,     last,      email,     phone,     country,   permissions
                            updateUserInfo(selfModification[0].userName, undefined, undefined, undefined, undefined, undefined, selfModification[0].permissions).then(success => this.onAccountsManaged(success));
                    }
                });
            }
        }
        getGroups(this.companyKey).then(groups => this.onGetGroupsResponse(groups));

        this.fInitialized = true;
        return this;
    }

    onGetGroupsResponse(groups) {
        this.groups     = [...groups];
        this.newGroups  = [...groups];
        if (!this.user)
            getCompanyUsers(this.companyKey).then(users => this.onUsersReceived(users))
        else
            this.buildList();
    }

    onUsersReceived(users) {
        assert(Array.isArray(users), "onUsersReceived has a bad callback");
        this.accounts = []; // just to keep track of all of our accounts
        this.newUsers = [];
		for (let i = 0; i < users.length; ++i)	{   // For each user we got back
            this.accounts.push(users[i]);           // Add them to our accounts
            this.newUsers.push(JSON.parse(JSON.stringify(users[i])));
        };
        this.buildList();
    }

    onViewShown() {
        this.companyKey = owner.menuPanel.getCompanyKey()
        getGroups(this.companyKey).then(groups => this.onGetGroupsResponse(groups));
    }

    buildList() {
        this.groupList.removeChildren();
        this.groupMap.clear();
        //this.createGroup(this., true);
        for (let i=0; i<this.newGroups.length;i++) {
            let group = this.newGroups[i];
            this.createGroup(group)
        };
        if (!this.deviceKey && !this.user)
            for (let i=0;i<this.newUsers.length;i++) {
                for (let j=0;j<this.newUsers[i].permissions.length;j++) {
                    let permission  = this.newUsers[i].permissions[j];
                    let group       = permission.group == '*'? '' : permission.group;
                    if (this.groupMap.has(group))
                        this.createUser(this.newUsers[i], this.groupMap.get(group), permission)
                }
            }
        this.cleanList();

    }

    cleanList() {
        // Dont show drop down arrows for groups that have no children
        for (let value of this.groupMap.values()){
            if (!value.firstChild) {
                value.previousSibling.arrow.style.opacity = '0';
                value.previousSibling.arrow.onclick = (e) => e.preventDefault();
            }
            else {
                value.previousSibling.arrow.style.opacity = '1';
                value.previousSibling.arrow.onclick = (e) => this.dropDown(e, value.previousSibling);
            }
        }
        // make sure we start in a valid configuration
        if (this.user) {
            let index = 0;
            let groupCard = this.groupMap.get('').previousSibling
            for (let j=0;j<groupCard._buttons.length;j++) {
                if (groupCard._buttons[j].checked) {
                    index = j;
                    break;
                };
            };
            this.updateRadios(groupCard, index);
        }
        else if (this.deviceKey) {
            this.groupMap.get('').previousSibling.name.style.color = 'var(--color-primary)';
            for (let i=0;i<this.newGroups.length;i++) {
                let groupCard = this.groupMap.get(this.newGroups[i].name).previousSibling;
                groupCard.name.style.color = groupCard.checkbox.checked || this.anyChildChecked(groupCard) ? 'var(--color-primary)' : 'var(--color-onSurface)'
            }
        }
    }

    anyChildChecked(groupCard) {
        return Array.from(groupCard.nextSibling.children).some(card => {
            if (card.checkbox && !card.checkbox.checked) {
                return this.anyChildChecked(card)
            }
            else if (card.checkbox) return true;
        });
    }

    onCompanySelectorChanged(companyKey) {
        this.companyKey = companyKey;
        getGroups(this.companyKey).then(groups => this.onGetGroupsResponse(groups));
    }

    createGroup(newGroup) {
        let groupCard;
        let group = newGroup;
        if (this.groupMap.has(group.name)) // we already created this group
            return;
        else if (group.name === "") {
            groupCard = createElement('div', 'site-access__group-row', this.groupList);
        }
        else
        {
            let parentGroup;
            for (let i=0;i<this.groups.length;i++) {
                if (group.parentName == this.groups[i].name) {
                    parentGroup = this.groups[i];
                    break;
                }
            }
            if (parentGroup)
                this.createGroup(parentGroup);
            groupCard = createElement('div', 'site-access__group-row', this.groupMap.get(group.parentName), null);
        }
        groupCard.group = group;
        if (this.user) { // if we have a user, we want to let them modify settings at the group level
            this.createRadioButtons(groupCard, ['No','RO','RW']);
            groupCard._buttons[0].checked = true;               // show that they don't have access to any groups

            if (this.user.permissions.length > 0)                   // this user is not a member of any groups
                for (let i=0;i<this.user.permissions.length;i++) {  // for each permission they do have
                    let permission = this.user.permissions[i];      // convenience reference
                    let name = permission.group == '*'? '' : permission.group // make sure * = All for our search
                    if (name == group.name) {                       // if the user has a permission setting for this group
                        groupCard._buttons[1].checked = true;       // by default give them read only settings
                        if (permission.fWrites)                     // if they have read/write settings
                            groupCard._buttons[2].checked = true;   // check the box
                        break;                                      // if we found the right permission, break the loop
                    };
                };
        }
        else if (this.deviceKey) {
            let id 				= createUniqueId();
			let checkwrapper 	= createElement('div', 'se-checkbox', groupCard)
			let checkbox		= createElement('input', null, checkwrapper, null, { 'type': 'checkbox', 'id': id });
			createElement('label', null, checkwrapper, undefined, { 'htmlFor': id });
			checkbox.checked 	= groupCard.group.name == '' ? true : groupCard.group.devices.some((key)=>{return key == this.deviceKey});
            checkbox.disabled   = groupCard.group.name == '';
			checkbox.onchange   = () => {
                new WritesEnabler(()=>{
                    if (checkbox.checked) {
                        groupCard.group.devices.push(this.deviceKey)
                        this.ldc.modifyGroupDevices(this.callbackID, groupCard.group.name, this.companyKey, groupCard.group.devices);
                    }
                    else {
                        groupCard.group.devices = groupCard.group.devices.filter((key)=>{return key != this.deviceKey});
                        this.ldc.modifyGroupDevices(this.callbackID, groupCard.group.name, this.companyKey, groupCard.group.devices);
                    }
                    this.cleanList();
                });
			}
            groupCard.checkbox = checkbox;
        }
        else {
            let options = createElement('img', 'site-access__option', groupCard, undefined, {'src':MoreIcon});
            options.onclick = (e) => {
                e.stopPropagation();
                let options = ['Add Group to ' + group.name, 'Add/Remove User'];    // dropdown options for groups
                if (group.name != '')                                            // we can't delete the 'All' group
                    options.push('Remove Group')
                this.dropdown = new Dropdown(e, options, this.optionsCallback.bind(this, groupCard))
            }
        }

        let nameWrapper = createElement('div', 'site-access__group-row__name-wrapper', groupCard);
        groupCard.group = group
        groupCard.level = (groupCard.parentElement.previousSibling && groupCard.parentElement.previousSibling.level >= 0) ? groupCard.parentElement.previousSibling.level + 1 : 0;
        groupCard.arrow = createElement('img', 'site-access__group-row__icon', nameWrapper, null, { 'src': ArrowDownFilledIcon });
        groupCard.arrow.style.width         = '36px'
        groupCard.arrow.style.height        = '24px'
        groupCard.arrow.style.transform     = 'rotate(0deg)';
        groupCard.icon                      = createElement('img', 'site-access__group-row__icon', nameWrapper, null, { 'src':AccountsIcon});
        groupCard.name                      = createElement('div', 'site-access__group-row__title', nameWrapper, group.name === "" ? "All" : group.name);
        groupCard.arrow.onclick             = (e) => this.dropDown(e, groupCard);
        groupCard.style.paddingLeft         = (groupCard.level * 18) + 5 + 'px'; // indent each level a bit

        let itemList = createElement('div', 'site-access__item-list');       // create an element to hold our folder's guts
        itemList.setAttribute('is-collapsed', 'false');                     // make sure we start out collapsed
        groupCard.parentNode.insertBefore(itemList, groupCard.nextSibling); // insert our new list in between the folder and its next sibling
        this.groupMap.set(group.name, itemList);
    }

    optionsCallback(card, selection) {
        switch(selection) {
            case 'Add Group to ' + card.group.name:
                let properties = {
                    title:  'New Group',
                    body:   'Please enter a name for your new group.',
                    fText:  true,
                    buttons: [
                        {'title':'Submit',callback:(name)=>this.addGroup(card.group.name == 'All'? '' : card.group.name, name)},
                        {'title':'Cancel'}
                    ]
                }
                new Dialog(document.body, properties);
                break;
            case 'Add/Remove User': {
                new ViewModal(new UsersView(this.newUsers, card.group.name, this), {
                    maxWidth:               '400px',
                    maxHeight:              '600px',
                    title:                  `User List for "${(card.group.name == '') ? 'All' : card.group.name}"`,
                    titleTextColor:			'var(--color-inverseOnSurface)',
                    titleBackgroundColor: 	'var(--color-primary)',
                });
                break;
            }
            case 'Remove Group': {
                this.newGroups = this.newGroups.filter((group) => {
                    return (group.name != card.group.name && !this.isChildGroupOf(card.group, group))
                });
                console.log(this.newGroups)
                this.newUsers.forEach((user) => { // For each user
                    user.permissions = user.permissions.filter((permission) => { // filter user's permissions
                        return this.newGroups.some((group) => {
                            return group.name == permission.group || group.name == '' && permission.group == '*';
                        }); // based on whether or not their permission's group still exists
                    });
                });
                console.log(this.newUsers)
                this.buildList();
            }
        }
    }

    isChildGroupOf(group, childGroup) {
        if (group.name == childGroup.name || childGroup.parent == undefined) // We're checking against ourselves or the childGroup is the All group
            return false
        else if (childGroup.parent.name == group.name) // This group is the childGroups parent
            return true;
        else { // Keep going up the tree until we find a match or hit a dead end
            return this.isChildGroupOf(group, this.groupMap.get(childGroup.parent.name).previousSibling.group);
        }
    }

    onAccountsManaged(fResult) {
        let properties;
        if (fResult) {
            if (--this.pendingChanges == 0) {
                getGroups(this.companyKey).then(groups => this.onGetGroupsResponse(groups));
                properties = {
                    title: 'Success',
                    body: 'Your organization\'s groups have been successfully modified.',
                }
                new Dialog(document.body, properties);
            }
        }
        else {
            properties = {
                title: 'Error',
                body: 'Something went wrong while attempting to modify group settings. Please try again.',
            }
            new Dialog(document.body, properties);
        }
    }

    addGroup(parent, name) {
        if (!Array.from(this.groupMap.keys()).includes(name)) {
            this.newGroups.push({
                name:       name,
                devices:    [],
                parentName: parent,
            });
            this.buildList();
            //this.ldc.createGroup(name, this.companyKey, parent);
        }
        else {
            let properties = {
                title: 'Error',
                body: 'A group with this name already exists. Please enter a unique group name.',
                fText: true,
                buttons: [
                    {'title':'Submit',callback:(name)=>this.addGroup(parent, name)},
                    {'title':'Cancel'}
                ]
            }
            new Dialog(document.body, properties);
        }

    }

    dropDown(e, groupCard) {
        e.stopPropagation();
        if (this.fSliding) return;
        if (groupCard.opened) {
            let finishCallback = () => this.fSliding = false;  // callback to remove dom elements once they're no longer visible
            groupCard.nextSibling.addEventListener('transitionend', finishCallback, { once: true });      // add the listener for our transition callback
            this.toggleFilterList(groupCard.arrow, groupCard.nextSibling)                                   // reuse filter list toggle for animation
            groupCard.opened = false;                                                                     // mark this folder as unopened
            this.fSliding = true;
        }
        else {
            this.toggleFilterList(groupCard.arrow, groupCard.nextSibling);          // reuse filter list toggle method for animation=
            groupCard.opened = true;                                              // remember that we are open now
        }
    }

    updateRadios(card, selection) {
        if (card.nextSibling && card.nextSibling.classList.contains('site-access__item-list')) {
            let childGroups = Array.from(card.nextSibling.children).filter((element)=>{return element.classList.contains('site-access__group-row')});
            for (let i=0;i<childGroups.length;i++) {
                let index = 0;
                for (let j=0;j<childGroups[i]._buttons.length;j++) {
                    if (childGroups[i]._buttons[j].checked) {
                        index = j;
                        break;
                    };
                };

                if (index < selection) {        // we have a child that has more restrictive permissions than its parent
                    card.name.style.color = SiteAccessView.permissionDetails[card._buttons[selection].label].color;
                    this.updateRadios(childGroups[i], selection) // recursively move down the list
                }
                else {
                    card.name.style.color = SiteAccessView.permissionDetails[card._buttons[index].label].color;
                    this.updateRadios(childGroups[i], index) // recursively move down the list
                }
            }
        }
        // update the color of the name so users can easily see what users have what access
        card.name.style.color = SiteAccessView.permissionDetails[card._buttons[selection].label].color;

    }

    createUser(user, groupElement, permission) {
        let userCard = createElement('div', 'site-access__group-row');
        groupElement.prepend(userCard)
        let labels = ['RO','RW'];
        this.createRadioButtons(userCard, labels, (selection)=>{
            switch(selection) {
                case 'RO': {
                    permission.fWrites = false;
                    break;
                }
                case 'RW': {
                    permission.fWrites = true;
                    break;
                }
            }
        });
        let nameWrapper = createElement('div', 'site-access__group-row__name-wrapper', userCard);
        userCard.level = (userCard.parentNode.previousSibling && userCard.parentNode.previousSibling.level >= 0) ? userCard.parentNode.previousSibling.level + 1 : 0;
        userCard.fUser = true;
        userCard.name  = createElement('div', 'site-access__group-row__title', nameWrapper, user.firstName + ' ' + user.lastName);
        userCard.style.paddingLeft   = (userCard.level * 18) + 56 + 'px';

        if (permission.fWrites) {
            userCard._buttons[1].checked = true;
            userCard.name.style.color = SiteAccessView.permissionDetails[labels[1]].color
        }
        else {
            userCard._buttons[0].checked = true;
            userCard.name.style.color = SiteAccessView.permissionDetails[labels[0]].color

        }
    }

    createRadioButtons(card, labels, callback) {

        if (!this.legend) {
            this.legend      = createElement('div', 'site-access__legend', this.header);
            for (let i=0;i<labels.length;i++) {
                let item = createElement('div', 'site-access__legen__item', this.legend, SiteAccessView.permissionDetails[labels[i]].text);
                item.style.color = SiteAccessView.permissionDetails[labels[i]].color;
            }
        }
		// Store array of buttons in 'groupCard':
		card._buttons = [];
		card._labels = [];
		// Set up a button for each label:
        let radioSet = createUniqueId();
		for (let i = 0; i < labels.length; i++) {
			let radio = createElement('input', 'radio-buttons__input', card);
			let radioID = createUniqueId();
			radio.setAttribute('type',	'radio');
			// Each input element in the set of radiobuttons must have the same 'name':
			radio.setAttribute('name',	radioSet);
			// Each input/label must have the same unique 'id'/'for' attribute:
			radio.setAttribute('id', radioID);
			radio.index             = i;

			let label               = createElement('label', 'radioButtonLabel', card, labels[i]);	// trim leading and trailing whitespace
            radio.label             = labels[i];
            label.style.fontSize    = '12px';
            label.style.color       = SiteAccessView.permissionDetails[labels[i]].color

			// Each input/label must have the same unique 'id'/'for' attribute:
			label.setAttribute('for', radioID);

            card._buttons.push(radio);		// Store button in top element array
            card._labels.push(label);		// Store the label so its width can be queried, if necessary
            radio.onclick = () => {
                if (radio.checked) {
                    card.selectedIndex = radio.index;
                    card.name.style.color = SiteAccessView.permissionDetails[labels[i]].color;
                    if (callback)
                        callback(labels[i])
                }
                this.cleanList()
            }
		}
    }

    toggleFilterList(dropIcon, element) { // toggles whether or not the element is collapsed. See collapse and expand methods in elements.js
        if (!element) return
        var isCollapsed = element.getAttribute('is-collapsed') === 'true';

        if (isCollapsed) {
            element.expand();
            if (dropIcon) {
                dropIcon.style.transform = 'rotate(0deg)';
            }
        } else {
            element.collapse();
            if (dropIcon) dropIcon.style.transform = 'rotate(-90deg)';
        }
    }

    static permissionDetails = {
        'No':   {color:'var(--color-onSurfaceVariant)', text:'No - No Access'},
        'RO':   {color:'var(--color-blue-8)', text:'RO - Read Only'},
        'RW':   {color:'var(--color-green-8)',text:'RW - Read/Write'}
    }

}

class UsersView extends View {
    constructor(users, group, view) {
        super();
        this.users  = users;
        this.group  = group;
        this.view   = view;
    }

    initialize(parent) {
        super.initialize(parent);
        this.wrapper = createElement('div', 'user-view__wrapper', this.parent)
        let container       = createElement('div', 'user-view__container', this.wrapper);
        for (let i=0;i<this.users.length;i++) {
            let row             = createElement('div', 'user-view__row', container)
            let id 				= createUniqueId();
            let checkwrapper 	= createElement('div', 'se-checkbox', container)
            let checkbox		= createElement('input', null, checkwrapper, null, { 'type': 'checkbox', 'id': id });
            createElement('label', null, checkwrapper, this.users[i].firstName + ' ' + this.users[i].lastName, { 'htmlFor': id });
            console.log(this.users[i].permissions);
            console.log(this.group)
            checkbox.checked 	= this.users[i].permissions.some((permission)=>{return permission.group == (this.group == ''? '*' : this.group)})
            checkbox.onchange   = () => {
                let permission = {
                    group:          this.group == '' ? '*' : this.group,
                    fWrites:        false,  // only read access by default
                }
                if (checkbox.checked) {
                    this.users[i].permissions.push(permission);
                }
                else
                    this.users[i].permissions = this.users[i].permissions.filter((oldPermission)=>{return oldPermission.group != permission.group});
                this.view.buildList();
            }
        }
    }
}