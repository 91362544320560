import { Attribute } from "../../lib/attributes";
import { TagAttribute } from "../../lib/tag";
import { RegisterWidget, Widget } from "../../lib/widget";
import type { Tag, TagDefinition } from "../../lib/tag";
import { TagUnit, convert } from '../../lib/tagunits';
import template from './datetime.html';
import Icon from '../../../images/icons/schedule.svg';
/**
 * Date Time Setpoint Widget
 *
 * Takes a numeric, writeable tag and renders a datetime input element
 * Expects tag values in milliseconds TODO: We could make a select attribute for different formats
 */

@RegisterWidget({tag:'date-time-setpoint', icon: Icon, roles:['StartSecondOfDay', 'EndSecondOfDay'], template: template, displayName: 'Datetime', section: 'Input', editor: true})
export class DateTimeSetpoint extends Widget {
    valueElement: HTMLInputElement;
    @Attribute({displayName: 'Step'}) step: number;
    @Attribute({displayName: 'Show Units'}) showUnits: boolean = false;

    @TagAttribute({
        displayName: 'Setpoint Tag',
        shouldSubscribe: true,
        requiredProperties: ['writeable'],
        supportedTypes: ['numeric']
    }) setpoint: TagDefinition;

    protected connectedCallback(): void {
        this.valueElement = this.shadowRoot?.getElementById('input')! as HTMLInputElement;
    }

    protected enliven(): void {
        this.valueElement.step = this.step ? this.step.toString() : this.setpoint.tag.resolution.toString();
        if (this.showUnits)
        {
            let unitsElement = this.shadowRoot!.getElementById('units')!;
            unitsElement.textContent = this.setpoint.tag.unitsText ?? '';
        }
        let tag = this.setpoint.tag;
        this.valueElement.onchange = (e) => tag.setPendingValue(convert(this.valueElement.valueAsNumber, TagUnit.TU_MSECONDS, tag.units), this);
    }

    update(tag: Tag): void {
        let mSeconds = tag.convertValue(TagUnit.TU_MSECONDS, this);
        this.valueElement.valueAsNumber = mSeconds;
    }
}