/**
 * Widget Web Component Framework
 *
 * A modern, TypeScript-based web component framework for building reusable UI widgets
 * with built-in tag subscription, attribute management, and error handling.
 *
 * @module widget
 */

import type { Alarm, ConfiguredAlarm } from '../../alarm';
import type { Tag, TagDefinition } from './tag';
import { ExtendedAttributeMetadata, attrMetadataSymbol, attrSymbol } from './attributes';
import { ExtendedTagMetadata, ExtendedTagSetMetadata, SerializedTag, tagAttributeMetadataSymbol, tagSetAttributeMetadataSymbol, TagQuality, QualityMap, findGlobalTagsFromPath } from './tag';
import { filterTag } from './tagfilters';
import { type TagID, type NodeSubscriber} from '../../node';
import { RoleMap } from './createwidget';

export enum WidgetErrorType {
  ATTRIBUTE_ERROR = 'attribute_error',
  TAG_ERROR = 'tag_error',
  RENDERING_ERROR = 'rendering_error',
  SUBSCRIPTION_ERROR = 'subscription_error',
  SERIALIZATION_ERROR = 'serialization_error'
}

export interface WidgetError {
  type: WidgetErrorType;
  message: string;
  details?: any;
  timestamp: number;
}

/**
 * Defines a type for Widget constructors with two specific requirements:
 *
 * 1. The constructor must be a Function. This ensures that the type can be used to instantiate
 *    new objects
 *
 * 2. The constructor must have an `observedAttributes` property which is an array of strings.
 *    This property is intended to specify the names of attributes that the widget should monitor for changes.
 *    When an attribute listed in `observedAttributes` changes, the widget can react accordingly.
 *
 * Additionally, the type allows for indexing by symbols, meaning that constructors can have symbol-indexed
 * properties for additional metadata or functionality that does not conflict with string-keyed properties.
 */
export type WidgetConstructorType = Function & {
    [index: symbol]: any;
    observedAttributes: string[];
};

export function define(name: string,): (constructor: CustomElementConstructor) => void {
    return (constructor: CustomElementConstructor) => {
        customElements.define(name, constructor);
    };
}

/**
 * Defines properties for registering a new Widget component
 *
 * @interface WidgetProperties
 * @property {string} tag - The custom element tag name (without hyphens)
 * @property {string} displayName - Human-readable name for the widget
 * @property {string} [template] - Optional HTML template string
 * @property {string[]} [roles] - Optional roles this widget can fulfill
 * @property {string} [section] - Optional section for categorization
 * @property {string} [icon] - Optional icon path or name
 * @property {boolean} [isLazy=false] - Whether to lazy-load this widget
 * @property {boolean} [editor=false] - Whether this widget is for editor use
 */
export interface WidgetProperties {
    tag: string;
    displayName: string;
    template?: string;
    roles?: string[];
    section?: string;
    icon?: string;
    isLazy?: boolean;
    editor?: boolean;
}

export const Widgets: WidgetProperties[] = [];
export const defaultAttrValues = Symbol()

export const formInternals = Symbol();

export const WidgetsInDOM: Set<Widget> = new Set()

export const isConnecting = Symbol();
const warningDiv = Symbol();
const showWarning = Symbol();
const hideWarning = Symbol();
const onConnected = Symbol();
const subscribe = Symbol();
const unsubscribe = Symbol();
const resolveTags = Symbol();
const internals = Symbol();
const getAttributeMetadata = Symbol();
export const getTagMetadata = Symbol();
const getTagSetMetadata = Symbol();
const update = Symbol();
export const refresh = Symbol();
const isDisconnecting = Symbol();
const onDisconnect = Symbol();
const resizeListener = Symbol();
export const isAttached = Symbol();
const requiresValue = Symbol();
const isSubscribed = Symbol();
const getResolvedTags = Symbol();
const isAboutToRefresh = Symbol();
export const isEnlivened = Symbol();
const onResize = Symbol();
const onAttributeDidChange = Symbol();
const widgetName = Symbol();
const getAddTagOptions = Symbol();
const subscribedTags = Symbol();
const isAboutToSubscribe = Symbol();
const template = Symbol();
const serializedTags = Symbol();
const serializedTagSets = Symbol();
const tagSlots = Symbol();
const isLazy = Symbol();
const observer = Symbol();
const initPromise = Symbol();
const resolveInit = Symbol();
const slottedTags = Symbol();
const errors = Symbol();
/**
 * Decorator function to register a class as a Widget component
 *
 * This decorator automatically registers the class as a custom element and
 * adds all the Widget framework functionality.
 *
 * @example
 * ```typescript
 * @RegisterWidget({
 *   tag: 'my-widget',
 *   displayName: 'My Widget',
 *   isLazy: true
 * })
 * class MyWidget extends Widget {
 *   // Custom implementation
 * }
 * ```
 *
 * @param {WidgetProperties} properties - Configuration properties for the widget
 * @returns {ClassDecorator} A decorator function that transforms a class into a Widget
 */
export function RegisterWidget(properties: WidgetProperties) {return <T extends CustomElementConstructor>(constructor: T) => {
    const RegisteredWidget = class extends constructor {
        [internals]: { [key: symbol]: HTMLElement } = this[internals] ?? {};
        //@ts-ignore
        [resizeListener]: () => void = () => this[onResize]();
        [isConnecting] = this[isConnecting] ?? false;
        [isDisconnecting] = this[isDisconnecting] ?? false;
        [isEnlivened] = this[isEnlivened] ?? false;
        [isAboutToRefresh] = this[isAboutToRefresh] ?? false;
        [defaultAttrValues] = this[defaultAttrValues] ?? new Map();
        [subscribedTags] = this[subscribedTags] ?? new Set();
        [template] = this[template] ?? properties.template;
        [serializedTags] = this[serializedTags] ?? new Map();
        [serializedTagSets] = this[serializedTagSets] ?? new Map();
        [errors] = this[errors] ?? new Map<WidgetErrorType, WidgetError[]>();
        [tagSlots] = this[tagSlots] ?? new Map<string, HTMLSlotElement>();
        [slottedTags] = this[slottedTags] ?? new Map<HTMLElement, Set<TagDefinition>>();
        [isLazy] = this[isLazy] ?? properties.isLazy;
        [initPromise] = this[initPromise] ?? new Promise<boolean>((resolve) => this[resolveInit] = resolve);

        constructor(...args: any[]) {
            super();
            if (!this.shadowRoot) { // Only want to call this once in our prototype chain
                // Use delegatesFocus for better keyboard navigation
                this.attachShadow({ mode: 'open', delegatesFocus: true });

                // Add form association if supported
                if ('attachInternals' in this) {
                    this[formInternals] = (this as any).attachInternals();
                }
            }

            // Store default values
            for (let [attrName, metadata] of this[getAttributeMetadata]()) {
                let privateKey = constructor[attrSymbol].get(attrName);
                if (this[privateKey])
                    this[defaultAttrValues].set(attrName, this[privateKey]);
            }
            for (let [attrName, metadata] of this[getTagMetadata]()) {
                let privateKey = constructor[attrSymbol].get(attrName);
                if (this[privateKey])
                    this[defaultAttrValues].set(attrName, this[privateKey]);
            }
        }

        /**
         * Web Component lifecycle hook. Called whenever this element is attached to the DOM.
         * We don't want to take away the ability for a developer to implement their own logic at
         * this step, so we need to call super.connectedCallback() to make sure we call it all
         * the way down the prototype chain. However, we only want to call our [onConnected]() method
         * once per implementation (and if we inherit from another RegisterWidget decorated class,
         * their connectedCallback will be called as well). So, we store a symbol indexed boolean
         * on the instance and set it to true in the [onConnected]() method. Then, we can check whether
         * we have already attached our ShadowDOM using the [isConnecting] property
         */
        connectedCallback(): void {
            // Set default ARIA role if not specified - MOVED from constructor to here
            if (!this.hasAttribute('role')) {
                this.setAttribute('role', 'widget');
            }

            if (!this[isConnecting]) // Make sure we only call these methods once, base class may also be a Widget!
                this[onConnected]();
            //@ts-ignore
            super.connectedCallback();
            this[isConnecting] = false;
            this[isAttached] = true;
        }

        [onConnected]() {
            this[isConnecting] = true;
            if (this[isLazy]) {
                this[observer] = new IntersectionObserver((entries) => {
                    if (entries[0].isIntersecting) {
                        this[observer].unobserve(this);

                        // Add a placeholder UI while loading
                        const placeholder = document.createElement('div');
                        placeholder.style.cssText = `
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: var(--color-surface, #f5f5f5);
                            border-radius: 4px;
                        `;
                        placeholder.innerHTML = `<span>Loading ${properties.displayName || 'Widget'}...</span>`;
                        this.shadowRoot?.appendChild(placeholder);

                        // Use requestIdleCallback for non-critical initialization
                        if ('requestIdleCallback' in window) {
                            (window as any).requestIdleCallback(() => {
                                this.shadowRoot?.removeChild(placeholder);
                                this[refresh]();
                                //@ts-ignore
                                WidgetsInDOM.add(this);
                                addEventListener('resize', this[resizeListener]);
                            }, { timeout: 1000 }); // 1 second timeout as fallback
                        } else {
                            // Fallback for browsers that don't support requestIdleCallback
                            setTimeout(() => {
                                this.shadowRoot?.removeChild(placeholder);
                                this[refresh]();
                                //@ts-ignore
                                WidgetsInDOM.add(this);
                                addEventListener('resize', this[resizeListener]);
                            }, 0);
                        }
                    }
                }, {
                    // Add threshold options for more granular loading
                    threshold: [0, 0.1, 0.5, 1.0],
                    // Add rootMargin to load slightly before visible
                    rootMargin: '100px'
                });
                this[observer].observe(this);
            }
            else {
                this[refresh]();
                //@ts-ignore
                WidgetsInDOM.add(this);
                addEventListener('resize', this[resizeListener]);
            }
        }

        /**
         * Display a red 'X' image over the widget indicating that there are one or more issues preventing the widget from rendering
         * @param warningText Text to display when hovering over the warning image
         */
        [showWarning](warningText: string = ''): void {
            this[internals][warningDiv].style.display = '';
            this[internals][warningDiv].title = warningText;
            //@ts-ignore
            super.onWarningShown(warningText);
        }

        /**
         * Check
         * @returns A promise comprised of an array of promises for each tag that we need to resolve
         */
        [resolveTags](): Promise<void[]> {
            let promises: Promise<void>[] = [];
            for (let [key, metaData] of [...this[getTagMetadata](), ...this[getTagSetMetadata]()]) {
                if (metaData.shouldSubscribe)
                    this[requiresValue] = true;
                let tags: Tag[] = [];
                if (metaData.type === 'set') { // If this attribute is an array of tags
                    if (!metaData.isOptional && !this[metaData.privateKey]?.length) {
                        promises.push(new Promise<void>(resolve => {
                            this.addError(WidgetErrorType.TAG_ERROR, `Tag set '${metaData.displayName}' requires at least one tag.`);
                            resolve();
                        }));
                    }
                    else if (this[metaData.privateKey]?.length)
                        for (let tagDef of this[metaData.privateKey]) {
                            tags.push(tagDef.tag);
                        }
                }
                else { // It must be a single reference to a tag Definition
                    tags.push(this[metaData.privateKey]?.tag);
                }

                for (let tag of tags) {
                    if (tag) {
                        promises.push(new Promise<void>((resolve, reject) => {
                            //TODO: give the user some additional feedback about which filters weren't valid
                            let error = filterTag(tag, metaData);
                            if (!error)
                                resolve();
                            else {
                                this.addError(WidgetErrorType.TAG_ERROR, `Tag set '${metaData.displayName}' requires at least one tag.`);
                                resolve();
                            }
                        }))
                    }
                    else if (metaData.isOptional !== true) { // Tag is required and we don't got it. Let them know and bail out.
                        promises.push(new Promise<void>((resolve, reject) => {
                            this.addError(WidgetErrorType.TAG_ERROR, `No attribute given for required tag '${metaData.displayName}'`);
                            resolve();
                        }));
                    }
                }
            }
            // Create a new promise that will be resolved when each promise in the array is resolved or rejected when any promise is rejected
            return Promise.all(promises);
        }

        [getResolvedTags](): [Tag, (ExtendedTagMetadata | ExtendedTagSetMetadata)][] {
            let tags: [Tag, (ExtendedTagMetadata | ExtendedTagSetMetadata)][] = [];
            for (let [key, metaData] of [...this[getTagMetadata](), ...this[getTagSetMetadata]()]) {
                if (!metaData.shouldSubscribe)
                    continue;
                if (metaData.type === 'set') {
                    for (let tagDef of this[metaData.privateKey])
                        tags.push([tagDef.tag, metaData]);
                }
                else
                    tags.push([this[metaData.privateKey]?.tag, metaData]);
            }
            return tags;
        }

        [getAddTagOptions](tag: Tag): Map<string, (tag: Tag)=>void> {
            let map: Map<string, (tag: Tag)=>void> = new Map();
            for (let [key, metaData] of [...this[getTagMetadata](), ...this[getTagSetMetadata]()]) {
                if (!filterTag(tag, metaData))
                    map.set(metaData.displayName, (tag)=> {
                        if (metaData.type === 'set')
                            this[metaData.privateKey].push({tag: tag});
                        else
                            this[metaData.privateKey] = {tag: tag};
                    })
            }
            return map;
        }

        [hideWarning]() {
            this[internals][warningDiv].style.display = 'none';
            //@ts-ignore
            super.onWarningHidden();
        };

        subscribeToTag(tag: Tag) {
            this[subscribedTags].add(tag);
            if (this[isAboutToSubscribe]) // We already have a subscribe queued
                return;
            this[isAboutToSubscribe] = true;
            queueMicrotask(() => {
                this[isAboutToSubscribe] = false;
                for (let tag of this[subscribedTags])
                    tag.subscribeWidget(this, (tag: Tag) => this[update](tag));
            });
        }

        subscribeToTags(tags: Tag[]) {
            tags.forEach(tag => this.subscribeToTag(tag));
        }

        [subscribe]() {
            for (let [tag, metadata] of this[getResolvedTags]()) {
                if (tag && metadata.shouldSubscribe) {
                    this.subscribeToTag(tag);
                }
            }
            this[isSubscribed] = true;
        }

        [unsubscribe]() {
            for (let subbedTag of this[subscribedTags]) {
                if (subbedTag && subbedTag.subscribers && subbedTag.subscribers.has(this)) {
                    subbedTag.unsubscribe(this);
                }
            }
            this[subscribedTags].clear();
            this[isSubscribed] = false;
        }

        [update](tag: Tag) {
            let badQualities: [string, TagQuality][] = [];
            for (let subbedTag of this[subscribedTags]) {
                if (subbedTag.quality != TagQuality.TQ_GOOD)
                    badQualities.push([tag.name, tag.quality]);
                else //@ts-ignore
                    this.update(subbedTag)
            }
            if (badQualities.length == 0)
                this[hideWarning]();
            else {
                let warningString = '';
                for (let badQ of badQualities) {
                    for (let [tq, text] of QualityMap) {
                        if ((badQ[1] & tq) !== 0)
                            warningString += `\nTag '${badQ[0]}': ${text}`
                    }
                }
                this[showWarning](warningString);
            }
        }

        [onDisconnect]() {
            this[isDisconnecting] = true;
            this[unsubscribe]();
        }

        public disconnectedCallback() {
            removeEventListener('resize', this[resizeListener]);
            this[onDisconnect]();
            this[observer]?.disconnect();
            //@ts-ignore
            super.onDisconnect();
            this[isDisconnecting] = false;
            //@ts-ignore
            WidgetsInDOM.delete(this);
        }

        /**
         * Called when any attribute is changed on our widget.
         * @param name
         * @param oldValue
         * @param newValue
         */
        public attributeChangedCallback(name: string, oldValue: string, newValue: string | null): void {
            this[onAttributeDidChange](name, oldValue, newValue);
        }

        /**
         *
         * @param name
         * @param oldValue
         * @param newValue
         */
        [onAttributeDidChange](name: string, oldValue: string, newValue: string | null) {
            let privateKey = constructor[attrSymbol].get(name); // Get the private key to look up our value
            if (newValue === '' || newValue === null) { // Empty string or null indicates we want to remove this attribute and set it back to the default
                this[privateKey] = this[defaultAttrValues].get(name); // Set it back to the default value (which could be undefined)
                if (this[isAttached]) // And, if we are attached, refresh and rerender
                    this[refresh]();
            }
            else if (this[getAttributeMetadata]().has(name)) { // If we aren't removing the attribute, check if it is defined in our attributeMetadata
                switch (this[getAttributeMetadata]().get(name)!.type) { // Look up the attribute's type
                    case 'String':
                        this[privateKey] = newValue; // We can work directly with strings, just set the value
                        break;
                    case 'Boolean':
                        this[privateKey] = newValue === 'true'; // Only set to true if we exactly match 'true', no other truthy values accepted
                        break;
                    case 'Number':
                        this[privateKey] = Number(newValue); // Cast the string to a number for numbers
                        break;
                    case 'Array':
                    case 'Object':
                        try {
                            this[privateKey] = JSON.parse(newValue); // Try to parse an object and hope it is a valid object for this attribute
                        } catch (reason) { // If something goes wrong parsing the new value, show the warning and set the warning text
                            this[privateKey] = newValue;
                            //throw (new Error(`Error parsing attribute '${name}': ${reason}`));
                        };
                        break;
                    default:
                        throw (new Error(`Invalid metadata type for attribute: ${name}`));
                }
            }

            if (this[isAttached])
                this[refresh]();
        }

        async [refresh]() {
            if (this[isAboutToRefresh]) // We already have a refresh queued up.
                return;
            //@ts-ignore
            super.onRefresh()
            this[initPromise] = new Promise<boolean>((resolve) => this[resolveInit] = resolve);
            this[isEnlivened] = false;
            this[isAboutToRefresh] = true;

            Array.from(this.shadowRoot?.childNodes!).forEach(node => this.shadowRoot?.removeChild(node));
            this.createDefaultStyles();
            const warning = document.createElement('div');  // Element that will hold the red X and show error text on hover
            this[internals][warningDiv] = warning;          // Add it to our array of internal elements
            warning.style.cssText = `
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 99999;
            `;
            warning.innerHTML = createXImage();
            this.shadowRoot!.appendChild(warning);
            this[tagSlots].clear();
            for (let [key, metaData] of [...this[getTagMetadata](), ...this[getTagSetMetadata]()]) {
                if (metaData.shouldSubscribe)
                    this[requiresValue] = true;
                let slot = document.createElement('slot');
                slot.name = key;
                this[tagSlots].set(key, slot);
                this.shadowRoot?.appendChild(slot);
            }

            if (this[isSubscribed])
                this[unsubscribe]();

            let renderedNode: Node | null = null;
            if (this[template]) {
                let templateElement = document.createElement('template');
                templateElement.innerHTML = this[template] ?? '';
                renderedNode = templateElement.content.cloneNode(true);
            }
            else {
                //@ts-ignore
                renderedNode = super.render();
            }
            if (renderedNode !== null)
                this.shadowRoot?.appendChild(renderedNode);
            if (!this[isConnecting]) // @ts-ignore
                super.connectedCallback()

            //this.shadowRoot?.addEventListener('slotchange', (e) => {
            //    if (this[isAttached]) // And, if we are attached, refresh and rerender
            //        this[refresh]();
            //})
            /**
             * Here we use the queueMicrotask method to delay this call until after the current
             * task has completed and there is no other code waiting to be run.
             * We want to make sure we only refresh once in the case that we trigger multiple
             * refresh calls at once. For instance, when the Widget is first connected to the
             * DOM, each attribute predefined in the DOM will trigger its own
             * attributeChangedCallback.
             */
            queueMicrotask(async () => {
                /* Check which tags were already deserialized. If they are no longer serialized, remove them */
                for (let [element, tagDefs] of this[slottedTags]) {
                    if (!this.contains(element)) {
                        const privateKey = constructor[attrSymbol].get(element.slot);
                        let isTagSet = this[getTagSetMetadata]().has(element.slot);

                        if (!isTagSet)
                            if (this[privateKey]?.tag && tagDefs.has(this[privateKey])) // Our variable is set to the removed tag
                                this[privateKey] = undefined;
                            else
                                throw new Error(`Tried to remove a serialized tag that does not exist on this widget`)
                        else if (this[privateKey]) { // It's an array and it already exists, filter our tag out
                            let removeIndex = this[privateKey].findIndex(tagDef => tagDef.fromSerialized && tagDefs.has(tagDef));
                            if (removeIndex !== -1)
                                this[privateKey].splice(removeIndex, 1);
                            else
                                throw new Error('Attempting to remove a tag which does not exist');
                        }
                    }
                }
                this[errors] = new Map<WidgetErrorType, WidgetError[]>();
                this[slottedTags].clear()
                await this.deserializeTags();
                await this[resolveTags]();
                this[isAboutToRefresh] = false;


                if(this[errors].size > 0) {
                    const errorMessages = Array.from(this[errors].values())
                        .flat()
                        .map((err: WidgetError) => `<strong>${err.type}</strong>: ${err.message}`)
                        .join('<br>');
                    this[showWarning](errorMessages);
                    return;
                }
                this[hideWarning]();

                //@ts-ignore
                super.enliven();
                this[isEnlivened] = true;
                if (this[requiresValue])    // We should have stored whether or not any tag needs to be subscribed to
                    this[subscribe]();      // If we do, subscribe to all the tags that need values

                this[resolveInit](true);
            });
        }

        public isInitialized(): Promise<boolean> {
            return this[initPromise];
        }

        [getAttributeMetadata](): Map<string, ExtendedAttributeMetadata> {
            return constructor[attrMetadataSymbol] ?? new Map<string, ExtendedAttributeMetadata>();
        }

        [getTagMetadata](): Map<string, ExtendedTagMetadata> {
            return constructor[tagAttributeMetadataSymbol] ?? new Map<string, ExtendedTagMetadata>();
        }

        [getTagSetMetadata](): Map<string, ExtendedTagSetMetadata> {
            return constructor[tagSetAttributeMetadataSymbol] ?? new Map<string, ExtendedTagSetMetadata>();
        }

        [onResize]() {
            if (this[isEnlivened]) //@ts-ignore
                requestAnimationFrame(() => super.onResize())
        }

        set name(name: string) {
            this[widgetName] = name;
        }

        get name() {
            return this[widgetName]
        }

        private createDefaultStyles() {
            // Use Constructable Stylesheets if supported
            if ('adoptedStyleSheets' in Document.prototype && 'replaceSync' in CSSStyleSheet.prototype) {
                try {
                    const sheet = new CSSStyleSheet();
                    (sheet as any).replaceSync(/*css*/`
                        * {
                            box-sizing: border-box;
                            position: relative;
                        }
                        div {
                            display: inline-block;
                        }
                        .__warning {
                            display: flex;
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            border-radius: 10%;
                            z-index: 99999;
                        }
                        /* custom scrollbar */
                        ::-webkit-scrollbar {
                            width: 20px;
                        }

                        ::-webkit-scrollbar-track {
                            background-color: transparent;
                        }

                        ::-webkit-scrollbar-thumb {
                            background-color: var(--color-primaryContainer);
                            border-radius: 20px;
                            border: 6px solid transparent;
                            background-clip: content-box;
                        }

                        ::-webkit-scrollbar-thumb:hover {
                            background-color: var(--color-primaryFixedDim);
                        }

                        ::-webkit-scrollbar-thumb:active {
                            background-color: var(--color-primary);
                        }
                        :host-context(.editor__preview) {
                            outline: 1px solid var(--color-gray-6);
                        }

                        :host(:focus) {
                            outline: 2px solid var(--color-primary, blue);
                            outline-offset: 2px;
                        }

                        /* High contrast mode support */
                        @media (forced-colors: active) {
                            :host(:focus) {
                                outline: 2px solid CanvasText;
                            }
                        }

                        /* Shadow Parts for external styling */
                        ::part(container) {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }

                        ::part(header) {
                            display: flex;
                            align-items: center;
                        }

                        ::part(content) {
                            display: block;
                        }

                        ::part(footer) {
                            display: flex;
                            align-items: center;
                        }
                    `);

                    // Apply the stylesheet to the shadow root
                    (this.shadowRoot as any).adoptedStyleSheets = [(this.shadowRoot as any).adoptedStyleSheets || [], sheet].flat();
                    return;
                } catch (e) {
                    console.warn('Constructable Stylesheets not fully supported, falling back to style element');
                }
            }

            // Fallback for browsers that don't support Constructable Stylesheets
            let styleSheet = document.createElement('style');
            styleSheet.setAttribute('className', 'Default');
            styleSheet.innerHTML = /*css*/`
                * {
                    box-sizing: border-box;
                    position: relative;
                }
                div {
                    display: inline-block;
                }
                .__warning {
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    border-radius: 10%;
                    z-index: 99999;
                }
                /* custom scrollbar */
                ::-webkit-scrollbar {
                    width: 20px;
                }

                ::-webkit-scrollbar-track {
                    background-color: transparent;
                }

                ::-webkit-scrollbar-thumb {
                    background-color: var(--color-primaryContainer);
                    border-radius: 20px;
                    border: 6px solid transparent;
                    background-clip: content-box;
                }

                ::-webkit-scrollbar-thumb:hover {
                    background-color: var(--color-primaryFixedDim);
                }

                ::-webkit-scrollbar-thumb:active {
                    background-color: var(--color-primary);
                }
                :host-context(.editor__preview) {
                    outline: 1px solid var(--color-gray-6);
                }

                /* Accessibility improvements */
                :host {
                    display: block;
                }

                :host(:focus) {
                    outline: 2px solid var(--color-primary, blue);
                    outline-offset: 2px;
                }

                /* High contrast mode support */
                @media (forced-colors: active) {
                    :host(:focus) {
                        outline: 2px solid CanvasText;
                    }
                }
            `;
            this.shadowRoot?.appendChild(styleSheet);
        }

        async removeDeserializedTag(name: string, tagLocation: string) {
            const privateKey = constructor[attrSymbol].get(name);
            let isTagSet = this[getTagSetMetadata]().has(name);
            const metadata = isTagSet ? this[getTagSetMetadata]().get(name)! : this[getTagMetadata]().get(name)!;

            try {
                const tags = await findGlobalTagsFromPath(tagLocation);
                if (isTagSet)
                    if (this[privateKey]?.tag && tags.has(this[privateKey]?.tag)) // Our variable is set to the removed tag
                        this[privateKey] = undefined;
                    else
                        throw new Error(`Tried to remove a serialized tag that does not exist on this widget`)
                else if (this[privateKey]) // It's an array and it already exists, filter our tag out
                    this[privateKey] = this[privateKey].filter(tagDef => tagDef.fromSerialized && tags.has(tagDef.tag));
            } catch (reason) {
                throw new Error(`Invalid tag ID attribute set for ${metadata.displayName}: ${reason}`);
            }
        }

        async deserializeTags(): Promise<void[]> {
            const allTagsResolved: Promise<void>[] = [];

            // Helper function to resolve a single tag
            const resolveTag = async (name: string, tagID: TagID, slottedSet: Set<TagDefinition>, isTagSet: boolean = false) => {
                const privateKey = constructor[attrSymbol].get(name);
                const metadata = isTagSet ? this[getTagSetMetadata]().get(name)! : this[getTagMetadata]().get(name)!;

                try {
                    const tags = await findGlobalTagsFromPath(tagID.location);
                    let tagArray = Array.from(tags);
                    let tagDefs = tagArray.map(tag => {return { tag, attributes: tagID.attributes, fromSerialized: true }});
                    if (!isTagSet) { // Not an array, so just set the variable
                        this[privateKey] = tagDefs[0];
                        slottedSet.add(tagDefs[0]);
                    }
                    else {
                        tagDefs.forEach(tagDef => {
                            let tagIndex = this[privateKey].findIndex(existingTagDef => existingTagDef.tag === tagDef.tag);
                            if (tagIndex !== -1) { // If this tag is already in the array
                                this[privateKey][tagIndex].attributes = tagDef.attributes; // update its attributes
                                slottedSet.add(this[privateKey][tagIndex]);
                            }
                            else {
                                this[privateKey].push(...tagDefs);
                                slottedSet.add(tagDef);
                            }
                        })
                    }
                } catch (reason) {
                    throw new Error(`Invalid tag ID attribute set for ${metadata.displayName}: ${reason}`);
                }
            };

            // Resolve slotted tag data elements
            for (const [name, slotElement] of this[tagSlots]) {
                for (let element of (slotElement as HTMLSlotElement).assignedElements()) {
                    let slottedSet = new Set<TagDefinition>;
                    this[slottedTags].set(element, slottedSet);
                    let attributes = {}
                    let isTagSet = this[getTagSetMetadata]().has(name);
                    const metadata = isTagSet ? this[getTagSetMetadata]().get(name)! : this[getTagMetadata]().get(name)!;
                    metadata.attributes?.forEach(attr => {
                        if (element.hasAttribute(attr.id)) {
                            attributes[attr.id] = element.getAttribute(attr.id);
                        }
                    })
                    if (element.hasAttribute('tag-path')) {
                        allTagsResolved.push(resolveTag(name, {
                            location: element.getAttribute('tag-path')!,
                            attributes: attributes
                        },  slottedSet, isTagSet))
                    }
                }
            }

            // Wait for all tags to be resolved
           return Promise.all(allTagsResolved);
        }

        swapTags(oldTag: Tag, newTag: Tag) {
            for (let [key, metadata] of this[getTagMetadata]()) {
                if (this[metadata.privateKey] && this[metadata.privateKey].tag === oldTag) {
                    this[subscribedTags].delete(oldTag);
                    this[metadata.publicKey] = {
                        tag: newTag,
                        attributes: this[metadata.privateKey].attributes
                    };
                }
            }
        }

        getSlottedElements(slotName: string): Element[] {
            return this[tagSlots].get(slotName)?.assignedElements() ?? [];
        }

        onNodeDisconnected(tag: Tag) {
            this[subscribedTags].delete(tag);
        }

        /**
         *
         * @param tagLocation
         */
        private getAbsoluteLocation(tagLocation: string): string | null {

            //TODO: this needs role resolution, relative path, all that good stuff
            //@ts-ignore
            tagLocation = this.workDir + tagLocation;
            return this.buildWorkingDirectory(tagLocation);
        }

        /**
         * Work our way up the DOM until we have resolved an absolute path.
         */
        private buildWorkingDirectory(location: string): string | null {
            // We may be nested down in n shadow doms. In that case, to break out of the shadow DOM as we traverse up the tree
            // we need to get the rootNode(shadow DOM)'s host
            // @ts-ignore
            const getParent = (el: HTMLElement): HTMLElement => el.parentElement ?? el.getRootNode().host
            let parent = getParent(this);
            while (parent) {
                if (parent.hasAttribute('work-dir'))
                    location = parent.getAttribute('work-dir') + location;
                if (this.isAbsolutePath(location))
                    return location;
                parent = getParent(parent);
            }
            return null; // We traversed all the way up the DOM and never resolved a full absolute path
        }

        private isAbsolutePath(location: string): boolean {
            return location.split(':').length == 2;
        }

        addError(type: WidgetErrorType, message: string, details?: any): void {
            if (!this[errors].has(type)) {
                this[errors].set(type, []);
            }
            this[errors].get(type)!.push({
                type,
                message,
                details,
                timestamp: Date.now()
            });

            // Log to console in development mode
            if (process.env.NODE_ENV !== 'production') {
                console.warn(`Widget Error (${type}):`, message, details);
            }
        }
    };

    Widgets.push(properties); // push our properties to our global array of widget props

    customElements.define(properties.tag, RegisteredWidget);
    if (typeof properties.roles !== 'undefined') {
        for (let role of properties.roles)
            RoleMap.set(role, properties.tag);
    }
    return RegisteredWidget;
}};

export abstract class Widget extends HTMLElement implements NodeSubscriber {
    [index: symbol]: any;
    observedAttributes: string[];
    name: string;
    _workDir: string;

    #isConnecting = false;
    #isDisconnecting = false;
    #isEnlivened = false;
    #isAttached = false;
    #isAboutToRefresh = false;
    #subscribedTags = new Set<Tag>();
    #errors: string[] = [];

    get workDir() { // We want to be able to set these programmatically and also as inline attributes
        return this._workDir ?? this.getAttribute('work-dir') ?? '';
    }

    set workDir(value: string) {
        this._workDir = value;
    }

    getSlottedElements(slotName: string): Element[] { return []};

    protected connectedCallback() { };
    protected subscribeToTag(tag: Tag) {};
    protected subscribeToTags(tags: Tag[]) {};

    protected render(): Node | null {return null};
    protected enliven() { }

    protected onWarningShown(warningText: string) { };
    protected onWarningHidden() { };
    isInitialized(): Promise<boolean> {
        return new Promise<boolean>(()=>false);
    };

    onAttributeDidChange(attribute: any) { };
    getAddTagOptions(tag: Tag): Map<string, (tag: Tag)=>void> {return this[getAddTagOptions](tag)}
    getAttributeMetadata() {return this[getAttributeMetadata]()}
    onNodeChanged(tag: Tag) {
        this[refresh]();
    };
    swapTags(oldTag: Tag, newTag?: Tag) {};
    onNodeRemoved(tag: Tag) { };
    onAlarm(tag: Tag, alarm: Alarm, fAdded: boolean, fChanged: boolean, fDeleted: boolean) { };
    onConfiguredAlarm(tag: Tag, configuredAlarm: ConfiguredAlarm, fAdded: boolean) { };
    update(tag: Tag) {
        throw (new Error(`Widget subscribed to tags but did not implement the update method`))
    };
    onNodeDisconnected(tag: Tag) {};

    protected onResize() { };
    protected onRefresh() { };
    protected onDisconnect() { };
}

function createXImage(): string {
    return `<svg height="100%"width="100%" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><style>svg{position:absolute;top: 0;left:0;height:100%;width:100%;z-index:2;}</style><rect width=100% height="100%" style="fill:rgb(255,0,0);fill-opacity:0.0;" /><line x1="0" y1="0" x2="100%" y2="100%" stroke="#d0342c" stroke-width="6" stroke-opacity="0.7" /><line x1="0" y1="100%" x2="100%" y2="0" stroke="#d0342c" stroke-width="6" stroke-opacity="0.7" /></svg>`
}

export function getDependentWidgets(tag: Tag): Set<Widget> {
    let dependentWidgets = new Set<Widget>();
    for (let widget of WidgetsInDOM) {
        for (let [key, metaData] of [...widget[getTagMetadata](), ...widget[getTagSetMetadata]()]) {
            let keyTag = widget[metaData.privateKey];
            if (!keyTag)
                continue;
            if (metaData.type === 'set' && keyTag.some(subTag => subTag.tag === tag)) //@ts-ignore
                dependentWidgets.add(widget)
            else if (keyTag.tag === tag)
                dependentWidgets.add(widget)
        }
    }
    return dependentWidgets;
}


