import { Attribute } from '../../../lib/attributes';
import { TagAttribute } from '../../../lib/tag';
import { RegisterWidget, Widget } from '../../../lib/widget';
import type { TagDefinition } from "../../../lib/tag";
import template from './pumpcard.html';
import { type DigitalGauge } from '../../../status/digitalgauge/digitalgauge';
import { type PumpCurve } from '../pumpcurve/pumpcurve';
import { type Accordion } from '../../../layout/accordion/accordion';
import { ColorInput } from '../../../../views/attributeeditorview';

@RegisterWidget({tag: 'pump-card', displayName: 'Pump Card', section: 'Pumps', template: template})
export class PumpCard extends Widget {
    private pumpCurve: PumpCurve;
    private curveAccordion: Accordion;
    private flowGauge: DigitalGauge;
    @Attribute({
        displayName: 'Pump Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) pumpColor: string;
    @Attribute({
        displayName: 'Text Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) textColor: string = '#1D1B20';
    @TagAttribute({displayName: 'Pump Tag'}) pumpFolder: TagDefinition;
    @TagAttribute({displayName: 'Model Folder'}) modelFolder: TagDefinition;
    @Attribute({displayName: 'Pulse Animation'}) isAnimated : boolean = false;
    @Attribute({displayName: 'Show Units'}) showUnits: boolean = true;

    protected connectedCallback(): void {
        this.pumpCurve = this.shadowRoot!.getElementById("curve") as PumpCurve;
        this.curveAccordion = this.shadowRoot!.getElementById("curve-accordion") as Accordion;
        this.flowGauge = this.shadowRoot!.getElementById("flow-gauge") as DigitalGauge;
    }

    protected enliven(): void {
        this.pumpCurve.pumpFolder = {tag: this.pumpFolder.tag};
        this.pumpCurve.modelFolder = {tag: this.modelFolder.tag};
        let flowTag = this.modelFolder.tag.findChild('Flow');
        if (flowTag)
            this.flowGauge.valueTag = {tag: flowTag};
    }
}