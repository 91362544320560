import * as echarts from 'echarts';

type EChartsOption = echarts.EChartsOption;
import { RegisterWidget, Widget } from "../../lib/widget";
import { TagAttribute } from "../../lib/tag";
import type { TagDefinition, Tag } from '../../lib/tag';
import RadialGaugeIcon from '../../../images/icons/gauge.svg'
import { Attribute } from '../../lib/attributes';
import { RangeInput } from '../../../views/attributeeditorview';
import template from './radialgauge.html';

const DefaultRangeSettings = {
    minimum: 0,
    ranges: [{
        name: 'Default',
        upperLimit: 100,
        value: '#000000'
    }]
};

@RegisterWidget({tag: 'radial-gauge', displayName: 'Radial Gauge', template: template, icon: RadialGaugeIcon, section: 'Status', editor: true})
export class RadialGauge extends Widget {
    gauge: echarts.ECharts;
    @TagAttribute({
        displayName: 'Value Tag',
        shouldSubscribe: true,
        supportedTypes: ['numeric']
    }) valueTag: TagDefinition;

    @Attribute({
        displayName: 'Ranges',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new RangeInput(name, parent, property, getValue, onSettingChangedCallback)
    }) rangeSettings: {minimum: number, ranges: {name: string, upperLimit: number, value: string}[]} = DefaultRangeSettings;

    options: EChartsOption = {
        series: [
            {
                type: 'gauge',
                axisLine: {
                    lineStyle: {
                        //width: 12,
                    }
                },
                pointer: {
                    itemStyle: {
                    color: 'auto'
                    }
                },
                axisTick: {
                    distance: -30,
                    length: 8,
                    splitNumber: 5,
                    lineStyle: {
                    color: '#fff',
                    width: 2,

                    }
                },
                splitNumber: 5,
                splitLine: {
                    distance: -30,
                    length: 30,
                    lineStyle: {
                        color: '#fff',
                        width: 4
                    }
                },
                axisLabel: {
                    color: 'inherit',
                    distance: 2,
                    fontSize: 12
                },
                detail: {
                    valueAnimation: true,
                    color: 'inherit'
                },
                data: [
                    {
                        value: 70
                    }
                ]
            }
        ]
    };

    protected enliven(): void {
        let chartDom = this.shadowRoot?.querySelector('.wrapper') as HTMLDivElement;
        this.gauge = echarts.init(chartDom);
        let maxDim = Math.min(this.clientWidth, this.clientHeight);

        let options: EChartsOption = {
            series: [
                {
                    radius: '85%',
                    min: this.valueTag.tag.engMin,
                    max: this.valueTag.tag.engMax,
                    type: 'gauge',
                    axisLine: {
                        lineStyle: {
                            color: [],
                            width: maxDim / 25
                            //width: 12,
                        },

                    },
                    labelLayout: {

                    },
                    pointer: {

                        itemStyle: {
                            color: 'auto'
                        }
                    },
                    axisTick: {
                        distance: -maxDim / 20,
                        length: maxDim / 20,
                        splitNumber: 5,
                        lineStyle: {
                            color: '#fff',
                            width: maxDim / 100,
                        }
                    },
                    splitNumber: 5,
                    splitLine: {
                        distance: -maxDim / 10,
                        length: maxDim / 10,
                        lineStyle: {
                            color: '#fff',
                            width: maxDim / 50
                        }
                    },
                    axisLabel: {
                        color: 'inherit',
                        distance: maxDim / 20,
                        fontSize: maxDim / 15
                    },
                    markArea: {

                    },
                    itemStyle: {

                    },
                    detail: {
                        valueAnimation: true,
                        color: 'inherit',
                        fontSize: maxDim / 10,
                        offsetCenter: [0, '90%']
                    },
                    data: [
                        {
                            value: 70
                        }
                    ]
                }
            ]
        };

        this.options.series![0].min = this.valueTag.tag.engMin;
        this.options.series![0].max = this.valueTag.tag.engMax;
        this.options.series![0].axisLine.lineStyle.color = [];
        this.options.series![0].axisLine.lineStyle.width = maxDim / 25;
        this.options.series![0].axisTick.width = maxDim / 100;
        this.options.series![0].axisTick.splitNumber = (this.valueTag.tag.engMax - this.valueTag.tag.engMin) / 5 / 5;

        options.series![0].axisLine.lineStyle.color = [];
        for (let i=0;i<this.rangeSettings.ranges.length;++i) {
            let range = this.rangeSettings.ranges[i];
            let pct = (range.upperLimit - this.valueTag.tag.engMin) / (this.valueTag.tag.engMax - this.valueTag.tag.engMin);
            options.series![0].axisLine.lineStyle.color.push([pct, range.value]);
        }
        options.series![0].axisLine.lineStyle.color.push([1, '#ff1234']);

        this.gauge.setOption(options);
    }

    update(tag: Tag): void {
        this.gauge.setOption({
            series: [{
                data: [{
                    value: tag.getValue()
                }],
                detail: {
                    formatter: `${tag.valueText} ${this.valueTag.tag.unitsText}`,
                }
            }]
        })
    }
}