import owner from '../owner';
import jstz from 'jstz';
import assert from './debug';
import LiveDataClient from './livedataclient';
import FrameMaker from './framemaker';
import LiveData from './livedata';

//The Date format prototype:
//'format' can be any arbitrary string. Each occurence of the following will be replaced with a date value:
// %yyyy:	four-digit year (2012)
// %yy:		zero-padded two-digit year (09)
// %y:		one- or two-digit year (9), (12)
// %MMMM:	return full month name text
// %MMM:	return first 3 characters or month
// %MM:		return zero-padded 2-digit month
// %M:		return unpadded 1- or 2-digit month
// %DDDD:	return day of week string
// %DDD:	return first three characters of day
// %dd:		return zero-padded day of month
// %d:		return unpadded day of month
// %HH:		zero-padded 2-digit 24-hour value (00-23)
// %H:		24-hour value (0-23)
// %hh:		zero-padded 2-digit 12-hour value (01-12)
// %h:		12-hour value (1-12)
// %mm:		zero-padded 2-digit minute
// %m:		minute
// %ss:		zero-padded 2-digit second
// %s:		second
// %t:		AM/PM
//
// All returned values are in the selected time zone.
// Example format strings and the results:
// '%yy.%MM.%dd %HH:%mm:%ss'			-> 12.09.01 22:15:17
// 'Today is %dddd, %MMMM %d, %yyyy.'	-> Today is Monday, September 1, 2012.
export var monthStrings = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export var dayStrings	= ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


declare global {
	interface Date {
		format(string: string);
		toSelected();
		toUTC();
		toDatetimeLocal();
		fromDatetimeLocal();
	}
}

Date.prototype.format = function(string) {
	var d = this;
	var o = owner.timeZone ? owner.timeZone.toSelectedZone(this) : {year: d.getFullYear(), month: d.getMonth(), weekday: d.getDay(), date: d.getDate(), hours: d.getHours(), minutes: d.getMinutes(), seconds: d.getSeconds(), abbr: ''};
	return string.replace(/(%yyyy|%yy|%y|%MMMM|%MMM|%MM|%M|%DDDD|%DDD|%dd|%d|%HH|%H|%hh|%h|%mm|%m|%ss|%s|%t|%zz)/g, function($1) {
		var h;
		switch ($1) {
			case '%yyyy':	return o.year;
			case '%yy':		return ('0'+(o.year%100)).slice(-2);
			case '%y':		return (o.year%100);
			case '%MMMM':	return monthStrings[o.month];
			case '%MMM':	return monthStrings[o.month].substr(0, 3);
			case '%MM':		return ('0'+(o.month + 1)).slice(-2);
			case '%M':		return (o.month + 1);
			case '%DDDD':	return dayStrings[o.weekday];
			case '%DDD':	return dayStrings[o.weekday].substr(0, 3);
			case '%dd':		return ('0'+o.date).slice(-2);
			case '%d':		return o.date;
			case '%HH':		return ('0'+o.hours).slice(-2);
			case '%H':		return o.hours;
			case '%hh':		return ('0'+((h = (o.hours % 12)) ? h : 12)).slice(-2);
			case '%h':		return (h = o.hours % 12) ? h : 12;
			case '%mm':		return ('0'+o.minutes).slice(-2);
			case '%m':		return o.minutes;
			case '%ss':		return ('0'+o.seconds).slice(-2);
			case '%s':		return o.seconds;
			case '%t':		return o.hours < 12 ? 'AM' : 'PM';
			case '%zz':		return o.abbr;
		}
	});
};

Date.prototype.toSelected = function() {
	this.setTime(owner.timeZone.toSelected(this)*1000);
};

Date.prototype.toUTC = function() {
	this.setTime(owner.timeZone.toUTC(this)*1000);
};

// https://webreflection.medium.com/using-the-input-datetime-local-9503e7efdce
Date.prototype.toDatetimeLocal =
  function() {
    var
      date = this,
      ten = function (i) {
        return (i < 10 ? '0' : '') + i;
      },
      YYYY = date.getFullYear(),
      MM = ten(date.getMonth() + 1),
      DD = ten(date.getDate()),
      HH = ten(date.getHours()),
      II = ten(date.getMinutes()),
      SS = ten(date.getSeconds())
    ;
    return YYYY + '-' + MM + '-' + DD + 'T' +
             HH + ':' + II; //+ ':' + SS;
  };

Date.prototype.fromDatetimeLocal = (function fromDatetimeLocal(BST) {
  // BST should not be present as UTC time
  return new Date(BST).toISOString().slice(0, 16) === BST ?
    // if it is, it needs to be removed
    () => {
		let date = this as Date;
		return new Date(
			date.getTime() +
			(date.getTimezoneOffset() * 60000)
		).toISOString();
    } :
    // otherwise can just be equivalent of toISOString
    Date.prototype.toISOString;
}('2006-06-06T06:06'));

// This objects allows a TimeZone to be selected uses the Date.format function above to display
// all Date objects in that TimeZone. It is meant to be a singleton that lives under owner.
export class TimeZone {
	timeZones: any;
	localZone: any;
	selectedZone: any;
	firstYear: number = 1970;
	daysSinceFirstYear: number[];
	nonLeapDaysSinceStartOfYear: number[];
	leapDaysSinceStartOfYear: number[];
	requestedTimeZones: Set<string> = new Set();
    constructor() {
        this.timeZones = {'UTC': []};				// Map to hold all of our acquired time zones by name. We could query UTC, but it will always have 0 transitions
        this.localZone = jstz.determine().name();	// Get the local time zone of the user. Only time we use this library
		this.getTimeZone(typeof this.localZone != 'undefined' ? this.localZone : 'America/Chicago');			// Get the local time zone rules from Whoville
    //	this.getTimeZone('America/New_York');
    //	this.getTimeZone('America/Los_Angeles');
    //	this.getTimeZone('Europe/Madrid');
        this.selectedZone = this.localZone;			// Default to the local time zone of the user

        this.daysSinceFirstYear = [	0,  	365,   730,  1096,  1461,  1826,  2191,  2557,  2922,  3287,		// Count of days since year 1970
                                3652,  4018,  4383,  4748,  5113,  5479,  5844,  6209,  6574,  6940,
                                7305,  7670,  8035,  8401,  8766,  9131,  9496,  9862, 10227, 10592,
                                10957, 11323, 11688, 12053, 12418, 12784, 13149, 13514, 13879, 14245,
                                14610, 14975, 15340, 15706, 16071, 16436, 16801, 17167, 17532, 17897,
                                18262, 18628, 18993, 19358, 19723, 20089, 20454, 20819, 21184, 21550,
                                21915, 22280, 22645, 23011, 23376, 23741, 24106, 24472, 24837, 25202,
                                25567, 25933, 26298, 26663, 27028, 27394, 27759, 28124, 28489, 28855,
                                29220, 29585, 29950, 30316, 30681, 31046, 31411, 31777, 32142, 32507,
                                32872, 33238, 33603, 33968, 34333, 34699, 35064, 35429, 35794, 36160]
        this.nonLeapDaysSinceStartOfYear = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334]		    // Days in normal year at start of each month
        this.leapDaysSinceStartOfYear    = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335]				// Days in leap year at start of each month
    }

    getTimeZone(zone: string) {				// Ask for a set of TimeZone rules from Whoville
		if(!this.requestedTimeZones.has(zone)) {	// If we don't have the rules already
			this.requestedTimeZones.add(zone);
			let fm = new FrameMaker();
			fm.buildFrame(LiveData.WVC_GET_TIME_ZONE);
			fm.push_string(zone);
			LiveDataClient.sendRequest(fm).then(fp => {
				var name = fp.pop_string();	// Olsen time zone name
				var transitions: any[] = [];		// To hold all the transitions we get back
				var count = fp.pop_u32();	// Count of transitions in the frame
				for (var i = 0; i < count; ++i) {
					var transition = {
						utcFront: fp.pop_u64(),
						offset: fp.pop_s32(),
						isDST: fp.pop_u8() > 0,
						utcBack: fp.pop_u64(),
						localBack: fp.pop_u64(),
						abbr: fp.pop_string(),
					};
					transitions.push(transition);
				}
				this.addTimeZone(name, transitions);
			});
		}
	}

	setTimeZone(zone) {	// Set the TimeZone we should convert everything to by its Olson name
		this.selectedZone = zone;
	}

	addTimeZone(name, transitions) {	// Add new TimeZone rules that we got from Whoville. Called by LDClient
		this.timeZones[name] = transitions;		// Add the new rules to the map. Don't assert incase we are asked to get them twice in quick succession
	}

	lowerMemberBound(array, name, value) {	// Find the first value NOT less than the value provided
		for (var i = 0; i < array.length; ++i) {	// Check the whole array linearly
			if (array[i][name] < value)				// If this value is less than the value provided
				continue;							// Keep looking
			return i;								// Return the first value that is equal or greater to
		}
		return array.length;
	}

	lowerValueBound(array, value) {		// Find the first value NOT less than the value provided
		for (var i = 0; i < array.length; ++i) {	// Check the whole array linearly
			if (array[i] < value)					// If this value is less than the value provided
				continue;							// Keep looking
			return i;								// Return the first value that is equal or greater to
		}
		return array.length;
	}

	toSelectedZone(date: Date) {					// Convert the Javascript Date's timestamp to the selected time zone
		var zone = this.timeZones[this.selectedZone];	// Get the TimeZone we are converting to
		if (zone === undefined)							// No time zone yet?
			return {seconds: date.getSeconds(),			// Don't die, return an object with no changes
					minutes: date.getMinutes(),
					hours: date.getHours(),
					weekday: date.getDay(),
					year: date.getFullYear(),
					month: date.getMonth(),
					date: date.getDate(),
					abbr: ''};

		var utc = Math.floor(date.getTime() / 1000);	// Get the UTC timestamp
		assert (utc >= this.daysSinceFirstYear.front() * 86400);					// First day we can convert (now: January 1, 1969)
		assert (utc <= (this.daysSinceFirstYear.back() + 365 + 31 + 28) * 86400);	// Last day we can convert (now: February 28, 2070)

		var transitionIndex = this.lowerMemberBound(zone, 'utcBack', utc);	// Find first transition that counts for this guy
		var transition = zone[transitionIndex];	// Convenience reference to the transition object
		if (transition === undefined) 			// No transition found. Only can happen on UTC, which has zero transitions
			return {seconds: date.getUTCSeconds(),
					minutes: date.getUTCMinutes(),
					hours: date.getUTCHours(),
					weekday: date.getUTCDay(),
					year: date.getUTCFullYear(),
					month: date.getUTCMonth(),
					date: date.getUTCDate(),
					offset: 0,
					abbr: 'GMT'};

		var	seconds		= utc + transition.offset;		// Adjust utc timestamp to local seconds
		var	justSeconds = seconds % 86400;				// Calculate the seconds left into the current day
		var days		= Math.floor(seconds / 86400);	// Local days since epoch

		var yearIndex = this.lowerValueBound(this.daysSinceFirstYear, days);	// Find how many days have occured since epoch at the start of the year
		if (this.daysSinceFirstYear[yearIndex] != days)							// If we aren't on the exact value
			--yearIndex;														// Get the first value LESS than our day count
		let dateDays = days;
		dateDays -= this.daysSinceFirstYear[yearIndex];		// Days now represents days so far in current year

		var daysArray = this.isLeapYear(yearIndex + this.firstYear) ? this.leapDaysSinceStartOfYear : this.nonLeapDaysSinceStartOfYear;	// Get appropriate monthly day count
		var daysIndex = this.lowerValueBound(daysArray, dateDays);	// Figure out which month we are in
		if (daysArray[daysIndex] != dateDays)						// If we aren't on the exact value
			--daysIndex;										// Get the first value LESS than our day count

		dateDays -= daysArray[daysIndex];	// Now holds days into month

		var output 		= {
			seconds: 	justSeconds % 60,					// Build an output object and store the seconds left in the past the minute. Note, modulo returns an Integer
			minutes: 	Math.floor(justSeconds / 60) % 60,	// Save minutes past the hour
			hours: 		Math.floor(justSeconds / 3600) % 24,// Save hours past midnight
			weekday: 	Math.floor(days + 4) % 7,			// Calculate day of the week. Remember, January 1, 1970 was a Thursday
			year:	 	yearIndex + this.firstYear,			// Save year (eg, 2016, 1978)
			month:		daysIndex,							// Save current month
			date:		dateDays + 1,						// Save current day of month (base 1)
			offset:		transition.offset,
			abbr:		transition.abbr
		}

		return output;					// Return the object
	}

	toSelected(date) {
		var o = this.toSelectedZone(date);
		var daysArray = this.isLeapYear(o.year) ? this.leapDaysSinceStartOfYear : this.nonLeapDaysSinceStartOfYear;			// Get appropriate day count
		return 			(this.daysSinceFirstYear[o.year - this.firstYear] + daysArray[o.month] + (o.date - 1)) * 86400 +	// Convert total days passed to seconds
						(o.hours * 3600) + (o.minutes * 60) + o.seconds;													// And add in hours, minutes, and seconds
	}

	toUTC(date) {	// Convert a JavaScript local date into a second-based UTC timestamp
		var zone = this.timeZones[this.selectedZone];	// Based on our current zone, get the transitions
		if (zone === undefined)							// No transitions?
			return date.getTime() / 1000;				// Return the simple timestamp from the date

		var year	= date.getFullYear();				// Four digit year
		var daysArray = this.isLeapYear(year) ? this.leapDaysSinceStartOfYear : this.nonLeapDaysSinceStartOfYear;	// Get appropriate day count
		var seconds = (this.daysSinceFirstYear[year - this.firstYear] + daysArray[date.getMonth()] + (date.getDate() - 1)) * 86400 +	// Convert total days passed to seconds
						(date.getHours() * 3600) + (date.getMinutes() * 60) + date.getSeconds();										// And add in hours, minutes, and seconds

		if (zone.length == 0)							// No transitions? Should only happen on UTC
			return seconds;								// Return the seconds count
		var transitionIndex = this.lowerMemberBound(zone, 'localBack', seconds);	// First first transition that applies
		return seconds - zone[transitionIndex].offset;								// Adjust to UTC time (could be forward or backward)
	}

	toLocal(utcDate: Date) {
		var zone = this.timeZones[this.selectedZone];	// Based on our current zone, get the transitions
		if (zone === undefined)							// No transitions?
			return utcDate.getTime() / 1000;				// Return the simple timestamp from the date

		var seconds = utcDate.getTime() / 1000

		if (zone.length == 0)							// No transitions? Should only happen on UTC
			return seconds;								// Return the seconds count
		var transitionIndex = this.lowerMemberBound(zone, 'localBack', seconds);	// First first transition that applies
		return seconds - zone[transitionIndex].offset;								// Adjust to UTC time (could be forward or backward)
	}

	isLeapYear(year)	{return (year % 4) == 0 && ((year % 100) != 0 || (year % 400) == 0);}	// Figure out if this is a leap year
};
