import { createElement, createUniqueId }    from '../elements';
import { Node, NodeFlags }      		    from '../node';
import assert                               from '../debug';
import owner                                from '../../owner';
import View                                 from './view';
import { TagConfigurationView }             from './tagConfigurationView';
import { SearchFilter, TagFilter }          from '../tagfilter';
import Dropdown                             from '../components/dropdown';
import ViewModal                            from '../viewmodal';
import Dialog                               from '../dialog';
import { Device, Driver, PotentialDataSource } from '../device';
import { DeviceConfigurationView }          from './devicesettingsview';
import { TagCard, FolderTagCard, SearchTagCard, WidgetCard, RangeWidgetTagCard, RangeTreeable, ExerciseWidgetTagCard, ExerciseTreeable, GenericTagCard } from '../components/tagcard';
import FrameParser                          from '../frameparser';
import './treeview.css';

import ArrowBackIcon                        from '../images/icons/arrow_back.svg';
import OptionsIcon                          from '../images/icons/more.svg';
import SearchIcon                           from '../images/icons/search.svg';
import DragIcon                             from '../images/icons/add_tag.svg';
import GlobeIcon                            from '../images/icons/globe.svg';
import fuzzysort                            from 'fuzzysort';
import { ConfiguredAlarm } from '../alarm';
import LiveDataClient from '../livedataclient';
import { LineGraph } from '../widgets/linegraph';
import { Widget } from '../widgets/lib/widget';
import { Tag } from '../widgets/lib/tag';

export enum TreeViewTypes {
    TVT_FINDER,
    TVT_SELECT,
    TVT_MULTISELECT,
    TVT_SELECT_ACCEPT,
    TVT_MULTISELECT_ACCEPT,
    TVT_SETTINGS
}

export enum TreeViewMode {
    TVM_LIST,
    TVM_ICON,
    TVM_TREE,
    TVM_SEARCH,
    TVM_DRAGDROP
}

export enum TransitionType {
    TT_NONE,
    TT_BACK,
    TT_FORWARD
}

export interface Treeable {
    name:           string;
    getDisplayName: (fUnits?: boolean, fPretty?: boolean)=>string;
    treeChildren:   Treeable[];
    parent?:        Treeable;
}


export class GenericTreeable implements Treeable {
    name: string;
    getDisplayName: (fUnits?: boolean | undefined, fPretty?: boolean | undefined) => string = () => this.name;
    treeChildren: Treeable[] = [];
    icon: string | undefined;
    parent: Treeable;
    treeWidgetCallback: ()=>HTMLElement;
    extendedWidgetCallback: ()=>HTMLElement;
    constructor(name: string, parent: Treeable, treeWidgetCallback: ()=>HTMLElement, extendedWidgetCallback: ()=>HTMLElement, icon?: string) {
        this.name = name;
        this.parent = parent;
        this.treeWidgetCallback = treeWidgetCallback;
        this.extendedWidgetCallback = extendedWidgetCallback;
        this.icon = icon;
    }

    buildExtendedWidget(): HTMLElement {
        return this.extendedWidgetCallback();
    }

    buildTreeWidget(): HTMLElement {
        return this.treeWidgetCallback();
    }

}

interface FilterGroup {
    orFilters: TagFilter[];
    andFilters: TagFilter[];
}

export interface InfoColumn {
    header: string;
    info: (tag: Treeable) => HTMLElement;
    width?: string;
}

export interface TreeViewProperties {
    type: TreeViewTypes;
    selectedTags?: Treeable[];
    selectCallback?: (tags: Treeable[]) => void;
    deselectCallback?: (tag: Treeable) => void;
    clickCallback?: (tag: Treeable) => void;
    acceptCallback?: (tags: Treeable[]) => void;
    selectFilters?: FilterGroup;
    displayFilters?: FilterGroup;
    searchFilters?: FilterGroup; // TODO: This should be just a copy of the selectfilters. This is hard.
    fDeviceBound?: boolean;
    folder?: Treeable;
    infoColumns?: InfoColumn[];
    recursiveOpen?: boolean;
    hideNav?: boolean;
    fPrettyNames?: boolean;
    supportsDragging?: boolean;
}

/**
 *
 * @param {DOM Element} parent
 * @param {Object} properties
 */
export default class TreeView extends View implements TreeViewProperties {
    type: TreeViewTypes;
    parent: HTMLElement;
    selectCallback?:    (tags: Treeable[]) => void;
    deselectCallback?:  (tag: Treeable) => void;
    clickCallback?:     (tag: Treeable) => void = ()=>{};
    fDeviceBound:       boolean;
    selectionMap:       Map<Treeable, TagCard | null> = new Map(); // map of our selected tags to their corresponding element
    tagMap:             Map<Treeable, TagCard> = new Map(); // map of each element to its corresponding TagCard
    currentWrapperItems:TagCard[];
    folder:             Treeable;
    wrapper:            HTMLElement;
    topContent:         HTMLElement;
    bottomContent:      HTMLElement;
    title:              HTMLElement;
    search:             HTMLInputElement;
    searchFilter:       SearchFilter;
    listWrapper:        HTMLElement;
    acceptButton:       HTMLButtonElement;
    addButton:          HTMLElement;
    selectedTags:       Treeable[] = [];
    entry:              Treeable; // save a copy of where we started just in case
    fInitialized:       boolean;
    searchTimeout:      NodeJS.Timeout;

    transitionCallback: (transitionEvent: TransitionEvent) => void;
    mode:               TreeViewMode;
    tagCards:           Set<TagCard> = new Set();
    fSearch:            boolean = false;
    fSmall:             boolean;
    currentWrapper:     HTMLElement;
    titleRow:           HTMLElement;
    backArrow:          HTMLElement;
    displayFilters:     FilterGroup = {
        andFilters: [],
        orFilters: []
    };
    selectFilters:      FilterGroup = {
        andFilters: [],
        orFilters: []
    };
    searchFilters:      FilterGroup = {
        andFilters: [],
        orFilters: []
    };
    infoColumns?: InfoColumn[];
    recursiveOpen?: boolean;
    searchWrapper: HTMLElement;
    searchContainer: HTMLElement;
    searchOptions: HTMLElement;
    navTop: HTMLElement;
    searchCache: Treeable[] | null = null;
    hideNav: boolean = false;
    fPrettyNames: boolean = false;
    supportsDragging?: boolean;
    acceptCallback?: (tags: Node[]) => void;
    moveListener   : (e: MouseEvent) => any;
    upListener     : (e: MouseEvent) => any;
    dragHoveredElement: Widget | null = null;
    dragIndicator: HTMLImageElement = createElement('img', 'tree-view__drag-indicator hide', document.body, '', {src: DragIcon});
    pathContainer: HTMLElement;
    workDir: string = '';
    backTitleWrapper: HTMLElement;
    currentTitleWrapper: HTMLElement
    backTitle: HTMLElement;
    currentTitle: HTMLElement;
    backButton: HTMLElement;
    constructor(properties: TreeViewProperties) {
        super();
        //Default Properties
        this.type                   = TreeViewTypes.TVT_SETTINGS;    // default is to show widgets for tags
        this.selectCallback         = () => {};
        this.deselectCallback       = () => {};
        this.clickCallback          = () => {};
        this.fDeviceBound           = true;                         // default is to limit tree to folder's device
        this.currentWrapperItems    = [];
        this.mode                   = TreeViewMode.TVM_TREE;
        // create a tag just to hold our devices
        this.folder                 = this.getDefaultFolder();

        // Copy properties over that can override the defaults above
        this.copy(properties);
        this.selectedTags.forEach(tag => this.selectionMap.set(tag, null));
    }

    getDefaultFolder(): Treeable {
        return {
            treeChildren: owner.sortedDevices.filter(device => device.key.includes(owner.menuPanel.getCompanyKey() == 'All' ? '' : owner.menuPanel.getCompanyKey())).map(device => device.tree.nodes[0]!),
            name: 'All Sites',
            getDisplayName: () => 'All Sites'
        };
    }

    initialize(parent: HTMLElement) {
        super.initialize(parent);
        this.fSmall             = window.innerWidth < 800;
        this.wrapper            = createElement('div', 'tag-viewer__wrapper', this.parent);          // content container
        let content             = createElement('div', 'tag-viewer__content', this.wrapper);          // content container
        this.pathContainer      = createElement('div', 'tag-viewer__content__path', content, 'testpath');
        this.topContent         = createElement('div', 'tag-viewer__content__top', content);

        this.bottomContent      = createElement('div', 'tag-viewer__content__bottom '  + ((this.type == TreeViewTypes.TVT_SELECT_ACCEPT) || (this.type == TreeViewTypes.TVT_MULTISELECT_ACCEPT) ? '' : 'hide'), content);
        this.acceptButton       = createElement('button', 'se-button', this.bottomContent, 'Accept', {disabled: true});
        this.acceptButton.onclick    = () => {
            this.acceptCallback && this.acceptCallback(Array.from(this.selectionMap.keys()).map(tag => tag as Node));
            this.modal && this.modal.destroy();
        }
        let nav                 = createElement('div', `tag-viewer__nav ${this.hideNav ? 'hide' : ''}`, this.topContent);
        this.navTop             = createElement('div', 'tag-viewer__nav__top', nav);
        this.backArrow          = createElement('img', 'tag-viewer__nav__back-icon', this.navTop, undefined, { src: ArrowBackIcon });
        this.titleRow           = createElement('div', 'tag-viewer__nav__top__title-row', this.navTop);
        this.backButton         = createElement('div', 'tag-viewer__nav__top__back-target', this.navTop)
        this.backButton.onclick = () => this.rebuild(this.folder.parent ?? this.entry, TransitionType.TT_BACK);
        let navBottom           = createElement('div', 'tag-viewer__nav__search', nav);
        this.searchContainer    = createElement('div',  'tag-viewer__search', navBottom);
        this.search             = createElement('input', 'tag-viewer__search__input', this.searchContainer, undefined, {'type':'text'});
        this.searchOptions      = createElement('div', 'tag-viewer__nav__search__options', navBottom);
        createElement('div', 'tag-viewer__nav__search__options__cancel', this.searchOptions, 'Cancel').onclick = () => this.clearSearch()
        createElement('img', 'tag-viewer__search__icon', this.searchContainer, undefined, {'src':SearchIcon});
        this.search.onfocus = () => {
            this.navTop.classList.add('tag-viewer__focus');
            this.searchContainer.classList.add('tag-viewer__focus');
            this.searchOptions.classList.add('tag-viewer__focus');
        }
        this.searchContainer.onclick = () => {
            this.search.focus();
        }
        this.search.oninput         = () => {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(()=>this.smartSearch(), 200);
        }
        this.search.onblur = () => {
            if (this.search.value.length == 0) {
                this.clearSearch();
                this.navTop.classList.remove('tag-viewer__focus');
                this.searchContainer.classList.remove('tag-viewer__focus');
                this.searchOptions.classList.remove('tag-viewer__focus');
            }
        }

        //let options         = createElement('img', 'tag-viewer__nav__search__add-button', navTop, '', {'src':OptionsIcon});

        let fFilters        = this.searchFilters.andFilters.some(filter => filter.fToggleable) || this.searchFilters.orFilters.some(filter => filter.fToggleable)
        if (fFilters) {
            createElement('div', 'tag-viewer__filter-list__title', nav, 'Filters:')
            let filterList      = createElement('div', 'tag-viewer__filter-list', nav);
            this.createFilterList(filterList);
        }
        let topWrapper      = createElement('div', 'tag-viewer__top-wrapper', this.topContent)
        if (this.infoColumns) {
            let headerWrapper   = createElement('div', 'tag-card__grid-wrapper', topWrapper);
            headerWrapper.style.gridTemplateColumns = 'minmax(240px, 100%)';
            headerWrapper.style.overflowY = 'scroll';
            createElement('div','tag-viewer__card-row__info', headerWrapper);
            this.infoColumns.forEach(column => {
                headerWrapper.style.gridTemplateColumns += ` ${column.width ?? '1fr'}`;
                createElement('div','tag-viewer__card-row__info', headerWrapper, column.header);
            })
        }

        let contentWrapper  = createElement('div', 'tag-viewer__content-wrapper', topWrapper);
        this.listWrapper    = createElement('div', 'tag-viewer__list-wrapper', contentWrapper);
        this.searchWrapper  = createElement('div', 'tag-viewer__list-container tag-viewer__search-container', this.listWrapper);
        this.currentWrapper = createElement('div', 'tag-viewer__list-container', this.listWrapper);

        this.buildTagList(this.currentWrapper, this.folder, this.recursiveOpen);
        this.backTitleWrapper = createElement('div', 'tag-viewer__nav__top__title', this.titleRow);
        this.backTitle  = createElement('div', 'tag-viewer__nav__top__title__text', this.backTitleWrapper);
        this.currentTitleWrapper = createElement('div', 'tag-viewer__nav__top__title', this.titleRow);
        this.currentTitle = createElement('div', 'tag-viewer__nav__top__title__text', this.currentTitleWrapper);
        this.currentTitleWrapper.style.color = 'var(--color-onSurface)';
        createElement('div', 'tag-viewer__nav__top__title__text', this.currentTitle, this.folder.getDisplayName())

        this.entry = this.folder; // TODO: right now if we switch from a big to small screen, or vice versa, we just start over. This could be better
        //@ts-ignore FIXME: treeables? yuck
        this.buildPath(this.pathContainer, this.folder)
        this.fInitialized = true;

        return this;
    }

    createFilterList(filterList: HTMLElement) {
        let filters = [...this.searchFilters.orFilters, ...this.searchFilters.andFilters]; // copy in all of our filters
        for (let i = 0; i < filters.length; i++) {
            if (filters[i].fToggleable) {
                let filterWrapper   = createElement('div', 'tag-viewer__filter-wrapper', filterList);                // wrapper for this filter
                filters[i].createToggle(filterWrapper, ()=>{
                    if (this.fSearch)
                        this.smartSearch(true)
                });
            }
        }
    }

    unregisterTagCard(tagCard: TagCard) {
        this.tagMap.delete(tagCard.tag);
    }

    toggleFilterList(dropIcon: HTMLImageElement, element: HTMLElement) { // toggles whether or not the element is collapsed. See collapse and expand methods in elements.js
        if (!element) return
        var isCollapsed = element.getAttribute('is-collapsed') === 'true';

        if (isCollapsed) {
            element.expand();
            if (dropIcon) {
                dropIcon.style.transform = 'rotate(0deg)';
            }
        } else {
            element.collapse();
            if (dropIcon) dropIcon.style.transform = 'rotate(-90deg)';
        }
    }

    filterDisplay(itemsToFilter? : TagCard[]) {
        let filteredItems: TagCard[] = itemsToFilter ?? Array.from(this.tagMap.values());                  // start with all our tags
        filteredItems.forEach(tagCard => tagCard.hide());    // hide all tags
        this.displayFilters.andFilters.forEach(andFilter => {                                              // for each filter
            filteredItems = filteredItems.filter(tagCard => andFilter.filter(tagCard.tag as Node))  // filter the array
        });
        if (this.displayFilters.orFilters.length > 0) {
            for (let i = filteredItems.length - 1; i >= 0; i--) { // reversed for loop so we only calculate the length once
                if (!this.displayFilters.orFilters.some(filter => filter.filter(filteredItems[i].tag as Node))) {// if we match any or filter
                    filteredItems.splice(i, 1);
                }
            }
        }
        filteredItems.forEach(tagCard => tagCard.show())
    }

    filterPass(item: Treeable, filters: FilterGroup) {
        let pass = true;                                        // will remain true if we match filters
        let andFilters = [...filters.andFilters.filter(filter => filter.fActive)];      // copy our array of andfilters
        if (andFilters.length > 0)
            pass = andFilters.every(andFilter => andFilter.filterOnly(item as Node));   // pass remains true if we pass all and filters
        if (pass && filters.orFilters.length > 0) {
            pass=filters.orFilters.some(orFilter => orFilter.filterOnly(item as Node)); // pass remains true if we pass any or filters
        }
        return pass;
    }

    smartSearch(fRecache: boolean = false) {
        let pattern = this.search.value;
        if (this.fSearch && this.search.value.length == 0 && this.search !== document.activeElement) { // We are already in the middle of a search
            this.clearSearch();
        }
        else {
            this.fSearch = true;
            this.searchWrapper.removeChildren();
            if (!this.searchCache || fRecache) {
                this.searchCache = [];
                let buildChildrenRecursively = (folder: Treeable, limit: number, index: number = 0) => {
                    //if (index >= limit)
                    //    return;
                    for (let i=0;i<folder.treeChildren.length;++i) {
                        let child = folder.treeChildren[i];
                        if (this.filterPass(child, this.searchFilters)) {
                            this.searchCache!.push(child);
                        }
                        buildChildrenRecursively(child, limit);
                    }
                }
                buildChildrenRecursively(this.folder, 1000);
            }
            let result = fuzzysort.go(pattern, this.searchCache, {
                key: 'absolutePath',
                threshold: -1000,
                limit: 200,
            });

            let childFolder = result.map(item => {return {obj: item.obj, score: Math.round(item.score), name: item.target}}).sort((d1, d2)=>(d1.score > d2.score) ? -1 : (d1.score == d2.score) ? d1.name.localeCompare(d2.name, undefined, {numeric: true}) : 1);
            let folder: Treeable = {

                treeChildren: childFolder.map(item => item.obj),
                getDisplayName: ()=>'Search',
                name: 'Search'
            }
            this.buildTagList(this.searchWrapper, folder);
            this.searchWrapper.style.zIndex = pattern === '' ? '0' : '2';
        }
    }
    /**
     * Find a tag card given a tag even if the tagcard is deeply nested. This method will open parent folders until it finds the tagcard
     *
     * @param {Treeable} tag
     * @return {*}  {(TagCard | undefined)}
     * @memberof TreeView
     */
    async findTagCard(tag: Treeable): Promise<TagCard | undefined> {
        if (!this.tagMap.has(tag) && tag.parent)
            await this.findFirstAvailableParent(tag.parent);
        return this.tagMap.get(tag);
    }

    /**
     * Recursive method used to find the first tagcard in the hierarchy of a tagcard's parent cards that is in the dom.
     *
     * @param {Treeable} parentTag
     * @param {Treeable[]} [parents=[]]
     * @memberof TreeView
     */
    async findFirstAvailableParent(parentTag: Treeable, parents: Treeable[] = []) {
        if (this.tagMap.has(parentTag)) {           // if we are in the map
            parents.unshift(parentTag);             // add us to the front of the array
            for (let i=0;i<parents.length;++i) {    // for each of the parent cards below us
                let parentCard = this.tagMap.get(parents[i]) as FolderTagCard;  // find us in the map (should always be in the map now)
                await parentCard.initialize().then(()=> {
                    if (!parentCard.fOpened)
                        parentCard.dropDown(false, false);
                });
            }
        }
        else if (parentTag.parent) {
            parents.unshift(parentTag);
            this.findFirstAvailableParent(parentTag.parent, parents);
        }
    }

    clearSearch() {
        this.search.value = '';
        this.searchWrapper.destroyWidgets(true);
        this.searchWrapper.removeChildren();
        this.navTop.classList.remove('tag-viewer__focus');
        this.searchContainer.classList.remove('tag-viewer__focus');
        this.searchOptions.classList.remove('tag-viewer__focus');
        this.fSearch = false;
        this.searchWrapper.style.zIndex = '0';
    }

    buildTagList(parent: HTMLElement, folder: Treeable, fRecursive?: boolean) {
        for (let i = 0; i < folder.treeChildren.length; i++) {
            let tag = folder.treeChildren[i];
            if ((tag instanceof Node && (tag.flags & NodeFlags.NF_TEMPORARY) != 0 && i!=folder.treeChildren.length - 1) || tag instanceof GenericTreeable && this.type != TreeViewTypes.TVT_FINDER) continue;
            assert(tag, 'attempted to build undefined child');
            let tagWrapper = createElement('div', 'tag-viewer__card-row');
            let tagCard: TagCard;
            if (tag instanceof Node) {
                if (((<Node>tag).flags & NodeFlags.NF_TEMPORARY) != 0)      // there is a chance we come across a temporary tag. Skip it.
                    continue;
                else {
                    if (TagFilter.isFolder(tag)) {
                        tagCard = this.fSearch ? new SearchTagCard(tagWrapper, tag, this) : new FolderTagCard(tagWrapper, tag, this, this.fPrettyNames);
                        if (fRecursive && tagCard instanceof FolderTagCard)
                            tagCard.dropDown(true, false);
                    }
                    else
                        tagCard = this.fSearch ? new SearchTagCard(tagWrapper, tag, this) : new WidgetCard(tagWrapper, <Node>tag, this, false, this.fPrettyNames);
                    if (this.type == TreeViewTypes.TVT_MULTISELECT || this.type == TreeViewTypes.TVT_SELECT || this.type == TreeViewTypes.TVT_MULTISELECT_ACCEPT || this.type == TreeViewTypes.TVT_SELECT_ACCEPT) {
                        if (this.selectedTags.includes(tag))
                            this.selectionMap.set(tag, tagCard);
                        if (this.filterPass(tag, this.selectFilters))
                            this.addCheckbox(tagCard);
                    }
                }
            }
            else if (tag instanceof ConfiguredAlarm) {
                tagCard = new TagCard(tagWrapper, tag, this);
            }
            else if (tag instanceof GenericTreeable) {
                tagCard = new GenericTagCard(tagWrapper, tag, this);
            }
            else {
                if (tag.treeChildren && tag.treeChildren.length > 0) {
                    tagCard = new FolderTagCard(tagWrapper, tag, this);
                    if (fRecursive && tagCard instanceof FolderTagCard)
                        tagCard.dropDown(true, false);
                }
                else if ('minNode' in tag)
                    tagCard = new RangeWidgetTagCard(tagWrapper, <RangeTreeable>tag, this);
                else if ('minScopeNode' in tag)
                    tagCard = new ExerciseWidgetTagCard(tagWrapper, <ExerciseTreeable>tag, this);
                else
                    tagCard = new TagCard(tagWrapper, tag, this);
            }

            tagCard.initialize();
            parent.appendChild(tagWrapper)
            tagWrapper.onmousedown = (e: MouseEvent) => {
                e.stopPropagation();
                this.upListener = this.onDragCancelled.bind(this, tagCard);
                document.addEventListener('mousemove', this.moveListener);
                document.addEventListener('mouseup', this.upListener, {once: true});
            }

            let longPressTimer: NodeJS.Timeout | undefined;
            tagWrapper.ontouchstart = (e) => {
                longPressTimer = setTimeout(()=> {
                    longPressTimer = undefined;
                    this.doubleClick(e, tagCard);
                }, 600);
            };

            let longPressCancel = () => clearTimeout(longPressTimer);
            tagWrapper.ontouchend = tagWrapper.ontouchcancel = tagWrapper.ontouchmove = longPressCancel;

            tagWrapper.ondblclick = (e) => this.doubleClick(e, tagCard);

            tagWrapper.oncontextmenu = (e: MouseEvent) => {
                e.preventDefault();
                e.stopImmediatePropagation();
            };
            tagCard.gridWrapper.style.gridTemplateColumns = 'minmax(240px, 100%)';
            if (!this.fSearch)
                this.tagMap.set(tagCard.tag, tagCard);
            this.infoColumns?.forEach(column => {
                let infoWrapper = createElement('div','tag-viewer__card-row__info', tagCard?.gridWrapper);
                infoWrapper.onmousedown = (e) => e.stopPropagation(); // Don't propagate the mousedown event to prevent search stealing our setpoint changes
                let infoElement = column.info(tag);
                infoWrapper.append(infoElement);
                tagCard!.gridWrapper.style.gridTemplateColumns += ` ${column.width ?? '1fr'}`;
            });
        }
        this.filterDisplay();
    }

    buildPath(pathContainer: HTMLElement, folder: Treeable) {
        pathContainer.removeChildren();
        let tag: Treeable | undefined = folder;

        while (tag !== undefined) {
            if (tag instanceof Node) {
                let localTag = tag;
                let link = createElement('div', 'tree-view__path__link', undefined, tag === tag.tree.nodes[0] ? tag.device.siteName : tag.name); //@ts-ignore
                link.onclick = () => {
                    this.rebuild(localTag, TransitionType.TT_NONE);
                }
                pathContainer.prepend(link);
                pathContainer.prepend(createElement('div', 'tree-view__path__separator', undefined, '/'));
            }
            tag = tag.parent;
        }
        let global = createElement('img', 'tree-view__path__globe tree-view__path__link', undefined, '', {src: GlobeIcon});
        global.onclick = () => this.rebuild(this.entry, TransitionType.TT_NONE);
        pathContainer.prepend(global);
    }

    addCheckbox(tagCard: TagCard) {
        let id                      = createUniqueId();
        let checkWrapper            = createElement('div', 'se-checkbox');
        tagCard.checkbox            = createElement('input', '', checkWrapper, '', {'type':'checkbox', 'id':id});
        tagCard.checkbox.checked    = this.selectionMap.has(<Node>tagCard.tag)
        createElement('label', '', checkWrapper, '', {'htmlFor': id});
        checkWrapper.onmousedown = (e) => e.stopPropagation();
        checkWrapper.onclick = (e) => {
            e.stopPropagation();
            if (!tagCard!.checkbox.checked) {	// If the user checks the checkbox then it gets added
                if (this.type == TreeViewTypes.TVT_SELECT || this.type == TreeViewTypes.TVT_SELECT_ACCEPT) { // If we only want a single select
                    this.selectionMap.forEach((selectedTagCard, selectedTag) => {     // Unselect all the selected tags (should just be one)
                        if (selectedTagCard)
                            selectedTagCard.checkbox.checked = false;
                        this.selectionMap.delete(selectedTag);
                        if (this.deselectCallback)
                            this.deselectCallback!(selectedTag);
                    });
                }
                tagCard!.checkbox.checked = true;
                this.selectionMap.set(tagCard.tag, tagCard!);
                if(this.selectCallback)
                    this.selectCallback([...this.selectionMap.keys()]) // pass an array of tags back to our select callback
            }
            else {
                tagCard!.checkbox.checked = false;
                this.selectionMap.forEach((value, key) => {
                    if (key == tagCard.tag) {
                        this.selectionMap.delete(key);
                        if (this.deselectCallback)
                            this.deselectCallback!(tagCard.tag);
                    }
                });
            }
            if (this.selectionMap.size > 0) {
                if (this.type == TreeViewTypes.TVT_SELECT_ACCEPT || this.type == TreeViewTypes.TVT_MULTISELECT_ACCEPT) {
                    this.acceptButton.disabled = false;
                    this.acceptButton.focus();
                }
            }
        }
        tagCard.checkWrapper.appendChild(checkWrapper);
    }

    clickTag(tag: Treeable, scrollIntoView: boolean = false) {
        this.findTagCard(tag).then(tagCard=> {
            this.selectCard(tagCard!);
            if (scrollIntoView)
                requestAnimationFrame(()=> {
                    tagCard?.element.scrollIntoView();
                })
        });
    }

    clearTagSelection() {
        for (let i=0;i<this.selectedTags.length;++i) {
            if (this.tagMap.has(this.selectedTags[i]))
                this.tagMap.get(this.selectedTags[i])!.checkbox!.checked = false;
        }
        this.selectedTags = [];
    }

    syncTagSelection(tags: Treeable[]) {

    }

    onDragCancelled(draggedTagCard: TagCard, e: MouseEvent) {
        document.removeEventListener('mousemove', this.moveListener);   // get rid of the old move listener
        document.removeEventListener('mouseup', this.upListener);       // get rid of the old up listener
        this.mouseUp(e, draggedTagCard);
    }

    mouseUp(e: MouseEvent, tagCard: TagCard) {
        e.stopPropagation();
        if (this.fSearch) {
            this.clearSearch();
            this.findTagCard(tagCard.tag).then(foundTagCard => {
                if (this.clickCallback && foundTagCard)
                this.clickCallback(foundTagCard!.tag);
                requestAnimationFrame(()=> {
                    foundTagCard?.element.scrollIntoView();
                })
                return;
            })
        }

        if (this.type === TreeViewTypes.TVT_FINDER) {
            for (let tag of this.selectedTags) {
                this.deselectTreeable(tag)
            }
            this.selectCard(tagCard);
            this.clickCallback!(tagCard.tag);
        } else if (tagCard instanceof FolderTagCard || tagCard instanceof GenericTagCard) {
            if (this.fSmall)
                this.rebuild(tagCard.tag, TransitionType.TT_FORWARD);
            else
                this.clickCallback!(tagCard.tag);
        }
    }

    doubleClick(e: Event, tagCard: TagCard) {
        e.stopPropagation(); // It's ok to be greedy here, there shouldn't be any more events after a double click
        this.folder = tagCard.tag;
        this.rebuild(tagCard.tag, TransitionType.TT_FORWARD);
    }

    selectCard(tagCard: TagCard) {
        this.selectionMap.forEach((selectedTagCard, tag) => {
            this.selectionMap.delete(tag);                                  // remove all selected items from our map
            selectedTagCard?.onDeselect();
        });

        this.selectionMap.set(tagCard.tag, tagCard);            // add our newly selected item to our map
        tagCard.onSelect();
    }

    syncSelection(selectedTreeables: Treeable[]) {
        this.selectionMap.forEach((selectedTagCard, tag) => {
            if (!selectedTreeables.includes(tag)) {
                this.selectionMap.delete(tag);                                  // remove all selected items from our map
                selectedTagCard?.onDeselect();
                if (selectedTagCard?.checkbox)
                    selectedTagCard.checkbox.checked = false;
            }
        });
        selectedTreeables.forEach(treeable => {
            if (this.tagMap.has(treeable))
                this.tagMap.get(treeable)?.onSelect();
        });
        this.selectedTags = selectedTreeables;
    }

    deselectTreeable(treeable: Treeable) {
        if (!this.selectionMap.has(treeable))
            return;
        let card = this.selectionMap.get(treeable);
        this.selectionMap.delete(treeable);
        card?.onDeselect();
    }

    rebuild(folder: Treeable, transition: TransitionType = TransitionType.TT_NONE) {
        this.search.value = ''; // make sure we clear the search so we don't continue filtering for a previously entered input
        this.searchCache = null;
        this.currentWrapperItems = [];
        let old = this.currentWrapper;
        this.currentWrapper = createElement('div', 'tag-viewer__list-container');
        this.buildTagList(this.currentWrapper, folder); // default is to show the tags as a list
        this.buildPath(this.pathContainer, folder);
        let parentName = '';
        if (folder === this.entry) {
            this.backArrow.style.opacity = '0';
            this.backButton.style.display = 'none';
        }
        else {
            parentName = folder.parent ? folder.parent.getDisplayName() : this.entry.getDisplayName();
            this.backButton.style.display = '';
            this.backArrow.style.opacity = '1';
        }
        parentName = parentName.length > 12 ? 'Back' : parentName;

        switch (transition) {
            case TransitionType.TT_NONE: // No animation needed, just set the text
                this.backTitleWrapper.style.transition = this.currentTitleWrapper.style.transition = 'none';
                this.backTitle.textContent = parentName;
                this.backTitleWrapper.style.width = this.backTitle.scrollWidth + 10 + 'px';
                this.backTitleWrapper.style.color = 'var(--color-primary)';
                this.currentTitle.textContent = folder.getDisplayName();
                old.remove();
                this.listWrapper.appendChild(this.currentWrapper);
                requestAnimationFrame(() => this.backTitleWrapper.style.transition = this.currentTitleWrapper.style.transition = '');
                break;
            case TransitionType.TT_BACK:
                let currentToBeRemoved = this.currentTitleWrapper;
                currentToBeRemoved.ontransitionend = () => currentToBeRemoved.remove();
                currentToBeRemoved.style.color = 'var(--color-transparent)';
                this.currentTitleWrapper = this.backTitleWrapper;
                this.currentTitle = this.backTitle;

                old.ontransitionend = () => old.remove();
                old.style.left = '100%';

                this.listWrapper.prepend(this.currentWrapper);

                this.backTitleWrapper = createElement('div', 'tag-viewer__nav__top__title', this.titleRow);
                this.backTitle = createElement('div', 'tag-viewer__nav__top__title__text', this.backTitleWrapper);
                this.backTitle.textContent = parentName;
                this.backTitleWrapper.style.transition = this.currentWrapper.style.transition =  'none';
                this.backTitleWrapper.style.width = this.backTitle.scrollWidth + 10 + 'px';
                this.currentWrapper.style.left = '-25%';
                requestAnimationFrame(() => {
                    this.backTitleWrapper.style.transition = this.currentWrapper.style.transition = '';
                    this.currentWrapper.style.left = '0px';
                    this.backTitleWrapper.style.color = 'var(--color-primary)';
                    this.currentTitleWrapper.style.width = '100%';
                    this.currentTitleWrapper.style.color = 'var(--color-onSurface)'
                    this.currentTitle.textContent = folder.getDisplayName();
                });
                break;

            case TransitionType.TT_FORWARD:
                let backToBeRemoved = this.backTitleWrapper;
                backToBeRemoved.ontransitionend = () => backToBeRemoved.remove();
                backToBeRemoved.style.color = 'var(--color-transparent)';
                this.backTitleWrapper = this.currentTitleWrapper;
                this.backTitle = this.currentTitle;
                this.backTitle.textContent = parentName;

                old.ontransitionend = () => old.remove();
                old.style.left = '-25%';

                this.listWrapper.appendChild(this.currentWrapper);

                this.currentTitleWrapper = createElement('div', 'tag-viewer__nav__top__title', this.titleRow);
                this.currentTitle = createElement('div', 'tag-viewer__nav__top__title__text', this.currentTitleWrapper);
                this.currentTitle.textContent = parentName;
                this.currentTitleWrapper.style.transition = this.currentWrapper.style.transition =  'none';
                this.currentTitleWrapper.style.width = '100%';
                this.currentTitle.textContent = folder.getDisplayName();
                this.currentWrapper.style.left = '100%';
                requestAnimationFrame(() => {
                    this.currentTitleWrapper.style.transition = this.currentWrapper.style.transition = '';
                    this.currentWrapper.style.left = '0px';
                    this.currentTitleWrapper.style.color = 'var(--color-onSurface)';
                    this.backTitleWrapper.style.color = 'var(--color-primary)'
                    this.backTitleWrapper.style.width = this.backTitle.scrollWidth + 10 + 'px';
                });
                break;
        }
        this.folder = folder;
    }

    resize() {
        if (this.fSmall == (window.innerWidth < 800))
            return;
        this.fSmall = window.innerWidth < 800;
        this.rebuild(this.folder, TransitionType.TT_NONE)
    }

    destroy() {
        if (!this.fInitialized)
            return;
        this.wrapper.removeChildren();
        this.parent.removeChild(this.wrapper);
        return;
    }
};
