import { Attribute } from "../../lib/attributes";
import { TagAttribute } from "../../lib/tag";
import { RegisterWidget, Widget } from "../../lib/widget";
import type { Tag, TagDefinition } from "../../lib/tag";
import template from './select.html';
import Icon from '../../../images/icons/list.svg';
import { MapInput } from "../../../views/attributeeditorview";

/**
 * Base Select class
 */
@RegisterWidget({tag: 'select-widget', template: template, displayName: 'Select'})
export class SelectWidget extends Widget {
    buttonLimit: number = 12;
    isOverLimit: boolean = false;
    _value: number;
    onchange = ()=>{};
    _disabled: boolean = false;
    protected select: HTMLSelectElement;
    protected optionMap: Map<number, HTMLOptionElement> = new Map();

    get disabled() {
        return this._disabled
    }

    set disabled(value: boolean) {
        this._disabled = value;
        this.shadowRoot?.querySelectorAll('option').forEach(inputElement => inputElement.disabled = this._disabled);
    }

    @Attribute({displayName: 'Label Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Option')
    }) valueMap: {value: number, text: string}[] = [
        {value: 1, text: 'One'},
        {value: 2, text: 'Two'},
        {value: 3, text: 'Three'}
    ];


    get value(): number {
        return this._value;
    }

    set value(v: number) {
        if (this.optionMap.has(v))
            this.select.value = v.toString();
        else
            return;
        this._value = v;
        this.onchange();
    }

    protected connectedCallback(): void {
        this.select = this.shadowRoot?.getElementById('selector') as HTMLSelectElement;
        for (let {value, text} of this.valueMap) {
            let option = document.createElement('option');
            option.value = value.toString();
            option.textContent = text;
            this.optionMap.set(value, option);
            this.select.appendChild(option);
        }
        this.select.onchange = () => this.value = parseInt(this.select.options[this.select.selectedIndex].value);
    }
}

@RegisterWidget({tag: 'tag-select', displayName: 'Select', section: 'Input', editor: true, icon: Icon})
export class TagSelect extends SelectWidget {
    @TagAttribute({
        displayName: 'Value Tag',
        supportedTypes: ['numeric'],
        requiredProperties: ['writeable'],
        shouldSubscribe: true
    }) valueTag: TagDefinition;

    enliven() {
        try {

            this.select.onchange = () => {
                let value = parseInt(this.select.options[this.select.selectedIndex].value);
                this.valueTag.tag.setPendingValue(value, this);
                this.value = value;
            }
            this.disabled = !this.valueTag.tag.isWriteable
        }
        catch(reason) {
            throw(new Error('Invalid Label Map: ' + reason));
        }
    }

    update(tag: Tag): void {
        this.value = tag.getValue(this);
    }
}

@RegisterWidget({tag: 'select-week-of-month', displayName: 'Week of Month Select', roles: ['NthWeek'], section: 'Setpoint'})
class WeekOfMonthSelect extends TagSelect {
    @Attribute({displayName: 'Label Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Option')
    }) valueMap: {value: number, text: string}[] = [
        {value: 1, text: 'First'},
        {value: 2, text: 'Second'},
        {value: 3, text: 'Third'},
        {value: 4, text: 'Fourth'},
        {value: 5, text: 'Fifth'},
        {value: 6, text: 'Sixth'},
        {value: 7, text: 'Seventh'},
        {value: 8, text: 'Eighth'}
    ];
}

@RegisterWidget({tag: 'select-month', displayName: 'Month Select', roles: ['ScheduleMonth'], section: 'Setpoint'})
class MonthSelect extends TagSelect {
    @Attribute({displayName: 'Label Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Option')
    }) valueMap: {value: number, text: string}[] = [
        {value: 1, text: 'January'},
        {value: 2, text: 'February'},
        {value: 3, text: 'March'},
        {value: 4, text: 'April'},
        {value: 5, text: 'May'},
        {value: 6, text: 'June'},
        {value: 7, text: 'July'},
        {value: 8, text: 'August'},
        {value: 9, text: 'September'},
        {value: 10, text: 'October'},
        {value: 11, text: 'November'},
        {value: 12, text: 'December'}
    ];
}


@RegisterWidget({tag: 'radio-button-kubota-sub-state', displayName: 'MBR Level State', roles: ['Kubota_MBR_SubState'], section: 'Kubota'})
class MBRSubStateSelect extends TagSelect {
    @Attribute({displayName: 'Label Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Option')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0,  text: 'Offline'},
        {value: 10, text: 'Low Flow'},
        {value: 11, text: 'Medium Flow'},
        {value: 12, text: 'High Flow'},
        {value: 13, text: 'Relax'},
        {value: 14, text: 'DifCln'},
        {value: 15, text: 'Relax - Waiting for UV'},
        {value: 20, text: 'Sleep'},
        {value: 21, text: 'Nap'},
        {value: 22, text: 'Alarm Nap'},
        {value: 30, text: 'CIP'},
        {value: 99, text: 'Waiting for Power'}
    ];
}

