import './pumpstation.css';
import View 							from './view';
import LiveDataClient 					from '../livedataclient';
import { Device } 						from '../device';
import { createElement } 				from '../elements';
import { Node, NodeFlags } 	from '../node';
import assert 							from '../debug';
import { GenericGraph } 				from '../graph';
import { PumpBank } 					from '../pumpbank';
import BarGraph 						from '../bargraph';
import { PumpTwins } from '../curves';
import { Role } from '../role';
import { RoleMap } from '../widgets/lib/createwidget';

export interface WellOverviewTank
{
	level			: Node;
	startRules?		: Node;
	lockoutRules?	: Node;
}

// PumpStation creates all of the visual elements to display a PumpStation on the web page.
export default class WellOverview extends View
{

	ldc   : LiveDataClient;
	device: Device;
	parent: HTMLElement;
	fAxis : boolean 			= true; // flag that determines whether we need to fully redraw the graph

	rootNode              : Node;
	firstAlternatorFolder : Node | null = null;
	secondAlternatorFolder: Node | null = null;

	pumpBanks  : PumpBank[] = [];
	gauges     : HTMLElement[] = [];
	tanks      : WellOverviewTank[] = [];

	parameterTable : HTMLElement;
	pumpBankWrapper: HTMLElement;
	graphRow       : HTMLElement;
	controlDiv     : HTMLElement;
	graphDiv       : HTMLElement;
	legendDiv      : HTMLElement;
	pumpTable      : HTMLElement;
	wetWellDiv     : HTMLElement;
	graph          : GenericGraph;
	alternators	   : Node[] = [];
	fPumpCurvesComplete: boolean = false;
    constructor(device: Device, ldc: LiveDataClient)
	{
		super();
		assert(device);
		this.device = device;
		this.ldc 	= ldc;
        assert (this.device.isTreeComplete(), 'Device must already have a complete node tree');
	};

	initialize(parent: HTMLElement): View
	{
		this.parent 				= parent;
		this.wrapper 				= createElement('div', 'overview__page-wrapper', this.parent) 									// horizontal flex wrapper
		var parameterWrapper		= createElement('div', 'overview__parameter-wrapper', this.wrapper)
		var parameterContainer		= createElement('div', 'overview__parameter-wrapper__value-container', parameterWrapper);
		this.parameterTable 		= createElement('div', 'overview__parameter-table', parameterContainer);
        var overviewWrapper 		= createElement('div', 'overview__overview-graph-wrapper', this.wrapper); 						// center div that holds the gauges and chart
		this.pumpBankWrapper 		= createElement('div', 'overview__pump-bank-wrapper', overviewWrapper);
        var overviewGraphContainer 	= createElement('div', 'overview__overview-graph-container', overviewWrapper); 					// graph flex wrapper
        this.graphRow				= createElement('div', 'overview__overview-graph-wrapper__graph-row', overviewGraphContainer);
        this.controlDiv				= createElement('div', 'overview__overview-graph-wrapper__graph-row__controls', this.graphRow);
        this.graphDiv				= createElement('div', 'overview__overview-graph-wrapper__graph-row__graph', this.graphRow);
        this.legendDiv				= createElement('div', 'overview__overview-graph-wrapper__graph-row__controls', this.graphRow);

		this.rootNode     = this.device.tree.nodes[0]!;
		this.alternators = this.rootNode.findByRole(Role.ROLE_ALTERNATOR_FOLDER);
		if(this.alternators.length == 0)
			this.alternators = this.rootNode.findByRole(Role.ROLE_TOP_LEVEL);
		this.firstAlternatorFolder = this.alternators[0];
		this.secondAlternatorFolder = this.alternators[1];

		for (let i = 0; i < this.alternators.length; ++i)
		{
			this.checkTankRules(this.alternators[i].findChildByRole(Role.ROLE_START_RULES_FOLDER));
			this.checkTankRules(this.alternators[i].findChildByRole(Role.ROLE_LOCKOUT_RULES_FOLDER));
			let barGraphs = this.alternators[i].findByRole(Role.ROLE_BAR_GRAPH);
			for(let j = 0; j < barGraphs.length; ++j)
			{
				let t: WellOverviewTank = {level: barGraphs[j]};
				this.tanks.push(t);
			}
		}
		this.pumpTable = createElement('div', 'overview__parameter-table', parameterContainer)

		let folder = [...this.alternators];
		let roleClasses = new Map([
			[Role.ROLE_TOTAL_FLOW, 'overview__gauge PumpStationFlow'],
			[Role.ROLE_DISCHARGE_PRESSURE, 'overview__gauge PumpStationDP'],
			[Role.ROLE_SUCTION_PRESSURE, 'overview__gauge PumpStationSP'],
			[Role.ROLE_CHLORINE_RESIDUAL, 'overview__gauge PumpStationChlorine']
		]);
		folder.forEach(node => {
			roleClasses.forEach((value, key) => {
				this.createGauge(this.parameterTable, value, node?.findChildByRole(key) ?? null)
			});
		})
		let otherGauges = this.rootNode.findByRole(Role.ROLE_OVERVIEW_GAUGE);
		for (let gauge of otherGauges)
			createElement('tag-badge', 'overview__gauge', this.parameterTable, '', {statusTag: {tag: gauge}, showUnits: true});

		if (this.gauges.length == 0)
			parameterContainer.classList.add('hide');

		this.pumpTable  = createElement('div', 'overview__parameter-table', parameterContainer)
		this.wetWellDiv = createElement('div', 'lift-station__parameter-wrapper__wet-well-container', parameterWrapper);  // bargraph for liftstation wet well
		if (this.tanks.length == 0) {
			this.wetWellDiv.classList.add('hide');
			if (this.gauges.length == 0)
				parameterWrapper.classList.add('hide');
		}

		this.device.requestPumpTwins(this);
		this.fInitialized = true;

		return this;
	};

	createGauge(parent: HTMLElement, className: string, node: Node | null) {
		if (!node)
			return;
		this.gauges.push(createElement('tag-badge', className, parent, '', {statusTag: {tag: node}, showUnits: true}));
	}

	onPumpTwinsComplete(pumpTwins: PumpTwins) {
		this.fPumpCurvesComplete = true;
		this.pumpBanks = [];
		this.createPumpBanks(this.alternators.length);
		this.createSimpleGraph();
		this.resize();				// Create and size the initial tanks
	}

	createPumpBanks(numAlternators: number): void
	{
		let fLandscape = window.innerHeight < 420;

		if (numAlternators === 1)
		{
			assert(this.secondAlternatorFolder === undefined, 'secondAlternatorFolder should be undefined');
			if (this.firstAlternatorFolder?.roles.has(Role.ROLE_ALTERNATOR_FOLDER) || (this.firstAlternatorFolder?.roles.has(Role.ROLE_TOP_LEVEL) && this.firstAlternatorFolder.findByRole(Role.ROLE_PUMP).length > 0))
				this.pumpBanks.push(new PumpBank(this.firstAlternatorFolder, fLandscape ? this.pumpTable : this.pumpBankWrapper));
		}
		else if (numAlternators > 1)
		{
			this.pumpBanks.push(new PumpBank(this.firstAlternatorFolder!, fLandscape? this.pumpTable : this.pumpBankWrapper));
			this.pumpBanks.push(new PumpBank(this.secondAlternatorFolder!, fLandscape? this.pumpTable : this.pumpBankWrapper, undefined, this.firstAlternatorFolder?.findByRole(Role.ROLE_PUMP).length));
		}
		if(this.pumpBanks.length == 0)
			this.pumpBankWrapper.classList.add('hide');
	}

    resize(): void
	{
		if (!this.fInitialized || !this.fPumpCurvesComplete)
			return;
		this.wetWellDiv.destroyWidgets(true);
		this.wetWellDiv.removeChildren();

		for (let i = 0; i < this.pumpBanks.length; ++i)
			this.pumpBanks[i].resize();

		let tankWrappers: HTMLElement[] = [];
		for (let i = 0; i < this.tanks.length; ++i) {
			let element = createElement('div', 'lift-station__parameter-wrapper__tank-container__tanks', this.wetWellDiv);
			tankWrappers.push(element);
		}
		for(let i = 0; i < this.tanks.length; ++i) {
			this.createTank(tankWrappers[i], 'darkblue', this.tanks[i].startRules, this.tanks[i].lockoutRules, this.tanks[i].level);
		}
		this.createSimpleGraph();
    };

    createSimpleGraph(): void
	{
        var end       = new Date();                        // Current time
        var endTime   = end.getTime();
        var start     = new Date(endTime - 1000*3600*24);  // Default to the last 24 hours
        var startTime = start.getTime();

		if (this.graph && this.graph.graph)
		{
			this.graph.resize(this.graphDiv.clientWidth, this.graphDiv.clientHeight);
			//@ts-ignore
			this.graph._updateTimer();
			//@ts-ignore
			return;
		}
		// "wall to wall" graph on mobile widths

		this.graph = new GenericGraph(this.ldc, this.graphDiv, this.graphDiv.clientWidth, this.graphDiv.clientHeight, start, end, false, this.controlDiv, this.legendDiv);	// Create the graph that takes care of the hard stuff for us
		this.graph.makeInteractive(true);	// Let them pan and zoom and such
		this.graph.createLegend();			// Make a legend happen
		this.graph.createDateSelection();	// Give them buttons that change the range of data presented
		this.graph.axesCanChange();			// Axis can change between attached nodes

		let gstLevel   = (this.secondAlternatorFolder || this.firstAlternatorFolder)?.findChildByRole(Role.ROLE_SOURCE_TANK_LEVEL);
		let estLevel   = (this.secondAlternatorFolder || this.firstAlternatorFolder)?.findChildByRole(Role.ROLE_TARGET_TANK_LEVEL);
		let bDischarge = (this.secondAlternatorFolder || this.firstAlternatorFolder)?.findChildByRole(Role.ROLE_DISCHARGE_PRESSURE);
		let sPressure = (this.secondAlternatorFolder || this.firstAlternatorFolder)?.findChildByRole(Role.ROLE_SUCTION_PRESSURE);
		let flow = (this.secondAlternatorFolder || this.firstAlternatorFolder)?.findChildByRole(Role.ROLE_TOTAL_FLOW);

		if(gstLevel)
			this.graph.addNode(gstLevel, true, gstLevel.name, "red", true, gstLevel.engMax, gstLevel.engMin);
		if(estLevel)
			this.graph.addNode(estLevel, true, estLevel.name, "green", true, estLevel.engMax, estLevel.engMin);
		if(bDischarge)
			this.graph.addNode(bDischarge, true, bDischarge.name, "purple", true, bDischarge.engMax, bDischarge.engMin);
		if(sPressure)
			this.graph.addNode(sPressure, true, sPressure.name, "black", true, sPressure.engMax, sPressure.engMin);
		if(flow)
			this.graph.addNode(flow, true, flow.name, "blue", true, flow.engMax, flow.engMin);
		for(let i = 0; i < this.tanks.length; ++i)
			this.graph.addNode(this.tanks[i].level, true, this.tanks[i].level.name, '', true, this.tanks[i].level.engMax, this.tanks[i].level.engMin);

		let superSpecials = this.rootNode.findByRole(Role.ROLE_GRAPH_VALUE);
		for(let i = 0; i < superSpecials.length; ++i)
			this.graph.addNode(superSpecials[i], true, superSpecials[i].name, undefined, true, superSpecials[i].engMax, superSpecials[i].engMin);

		let runningNodes: Node[] = [];
		for(let i = 0; i < this.alternators.length; ++i)
			runningNodes.push(...this.alternators[i].findByRole(Role.ROLE_BOOL_RUNNING));

		if (runningNodes.length > 0)
			this.graph.createBooleanFill(runningNodes, true);

		this.graph.requestDataForAllDevices(startTime, endTime);
    }

	destroy(): void
	{
		this.parent.destroyWidgets(true);  // First, detach all LiveData nodes from all DOM elements
		this.parent.removeChildren();      // Second, delete all child DOM elements
	};

	createTank(parent: HTMLElement, color: string, startRules: Node | undefined, lockoutRules: Node | undefined, node: Node): void
	{
		let tankWrapper = createElement('div', 'lift-station__parameter-wrapper__tank-container__tanks__tank', parent);

		// Create the tank label (use node name if node has no description):
		let label = node.getDisplayName();
		let units = node.getUnitsText()!;
		if (units.length > 0)	// node has units:
			label += ' (' + units + ')';
		createElement('div', 'tank-label', parent, label);

		// Create the bar graph:
		let graph = new BarGraph(tankWrapper, {
			fBackgroundGradient: false,
			fBarGradient       : false,
			node               : node,
			width              : 30,
			height             : tankWrapper.clientHeight,
			fVertical          : true,
			minMaxPosition     : 'right',
			scalePosition      : 'left',
			color              : color});

		graph.addShuttle(node, 'left')	// add shuttle on left side of bar

		if(startRules)
		{
			let startLevels = startRules.findByRole(Role.ROLE_START_LEVEL);
			let stopLevels  = startRules.findByRole(Role.ROLE_STOP_LEVEL);
			graph.addShuttleSet(startLevels, 'left', true, 'Start', 'blueShuttle')		// add shuttles for start/stop levels
			graph.addShuttleSet(stopLevels, 'right', true, 'Stop', 'grayShuttle')

		}
		if(lockoutRules)
		{
			let lockoutLevels = lockoutRules.findByRole(Role.ROLE_START_LEVEL);
			let resetLevels   = lockoutRules.findByRole(Role.ROLE_STOP_LEVEL);
			graph.addShuttleSet(lockoutLevels, 'left', true, 'Lockout', 'amberShuttle')		// add shuttles for lockout/reset levels
			graph.addShuttleSet(resetLevels, 'right', true, 'Reset', 'dGrayShuttle')
		}
		graph.initialize();


	}

	checkTankRules(rulesFolder : Node | null): void
	{
		if(rulesFolder)
		{
			let level = rulesFolder.findChildByRole(Role.ROLE_PSC_CONTROLLING_LEVEL)!;
			var found = this.tanks.find(t => t.level.sourceID === level.sourceID);
			if(found)
				this.setFolder(found, rulesFolder);
			else
			{
				let t: WellOverviewTank = {level: level};
				this.setFolder(t, rulesFolder);
				this.tanks.push(t);
			}
		}
	}

	setFolder(tank : WellOverviewTank, folder : Node): void
	{
		if(folder.roles.has(Role.ROLE_START_RULES_FOLDER))
		{
			assert(tank.startRules === undefined);
			tank.startRules = folder;
		}
		else
		{
			assert(folder.roles.has(Role.ROLE_LOCKOUT_RULES_FOLDER), "What did you just give me?");
			assert(tank.lockoutRules === undefined);
			tank.lockoutRules = folder;
		}
	}

};

