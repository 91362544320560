import { RateMap, TagUnit, convert } from "./widgets/lib/tagunits";
import { Tag } from './widgets/lib/tag'
import LiveDataClient, { LDCResponse } from "./livedataclient";
import FrameMaker from "./framemaker";
import LiveData from "./livedata";
import FrameParser from "./frameparser";
import { NodeFlags, NodeOperand } from "./node";
import assert from "./debug";
import { Role } from "./role";
import User from "./user";

interface DataRequest {
    start: Date;
    end: Date;
    tags: Tag[];
    interval: DataInterval;
}

export interface TagData  {
    name: string;
    path: string;
    start: number;
    end: number;
    node: Tag;
    fMax: boolean;
    _start?: number;
    _end?: number;
}

interface DataCache {
    start: Date;
    end: Date;
    dataIndex: number;
}

interface Crate {
    cn: number;
    time: number;
    size: number;
    interval: DataInterval;
    resolution: number;
    boxTime: number;
    n: number;
    min: number;
    max: number;
    avg: number;
    conversion: number;
}

export enum DataType {
	MIN = 0,
	AVG = 1,
	MAX = 2
}

export type HistoricalData = [string[], ...Array<number[]>];

export type DataInterval = 1 | 10 | 60 | 600 | 3600 | 21600 | 86400;

export const Intervals: DataInterval[] = [1, 10, 60, 600, 3600, 21600, 86400];

export function CalculateIntervalFromDates(start: Date, end: Date, maxPoints: number = 1000): DataInterval {
    let seconds = (end.getTime() - start.getTime()) / 1000;			// Number of seconds we are currently viewing
    return CalculateInterval(seconds, maxPoints);
}

export function CalculateInterval(seconds: number, maxPoints: number = 1000): DataInterval {
    for (let index = Intervals.length - 1; index >= 0; --index) {
        let points = seconds / Intervals[index];// Number of data points displayed should we choose this interval
        if (points > maxPoints)					// Select the first interval that will load up fewer than x000 data points
            return Intervals[index];
    }
    return Intervals.front();
}

/**
 * The Cruncher class abstracts the data manipulation required to perform operations on time-series data
 * @param  {number[][]} data
 */
export default class Cruncher {
    callbacks:          { (data: HistoricalData) : void }[] = [];
    crateMap: Map<Tag, Crate> = new Map();
    outstandingRequests: Set<LDCResponse> = new Set();
    private cachedData: Map<DataInterval, Map<Tag, DataCache>> = new Map();
    private data: HistoricalData = [[]];
    constructor() {
        for (let interval of Intervals) {
            this.cachedData.set(interval, new Map());
        }
    }

    getAverage(start: Date, end: Date, tag: Tag, interval: DataInterval, callback: (value: number, confidence: number) => void) {
        this.requestData(start, end, [tag], interval).then(([tags, data]) => {
            this._getTrimmedData(start, end, data);
            let confidence = this.getConfidence(data);
            let value       = 0;
            let nullDeltas  = 0;
            let dT          = 0;
            for (let i=1;i<data[1].length;++i) {
                if (data[3][i-1] == null) {
                    nullDeltas += data[1][i] - data[1][i - 1]
                    continue;
                }
                value   += data[3][i - 1] * (data[1][i] - data[1][i - 1]);
                dT      += data[1][i] - data[1][i - 1]
            }
            value = value / dT;
            callback(value, confidence);
        });
    }

    getMinimum(start: Date, end: Date, tag: Tag, interval: DataInterval): Promise<[number, number]> {
        return new Promise(resolve => {
            this.requestData(start, end, [tag], interval).then(([tags, data]) => {
                this._getTrimmedData(start, end, data);
                let confidence = this.getConfidence(data);
                resolve([Math.min(...data[2]), confidence]);
            })
        })
    }

    getMaximum(start: Date, end: Date, tag: Tag, interval: DataInterval, callback: (value: number, confidence: number) => void) {
        this.requestData(start, end, [tag], interval).then(([tags, data]) => {
            this._getTrimmedData(start, end, data);
            let confidence = this.getConfidence(data);
            callback(Math.max(...data[4]), confidence);
        });
    }

    getTotalFromRate(start: Date, end: Date, tag: Tag, interval: DataInterval, callback: (value: number, confidence: number) => void) {
        if (!RateMap.has(tag.units))
            return;
        this.requestData(start, end, [tag], interval).then(([tags, data]) => {
            this._getTrimmedData(start, end, data);
            let confidence = this.getConfidence(data);
            let value       = 0;
            let nullDeltas  = 0;
            let dT          = 0;
            for (let i=1;i<data[1].length;++i) {
                if (data[3][i-1] == null) {
                    nullDeltas += data[1][i] - data[1][i - 1]
                    continue;
                }
                value   += data[3][i - 1] * (data[1][i] - data[1][i - 1]);
                dT      += data[1][i] - data[1][i - 1]
            }
            let total = value / dT * convert(dT / 1000, TagUnit.TU_SECONDS, RateMap.get(tag.units)!.time);
            callback(total, confidence);
        });
    }

    getData(start: Date, end: Date, tags: Tag[], interval: DataInterval, callback: (data: HistoricalData) => void) {
        this.requestData(start, end, tags, interval).then(([tags, data]) => {
            callback(data);
        });
    }

    getTrimmedData(start: Date, end: Date, tags: Tag[], interval: DataInterval): Promise<HistoricalData> {
        start = new Date(Math.floor(start.getTime() / 1000 / interval) * interval * 1000);
        end = new Date(Math.floor(end.getTime() / 1000 / interval) * interval * 1000);
        return new Promise<HistoricalData>((resolve, reject) => {
            this.requestData(start, end, tags, interval).then(([tags, data]) => {
                this._getTrimmedData(start, end, data);
                resolve(data);
            })
        })
    }

    getFormattedData(start: Date, end: Date, tags: Tag[], interval: DataInterval): Promise<HistoricalData> {
        start = new Date(Math.floor(start.getTime() / 1000 / interval) * interval * 1000);
        end = new Date(Math.floor(end.getTime() / 1000 / interval) * interval * 1000);
        return new Promise<HistoricalData>((resolve, reject) => {
            this.requestData(start, end, tags, interval).then(([orderedTags, data]) => {
                this._getTrimmedData(start, end, data);
                let formattedData: HistoricalData = [data[0]];
                for (let i=1;i<data.length;i+=4) {
                    let nodeIndex = (i-1) / 4;
                    let tag = orderedTags[nodeIndex];
                    formattedData.push([this.getFormattedValue(data[i][0] as number, tag)],[this.getFormattedValue(data[i+1][0] as number, tag)],[this.getFormattedValue(data[i+2][0] as number, tag)],[this.getFormattedValue(data[i+3][0] as number, tag)]);
                    for (let j=1;j<data[i].length;++j) {
                        let deltaT          = data[i][j] as number - (data[i][j-1] as number);
                        let intervalCount   = deltaT / (interval * 1000) - 1; // @ts-ignore
                        formattedData[i].push(...Array(intervalCount).fill(null).map((_, k)=>data[i][j-1] + (k+1)*interval*1000), data[i][j]); // @ts-ignore
                        formattedData[i+1].push(...Array(intervalCount).fill(this.getFormattedValue(data[i+1][j-1] as number, tag)), this.getFormattedValue(data[i+1][j] as number, tag));// @ts-ignore
                        formattedData[i+2].push(...Array(intervalCount).fill(this.getFormattedValue(data[i+2][j-1] as number, tag)), this.getFormattedValue(data[i+2][j] as number, tag));// @ts-ignore
                        formattedData[i+3].push(...Array(intervalCount).fill(this.getFormattedValue(data[i+3][j-1] as number, tag)), this.getFormattedValue(data[i+3][j] as number, tag));
                    }
                }
                resolve(formattedData);
            }).catch(reason => reject(reason));
        })
    }

    getFormattedValue(value: number | null, node: Tag) {
        if (value === null)
            return NaN;
        return parseFloat(node.getFormattedTextFromValue(value, false));
    }

    getConfidence(data: HistoricalData) {
        let nullDeltas = 0;
        for (let i=1;i<data[1].length;++i) {
            if (data[3][i-1] == null)
                nullDeltas += data[1][i] - data[1][i - 1]
        }
        return 1 - (nullDeltas / (data[1][data[1].length - 1] - data[1][0]))
    }

    getIntervalData(start: Date, end: Date, tags: Tag[], interval: DataInterval, callback: (data: HistoricalData) => void) {
        start = new Date(Math.floor(start.getTime() / 1000 / interval) * interval * 1000);
        end = new Date(Math.floor(end.getTime() / 1000 / interval) * interval * 1000);
        this.callbacks.push((data)=> {
            let intervalData: HistoricalData = [data[0]];           // start with our name array and an empty timestamp array
            for (let i=1;i<data.length;i+=4) {
                intervalData.push([data[i][0] as number],[data[i+1][0] as number],[data[i+2][0] as number],[data[i+3][0] as number]);
                for (let j=1;j<data[i].length;++j) {
                    let deltaT          = data[i][j] as number - (data[i][j-1] as number);
                    let intervalCount   = deltaT / (interval * 1000); // @ts-ignore
                    intervalData[i].push(...Array(intervalCount).fill(null).map((_, k)=>data[i][j-1] + (k+1)*interval*1000)); // @ts-ignore
                    intervalData[i+1].push(...Array(intervalCount).fill(data[i+1][j] as number));// @ts-ignore
                    intervalData[i+2].push(...Array(intervalCount).fill(data[i+2][j] as number));// @ts-ignore
                    intervalData[i+3].push(...Array(intervalCount).fill(data[i+3][j] as number));
                }
            }
            callback(intervalData);
        })
        this.requestData(start, end, tags, interval);
    }


    /**
     * Trim the input data to the requested time period
     * @param  {Date} start
     * @param  {Date} end
     * @param  {number} interval
     * @param  {number[][]} data
     */
    _getTrimmedData(start: Date, end: Date, data: HistoricalData) {
        for (let i=1;i<data.length;i+=4) {
            let startIndex  = 0;
            start.setSeconds(0, 0);
            end.setSeconds(0, 0);
            let startTime   = start.getTime();
            let endTime     = end.getTime();
            for (startIndex;startIndex<data[i].length - 1;) {   // iterate over our timestamps
                if (data[i][startIndex + 1] as number > startTime)         // if our timestamp is in our range of interest - break
                    break;
                startIndex++
            }
            let endIndex = data[i].length - 1;
            for (endIndex;endIndex>0;--endIndex) {
                if (data[i][endIndex] as number < endTime)
                    break;
            }
            let validPoints = endIndex - startIndex;
            let endFluff    = data[i].length - validPoints - startIndex;
            let startPoint  = {
                min:        data[i+1][startIndex] as number,
                average:    data[i+2][startIndex] as number,
                max:        data[i+3][startIndex] as number
            }
            let endPoint    = {
                min:        data[i+1][endIndex] as number,
                average:    data[i+2][endIndex] as number,
                max:        data[i+3][endIndex] as number
            }
            for (let j=i;j<i+4;j+=4) {
                data[j].splice(0, startIndex + 1, startTime); // remove the fluff at the beginning of the array
                data[j+1].splice(0, startIndex + 1, startPoint.min);
                data[j+2].splice(0, startIndex + 1, startPoint.average);
                data[j+3].splice(0, startIndex + 1, startPoint.max);

                data[j].splice(validPoints, endFluff + 1, endTime); // remove the fluff at the end of the array
                data[j+1].splice(validPoints, endFluff + 1, endPoint.min);
                data[j+2].splice(validPoints, endFluff + 1, endPoint.average);
                data[j+3].splice(validPoints, endFluff + 1, endPoint.max);
            }
        }
    }

    requestData(start: Date, end: Date, tags: Tag[], interval: DataInterval): Promise<[Tag[], HistoricalData]> {
        let deviceTagMap = new Map();
        tags.forEach(tag => {
            let device = tag.device;
            if (!deviceTagMap.has(device))
                deviceTagMap.set(device, [{node: tag, path: tag.deviceRelativePath}]);
            else
                deviceTagMap.get(device).push({node: tag, path: tag.deviceRelativePath});
        });
        let promises: Promise<[Tag[], HistoricalData]>[] = [];
        for (let [device, deviceTags] of deviceTagMap) {
            let starts          = new Array(deviceTags.length);
            let ends            = new Array(deviceTags.length);
            starts.fill(start.getTime() / 1000)
            ends.fill(end.getTime() / 1000);
            promises.push(this.requestHistoricalData(device.id, interval, starts, ends, deviceTags, true));
        }
        return new Promise((resolve, reject) => {
            Promise.all(promises).then((tagsAndData) => {
                let orderedTags: Tag[] = [];
                let combinedData: HistoricalData = [[]];
                tagsAndData.forEach(tagAndData => {
                    orderedTags.push(...tagAndData[0])
                    combinedData[0]!.push(...tagAndData[1][0])
                    combinedData.push(...tagAndData[1].slice(1));
                })
                resolve([tags, combinedData]);
            }, (reason) => reject(reason));
        });
    }

    _getNodeFromAbsolutePath(nodeName: string): Tag {
        let slashIndex  = nodeName.indexOf('/');
        let key         = nodeName.substring(0, nodeName.indexOf('/'));
        let path        = nodeName.substring(slashIndex);
        let dev         = User.devices.getByKey(key);
        let Tag        = dev?.tree.findNode(path)!;
        return Tag;
    }

    requestHistoricalData(deviceID: number, interval: DataInterval, starts: number[], ends: number[], graphNodes: TagData[], fNewCrates: boolean): Promise<[Tag[], HistoricalData]> {
        if (!LiveDataClient.isLoggedIn())
			return new Promise<[Tag[], HistoricalData]>((resolve, reject) => {
                reject("Live Data Client not logged in");
            });

		assert(interval > 0, "Invalid historical request data.");
		assert(graphNodes.length === starts.length, "Invalid node count.");
		assert(graphNodes.length === ends.length, "Invalid node count.");

		if (fNewCrates) // Filter out any cached crates for this device
            this.crateMap = new Map<Tag, Crate>([...this.crateMap.entries()].filter(([tag, crate]) => tag.device.id !== deviceID));
		let ignoreCrates: boolean[] = [];
        let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_GRAPH_QUERY, deviceID);
		fm.push_u32(interval);	// Append interval between data
		var count = 0;
		var countOffset = fm.getPosition();
		fm.push_u16(count);	// Place holder for the amount of tags we are requesting
        let tags: Tag[] = [];
		for (var i = 0; i < graphNodes.length; ++i) {
            tags.push(graphNodes[i].node)
			if (graphNodes[i].node.flags & NodeFlags.NF_DERIVED) {
				let ops = graphNodes[i].node.operations;
				graphNodes[i]._start = starts[i];
				graphNodes[i]._end = ends[i];

				for (var j = 0; j < ops.length; ++j) {
					if (ops[j].node !== undefined) {
						++count;
						fm.push_u32(starts[i]);	// Append interval start time
						fm.push_u32(ends[i]);		// Append interval end time
						var node = graphNodes[i].node.device.tree.getNode(ops[j].node!)!;
						fm.push_string(node.deviceRelativePath);	// Append each node's full path name
						fm.push_u64(0);			// No old crates allowed
						fm.push_u16(0);
						ignoreCrates.push(true);
					}
				}
			} else {
				++count;
				fm.push_u32(starts[i]);		// Append interval start time
				fm.push_u32(ends[i]);			// Append interval end time
				fm.push_string(graphNodes[i].node.deviceRelativePath); // Append each node's full path name
				var oldCrate = this.crateMap.get(graphNodes[i].node);
				fm.push_u64(oldCrate ? oldCrate.cn : 0);
				fm.push_u16(oldCrate ? oldCrate.size : 0);
				ignoreCrates.push(false);
			}
		}
		var lastPosition = fm.getPosition();	// Record frame so we can zoom back to the end
		fm.setPosition(countOffset);			// Move back to where the count lives
		fm.push_u16(count);					// Update the count
		fm.setPosition(lastPosition);			// Go back to the end of the frame
        return new Promise((resolve, reject) => {
            let request = LiveDataClient.sendRequest(fm)
            this.outstandingRequests.add(request);
            request.then((fp: FrameParser) => {
                resolve([tags, this.parseResponseFrame(fp, graphNodes, ignoreCrates)]);
            }).catch(reason => {
                
            }).finally(() => this.outstandingRequests.delete(request));
        })
	}

    abortAllRequests() {
        this.outstandingRequests.forEach(request => request.cancel());
        this.outstandingRequests.clear();
    }

    parseResponseFrame(fp: FrameParser, graphNodes: TagData[], ignoreCrates: boolean[]): HistoricalData {
        let interval = fp.pop_u32() as DataInterval;	// Interval between data points
        let count = fp.pop_u16();	// Number of tags requested
        let dev = User.devices.get(fp.wv_id)!;

        // Extract all of the node names (in the order data is attached for them) followed by the data
        let data: HistoricalData = [[]];		// Empty array to start with (with empty label array in the first index)
        for (let i = 0; i < count; ++i) {	// For each node
            assert(fp.is_string_next(), "String was not next while extracting node names");
            let deviceRelativePath = fp.pop_string();
            let name = dev.key + deviceRelativePath;
            let node = dev.tree.findNode(deviceRelativePath);
            data[0]!.push(name);	// Add the name to the list of names
            var lastCrate: Crate | null = this.crateMap.get(node!) ?? null;
            if (ignoreCrates[i])
                lastCrate = null;

            let dates: number[] = [], avgs: (number | null)[] = [], mins: (number | null)[] = [], maxs: (number | null)[] = [];	// Extract and store data in the SE data array format (see dygraph.js for more info)
            let crateCount = fp.pop_u16();
            let bytes: number[] = [];
            for (var j = 0; j < crateCount; ++j) {
                var cn = fp.pop_u64();
                fp.skip(4);	// Skip crate header start partial seconds
                var time = fp.pop_u32();
                fp.skip(4);	// Skip crate header end partial seconds
                var end = fp.pop_u32();
                var sealed = fp.pop_bool();
                var size = fp.pop_u16();
                var nextCrate = fp.size() - size;

                let boxTime: number, n: number | null, min: number | null, max: number | null, avg: number | null, conversion = 1, resolution = 0.01;
                if (lastCrate && lastCrate.cn === cn && lastCrate.interval === interval) {
                    n = lastCrate.n;
                    min = lastCrate.min;
                    max = lastCrate.max;
                    avg = lastCrate.avg;
                    boxTime = lastCrate.boxTime;
                    size = size + lastCrate.size;
                    resolution = lastCrate.resolution;
                    conversion = lastCrate.conversion;
                    // We are in MID BOX HERE. If there's more data, get the next box.
                    time = fp.size() > nextCrate ? lastCrate.boxTime + fp.pop_UVarInt() : end + interval;	// Calculate first second NOT in the box
                    dates.push((time - interval) * 1000);	// Add a ghost point at the end of the interval in the box
                    mins.push(min);
                    maxs.push(max);
                    avgs.push(avg);
                }

                while (fp.size() > nextCrate) {
                    let box = fp.pop_u8();	// Get the next box
                    bytes.push(box);
                    switch (box)	// React to data after the box
                    {
                        case LiveData.BoxResolution:
                            resolution = fp.pop_f64();	// Data resolution of the crate after this
                            break;

                        case LiveData.BoxUnits:
                            conversion = convert(1, fp.pop_u16(), node!.units, node?.tree.findNodesByRole(Role.ROLE_MAX_SPEED_HZ)[0]?.getValue());
                            break;

                        case LiveData.BoxTrue:
                        case LiveData.BoxFalse:
                            n = 1;
                            min = max = avg = box == LiveData.BoxTrue ? 1 : 0;	// If the box is true type, sum is 1
                            break;

                        case LiveData.BoxFullBoolean:
                            n = avg = 0;					// Reset so the delta boolean below will add in full value
                        // no break:

                        case LiveData.BoxDeltaBoolean:
                            {
                                let sum: number = n! * avg!;						// Calculate the sum
                                n! += fp.pop_SVarInt();				// Get the delta count
                                avg = (sum + fp.pop_SVarInt()) / n!;		// Adjust the sum, then recalculate the average
                                min = avg == 1 ? 1 : 0;
                                max = avg == 0 ? 0 : 1;
                            }
                            break;

                        case LiveData.BoxFullNumeric:
                        case LiveData.BoxFullRange:
                            n = min = max = avg = 0;	// Reset all the variables, then add in delta to each variable
                        // no break:

                        case LiveData.BoxDeltaNumeric:
                        case LiveData.BoxDeltaRange:
                            if (box >= LiveData.BoxFullRange) {		// Full or delta range boxes have 4 varints before the time delta
                                assert(box == LiveData.BoxFullRange || box == LiveData.BoxDeltaRange);		// Better be a range box if you are here
                                n! += fp.pop_SVarInt();				// Get delta count
                                min! += conversion * fp.pop_SVarInt() * resolution;	// Get delta min
                                max! += conversion * fp.pop_SVarInt() * resolution;	// Get delta max
                            }
                            avg! += conversion * fp.pop_SVarInt() * resolution;		// Get delta average
                            if (box <= LiveData.BoxDeltaNumeric) {	// If this is one of the second log points
                                assert(box == LiveData.BoxFullNumeric || box == LiveData.BoxDeltaNumeric);	// Better be a numeric box if you are here
                                min = max = avg!;						// Min and max are just the sum
                            }
                            break;

                        case LiveData.BoxGap:
                            n = min = max = avg = null;		// Null out our data
                            break;

                        default:	// Here lies: Box::None
                            assert(false, `Invalid box: '${box}', name: '${name.substring(0, 100)}', crate: ${cn}`);
                            break;
                    }
                    if (box > LiveData.BoxUnits) {	// If this is a box that closes

                        dates.push(time * 1000);			// Add this box's start to the data
                        mins.push(min!);
                        maxs.push(max!);
                        avgs.push(avg!);

                        boxTime = time;
                        var boxEnd = fp.size() > nextCrate ? time + fp.pop_UVarInt() : end + interval;	// Calculate first second NOT in the box
                        if (boxEnd - time > interval) {		// If there's more than one point encoded in the box
                            dates.push((boxEnd - interval) * 1000);	// Add a ghost point just before the end of the interval
                            mins.push(min!);
                            maxs.push(max!);
                            avgs.push(avg!);
                        }
                        time = boxEnd;	// Update our running time stamp for the crate
                    }
                }

                if (!sealed && !ignoreCrates[i]) {	// If we weren't sealed and we weren't told explicitly not to, save the crate for next time
                    if (size === 0)
                        debugger;
                    this.crateMap.set(node!, {
                        cn: cn,
                        time: time,
                        size: size,
                        interval: interval,
                        resolution: resolution,
                        boxTime: boxTime!,
                        n: n!,
                        min: min!,
                        max: max!,
                        avg: avg!,
                        conversion: conversion
                    });
                }
            } //@ts-ignore FIXME: maybe include nulls in HistoricalData?
            data.push(dates, mins, avgs, maxs);	// We have filled up all the data for this line. Add our arrays to our aggregate data array
        }

        for (var i = 0; i < graphNodes.length; ++i) {
            if (graphNodes[i].node.flags & NodeFlags.NF_DERIVED) {	// Need to fix up the data for this node
                // First, figure out how many nodes this guy depends on
                let count: number = 0;
                var ops = graphNodes[i].node.operations;
                for (var j = 0; j < ops.length; ++j) {
                    if (ops[j].node !== undefined)
                        ++count;
                }
                // Now, compute each point
                let start = graphNodes[i]._start! * 1000;
                let end: number = graphNodes[i]._end! * 1000;
                let dates: number[] = [];
                let derived: (number | null)[][] = [[], [], []];
                var indexes = new Array(data[0]!.length).fill(0);		// Indexes of how far we've iterated through each data set
                // Fix up the data array, removing the old data and adding our new
                data[0]!.splice(i, count, dev.key + graphNodes[i].path);
                for (var time: number = start; time <= end; time += interval * 1000) {
                    dates.push(time);
                    for (let k = 0; k < 3; ++k) {
                        let value: number | null = 0;
                        let lineIndex = 0;
                        for (var j = 0; j < ops.length; ++j) {
                            var newVal: number | undefined | null = ops[j].constant;
                            if (ops[j].node !== undefined) {
                                if (ops[j].conversion === undefined) {
                                    let n: Tag = dev.tree.getNode(ops[j].node!)!;
                                    ops[j].conversion = convert(1, n.units, n.cacheUnits);
                                }
                                newVal = this.getValueAtTime(time, i + lineIndex++, data, indexes, k)! * ops[j].conversion!;
                                if (newVal < ops[j].min! || ops[j].max! < newVal)
                                    newVal = null;
                            }

                            if (newVal === null || (ops[j].op == NodeOperand.DERIVED_DIVIDE && newVal == 0)) {
                                value = null;
                                break;
                            }
                            switch (ops[j].op) {
                                case NodeOperand.DERIVED_ADD: value += newVal as number; break;
                                case NodeOperand.DERIVED_SUBTRACT: value -= newVal as number; break;
                                case NodeOperand.DERIVED_MULTIPLY: value *= newVal as number; break;
                                case NodeOperand.DERIVED_DIVIDE: value /= newVal as number; break;
                            }
                        }
                        derived[k].push(convert(value, graphNodes[i].node.cacheUnits, graphNodes[i].node.units));
                    }
                }
                // @ts-ignore Fix up the data array, removing the old data and adding our new
                data.splice(i * 4 + 1, count * 4, dates, ...derived);
            }
        }
        if (fp.bytesLeft > 0)
            throw(new Error("Did not parse all data from frame"));
        return data;
    }

    getValueAtTime(time: number, i: number, data: HistoricalData, indexes: number[], type: DataType = DataType.AVG) {
		var setTimes = data[1 + 4 * i];		// Get the time column
		var setData = data[2 + 4 * i + type];	// Get the min, avg, or max column based on the provided type
		if (setTimes === undefined)			// Data set does not match graph's requested nodes. Graph has requested a new set of data
			return null;
        //@ts-ignore
		if (time < setTimes[0])	// If this is before our first time
			return null;		// No deal
        //@ts-ignore
		while (setTimes[indexes[i] + 1] < time && indexes[i] < setTimes.length - 2)	// Keep looking through the rows until we find the correct period
			++indexes[i];
		if (setData.length < 2 || setData[indexes[i]] === null || setData[indexes[i] + 1] === null)	// If either side is a null, this guy is a null
			return null;
		else //@ts-ignore Else interpolate between the values on either side
			return setData[indexes[i]] + (setData[indexes[i] + 1] - setData[indexes[i]]) * (time - setTimes[indexes[i]]) / (setTimes[indexes[i] + 1] - setTimes[indexes[i]]);
	}

    destroy() {
        this.abortAllRequests();
    }
}