import { WritesEnabler } from "./dialog";
import FrameMaker from "./framemaker";
import FrameParser from "./frameparser";
import LiveData from "./livedata";
import LiveDataClient from "./livedataclient";
import User from "./user";

export interface ServiceTag {
    tag: string;
    key: string;
    status: boolean;
}

export function getServiceTags(companyKey: string): Promise<ServiceTag[]> {
    let fm = new FrameMaker();
    fm.buildFrame(LiveData.WVC_GET_SERVICE_TAGS);
    fm.push_string(User.isPowerUser() ? companyKey : this.user.companyKey);
    return new Promise<ServiceTag[]>(resolve => {
        LiveDataClient.sendRequest(fm).then(fp => {
            let serviceTags: ServiceTag[] = [];
            var count = fp.pop_u32();
            for (var i = 0; i < count; ++i) {
                serviceTags.push({
                    tag: fp.pop_string(),
                    key: fp.pop_string(),
                    status: fp.pop_bool()
                });
            };
            resolve(serviceTags);
        });
    });
}

export function getLocalDeviceTokens(serviceTag: string): Promise<FrameParser> {
    let fm = new FrameMaker();
    fm.buildFrame(LiveData.WVC_GET_DEVICE_TOKEN_METADATA);
    fm.push_string(serviceTag);
    return LiveDataClient.sendRequest(fm);
}

export function createLocalDeviceToken(serviceTag: string, expireInSecondsFromNow: number | undefined): Promise<FrameParser> {
    return new Promise(resolve => {
        new WritesEnabler(() => {
            let fm = new FrameMaker();
            fm.buildFrame(LiveData.WVC_CREATE_DEVICE_TOKEN);
            fm.push_string(serviceTag);
            fm.push_u64(expireInSecondsFromNow ? expireInSecondsFromNow : 2630000); // Default to 1 month if duration is not specified
            LiveDataClient.sendRequest(fm).then(fp => resolve(fp));
        });
    })

}

export function revokeLocalDeviceToken(tokenID: string, serviceTag: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
        new WritesEnabler(() => {
            let fm = new FrameMaker();
            fm.buildFrame(LiveData.WVC_REVOKE_DEVICE_TOKEN);
            fm.push_string(serviceTag);
            fm.push_string(tokenID);
            LiveDataClient.sendRequest(fm).then(fp => resolve(fp.pop_bool()));
        });
    })

}

export function assignSiteKey(serviceTag: string, deviceKey: string): Promise<FrameParser> {
    let fm = new FrameMaker();
    fm.buildFrame(LiveData.WVC_ASSIGN_DEVICE_KEY);
    fm.push_string(serviceTag);
    fm.push_string(deviceKey);
    return LiveDataClient.sendRequest(fm)
}

export function createDeviceKey(serviceTag: string, siteName: string, timeZone: string) {
    let fm = new FrameMaker();
    fm.buildFrame(LiveData.WVC_CREATE_DEVICE_KEY);
    fm.push_string(serviceTag);
    fm.push_string(siteName);
    fm.push_string(timeZone);
    return LiveDataClient.sendRequest(fm)
}

export function verifyServiceTag(serviceTag: string) {

}
