import SVGWidget from './svgwidget';
import { Routes } from '../../owner';
import assert from '../debug';
import { getHash } from '../router/router';
import LiveDataClient from '../livedataclient';

//See write-button for a button that writes to nodes.
//
//The Button lens will attach an onclick event to an element
//  that can change an environment variable and restart.
//
//REQUIRED ATTRIBUTES:
//  data-se-var:<env var name>
//      ^determines the name of the variable that will be set
//  data-se-value:<string>
//      ^the string that the var will be set to

export default class Button extends SVGWidget{
	constructor(nodeRoot,element){
		super(nodeRoot,element);

        //Find the nearest HTML node:
		var HTMLcontainer = SVGWidget.findFirstHTMLParent(this.element);

        //Find the environment variable that will be written to, and the value
        // that will be written to it.
        var variable = SVGWidget.readAttribute(this.element,"seVar");
        var value = SVGWidget.readAttribute(this.element,"seValue");

        if (value !== "") {
        	if(variable === "file") {
        		//Set an onclick that fires the seSVGVarChange event.
        		//Currently, customTab will detect this event and handle it.
        		this.element.onclick = function (){
        			var delta = {};
        			delta[variable] = value;
        			HTMLcontainer.dispatchEvent(
        					// Set bubbles to true to enable the event to "bubble up" to something that will eventually catch it
        					new CustomEvent('SEESVG-varchange',{'detail':delta, bubbles:true}));
        		}
        	} else if(variable === "link") {
        		this.element.onclick = function (){
        			var device = LiveDataClient.devices.getByKey(value);
        			if(device === undefined || !device.connected)
        				return;
					device._tabRequest = 0;
					location.hash = getHash(Routes.Device, {'key':device.key, 'tab':'Default', 'index':'0'});
        		}
        	} else assert(false);
        }

        //HACK: Assign a css class that lets us have an onHover.
        //  This should indicate that buttons are clickable.
        this.element.classList.add('custom-tab-hoverable');
	}
}
