import { createElement } from '../elements';
import View from './view';
import TreeView, { TreeViewTypes } from './treeview';
import './chartview.css';
import { TreeViewProperties } from './treeview';
import { Device } from '../device';
import LiveDataClient from '../livedataclient';


export default class TagFinderView extends View {
	treeView: TreeView;
    device: Device;
    ldc: typeof LiveDataClient;
    constructor(ldc: typeof LiveDataClient, device: Device) {
		super();
        this.ldc            = ldc;
        this.device         = device
    };

	initialize(parent: HTMLElement): View {
        super.initialize(parent);
        this.wrapper			= createElement('div', 'chart-view__page-wrapper',this.parent);
        this.buildTree();
        this.fInitialized       = true;
        return this;
    }

    buildTree() {
        let properties: TreeViewProperties = {
            type: TreeViewTypes.TVT_FINDER,
            folder: this.device.tree.nodes[0],
        }
        this.treeView           = new TreeView(properties).initialize(this.wrapper);
    }
}