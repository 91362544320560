import { RegisterWidget, Widget } from "../../lib/widget";
import { Attribute } from "../../lib/attributes";
import Icon from '../../../images/icons/button.svg';
import ArrowIcon from '../../../images/icons/arrow_right_filled.svg';
import template from './carousel.html'
import { ColorInput } from "../../../views/attributeeditorview";

interface CarouselCard {
    content: HTMLElement;
    dot: HTMLElement;
}

/**
 * Carousel Widget
 *
 * This Widget creates a carousel of cards that can be viewed one at a time in a progression.
 * Cards are added to the carousel by appending child nodes.
 */
@RegisterWidget({tag: 'hmi-carousel', displayName: 'Tab Bar', template: template, icon: Icon, section: 'Layout'})
export class Carousel extends Widget {
    private _index = 0;
    protected valueElement: HTMLDivElement;
    private dots: HTMLElement;
    private leftButton: HTMLButtonElement;
    private rightButton: HTMLButtonElement;
    private cards: CarouselCard[] = [];
    content: HTMLDivElement;
    onchange: ()=>void = ()=>{};
    @Attribute({
        displayName: 'Background Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) backgroundColor: string = '#ffffff';
    @Attribute({
        displayName: 'Border Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) borderColor: string = '#2C2D79';
    @Attribute({
        displayName: 'Text Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) textColor: string = '#1D1B20';
    @Attribute({displayName: 'Disabled'}) disabled : boolean = false;
    @Attribute({displayName: 'Default Index'}) defaultIndex: number = 0;

    enliven() {
        this.cards = [];
        this.dots = this.shadowRoot?.querySelector('.dots') as HTMLDivElement;
        this.leftButton = this.shadowRoot?.querySelector('#left') as HTMLButtonElement;
        this.rightButton = this.shadowRoot?.querySelector('#right') as HTMLButtonElement;
        this.content = this.shadowRoot?.querySelector('.cards') as HTMLDivElement;

        let arrowIcon = document.createElement('img');
        arrowIcon.src = ArrowIcon;

        this.leftButton.appendChild(arrowIcon);
        this.rightButton.appendChild(arrowIcon.cloneNode());

        this.rightButton.onclick = () => this.setIndex(++this.index);
        this.leftButton.onclick = () => this.setIndex(--this.index);

        this.childNodes.forEach((child, index) => {
            if (child instanceof HTMLElement) {
                let container = document.createElement('div');
                container.classList.add('card');
                let slot = document.createElement('slot');
                let slotName = `slot-${index}`;
                slot.setAttribute('name', slotName);
                child.slot = slotName!;
                container.appendChild(slot);
                this.content.appendChild(container);

                let dot = document.createElement('div');
                dot.classList.add('dot');
                this.dots.appendChild(dot);

                this.cards.push({
                    content: child,
                    dot: dot
                });
            }
        });
        this.setIndex(this.defaultIndex);
    }

    get index(): number {
        return this._index;
    }

    set index(v: number) {
        this.setIndex(v);
    }

    disableForward() {
        this.rightButton.disabled = true;
    }

    enableForward() {
        this.rightButton.disabled = false;
    }

    disableBack() {
        this.leftButton.disabled = true;
    }

    enableBack() {
        this.leftButton.disabled = false;
    }

    private setIndex(i: number) {
        if (!this.cards[i])
            throw(new Error(`Invalid index '${i}' set on Carousel`));
        for (let card of this.cards) {
            card.dot.setAttribute('selected', '');
        }
        this._index = i;
        let selectedCard = this.cards[this._index];
        if (this._index === 0)
            this.disableBack();
        else
            this.enableBack();
        if (this._index === this.cards.length  - 1)
            this.disableForward();
        else
            this.enableForward();
        this.content.style.left = `-${this.clientWidth * this._index}px`;
        selectedCard.dot.setAttribute('selected', 'true');
    }

    protected onResize(): void {
        this.content.style.left = `-${this.clientWidth * this._index}px`;
    }
}
