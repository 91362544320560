import { Device, DeviceList } from './device';
import Logo from '../library/images/logo-white.png';
import PassKeys from './passkeys';
import LiveDataClient, { LoginStatus } from './livedataclient';
import FrameMaker from './framemaker';
import LiveData from './livedata';
import FrameParser from './frameparser';
import PathRestrictedStorage from './pathrestrictedstorage';
import { betaSettings, deploySettings, insecureLocal, localSettings, testSettings, transcoSettings } from '../deploymentspecificsettings';
import ViewModal from './viewmodal';
import { DisconnectedView } from './views/disconnectedview';
import Loader from './loader';
import { TagQuality } from './widgets/lib/tag';
import Dialog, { WritesEnabler } from './dialog';
import { type GroupInfo } from './accountmanager';
import assert from './debug';

export interface PermissionGroup {
	group?: string;
	fWrites?: boolean;
}

interface UserBranding {
	logo: string;
}

export enum SMSSubscriptionStatus {
	PENDING = 0,
	SUBSCRIBED = 1,
	UNSUBSCRIBED = 2,
	NO_PHONE_NUMBER = 3
}

class UserPreferences {
	theme: string;
	units: { [key: string]: string }
	constructor(prefs: any) {
		this.theme = prefs['theme'] ?? 'Light';
		this.units = prefs['units'] ?? {};
	}

}

declare var grecaptcha: any; // Grecaptcha is defined by remote Google ReCAPTCHA js

export interface CalloutInterval {
	day: number;
	start: number;
	end: number;
}

export enum NotificationMethod {
	SMS = 0,
	EMAIL = 1,
}

export interface LoginMessage {
	username: string;
	password: string;
	twoFactorToken: string; // 6 digit TOTP token
	freshTwoFactorKey: string; // 2FA TOTP seed that's used if we're setting up MFA
	newPassword: string; // Used if logging in with a temporary password
	twoFactorCookie: string;  // Used to satisfy TOTP for 30 days
	shouldRemember: boolean; // User intends to persist a TFcookie on this machine
}

export const NotificationMethodText: Map<NotificationMethod, string> = new Map([
	[NotificationMethod.SMS, "SMS Text"],
	[NotificationMethod.EMAIL, "Email"]
]);

// This file defines the user object:
class usr {
	sessionID: number;
	username: string;
	fullName: string;
	firstName: string;
	lastName: string;
	companyName: string;
	companyKey: string;
	selectedCompanyKey: string;
	permissions: PermissionGroup[] = [];
	fWizard: boolean;
	fTagConfig: boolean;
	fDevConfig: boolean;
	fAdmin: boolean;
	resetPassword: boolean;
	lastVersion: string;
	color: string;
	timerID: number;
	branding: UserBranding = {
		logo: Logo
	};
	applicationContext: number;
	status: LoginStatus;
	jwtRefresh: string;
	jwtAuth: string;
	fUsedPasswordLogin: boolean = false;
	freshTFKey: string;
	TFCookie: string;
	freshTwoFactorCookie = "";
	authTokenName: string = "auth";
	refreshTokenName: string = "refresh";
	authenticationServer: string;
	address: string;
	performCaptcha: boolean = true;

	preferences: {[key: string]: string} = {};
	verifiedUntil: number = 0;
	passKeys: PassKeys;
	pathStorage: PathRestrictedStorage;
	recaptchaSiteKey: string = "6LfGrIQkAAAAAHmlbJwaN9Zh6dc2aqwQLJyDaZfp"; // Public (not sensitive) reCAPTCHA site key
	recaptchav3js: any;
	splash: HTMLElement;
	disconnectedModal: ViewModal<DisconnectedView> | null;
	devices: DeviceList;
	private connectionInterval: NodeJS.Timeout;
	private pingTimeout: NodeJS.Timeout;
	private isInitialized: boolean;
	constructor() {
		this.pathStorage = new PathRestrictedStorage();
		this.passKeys = new PassKeys(this);
		switch (process.env.TARGET) {
			case 'beta':
				this.address = betaSettings.address;
				this.authenticationServer = betaSettings.authentication;
				this.applicationContext = betaSettings.applicationContext;
				this.performCaptcha = betaSettings.performCaptcha;
				break;
			case 'local':
				this.address = localSettings.address;
				this.authenticationServer = localSettings.authentication;
				this.applicationContext = localSettings.applicationContext;
				this.performCaptcha = localSettings.performCaptcha;
				break;
			case 'localTest':
				this.address = insecureLocal.address;
				this.authenticationServer = insecureLocal.authentication;
				this.applicationContext = insecureLocal.applicationContext;
				this.performCaptcha = insecureLocal.performCaptcha;
				break;
			case 'test':
				this.address = testSettings.address;
				this.authenticationServer = testSettings.authentication;
				this.applicationContext = testSettings.applicationContext;
				this.performCaptcha = testSettings.performCaptcha;
				break;
			case 'whoville':
				this.address = deploySettings.address;
				this.authenticationServer = deploySettings.authentication;
				this.applicationContext = deploySettings.applicationContext;
				this.performCaptcha = deploySettings.performCaptcha;
				break;
			case 'transco':
				this.address = transcoSettings.address;
				this.authenticationServer = transcoSettings.authentication;
				this.applicationContext = transcoSettings.applicationContext;
				this.performCaptcha = transcoSettings.performCaptcha;
				break;
			default:
				assert(false); // Unknown target environment
				break;
		}
		addEventListener('onSelectedCompanyKeyChanged', (e: CustomEvent) => {
			this.selectedCompanyKey = e.detail.key;
		})
	}

	initialize() {
		this.devices = new DeviceList(this);
		assert(!this.isInitialized);
		LiveDataClient.registerCommandResponse(LiveData.WVCR_LOGIN, (fp) => this.onLogInResponse(fp));
		LiveDataClient.registerCommandResponse(LiveData.WVCR_JWT_LOGIN, (fp) => this.onJWTLoginResponse(fp));
		LiveDataClient.registerCommandResponse(LiveData.WVCR_JWT_REFRESH, (fp) => this.onJWTLoginResponse(fp));
		this.recaptchav3js = new Promise<Event | undefined>((resolve, reject) => {
			if (this.performCaptcha) {
				const script = document.createElement('script');
				document.body.appendChild(script);
				script.onload = resolve;
				script.onerror = () => reject(new Error('Error in google recaptcha'));
				script.async = true;
				script.src = `https://www.google.com/recaptcha/api.js?render=${this.recaptchaSiteKey}`;
			}
			else	// No need to actually load the reCAPTCHA JS if we aren't performing a captcha
				resolve(undefined);
		});
		this.recaptchav3js.then(() => {
			// Only attempt to auto-login after recaptcha JS has loaded
			this.attemptJWTlogin();
		}).catch(e => console.error('Failed to load recaptcha:', e));
		this.splash = document.createElement('div')
		this.splash.classList.add('page__loader')
		document.body.append(this.splash);
		let text = document.createElement('p')
		text.classList.add('page__connecting_text')
		text.textContent = "Reconnecting you to live data...";
		this.splash.appendChild(text);
		new Loader(this.splash);
		this.splash.style.display = 'none';
		let returnToLogin = document.createElement('input')
		returnToLogin.classList.add('se-button', 'page__return_to_login');
		returnToLogin.type = 'button';
		returnToLogin.value = 'RETURN TO LOGIN';
		this.splash.appendChild(returnToLogin);
		returnToLogin.onclick = () => {
			this.pathStorage.deleteItem(this.refreshTokenName).then(() => {
				this.pathStorage.deleteItem(this.authTokenName).then(() => {
					window.location.reload();
				});
			});
		}
		this.isInitialized = true;
	}

	canWrite(device: Device): boolean {
		return this.findPermission(device).fWrites ?? false;
	}

	canModifyTags() {
		return this.fTagConfig
	}

	canModifyDevice() {
		return this.fDevConfig;
	}

	writesEnabled() {	// check if they're enabled
		return this.verifiedUntil > Math.round(Date.now() / 1000) || (process.env.TARGET == 'localDevice'); // Either you have writes enabled or you are a client to a local device (in which case, you always have writes enabled)
	}

	findPermission(device: Device): PermissionGroup {
		var rule: PermissionGroup = {};
		for (var i = 0; i < this.permissions.length; ++i) {
			var p = this.permissions[i];
			if (p.group === "*") {			// Global rule
				if (rule.group === undefined)
					rule.group = p.group;	// Give it read-only access
				rule.fWrites = rule.fWrites || p.fWrites;	// OR in writes
				continue;
			}

			for (var j = 0; j < device.groups.length; ++j) {
				var fMatch = false;
				var devGroup: GroupInfo | undefined = device.groups[j];
				while (devGroup) {
					fMatch = p.group === devGroup.name;
					if (fMatch)		// If there's a match
						break;		// Stop iterating and check out the rules
					devGroup = devGroup.parent;
				}
				if (!fMatch)		// No match to compare
					continue;		// Try the net group
				if (rule.group === undefined)
					rule.group = p.group;	// Give it read-only access
				rule.fWrites = rule.fWrites || p.fWrites;	// OR in writes
			}
		}
		return rule; // Return whatever rule we found or a blank object
	}

	destroy() {
		// Delete everything that we wouldn't want a different user to be able to find

	}

	isAdmin() {
		return this.fAdmin;
	}

	isPowerUser() {
		return this.fWizard && this.companyKey.length == 0;	// Only a power user with the wizard flag and SE company key
	}

	isSEEmployee() {
		return this.companyKey.length == 0;
	}

	login(loginMessage: LoginMessage): Promise<LoginStatus> {
		return new Promise((resolve, reject) => {
			LiveDataClient.initializeSocket(this.onSocketClosed.bind(this), this.authenticationServer).then(() => {
				if (loginMessage.shouldRemember) {
					this.pathStorage.setItem("__twofa" + loginMessage.username.hashCode(), this.freshTwoFactorCookie);
					loginMessage.twoFactorCookie = this.freshTwoFactorCookie;
				}

				if (!this.performCaptcha) // Try to log in the user:
					this.sendLoginMessage(loginMessage).then(status => resolve(status));

				this.recaptchav3js.then(() => {
					assert(grecaptcha);
					grecaptcha.ready(() => {						// recaptcha javascript is ready
						grecaptcha.execute(this.recaptchaSiteKey, { action: 'submit' }).then((token) => {	// Silently solve a challenge
							this.verifyCaptcha(token).then(() => {
								this.sendLoginMessage(loginMessage).then(status => {
									resolve(status)
								}).catch(e => console.error(e))
							}).catch(e => console.error(e));	// Verify the token with our backend and a login message will be sent when we receive a response
						}).catch(() => { console.error('Gre Captcha failed') });
					});
				}).catch(e => console.log(e)); // Ensure the Google's reCAPTCHAv3 js is loaded before trying to use the grecaptcha object
			}).catch(() => {
			});
		});
	}

	verifyCaptcha(captchaSolution): Promise<void> {
		assert(this.status != LoginStatus.LOGGED_IN);
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_IS_HUMAN, 777, 0);			// Build the login frame
		fm.push_string(captchaSolution);		// Add on the solution
		return new Promise<void>((resolve, reject) => {
			LiveDataClient.sendRequest(fm).then(fp => {
				let result = fp.pop_u8();
				if (result)
					console.log('Captcha verified');
				else
					console.log('Captcha not verified');
				resolve();
			}).catch(e => reject(e));
		});
	}

	logOut() {
		this.pathStorage.getItem(this.authTokenName).then(async (authToken) => {
			if (LiveDataClient.status == LoginStatus.LOGGED_IN && LiveDataClient.socket?.readyState == 1 && authToken) {	// If a user is actually logged in
				let fm = new FrameMaker()
				fm.buildFrame(LiveData.WVC_LOGOUT);		// Build the log out frame
				fm.push_u8(0x01);							// Append the primary flag
				fm.push_string(authToken);					// Ask the server to revoke our auth token if we have one
				this.pathStorage.deleteItem(this.authTokenName);	// Delete it
				LiveDataClient.sendRequest(fm)											// Transmit the frame
			}
			this.status = LoginStatus.LOGGED_OUT;	// Set status to logged out

			LiveDataClient.cleanup(true); // close socket
			this.revokeTokensDuringLogout();
			dispatchEvent(new CustomEvent('onConnectionStatusChanged', { detail: { status: this.status } }));

			//@ts-ignore If we're running in an ios app, tell the app the user is logged out
			if (window.webkit) //@ts-ignore
				webkit?.messageHandlers?.callback?.postMessage({ message: 'loggedOut' });
		});
	}

	private sendLoginMessage(loginMessage: LoginMessage): Promise<LoginStatus> {
		this.fUsedPasswordLogin = true; // Note that they logged in with a password
		// Try to log in the user:
		if (loginMessage.twoFactorCookie == null || loginMessage.twoFactorCookie === undefined)
			loginMessage.twoFactorCookie = "";
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_LOGIN);				// Build the login frame
		fm.push_string(loginMessage.username);						// Add on the username
		fm.push_string(loginMessage.password);						// Append the plain text password
		fm.push_u32(parseInt(loginMessage.twoFactorToken, 10));		// Append the 2fa token
		fm.push_string(loginMessage.freshTwoFactorKey);				// Append the fresh two factor auth key (base32 encoded)
		fm.push_string(loginMessage.newPassword);					// Append the new plain text password
		fm.push_string(loginMessage.twoFactorCookie ?? '');						// Append the two factor cookie
		fm.push_u8(0x01);								// Append the primary flag
		fm.push_u32(this.applicationContext);		// Append application context
		return new Promise<LoginStatus>(resolve => {
			LiveDataClient.sendRequest(fm).then((fp) => {
				resolve(this.onLogInResponse(fp));
			});
		});
	}

	private onLogInResponse(fp: FrameParser): number {
		LiveDataClient.status = fp.pop_u8();
		switch (LiveDataClient.status) {	// WVC_LOGIN result:
			case LoginStatus.LOGGED_IN:
				this.sessionID = fp.sessionID;		// Save user session id from frame
				this.username = fp.pop_string();
				this.fullName = fp.pop_string();
				this.firstName = this.fullName.split(' ')[0];
				this.lastName = this.fullName.split(' ')[1];
				this.companyName = fp.pop_string();
				this.companyKey = fp.pop_string();
				var permissionCount = fp.pop_u16();
				this.permissions = [];
				for (var i = 0; i < permissionCount; ++i) {
					var group = fp.pop_string();
					var fWrites = fp.pop_bool();
					this.permissions.push({ group: group, fWrites: fWrites });
				}
				this.fWizard = fp.pop_bool();
				this.fTagConfig = fp.pop_bool();
				this.fDevConfig = fp.pop_bool();
				this.fAdmin = fp.pop_bool();
				this.resetPassword = fp.pop_bool();
				this.jwtRefresh = fp.pop_string();
				this.jwtAuth = fp.pop_string();
				clearInterval(this.connectionInterval);
				this.connectionInterval = setInterval(() => this.checkConnection(), 2000);
				this.pathStorage.setItem(this.refreshTokenName, this.jwtRefresh);
				this.pathStorage.setItem(this.authTokenName, this.jwtAuth).then(() => {
					if (this.authenticationServer !== this.address || LiveDataClient.status !== LoginStatus.LOGGED_IN) {
						LiveDataClient.cleanup(true);
						this.attemptJWTlogin();
					}
				});
				this.passKeys.abortPendingPasskeyOperations();
				this.getPreferences();
				break;
			case LoginStatus.TWOFACTOR_INIT:
				this.freshTwoFactorCookie = fp.pop_string();
				break;
			case LoginStatus.PASSWORD_RESET: // Password is expired and requires changing
				break;
			case LoginStatus.TWOFACTOR_VERIFY:
				this.freshTwoFactorCookie = fp.pop_string();
				break;
			case LoginStatus.LOGGED_OUT:
				location.reload();
				break;
			case LoginStatus.INVALID_PASSWORD:
				break;
			default:
				assert(false);
				break;
		}
		dispatchEvent(new CustomEvent('onConnectionStatusChanged', { detail: { status: LiveDataClient.status, freshTwoFaKey: this.freshTFKey, freshTwoFaCookie: this.freshTwoFactorCookie } }));
		return LiveDataClient.status;
	}

	private onJWTLoginResponse(fp: FrameParser) {
		let result = fp.pop_u8();
		if (result) {
			if (this.disconnectedModal) {
				this.disconnectedModal.destroy();
				this.disconnectedModal = null;
			}
			let tokenPayload = JSON.parse(window.atob(this.jwtAuth.split('.')[1]));
			this.sessionID = fp.sessionID;
			this.username = tokenPayload["user"];
			this.fullName = tokenPayload["full_name"];
			this.firstName = this.fullName.split(' ')[0];
			this.lastName = this.fullName.split(' ')[1];
			this.companyName = tokenPayload["company_name"];
			this.companyKey = tokenPayload["company_key"];
			this.selectedCompanyKey = this.companyKey;
			this.permissions = [];
			tokenPayload["permissions"].forEach(permission => {
				this.permissions.push({ group: permission["group"], fWrites: permission["fWrites"] });
			});
			this.fWizard = tokenPayload["fWizard"];
			this.fTagConfig = tokenPayload["fTagConfig"];
			this.fDevConfig = tokenPayload["fDeviceConfig"];
			this.fAdmin = tokenPayload["fAdmin"];
			this.verifiedUntil = 0; // Reset our accounting of their verified (write-mode) status in case they had writes enabled when they were last connected
			LiveDataClient.status = LoginStatus.LOGGED_IN;
			clearInterval(this.connectionInterval);
			this.connectionInterval = setInterval(() => this.checkConnection(), 2000);
			this.splash.style.display = 'none';
			this.getPreferences();
			dispatchEvent(new CustomEvent('onConnectionStatusChanged', { detail: { status: LiveDataClient.status, freshTwoFaKey: this.freshTFKey, freshTwoFaCookie: this.freshTwoFactorCookie } }));
		}
		else {
			this.pathStorage.deleteItem(this.authTokenName);	// We supplied an invalid auth JWT, delete it
			LiveDataClient.cleanup(true);
			if (process.env.TARGET == 'localDevice') { // Local whovilles have no concept of refresh tokens, the auth token either worked or they need to go get a new one
				new Dialog(document.body, {
					title: 'Invalid device token',
					body: 'The token you have saved for this device was rejected by the device. It could be that the token has been revoked, expired, or is otherwise invalid. Please regenerate a new token using the https://dashboard.specificenergy.com application.'
				})
			}
			else // Otherwise, maybe we can refresh it
				this.attemptingJWTrefresh(); // Attempt to refresh it
		}
	}

	onSocketClosed(closeEvent: CloseEvent) {
		if (this.disconnectedModal == null)
			this.disconnectedModal = new ViewModal(new DisconnectedView(), {
				title: 'Reconnecting',
				maxHeight: '240px',
				maxWidth: '400px',
				titleBackgroundColor: 'var(--color-primary)',
				titleTextColor: 'var(--color-inverseOnSurface)',
				fUncloseable: true
			});
		this.devices.array.forEach(device => {
			device.tree.nodes.forEach(node => {
				if (!node || node.fPsuedo)
					return;
				if (node.subscribers.size > 0) {
					node.fQualityChanged = true;
					node.quality = TagQuality.TQ_DISCONNECTED;
					node._updateSubscribers(false);
				}
			})
		});
		LiveDataClient.cleanup(true); // close socket

		this.pathStorage.getItem(this.authTokenName).then((token) => {
			if (token)
				this.attemptJWTlogin();
			else
				location.reload();// Reload the page so they get bumped to login page
		})
	}

	checkConnection() {
		if (LiveDataClient.fMessageReceived) {
			LiveDataClient.fMessageReceived = false;
		}
		else {
			clearInterval(this.connectionInterval);
			this.pingTimeout = setTimeout(() => this.refreshSocket(), 2000);
			this.ping();
		}
	}

	ping() {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_PING);
		LiveDataClient.sendRequest(fm).then(() => this.onPing());
	}

	onPing() {
		clearTimeout(this.pingTimeout);
		this.connectionInterval = setInterval(() => this.checkConnection(), 2000);
	}

	refreshSocket() {
		this.status = LoginStatus.RECONNECTING;
		if (this.disconnectedModal == null)
			this.disconnectedModal = new ViewModal(new DisconnectedView(), {
				title: 'Reconnecting',
				maxHeight: '240px',
				maxWidth: '400px',
				titleBackgroundColor: 'var(--color-primary)',
				titleTextColor: 'var(--color-inverseOnSurface)',
				fUncloseable: true
			});
		this.devices.array.forEach(device => {
			device.tree.nodes.forEach(node => {
				if (!node || node.fPsuedo)
					return;
				if (node.subscribers.size > 0) {
					node.fQualityChanged = true;
					node.quality = TagQuality.TQ_DISCONNECTED;
					node._updateSubscribers(false);
				}
			})
		});

		this.pathStorage.getItem(this.authTokenName).then((token) => {
			if (token) {
				this.attemptJWTlogin();
			}
			else
				location.reload();// Reload the page so they get bumped to login page
		})
	}

	attemptJWTlogin() {
		this.pathStorage.getItem(this.authTokenName).then((token) => {
			if (token) {	// We found an auth token in pathRestrictedStorage
				this.splash.style.display = '';
				this.jwtAuth = token;
				LiveDataClient.cleanup(true);
				LiveDataClient.initializeSocket(this.onSocketClosed.bind(this), this.address)
				.then(() => this.onJWTauthSocketOpen())
				.catch(e => console.log(e)); // Attach our handlers to the socket:
			}
			else if (process.env.TARGET == 'localDevice')
				new Dialog(document.body, {
					title: 'Error',
					body: 'An error occurred while attempting to read the token saved for this device. Please close this window and try again or try reimporting your device token.'
				});
			else
				this.attemptingJWTrefresh();
		});
	}

	attemptingJWTrefresh() {
		assert(LiveDataClient.socket == null, 'no socket should exist');
		this.pathStorage.getItem(this.refreshTokenName).then((token) => {
			if (token) {	// We found an auth token in pathRestrictedStorage
				this.splash.style.display = '';
				this.jwtRefresh = token;
				LiveDataClient.initializeSocket(this.onSocketClosed.bind(this), this.authenticationServer).then(() => this.onJWTrefreshSocketOpen()).catch(e => console.log(e));
			}
			else {	// Hide the loading indicator
				this.splash.style.display = 'none';			// Hide the ripply loader thing
				this.passKeys.loginButton.activateConditionalUI();
			}
		});
	}

	onJWTauthSocketOpen() {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_JWT_LOGIN, 1);
		fm.push_string(this.jwtAuth);						// Add on the auth JWT
		LiveDataClient.sendRequest(fm).then(fp => this.onJWTLoginResponse(fp));
	}

	onJWTrefreshSocketOpen() {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_JWT_REFRESH, 1);
		fm.push_string(this.jwtRefresh);
		LiveDataClient.sendRequest(fm).then(fp => {
			let result = fp.pop_u8();
			if (result) {
				this.jwtAuth = fp.pop_string();
				this.pathStorage.setItem(this.authTokenName, this.jwtAuth).then(() => {
					// We just set a new JWT auth token after successful authentication
					if (this.authenticationServer === this.address && this.status === LoginStatus.LOGGED_IN) {
						dispatchEvent(new CustomEvent('onConnectionStatusChanged', { detail: { status: this.status } }));
					}
					else {
						LiveDataClient.cleanup(true); // Close the socket to the authentication server
						this.attemptJWTlogin(); // Use the JWT to log in to our application server
					}
				});
			}
			else {	// Auth and refresh token were invalid
				this.splash.style.display = 'none';			// Hide the ripply loader thing
				this.pathStorage.deleteItem(this.refreshTokenName); // We supplied an invalid refresh token, delete it
				dispatchEvent(new CustomEvent('onConnectionStatusChanged', { detail: { status: LoginStatus.LOGGED_OUT } }));
				location.reload();
			}
		})
	}

	revokeToken(token: string | undefined) {
		if (token !== undefined) {
			let fm = new FrameMaker();
			fm.buildFrame(LiveData.WVC_JWT_REVOKE);
			fm.push_string(token);
			LiveDataClient.sendRequest(fm)
		}
	}

	revokeTokensDuringLogout() {
		assert(LiveDataClient.socket == null, 'no socket should exist');
		LiveDataClient.initializeSocket(() => { }, this.authenticationServer).then(async () => {
			// Revoke tokens with the server
			this.revokeToken(await this.pathStorage.getItem(this.refreshTokenName));
			this.revokeToken(await this.pathStorage.getItem(WritesEnabler.pathStorageName));

			// Delete them from client storage
			await this.pathStorage.deleteItem(this.refreshTokenName);
			await this.pathStorage.deleteItem(WritesEnabler.pathStorageName);

			location.reload(); // These are the last things we do during the logout process
		}).catch(e => console.log(e));;
	}

	changePassword(oldPassword: string, newPassword: string): Promise<boolean> {  // Change password of logged-in user.
		assert(newPassword != undefined && newPassword != '', "new password should be defined");
		assert(oldPassword != undefined && oldPassword != '', "old password should be defined");
		assert(LiveDataClient.isLoggedIn(), "Trying to change the password of a user who isn't logged in!");
		assert(LiveDataClient.socket && LiveDataClient.socket.readyState == 1, "Client needs to be connected to change password!");	// readyState == 1 means OPEN

		// Build a frame to change password based on logged in user's session ID
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_CHANGE_PASSWORD);
		fm.push_string(oldPassword);	// Append old password followed by the new password
		fm.push_string(newPassword);
		return new Promise<boolean>(resolve => {
			LiveDataClient.sendRequest(fm).then(fp => {
				assert(fp.command == LiveData.WVCR_CHANGE_PASSWORD, "User::onChangePasswordResponse called for bad frame!");
				resolve(fp.pop_u8() === 1);
			});
		});
	}

	forgotPassword(username: string) {
		if (!LiveDataClient.socket)	// Make a socket for this if we need to
			LiveDataClient.initializeSocket(() => { }, this.address).then(() => this.onForgotPasswordSocketOpen(username)).catch(e => console.log(e));
		else
			this.onForgotPasswordSocketOpen(username);
	}

	onForgotPasswordSocketOpen(username: string) {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_FORGOT_PASSWORD, 1);
		fm.push_string(username);
		LiveDataClient.sendRequest(fm);
	}

	forgotPasswordReset(resetToken: string, newPassword: string) {
		if (!LiveDataClient.socket)	// Make a socket for this if we need to
			LiveDataClient.initializeSocket(() => { }, this.authenticationServer).then(() => this.onForgotPasswordResetSocketOpen(resetToken, newPassword)).catch(e => console.log(e));
		else
			this.onForgotPasswordResetSocketOpen(resetToken, newPassword);
	}

	onForgotPasswordResetSocketOpen(resetToken: string, newPassword: string) {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_RESET_PASSWORD, this.sessionID);
		fm.push_string(resetToken);
		fm.push_string(newPassword);
		LiveDataClient.sendRequest(fm).then(fp => {
			let resetResult = fp.pop_u8();
			if (resetResult)
				LiveDataClient.status = LoginStatus.FORGOT_PASSWORD_RESET_SUCCESS;
			else
				LiveDataClient.status = LoginStatus.FORGOT_PASSWORD;
			dispatchEvent(new CustomEvent('onPasswordReset', { detail: { resetResult: resetResult } }));
		});
	}

	verify(password: string, lifeSpan: number): Promise<{ success: boolean, token: string }> {
		assert(password != undefined && password != '', "password should be defined");
		assert(LiveDataClient.isLoggedIn(), "Trying to change the password of a user who isn't logged in!");
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_VERIFY_USER);
		fm.push_string(User.username);	// Append the username
		fm.push_string(password);		// Append the password hash after the username.
		fm.push_u16(lifeSpan); // The backend caps this at 2hrs in seconds
		return new Promise<{ success: boolean, token: string }>(resolve => LiveDataClient.sendRequest(fm).then(fp => {
			let success = fp.pop_u8() === 1;
			let token = '';
			if (success)
				token = fp.pop_string();
			resolve({ success: success, token: token });
		}));
	}

	verifyUserByToken(token: string): Promise<boolean> {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_VERIFY_USER_BY_TOKEN);
		fm.push_string(token);
		return new Promise<boolean>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(fp.pop_u8() === 1)));
	}

	getPreferences(): Promise<{[key: string]: string}> {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_GET_USER_PREFS);
		return new Promise<{[key: string]: string}>(resolve => LiveDataClient.sendRequest(fm).then(fp => {
			let prefString = fp.pop_string();
			if (prefString === "")
				this.preferences = {};
			else
				this.preferences = JSON.parse(prefString);
			resolve(this.preferences);
		}));
	}

	setPreferences(preferences: {[key: string]: string}): Promise<boolean> {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_SET_USER_PREFS);
		fm.push_string(JSON.stringify(Object.assign(this.preferences, preferences)));
		return new Promise<boolean>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(fp.pop_u8() === 1)));
	}
};

const User = new usr();
export default User;