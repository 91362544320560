import { Attribute } from "../../lib/attributes";
import { TagAttribute, VType } from '../../lib/tag';
import { RegisterWidget, Widget } from "../../lib/widget";
import type { Tag, TagDefinition } from "../../lib/tag";
import template from './colorstatus.html'
import { MapInput } from "../../../views/attributeeditorview";
import Icon from '../../../images/icons/palette.svg'

/**
 * Base Radio Button class
 */
@RegisterWidget({tag: 'color-status', displayName: 'Color Status', template: template, section: 'Status', editor: true, icon: Icon})
export class ColorStatus extends Widget {
    private _colorMap: Map<number, string> = new Map();
    @Attribute({
        displayName: 'Color Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Color', true)
    }) colorMap: {value: number, text: string}[] = [];
    @TagAttribute({
        displayName: 'Value Tag',
        supportedTypes: ['numeric', 'boolean'],
        shouldSubscribe: true
    }) valueTag: TagDefinition;

    protected enliven(): void {
        this._colorMap.clear();
        for (let color of this.colorMap) {
            this._colorMap.set(color.value, color.text);
        }
    }

    update(tag: Tag): void {
        if (tag !== this.valueTag.tag)
            throw(new Error('Invalid subscribed tag'));
        let value = this.valueTag.tag.vtype === VType.VT_BOOL ? this.valueTag.tag.getValue() ? 1 : 0 : this.valueTag.tag.getValue();
        this.style.backgroundColor = this._colorMap.get(value) ?? '';
    }
}
