import { RegisterWidget, Widget } from "../../lib/widget";
import { TagAttribute } from "../../lib/tag";
import { Attribute } from "../../lib/attributes";
import NumericIcon from '../../../images/icons/value.svg';
import TextIcon from '../../../images/icons/settings.svg'
import type { Tag, TagDefinition } from "../../lib/tag";
import { MapInput } from "../../../views/attributeeditorview";
import template from './digitalgauge.html';

const ValueID = 'val';
const UnitsID = 'units';

@RegisterWidget({tag: 'digital-gauge', displayName: 'Digital Gauge', section: 'Input', editor: true, icon: NumericIcon, template: template})
export class DigitalGauge extends Widget {
    valueElement: HTMLElement;
    private overrideMap: Map<number, string> = new Map();
    @Attribute({
        displayName: 'Value Override',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueOverride: {value: number, text: string}[] = [];
    @Attribute({displayName: 'Show Units'}) showUnits: boolean = false;
    @TagAttribute({
        displayName: 'Value Tag',
        shouldSubscribe: true,
        supportedTypes: ['numeric','boolean', 'string']
    }) valueTag: TagDefinition;

    protected connectedCallback(): void {
        this.valueElement = this.shadowRoot!.getElementById(ValueID)!;
    }

    protected enliven(): void {
        this.overrideMap.clear();
        if (this.showUnits){
            let unitsElement = document.createElement('div');
            unitsElement.id = UnitsID;
            unitsElement.textContent = ` ${this.valueTag.tag.unitsText}`;
            this.shadowRoot?.appendChild(unitsElement);
        }
        if (this.valueOverride)
            for (let override of this.valueOverride) {
                this.overrideMap.set(override.value, override.text);
            }
    }

    update(tag: Tag) {
        let value = tag.getValue(this);
        this.valueElement.textContent = this.overrideMap.get(value) ?? tag.getFormattedText(false) ?? '';
    }
}


//@RegisterWidget({tag: 'kubota-mbr-sub-state', displayName: 'MBR State', roles: ['Kubota_MBR_SubState'], icon: TextIcon, section: 'Kubota'})
//export class KubotaMBRSubState extends DigitalGauge {
//    @Attribute({displayName: 'Value Override'}) valueOverride: {[value: number]: string} = {
//        0: 'Offline',
//        10: 'Low Flow',
//        11: 'Medium Flow',
//        12: 'High Flow',
//        13: 'Relax',
//        14: 'DifCln',
//        15: 'Relax - Waiting for UV',
//        20: 'Sleep',
//        21: 'Nap',
//        22: 'Alarm Nap',
//        30: 'CIP',
//        99: 'Waiting for Power'
//    };
//}

@RegisterWidget({tag: 'kubota-pa-mode', displayName: 'PA State', roles: ['Kubota_PA_Mode'], icon: TextIcon, section: 'Kubota'})
export class KubotaPAMode extends DigitalGauge {
    @Attribute({displayName: 'Value Override'}) valueOverride = [
        {value: 0, text: 'Offline'},
        {value: 1, text: 'ConstSpd'},
        {value: 2, text: 'Pulse'},
        {value: 3, text: 'Auto'},
    ]
}


@RegisterWidget({tag: 'kubota-level-state', displayName: 'Level State', roles: ['Kubota_Level_State'], icon: TextIcon, section: 'Kubota'})
export class KubotaLevelState extends DigitalGauge {
    @Attribute({displayName: 'Value Override'}) valueOverride = [
        {value: 0, text: 'Stop'},
        {value: 1, text: 'Low Flow'},
        {value: 2, text: 'Med Flow'},
        {value: 3, text: 'High Flow'}
    ]
}

@RegisterWidget({tag: 'open-close-value', displayName: 'Open Close Value', roles: ['OpenClose'], icon: TextIcon, section: 'Values'})
export class OpenClose extends DigitalGauge {
    @Attribute({displayName: 'Value Override'}) valueOverride = [
        {value: 0, text: 'Closed'},
        {value: 1, text: 'Open'}
    ]
}
