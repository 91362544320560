import { createElement, getHumanReadableTime, createUniqueId } from '../elements';
import View from './view';
import owner from '../../owner';
import assert from '../debug';
import SettingsIcon from '../images/icons/settings.svg';
import ScheduleIcon from '../images/icons/schedule.svg';
import MuteIcon from '../images/icons/mute.svg'
import XIcon from '../images/icons/x.svg'
import PassKeyIcon from '../images/icons/passkeys-logo.svg'
import ArrowDownIcon from '../images/icons/arrow_down.svg';
import ArrowBackIcon from '../images/icons/arrow_back.svg';
import WizardIcon from '../images/icons/wizard.svg';
import AddUserIcon from '../images/icons/add_user.svg'
import CheckIcon from '../images/icons/check.svg';
import { dayStrings } from '../timezone'
import './accountsettingsview.css';
import ViewModal from '../viewmodal';
import TextInput from '../components/textinput';
import Dialog, { WritesEnabler } from '../dialog';
import SiteAccessView from './siteaccessview';
import LiveData from '../livedata';
import { Device } from '../device';
import CountryCodes from '../assets/countryCodes.json';
import User, { NotificationMethodText, SMSSubscriptionStatus } from '../user'
import LiveDataClient from '../livedataclient';
import PasskeyView from './passkeyview'
import DropDownArrow from '../images/icons/arrow_right_filled.svg';
import AddIcon from '../images/icons/add_circle.svg';
import DeleteIcon from '../images/icons/delete.svg';
import createSVGElement from '../svgelements';
import { createUser, deleteUser, getCompanyUsers, getGroups, updateUserInfo } from '../accountmanager';

export default class AccountSettings extends View {
	constructor(companyKey) {
		super();
		this.companyKey = companyKey;
	}

	initialize(parent) {
		super.initialize(parent);
		this.id = LiveDataClient.registerGraph(this);
		this.changes = [];
		this.accounts = [];
		this.wrapper = createElement('div', 'account__table-wrapper', this.parent);
		let tableContainer = createElement('div', 'account__table-container', this.wrapper);
		let titleRow = createElement('div', 'account__title', tableContainer);										    // Normal user
		getCompanyUsers(this.companyKey).then(userInfo => this.onUsersReceived(userInfo));

		let addUser = createElement('button', 'se-button device__refresh-button', titleRow);
		createElement('img', 'account__add-user', addUser, '', { 'src': AddUserIcon });
		addUser.onclick = () => this.modal = new ViewModal(new AccountModifyView(undefined, undefined, this.companyKey), {
			maxWidth: '1000px',
			title: 'Create New User',
			titleTextColor: 'var(--color-inverseOnSurface)',
			titleBackgroundColor: 'var(--color-primary)',
			closeCallback: () => getCompanyUsers(this.companyKey).then(userInfo => this.onUsersReceived(userInfo))
		});

		this.tableGrid = createElement('div', 'account__table-grid', tableContainer);
		let columns = [{ title: 'Name', sortBy: 'lastName' },
		{ title: 'Active', sortBy: 'fEnabled' },
		{ title: 'Admin', sortBy: 'fAdmin' },
		{ title: 'Two-Factor Enabled', sortBy: 'fTwoFactorEnabled' },
		{ title: 'Last Login', sortBy: 'lastLogin' },
		{ title: 'Login Count', sortBy: 'loginCount' },
		{ title: 'Schedule', sortBy: undefined },
		{ title: 'Settings', sortBy: undefined }];
		if (User.fWizard)
			columns.push({ title: 'Wizard', sortBy: 'fWizard' });

		let gridTemplate = '';
		for (let i = 0; i < columns.length; i++) {
			gridTemplate += i == 0 ? '2fr ' : '1fr ';
			let entry = createElement('div', 'account__table-header', this.tableGrid);
			createElement('div', 'account__table-header__title', entry, columns[i].title);
			if (columns[i].sortBy) {
				let iconwrapper = createElement('div', 'account__sort-wrapper', entry);
				let iconUp = createElement('img', 'account__sort-icon', iconwrapper, null, { 'src': ArrowDownIcon });
				let iconDown = createElement('img', 'account__sort-icon', iconwrapper, null, { 'src': ArrowDownIcon });
				iconDown.style.opacity = "0.3";
				iconUp.style.opacity = "0.3";
				iconUp.style.transform = 'rotate(180deg)';

				entry.onclick = () => {
					if (iconDown.style.opacity == "1")
						iconDown.sorted = true;

					let cells = document.querySelectorAll(".account__sort-icon");
					for (let i = 0; i < cells.length; i++) {
						cells[i].style.opacity = "0.3";
					}
					if (columns[i].sortBy == this.lastSorted) {
						if (iconDown.sorted == true) {
							iconDown.sorted = false;
							iconUp.style.opacity = "1"
						}
						else {
							iconDown.sorted = true;
							iconDown.style.opacity = "1"
						}
					}
					else iconDown.style.opacity = "1"

					this.sort(columns[i].sortBy)
				}
			}
		}
		this.tableGrid.style.gridTemplateColumns = gridTemplate;
		this.fInitialized = true;
		return this;
	}

	onCompanySelectorChanged(companyKey) {
		this.companyKey = companyKey; // Keep track of the new company key context
		getCompanyUsers(this.companyKey).then(userInfo => this.onUsersReceived(userInfo));
	}

	onViewShown() {
		this.onCompanySelectorChanged(this.companyKey);
	}

	onUsersReceived(users) {
		assert(Array.isArray(users), "onUsersReceived has a bad callback");
		this.accounts = [];
		for (var i = 0; i < users.length; ++i)				// For each user we got back
			this.accounts.push(users[i])
		this.sort();
		this.updateTable();
	}

	updateTable() {
		let cells = document.querySelectorAll(".account__table-row");
		for (let i = 0; i < cells.length; i++) {
			this.tableGrid.removeChild(cells[i])
		}
		for (let i = 0; i < this.accounts.length; i++) {
			let account = this.accounts[i]; // convenience reference
			let row = createElement('div', 'account__table-row', this.tableGrid)
			createElement('div', 'account__table-cell', row, account.firstName + ' ' + account.lastName)
			let enabledWrapper = createElement('div', 'account__table-cell', row);
			if (account.fEnabled)
				createElement('img', 'account__table-cell__icon', enabledWrapper, undefined, { 'src': CheckIcon })

			let adminWrapper = createElement('div', 'account__table-cell', row)
			if (account.fAdmin)
				createElement('img', 'account__table-cell__icon', adminWrapper, undefined, { 'src': CheckIcon })

			createElement('div', 'account__table-cell', row, account.fTwoFactorEnabled)
			createElement('div', 'account__table-cell', row, this.getTime(account.lastLogin))
			createElement('div', 'account__table-cell', row, account.loginCount.toString())
			let iconWrapper = createElement('div', 'account__table-cell', row)

			var calloutIcon;
			var calloutIconColor = '';
			var calloutCallback;

			let openScheduleModificationView = () => {
				if (User.devices.array.length > 0)
					new ViewModal(new CalloutView(this, i, this.accounts, this.companyKey), {
						title: 'Schedule',
						titleTextColor: 'var(--color-onTertiary)',
						titleBackgroundColor: 'var(--color-tertiary)',
						closeCallback: () => this.onCompanySelectorChanged(this.companyKey)
					})
				else
					calloutCallback = () => new Dialog(document.body, { title: 'No Devices to Configure', body: 'Your account doesn\'t have access to any devices. Please set up a device before configuring callouts.' });
			};

			switch (account.smsSubscriptionStatus) {
				case SMSSubscriptionStatus.PENDING: {
					calloutIcon = ScheduleIcon;
					calloutIconColor = 'account__filter-alarm-1';
					calloutCallback = () => new Dialog(
						document.body,
						{
							title: 'Pending SMS verification',
							body: `${account.firstName} ${account.lastName} has not yet agreed to allow Specific Energy to send SMS alarms to their phone number. A callout schedule can be configured for this user but they will not receive SMS alarms until they reply "START" to +15122707992 from (${account.phone})`,
							buttons: [
								{
									title: 'Ok',
									callback: openScheduleModificationView,
								}
							]
						});
					break;
				}

				case SMSSubscriptionStatus.SUBSCRIBED: {
					calloutIcon = ScheduleIcon;
					calloutCallback = openScheduleModificationView;
					break;
				}

				case SMSSubscriptionStatus.UNSUBSCRIBED: {
					calloutIcon = MuteIcon;
					calloutCallback = () => new Dialog(
						document.body,
						{
							title: 'Unsubscribed from alarms', body: `${account.firstName} ${account.lastName} has unsubscribed from receiving SMS messages. In order to resubscribe, this user must text "START" to +15122707992 from the phone number associated with this account (${account.phone}). You may still edit this account's callout schedule, but they will not receive SMS alarms unless they resubscribe.`,
							buttons: [
								{
									title: 'Ok',
									callback: openScheduleModificationView,
								}
							]
						});
					break;
				}

				case SMSSubscriptionStatus.NO_PHONE_NUMBER: {
					calloutIcon = XIcon;
					calloutCallback = () => new Dialog(
						document.body,
						{
							title: 'Missing phone number', body: `${account.firstName} ${account.lastName} does not have a phone number associated with their account and will not receive SMS alarms.`,
							buttons: [
								{
									title: 'Ok'
								}
							]
						});
					break;
				}

				default:
					assert(false);
			}

			createElement('img', `${calloutIconColor} account__table-cell__icon`, iconWrapper, null, { 'src': calloutIcon, onclick: calloutCallback })

			// if(!account.fSubscribedToSMS)
			// 	callOut.onclick 	= () => new Dialog(document.body, {title:'Unsubscribed from alarms',body:`${account.firstName} ${account.lastName} has unsubscribed from receiving SMS messages. In order to resubscribe, this user must text "START" to +15122707992 from the phone number associated with this account (${account.phone})`});
			// else if (User.devices.sorted.length > 0)
			// 	callOut.onclick 	= () => new ViewModal(CalloutView.bind(undefined, undefined, this, i, this.accounts, owner.menuPanel.getCompanyKey()), {
			// 		maxWidth:               '800px',
			// 		title: 				    'Schedule',
			// 		titleTextColor:			'var(--color-inverseOnSurface)',
			// 		titleBackgroundColor: 	'var(--color-primary)',
			// 		closeCallback:          () => this.onCompanySelectorChanged()
			// 	});
			// else
			// 	callOut.onclick 	= () => new Dialog(document.body, {title:'No Devices to Configure',body:'Your account doesn\'t have access to any devices. Please set up a device before configuring callouts.'});

			let settingsWrapper = createElement('div', 'account__table-cell', row)
			account.settingsCog = createElement('img', 'account__table-cell__icon', settingsWrapper, null, { 'src': SettingsIcon });
			account.settingsCog.onclick = () => {console.log('huh'); this.modal = new ViewModal(new AccountModifyView(this.accounts[i], i, this.companyKey), {
				maxWidth: '1000px',
				title: this.accounts[i].userName,
				titleTextColor: 'var(--color-inverseOnSurface)',
				titleBackgroundColor: 'var(--color-primary)',
				closeCallback: () => this.onCompanySelectorChanged(this.companyKey)
			})}
			if (User.fWizard) {
				let wizardWrapper = createElement('div', 'account__table-cell', row);
				let wizard = account.fWizard ? createElement('img', 'account__table-cell__icon', wizardWrapper, undefined, { 'src': WizardIcon }) : undefined;
			};
		};
	};

	sort(sortBy) {							// Sort account table
		if (sortBy == undefined) {
			if (this.lastSorted == undefined)
				return;
			sortBy = this.lastSorted
		}
		else if (sortBy == this.lastSorted) {		// Same sort was selected, reverse
			this.accounts.reverse();
			this.updateTable();
			return;
		}
		switch (sortBy) {
			case "lastName":
				this.accounts.sort(function (a, b) {								// Alphabetical sort
					return a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase());
				});
				break;
			case "fEnabled":
			case "fAdmin":
			case "fTwoFactorEnabled":
			case "fWizard":
				this.accounts.sort(function (a, b) {								// Boolean sort
					return (a[sortBy] == b[sortBy]) ? 0 : a[sortBy] ? -1 : 1;
				});
				break;
			case "lastLogin":
			case "loginCount":
				this.accounts.sort(function (a, b) {								// Numerical sort
					return b[sortBy] - a[sortBy];
				});
				break;
			default:
				assert(false, "Bad sort name");
		}
		this.lastSorted = sortBy;
		this.updateTable();
	}

	getTime(lastLogin) {
		if (lastLogin == 0)		// The user has never logged in
			return "Never";

		if ((Math.floor(Date.now() / 1000) - Math.floor(lastLogin / (1000 * 1000))) > 60 * 24 * 3600) {		// More than sixty days, return MM/dd/yy
			return new Date(lastLogin / 1000).format('%MM/%dd/%yy');
		}
		else								// Return relative human readable time followed by ' ago'
			return getHumanReadableTime(lastLogin, '', ' ago');
	}

	onAccountsManaged(command, result) {	// Feedback from one of our account management changes
		if (result) {
			new Dialog(document.body, { title: 'Success', body: 'Account successfully modified.' });
			this.onCompanySelectorChanged(this.companyKey);
		}
		else
			new Dialog(document.body, { title: 'Error', body: 'An error occurred while attempting to submit this change. Please try again.' })
	};

	refresh() {
		this.onCompanySelectorChanged(this.companyKey);
	}
};

class CalloutView extends View {
	constructor(view, index, users, companyKey) {
		super();
		this.view = view;
		this.index = index;
		this.siteIndex = 0;
		this.user = users[index];
		this.users = users;
		this.calloutSites = [];
		this.companyKey = companyKey;
		this.siteMap = new Map();
		this.groupMap = new Map();
		this.callOut = this.user.callOut;
		this.siteChecks = [];
		this.id = LiveDataClient.registerGraph(this);
	}

	initialize(parent) {
		super.initialize(parent);
		this.accountForm = createElement('div', 'account__callout', this.parent);			// Create an account modification wrapper
		var nameWrapper = createElement('div', 'account__user', this.accountForm);	// Username title on the modal box
		var prevUser = createElement('img', 'account__arrow', nameWrapper, undefined, { 'src': ArrowBackIcon });			// Arrow to click through to the previous user
		prevUser.onclick = this.showNextUser.bind(this, -1);	// When they click this div, show the user before the one we're on
		this.name = createElement('div', 'account__user__name', nameWrapper, this.user.firstName + ' ' + this.user.lastName);			// Div to actually hold the user's name
		var nextUser = createElement('img', 'account__arrow', nameWrapper, undefined, { 'src': ArrowBackIcon });			// Arrow to click through to the previous user
		nextUser.onclick = this.showNextUser.bind(this, 1);	// When they click this div, show the user after the one we're on
		nextUser.style.transform = 'rotate(180deg)';

		let settingsRow = createElement('div', 'account__callout__settings-row', this.accountForm);		// Wrapper for user settings
		let siteContainer = createElement('fieldset', 'account__site-container', settingsRow)
		createElement('legend', '', siteContainer, 'Notified Sites');
		this.siteList = createElement('div', 'account__site-list', siteContainer);	// List of sites user is enabled for

		var settingsContainer = createElement('fieldset', 'account__callout__settings-row__settings-container', settingsRow);
		createElement('legend', '', settingsContainer, 'Notification Settings')
		let settingsList = createElement('div', 'account__callout__settings-row__settings', settingsContainer);
		var sevRow = createElement('div', 'account__callout__settings-row__settings__row', settingsList);		// Row to hold user's callout severity
		createElement('label', 'treeOptionsLabel', sevRow, 'Severities between ');	// Text label
		this.lowSev = createElement('input', 'spinner', sevRow);					// Spinner holding lowest severity
		createElement('label', 'treeOptionsLabel', sevRow, ' and ');					// Text label
		this.highSev = createElement('input', 'spinner', sevRow);					// Spinner holding hightest severity
		this.lowSev.type = this.highSev.type = 'number';								// Make both inputs number spinners
		this.lowSev.min = this.highSev.min = 0;										// Min alarm level is 0
		this.lowSev.max = this.highSev.max = 1000;									// Max alarm level is 1000
		this.lowSev.valueAsNumber = this.user.lowSev;
		this.highSev.valueAsNumber = this.user.highSev;

		let notificationMethodRow = createElement('div', 'account__callout__settings-row__settings__row', settingsList);
		createElement('label', 'treeOptionsLabel', notificationMethodRow, 'Notify by: ');					// Text label
		this.notificationMethod = createElement('select', '', notificationMethodRow);
		for (let [method, name] of NotificationMethodText)
			createElement('option', '', this.notificationMethod, name, { value: method, selected: method === this.user.notificationMethod });

		var failRow = createElement('div', 'account__callout__settings-row__settings__row', settingsList);		// Row to hold user's failover settings
		createElement('label', 'treeOptionsLabel', failRow, 'After ');					// Text label
		this.failMinutes = createElement('input', 'spinner', failRow);				// Spinner holding lfailover time
		this.failMinutes.type = 'number';												// Make the time a spinner
		this.failMinutes.min = 1;													// Min fail time is 1 minute
		this.failMinutes.max = 120;
		this.failMinutes.valueAsNumber = this.user.failMinutes;												// Max fail time is 120 minutes
		createElement('label', 'treeOptionsLabel', failRow, ' minutes, contact ');			// Text label
		this.failover = createElement('select', null, failRow);				// Drop down forfailover name
		createElement('option', null, this.failover, '-').value = '';				// Create a default no failover entry

		for (let i = 0; i < this.users.length; ++i) {		// For each user in the accounts table
			let u = this.users[i];							// Convenience reference to the user
			if (!u.fEnabled || u === this.user)			// If this is the current user or the user is disabled
				continue;								// Skip 'em
			let option = createElement('option', null, this.failover, u.userName, { 'value': u.username });	// Create an option for the user
			option.value = u.userName;												// Save the user name
			option.selected = u.userName === this.user.failover;								// Select the user if the failover is active
		}

		for (var j = 0; j < User.devices.sorted.length; ++j) {	// For each site
			let device = User.devices.sorted[j];				// Convenience reference
			if (!device.key.includes(this.companyKey))	// If we are SE and not looking at our company, skip some sites
				continue;
			this.calloutSites.push(device);
			let row = createElement('div', 'account__site-list__row', this.siteList);		// Create a row
			let check = createElement('div', 'se-checkbox', row)
			let id = createUniqueId();
			let checkbox = createElement('input', 'UserSiteCheckbox', check, '', { 'type': 'checkbox', 'id': id });			// Create a checkbox to enable callouts
			checkbox.checked = this.user.callouts.indexOf(device.key + ',') !== -1;			// Check it if the user gets callouts from here
			createElement('label', 'UserSiteName', check, '', { 'htmlFor': id });
			checkbox.onchange = (e) => this.changeCalloutSites(e);	// On click, change callouts
			checkbox.site = device;
			checkbox.name = device.key;
			this.siteChecks.push(checkbox);

			createElement('div', 'UserSiteName', row, device.siteName).onclick = this.onCalloutSiteClicked.bind(this, device, this.user);	// Label the site
		}

		getGroups(this.companyKey).then(groups => this.onGetGroupsResponse(groups));

		let calRow = createElement('fieldset', 'account__cal-row', settingsRow);
		createElement('legend', '', calRow, 'Notification Schedule')
		this.calContainer = createElement('div', 'account__cal-row__cal-container', calRow);
		let buttonRow = createElement('div', 'account__button-row', this.parent)
		this.submit = createElement('button', 'se-button account-modify__submit', buttonRow, 'Submit');
		this.submit.onclick = () => {
			new WritesEnabler(() => {
				this.changeCallouts();
			});
		}
		this.changeCalloutSites();
	}

	destroy() {
		this.parent.removeChildren();	// Delete any DOM elements left over
	}

	onAccountsManaged(command, result) {	// Feedback from one of our account management changes
		if (result) {
			new Dialog(document.body, {
				title: 'Success',
				titleBackground: 'var(--color-primary)',
				titleColor: 'var(--color-inverseOnSurface)',
				body: this.user.firstName + ' ' + this.user.lastName + '\'s callout settings have been successfully modified.',
			})
			getCompanyUsers(this.companyKey).then(userInfo => this.onUsersReceived(userInfo)); // Grab a fresh list of users
		}
		else
			new Dialog(document.body, {
				title: 'Error',
				titleBackground: 'var(--color-error)',
				titleColor: 'var(--color-inverseOnSurface)',
				body: 'Could not successfully change callouts.'
			});
	}

	onGetGroupsResponse(groups) {
		this.groupMap = new Map();
		for (let i = 0; i < groups.length; ++i) {	// For each site
			if (groups[i].name === '') // Shouldn't be able to add callouts for 'All' group
				continue;
			this.calloutSites.push(groups[i]);
			let row = createElement('div', 'account__site-list__row', this.siteList);		// Create a row
			let check = createElement('div', 'se-checkbox', row)
			let id = createUniqueId()
			let checkbox = createElement('input', 'UserSiteCheckbox', check, null, { 'type': 'checkbox', 'id': id });			// Create a checkbox to enable callouts
			createElement('label', '', check, '', { 'htmlFor': id })
			checkbox.checked = this.user.callouts.split(',').indexOf(groups[i].name) !== -1;			// Check it if the user gets callouts from here
			checkbox.onchange = (e) => this.changeCalloutSites(e);	// On click, change callouts
			checkbox.name = groups[i].name;
			this.groupMap.set(groups[i], checkbox)
			this.siteChecks.push(checkbox);

			createElement('div', 'UserSiteName', row, groups[i].name).onclick = this.onCalloutSiteClicked.bind(this, groups[i], this.user);	// Label the site
		}
		this.changeCalloutSites();
		this.onCalloutSiteClicked(this.calloutSites[0], this.user);
	}

	changeCallouts() {	// Change user callouts with this nasty call defined in only one spot
		updateUserInfo(this.user.userName, undefined, undefined, undefined, this.user.phone, this.user.countryCode, undefined, undefined, undefined, undefined, undefined, undefined, this.callouts, this.user.intervals, this.lowSev.value, this.highSev.value, this.failover.value, this.failMinutes.value, undefined, parseInt(this.notificationMethod.options[this.notificationMethod.selectedIndex].value)).then(success => this.onAccountsManaged(undefined, success));
	}

	changeCalloutSites(e) {
		if (e)
			e.stopPropagation();	// Don't click through (which would hide the box)
		this.callouts = '';		// Start off blank
		for (let i = 0; i < this.siteChecks.length; ++i) {	// Check all children
			if (this.siteChecks[i].checked)					// If the row's checkbox is checked
				this.callouts += this.siteChecks[i].name + ',';		// Add the site's key to the callout with a comma after it
		}
		this.calContainer.removeChildren();
		this.calendar = new NewCalendar(this.calContainer, this, this.user); //new Calendar(this.calContainer, this.calContainer.clientWidth - 30, this.calContainer.clientHeight - 30, this, this.user);
	}

	onUsersReceived(users) {
		assert(Array.isArray(users), "onUsersReceived has a bad callback");
		this.users = users;
		this.showNextUser.bind(this, 0);
	}

	findChildIndex(div) {	// Find the index of a child by looking at previous siblings
		var i = 0;
		while ((div = div.previousSibling) != null) ++i;	// Keep iterating until we run out of previous siblings
		return i;
	}

	showNextUser(direction) {
		this.index = this.index + direction;	// Change index
		if (this.index < 0)										// If we've gone negative
			this.index = this.users.length - 1;	// Wrap
		if (this.index > this.users.length - 1)	// If we've gone too far
			this.index = 0;										// Wrap
		this.user = this.users[this.index];
		this.parent.removeChildren();
		this.initialize(this.parent);
	}

	hideCallouts(e) {
		this.modalBox.setAttribute('show', false);	// Hide the modal box
	}

	onCalloutSiteClicked(site, user) {	// User clicked a text site name on the modal box
		let fDevice = site instanceof Device;
		let displayName = fDevice ? site.siteName : site.name
		//this.calendar.showSite(fDevice ? site.key : site.name, this.users, user);			// Update calendar
	}
}

// Nice little JSON country information list taken from https://gist.github.com/angusjf/cb53d2adabd072331a5aedb05c86cc54
const countryInfo = [CountryCodes.us, CountryCodes.ae, CountryCodes.ca, CountryCodes.gb, CountryCodes.mx, CountryCodes.qa];
export class AccountModifyView extends View {
	constructor(user, index, companyKey) {
		super();
		this.user = user;
		this.index = index;
		this.companyKey = companyKey;
		this.restrictedSites = [];
		this.id = LiveDataClient.registerGraph(this);
	}

	initialize(parent) {
		super.initialize(parent);
		this.wrapper = createElement('div', 'account-modify__container', this.parent);
		let grid = createElement('div', 'account-modify__grid', this.wrapper);
		let leftColumn = createElement('div', 'account-modify__grid__column', grid);
		let rightColumn = createElement('div', 'account-modify__grid__column', grid);
		if (!this.user)
			this.userName = new TextInput(rightColumn, 'Username', 'var(--color-onSurface)', { 'autocomplete': 'off', 'autocapitalize': 'off', 'autocorrect': 'off', 'spellcheck': 'false', 'background': 'true' });
		this.firstName = new TextInput(leftColumn, 'First Name', 'var(--color-onSurface)', { 'autocomplete': 'off', 'autocapitalize': 'off', 'autocorrect': 'off', 'spellcheck': 'false', 'background': 'true' });
		this.lastName = new TextInput(leftColumn, 'Last Name', 'var(--color-onSurface)', { 'autocomplete': 'off', 'autocapitalize': 'off', 'autocorrect': 'off', 'spellcheck': 'false', 'background': 'true' });
		this.email = new TextInput(rightColumn, 'Email Address', 'var(--color-onSurface)', { 'autocomplete': 'off', 'autocapitalize': 'off', 'autocorrect': 'off', 'spellcheck': 'false', 'background': 'true', 'name': 'emailSearch' /*hack to prevent 1password from filling username here during re-auth - https://1password.community/discussion/comment/625320/#Comment_625320*/ });
		let phoneWrapper = createElement('div', 'account-modify__phone-wrapper', leftColumn);
		this.countrySelector = createElement('select', 'account-modify__phone-wrapper__select', phoneWrapper, '', { 'name': 'countryCode' });
		let phoneMessage = createElement('div', 'account-modify__phone-message', leftColumn, 'By providing your phone number, you agree to receive text messages from Specific Energy. Message and data rates may apply. Message frequency varies.');

		// Loop through our countries and create an option for each
		for (let [code, info] of Object.entries(countryInfo)) {
			let option = createElement('option', 'account-modify__phone-wrapper__select__option', this.countrySelector);
			createElement('div', 'account-modify__phone-wrapper__select__option__text', option, `${info.flag} ${info.name} (${info.dialCode})`);
			option.value = info.dialCode.substring(1);
		}

		this.phone = new TextInput(phoneWrapper, 'Mobile Phone', 'var(--color-onSurface)', { 'autocomplete': 'off', 'autocapitalize': 'off', 'autocorrect': 'off', 'spellcheck': 'false', 'background': 'true' });

		// two factor settings
		let twoFactorContainer = createElement('div', 'account__settings-row', leftColumn);
		createElement('div', 'account__settings-title', twoFactorContainer, 'Two-Factor Enabled:');
		var id = createUniqueId();
		var checkwrapper = createElement('div', 'se-checkbox', twoFactorContainer)
		this.twoFactorCheckbox = createElement('input', null, checkwrapper, null, { 'type': 'checkbox', 'id': id });
		createElement('label', null, checkwrapper, null, { 'htmlFor': id });
		this.twoFactorCheckbox.checked = this.user ? this.user.fTwoFactorEnabled : true;
		this.twoFactorCheckbox.disabled = this.twoFactorCheckbox.checked
		// admin settings
		let admin = createElement('div', 'account__settings-row', leftColumn);
		createElement('div', 'account__settings-title', admin, 'Admin Privileges for Account:');
		var id = createUniqueId();
		var checkwrapper = createElement('div', 'se-checkbox', admin)
		this.adminCheckbox = createElement('input', null, checkwrapper, null, { 'type': 'checkbox', 'id': id });
		createElement('label', null, checkwrapper, null, { 'htmlFor': id });
		this.adminCheckbox.checked = this.user ? this.user.fAdmin : false;

		// site restriction settings
		if (this.user) {

		}

		// active account settings
		let active = createElement('div', 'account__settings-row', leftColumn);
		createElement('div', 'account__settings-title', active, 'Account is Active:');
		var id = createUniqueId();
		var checkwrapper = createElement('div', 'se-checkbox', active)
		this.activeCheckbox = createElement('input', null, checkwrapper, null, { 'type': 'checkbox', 'id': id });
		createElement('label', null, checkwrapper, null, { 'htmlFor': id });
		this.activeCheckbox.checked = this.user ? this.user.fEnabled : true;

		if (User.isPowerUser() && this.companyKey == '') {
			let wizard = createElement('div', 'account__settings-row', leftColumn);
			createElement('div', 'account__settings-title', wizard, '🧙');
			var id = createUniqueId();
			var checkwrapper = createElement('div', 'se-checkbox', wizard)
			this.wizardCheckbox = createElement('input', null, checkwrapper, null, { 'type': 'checkbox', 'id': id });
			createElement('label', null, checkwrapper, null, { 'htmlFor': id });
			this.wizardCheckbox.checked = this.user ? this.user.fWizard : false;
		}

		let tagConfig = createElement('div', 'account__settings-row', rightColumn);
		createElement('div', 'account__settings-title', tagConfig, 'Tag Configuration:');
		var id = createUniqueId();
		var checkwrapper = createElement('div', 'se-checkbox', tagConfig)
		this.tagConfigCheckbox = createElement('input', null, checkwrapper, null, { 'type': 'checkbox', 'id': id });
		createElement('label', null, checkwrapper, null, { 'htmlFor': id });
		this.tagConfigCheckbox.checked = this.user ? this.user.fTagConfig : false;
		createElement('div', 'account__settings-explain', createElement('div', 'account__helper account__settings-row', rightColumn), 'Allows actions that modify tag configuration (i.e. submitting tag configuration spreadsheets). Does not enable setting tag values (i.e. turning pumps on and off).');

		let devConfig = createElement('div', 'account__settings-row', rightColumn);
		createElement('div', 'account__settings-title', devConfig, 'Device Configuration:');
		var id = createUniqueId();
		var checkwrapper = createElement('div', 'se-checkbox', devConfig)
		this.devConfigCheckbox = createElement('input', null, checkwrapper, null, { 'type': 'checkbox', 'id': id });
		createElement('label', null, checkwrapper, null, { 'htmlFor': id });
		this.devConfigCheckbox.checked = this.user ? this.user.fDevConfig : false;
		createElement('div', 'account__settings-explain', createElement('div', 'account__helper account__settings-row', rightColumn), 'Allows actions that modify device configurations (i.e. assign/unassign a site to a device).');

		if (this.user) {
			this.firstName.value = this.user.firstName;
			this.lastName.value = this.user.lastName;
			this.email.value = this.user.email;
			this.countrySelector.value = this.user.phone ? this.user.countryCode : 1; // Default the selector to 1 if the user doesn't have a phone
			this.phone.value = this.user.phone ? this.user.phone : '';
			let reset = createElement('div', 'account__settings-row', leftColumn);

			this.resetTwoFactor = createElement('button', 'se-button', reset, 'Reset Two-Factor');
			this.resetTwoFactor.onclick = () => {
				//                       clientID,username,           first,     last,      email,     phone,     country,   permissions,fEnabled, fWizard,   fTagConfig,fDevConfig,fAdmin,    callouts,  intervals,lowSev,highSev, fail,      failTime, fEnableTwoFactor
				new WritesEnabler(() => {
					updateUserInfo(this.user.userName, undefined, undefined, undefined, this.user.phone, this.user.countryCode, undefined, undefined, undefined, undefined, undefined, undefined, undefined, '', undefined, undefined, undefined, undefined, true).then(success => this.onAccountsManaged(undefined, success));
				});
			}

			let resetPasswordContainer = createElement('div', 'account__settings-row', rightColumn);
			this.resetPassword = createElement('button', 'se-button', resetPasswordContainer, 'Send Password Reset Email');
			this.resetPassword.onclick = () => {
				LiveDataClient.sendPasswordResetEmail(this.id, this.user.userName);
				new Dialog(document.body, {
					title: 'Success',
					body: 'This account should receive an email with a password reset link that will expire in 10 minutes.',
					titleBackground: 'var(--color-primary)',
					titleColor: 'var(--color-inverseOnSurface)'
				});
			}

			let restrictions = createElement('div', 'account__settings-row', rightColumn);
			let siteAccessButton = createElement('button', 'se-button', restrictions, 'Modify Group Settings');
			siteAccessButton.onclick = () => new ViewModal(new SiteAccessView(LiveDataClient, this.user, this.companyKey), {
				maxWidth: '1000px',
				title: this.user.firstName + ' ' + this.user.lastName + '\'s Group Settings',
				titleTextColor: 'var(--color-inverseOnSurface)',
				titleBackgroundColor: 'var(--color-primary)',
			});

			let passkeyRow = createElement('div', 'account__settings-row', rightColumn);
			let passkeyButton = createElement('button', 'se-button', passkeyRow);
			createElement('img', 'login__button__icon', passkeyButton, null, { 'src': PassKeyIcon });
			createElement('div', 'login__passkey-button-text', passkeyButton, 'Manage passkeys');
			passkeyButton.onclick = () => new ViewModal(new PasskeyView(this.user.userName), {
				maxWidth: '1000px',
				title: `${this.user.firstName} ${this.user.lastName}'s Passkeys`,
				titleTextColor: 'var(--color-inverseOnSurface)',
				titleBackgroundColor: 'var(--color-primary)',
			});

			if (User.username != this.user.userName && User.companyKey != this.user.companyKey) {
				let deleteRow = createElement('div', 'account__settings-row', rightColumn);
				this.deleteUser = createElement('button', 'se-button', deleteRow, 'Delete User');
				this.deleteUser.style.border = '2px solid var(--color-error)';
				this.deleteUser.onclick = () => new Dialog(document.body, {
					title: 'Delete ' + this.user.userName + '?',
					titleBackground: 'var(--color-red-8)',
					titleColor: 'var(--color-inverseOnSurface)',
					body: 'Are you sure you want to delete ' + this.user.firstName + ' ' + this.user.lastName + '\'s account? This action is permanent. All user data will be deleted.',
					buttons: [
						{
							title: 'Delete User',
							borderColor: 'var(--color-error',
							callback: () => new WritesEnabler(() => deleteUser(this.user.userName)),
						},
						{
							title: 'Cancel'
						}]
				});
			};
		};

		this.errorMessage = createElement('div', 'account-modify__errors', this.wrapper)

		let buttonContainer = createElement('div', 'account-modify__button-container', this.parent);
		this.submit = createElement('button', 'se-button account-modify__submit', buttonContainer, 'Submit');
		this.submit.style.border = '2px solid var(--color-primary)';
		this.submit.onclick = () => {
			this.errorMessage.removeChildren();
			if (this.checkForm()) {
				new WritesEnabler(() => {
					let restrictedText = '';
					if (this.restrictedSites.length != 0)
						restrictedText = this.restrictedSites.join(',');
					let modifyTwoFactor = this.user && (this.twoFactorCheckbox.checked != this.user.fTwoFactorEnabled);		// If two-factor was either enabled or reset (same process for both)
					if (this.user) {// modify existing user:
						updateUserInfo(this.user.userName, this.firstName.value, this.lastName.value, this.email.value, this.phone.value, this.countrySelector.selectedOptions[0].value, this.user.permissions, this.activeCheckbox.checked, this.wizardCheckbox ? this.wizardCheckbox.checked : false, this.tagConfigCheckbox.checked, this.devConfigCheckbox.checked, this.adminCheckbox.checked, undefined, undefined, undefined, undefined, undefined, undefined, modifyTwoFactor, undefined);
					}
					else {			// create new user:
						this.pendingUsername = this.userName.value;
						createUser(this.userName.value, this.companyKey, this.firstName.value, this.lastName.value, this.email.value, this.phone.value, this.countrySelector.selectedOptions[0].value, [], this.activeCheckbox.checked, this.wizardCheckbox ? this.wizardCheckbox.checked : false, this.tagConfigCheckbox.checked, this.devConfigCheckbox.checked, this.adminCheckbox.checked);
					}
				});
			}
			else {
				for (let i = 0; i < this.errors.length; i++) {
					createElement('div', '', this.errorMessage, this.errors[i])
				}
			}
		}


	}

	checkForm() {			// Run the gauntlet of error checking
		var formIsValid = true;
		this.errors = [];

		// Strip leading and trailing whitespace from these user supplied values
		this.firstName.value = this.firstName.value.trim()
		this.lastName.value = this.lastName.value.trim()
		this.email.value = this.email.value.trim()
		this.phone.value = this.phone.value.trim()

		let inValidEmail = this.emailIsInvalid(this.email.value);								// Invalid email
		let inValidPhone = (this.phone.value == "") ? false : this.phoneIsInvalid(this.phone.value, this.countrySelector.selectedOptions[0].value);	// Invalid phone (allow blank phone #)
		let inValidUsername = (this.user) ? false : this.invalidUsername(this.userName.value);		// Invallid username

		if (inValidUsername) {
			this.errors.push(inValidUsername);
			formIsValid = false;
		}
		if (this.firstName.value == '') {
			this.errors.push('First name required.');
			formIsValid = false;
		}
		if (this.lastName.value == '') {
			this.errors.push('Last name required.');
			formIsValid = false;
		}
		if (inValidEmail) {
			this.errors.push(inValidEmail);
			formIsValid = false;
		}
		if (inValidPhone) {
			this.errors.push(inValidPhone);
			formIsValid = false;
		}
		if (formIsValid)
			return true;
		else
			return false;
	}

	invalidUsername(username) {				// Check if username is good enough
		for (var i = 0; i < username.length; ++i) {		// Check each character
			var letter = username.charCodeAt(i);		// Get character code
			//				0		9						   a		 z							 A		Z
			if (((letter < 48) || (57 < letter)) && ((letter < 97) || (122 < letter)) && ((letter < 65) || (90 < letter)))
				return "Invalid character '" + username.charAt(i) + "' in new username.";	// Not a valid character
		}
		return username.length > 3 ? false : "Usernames must be at least 4 characters long.";
	}

	emailIsInvalid(address) {
		// Do a check for any amount of numbers, letters, underscores and periods, followed by an at sign, with one dot after the at sign
		var atSign = false;		// One and only one at sign
		var finaldot = false;	// At least one period after the at sign
		for (var i = 0; i < address.length; ++i) {
			var letter = address.charCodeAt(i);
			if (!atSign && (letter == 64) && (i != 0)) {	// 64 = '@' If haven't found @ previously and this char == @
				atSign = true;
				if (i == address.length - 1)	// Need stuff after the @ -- and we are about to check after it
					return 'Invalid email address. Need text after the @.';
				if (address.charCodeAt(i - 1) == 46 || address.charCodeAt(i + 1) == 46)	// No . next to @
					return 'Invalid email address. No periods adjacent to the @.';
			} else if (letter == 46) {	// 46 = '.'
				if ((i == 0) || (address.charCodeAt(i - 1) == 46))	// No adjacent periods or first character periods
					return 'Invalid email address. No adjacent periods.';
				if (atSign)
					finaldot = true;	// First period after the @ sign
				//				 	 0		 9							  -                 _				  a	      z						 	  A		  Z
			} else if (((letter < 48) || (57 < letter)) && (letter != 45) && (letter != 95) && ((letter < 97) || (122 < letter)) && ((letter < 65) || (90 < letter)))
				return 'Invalid email address. Unsupported character.';	// Not a valid character
		}
		if (!atSign)
			return 'Invalid email address. @ is required.';
		return finaldot ? false : 'Invalid email address. No period after the @.';
	}

	phoneIsInvalid(number, countryCode) {
		for (var i = 0; i < number.length; ++i) {
			var letter = number.charCodeAt(i);
			//			   0	  9
			if ((letter < 48) || (57 < letter))
				return 'Invalid phone number. Only numeric digits.';	// Not a valid character
		}
		let dialCode = `+${countryCode}`;
		switch (dialCode) {	// FIXME: This should be an mapped enum or smart class somewhere once we typescriptify
			case CountryCodes.us.dialCode: return number.length != 10 ? "US/Canada phone numbers should be 10 digits long" : "";
			case CountryCodes.gb.dialCode: return number.length != 10 ? "UK phone numbers should be 10 digits long" : "";
			case CountryCodes.mx.dialCode: return number.length != 10 ? "Mexico phone numbers should be 10 digits long" : "";
			case CountryCodes.qa.dialCode: return number.length != 8 ? "Qatar phone numbers should be 8 digits long" : "";
			case CountryCodes.ae.dialCode: return number.length != 9 ? "UAE phone numbers should be 9 digits long" : "";
			default: return "Unsupported country code";	// Fail bad country codes because we have to enable countries on Twilio anyways
		}
	}

	onAccountsManaged(command, result) {
		if (result) {
			if (command == LiveData.ACCOUNT_MODIFY_USER)
				new Dialog(document.body, {
					title: 'Success',
					body: 'Account successfully modified.',
					titleBackground: 'var(--color-primary)',
					titleColor: 'var(--color-inverseOnSurface)'
				});
			else if (command == LiveData.ACCOUNT_DELETE_USER) {
				new Dialog(document.body, {
					title: 'Success',
					body: 'Account successfully deleted.',
					titleBackground: 'var(--color-primary)',
					titleColor: 'var(--color-inverseOnSurface)'
				});
				owner.modal.destroy();
			}
			else if (command == LiveData.ACCOUNT_ADD_USER) {
				getCompanyUsers(this.companyKey).then(userInfo => this.onUsersReceived(userInfo)); // Load the user's company
			}
		}
		else
			new Dialog(document.body, {
				title: 'Error',
				titleBackground: 'var(--color-error)',
				titleColor: 'var(--color-inverseOnSurface)',
				body: 'An error occurred while attempting to submit this change. Please try again.'
			});
	}

	onUsersReceived(users) {
		assert(Array.isArray(users), "onUsersReceived has a bad callback");

		// Modal we intend to destroy after displaying prompt to set up groups
		let destroyMe = owner.modal;

		// References to some things in "this" that we need to reference potentially after we call this.destroy()
		let ldc = LiveDataClient;
		let companyKey = this.companyKey;

		for (var i = 0; i < users.length; ++i) {				// For each user we got back
			if (this.pendingUsername && this.pendingUsername == users[i].userName) { // If we got a new user back
				let user = users[i];
				new Dialog(document.body, {
					title: 'Success',
					body: 'Account successfully created. This user won\'t have access to any Taggers until they are added to a group. Would you like to add this user to a group now?',
					titleBackground: 'var(--color-primary)',
					titleColor: 'var(--color-inverseOnSurface)',
					buttons: [
						{
							title: 'Set Up Groups',
							color: 'var(--color-primary)',
							callback: () => {
								new ViewModal(new SiteAccessView(ldc, user, companyKey), {
									title: user.firstName + ' ' + user.lastName + '\'s Group Settings',
									titleTextColor: 'var(--color-inverseOnSurface)',
									titleBackgroundColor: 'var(--color-primary)',
								});
							}
						},
						{
							title: 'Skip for Now',
							color: 'var(--color-onSurface)',
						}
					]
				});
			}
		}
		destroyMe.destroy();
	}
};

class NewCalendar {
	constructor(parent, view, user) {
		this.parent = parent;
		this.view = view;
		this.user = user;
		this.createInputs();
	}

	createInputs() {
		let coverageButton = createElement('button', 'se-button', this.parent, 'View Coverage')
		coverageButton.onclick = () => {
			new ViewModal(new CoverageView(this.view, this.user), {
				title: 'Notification Coverage',
				titleBackgroundColor: 'var(--color-brand)',
				titleTextColor: 'var(--color-onBrand)'

			})
		}
		for (let i = 0; i < 7; ++i) {
			let wrapper = createElement('div', 'callout__calendar__day callout__disabled', this.parent);
			let topRow = createElement('div', 'callout__calendar__day__row', wrapper);
			let toggle = createElement('toggle-switch', 'callout__calendar__toggle', topRow, '', { checked: false });
			let everythingElse = createElement('div', 'callout__calendar__day__row__wrapper', topRow);
			let title = createElement('div', 'callout__calendar__day__row__title', everythingElse, dayStrings[i]);
			let icon = createElement('img', 'callout__calendar__day__row__arrow', everythingElse, '', { src: DropDownArrow });
			let accordion = createElement('se-accordion', '', wrapper, '', { defaultOpen: true });

			everythingElse.onclick = () => {
				accordion.toggle();
				if (accordion.isOpen)
					icon.style.rotate = '90deg'
				else
					icon.style.rotate = '';
			}
			toggle.onchange = () => {
				if (toggle.checked) {
					let newInterval = {
						day: i,
						start: 480,
						end: 1080
					}
					this.user.intervals.push(newInterval);
					this.createRow(accordion, newInterval, true);
					accordion.open().then(() => wrapper.classList.remove('callout__disabled'));
					icon.style.rotate = '90deg'
				}
				else {
					this.user.intervals = this.user.intervals.filter(interval => interval.day !== i);
					console.log(this.user.intervals);
					accordion.close().then(() => {
						accordion.removeChildren();
						wrapper.classList.add('callout__disabled');
					});
					icon.style.rotate = '';
				}
			}
			let fFirstInterval = true;

			for (let j = 0; j < this.user.intervals.length; ++j) {
				let interval = this.user.intervals[j];
				if (interval.day === i) {
					icon.style.rotate = '90deg'
					toggle.checked = true;
					wrapper.classList.remove('callout__disabled');
					this.createRow(accordion, interval, fFirstInterval);
					fFirstInterval = false;
				}
			}
		}
	}

	createRow (parent, interval, fFirstInterval) {
		let timeRow = createElement('div', 'callout__calendar__time', parent);
		let inputRow = createElement('div', 'callout__calendar__time__inputs', timeRow);
		let minTime = createElement('input', 'callout__calendar__input', inputRow, '', { type: 'time' });
		minTime.value = `${Math.floor(interval.start / 60).toString().padStart(2, '0')}:${(interval.start % 60).toString().padStart(2, '0')}`;

		createElement('div', '', inputRow, 'through')
		let maxTime = createElement('input', 'callout__calendar__input', inputRow, '', { type: 'time' });
		let end = interval.end === 1440 ? 0 : interval.end;
		maxTime.value = `${Math.floor(end / 60).toString().padStart(2, '0')}:${(end % 60).toString().padStart(2, '0')}`;

		let getMinutes = (input) => {
			const [hours, minutes] = input.value.split(':').map(Number); // Extract hours and minutes
    		return (hours * 60) + minutes; // Convert hours to minutes and sum
		}
		minTime.onchange = () => {
			let startMinutes = getMinutes(minTime);
			let endMinutes = getMinutes(maxTime);
			endMinutes = endMinutes === 0 ? 1440 : endMinutes;
			if (startMinutes >= endMinutes) {
				startMinutes = endMinutes - 1;
				minTime.value = `${Math.floor(startMinutes / 60).toString().padStart(2, '0')}:${(startMinutes % 60).toString().padStart(2, '0')}`;
			}
			interval.start = startMinutes;
		}

		maxTime.onchange = () => {
			let startMinutes = getMinutes(minTime);
			let endMinutes = getMinutes(maxTime);
			endMinutes = endMinutes === 0 ? 1440 : endMinutes;
			if (startMinutes >= endMinutes) {
				endMinutes = startMinutes + 1;
				maxTime.value = `${Math.floor(endMinutes / 60).toString().padStart(2, '0')}:${(endMinutes % 60).toString().padStart(2, '0')}`;
			}
			interval.end = endMinutes;
		}

		if (fFirstInterval) {
			let addButton = createElement('img', 'callout__button', timeRow, '', { src: AddIcon });
			addButton.onclick = () => {
				let newInterval = {
					day: interval.day,
					start: 480,
					end: 1080
				}
				this.user.intervals.push(newInterval);
				this.createRow(parent, newInterval, false);
			};
		}
		else {
			let deleteButton = createElement('img', 'callout__button', timeRow, '', { src: DeleteIcon });
			deleteButton.onclick = () => {
				this.user.intervals = this.user.intervals.filter(intervalItr => intervalItr !== interval)
				timeRow.remove();
			}
		}
	}
}

class CoverageView extends View {
	constructor(parentView, user) {
		super();
		this.view = parentView;
		this.user = user;
		this.resizeListener = () => this.resize();
	}
	initialize(parent) {
		this.parent = parent;
		this.wrapper = createElement('div', 'coverage__wrapper', parent);
		this.siteSelect = createElement('select', '', this.wrapper);
		this.calendarWrapper = createElement('div', 'coverage__calendar', this.wrapper)
		this.calendar = new Calendar(this.calendarWrapper, this.calendarWrapper.clientWidth - 48, this.calendarWrapper.clientHeight - 48, this.view, this.user);
		for (var i = 0; i < User.devices.sorted.length; ++i) {	// For each site
			let device = User.devices.sorted[i];
			if (!device.key.includes(this.view.companyKey))	// If we are SE and not looking at our company, skip some sites
				continue;
			let option = createElement('option', '', this.siteSelect, device.siteName);
			option.value = device.key;
		}
		this.siteSelect.onchange = (e) => {
			this.calendar.showSite(this.siteSelect.value, this.view.users, this.user);			// Update calendar
		}
		addEventListener('resize', this.resizeListener);
		return this;
	}

	resize() {
		this.calendarWrapper.removeChildren();
		this.calendar = new Calendar(this.calendarWrapper, this.calendarWrapper.clientWidth - 48, this.calendarWrapper.clientHeight - 48, this.view, this.user);
		this.siteSelect.onchange();
	}

	destroy() {
		removeEventListener('resize', this.resizeListener);
		super.destroy();
	}
}

class Calendar {	// This is how the user adjust callout intervals
	constructor(div, width, height, calloutView, user) {
		this.fills = [];									// Fills on the graph
		this.calloutView = calloutView;								// UserTab who created us (so we can update the account)
		var svg = createSVGElement('svg', 'tankGraphBase', div, { width: width + 1, height: height + 1 });	// Create SVG background
		this.graph = createSVGElement('g', null, svg);	// Create the graph frame
		this.graph.translate(34, 4);						// Slide it down so text can fit outside graph axes
		height -= 16;	// Make the graph itself a little smaller than the height and width we got so labels fit
		width -= 34;
		this.user = user;
		for (var i = 0; i <= 24; ++i) {	// Draw y major and minor lines one time only. Add y major labels
			var lineHeight = i * height / 24;	// Calculate height
			var css = 'tankGraphDottedLine';	// Most are dotted lines
			if (i % 2 == 0) {					// Every other line
				css = null;						// Draw the line solid
				createSVGElement('text', 'UserCalendarText', this.graph).updateLabel(-34, lineHeight + 4, ('0' + i + ':00').slice(-5));	// Label the line on the y-axis
			}
			createSVGElement('line', css, this.graph).updateLine(0, lineHeight, width, lineHeight);	// Draw the line
		}
		for (var i = 0; i < 7; ++i) {		// Draw x major lines one time only. Add y labels
			var lineWidth = i * width / 7;	// Calculate line offset
			createSVGElement('line', null, this.graph).updateLine(lineWidth, 0, lineWidth, height);	// Draw the line
			createSVGElement('text', 'UserCalendarText', this.graph, { 'text-anchor': 'middle' }).updateLabel(lineWidth + width / 14, height + 10, dayStrings[i]);	// Label the gap
		}
		createSVGElement('line', null, this.graph).updateLine(width, 0, width, height);	// Draw a final line on the far right to close the graph up

		this.background = createSVGElement('rect', 'UserCalenderBackground', this.graph);	// Create a transparent top layer to suck up all the mouse clicks
		this.background.updateFill(0, 0, width, height);			// Make the fill take up the whole graph
		this.height = height;	// Save our size for later
		this.width = width;

		var legend = createElement('div', 'UserLegend', div);	// Create a legend below the graph
		this.legend = this.createLegendEntry(legend, 'Current User Callouts', '#009acd');	// Three entries for the three things we show
		this.createLegendEntry(legend, 'Different user callouts', '#777');
		this.createLegendEntry(legend, 'No callouts configured', 'white');
	};

	createLegendEntry(wrapper, text, color) {	// Create a legend entry
		var entryWrapper = createElement('div', 'UserLegendEntry', wrapper);	// Wrap the whole entry
		createElement('div', 'UserLegendColor', entryWrapper).style.background = color;	// Create a box showing the color
		return createElement('div', null, entryWrapper, text);							// Create a label next to the box and return it
	}

	showSite(name, rows, currentUser) {	// Show a given site and user on the graph
		while (this.fills.length)						// Rather than try to reuse our fills and worry about layering
			this.graph.removeChild(this.fills.pop());	// Just clean house
		var calName = name + ',';					// Get our site key and add the comma that always follows in the callouts string
		for (var i = 0; i < rows.length; ++i) {
			var u = rows[i];							// Convenience reference to the user
			let fGroupHasSite = false;
			for (let [group, checkbox] of this.calloutView.groupMap) {
				if (checkbox.checked && group.devices.includes(name)) {
					fGroupHasSite = true;
					break;
				}
			}
			if ((u.callouts.indexOf(calName) == -1 && !fGroupHasSite) || !u.fEnabled)	// If the user doesn't get callouts for this site or is disabled
				continue;											// Skip 'em
			var fCurrentUser = u.userName == currentUser.userName;			// Check if this user is the user we are focusing on
			for (var j = 0; j < u.intervals.length; ++j)	// For each interval the user has
				this.createFill(u.intervals[j].day, u.intervals[j].start, u.intervals[j].end, fCurrentUser);	// Create a fill on the graph
		}
		this.user = currentUser;	// Save the user
		this.legend.textContent = currentUser.userName + "'s callouts";	// Update the legend
	}

	createFill(day, startMinute, endMinute, fCurrent) {	// Create a filled SVG region on the graph
		var fill = createSVGElement('rect', 'UserCalendarFill');	// Create the fill -- still an orphan, here
		fill.updateFill(day * this.width / 7, this.height * startMinute / 1440, this.width / 7, this.height * (endMinute - startMinute) / 1440);	// Position the fill
		if (fCurrent) {											// If this is a fill for the current user
			fill.classList.add('UserCalendarCurrent');			// Give it a special color
			this.graph.insertChildAt(fill, this.fills.length);	// And make sure it shows up on top
		} else													// Fill is for another use
			this.graph.insertChildAt(fill, 0);					// It goes on bottom
		this.fills.push(fill);									// Add it to our array of fills
	}

	fixDayIntervals(day, fAdding, newStart, newEnd) {	// A user has adjusted the callouts for a user. Calculate the set of intervals needed for a given day
		var hours = new Array(24 * 4).fill(false);				// Prefill the day with no callouts --> 24 hours in a day and four 15 minutes intervals per hour

		for (var i = 0; i < this.user.intervals.length; ++i) {	// Check all intervals
			var interval = this.user.intervals[i];				// Convenience reference to the interval
			if (interval.day != day)							// If this interval doesn't affect the day
				continue;										// Keep looking
			this.setPeriods(hours, interval.start / 15, interval.end / 15 - 1, true);	// Set the periods the user is already configured for in the arry
			this.user.intervals.splice(i--, 1);					// Remove the rule so we don't commit it when we update whoville
		}
		this.setPeriods(hours, newStart, newEnd, fAdding);		// Set or clear the periods based on the new area

		// Now, compute the set of intervals we need by iterating through the periods
		var fLive = false, start = 0;	// Start off with no callout required
		for (var i = 0; i < hours.length; ++i) {	// Check each interval
			if (hours[i]) {			// If the user should get callouts for the period
				if (!fLive) {		// If we haven't need to get callouts before now
					start = i;		// Remember this starting position
					fLive = true;	// Make a note we are now live
				}					// Else we are still live an need to keep iterating until we see the live go false
			} else if (fLive) {		// User shouldn't get callouts, but did the previous period
				this.user.intervals.push({ day: day, start: start * 15, end: i * 15 });	// Save the interval now that we know the start and end
				//				var a = this.user.intervals.back();
				//				console.log("day " + day + ' from ' + Math.floor(a.start/60) +":" + Math.round(a.start%60) + ' to ' + Math.floor(a.end/60) +":" + Math.round(a.end%60));
				fLive = false;		// No longer live
			}
		}
		if (fLive) {	// If we were live at the end of the day
			this.user.intervals.push({ day: day, start: start * 15, end: 24 * 4 * 15 });	// Put an interval to the end of the day
			//			var a = this.user.intervals.back();
			//			console.log("day " + day + ' from ' + Math.floor(a.start/60) +":" + Math.round(a.start%60) + ' to ' + Math.floor(a.end/60) +":" + Math.round(a.end%60));
		}
	}

	setPeriods(hours, start, end, value) {	// Convenience method to set a bunch of members in an array
		for (var i = start; i <= end; ++i) hours[i] = value;
	}
}
