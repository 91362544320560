import { RegisterWidget } from "../../lib/widget";
import template from './alarmcount.html';
import Cruncher from '../../../cruncher';
import { AlarmTypes, type Alarm } from "../../../alarm";
import { AlarmWidget } from "../alarm";
import { Attribute } from "../../lib/attributes";
import Icon from '../../../images/icons/alarms.svg';

@RegisterWidget({tag:'alarm-count', displayName: 'Alarm Count', icon: Icon, template: template, section: 'Alarms', editor: true})
export class AlarmCount extends AlarmWidget {
    valueElement: HTMLElement;
    trendWrapper: HTMLElement;
    trendValueElement: HTMLElement;
    arrowElement: HTMLElement;
    trendArrow: HTMLElement;
    tickInterval: ReturnType<typeof setInterval>;
    cruncher: Cruncher = new Cruncher();
    start: Date;
    end: Date;
    @Attribute({displayName: 'Min Severity'}) minSev: number = 1;
    @Attribute({displayName: 'Max Severity'}) maxSev: number = 1000;
    @Attribute({displayName: 'Show Trend'}) showTrend: boolean = true;

    protected connectedCallback(): void {
        this.valueElement = this.shadowRoot?.getElementById('value') as HTMLElement;
        this.trendWrapper = this.shadowRoot?.getElementById('trend-wrapper') as HTMLElement;
        this.trendValueElement = this.shadowRoot?.getElementById('trend-value') as HTMLElement;
        this.arrowElement = this.shadowRoot?.getElementById('arrow') as HTMLElement;
    }

    protected enliven(): void {
        this.fTrend = this.showTrend;
        super.enliven();
    }

    onHistoricalAlarmsFinished(alarms: Alarm[], trendAlarms: Alarm[], start: number, end: number) {
        let getCurrent = event =>{
            if (event.type !== AlarmTypes.ACTIVATED || event.condition === null)
                return false;
            let tag = this.rootTag.tag.device.tree.findNode(event.tag);
            let configured = tag?.configured[event.condition];
            return configured && configured.severity >= this.minSev && configured.severity <= this.maxSev
        };
        let alarmActivations = alarms.filter(getCurrent).length;
        this.valueElement.textContent = alarmActivations.toString();
        if (this.fTrend) {
            let trendActivations = trendAlarms.filter(getCurrent).length;
            this.trendWrapper.style.display = 'block'
            this.trendValueElement.textContent = `${((alarmActivations - trendActivations) / trendActivations * 100).toFixed(2)}%`
        }
        else
            this.trendWrapper.style.display = ''

    }
}