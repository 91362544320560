import assert from '../debug';
import Dialog from '../dialog';
import LiveDataClient from '../livedataclient';
import User from '../user';

export interface LogoutMessageData {
    message: string,
    value: number
}
export class LogoutTimer  {
    timer: Worker;
    dialog: Dialog | undefined;
    fEnabled: boolean = true;
    listeners: any[] = [];
    timeLeft: string;
    constructor() {
        this.timer = new Worker('tick.js');
        this.timer.onmessage = (e) => {
            let data = JSON.parse(e.data) as LogoutMessageData;
            switch (data.message) {
                case 'tick':
                    break;
                case 'warn':
                    //this.fWorkDayAccess = this.fWorkDayAccess && new Date().getHours() < 17;
                    //let enabled         = this.fEnabled && this.user.permissionLevel >= 4;
                    if (LiveDataClient.isLoggedIn() && this.fEnabled/* && !this.fWorkDayAccess*/) {
                        let properties = {
                            title:      'Auto Logout in ' + this.timeLeft,
                            body:       `In order to keep your account safe, we periodically check to see if you're still here. You can move your cursor or press any key to stay logged in.\n\nAuto-logout can be disabled in the menu panel.`,
                            fButtons:   false,
                            fImportant: true,
                            titleBackground: 'var(--color-primary)',
                            titleColor: 'var(--color-inverseOnSurface)'
                        }
                        this.dialog = new Dialog(document.body, properties);
                    }
                    break;
                case 'logout':
                    User.logOut();
                    break;
                case 'stop':
                    this.fEnabled = false;
                    break;
                case 'start':
                    this.fEnabled = true;
                    break;
                default:
                    assert(false, 'Unexpected message from logout timer web worker');
            }
            this.update(data);
        }

        document.addEventListener('mousemove', 	() => this.reset());
		document.addEventListener('keydown', 	() => this.reset());
        document.addEventListener('click', 		() => this.reset());

        this.timer.postMessage('start'); // kick off the timer
    }

    reset() {
        if (this.dialog && this.dialog.fInitialized) {
            this.dialog.destroy();
            this.dialog = undefined;
        }
        if (this.fEnabled) {
            this.timer.postMessage('reset');
        }
    }

    start() {
        this.timer.postMessage('start'); // stop the timer
    }

    stop() {
        if (this.dialog && this.dialog.fInitialized) {
            this.dialog.destroy();
            this.dialog = undefined;
        }
        this.timer.postMessage('stop'); // stop the timer
    }

    register(listener: any, callback: (data: LogoutMessageData) => void) {
        this.listeners.push([listener, callback])
    }

    update(data: LogoutMessageData) {
        this.timeLeft = (''+Math.floor(data.value/60)).padStart(2,'0') + ':' + (''+Math.floor(data.value%60)).padStart(2, '0');
        if (this.dialog) { // if we are already displaying the warning dialog, update the title
            this.dialog.setTitle('Auto Logout in ' + this.timeLeft);
        }
        for (const [listener,callback] of this.listeners) {
			callback.call(listener,data);
		};
    };
}