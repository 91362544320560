import { Node as Tag } from "../../../node";
import { RegisterWidget, Widget } from "../../lib/widget";
import { Attribute } from "../../lib/attributes";
import SwitchIcon from '../../../images/icons/check.svg';
import { TagAttribute } from "../../lib/tag";
import type { TagDefinition } from "../../lib/tag";
import template from './checkbox.html';
import { ColorInput } from "../../../views/attributeeditorview";

@RegisterWidget({tag: 'se-checkbox', displayName: 'Checkbox', template: template, icon: SwitchIcon, section: 'Input'})
export class Checkbox extends Widget {
    protected valueElement: HTMLDivElement;
    private _checked: boolean;
    protected checkbox: HTMLDivElement;
    protected check: HTMLInputElement;
    onchange: ()=>void = ()=>{};
    @Attribute({
        displayName: 'Background Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => {
            return new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip);
        }}
    ) onColor: string = '#2196F3';
    @Attribute({
        displayName: 'Background Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => {
            return new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip);
        }}
    ) offColor: string = '#ffffff';
    @Attribute({displayName: 'Disabled'}) disabled : boolean = false;

    protected connectedCallback(): void {
        this.checkbox = this.shadowRoot!.querySelector('.checkbox') as HTMLDivElement;
        this.check = this.shadowRoot!.getElementById('check') as HTMLInputElement;
    }

    protected enliven(): void {
        this.check.onchange = () => {
            this._checked = this.check.checked;
            this.onchange();
        }

        if (this.disabled)
            this.checkbox.classList.add('disabled')
        else
            this.check.disabled = false;

        this.checkbox.style.setProperty('--off-color', this.offColor);
        this.checkbox.style.setProperty('--on-color', this.onColor);
        this.check.checked = this.checked;
    }

    get checked() {
        return this._checked;
    }

    set checked(v: boolean) {
        this._checked = v;
        let check = this.shadowRoot?.getElementById('check') as HTMLInputElement;
        if (check) {
            check.checked = this._checked;
        }
    }
}



@RegisterWidget({tag: 'se-tag-checkbox', displayName: 'Checkbox', icon: SwitchIcon, editor: true, section: 'Input'})
export class TagCheckbox extends Checkbox {
    @TagAttribute({
        displayName: 'Check Tag',
        shouldSubscribe: true,
        requiredProperties: ['writeable'],
        supportedTypes: ['boolean']
    }) toggleTag: TagDefinition;

    protected enliven(): void {
        if (!this.toggleTag.tag.isWriteable)
            this.checkbox.classList.add('disabled')
        else
            this.check.disabled = false;

        this.checkbox.style.setProperty('--off-color', this.offColor);
        this.checkbox.style.setProperty('--on-color', this.onColor);

        this.check.onchange = () => this.toggleTag.tag.setPendingValue(this.check.checked ? 1 : 0, this);
        let state = this.toggleTag.tag.getValue() != 0;
        this.check.checked = state;
    }

    update(tag: Tag): void {
        let check = this.shadowRoot!.getElementById('check') as HTMLInputElement;
        let state = tag.getValue(this) != 0;
        check.checked = state;
    }
}

