import { Attribute } from "../../lib/attributes";
import { RegisterWidget, Widget } from "../../lib/widget";
import template from './image.html';
import { FileInput } from "../../../views/attributeeditorview";
import FrameMaker from "../../../framemaker";
import LiveData from "../../../livedata";
import LiveDataClient from "../../../livedataclient";
import { Tag, TagAttribute, type TagDefinition } from "../../lib/tag";
import User from "../../../user";

@RegisterWidget({tag:'image-widget', displayName: 'Image', template: template})
export class ImageWidget extends Widget {
    imageElement: HTMLImageElement;
    @Attribute({
        displayName: 'Image',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new FileInput(name, parent, property, getValue, onSettingChangedCallback)
    }) uuid: string = '';

    @TagAttribute({
        displayName: 'Visibility Toggle Tag',
        isOptional: true,
        shouldSubscribe: true,
        supportedTypes: ['boolean']
    }) visibilityToggle: TagDefinition;

    protected connectedCallback(): void {
        this.imageElement = this.shadowRoot?.getElementById('image') as HTMLImageElement;
    }

    protected enliven(): void
    {
        if (this.uuid === '')
            return;
        let fm = new FrameMaker();
        fm.buildFrame(LiveData.WVC_GET_DOWNLOAD_URL);
        fm.push_string(this.uuid);
        fm.push_string(User.selectedCompanyKey);
        LiveDataClient.sendRequest(fm).then(fp => {
            let success = fp.pop_u8();
            if (success) {
                let url         = fp.pop_string();
                this.imageElement.src = url;
            }
        });
    }

    update(tag: Tag): void {
        if (!tag.getValue())
            this.imageElement.style.display = 'none';
        else
            this.imageElement.style.display = '';
    }
}