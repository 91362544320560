import Icon from '../../../images/icons/repeat.svg';
import { TagSetAttribute } from '../../lib/tag';
import { RegisterWidget, Widget } from '../../lib/widget';
import type { TagDefinition } from "../../lib/tag";
import template from './repeater.html';
import { Attribute } from '../../lib/attributes';

@RegisterWidget({tag: 'se-repeater', displayName: 'Repeater', icon: Icon, section: 'Layout', template: template})
export class Repeater extends Widget {
    @Attribute({displayName: 'Widget Name' }) widgetName: string;
    @TagSetAttribute({displayName: 'Widget Tags'}) widgetTags: TagDefinition[] = [];

    protected enliven(): void {
        for (let i=0; i< this.widgetTags.length - 1;++i) {
            let tagDef = this.widgetTags[i];
            let widget = document.createElement(this.widgetName) as Widget;

            widget.setAttribute('repeater-index', i.toString());
            widget.setAttribute('work-dir', tagDef.tag.absolutePath);
            //@ts-ignore
            widget.constructor.observedAttributes.forEach(attr => {
                if (tagDef.attributes)
                    widget.setAttribute(attr, tagDef.attributes[attr]);
            })
            this.shadowRoot?.appendChild(widget);
        }
    }
}