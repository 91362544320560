import { Attribute } from "../lib/attributes";
import { RegisterWidget, Widget } from "../lib/widget";
import template from './analytics.html';
import { SelectInput } from "../../views/attributeeditorview";
import { DataInterval, DataType, HistoricalData, Intervals } from "../../cruncher";
import { TagAttribute, type TagDefinition } from "../lib/tag";
import Cruncher from '../../cruncher';

@RegisterWidget({tag:'min-value', displayName: 'Min Value', template: template})
export class MinWidget extends Widget {
    valueElement: HTMLElement;
    trendWrapper: HTMLElement;
    trendValueElement: HTMLElement;
    arrowElement: HTMLElement;
    trendArrow: HTMLElement;
    tickInterval: ReturnType<typeof setInterval>;
    cruncher: Cruncher = new Cruncher();
    start: Date;
    end: Date;interval: DataInterval;
    @Attribute({
        displayName: 'Period',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new SelectInput(name, parent, property, getValue, ['Hour','Day','Week','Year'], ['3600','86400','604800','31540000'], onSettingChangedCallback)
    }) period: number = 86400;
    @Attribute({displayName: 'Periods'}) periods: number = 1;
    @Attribute({displayName: 'Show Trend'}) showTrend: boolean = false;
    @TagAttribute({
        displayName: 'Data Tag',
        requiredProperties: ['logged'],
        supportedTypes: ['numeric']
    }) dataTag: TagDefinition;

    protected connectedCallback(): void {
        this.valueElement = this.shadowRoot?.getElementById('value') as HTMLElement;
        this.trendWrapper = this.shadowRoot?.getElementById('trend-wrapper') as HTMLElement;
        this.trendValueElement = this.shadowRoot?.getElementById('trend-value') as HTMLElement;
        this.arrowElement = this.shadowRoot?.getElementById('arrow') as HTMLElement;
    }

    protected enliven(): void {
        let period      = this.period;
        this.interval    = Intervals.back()
        for (let i = Intervals.length - 1; i>=0;--i) {
            if (period % Intervals[i] == 0 && Intervals[i] < period) {
                this.interval = Intervals[i];
                break;
            }
        }
        this.end        = new Date();
        this.start      = new Date((this.end.getTime() / 1000 - this.periods * (this.showTrend ? 2 * period : period)) * 1000);
        this.cruncher.getTrimmedData(this.start, this.end, [this.dataTag.tag], this.interval).then((data: HistoricalData) => this.onDataResponse(data));
    }

    private onDataResponse(data: HistoricalData) {	// We got historical data back
        let period      = this.period;
        let parsedData: number[][] = [[],[],[],[]];
        for (let i = 0; i < this.periods; ++i) {
            let index       = 0; // keep track of this for speediness
            let startTime = this.start.getTime() + i * period * 1000;
            let indexes = new Array(data[0]!.length).fill(0);		// Indexes of how far we've iterated through each data set
            for (let time = startTime; time <= startTime + period * 1000; time += this.interval * 1000) {	// Go through the entire interval they requested at the interval they requested
                let min: number | null     = this.cruncher.getValueAtTime(time, index, data, indexes, DataType.MIN);
                let average: number | null = this.cruncher.getValueAtTime(time, index, data, indexes, DataType.AVG);
                let max: number | null     = this.cruncher.getValueAtTime(time, index, data, indexes, DataType.MAX);
                if (average == null)
                    continue; //TODO: add null range for bad data
                parsedData[0].push(time);
                parsedData[1].push(min!);
                parsedData[2].push(average);
                parsedData[3].push(max!);
            }
        }
        this.calculate(parsedData);
    }

    calculate(data: number[][]) {
        let value: number;
        if (this.showTrend) {    // user wants to see how this value is trending
            let previousPeriod  = data[1].slice(0, this.periods);
            let previousValue   = Math.min(...previousPeriod);
            let currentPeriod   = data[1].slice(-this.periods);
            value               = Math.min(...currentPeriod);
            let change          = (value - previousValue) / previousValue * 100;
            this.trendWrapper.style.display = 'inline-block';
            this.trendValueElement.textContent = `${change.toFixed(1)}%`;
            this.arrowElement.style.rotate = change < 0 ? '180deg' : '';
        }
        else {
            value = Math.min(...data[1]);
        }
        this.valueElement.textContent = value.toFixed(this.dataTag.tag.digits);
    }
}

@RegisterWidget({tag:'max-value', displayName: 'Max Value', template: template})
export class MaxWidget extends MinWidget {
    calculate(data: number[][]) {
        let value: number;
        if (this.showTrend) {    // user wants to see how this value is trending
            let previousPeriod  = data[3].slice(0, data[3].length / 2);
            let previousValue   = Math.max(...previousPeriod);
            let currentPeriod   = data[3].slice(-data[3].length / 2);
            value               = Math.max(...currentPeriod);
            let change          = (value - previousValue) / previousValue * 100;

            this.trendWrapper.style.visibility = 'inline-block';
            this.trendValueElement.textContent = `${change.toFixed(1)}%`;
            this.arrowElement.style.rotate = change < 0 ? '180deg' : '';
        }
        else {
            value = Math.max(...data[3]);
        }
        this.valueElement.textContent = value.toFixed(this.dataTag.tag.digits);
    }
}

@RegisterWidget({tag:'avg-value', displayName: 'Average Value', template: template})
export class AverageWidget extends MinWidget {
    calculate(data: number[][]) {
        let value: number;
        if (this.showTrend) {    // user wants to see how this value is trending
            let previousPeriod  = [...data[2].slice(0, this.periods)];
            let previousValue   = previousPeriod.reduce((a,b) => a + b) / previousPeriod.length
            let currentPeriod   = [...data[2].slice(-this.periods)];
            value               = currentPeriod.reduce((a,b) => a + b) / currentPeriod.length;
            let change          = (value - previousValue) / previousValue * 100;

            this.trendWrapper.style.visibility = 'inline-block';
            this.trendValueElement.textContent = `${change.toFixed(1)}%`;
            this.arrowElement.style.rotate = change < 0 ? '180deg' : '';
        }
        else {
            let currentPeriod   = [...data[2].slice(-this.periods)];
            value               = currentPeriod.reduce((a,b) => a + b) / currentPeriod.length;
        }
        this.valueElement.textContent = value.toFixed(this.dataTag.tag.digits);
    }
}