import assert from './debug';
/*
 * SVG namespace:
 */
var nsSVG = nsSVG || 'http://www.w3.org/2000/svg';

declare global {
	interface SVGElement {
		getStrokeWidth();
		shrinkByStrokeWidth();
		createGradientFill(svg: SVGElement, fVertical: boolean, fInny: boolean, color: string, ratio: number);
		roundToHalfPixel(x: number);
		updateLine(x1: number, y1: number, x2: number, y2: number);
		updateLabel(x: number, y: number, text: string);
		updateFill(x: number, y: number, width: number, height: number);
		translate(x: number, y: number);
	}
}

type AllElements = {
	'g': SVGGElement,
	"svg": SVGElement,
	"image": SVGImageElement,
	'pattern': SVGPatternElement,
	'rect': SVGRectElement,
	'foreignObject': SVGForeignObjectElement,
	'line': SVGLineElement,
	'text': SVGTextElement,
}

type CreatedSVGElement<T extends string> = T extends keyof AllElements ? AllElements[T] : SVGElement;

/**
 * Create an SVG element, with optional collection of style properties
 *
 * @param {string}		tag			SVG element type (svg, g, rect, circle, text, etc)
 * @param {string}		className	class name(s)
 * @param {element}		parent
 * @param {properties}	properties	collection of property/value pairs
 * @param {string}		text content of SVG element
 * @return {SVGElement}
 *
 */
// FIXME: Make properties type compatibale with Parital<CreatedSVGElemented<T>>
export default function createSVGElement <T extends string>(tag: T, className?: string, parent?: HTMLElement | SVGElement, properties?: any, content?: string) : CreatedSVGElement<T> {
	var element = document.createElementNS("http://www.w3.org/2000/svg", tag) as CreatedSVGElement<T>

	if (className)		// optional
		element.setAttribute ('class', className);

	if (parent)			// optional
		parent.appendChild(element);

	if (properties) {	// optional
		for (var property in properties){
			if (property !== undefined)
				element.setAttribute(property, properties[property]);
		}
	}

	if (content)		// optional text content
		element.textContent = content;

 	return element;
};

/**
 * Get the outline stroke width of an svg element (CSS pixels):
 *
 * @return {Number} stroke width in CSS pixels
 */
SVGElement.prototype.getStrokeWidth = function (): number {
	var stroke = this.getCSS('stroke');

	if (stroke == '' || stroke == 'none')
		return 0;

	return this.getCSSInt('stroke-width');
};

/*
 * Shrink an svg shape by half the width of the stroke all the way around so that it will draw without clipping half the line:
 */

SVGElement.prototype.shrinkByStrokeWidth = function () {
	var sw = this.getStrokeWidth();
	var sw2 = sw / 2;

	switch (this.tagName) {
	case 'rect':
		this.setAttribute ('x', this.getAttribute ('x') + sw2);
		this.setAttribute ('y', this.getAttribute ('y') + sw2);
		this.setAttribute ('width', this.getAttribute ('width') - sw);
		this.setAttribute ('height', this.getAttribute ('height') - sw);
		break;

	case 'circle':
		this.setAttribute ('r', this.getAttribute ('r') - sw2);
		break;

	case 'ellipse':
		this.setAttribute ('rx', this.getAttribute ('rx') - sw2);
		this.setAttribute ('ry', this.getAttribute ('ry') - sw2);
		break;

	default:
		assert (false, 'SVG shrink not implemented for ' + this.tagName);
		break;
	}
	return this;
};

/**
 * Add gradient to SVG element, using 'fill' color as base color:
 * @param {SVGElement} viewport element (probably the parent of 'this')
 * @param {boolean} optional parameter to specify vertical gradient (default is horizontal)
 * @param {boolean} optional parameter to specify inny (depressed). Default is outy (pooched)
 * @param {string or color} optional parameter to specify color. Otherwise css fill color is used.
 * @param {Number} optional parameter to specify how much of a gradient to apply. Default is 0.4.
 */
/*
SVGElement.prototype.createGradientFill = function (svg, fVertical, fInny, color, ratio) {
	var defs	= createSVGElement('defs');
	var gradId	= createUniqueId();
	var grad	= createSVGElement('linearGradient', '', defs, {id:gradId, x2:fVertical ? '0%' : '100%', y2:fVertical ? '100%' : '0%'});

	grad._fVertical = !!fVertical;
	grad._fInny 	= !!fInny;

	grad._s1 = createSVGElement('stop', '', grad, {'offset':'0%'});
	grad._s2 = createSVGElement('stop', '', grad, {'offset':'50%'});
	grad._s3 = createSVGElement('stop', '', grad, {'offset':'100%'});

	svg.appendChild(defs);

	// Store a reference to the gradient on the object:
	this._grad = grad;
	this.ratio = ratio;

	// Set the gradient color:
	if (color === undefined)	// Create the linear gradient using the current fill color:
		color = this.getCSS('fill');

	this.setGradientFillColor(color);

	// Replace the solid fill with this new gradient fill:
	this.style.fill = 'url(#' + gradId + ')';	// Must use inline style to override css fill!!
};
*/

/**
 * Change gradient fill color and opacity/transparency of an svg element:
 * @param {Color object} color definition
 */
/*
SVGElement.prototype.setGradientFillColor = function (color) {
	assert (this._grad, 'gradient must already be created');
	color = new Color(color);	// convert in case color is a string
	var ratio = this.ratio || 0.4;

	// Color gradient:
	this._grad._s1.setAttribute ('stop-color', this._grad._fInny ? color.darken(ratio) : color.lighten(ratio));
	this._grad._s2.setAttribute ('stop-color', color);
	this._grad._s3.setAttribute ('stop-color', this._grad._fInny ? color.lighten(ratio) : color.darken(ratio));

	// Color opacity:
	this._grad._s1.setAttribute ('stop-opacity', color.rgba[3]);
	this._grad._s2.setAttribute ('stop-opacity', color.rgba[3]);
	this._grad._s3.setAttribute ('stop-opacity', color.rgba[3]);
};
*/

/**
 * Get element content width in pixels
 * @returns {number} pixels
 */
SVGElement.prototype.getWidth = function (): number {
	return this.getAttribute('width');
};

/**
 * Get element content height in pixels
 * @returns {number} pixels
 */
SVGElement.prototype.getHeight = function (): number {
	return this.getAttribute('height');
};

/**
 * Round positioning parameter because lines are drawn sharper at the half pixel
 * @param {Number x} Number to be rounded to half pixel
 * @returns {number}
 */
SVGElement.prototype.roundToHalfPixel = function(x: number): number{
	return Math.round(x - 0.5) + 0.5;
};

/**
 * Update positioning parameters for a SVG Line element. All parameters are rounded to nearest half pixel
 * @param {Number x1} Line start horizontal coordinate
 * @param {Number y1} Line start vertical coordinate
 * @param {Number x2} Line end horizontal coordinate
 * @param {Number y2} Line end vertical coordinate
 */
SVGElement.prototype.updateLine = function (x1: number, y1: number, x2: number, y2: number) {
	if (isNaN(x1) || isNaN(y1) || isNaN(x2) || isNaN(y2))	// One or more of the points are bad
		return;												// Bail out
	this.setAttribute('x1', this.roundToHalfPixel(x1));	// X-coordinate of the line's starting position
	this.setAttribute('y1', this.roundToHalfPixel(y1));	// Y-coordinate of the line's starting position
	this.setAttribute('x2', this.roundToHalfPixel(x2));	// X-coordinate of the line's ending position
	this.setAttribute('y2', this.roundToHalfPixel(y2));	// Y-coordinate of the line's ending position
};

/**
 * Update positioning parameters for a SVG Text element. All parameters are rounded to nearest half pixel
 * @param {Number x} Start horizontal coordinate
 * @param {Number y} End vertical coordinate
 * @param {string text} New label value
 */
SVGElement.prototype.updateLabel = function(x: number, y: number, text: string) {
	this.setAttribute('x', this.roundToHalfPixel(x));	// X-coordinate of the label's position
	this.setAttribute('y', this.roundToHalfPixel(y));	// Y-coordinate of the label's position
	this.innerHTML = text;								// Text on the label
};

/**
 * Update positioning parameters for a SVG Fill element. All parameters are rounded to nearest half pixel
 * @param {Number x} Start horizontal coordinate
 * @param {Number y} End vertical coordinate
 * @param {Number width} Horizontal size of SVG element
 * @param {Number height} Vertical size of SVG element
 */
SVGElement.prototype.updateFill = function(x: number, y: number, width: number, height: number) {
	if (isNaN(x) || isNaN(y) || width < 0 || height < 0)	// One or more of the points are bad
		return;					// Bail out
	this.setAttribute('x', 		this.roundToHalfPixel(x));		// X-coordinate of the fill's top left corner
	this.setAttribute('y',		this.roundToHalfPixel(y));		// Y-coordinate of the fill's top left corner
	this.setAttribute('width',	this.roundToHalfPixel(width));	// Fill width in pixels
	this.setAttribute('height',	this.roundToHalfPixel(height));	// Fill height in pixels
};

/**
 * Translate an SVG element by sliding element vertically and horizontally
 * @param {Number x} Magnitude of horizontal slide
 * @param {Number y} Magnitude of vertical slide
 */
SVGElement.prototype.translate = function(x: number, y: number) {
	this.setAttributeNS(null, 'transform', 'translate(' + x + ','+ y + ')');
};