import View from "./view";
import {createElement} from '../elements';
import { FormElement } from "../formelements";
import './formview.css';
import { WritesEnabler } from "../dialog";
import User from "../user";

export default class FormView extends View {
    device: any;
    wrapper!: HTMLElement;
    formWrapper!: HTMLElement;
    tab!: HTMLElement;
    submitButton!: HTMLInputElement;
    cancelButton!: HTMLInputElement;
    root!: FormElement;
    fp: any;
    fInitialized: boolean = false;
    fShown: boolean = false;

    constructor(device: any) {
        super();
        this.device = device;
    }

	initialize(parent: HTMLElement): FormView {	// Called when the tab is brought up for the first time
		super.initialize(parent);
		this.wrapper = createElement('div', 'FormTabWrapper', this.parent);
		this.device.getForm().then((fp: any) => this.onFormReceived(fp));
		this.fInitialized = true;
		return this;
	}

	onFormReceived(fp: any): void {
		this.fp = fp;

		this.formWrapper = createElement('div', 'FormWrapper', this.wrapper);
		const buttonWrapper = createElement('div', 'FormButtonWrapper', this.wrapper);

		this.tab = createElement('div', 'Form', this.formWrapper);	// Wrapper for the whole tab

		this.submitButton = createElement('input', 'FormButton SubmitButton', buttonWrapper) as HTMLInputElement;
		this.submitButton.onclick = this.onSubmit.bind(this);
		this.submitButton.type = 'button';
		this.submitButton.value = 'Submit';
		this.submitButton.disabled = !User.fTagConfig;

		this.cancelButton = createElement('input', 'FormButton CancelButton', buttonWrapper) as HTMLInputElement;
		this.cancelButton.onclick = this.onCancel.bind(this);
		this.cancelButton.type = 'button';
		this.cancelButton.value = 'Cancel';

		this.root = new FormElement(null, fp, this.tab);
		this.root.popAttributes(fp);

		this.resize();
	}

	resize(): void {	// Called with the window resizes
		if (!this.fShown || !this.fInitialized)
			return;
		let height: number;
		if(window.innerWidth > 768)
			height = (this.formWrapper as any).stretchHeight(150, 64);	// Stretch the table wrapper
		else
			height = (this.formWrapper as any).stretchHeight(150, 64);
			(this.formWrapper as any).setHeight(height);					// Set both tables to a little less than half height
	}

	onSubmit(): void {
		new WritesEnabler((): void => { // Only Submit if Writes are Enabled
			if(!window.confirm('Are you sure you want to submit these changes?'))
				return;
			this.root.submitAttributes(this.device.id);
			this.submitButton.disabled = true;
			this.cancelButton.disabled = true;
		});
	}

	onCancel(): void {
		if(!window.confirm('Are you sure you want to discard these changes?'))
			return;
		this.resetValues(this.root);
		this.submitButton.disabled = true;
		this.cancelButton.disabled = true;
	}

	resetValues(parent: FormElement): void {
		for(let i = 0; i < parent.children.length; ++i) {
			if(parent.children[i].children.length > 0)
				this.resetValues(parent.children[i]);
			else {
				parent.children[i].update(parent.children[i].value);
			}
		}
	}

	destroy(): void {
		(this.parent as any).destroyWidgets(true);	// Don't need to destroy our graph specifically
		(this.parent as any).removeChildren();		// Delete any DOM elements left over
	}
}