import { Attribute } from "../../lib/attributes";
import { RegisterWidget, Widget } from "../../lib/widget";
import template from './profile.html';
import { RangeInput, SelectInput } from "../../../views/attributeeditorview";
import { CalculateInterval, HistoricalData } from "../../../cruncher";
import { Chart } from "chart.js";
import { TagAttribute, type TagDefinition } from "../../lib/tag";
import Cruncher from '../../../cruncher';
import Icon from '../../../images/icons/stackedbar.svg';

const DefaultRangeSettings = {
    minimum: 0,
    ranges: [{
        name: 'Default',
        upperLimit: 100,
        value: '#000000'
    }]
};

@RegisterWidget({tag:'profile-chart', displayName: 'Profile Chart', section: 'Charts', icon: Icon, template: template, editor: true})
export class ProfileChartWidget extends Widget {
    canvas: HTMLCanvasElement;
    tickInterval: ReturnType<typeof setInterval>;
    cruncher: Cruncher = new Cruncher();
    start: Date;
    end: Date;
    @Attribute({
        displayName: 'Ranges',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new RangeInput(name, parent, property, getValue, onSettingChangedCallback)
    }) rangeSettings: {minimum: number, ranges: {name: string, upperLimit: number, value: string}[]} = DefaultRangeSettings;

    @Attribute({
        displayName: 'Period',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new SelectInput(name, parent, property, getValue, ['Hour','Day','Week','Year'], ['3600','86400','604800','31540000'], onSettingChangedCallback)
    }) period: number = 86400;

    @Attribute({displayName: 'Periods'}) periods: number = 7;

    @TagAttribute({
        displayName: 'Data Tag',
        requiredProperties: ['logged'],
        supportedTypes: ['numeric']
    }) dataTag: TagDefinition;

    protected connectedCallback(): void {
        this.canvas = this.shadowRoot?.getElementById('canvas') as HTMLCanvasElement;
    }

    protected enliven(): void {
        let period      = this.period;
        let interval    = CalculateInterval(period * this.periods, 10000)
        this.end        = new Date(Math.floor((new Date().getTime() + new Date().getTimezoneOffset()) / 1000 / period) * period * 1000);
        this.start      = new Date((this.end.getTime() / 1000 - this.periods * period) * 1000);
        this.cruncher.requestData(this.start, this.end, [this.dataTag.tag], interval).then(([tags, data]) => this.createProfileGraph(data, interval))
    }

    createProfileGraph(data: HistoricalData, interval: number) {
        let rangeSettings = this.rangeSettings;
        let period  = this.period;
        let periods = this.periods;
        var fluxSets: any = [];
        let ranges = rangeSettings.ranges;
        for (let i = 0; i < periods; ++i) {
            let cumulative  = new Array(ranges.length).fill(0);
            let index       = 0; // keep track of this for speediness
            let dateColumn  = data[1];
            let startTime = this.start.getTime() + i * period * 1000;
            let indexes = new Array(data[0]!.length).fill(0);		// Indexes of how far we've iterated through each data set
            for (let time = startTime; time < startTime + period * 1000; time += interval * 1000) {	// Go through the entire interval they requested at the interval they requested
                let value: number | null = this.cruncher.getValueAtTime(time, index, data, indexes);
                if (value == null)
                    continue; //TODO: add null range for bad data
                for (let j=0;j<ranges.length;++j) {
                    if (value < ranges[j].upperLimit) {
                        cumulative[j]++;
                        break;
                    }
                }
            }
            fluxSets.push(cumulative);
        }
        let fluxColors: string[] = []
        ranges.forEach(range => {
            fluxColors.push(range.value);
        });

        this.canvas.height = this.clientHeight;
        this.canvas.width = this.clientWidth;
        let ctx = this.canvas.getContext('2d')!;
        let dataLabels: number[] = [];
        for (let i=0;i<periods;++i)
            dataLabels.push(this.start.getTime() + i * period * 1000)
        let dataSets: any[] = [];
        for (let i=0;i<ranges.length;++i) {
            let dataSet: any = {
                label: ranges[i].name,
                backgroundColor: ranges[i].value,
                strokeColor: ranges[i].value,
                data: []
            }
            for (let j = 0; j < periods; ++j) {	// For each day we were given
                dataSet.data.push(fluxSets[j][i])
            }
            dataSets.push(dataSet)
        }

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: dataLabels,
                datasets: dataSets
            },
            options: {
                scales: {
                x: {
                    stacked: true,
                    offset: true,
                    type: 'time',
                    time: {
                    parser: 'MM/DD/YYYY',
                    tooltipFormat: 'll',
                    unit: 'day',
                    displayFormats: {
                        'day': 'MM/DD/YYYY'
                    }
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Minutes'
                    },
                    stacked: true,
                    ticks: {
                        sampleSize: period / interval,
                    },
                    type: 'linear',
                    max: period / interval
                }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 0
                }
            }
        })
    }
}