import Icon from '../../../images/icons/name.svg';
import CloseIcon from '../../../images/icons/cancel.svg';
import { RegisterWidget, Widget } from '../../lib/widget';
import template from './modal.html';
import { Attribute } from '../../lib/attributes';
import { ColorInput } from '../../../views/attributeeditorview';

@RegisterWidget({ tag: 'hmi-modal', displayName: 'Modal', template: template, icon: Icon, section: 'Layout' })
export class Modal extends Widget {
    private titleElement: HTMLDivElement;
    private closeIcon: HTMLImageElement;
    @Attribute({ displayName: 'Title Text' }) titleText: string = 'Title';
    @Attribute({ displayName: 'Min Width' }) preferredWidth: number;     // modal container minimum width style setting
    @Attribute({ displayName: 'Max Width' }) preferredHeight: string;     // modal container maximum width style setting
    @Attribute({ displayName: 'Min Height' }) minHeight: string;     // modal container minimum height style setting
    @Attribute({ displayName: 'Max Height' }) maxHeight: string;     // modal container maximum height style setting
    @Attribute({
        displayName: 'Title Background Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => {
            return new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip);
        }
    }
    ) titleBackgroundColor: string = '#000000';
    @Attribute({
        displayName: 'Title Background Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => {
            return new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip);
        }
    }
    ) titleTextColor: string = '#ffffff';
    @Attribute({ displayName: 'Is Animated' }) fAnimated: boolean;    // flag for whether or not we want an animation on instantiation
    @Attribute({ displayName: 'Prevent Close' }) fUncloseable: boolean;
    closeCallback?: () => boolean;   // called just before modal is destroyed

    protected connectedCallback(): void {
        this.style.setProperty('--title-background-color', this.titleBackgroundColor);
        this.style.setProperty('--title-text-color', this.titleTextColor);
        this.style.setProperty('--preferred-width', this.preferredWidth?.toString() ?? '');
        this.style.setProperty('--preferred-height', this.preferredHeight);
        this.titleElement = this.shadowRoot?.getElementById('title-bar') as HTMLDivElement;
        this.closeIcon = this.shadowRoot?.getElementById('close-icon') as HTMLImageElement;
    }

    protected enliven(): void {
        this.titleElement.textContent = this.titleText;
        this.closeIcon.src = CloseIcon;
        this.closeIcon.onclick = () => {
            this.parentElement?.removeChild(this);
        }
    }
}