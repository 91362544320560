import { Node as Tag } from "../../../node";
import { RegisterWidget, Widget } from "../../lib/widget";
import { TagAttribute, } from "../../lib/tag";
import { Attribute } from "../../lib/attributes";
import Icon from '../../../images/icons/button.svg';
import type { TagDefinition } from "../../lib/tag";
import { ColorInput } from "../../../views/attributeeditorview";
import template from './pushbutton.html'

@RegisterWidget({tag: 'push-button', displayName: 'Push Button', icon: Icon, section: 'Input'})
export class PushButton extends Widget {
    protected valueElement: HTMLDivElement;
    protected checkElement: HTMLInputElement;
    protected container: HTMLElement;
    private _checked: boolean;
    onchange: ()=>void = ()=>{};

    @Attribute({displayName: 'Pressed Value'}) pressedValue: number = 0;
    @Attribute({displayName: 'Text'}) buttonText: string;
    @Attribute({
        displayName: 'Background Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) backgroundColor: string = '#FFFFFF';
    @Attribute({
        displayName: 'Border Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) borderColor: string = '#68548E';
    @Attribute({
        displayName: 'Text Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    })  textColor: string = '#1D1B20'

    render(): Node | null {
        this.style.setProperty('--background-color', this.backgroundColor);
        this.style.setProperty('--text-color', this.textColor);
        this.style.setProperty('--border-color', this.borderColor);

        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected connectedCallback(): void {
        this.container = this.shadowRoot!.querySelector('.container') as HTMLDivElement;
        this.checkElement = this.shadowRoot!.getElementById('check') as HTMLInputElement;
        let text  = this.shadowRoot!.getElementById('label') as HTMLDivElement;
        text.textContent = this.buttonText;

        this.checkElement.onchange = () => {
            this.onchange();
        }
    }

    protected enliven(): void {
        this.checkElement.checked = this.checked;
    }

    get checked() {
        return this._checked;
    }

    set checked(v: boolean) {
        this._checked = v;
        this.checkElement.checked = this._checked;
    }
}



@RegisterWidget({tag: 'tag-push-button', displayName: 'Push Button', icon: Icon, section: 'Input', editor: true, roles: ['PushButton']})
export class TagPushButton extends PushButton{
    @TagAttribute({
        displayName: 'Button Tag',
        shouldSubscribe: true,
        requiredProperties: ['writeable'],
        supportedTypes: ['boolean']
    }) buttonTag: TagDefinition;

    protected enliven(): void {
        super.enliven();
        let text  = this.shadowRoot!.getElementById('label') as HTMLDivElement;
        text.textContent = this.buttonText ?? this.buttonTag.tag.getDisplayName(false, true);

        this.onchange = () => {
            this.buttonTag.tag.setPendingValue(this.pressedValue, this);
        }
    }

    update(tag: Tag): void {
        this.container.classList.toggle('disabled', tag.getValue(this) == this.pressedValue)
    }
}

