import SVGWidget from './svgwidget';
import {Node} from '../node';
import LiveDataClient from '../livedataclient';

// The Alarm driver connects the alarms associated with a given area to a corresponding
// amber-colored circle with a number within representing the number of alarms in that
// area or a green circle with a checkmark in it corresponding to no alarms.

// Could also be turned off when there are no alarms with a command.
// Need to specify an alarm's area
// Necessary attributes:
//		data-se-node="ALARM'S/TAG"
//		data-se-lens="alarm"
//
// Other assumptions: Assumes that the element that has data-se-lens="alarm-box" is an SVG circle element.
// Otherwise, this will likely break.
//
// Supplemental attributes:
//		data-se-check="true|false"		default:true
//
//		data-se-text-offset="0|1|2|3"	defines how many quarter turn offsets the text on the
//											pump should be offset to look proper (not upside down or tilted)

export default class Alarm extends SVGWidget {
	static attach(nodeRoot, element) {
		return new this(nodeRoot, element, LiveDataClient.alarmManager, owner.alarmSidepanel);		// Don't like using owner here... Cannot think of a better way though ¯\_(ツ)_/¯
	}

	constructor(nodeRoot, element, alarmManager, alarmSidepanel) {
		super(nodeRoot, element);

		this.HTMLcontainer = SVGWidget.findFirstHTMLParent(this.element);
		this.alarmManager = alarmManager;
		this.alarmSidepanel = alarmSidepanel;
        let that = this;
        this.treeTimeout = setInterval(function(){
            if(that.treesComplete(that.devices)){
                that.readSettings();
                clearInterval(that.treeTimeout);
            }
        }, 250, that);			// Keep checking for if the tree is complete until it is
		this.numAlarms = 1;		// 1 Alarm at start b/c we don' know how many alarms we have, which should be an alarm.
	}

	get node() {
		return this.nodes[""];
	}

	// Handles doing most of the setup by reading from the SVG element's dataset.
	readSettings() {
		this.setupDevices([''], true);
		this.setupNodes([''], true);			// Don't want to subscribe to any nodes because the queries will notify us when an alarm status changes
        this.showCheck = SVGWidget.readFlag(this.element,"seCheck",'true');
        let number = Number(SVGWidget.readAttribute(this.element, "seTextOffset", "0"));		// Special layout thing to make sure that the alarm's text is properly oriented
        if (number === 0 || number) {
            this.textRotation = 90 * number;	// Rotation as multiple of 90 degrees because the rotation SVG transform is out of 360 and not pi or tau
        }
        else {
            assert(false, "Invalid argument passed to data-se-text-offset for an Alarm SVG widget.");
            this.textRotation = 0;
        }
		this.numAlarms = (!this.node) ? 1 : 0;
		this.updateSVG();
		if (!this.node) {
			this.postError("Node not found: " + this.nodePaths[""], this.parentElem);
			return false;
		}
		if (this.node instanceof Node) {		// Check because the node might instead be a Live Data Client
			this.queryKey = this.node.tree.device.key + this.node.getDeviceRelativePath();
			this.queryOptions = {devices:[this.queryKey]};
		}
		else if (this.node instanceof LiveDataClient) {	// If node is an LDC then query option should be over all of the devices the LDC knows about
			this.queryKeys = [];
			for (const dev of this.node.devices.array) {
				this.queryKeys.push(dev.key);
			}
			this.queryOptions = {devices:this.queryKeys};
		}
		else {
			assert(false, "Impossible path to node");		// Should never hit this, traversing the node path should either give a node or LDC
			return;
		}
		this.query = new AlarmQuery(this.queryOptions, this.update.bind(this));
		this.alarmManager.query(this.query);				// Make sure that we follow the alarmManager
		this.createParentSVG();
	}

	update(alarms, query) {									// We have some new alarms or fewer alarms
		this.numAlarms = alarms.length;
		this.updateSVG();
		return false;
	}

	createParentSVG() {										// Build the wrapper for any displayed SVG
        let conformBox = this.element.getBBox();
		conformBox.viewBox = "0 0 100 100";
		this.parentElem = SE.createSVGElement('svg', null, this.element.parentNode, conformBox);

		this.createAlarmStuff();							// Create the alarm circle and number text element
		this.alarmStuff.classList.add("hide");
		if (this.showCheck) {								// Create the check mark if desired
			this.createCheckmark();
			this.checkMark.classList.add("hide");
		}
		this.updateSVG();
	}

	// Creates the alarm circle and text inside.
	createAlarmStuff() {
		this.alarmStuff = SE.createSVGElement('g', null, this.parentElem);

		if (this.queryOptions)								// Event listeners to open up the Alarm sidepanel with our query options
			this.alarmStuff.addEventListener("click", () => {
				this.alarmSidepanel.openQuery(this.queryOptions);
			});
		else												// No query options specified... :`(
			this.alarmStuff.addEventListener("click", () => {
				this.queryOptions = {};
				this.alarmSidepanel.openQuery(this.queryOptions);
			});

		// Create the alarm's circle background in SAE Amber, maybe should change.
		// Also, do we want to allow css classes in here? Would lead to nicer looking code, but more files... IDK and IDC which way, so long as its consistant across SVG Widgets
		var style = "fill:#FF7E00;stroke:#FFF;stroke-width:5;";
		this.alarmCircle = SE.createSVGElement("circle", null, this.alarmStuff, {"style":style, r:"45", cx:"50", cy:"50"});

		// All of this style is likely just for a textbox that should rarely pop up.
		var style = "font-style:normal;font-weight:normal;font-size:40;font-family:sans-serif;fill:rgba(255,255,255,1.0);stroke:none;";
		this.alarmCount = SE.createSVGElement("text", null, this.alarmStuff, {"style":style, x:"50",
			"text-anchor":"middle", 					// Anchor in the middle to prevent being off center if there are multiple digits
			y:"60",
			transform:"rotate(" + this.textRotation + ", 50, 50)"});	// Keep us rotated in the correct orientation
	}

	createCheckmark() {
		// Create the checkmark.
		var style = "stroke:#0F0;stroke-width:10;fill:rgba(0,0,0,0);";
		this.checkMark = SE.createSVGElement("path", null, this.parentElem, {"style":style,d:"M 12.5 37.5 l 20 25 l 55 -37.5", transform:"rotate(" + this.textRotation + ", 50, 50)"});
	}

	// Not sure if we should make a dedicated createSVG method that would then allow this code to simply toggle
	// css hide class on or off appropriately
	updateSVG() {
		if (this.numAlarms > 0 && this.alarmStuff) {
			this.alarmStuff.classList.remove("hide");
			this.alarmCount.innerHTML = this.numAlarms.toString();		// Adjust the alarm count to match the alarms in the area specified
		}
		else {
			if (this.alarmStuff)	this.alarmStuff.classList.add("hide");
		}
		if (this.numAlarms <= 0 && this.checkMark) {
			this.checkMark.classList.remove("hide");
		}
		else if (this.checkMark) this.checkMark.classList.add("hide");
	}
}
