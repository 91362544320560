import { createElement } from "../../../elements";
import { Attribute } from "../../lib/attributes";
import { TagAttribute, TagSetAttribute } from "../../lib/tag";
import { RegisterWidget, Widget } from "../../lib/widget";
import RadioIcon from '../../../images/icons/radio-button.svg';
import type { Tag, TagDefinition } from "../../lib/tag";
import template from './radio.html';
import { Role } from "../../../role";
import { MapInput } from "../../../views/attributeeditorview";

/**
 * Base Radio Button class
 */
@RegisterWidget({tag: 'radio-button', template: template, displayName: 'Radio Button'})
export class RadioButton extends Widget {
    protected wrapper: HTMLElement;
    buttonLimit: number = 12;
    isOverLimit: boolean = false;
    _value: number;
    onchange = (e: Event)=>{};
    _disabled: boolean = false;
    protected buttonMap: Map<number, HTMLInputElement> = new Map();
    protected labelMap: Map<string, number> = new Map();
    @Attribute({displayName: 'Default Value'}) defaultValue: number = 1;
    @Attribute({displayName: 'Equal Button Widths'}) equalButtonWidths: boolean = false;
    @Attribute({displayName: 'Wrap Buttons'}) wrapButtons: boolean = true;

    get disabled() {
        return this._disabled
    }

    set disabled(value: boolean) {
        this._disabled = value;
        this.shadowRoot?.querySelectorAll(this.isOverLimit ? 'option' : 'input').forEach(inputElement => inputElement.disabled = this._disabled);
    }

    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'Zero'},
        {value: 1, text: 'One'},
        {value: 2, text: 'Two'}
    ];

    get value(): number {
        return this._value;
    }

    set value(v: number) {
        this._value = v;
        if (this.buttonMap.has(v)) {
            this.buttonMap.get(v)!.checked = true;
        }

    }

    protected connectedCallback(): void {
        this.wrapper = this.shadowRoot!.getElementById('container')!;
        if (this.equalButtonWidths)
            this.style.setProperty('--label-flex', '1');
        if (!this.wrapButtons)
            this.style.setProperty('--wrap-buttons', 'nowrap');
    }

    enliven() {
        this.labelMap.clear();
        this.buttonMap.clear();
        try {
            // Set up a button for each label:
            for (let {value, text} of this.valueMap) {
                if (this.labelMap.has(text)) {
                    this.buttonMap.set(value, this.buttonMap.get(this.labelMap.get(text)!)!);
                    continue;
                }
                let inputElement = createElement('input', '', this.wrapper, '', {type: 'radio', name: 'radioName', id: `radio_${value.toString()}`});
                this.buttonMap.set(value, inputElement);
                this.labelMap.set(text, value);
                let labelElement = createElement('label', '', this.wrapper, text, {htmlFor: value.toString()});	// trim leading and trailing whitespace
                inputElement.checked = value == this.value
                labelElement.onclick = () => {
                    inputElement.checked = true;
                    this._value = value;
                    this.onchange(new Event('change'));;
                }
            }
        } catch(reason) {
            throw(new Error('Invalid Label Map: ' + reason));
        }
        this.value = this.defaultValue;
    }
}

/**
 * Base Radio Button class
 */
@RegisterWidget({tag: 'tag-radio-button', displayName: 'Radio Button', editor: true, section: 'Input', icon: RadioIcon})
export class TagRadioButton extends RadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'Zero'},
        {value: 1, text: 'One'},
        {value: 2, text: 'Two'}
    ];

    @TagAttribute({
        displayName: 'Value Tag',
        supportedTypes: ['numeric'],
        shouldSubscribe: true
    }) valueTag: TagDefinition;

    enliven() {
        this.labelMap.clear();
        this.buttonMap.clear();
        try {
            // Set up a button for each label:
            let wrapper = this.shadowRoot!.getElementById('container')!;
            for (let {value, text} of this.valueMap) {

                if (this.labelMap.has(text)) {
                    this.buttonMap.set(value, this.buttonMap.get(this.labelMap.get(text)!)!);
                    continue;
                }
                let inputElement = createElement('input', '', wrapper, '', {type: 'radio', name: 'radioName', id: `radio_${value.toString()}`});
                this.buttonMap.set(value, inputElement);
                this.labelMap.set(text, value);
                let labelElement = createElement('label', '', wrapper, text, {htmlFor: value.toString()});	// trim leading and trailing whitespace
                inputElement.checked = value === this.value
                if (this.valueTag.tag.isWriteable) { // Not a read-only node and they have the permission to write it one day:
                    labelElement.classList.add('writeable');	// Add class for formatting, updating disabled flag based on user write permission changes
                    labelElement.onclick = () => {
                        inputElement.checked = true;
                        this.valueTag.tag.setPendingValue(value, this);
                        this._value = value;
                        this.onchange(new Event('change'));
                    }
                } else {	// node is not writeable, so do not set the 'writeable' class, and disable the button:
                    wrapper.classList.add('disabled');
                    inputElement.disabled = true;
                }
            }
        } catch(reason) {
            throw(new Error('Invalid Label Map: ' + reason));
        }
    }

    update(tag: Tag): void {
        this.value = parseFloat(tag.getValue(this));
    }
}

@RegisterWidget({tag: 'radio-button-hoa', displayName: 'Software HOA', roles: ['Bitwise_HOA', 'SoftwareHOA', 'Bitwise_HR', 'Radio_HOA', 'Bitwise_HARR', 'OverallHOA', 'AutoHOASwitch'], icon: RadioIcon, section: 'Setpoints'})
export class HOARadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 1, text: 'Hand'},
        {value: 2, text: 'Off'},
        {value: 3, text: 'Auto'}
    ];
}

@RegisterWidget({tag: 'radio-button-software-hoa', displayName: 'Software HOA', roles: [Role.ROLE_PUMP_AUTO_HOA_SWITCH], icon: RadioIcon, section: 'Setpoints'})
class SoftwareHOARadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 1, text: 'On'},
        {value: 2, text: 'Off'},
        {value: 3, text: 'Auto'}
    ];
}

@RegisterWidget({tag: 'radio-button-kubota-train', displayName: 'Train Select', roles: ['Kubota_Train_Select'], icon: RadioIcon, section: 'Kubota'})
class TrainSelectRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 1, text: 'MBR 1'},
        {value: 2, text: 'MBR 2'},
    ];
}

@RegisterWidget({tag: 'radio-button-kubota-hor', displayName: 'HOR', roles: ['HOR'], icon: RadioIcon, section: 'Kubota'})
class HORRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 2, text: 'REMOTE'},
        {value: 0, text: 'OFF'},
        {value: 1, text: 'HAND'},
        {value: 3, text: 'REMOTE'}
    ];
}

@RegisterWidget({tag: 'radio-button-kubota-mbr-state', displayName: 'MBR State', roles: ['Kubota_MBR_State'], icon: RadioIcon, section: 'Kubota'})
class MBRStateRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'Offline'},
        {value: 1, text: 'Filter'},
        {value: 2, text: 'Maintained'},
        {value: 3, text: 'Sleep'}
    ];
}

@RegisterWidget({tag: 'radio-button-kubota-cip-mode-cmd', displayName: 'CIP Mode', roles: ['Kubota_CIP_Mode_Cmd'], icon: RadioIcon, section: 'Kubota'})
class CIPModeCMDRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'Reset'},
        {value: 1, text: 'Start'},
        {value: 2, text: 'Pause'},
        {value: 3, text: 'Stop'}
    ];
}

@RegisterWidget({tag: 'radio-button-kubota-mbr-mode-cmd', displayName: 'MBR Mode', roles: ['Kubota_MBR_Mode_Cmd'], icon: RadioIcon, section: 'Kubota'})
class MBRModeCmdRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'Offline'},
        {value: 1, text: 'Production'},
        {value: 2, text: 'Sleep'},
        {value: 3, text: 'CIP'}
    ];
}

@RegisterWidget({tag: 'radio-button-kubota-eq-mode-cmd', displayName: 'EQ Mode', roles: ['Kubota_EQ_Mode_Cmd'], icon: RadioIcon, section: 'Kubota'})
class EQModeCmdRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'Offline'},
        {value: 1, text: 'ConstSpd'},
        {value: 2, text: 'Pulse'}
    ];
}

@RegisterWidget({tag: 'radio-button-kubota-pa-mode-cmd', displayName: 'PA Mode', roles: ['Kubota_PA_Mode_Cmd'], icon: RadioIcon, section: 'Kubota'})
class PAModeCmdRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'Offline'},
        {value: 1, text: 'ConstSpd'},
        {value: 2, text: 'Pulse'},
        {value: 3, text: 'DO'}
    ];
}

@RegisterWidget({tag: 'radio-button-kubota-naoh-mode-cmd', displayName: 'NaOH Mode', roles: ['Kubota_NaOH_Mode_Cmd'], icon: RadioIcon, section: 'Kubota'})
class ChemModeCmdRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'Offline'},
        {value: 1, text: 'ConstSpd'},
        {value: 2, text: 'Pulse'},
        {value: 3, text: 'pH'}
    ];
}

@RegisterWidget({tag: 'radio-button-kubota-alum-mode-cmd', displayName: 'NaOH Mode', roles: ['Kubota_Alum_Mode_Cmd'], icon: RadioIcon, section: 'Kubota'})
class AlumModeCmdRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'Offline'},
        {value: 1, text: 'FlowPaced'},
        {value: 2, text: 'Pulse'}
    ];
}

@RegisterWidget({tag: 'radio-button-kubota-stdby-blr-cmd', displayName: 'Standby Select', roles: ['Kubota_Stdby_Blr_Select'], icon: RadioIcon, section: 'Kubota'})
class StandbyBlowerCmdRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'None'},
        {value: 1, text: 'MBR1'},
        {value: 2, text: 'MBR2'},
        {value: 3, text: 'PA1'},
        {value: 4, text: 'PA2'},
        {value: 5, text: 'EQ'}
    ];
}

@RegisterWidget({tag: 'radio-button-kubota-stdby-prmpmp-cmd', displayName: 'Standby Select', roles: ['Kubota_Stdby_PrmPmp_Select'], icon: RadioIcon, section: 'Kubota'})
class StandbyPrmPmpCmdRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'None'},
        {value: 1, text: 'MBR1'},
        {value: 2, text: 'MBR2'}
    ];
}

@RegisterWidget({tag: 'radio-button-kubota-level-state', displayName: 'MBR Level State', roles: ['Kubota_MBR_LevelState'], icon: RadioIcon, section: 'Kubota'})
class MBRLevelStateRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'Stop'},
        {value: 1, text: 'Low Flow'},
        {value: 2, text: 'Med Flow'},
        {value: 3, text: 'High Flow'}
    ];
}

@RegisterWidget({tag: 'day-of-week-radio', displayName: 'Day of Week Picker', roles: ['DayOfWeek', 'ScheduleDayOfWeek'], icon: RadioIcon, section: 'Kubota'})
class DayOfWeekRadio extends TagRadioButton {
    @Attribute({
        displayName: 'Value Map',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new MapInput(name, parent, property, getValue, onSettingChangedCallback, 'Add Override')
    }) valueMap: {value: number, text: string}[] = [
        {value: 0, text: 'S'},
        {value: 1, text: 'M'},
        {value: 2, text: 'T'},
        {value: 3, text: 'W'},
        {value: 4, text: 'Th'},
        {value: 5, text: 'F'},
        {value: 6, text: 'Sa'}
    ];
}

