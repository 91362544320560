import { VType, type Tag, type TagMetadata, type TagTypeOptions, type TagPropertyOptions, type TagUnitOptions } from "./tag";
import { TagQuantityMap } from "./tagunits";
export function filterTags(tags: Tag[], metaData: TagMetadata): string[] {
    return tags.map(tag => filterTag(tag, metaData));
}

export function filterTag(tag: Tag, metaData: TagMetadata): string {
    if (metaData.supportedRoles && !metaData.supportedRoles.some(role => tag.roles.has(role)))
        return 'does not have supported roles';
    if (metaData.requiredProperties) {
        for (let prop of metaData.requiredProperties) {
            if (!filterByProperty(prop, tag))
                return `is not ${prop}`
        }
    }
    if (metaData.supportedUnits && !metaData.supportedUnits?.some(unit => filterByUnit(unit, tag)))
        return 'does not have supported units';
    if (metaData.supportedTypes && !metaData.supportedTypes?.some(type => filterByType(type, tag))) {
        return 'is not of a supported tag type';
    }
    return '';
}

function filterByProperty(prop: TagPropertyOptions, tag: Tag): boolean
{
    switch(prop)
    {
        case "logged":
            return tag.isLogged;
        case "writeable":
            return tag.isWriteable;
        case "scaled":
            return tag.engMin !== undefined && tag.engMax !== undefined
        case "subscribeable":
            return tag.vtype > VType.VT_UNKNOWN && tag.vtype < VType.VT_BLOCK;
        case "alarmConfigured":
            return tag.configured && tag.configured.length > 0;
        default:
            throw new Error(`Unexpected tag property specified: '${prop}'`);
    }
}

function filterByType(type: TagTypeOptions, tag: Tag): boolean
{
    switch(type)
    {
        case 'boolean':
            return tag.vtype == VType.VT_BOOL;
        case 'folder':
            return tag.children && tag.children.length > 0;
        case 'numeric':
            return tag.vtype != VType.VT_BLOCK && tag.vtype != VType.VT_BOOL && tag.vtype != VType.VT_STRING;
        case 'string':
            return tag.vtype == VType.VT_STRING;
        case 'root':
            return tag === tag.device.tree.nodes[0];
        default:
            throw new Error(`Unexpected tag type specified: '${type}'`);
    }
}

function filterByUnit(unit: TagUnitOptions, tag: Tag): boolean
{
    if(TagQuantityMap.has(unit))
        return (tag.units & TagQuantityMap.get(unit)!) != 1;
    else
        throw(new Error('Unsupported unit provided for tagProperties'))
}