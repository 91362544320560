import { createElement } from "../elements";
import Card from "./card";
import owner, { Routes } from "../../owner";
import './feedcard.css';
import View from "../views/view";
import ViewModal from "../viewmodal";
import TextInput from "../components/textinput";
import { getHash } from "../router/router";
import LiveDataClient from "../livedataclient";
import User from "../user";

export default class LinkCard extends Card {
    constructor(parent, editor, props) {
        if (!props) {
            props = {
                width: 200,
                height: 100,
                minWidth: 50,
                minHeight: 50,
                type: 'link',
            }
        }
        super(parent, editor, props);
        this.element.linkWrapper = createElement('div', 'card__link-wrapper', this.element)
        if (this.links)
            for (let i=0;i<this.links.length;i++) {
                let arg = this.links[i].href.split('/').pop()
                if (!isNaN(parseInt(arg))) // Number, so must be a dashboard link
                    this.links[i].href = getHash(Routes.Editor, {'id':arg});
                else
                    this.links[i].href = getHash(Routes.Device, {'key':arg});

                let button = createElement('div', 'se-button', this.element.linkWrapper, this.links[i].name);
                button.onclick= () => window.location.hash = this.links[i].href;
            }
        else {
            this.links = [];
            new ViewModal(new LinkView(()=>this.applyLink()), {maxWidth:'600px', maxHeight:'400px'});
        }
        return this;
    }

    onSettingsClicked() {
        new ViewModal(new LinkView(()=>this.applyLink()), {maxWidth:'600px', maxHeight:'400px'});
    }

    applyLink(name, href) {
        this.links.push({name: name, href: href})
        let button = createElement('div', 'se-button', this.element.linkWrapper, name);
        button.onclick= () => window.location.hash = href;
    }
}

class LinkView extends View {
    constructor(callback) {
        super();
        this.callback = callback;
        this.id = LiveDataClient.registerGraph(this);
        this.linkMap = new Map();
    }

    initialize(parent) {
        super.initialize(parent);
        this.wrapper = createElement('div', 'link-view__wrapper', this.parent);
        this.title = createElement('div', 'link-view__title', this.wrapper, 'Link Settings');
        this.nameInput = new TextInput(this.wrapper, 'Link Name', undefined, {
            'autocomplete': 'off',
            'autocapitalize': 'off',
            'autocorrect': 'off',
            'spellcheck': 'false',
            'background': 'true'
        });
        let typeWrapper = createElement('div', 'link-view__type-wrapper', this.wrapper);
        createElement('div', undefined, typeWrapper, 'Link Type: ');
        this.typeSelect = createElement('select', 'link-view__selector', typeWrapper);		// Give the user a drop down selector to change what type of link they want
        this.typeSelect.onchange = () => this.onTypeSelectorChange();
        this.dashboardOption = createElement('option', 'link-view__option', this.typeSelect, 'Dashboard');	// Put an option in the selector
        this.deviceOption = createElement('option', 'link-view__option', this.typeSelect, 'Device');	// Put an option in the selector
        let linkWrapper = createElement('div', 'link-view__type-wrapper', this.wrapper);
        createElement('div', undefined, linkWrapper, 'Link Page: ');
        this.linkSelect = createElement('select', 'link-view__selector', linkWrapper);		// Give the user a drop down selector to change companies
        this.apply = createElement('div', 'se-button', this.wrapper, 'Apply')
        this.apply.onclick = () => {
            this.callback(this.nameInput.value, this.linkMap.get(this.linkSelect.selectedOptions[0]))
        }
        LiveDataClient.getDashboards(this.id, owner.menuPanel.getCompanyKey());
    }

    onTypeSelectorChange() {
        this.linkSelect.removeChildren();
		switch(this.typeSelect.selectedOptions[0].text) {
            case 'Dashboard':
                LiveDataClient.getDashboards(this.id);
                break;
            case 'Device':
                for (let i=0;i<User.devices.sorted.length;i++) {
                    let device = User.devices.sorted[i];
                    let link = createElement('option', 'link-view__option', this.linkSelect, device.siteName);	// Put an option in the selector
                    this.linkMap.set(link, getHash(Routes.Device, {'key':device.key}))
                }
        }
    }

    onDashboardsResponse(fp) {
        let count = fp.pop_u32();
        for (let i=0;i<count;i++) {
            let id      = fp.pop_u32();
            let name    = fp.pop_string();
            let version = fp.pop_u16();
            let creator = fp.pop_string();
            let deviceCount = fp.pop_u32();
            for (let j=0;j<deviceCount;++j) {
                fp.pop_string();
            }
            let link = createElement('option', 'link-view__option', this.linkSelect, name);	// Put an option in the selector
            this.linkMap.set(link, getHash(Routes.Editor, {'id':id}))
        }
    }
}