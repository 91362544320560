import { RegisterWidget } from "../../lib/widget";
import template from './alarmtimeline.html';
import { Chart } from "chart.js";
import Cruncher from '../../../cruncher';
import { AlarmTypes, type Alarm } from "../../../alarm";
import { AlarmWidget } from "../alarm";
import Icon from '../../../images/icons/gantt.svg';

const DefaultRangeSettings = {
    minimum: 0,
    ranges: [{
        name: 'Default',
        upperLimit: 100,
        value: '#000000'
    }]
};


@RegisterWidget({tag:'alarm-timeline', displayName: 'Alarm Timeline', icon: Icon, template: template, editor: true, section: 'Alarms'})
export class AlarmTimeline extends AlarmWidget {
    canvas: HTMLCanvasElement;
    tickInterval: ReturnType<typeof setInterval>;
    cruncher: Cruncher = new Cruncher();
    start: Date;
    end: Date;

    protected connectedCallback(): void {
        this.canvas = this.shadowRoot?.getElementById('canvas') as HTMLCanvasElement;
    }

    onHistoricalAlarmsFinished(alarms: Alarm[], trendAlarms: Alarm[], start: number, end: number) {
        let alarmActivations    = alarms.filter(event=>{return event.type == AlarmTypes.ACTIVATED});
        let alarmDeactivations  = alarms.filter(event=>{return event.type == AlarmTypes.DEACTIVATED});
        let alarmMap: Map<string, any[]> = new Map();
        for (let i=0; i< alarmActivations.length;++i) {
            let activationTime      = alarmActivations[i].time!
            let deactivationTime    = end
            let deactivationAlarm   = alarmDeactivations.find(element=>element.activated == activationTime);
            if (deactivationAlarm)
                deactivationTime = deactivationAlarm.time!;

            let activationTimes = {
                activated: Math.round(activationTime / 1000),
                deactivated: Math.round(deactivationTime / 1000)
            }
            //@ts-ignore
            if (alarmMap.has(alarmActivations[i].message)) {
                //@ts-ignore
                alarmMap.get(alarmActivations[i].message).push(activationTimes)
            }
            else {
                //@ts-ignore
                alarmMap.set(alarmActivations[i].message, [activationTimes])
            }
        }
        let ctx = this.canvas.getContext('2d')!;
        let dataLabels: any[] = [];
        let dataSets: any[] = [];

        let alarmNames = Array.from(alarmMap.keys());
        for (let i=0;i<alarmNames.length;++i) {
            let timestamps = alarmMap.get(alarmNames[i])!;
            dataSets.push({
                label: '',
                backgroundColor: 'rgba(0,0,0,0)',
                strokeColor: 'rgba(0,0,0,0)',
                data: [{
                    x: start / 1000,
                    y: dataSets.length,
                    tooltip: false,
                }],
                fill: true,
                stack: alarmNames[i],
            })
            let lastTime = start / 1000;
            for (let j=0;j<timestamps.length;++j) {
                let timestamp = timestamps[j];
                dataSets.push({
                    label: '',
                    backgroundColor: 'rgba(0,0,0,0)',
                    strokeColor: 'rgba(1,1,1,1)',
                    data: [{
                        x: timestamp.activated - lastTime,
                        y: dataSets.length,
                        tooltip: false,
                    }],
                    fill: true,
                    stack: alarmNames[i],
                },
                {
                    label: alarmNames[i],
                    backgroundColor: '#444fff',//owner.colors.hex(`--color-graph-${i + 1}`),
                    strokeColor: 'rgba(0,0,0,0)',
                    data: [{
                        x: timestamp.deactivated - timestamp.activated,
                        y: dataSets.length,
                        tooltip: true,
                        activationTime: timestamp.activated
                    }],
                    fill: true,
                    stack: alarmNames[i],
                })
                lastTime = timestamp.deactivated
            };
        }
        dataLabels.push(end / 1000)
        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: dataLabels,
                datasets: dataSets
            },
            options: {

                indexAxis: 'y',
                elements: {
                    point:{
                        radius: 0
                    }
                },
                scales: {
                    x: {
                        min: start / 1000,
                        max: end / 1000,
                        stacked: true,
                        type: 'time',
                        time: {
                            parser: 'MM/DD/YYYY',
                            tooltipFormat: '',
                            unit: 'day',
                            displayFormats: {
                                'day': 'MM/DD/YYYY'
                            }
                        }
                    },
                    y: {
                        labels: [''],
                        stacked: true
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (tooltipItem) => {
                                //@ts-ignore
                                if (tooltipItem.dataset.data[tooltipItem.dataIndex].tooltip) {
                                    //@ts-ignore
                                    return tooltipItem.dataset.label + ' ' + new Date(tooltipItem.dataset.data[tooltipItem.dataIndex].activationTime).toLocaleString();
                                }
                                return ''
                            },
                            title: ()=>''
                        }
                    },
                    legend: {
                        labels: {
                            filter: (item, chart) => {
                                let entries = chart.datasets.map(e => e.label);
                                return entries.indexOf(item.text) === item.datasetIndex && item.text.length > 0;
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 0
                }
            }
        })
    }
}