import assert from './debug';
import { type Node, NodeTree } from './node';
import LiveDataJob from './livedatajob';
import {AlarmSet, ConfiguredAlarms} from './alarm';
import {PumpTwins} from './curves';
import LiveDataClient, { LoginStatus } from './livedataclient';
import FrameParser from './frameparser';
import LiveData from './livedata';
import { TagUnit, TagUnitQuantity } from './widgets/lib/tagunits';
import { Role } from './role';
import { getDependentWidgets } from './widgets/lib/widget';
import FrameMaker from './framemaker';
import { WritesEnabler } from './dialog';
import type { FormElement } from './formelements';
import { GroupInfo } from './accountmanager';
import type User from './user';
import type ConfigFormElement from './configform';

export interface PDSComponent {
    type: PDSType;
    pattern: string;
    help: string;
    min?: number;
    max?: number;
    value: any;
	values?: string[];
}

export enum PDSType {
    PDST_TextInput      = 0,
    PDST_NumericInput   = 1,
    PDST_Text           = 2,
	PDST_Select 		= 3,
	PDST_Node 			= 4
}

export interface DashboardDetails {
	version: number;
	id: number;
}

export class Driver {
	name: string;
	fRequiresDataType: boolean = false;
    sources: Map<string, PotentialDataSource> = new Map();
	type: string;
	constructor(fp: FrameParser, device: Device) {
		this.name 				= fp.pop_string();
		this.fRequiresDataType 	= fp.pop_u8() == 1;
		//this.type  				= fp.pop_string();
		let size        		= fp.pop_u32(); // how many sources does this driver have?
		for (let j=0;j<size;++j) {      // For each source
			let source = new PotentialDataSource(fp, device);
			this.sources.set(source.name, source);
		}
	}

	getSourceFromRegisterName(name: string) : string | undefined {
		assert(!this.fRequiresDataType);
		for (let [sourceName, pds] of this.sources) {
			if (pds.validateRegisterName(name))
				return sourceName;
		}
		assert(false);
	}
}

export class PotentialDataSource {
    name: string;
    hint: string;
    help: string;
    fDiscrete: boolean;
    fWriteable: boolean;
    components: PDSComponent[];
	defaultRegister: string;
	device: Device;
	constructor(fp: FrameParser, device: Device) {
		this.device 			= device;
		this.name               = fp.pop_string(),
		this.fDiscrete          = fp.pop_u8() == 1,
		this.fWriteable         = fp.pop_u8() == 1,
		this.hint               = fp.pop_string(),
		this.help               = fp.pop_string(),
		this.components         = [],
		this.defaultRegister	= ""
		let componentSize = fp.pop_u8();
		for (let k=0;k<componentSize;++k) {
			let component: PDSComponent = {
				type: fp.pop_u8(),
				help: fp.pop_string(),
				pattern: '',
				value: undefined
			}
			switch (component.type) {
				case PDSType.PDST_TextInput:
					component.value     = fp.pop_string();
					component.pattern   = fp.pop_string();
				break;
				case PDSType.PDST_NumericInput:
					component.min 		= fp.pop_u32();
					component.max 		= fp.pop_u32();
					component.value 	= component.min;
					component.pattern 	= "^[0-9]+";
				break;
				case PDSType.PDST_Text:
					let value = fp.pop_string()
					component.value = value;
					component.pattern = "^" + value;
				break;
				case PDSType.PDST_Select:
					let count = fp.pop_u16();
					let values: string[] = [];
					for (let i=0;i<count;++i)
						values.push(fp.pop_string());
					component.values 	= values;
					component.value 	= values[0] ?? '';
					if (component.values?.length > 0)
					{
						component.pattern 	= "(";
						component.values.forEach(value => {
							component.pattern += `${value}|`
						});
						component.pattern = `${component.pattern.slice(0,-1)})`;
					}
					else
						component.pattern = '';
				break;
				case PDSType.PDST_Node:
					component.pattern 	= "^[A-Z]+(\.[A-Z0-9]+)*:\/([A-Za-z0-9]+(\/[A-Za-z0-9]+)*)?$";
					component.value 	= this.device.key + ':/';
				break;
				default:
					assert(false, "Received a bad PDS_Type");
				break;
			}
			this.defaultRegister += component.value;
			this.components.push(component);
		}
	}

	validateRegisterName(registerName: string): boolean {
		let copy = registerName.slice();
		for (let i=0;i<this.components.length;++i) {
			let component 	= this.components[i];
			let match: RegExpMatchArray | null = null;
			switch (component.type) {
				case PDSType.PDST_Text:
				case PDSType.PDST_TextInput:
				case PDSType.PDST_Node:
					match       = copy.match(component.pattern); 	// get the first string that matches the component pattern
				break;
				case PDSType.PDST_NumericInput:
					match 			= copy.match(component.pattern);
					if (!match || match.length < 1)
						return false;
					let matchString = copy.substring(0, match[0].length)
					let parsedNumber = parseFloat(matchString);
					if (parsedNumber < component.min! || parsedNumber > component.max!)
						return false;
				break;
				case PDSType.PDST_Select:
					if (!component.values)
						return false;
					for (let j=0;j<component.values.length;++j) {
						match = copy.match("^"+component.values[j]);
						if (match)
						 	break;
					}
			}
			if (!match || match.length < 1)
				return false;
			copy = copy.substring(match[0].length);     // lop off the portion of the string that matched so we can move to the next component
		};
		return copy.length == 0;
	}
}

export interface DeviceAttributes {
	id: number;
	cachedTree: boolean;
	connected: boolean;
	key: string;
	owner: string; // Company name
	siteName: string;
	timeZone: string;
	latitude: number;
	longitude: number;
	groups: GroupInfo[];
	cachedUnitsMap: Map<TagUnitQuantity, TagUnit>;
	customFiles: string[];
	companyKey: string;
}

export function parseDeviceFromFrame(fp: FrameParser): DeviceAttributes {
	let deviceAttributes: Partial<DeviceAttributes> = {};

	deviceAttributes.id         = fp.pop_u32();      // Device id
	deviceAttributes.connected  = fp.pop_u8() != 0;  // True if the device is connected
	deviceAttributes.cachedTree = fp.pop_u8() != 0;  // True if the device's NodeTree is on whoville
	deviceAttributes.key        = fp.pop_string();   // device key
	deviceAttributes.owner      = fp.pop_string();   // owner name
	deviceAttributes.siteName   = fp.pop_string();   // site name
	deviceAttributes.timeZone   = fp.pop_string();   // Olson time zone
	deviceAttributes.latitude   = fp.pop_f64();
	deviceAttributes.longitude  = fp.pop_f64();
	deviceAttributes.groups     = [];                // Empty array. Will be filled out by the LDC

	deviceAttributes.cachedUnitsMap = new Map();
	var unitCount 		= fp.pop_u8();			// Number of quantity/unit pairs appended
	for (var i = 0; i < unitCount; ++i) {		// For each pair
		var quantity	= fp.pop_u16();			// Get the quantity
		let newQuantity = fp.pop_u16();
		deviceAttributes.cachedUnitsMap.set(quantity, newQuantity);	// Get the units and save in the map on the tree
	}

	deviceAttributes.customFiles = [];
	var fileCount				 = fp.pop_u16();						// Number of custom file names appended
	for (var i = 0; i < fileCount; ++i)
		deviceAttributes.customFiles.push(fp.pop_string());					// Add the name of each custom file to the array
	if (fp.command == LiveData.WVCR_GET_DEVICE_INFO_V2)
		deviceAttributes.companyKey = fp.pop_string();

	return deviceAttributes as DeviceAttributes;
}

export interface PumpTwinResponder {
	onPumpTwinsComplete: (pumpTwins: PumpTwins) => void;
}

// This module defines the Device and DeviceList objects:
export class Device implements DeviceAttributes {
	onConnect: 						Map<any, any> = new Map();
	onDisconnect: 					Map<any, any> = new Map();
	id: 							number;
	cachedTree: 					boolean;
	_connected: 					boolean;
	key: 							string;
	owner: 							string;
	siteName: 						string;
	timeZone: 						string;
	latitude: 						number;
	longitude: 						number;
	groups: 						GroupInfo[];
	tree: 							NodeTree;
	customFiles: 					string[];
	job: 							LiveDataJob;
	alarms: 						AlarmSet;
	configuredAlarms: 				ConfiguredAlarms;
	nodeTreeRequests: 				(()=>void)[] = [];
	fOutstandingTreeRequest: 		boolean = false;
	pumpTwinRequests: 				PumpTwinResponder[] = [];
	fOutstandingPumpTwinRequest: 	boolean = false;
	graphId: 						number;
	dashboards: 					Map<string, DashboardDetails> = new Map();
	pumpTwins: 						PumpTwins;
	index: 							number;
	drivers: 						Driver[] = [];
	fAssetOnly: 					boolean;
	tagger:							boolean = false;
	name: 							string;
	cachedUnitsMap: 			   	Map<TagUnitQuantity, TagUnit> = new Map();
	_isWriteable: 					boolean | null = null;
	companyKey: 					string;
	oldTree: 						NodeTree | null = null
	private isInitialized:			boolean = false;
	user: 							typeof User;
    constructor(deviceAttributes: DeviceAttributes, user: typeof User) {
		this.onConnect = new Map();
		this.onDisconnect = new Map();
		this.user = user;
		this._connected = false;


		Object.assign(this, deviceAttributes);
	}

	initialize() {
		this.tree				= new NodeTree(this);						// empty node tree
        this.job				= new LiveDataJob(this);					// Create the Live Data Job
		this.pumpTwins			= new PumpTwins(this);						// empty curves class
        this.alarms				= new AlarmSet(this, LiveDataClient);			// set of all active and unacknowledged alarms
		this.configuredAlarms 	= new ConfiguredAlarms(LiveDataClient, this);	// Create a set of configured alarms
		this.alarms.subscribe();									// go ahead and get the alarms
		this.isInitialized = true;
	}

	get connected() {
		return this._connected; // Note: this is an integer and will crash whoville if it ever isn't.
	}

	set connected(status) {
		if (status === this._connected)
			return; //don't do anything if nothing has changed
		this._connected = status;
		if (status) {
			// If we already initialized, we need to build up all our nodes again
			if (this.isInitialized) {
				if (this.tree.isComplete()) {// Have an existing tree, cache it
					this.oldTree = this.tree;
				}
				this.tree 				= new NodeTree(this);
				this.job 				= new LiveDataJob(this);
				this.pumpTwins			= new PumpTwins(this);						// empty curves class
				this.alarms				= new AlarmSet(this, LiveDataClient);			// set of all active and unacknowledged alarms
				this.configuredAlarms 	= new ConfiguredAlarms(LiveDataClient, this);	// Create a set of configured alarms
				this.alarms.subscribe();									// go ahead and get the alarms
				this.fOutstandingTreeRequest = true;
				this.tree.populate();
			}
			this.onConnect.forEach(fn => fn());
		} else {
			this.onDisconnect.forEach(fn => fn())
			this.fOutstandingTreeRequest 		= false;
			this.fOutstandingPumpTwinRequest 	= false;
		}
	}

	isTreeComplete() {	// Device has a complete, fully-formed node tree
		return this.tree.isComplete();
	}

	requestNodeTree(callback: () => void) {
		if (this.tree.isComplete()) { //we've already got it, give it back
			callback();
		} else {	//we should wait for it
			this.nodeTreeRequests.push(callback);

			if (!this.fOutstandingTreeRequest) {
				this.fOutstandingTreeRequest = true;
				if (this.connected || this.cachedTree) {
					this.tree.populate();
				}
			}
		}
	}

	isIncludedIn(group: GroupInfo): boolean {
		if (group.devices.includes(this.key))
			return true;
		else if (group.children.length > 0) {
			return group.children.some((childGroup) => this.isIncludedIn(childGroup))
		}
		else return false;
	}

	onNodeTreeComplete() {
		this.fOutstandingTreeRequest = false;
		this.tagger = this.tree.findNodesByRole(Role.ROLE_VERSION).length > 0;
		if (this.configuredAlarms && !this.configuredAlarms.isInitialized)
			this.configuredAlarms.request();	// Request all configured alarms for the site. TODO: Should this come across with the node metadata?

		if (this.oldTree) {
			this.oldTree.nodes.forEach(node => {
				node && getDependentWidgets(node).forEach(widget => {
					let newTag = this.tree.findNode(node.deviceRelativePath);
					widget.swapTags(node, newTag)
				});
			});
		}
		this.oldTree?.clear();
		this.oldTree = null;

		for (const callback of this.nodeTreeRequests) {
			callback();
		};
		this.nodeTreeRequests = []; //clear callbacks
	}

	requestPumpTwins(listener: PumpTwinResponder) {
		if (this.pumpTwins && this.pumpTwins.isComplete) {
			listener.onPumpTwinsComplete(this.pumpTwins);
		}
		else if (!this.tree.isComplete()) {
			this.requestNodeTree(() => this.requestPumpTwins(listener))
			return
		}
		else {
			this.pumpTwinRequests.push(listener)
			if (!this.fOutstandingPumpTwinRequest) {
				this.fOutstandingPumpTwinRequest = true;
				this.pumpTwins.populate();
			}
		}
	}

	onPumpTwinsComplete() {
		for (const listener of this.pumpTwinRequests) {
			listener.onPumpTwinsComplete(this.pumpTwins);
		};
		this.fOutstandingPumpTwinRequest 	= false; 				// are we waiting for curves?
		this.pumpTwinRequests = [];
	}

	getDisplayName(fUnits?: boolean) {
		return this.siteName;
	}

	requestDrivers(): Promise<Driver[]> {
		assert(LiveDataClient.isLoggedIn());
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.LDC_GET_DRIVERS, this.id);
		return new Promise<Driver[]>(resolve => {
			LiveDataClient.sendRequest(fm).then(fp => {
				this.drivers = [];
				let count = fp.pop_u16();
				for (let i=0; i<count; ++i)
					this.drivers.push(new Driver(fp, this));
				resolve(this.drivers);
			});
		});
	}

	getDriverForms(): Promise<FrameParser> {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.LDC_GET_DRIVER_FORMS, this.id);
		return LiveDataClient.sendRequest(fm)
	}

	submitDriverAttributes(driverID: string, driverName: string, form: FormElement | ConfigFormElement): Promise<FrameParser> {
		return new Promise(resolve => {
			new WritesEnabler(() => {
				let fm = new FrameMaker();
				fm.buildFrame(LiveData.LDC_SUBMIT_DRIVER_ATTRIBUTES, this.id);
				fm.push_string(driverID);
				fm.push_string(driverName);
				form.push(fm);
				LiveDataClient.sendRequest(fm).then(fp => resolve(fp));
			});
		})
	}

	submitTagConfig() {

	}

	addBaseline(type: number, a: number, b: number, c: number): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			if (a == 0 && b == 0 && c == 0)
				reject();

			let pumpSystem = this.tree.nodes[0]?.findChildByRole(Role.ROLE_PUMP_BANK);
			if (!pumpSystem)
				reject();

			let fm = new FrameMaker();
			fm.buildFrame(LiveData.LDC_ADD_BASELINE, this.id);
			fm.push_string(pumpSystem!.getDeviceRelativePath());			// Append pump system name
			fm.push_u32(new Date().getTime() / 1000);	// Append the time the curve becomes active
			fm.push_u8(type);
			fm.push_f64(a);							// Append the three curve coefficients
			fm.push_f64(b);
			fm.push_f64(c);
			LiveDataClient.sendRequest(fm).then(fp => resolve(fp.pop_bool()));
		});
	}

	getTrialData(start: number, end: number, fBaseline: boolean, trialFlags: number, savingsFlags: number): Promise<FrameParser> {
		assert(LiveDataClient.isLoggedIn(), "User not logged in!");
		assert(end > start, "Invalid trial data request.");
		assert((trialFlags > 0) || (savingsFlags > 0), "No trial data requested.");

		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_TRIAL_QUERY, this.id);
		fm.push_u32(start);				// Append interval start time
		fm.push_u32(end);					// Append interval end time
		fm.push_u8(fBaseline ? 1 : 0);		// Append if we want a baseline
		fm.push_u8(trialFlags);			// Append the flags we want
		fm.push_u8(savingsFlags);			// Append the flags we want
		return new Promise(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(fp)));
	}

	getRegimeCurves() {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.LDC_REGIME_CURVES, this.id);
		return new Promise<FrameParser>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(fp)));
	}

	getPointData(fBySpeed: boolean, regime: number, fAuto: boolean, speeds: Node[] | null, convert: number | null, pointIndex: number | null) {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.LDC_POINT_REQUEST, this.id);
		fm.push_u8(fBySpeed ? 1 : 0);
		fm.push_u8(fAuto ? 1 : 0);
		fm.push_u16(regime);
		if (fBySpeed)
			for (let i = 0; i < speeds!.length; ++i)
				fm.push_f32(speeds![i].getValue() / convert!);
		else
			fm.push_u16(pointIndex);
		return new Promise<FrameParser>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(fp)));
	}

	getPumpCurves(start: number, end: number, config: number) {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_PUMP_CURVES, this.id);
		fm.push_u64(start);
		fm.push_u64(end);
		fm.push_u64(config);
		return new Promise(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(fp)));
	}

	getTestChecklists() {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.LDC_TEST_CHECKLISTS, this.id);
		return new Promise<FrameParser>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(fp)));
	}

	getSchedule(endTime: number): Promise<{time: number, index: number}[]> {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.LDC_SCHEDULE_REQUEST);
		fm.push_u32(endTime);
		return new Promise<{time: number, index: number}[]>(resolve => {
			LiveDataClient.sendRequest(fm).then(fp => {
				let count = fp.pop_u32();
				let controls: {time: number, index: number}[] = [];
				for (let i = 0; i < count; ++i) {
					let time = fp.pop_u32();
					let index = fp.pop_u8();
					controls.push({ time: time, index: index });
				}
				resolve(controls)
			});
		});
	}

	getGraphs() {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_GET_GRAPHS, this.id);
		return LiveDataClient.sendRequest(fm);
	}

	getConfigFile(): Promise<Blob> {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.LDC_GET_CONFIG_FILE, this.id);
		return new Promise<Blob>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(new Blob([<ArrayBuffer>fp.frame.buffer.slice(24)], { type: 'application/vnd.ms-excel' }))));	// Create a new Excel blob
	}

	setConfigFile(file: string, fileSize: number): Promise<FrameParser> {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.LDC_SET_CONFIG_FILE, this.id);
		fm.push_bytes(file, fileSize);
		return LiveDataClient.sendRequest(fm);
	}

	getStartupLog(): Promise<{type: number, text: string}[]> {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_GET_LOG_FILE, this.id);
		return new Promise<{type: number, text: string}[]>(resolve => {
			LiveDataClient.sendRequest(fm).then(fp => {
				let log: {type: number, text: string}[] = [];
				let count = fp.pop_u32();
				for (let i = 0; i < count; ++i) {
					log.push({
						type: fp.pop_u8(),
						text: fp.pop_string()
					});
				}
				resolve(log);
			});
		});
	}

	getForm() {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.LDC_GET_FORM, this.id);
		return LiveDataClient.sendRequest(fm);
	}

	isWriteable() {
		return this._isWriteable ?? this.findPermissions();
	}

	findPermissions() {
		this._isWriteable = this.user.canWrite(this) ?? false;
		return this._isWriteable;
	}
};

// DeviceList -- holds all visible devices, both connected and disconnected:
export class DeviceList {
	array: Device[] = [];
	sorted: Device[] = [];
	user: typeof User;
	constructor(user: typeof User) {
		this.user = user;
		// These are unsolicited messages from Whoville, and require no response
		LiveDataClient.registerCommandResponse(LiveData.WVC_EVENT_COMMAND, (fp) => this.onEventCommand(fp)); // Whoville is sending us subscriber info regarding alarms/events for devices:
		LiveDataClient.registerCommandResponse(LiveData.WVCR_EVENT_COMMAND, (fp) => this.onEventCommand(fp));// Whoville is sending us a response
		LiveDataClient.registerCommandResponse(LiveData.LDC_TAG_UPDATE, (fp) => this.onTagUpdate(fp));
		LiveDataClient.registerCommandResponse(LiveData.WVC_CONNECTED, (fp) => this.onDeviceStatusChanged(fp));	// Device just connected
		LiveDataClient.registerCommandResponse(LiveData.WVC_DISCONNECTED, (fp) => this.onDeviceStatusChanged(fp)); // Device just disconnected
	}
    //Prototype values for all DeviceList objects to inherit upon construction:
	push(device: Device) {
		device.index = this.array.length;	// Store the DeviceList index in device
		this.array.push(device);			// And store device at end of array
	}

	sort() {
		this.sorted = this.array;
		this.sorted.sort((d1, d2) => d1.siteName.localeCompare(d2.siteName, undefined, { numeric: true }));
	}

	size() {
		return this.array.length;
	}

	clear() {
		this.array.length = 0;	// idiomatic way to clear JavaScript arrays
	}

	at(index: number) {
		return this.array[index];
	}

	get(id: number): Device | undefined { // get device by id O(n):
		for (var i = 0, device; device = this.array[i]; ++i) {
			if (device.id == id)
				return device;
		}
		return undefined;
	}

    getByKey(key: string) : Device | undefined { // get device by key O(n):
        for (var i = 0, device; device = this.array[i]; ++i) {
			if (device.key === key)
				return device;
		}
		return undefined;
    }

	populate(key: string = ''): Promise<void> { // Request list of all devices this user can see:
		return new Promise(resolve => {
			assert(LiveDataClient.isLoggedIn());
			let fm = new FrameMaker();
			fm.buildFrame(LiveData.WVC_GET_DEVICE_INFO_V2);
			fm.push_string(key ? key : '');	// Empty prefix -> get all devices
			LiveDataClient.sendRequest(fm).then(fp => {
				let deviceCount = fp.pop_u32();
				for (let i = 0; i < deviceCount; ++i) {			// deserialize all devices visible to this user:
					let device = new Device(parseDeviceFromFrame(fp), this.user);
					let oldDevice = this.getByKey(device.key);

					if (oldDevice) {
						oldDevice.connected = device.connected;
					}
					else {
						device.initialize();
						this.push(device);	// store each new device in devices collection
					}
					//@ts-ignore
					if (typeof window.isReportedDevice !== 'undefined') {
						//@ts-ignore
						if (!window.isReportedDevice(device.key))
							continue;
					}
				}

				this.sort();

				// Add all of the company level custom files that this user can see
				var fileCount = fp.pop_u16();
				for (var i = 0; i < fileCount; ++i)
					LiveDataClient.customFiles.push(fp.pop_string()); // TODO: should this live here? Yes
				resolve();
			});
		});
	}

	onEventCommand(fp: FrameParser) {
		const device = this.get(fp.wv_id);
		assert(device, 'Device should exist');		// WTF
		if (device)	// Process remainder of frame bytes:
			device.alarms.onEventCommand(fp);
	}

	onTagUpdate(fp: FrameParser) {
		const device = this.get(fp.wv_id)!;
		device.job.onTagUpdate(fp);
	}

	onDeviceStatusChanged(fp: FrameParser) {
		// Note: the protocol documents state that these commands can have multiple device ids in the
		// message payload, but Whoville currently only sends one-at-a-time. So, for now, just
		// process a single id.
		let fConnected = (fp.command == LiveData.WVC_CONNECTED);
		let deviceID = fp.pop_u32();
		let device = this.get(deviceID);

		assert(device, 'Device id from WV should match one of our devices');

		if (device && (device.connected != fConnected)) { 	// match and connection status changed:
			if (!fConnected) {								// If this device is now no longer connected
				//				device.alarms.clear();						// Clear all alarms
				//				device.configuredAlarms.clear();			// Clear all configured alarms
				device.tree.setDisconnected();				// Set bad quality on every tag
				device.connected = fConnected;
			} else {
				device.connected = fConnected;
			}
		}
	}
};
