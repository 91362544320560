import { UnitsMap } from './widgets/lib/tagunits';
import { createElement } from './elements';
import ArrowForwardIcon from './images/icons/arrow_forward.svg';
import { VType } from './node';
import LiveDataClient from './livedataclient';
import LiveData from './livedata';
import FrameMaker from './framemaker';
import assert from './debug';
import User from './user';

// This file defines FormElement tree branch objects:
export class FormElement {
    parent: FormElement | null;
    id: string;
    label: string;
    help: string;
    fRestarts: boolean;
    children: FormElement[] = [];
    childWrapper: HTMLElement;
    childDiv: HTMLElement;
    wrapper: HTMLElement;
    inputWrapper: HTMLElement;
    input: HTMLInputElement | HTMLSelectElement;
    value: any;
    error: string;
    cachedValue: any;
    user: any; // TODO: Define proper type

    constructor(parent: FormElement | null, fp: any, parentDiv: HTMLElement) {
        this.parent = parent;            // Null if root
        this.id = fp.pop_string();       // identifies form element value (all radio button sibs have same id)
        this.label = fp.pop_string();    // form element label/title/prompt
        this.help = fp.pop_string();     // help text
        this.fRestarts = fp.pop_u8() > 0;  // True if changing the element restarts the site code
        this.popGuts(fp);                // read guts of subclasses (if any)
        if (this.parent !== null)
            this.draw(parentDiv);        // Render the interface for this element
        FormElement.ElementMap[this.id] = this;  // Put ourselves in the map

        let kids = fp.pop_u16();         // number of children
        if (kids > 0) {                  // If there are kids, create the elements to make a sub-section
            if (this.parent !== null) {
                this.childWrapper = createElement('div', 'SectionWrapper', parentDiv);
                createElement('div', 'FormSectionSpacer', this.childWrapper);
            }
            else this.childWrapper = createElement('div', 'FormMainWrapper', parentDiv);

            this.childDiv = createElement('div', 'FormSection', this.childWrapper);
        }

        for (let i = 0; i < kids; ++i)   // For each child
            this.children.push(this.popChild(fp));  // Extract next child
    }

    popChild(fp: any): FormElement {     // Create child based on type
        switch (fp.pop_u16()) {  // Get Form element type
            case FormElement.Type.Title:        return new FormTitle(this, fp, this.childDiv, false);
            case FormElement.Type.TextBox:      return new FormTextBox(this, fp, this.childDiv);
            case FormElement.Type.CheckBox:     return new FormCheckBox(this, fp, this.childDiv, false);
            case FormElement.Type.RadioButton:  return new FormRadioButton(this, fp, this.childDiv);
            case FormElement.Type.SelectBox:    return new FormSelectBox(this, fp, this.childDiv);
            case FormElement.Type.NumericBox:   return new FormNumericBox(this, fp, this.childDiv);
            case FormElement.Type.Section:      return new FormTitle(this, fp, this.childDiv, true);
            case FormElement.Type.TimeBox:      return new FormTimeBox(this, fp, this.childDiv);
            case FormElement.Type.CheckSection: return new FormCheckBox(this, fp, this.childDiv, true);
            case FormElement.Type.DeviceSelect: return new FormDeviceSelectBox(this, fp, this.childDiv);
            default: assert(false, "Unknown type."); return new FormTitle(this, fp, this.childDiv);
        }
    }

    popValue(fp: any): any {
        const value: any = { type: fp.pop_u8() };  // Extract data type
        switch (value.type) {            // Based on type, extract data
            case VType.VT_UNKNOWN: break;
            case VType.VT_S64:    value.data = fp.pop_u64();    break;
            case VType.VT_STRING: value.data = fp.pop_string(); break;
            case VType.VT_F64:    value.data = fp.pop_f64();    break;
            case VType.VT_BOOL:   value.data = fp.pop_u8();     break;
        }
        return value;
    }

    popGuts(fp: any): void {}    // Default is no additional guts, so do nothing
    createGuts(): void {}
    pushGuts(fm: any): void { assert(false, "Someone hasn't added a pusher."); }
    update(value: any): void {}
    isChanged(): boolean { return false; }

    createInput(type: string, className: string = 'spinner config-form__spinner'): void {
        this.input = createElement('input', className, this.inputWrapper) as HTMLInputElement;
        this.input.type = type;                          // Give it the passed in type
        this.input.setAttribute('required', 'true');
        this.input.onchange = this.onInput.bind(this);
    }

    onInput(e: Event): void {
        this.input.classList.toggle('FormError', this.input.validationMessage.length > 0);

        if (this.fRestarts && this.isChanged())  // TODO: Only give this warning on a delay or on blur instead of on every change?
            alert("Warning: changing " + this.label + " will restart the program.");
    }

    draw(parentDiv: HTMLElement): void {  // Render
        this.wrapper = createElement('div', 'FormRow', parentDiv);           // Wrapper for this row
        const label = createElement('div', 'FormLabel', this.wrapper, this.label);  // Text label for row
        // createElement('span', 'tooltip', label, this.help);               // Old Tooltip
        label.setAttribute("title", this.help);                              // New Tooltip using tippy
        /*tippy(label,
        {
            position: 'right',
            animation: 'shift',
            duration: 60,
            arrow: true,
            theme: 'light',
            trigger: 'mouseenter',
        });*/
        this.inputWrapper = createElement('div', 'FormInputWrapper', this.wrapper);  // A wrapper for whatever we want (toggles, text, timers)
        this.createGuts();                                                  // Create guts, if any
    }

    push(fm: any): void {  // Add our attributes back into the frame
        if (this.id)   // Title elements don't have ids and don't need to append an attribute
            this.pushGuts(fm);           // Append id, attributes
        for (let i = 0; i < this.children.length; ++i)  // For each child
            this.children[i].push(fm);  // Push id, attribute pairs
    }

    popAttributes(fp: any): void {  // Get all the attributes from the server
        while (fp.bytesLeft > 0) {  // While there's still more
            const id = fp.pop_string();      // Get the element

            const element = FormElement.ElementMap[id];  // Get the element this is for
            element.value = this.popValue(fp);  // Pop the value

            element.error = fp.pop_string();                // Save the error string
            element.input.classList.toggle('FormError', element.error.length > 0);  // Update error message
            element.update(element.value);                        // Update the element's value
        }
    }

    submitAttributes(deviceID: number): void {
        if (!this.user.canModifyTags())
            return;
        const fm = new FrameMaker();
        fm.buildFrame(LiveData.LDC_SUBMIT_ATTRIBUTES, deviceID);
        this.push(fm);
        LiveDataClient.sendRequest(fm).then(fp => this.popAttributes(fp));
    }
}

// Static properties
export namespace FormElement {
    export const ElementMap: Record<string, FormElement> = {};  // To hold all elements, ordered by string-based ID
    export const RadioMap: Record<string, FormRadioButton[]> = {};  // To hold arrays with the sets of radio buttons, which will be ordered by their string-based ID
    export const Type = {            // Type enum
        Title:          0,
        TextBox:        1,
        CheckBox:       2,
        RadioButton:    3,
        SelectBox:      4,
        NumericBox:     5,
        Section:        6,
        TimeBox:        7,
        CheckSection:   8,
        DeviceSelect:   9
    };
}

class FormTitle extends FormElement {  // Title element subclass. Pretty much just extends FormElement
    arrow: HTMLImageElement;
    classList: DOMTokenList;

    constructor(parent: FormElement | null, fp: any, parentDiv: HTMLElement, fClickable: boolean = false) {
        super(parent, fp, parentDiv);  // Call parent class constructor
        if (fClickable && this.childDiv) {
            this.wrapper.onclick = this.onClick.bind(this);
            this.wrapper.classList.add('FormClickable');
            this.arrow = createElement('img', 'FormMenuArrow', this.inputWrapper, undefined, {'src': ArrowForwardIcon}) as HTMLImageElement;
            this.onClick();
        } else if (!fClickable)
            this.classList.add('FormMainTitle');
    }

    onClick(): void {  // Called when a title div is clicked
        this.childWrapper.classList.toggle('HiddenFormSection');  // Only show the selected children
        this.arrow.classList.toggle('FormMenuArrowFlipped');
    }
}

class FormTextBox extends FormElement {  // Text box subclass
    pattern: string;
    input: HTMLInputElement;
    popGuts(fp: any): void {
        this.pattern = fp.pop_string();  // Regex filter the input must match
    }

    createGuts(): void {  // Have to create an input element for the text field
        this.createInput('text');           // Make a text box input
        this.input.pattern = this.pattern;  // Make it match our regular expression
    }

    pushGuts(fm: any): void {
        fm.push_string(this.id);            // Append id
        fm.push_u8(VType.VT_STRING);        // Add a string type
        fm.push_string(this.input.value);   // Add the value
    }

    update(value: any): void {
        this.input.value = value.data;      // Update raw input text
        this.cachedValue = value.data;
    }

    isChanged(): boolean {
        return this.input.value != this.cachedValue;
    }

    onInput(e: Event): void {
        //this.input.classList.toggle('FormError', this.input.validationMessage.length > 0);

        if (this.fRestarts && this.isChanged())  // TODO: Only give this warning on a delay or on blur instead of on every change?
            alert("Warning: changing " + this.label + " will restart the program.");
    }
}

class FormCheckBox extends FormElement {  // Check box subclass
    input: HTMLInputElement;
    constructor(parent: FormElement | null, fp: any, parentDiv: HTMLElement, fClickable: boolean) {        // If fClickable is set, we should show or hide the child elements and update their disabled status
        super(parent, fp, parentDiv);                       // Call parent class constructor
        if (fClickable && this.childDiv) {                  // If we're clickable and actually have child elements
            this.wrapper.onclick = this.onClick.bind(this); // Bind the on click callback to ourselves
            this.wrapper.classList.add('FormClickable');    // Add the class to give it a pointer
            this.input.onclick = this.onChange.bind(this);  // Add an on change callback to the check box
            this.onChange(null);                            // Sync state on first call
        }
    }

    onClick(): void {  // Called when a title div is clicked
        this.childWrapper.classList.toggle('HiddenFormSection');  // Only show the selected children
    }

    onChange(e: Event | null): void {
        if (e)  // First call doesn't have an event in our constructor
            e.stopPropagation();    // Don't bubble up to the wrapper, which would show/hide children
        this.updateChildren(!this.input.checked, this);     // Update enabled status recursively
    }

    updateChildren(fDisabled: boolean, element: FormElement): void {
        for (let i = 0; i < element.children.length; ++i) {  // For each child
            const child = element.children[i];               // Get a convenience reference
            if (child.input)                        // If it has an input
                child.input.disabled = fDisabled;   // Update the diabled status
            this.updateChildren(fDisabled, child);  // Check all its children
        }
    }

    createGuts(): void {  // Have to create an input element
        // this.createInput('checkbox');  // Make a check box input

        const switcher = createElement('label', 'switch FormValue', this.inputWrapper);     // Create an input
        this.input = createElement('input', '', switcher) as HTMLInputElement;
        createElement('div', 'slider', switcher);
        this.input.type = 'checkbox';                                                      // Give it the passed in type
        this.input.setAttribute('required', 'true');
        this.input.onchange = this.onInput.bind(this);
    }

    pushGuts(fm: any): void {
        fm.push_string(this.id);            // Append id
        fm.push_u8(VType.VT_BOOL);          // Add a string type
        fm.push_u8(this.input.checked ? 1 : 0);     // Add the checked status
    }

    update(value: any): void {
        this.input.checked = value.data ? true : false;  // Update checked status
        this.cachedValue = value.data;
        this.onChange(null);                // Make sure everything is enabled or disabled as appropriate
    }

    isChanged(): boolean {
        return this.input.checked != this.cachedValue;
    }
}

class FormRadioButton extends FormElement {  // Radio button subclass
    input: HTMLInputElement;
    createGuts(): void {// Have to create an input element
        let buttons = FormElement.RadioMap[this.id];         // Check to see if this ID is already in the radio map
        if (buttons === undefined)                           // No array of buttons yet?
            buttons = FormElement.RadioMap[this.id] = [];    // Create one

        this.createInput('radio', 'radio-buttons__input');   // Make a radio button input
        this.input.name = this.id;                           // Link it to all radio buttons with this ID
        this.input.checked = buttons.length == 0;            // Start of with this one defauled
        buttons.push(this);                                  // Add this guy to the array
    }

    pushGuts(fm: any): void {
        const buttons = FormElement.RadioMap[this.id];   // Get all the radio buttons that have the same id
        if (buttons.indexOf(this) != 0)                  // If we aren't the first radio button
            return;                                      // Don't append us more than once

        fm.push_string(this.id);                        // Append id
        fm.push_u8(VType.VT_STRING);                    // Add a string type
        for (let i = 0; i < buttons.length; ++i)        // For each button
            if (buttons[i].input.checked) {             // If the button is checked
                fm.push_string(buttons[i].label);       // Add its label
                return;                                 // All done
            }
    }

    update(value: any): void {
        const buttons = FormElement.RadioMap[this.id];   // Get all the radio buttons that have the same id
        for (let i = 0; i < buttons.length; ++i)        // For each button
            buttons[i].input.checked = value.data == buttons[i].label;  // Set checked if it matches
        this.cachedValue = value.data;
    }

    isChanged(): boolean {
        const buttons = FormElement.RadioMap[this.id];   // Get all the radio buttons that have the same id
        for (let i = 0; i < buttons.length; ++i)        // For each button
            if (buttons[i].input.checked)               // If this is the checked input
                return this.cachedValue != buttons[i].label;  // Check if it has changed
        return false;
    }
}

class FormSelectBox extends FormElement {  // Selection box subclass
    options: any[];
    input: HTMLSelectElement;
    popGuts(fp: any): void {  // Selection boxes get extra data in the frame
        this.options = [];  // To hold our list of options
        const count = fp.pop_u16();                 // Option count
        for (let i = 0; i < count; ++i)            // For each option
            this.options.push(this.popValue(fp));  // Save the option in our set
    }

    createGuts(): void {  // Have to create an selection element
        this.input = createElement('select', 'FormValue', this.inputWrapper) as HTMLSelectElement;  // We create a selector
        this.input.onchange = this.onInput.bind(this);
        for (let i = 0; i < this.options.length; ++i)                                 // And for each option
            createElement('option', '', this.input).text = this.options[i].data;  // Add an entry
    }

    pushGuts(fm: any): void {
        const option = this.options[this.input.selectedIndex];
        fm.push_string(this.id);    // Append id
        fm.push_u8(option.type);    // Add the type
        switch (option.type) {      // Based on type, extract data
            case VType.VT_UNKNOWN:  return;
            case VType.VT_S64:      fm.push_u64(option.data);       return;
            case VType.VT_STRING:   fm.push_string(option.data);    return;
            case VType.VT_F64:      fm.push_f64(option.data);       return;
            case VType.VT_BOOL:     fm.push_u8(option.data);        return;
        }
    }

    update(value: any): void {
        for (let i = 0; i < this.options.length; ++i) {  // Go through each option
            if (this.options[i].data == value.data) {    // Find the option that matches the value
                this.input.selectedIndex = i;            // Set the index for that guy
                break;                                   // No need to go any further
            }
        }
        this.cachedValue = value.data;
    }

    isChanged(): boolean {
        const option = this.options[this.input.selectedIndex];  // Get the selected option
        return this.cachedValue != option.data;         // See if its value matches the data
    }
}

class FormDeviceSelectBox extends FormElement {  // Selection box subclass
    options: any[];
    input: HTMLSelectElement;
    createGuts(): void {  // Have to create an selection element
        this.input = createElement('select', 'FormValue', this.inputWrapper) as HTMLSelectElement;  // We create a selector
        this.input.onchange = this.onInput.bind(this);
        User.devices.array.forEach((device: any, i: number) => {
            createElement('option', '', this.input, device.siteName, {'value': device.key}).text = this.options[i].data;  // Add an entry
        });
    }

    pushGuts(fm: any): void {
        fm.push_string(this.id);    // Append id
        fm.push_string(this.input.options[this.input.selectedIndex].value);
    }

    update(value: any): void {
        this.input.value = value.data;
        this.cachedValue = value.data;
    }

    isChanged(): boolean {
        const option = this.options[this.input.selectedIndex];  // Get the selected option
        return this.cachedValue != option.data;         // See if its value matches the data
    }
}

class FormNumericBox extends FormElement {  // Numeric box subclass
    min: number;
    max: number;
    step: number;
    units: number;
    input: HTMLInputElement;
    popGuts(fp: any): void {  // Selection boxes get extra data in the frame
        this.min    = fp.pop_f64();
        this.max    = fp.pop_f64();
        this.step   = fp.pop_f64();
        this.units  = fp.pop_u16();
    }

    createGuts(): void {  // Have to create an input element
        this.createInput('number');     // Make a numeric spinner input
        this.input.min = this.min.toString();      // Save min and max
        this.input.max = this.max.toString();
        this.input.step = this.step.toString();    // Save step change
        createElement('label', "FormUnits", this.inputWrapper, UnitsMap.get(this.units)!.abbrev);
    }

    pushGuts(fm: any): void {
        fm.push_string(this.id);                // Append id
        if (this.step >= 1) {
            fm.push_u8(VType.VT_S64);               // Add a int type
            fm.push_u64(this.input.valueAsNumber);  // Add the checked status
        } else {
            fm.push_u8(VType.VT_F64);               // Add a float type
            fm.push_f64(isNaN(this.input.valueAsNumber) ? 0 : this.input.valueAsNumber);  // Add the checked status
        }
    }

    update(value: any): void {
        this.input.valueAsNumber = value.data;  // Update number value
        this.cachedValue = value.data;
    }

    isChanged(): boolean {
        return this.input.valueAsNumber != this.cachedValue;  // See if its value matches the cache
    }
}

class FormTimeBox extends FormElement {  // Time box subclass
    input: HTMLInputElement;
    createGuts(): void {  // Have to create an input element
        this.createInput('time');  // Make a time box input
    }

    pushGuts(fm: any): void {
        fm.push_string(this.id);                            // Append id
        fm.push_u8(VType.VT_S64);                           // Add a string type
        fm.push_u64(this.input.valueAsNumber / 1000);       // Add the checked status
    }

    update(value: any): void {
        this.input.valueAsNumber = value.data * 1000;  // Update checked status
    }
}