import owner from '../../owner';
import Switch from '../components/switch';
import { createElement } from '../elements';
import { Dashboard, ShareSettings } from '../pages/editorpage';
import User from '../user';
import DashboardView from './dashboardview';
import View from './view';
import { RadioSelector } from '../components/radio';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import Loader from '../loader';
import assert from '../debug';
import FrameParser from '../frameparser';
import { SerializedCompanyInfo } from './sharingsettingsview';
import avatar from '../components/avatar';

import ArrowIcon        from "../images/icons/arrow_down_filled.svg";
import LiveDataClient from '../livedataclient';

interface ScheduleInfo {
    scheduleID:     number;
    creator:        string;
    name:           string;
    frequency:      string;
    frequencyMod:   number;
    size:           number;
    offset:         number;
    sharedUsers:    string[];
}

export enum ReportPageSize {
    LETTER   = 0,
    LEGAL    = 1,
    A3       = 2,
    A4       = 3,
    A5       = 4,
}

interface SizeInfo {
    name:   string;
    width:  number;
    height: number;
}

enum ReportScheduleFrequency {
	RSCH_HOURLY     = 0,
    RSCH_DAILY      = 1,
    RSCH_WEEKLY     = 2,
    RSCH_MONTHLY    = 3,
    RSCH_ANNUALLY   = 4
};

export const PageMap: Map<ReportPageSize, SizeInfo> = new Map([
    [ReportPageSize.LETTER,  {name: "Letter",  width: 816, height: 1054}],
    [ReportPageSize.LEGAL,   {name: "Legal",   width: 816, height: 1346}],
    [ReportPageSize.A3,      {name: "A3",      width: 816, height: 1054}],
    [ReportPageSize.A4,      {name: "A4",      width: 816, height: 1054}],
    [ReportPageSize.A4,      {name: "A5",      width: 816, height: 1054}],
]);

export default class ReportScheduleView extends View {
    graphID         : number;
    loader          : HTMLElement;
    dashboard       : Dashboard;
    companyKey      : string;
    control         : HTMLElement;
    previewWindow   : HTMLElement;
    reportContainer : HTMLElement;
    view            : DashboardView;
    left            : HTMLElement;
    current         : HTMLElement;
    right           : HTMLElement;
    sizeInfo        : SizeInfo;
    currentPage     : number;
    pageNumber      : HTMLInputElement;
    maxPage         : number;
    pageMax         : HTMLElement;
    companySelector : HTMLSelectElement;
    id              : number;
    parent         : HTMLElement;
    settings       : HTMLSelectElement[] = [];
    userMap        : Map<HTMLSelectElement, any> = new Map();
    sharedUsers    : Map<string, ShareSettings> = new Map();
    userContainer  : HTMLElement;
    fInitialized   : boolean = false;
    users          : any[];
    uSelector      : Switch;
    userList       : HTMLElement;
    ldc            : LiveDataClient;
    constructor(ldc: LiveDataClient, dashboard: Dashboard) {
        super();
        this.ldc            = ldc;
        this.dashboard      = dashboard;
        this.id			    = this.ldc.registerGraph(this);
    }

    initialize(parent: HTMLElement) {
        super.initialize(parent);
        this.graphID            = this.ldc.registerGraph(this);
        this.wrapper            = createElement('div', 'report-schedule-view__wrapper', this.parent);
        let reportWrapper       = createElement('div', 'report-schedule-view__report-wrapper', this.wrapper);
        this.reportContainer    = createElement('div', 'report-schedule-view__report-container', reportWrapper);
        this.previewWindow      = createElement('div', 'report-schedule-view__preview', this.reportContainer);
        let pageControls        = createElement('div', 'report-schedule-view__page-controls', reportWrapper);

        let control            = createElement('div', 'report-schedule-view__control', this.wrapper);

        let enabledRow      = createElement('div', 'report-schedule-view__enabled', control);
        createElement('div', 'report-schedule-view__control__title', enabledRow, 'Create Reports');
        new Switch(enabledRow, this.dashboard.fReports, 'var(--color-primary)', state => this.dashboard.fReports = state);

        let recurrence      = createElement('div', 'report-schedule-view__recurrence', control);
        createElement('div', 'report-schedule-view__control__title', recurrence, 'Recurrence');

        this.userContainer  = createElement('div', 'report-schedule-view__sharing', control);
        createElement('div', 'report-schedule-view__control__title', this.userContainer, 'Notifications');

        if (this.ldc.isPowerUser()) {			// If the user is a Specific Energy, Inc. employee
			this.companySelector          = createElement('select', '', this.userContainer);// Give the user a drop down selector to change companies
			this.companySelector.onchange = this.onSelectorChange.bind(this);               // When the drop down changes, get users for that company
			this.ldc.getCompanies(this.id);  // Load available companies
		} else {																		    // Normal user
            this.ldc.getUserList(this.id, this.ldc.user.companyKey);
		}

        this.userList       = createElement('div', 'report-schedule-view__sharing__list', this.userContainer);


        let frequencyRow    = createElement('div', 'report-schedule-view__recurrence__frequency', recurrence);
        createElement('div', 'report-schedule-view__recurrence__text', frequencyRow, 'Repeat Every ');
        let frequencySelect = createElement('select', 'report-schedule-view__recurrence__frequency__select', frequencyRow);
        createElement('option', 'report-schedule-view__recurrence__frequency__select__option', frequencySelect, 'Hour', {'value':ReportScheduleFrequency.RSCH_HOURLY.toString()});
        createElement('option', 'report-schedule-view__recurrence__frequency__select__option', frequencySelect, 'Day', {'value':ReportScheduleFrequency.RSCH_DAILY.toString()})
        createElement('option', 'report-schedule-view__recurrence__frequency__select__option', frequencySelect, 'Week', {'value':ReportScheduleFrequency.RSCH_WEEKLY.toString()})
        createElement('option', 'report-schedule-view__recurrence__frequency__select__option', frequencySelect, 'Month', {'value':ReportScheduleFrequency.RSCH_MONTHLY.toString()})
        createElement('option', 'report-schedule-view__recurrence__frequency__select__option', frequencySelect, 'Year', {'value':ReportScheduleFrequency.RSCH_ANNUALLY.toString()})

        let weekModRow      = createElement('div', 'report-schedule-view__recurrence__frequency__sub hide', recurrence);
        new RadioSelector(weekModRow, {
            title: 'On ',
            buttons: [
                {name: '0', displayName: 'S'},
                {name: '1', displayName: 'M'},
                {name: '2', displayName: 'T'},
                {name: '3', displayName: 'W'},
                {name: '4', displayName: 'Th'},
                {name: '5', displayName: 'F'},
                {name: '6', displayName: 'Sa'},
            ],
            defaultSelection: this.dashboard.reportFrequencyMod.toString(),
            changeCallback: (name) => this.dashboard.reportFrequencyMod = parseInt(name)
        });

        let monthModRow     = createElement('div', 'report-schedule-view__recurrence__frequency__sub hide', recurrence);
        createElement('div', '', monthModRow, 'On day')

        let dayOfMonth      = createElement('input', 'report-schedule-view__recurrence__frequency__month-day spinner', monthModRow, undefined, {
            'type':     'number',
            'value':    this.dashboard.reportFrequency == ReportScheduleFrequency.RSCH_ANNUALLY ? moment('2020', 'YYYY').dayOfYear(this.dashboard.reportFrequencyMod).day().toString() : '1',
            'min':      '1',
            'step':     '1'
        });

        let yearModRow      = createElement('div', 'report-schedule-view__recurrence__frequency__sub hide', recurrence);
        createElement('div', '', yearModRow, 'On')
        let monthSelect     = createElement('select', 'report-schedule-view__recurrence__frequency__time', yearModRow);
        let monthDayInput   = createElement('input', 'report-schedule-view__recurrence__frequency__month-day spinner', yearModRow, undefined, {
            'type':     'number',
            'value':    this.dashboard.reportFrequency == ReportScheduleFrequency.RSCH_ANNUALLY ? moment('2020', 'YYYY').dayOfYear(this.dashboard.reportFrequencyMod).day().toString() : '1',
            'min':      '1',
            'step':     '1'
        });

        monthSelect.value = this.dashboard.reportFrequency == ReportScheduleFrequency.RSCH_ANNUALLY ? moment('2020', 'YYYY').dayOfYear(this.dashboard.reportFrequencyMod).month().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        }) : '01';
        let monthArray = moment.months('MM');

        for (let i=0;i<monthArray.length;++i) {
            createElement('option', '', monthSelect, monthArray[i], {'value':(i+1).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            })});
        }

        let updateAnnualFrequencies = () => {
            let dateHelper      = moment(`2020-${monthSelect.options[monthSelect.selectedIndex].value}-${(monthDayInput.valueAsNumber).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            })}}`, 'YYYY-MM-DD');
            console.log(dateHelper);
            this.dashboard.reportFrequencyMod = dateHelper.dayOfYear();
        }

        monthDayInput.oninput = monthDayInput.onchange = () => {
            if (monthDayInput.valueAsNumber < 1)
                monthDayInput.value = '1';
            else if (monthDayInput.valueAsNumber > parseInt(monthDayInput.max))
                monthDayInput.value = monthDayInput.max;
            updateAnnualFrequencies();
        }

        monthSelect.onchange = () => {
            let dateHelper      = moment(`2020-${monthSelect.options[monthSelect.selectedIndex].value}`, 'YYYY-MM')
            let daysInMonth     = dateHelper.daysInMonth();
            monthDayInput.max   = daysInMonth.toString();
            monthDayInput.dispatchEvent(new Event('change'));
            updateAnnualFrequencies()
        }

        let timeRow         = createElement('div', 'report-schedule-view__recurrence__frequency__sub hide', recurrence);
        createElement('div', '', timeRow, 'at')

        let hourSelect      = createElement('select', 'report-schedule-view__recurrence__frequency__time', timeRow);
        for (let i=1;i<=12;++i) {
            createElement('option', 'report-schedule-view__recurrence__frequency__select__option', hourSelect, `${i}:00`, {'value':i.toString()});
        }
        hourSelect.value = (this.dashboard.reportHourOffset % 12).toString();

        // Nah mahn it wasn't the AM-PM!
        let amPMSelect      = createElement('select', 'report-schedule-view__recurrence__frequency__time', timeRow);
        createElement('option', 'report-schedule-view__recurrence__frequency__select__option', amPMSelect, `AM`, {'value':'AM'});
        createElement('option', 'report-schedule-view__recurrence__frequency__select__option', amPMSelect, `PM`, {'value':'PM'});
        amPMSelect.value = this.dashboard.reportHourOffset > 11 ? 'PM' : 'AM';

        let tzWrapper     = createElement('div', 'report-schedule-view__recurrence__frequency__timezone-wrapper', timeRow);
        let tzSelect      = createElement('select', 'report-schedule-view__recurrence__frequency__time', tzWrapper);
        momentTZ.tz.names().forEach(tz => {
            createElement('option', 'report-schedule-view__recurrence__frequency__select__option', tzSelect, tz);
        });
        tzSelect.value = this.dashboard.reportTimezone;
        amPMSelect.onchange = hourSelect.onchange = tzSelect.onchange = () => {
            this.dashboard.reportHourOffset = parseInt(hourSelect.value) + (amPMSelect.value == 'AM' ? 0 : 12)
            this.dashboard.reportTimezone   = tzSelect.value;
        };

        let allRows = [weekModRow,monthModRow,yearModRow,timeRow];
        let hideAll = () => {allRows.forEach(row=>row.classList.add('hide'))};
        frequencySelect.onchange = () => {
            hideAll();
            this.dashboard.reportFrequency = parseInt(frequencySelect.options[frequencySelect.selectedIndex].value);
            switch (frequencySelect.options[frequencySelect.selectedIndex].value) {
                case ReportScheduleFrequency.RSCH_DAILY.toString():
                    timeRow.classList.remove('hide');
                break;
                case ReportScheduleFrequency.RSCH_WEEKLY.toString():
                    weekModRow.classList.remove('hide');
                    timeRow.classList.remove('hide');
                break;
                case ReportScheduleFrequency.RSCH_MONTHLY.toString():
                    monthModRow.classList.remove('hide');
                    timeRow.classList.remove('hide');
                break;
                case ReportScheduleFrequency.RSCH_ANNUALLY.toString():
                    yearModRow.classList.remove('hide');
                    timeRow.classList.remove('hide');
                break;
            }
        }

        this.loader             = createElement('div', 'report-schedule-view__loader', this.parent);
        new Loader(this.loader);
        this.view = new DashboardView(this.ldc, this.dashboard.id, undefined, () => this.onDashboardLoaded()).initialize(this.previewWindow);
        this.view.gizmoContainer.style.overflow = 'hidden';

        this.left               = createElement('button', 'report-schedule-view__button', pageControls);
        createElement('img', 'report-schedule-view__button__icon', this.left, undefined, {'src':ArrowIcon}).style.rotate = '90deg';
        let pageNumberContainer = createElement('div', 'report-schedule-view__page-number-container', pageControls);
        this.pageNumber         = createElement('input', 'report-schedule-view__page-input', pageNumberContainer, undefined, {'type':'number'});
        this.pageMax            = createElement('div', 'report-schedule-view__page-max', pageNumberContainer);
        this.right              = createElement('button', 'report-schedule-view__button', pageControls);
        createElement('img', 'report-schedule-view__button__icon', this.right, undefined, {'src':ArrowIcon}).style.rotate = '-90deg';
        this.right.onclick = () => {
            this.pageNumber.value = (++this.pageNumber.valueAsNumber).toString();
            this.updatePage();
        }
        this.left.onclick = () => {
            this.pageNumber.value = (--this.pageNumber.valueAsNumber).toString();
            this.updatePage();
        }
        this.pageNumber.onchange = () => {
            this.updatePage();
        }


        frequencySelect.value = this.dashboard.reportFrequency.toString();
        frequencySelect.dispatchEvent(new Event('change'));

        this.fInitialized = true;
        return this;
    }

    private onSelectorChange(): void {
        //this.accountTable.removeUserRows();								// Remove pre-existing rows
		var companyKey;
		if (this.companySelector) {									// If we can see multiple companies
			companyKey = this.companySelector.selectedOptions[0].value;	// Refresh the company we are looking at
            this.dashboard.companyKey = companyKey;
        }
		else
			companyKey = this.ldc.user.companyKey;					// Refresh the users associated with the owner's company
        this.ldc.getUserList(this.id, companyKey);
    }

    onCompaniesReceived(companies: SerializedCompanyInfo[]) {		// Got the companies back
        assert(Array.isArray(companies), "onCompaniesReceived has a bad callback");
        let sortedCompanies = companies.sort((c1, c2) => c1.name.localeCompare(c2.name, undefined, { numeric: true }));	// Insert each device alphabetically
		for (var i = 0; i < sortedCompanies.length; ++i)	// For each company we got
			createElement('option', '', this.companySelector, sortedCompanies[i].name).value = sortedCompanies[i].key;	// Put an option in the selector
        this.companySelector.value = this.dashboard.companyKey;
		this.onSelectorChange();					// Load the first company by default
    }

    onGetUserListResponse(fp: FrameParser) {
        this.userList.removeChildren();
        let userCount = fp.pop_u16();
        this.users = [];
        for (let i=0;i<userCount;++i) {
            this.users.push({
                username    : fp.pop_string(),
                firstName   : fp.pop_string(),
                lastName    : fp.pop_string()
            })
        }

        this.users.sort((u1, u2) => u1.lastName.localeCompare(u2.lastName, undefined, { numeric: true }));	// Insert each device alphabetically
        for (let i=0;i<this.users.length;++i) {
            let fReportUser = this.dashboard.reportUsers.get(this.users[i].username)
            let row = createElement('div', 'sharing-view__user-row', this.userList);
            let user = createElement('div', 'sharing-view__user', row);
            avatar(user, this.users[i].firstName, this.users[i].lastName);
            createElement('div', 'sharing-view__user__name', user, this.users[i].firstName + ' ' + this.users[i].lastName);
            let setting = this.sharedUsers.get(this.users[i].username);
            let fNotified = false;
            if (this.users[i].username == this.dashboard.creator || fReportUser)
                fNotified = true;
            let notificationSwitch = new Switch(row, fNotified, 'var(--color-orange-6)', state => {this.dashboard.reportUsers.set(this.users[i].username, state);            console.log(this.dashboard)
                            }                )
            if (this.users[i].username == this.dashboard.creator)
                notificationSwitch.disabled = true;
        }
    }

    onDashboardLoaded() {
        this.sizeInfo = PageMap.get(this.dashboard.reportSize)!;
        this.previewWindow.style.width  = this.sizeInfo.width + 'px'
        this.previewWindow.style.height = this.sizeInfo.height + 'px'
        let scale = Math.max(this.sizeInfo.width / this.reportContainer.clientWidth, this.sizeInfo.height / this.reportContainer.clientHeight);
        this.previewWindow.style.transform = `scale(${0.9/scale})`;
        this.maxPage = Math.ceil(this.view.gizmoContainer.scrollHeight / this.sizeInfo.height);
        this.view.wrapper.style.height = this.sizeInfo.height * this.maxPage + 'px'
        this.pageMax.textContent = `/ ${this.maxPage}`;
        this.pageNumber.value   = '1';
        this.updatePage();
        this.view.resize();
        this.loader.classList.add('hide');
    }

    updatePage() {
        if (this.pageNumber.valueAsNumber > this.maxPage)
            this.pageNumber.value = this.maxPage.toString();
        else if (this.pageNumber.valueAsNumber < 1)
            this.pageNumber.value = '1';
        this.previewWindow.scrollTo(0,(this.pageNumber.valueAsNumber - 1) * this.sizeInfo.height);
    }
}