import { Node as Tag } from '../../../node';
import { Attribute } from '../../lib/attributes';
import { TagAttribute } from '../../lib/tag';
import { RegisterWidget, Widget } from '../../lib/widget';
import type { TagDefinition } from "../../lib/tag";
import CloseIcon from '../../../images/icons/cancel.svg'
import template from './banner.html';
import { ColorInput } from '../../../views/attributeeditorview';

@RegisterWidget({tag: 'se-banner', displayName: 'Banner', template: template, section: 'Tag Information'})
export class Banner extends Widget {
    textElement: HTMLElement;
    imageElement: HTMLImageElement;
    oncancel: ((this: GlobalEventHandlers, ev: Event) => any) | null;
    @Attribute({
        displayName: 'Text Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => {
            return new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip);
        }}
    ) textColor: string = '#0000FF';
    @Attribute({
        displayName: 'Text Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => {
            return new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip);
        }}
    ) backgroundColor: string = '#F5EFF7';
    @Attribute({displayName: 'Pulse Animation'}) isAnimated : boolean = false;
    @Attribute({displayName: 'Cancellable'}) isCancellable: boolean = false;

    protected connectedCallback(): void {
        this.style.setProperty('--background-color', this.backgroundColor);
        this.style.setProperty('--text-color', this.textColor);
        this.imageElement = this.shadowRoot?.getElementById('hide-icon') as HTMLImageElement;
        this.imageElement.src = CloseIcon;
        this.imageElement.onclick = (e) => {
            this.oncancel && this.oncancel(e);
        }
        this.textElement = this.shadowRoot?.getElementById('text')!;
    }

    protected enliven(): void {
        this.imageElement.style.visibility = this.isCancellable ? 'visible' : 'hidden'
    }

    show() {
        this.style.display = 'flex';
        window.dispatchEvent(new Event('resize'));
    }

    hide() {
        this.style.display = 'none';
        window.dispatchEvent(new Event('resize'));
    }

    setText(v: string) {
        if (this.isConnected)
            this.textElement.textContent = v;
    }
}

@RegisterWidget({tag: 'tag-banner', displayName: 'Banner', section: 'Tag Information'})
export class TagBanner extends Banner {
    @TagAttribute({displayName: 'Tag', shouldSubscribe: true}) statusTag: TagDefinition;
    update(tag: Tag) {
        this.setText(tag.getFormattedText(false));
    }
}

@RegisterWidget({tag: 'lsg-maintenance-banner', displayName: 'Maintenance Banner', section: 'Tag Information'})
export class LSGMaintenanceBanner extends TagBanner {
    update(tag: Tag) {
        if (tag.getValue())
            this.show();
        else
            this.hide();
        this.setText(`Maintenance Underway: ${tag.getFormattedText(false)}`);
    }
}