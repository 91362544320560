import owner from '../../owner';
import TabBar from '../components/tabbar';
import assert from '../debug';
import Dialog from '../dialog';
import { Device } from '../device';
import './page.css';
import { Node } from '../node';

export default class Page {
    parent: HTMLElement;
    tabBar: TabBar;
    constructor(parent: HTMLElement, props?: {[key: string]: string}) { // all pages are given a parent element and an optional key-value pair of string properties
        if (owner.currentPage) {
            owner.currentPage.destroy();
        }
        owner.currentPage = this;
        assert(parent, 'A parent render node must be provided for each page');
        this.parent = parent;
    }

    destroy() {
        owner.selectedDevice = null;
        if (this.tabBar) {
            for (const view in this.tabBar.views) {//@ts-ignore // unselect all tabs
                if (this.tabBar.views[view].fInitialized)//@ts-ignore
                    this.tabBar.views[view].destroy()
            };
        }//@ts-ignore
		this.parent.destroyWidgets(true);	//@ts-ignore // Don't need to destroy our graph specifically
		this.parent.removeChildren();		// Delete any DOM elements left over
    }
    setProps(newProps: any) {
        const entries = Object.entries(newProps);
        for (const [key, value] of entries) { //@ts-ignore
            if (this.props[key] != value) { //@ts-ignore // check whether each property has changed
                this.props[key] = value;
            }
        }
    }

    onTimeZoneChanged() {
        this.resize();
    }

    onDeviceStatusChange(device: Device) {
        console.log('no custom onDeviceStatusChange method defined');
    }

    onConnectionStatusChanged(status: number, freshTwoFaKey?: string, freshTwoFaCookie?: string) {}

    onThemeChanged() {
        console.log('no custom onThemeChanged method defined')
    }

    onCompanySelected() {
        console.log('no custom onCompanySelect method defined');
    }

    refresh() { //@ts-ignore
        new this.constructor(this.parent, this.props);
    }

    resize() {

    }

    onNodeTreeChanged() {
        this.refresh();
    }

    onNodeAdded(node: Node) {

    }

    onNodeChanged(node: Node) {}

    onNodeRemoved(node: Node) {

    }

	makeNoMobileWarning() {
        let properties = {
            title:  'Warning',
            body:   'This page is not optimized for small screens. Please try again on a different device.'
        }
		new Dialog(document.body, properties);
	}
}