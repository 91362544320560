import owner, { Routes } from '../../owner';
import { createElement } from '../elements';
import AlarmIcon from '../images/icons/alarms.svg'
import CloseIcon from '../images/icons/close.svg'
import Logo from '../images/logo-white.png'
import SmallLogo from '../images/LogoSmall.png'
import ChangesIcon from '../images/icons/changes.svg'
import './navbar.css';
import Clock from './clock';
import ViewModal from '../viewmodal';
import ChangeView from '../views/changeview';
import HelpIcon from '../images/icons/help.svg';
import Dropdown from './dropdown';
import Dialog, { WritesEnabler } from '../dialog';
import SubmitTicketView from '../views/submitticketview';
import { getHash } from '../router/router';

export default class NavBar {
    constructor(parent) {
        this.parent = parent
        this.container = createElement('div', 'nav-bar__container', this.parent);
        this.navBar = createElement('div', 'nav-bar', this.container);
        this.menuButton = createElement('div', 'nav-bar__menu-button', this.navBar);
        // Hide the hamburger button if this is the interface for a single local device
        if (process.env.TARGET == "localDevice")
            this.menuButton.classList.add('hide');
        this.line1 = createElement('div', 'nav-bar__menu-button__line-1', this.menuButton);
        this.line2 = createElement('div', 'nav-bar__menu-button__line-2', this.menuButton);
        this.line3 = createElement('div', 'nav-bar__menu-button__line-3', this.menuButton);
        this.logoContainer = createElement('div', 'nav-bar__logo__container', this.navBar)
        this.logo = createElement('img', 'nav-bar__logo', this.logoContainer, undefined, { 'src': window.innerWidth < 620 ? SmallLogo : Logo });
        this.title = createElement('div', 'nav-bar__title', this.navBar);
        let helperContainer = createElement('div', 'nav-bar__icon-container', this.navBar)
        this.helpButton = createElement('img', 'nav-bar__icon-container__icon', helperContainer, undefined, { 'src': HelpIcon });
        createElement('img', 'nav-bar__icon-container__icon', this.helpButton, undefined, { 'src': ChangesIcon });

        let clockWrapper = createElement('div', 'nav-bar__clock__wrapper', this.navBar);
        let timeWrapper = createElement('div', 'nav-bar__clock__time-wrapper', clockWrapper);
        let time = createElement('div', 'nav-bar__clock__time', timeWrapper);
        let timeZone = createElement('div', 'nav-bar__clock__tz', timeWrapper);
        let timeZoneText = createElement('div', 'nav-bar__clock__tz__text', timeZone)
        let date = createElement('div', 'nav-bar__clock__date', clockWrapper);

        this.clock = new Clock(time, date, timeZoneText);
        //this.user = createElement('div', 'nav-bar__user', this.navBar);
        //this.avatar = createElement('div', '', this.user);
        let changesContainer = createElement('div', 'nav-bar__icon-container', this.navBar)
        this.changes = createElement('img', 'nav-bar__icon-container__icon', changesContainer, undefined, { 'src': ChangesIcon });
        this.changesBadge = createElement('div', 'nav-bar__icon-container__badge hide', changesContainer, '');
        let alarmContainer = createElement('div', 'nav-bar__icon-container', this.navBar)
        this.alarmButton = createElement('img', 'nav-bar__icon-container__icon', alarmContainer, undefined, { 'src': AlarmIcon })
        this.alarmBadge = createElement('div', 'nav-bar__icon-container__badge hide', alarmContainer, '');
        this.helpMap = new Map();


        //LiveDataClient.user.color = LiveDataClient.user.color ? LiveDataClient.user.color : 'var(--color-graph-' + Math.floor(Math.random() * 10 + 1) + ')';
        //avatar(this.avatar, (LiveDataClient.user.firstName ? LiveDataClient.user.firstName : ''), (LiveDataClient.user.lastName ? LiveDataClient.user.lastName : ''), undefined, 'var(--color-deep-purple-8)')

        this.changesBadge.style.backgroundColor = 'var(--color-blue-6)';
        this.alarmBadge.style.backgroundColor = 'var(--color-alarm-1)';

        if (owner.alarmPanel.alarmSummaries && owner.alarmPanel.alarmSummaries.alarmCount)
            this.alarmCount = owner.alarmPanel.alarmSummaries.alarmCount;

        this.menuButton.onclick = () => owner.menuPanel.toggle();
        this.logo.onclick = () => location.hash = getHash(Routes.Home);
        this.changes.onclick = () => {
            import('../settingsmanager').then(settingsManagerModule => {
                let changeView = new ChangeView(settingsManagerModule.default);
                let modal = new ViewModal(changeView, {
                    titleBackgroundColor: 'var(--color-red-6)',
                    titleTextColor: 'var(--color-inverseOnSurface)',
                    title: 'Confirm Changes',
                    maxWidth: '600px',
                    buttons: [{
                        title: 'Apply',
                        callback: () => {
                            new WritesEnabler(()=>{
                                settingsManagerModule.default.apply();
                                if (changeView.persistCheck.checked)
                                    settingsManagerModule.default.enablePersistedWriteMode(60 * 10 * 1000); // Enable for 10 minutes
                                else
                                    settingsManagerModule.default.disablePersistedWriteMode();
                                modal.destroy();
                            }, undefined, changeView.persistCheck.checked ? 60 * 10 : undefined);
                        },
                        borderColor: 'var(--color-primary)'
                    },
                    {
                        title: 'Cancel',
                        borderColor: 'var(--color-red-6)'
                    }]
                });
            })

        }

        this.helpButton.onclick = (e) => {
            let options = ['Help and Resources', 'Submit a Ticket', 'Contact Support'];
            for (let [title, helpObject] of this.helpMap) {
                options.push(title);
            }
            new Dropdown(e, options, (selection) => {
                if (selection === 'Help and Resources') {
                    window.window.open('https://specificenergy.atlassian.net/wiki/spaces/HELP/overview', '_blank');
                } else if (selection === 'Submit a Ticket') {
                    new ViewModal(new SubmitTicketView(), {
                        maxWidth: '700px',
                        title: 'Submit a Ticket',
                        titleBackgroundColor: 'var(--color-primary)',
                        titleTextColor: 'var(--color-inverseOnSurface)'
                    });
                } else if (selection == 'Contact Support') {
                    new Dialog(document.body, {
                        title: 'Contact Support',
                        body: 'Please contact our technical support staff if you have any questions.\n\n+1(512) 890-2765\ninfo@specificenergy.com'
                    })
                } else {
                    this.helpMap.get(selection)();
                }
            })
        };

        this.alarmButton.onclick = () => owner.alarmPanel.toggle();

        window.addEventListener('resize', this.resize.bind(this));
        this.titleChangeListener = (e) => this.setTitle(e.detail.title ?? this.title.textContent);
        addEventListener('onNavTitleChanged', this.titleChangeListener);
    };

    set alarmCount(value) {
        this.alarmBadge.textContent = value;
        if (value == 0)
            this.alarmBadge.classList.add('hide');
        else
            this.alarmBadge.classList.remove('hide');
    }

    set changeCount(value) {
        this.changesBadge.textContent = value;
        if (value == 0) {
            this.changesBadge.classList.add('hide');
        }
        else {
            this.changesBadge.classList.remove('hide');
        }
    }

    resize() {
        this.logo.src = window.innerWidth < 620 ? SmallLogo : Logo;
    }

    registerHelp(title, callback) {
        this.helpMap.set(title, callback)
    }

    unregisterHelper(title) {
        this.helpMap.delete(title);
    }

    openMenu() {
        this.line1.classList.add('toggle');
        this.line2.classList.add('toggle');
        this.line3.classList.add('toggle');
    }
    closeMenu() {
        this.line1.classList.remove('toggle');
        this.line2.classList.remove('toggle');
        this.line3.classList.remove('toggle');
    }
    openAlarm() {
        this.oldTitle = this.title.textContent;
        this.setTitle('')
        this.alarmButton.src = CloseIcon;
        this.alarmBadge.classList.add('hide');
    }
    closeAlarm() {
        if (this.oldTitle) this.setTitle(this.oldTitle);
        else this.setTitle('');
        this.alarmButton.src = AlarmIcon;
        this.alarmBadge.classList.remove('hide');
    }

    setTitle(e) {
        this.title.textContent = e;
    }

    destroy() {
        window.removeEventListener('resize', this.resize.bind(this), { once: true })
        this.container.removeChildren();
        this.parent.removeChild(this.container);
    }
};
