import Icon from '../../../images/icons/file_path.svg';
import { Attribute } from '../../lib/attributes';
import { TagAttribute } from '../../lib/tag';
import { RegisterWidget, Widget } from '../../lib/widget';
import type { TagDefinition } from "../../lib/tag";
import template from './tagpath.html';
import { SelectInput } from '../../../views/attributeeditorview';

@RegisterWidget({tag: 'tag-path', displayName: 'Tag Path', icon: Icon, editor: true, section: 'Tag Information', template: template})
export class TagPath extends Widget {
    @Attribute({
        displayName: 'Path Origin',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new SelectInput(name, parent, property, getValue, ['Global', 'Device'], ['global', 'device'], onSettingChangedCallback)
    }) pathOrigin: string = 'global';
    @TagAttribute({displayName: 'Path Tag'}) pathTag: TagDefinition;

    protected enliven(): void {
        let text = this.shadowRoot?.getElementById('text')!;
        switch (this.pathOrigin) {
            case 'global':
                text.textContent = this.pathTag.tag.absolutePath;
            break;
            case 'device':
                text.textContent = this.pathTag.tag.deviceRelativePath;
            break;
            default:
                throw(new Error('Invalid path origin provided'));
        }
    }
}