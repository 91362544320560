import assert from "./debug";

//'Private' member with MIME characters
const _stringCodes: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

export function encodeDataView(dataView: DataView, size: number): string {
	// Convert binary LiveData frame contents into a base64 (MIME-encoded) string.
	// String will be padded with '=' at the end up to a multiple of 4 characters.
	// The code below assumes that Javascript stores multibyte values big-endian!!
	// If this assumption proves to not be universal among web browsers, FrameMaker
	// and FrameParser can be modified to use the endian-neutral byte-by-byte
	// conversion in core/buffer.cpp. -pcs 2011-SEP-04
	let result: string = '';
	let pad: number = 0;

	for (let i = 0; i < size; i += 3) {
		let bits: number;

		if (i + 2 < size)	// 3 more bytes available to encode:
			bits = (dataView.getUint8(i) << 16) + (dataView.getUint8(i + 1) << 8) + dataView.getUint8(i + 2);
		else { // getting near the end:
			bits = dataView.getUint8(i)<<16;	// at least one byte to encode

			if (i + 1 < size) {	// two bytes to encode:
				bits += dataView.getUint8(i + 1) << 8;
				pad = 1;						// pad encrypted string with 1 '=' sign
			}
			else // only one byte to encode
				pad = 2;						// pad encrypted string with 2 '=' sign
		}

		// Encode four bytes (include up to two bytes of '=' padding):
		result += _stringCodes[bits >> 18];
		result += _stringCodes[(bits & 0x3FFFF) >> 12];
		result += (pad < 2) ? _stringCodes[(bits & 0xFFF) >> 6] : '=';
		result += (pad < 1) ? _stringCodes[bits & 0x3F] : '=';
	}
	return result;	// Return MIME-encoded string
}

export function base64UrlEncodeBuffer(arrayBuffer: ArrayBuffer): string {
	let base64Encoded: string = encodeDataView(new DataView(arrayBuffer), arrayBuffer.byteLength);
	let result: string = '';

	// Encode unsafe URL chars
	for (let i = 0; i < base64Encoded.length; i++) {
		const c = base64Encoded[i];
		if (c === '/')
			result += '_';
		else if (c === '+')
			result += '-';
		else
			result += c;
	}

	// Remove all the padding chars
	while (result[result.length - 1] === '=')
		result = result.slice(0, -1);
	return result;
}

//convert base64 string to ArrayBuffer:
export function decodeString(data: string): [DataView, number] {
	assert(typeof data === 'string');
	const buffer: DataView = new DataView(new ArrayBuffer((data.length+3)*(3/4)));	// This is usually oversized, but is never too small
	let bytesLeft: number = 0;

	for (let i = 0; i < data.length; i += 4) {	// convert next 4 characters to 3 binary bytes:
		const a0: number = data.charCodeAt(i);			// Have at least once character
		let a1: number;
		let a2: number;
		let a3: number;

		if (i+3 < data.length) { // most common case:
			a1 = data.charCodeAt(i+1);
			a2 = data.charCodeAt(i+2);
			a3 = data.charCodeAt(i+3);
		} else { // down to 1, 2, or 3 characters in this set:
			a1 = (i + 1 < data.length) ? data.charCodeAt(i + 1) : 61;	// pad with '=' (this is really an error -- there cannot be 3 pad characters)
			a2 = (i + 2 < data.length) ? data.charCodeAt(i + 2) : 61;	// pad with '='
			a3 = (i + 3 < data.length) ? data.charCodeAt(i + 3) : 61;	// pad with '='
		}

		// Convert each ascii value to an integer from 0 to 63:
		const decodedA0: number = _decode(a0);
		const decodedA1: number = _decode(a1);
		const decodedA2: number = _decode(a2);
		const decodedA3: number = _decode(a3);

		// Shift 6-bit value of each character into bits integer value:
		const bits: number = (decodedA0<<18) + (decodedA1<<12) + (decodedA2<<6) + decodedA3;

		// Copy the result into 3 binary bytes:
		buffer.setUint8(bytesLeft++, (bits >> 16) & 0xFF);
		if (decodedA2 !== 64)
			buffer.setUint8(bytesLeft++, (bits >> 8) & 0xFF);
		if (decodedA3 !== 64)
			buffer.setUint8(bytesLeft++, bits & 0xFF);
	}
	return [buffer, bytesLeft];	// Return array of the dataview and the size it should be assumed to be
}

// 'Private' method to convert a mime character into 6-bit binary value (0-63) plus '=' (64):
export function _decode(charCode: number): number {
	if (charCode >= 97)			// a-z
		return (charCode - 97 + 26);
	if (charCode >= 65)			// A-Z
		return (charCode - 65 + 0);
	if (charCode === 61)		// convert '=' to 64 (invalid)
		return 64;
	if (charCode >= 48)			// 0-9
		return (charCode - 48 + 52);
	if (charCode === 47)		// /
		return (63);
	if (charCode === 43)		// +
		return (62);
	return 0;					// default ('=' padding character converts to 0)
}