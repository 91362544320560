import { encodeDataView } from "./base64";
import LiveData from "./livedata";

/*
 * Build LiveData frame, same as c++ version
 * Call toString() to get a base64-encoded (mime-encoded) string.
 * Call toArrayBuffer() to get an ArrayBuffer of bytes.
 */

// Define the FrameMaker object constructor:
export default class FrameMaker {
	frame: DataView;
	size: number;
	private jobIDPosition: number | null;
	command: number;
    constructor() {
        this.frame	= new DataView (new ArrayBuffer (256));		// initial size of ArrayBuffer (256 bytes)
        this.size	= 0;	// bytes used so far (bytes remaining = frame.byteLength - size)
	}

	// Make room in frame to be able to append 'bytes' more bytes to frame:
	reserveAdd(bytes: number) {
		if (bytes > (this.frame.byteLength - this.size)) {	// not enough room:
			// Allocate with a granularity of 256 bytes (lifted from core_buffer::reserve()):
			var newCap = this.frame.byteLength + bytes + (256 - 1);
			newCap -= (newCap % 256);		// maintain capacity at even multiple of 256 bytes

			var biggerFrame	= new DataView(new ArrayBuffer (newCap));

			// Copy all defined bytes over to new frame:
			for (var i = 0; i < this.size; i++)
				biggerFrame.setUint8(i, this.frame.getUint8(i));

			// And replace old, inadequate frame with new, improved frame:
			this.frame = biggerFrame;
		}
	}

	toString(): string {
		this.frame.setUint32(0, this.size, true);	// LiveData frame size is first four bytes
		return encodeDataView(this.frame, this.size);
	}

	toArrayBuffer() {
		var result = new ArrayBuffer(this.size);
		var buffer = new DataView(result);

		this.frame.setUint32(0, this.size,true);	// LiveData frame size is first four bytes

		for (var i = 0; i < this.size; ++i)
			buffer.setUint8(i, this.frame.getUint8(i));

		return result;	// return ArrayBuffer copy that is exact size of frame bytes
	}

	// Build a proper LiveData frame preamble (jobID is optional -- if not provided, zero will be used)
	buildFrame(command: LiveData, deviceID?: number, jobID?: number) {
		this.size = 4;				// sizeof LiveDataFrame (fix up to this.size before creating string or ArrayBuffer)
		this.push_u16 (0xab13);		// protocol marker -- V1.01 == 0xab13
		this.push_u8  (0);			// not used by clients
		this.push_u8  (0);			// not used
		this.jobIDPosition = this.getPosition();
		this.push_u32 (jobID);		// job id
		this.push_u32 (deviceID != undefined ? deviceID : 777);	// If not overwritten, but in a device ID of 777
		this.push_s32 (1);			// not used
		this.push_u32 (command);	// protocol command
		this.command = <number>command;
	}

	push_u8(v) 	{this.reserveAdd(1); this.frame.setUint8(this.size,v);			this.size +=1;}
	push_s8(v) 	{this.reserveAdd(1); this.frame.setInt8(this.size,v);			this.size +=1;}
	push_u16(v) {this.reserveAdd(2); this.frame.setUint16(this.size,v,true);	this.size +=2;}
	push_s16(v) {this.reserveAdd(2); this.frame.setInt16(this.size,v,true);		this.size +=2;}
	push_u32(v) {this.reserveAdd(4); this.frame.setUint32(this.size,v,true);	this.size +=4;}
	push_s32(v) {this.reserveAdd(4); this.frame.setInt32(this.size,v,true);		this.size +=4;}
	push_f32(v) {this.reserveAdd(4); this.frame.setFloat32(this.size,v,true);	this.size +=4;}
	push_f64(v) {this.reserveAdd(8); this.frame.setFloat64(this.size,v,true);	this.size +=8;}
	push_u64(v) {
		this.reserveAdd(8);

		// Assumes v > -0.5.
		// push_s64() implementation will be significantly more complicated due to 2's complement negative value support.
		// Since v is an IEEE 8-byte float, only the first ~53 bits (size of mantissa) are available.

		var lo = v;
		var hi = 0;

		if (lo > 0x100000000) {	// 2^32
			hi = Math.floor(lo / 0x100000000);
			lo -= (hi * 0x100000000);
		}

		this.frame.setUint32(this.size+0,lo,true);
		this.frame.setUint32(this.size+4,hi,true);
		this.size += 8;
	}

	push_UVarInt(v: number) {	// assumes properly-formed varint:
		do {	// Google varint encodes 7 value bits in each byte (base-128), and uses the high bit to identify all but final byte
			var b = v & 0x7f;	// Get the lowest 7 bits
			v >>= 7;			// Bit shift the over value 7 places
			if(v)				// Still data to send?
				b |= 0x80;		// Make a note in the byte
			this.push_u8(b);	// Append the byte
		} while (v != 0);
	}

	push_SVarInt(v: number) {
		return this.push_UVarInt(v < 0 ? ~(v << 1) : (v << 1));
	}

	push_string(s: string) {
		this.reserveAdd(s.length+3);						// len16 + characters + null
		if (s.length >= 0xFFFF) {
			this.frame.setUint16(this.size, 0XFFFF, true);		// Warning character with all bits on
			this.frame.setUint32(this.size, s.length, true);	// acutal string length
			this.size += 6;
		} else {
			this.frame.setUint16(this.size, s.length, true);	// string length
			this.size += 2;
		}

		for (var i = 0; i < s.length; ++i)
			this.frame.setUint8(this.size++, s.charCodeAt(i));

		this.frame.setUint8(this.size++, 0);
	}

	push_bytes(str: string, length: number) {
		this.reserveAdd(length);
		for (let i = 0; i < length; ++i)
			this.frame.setUint8(this.size++, str.charCodeAt(i));
	}

	push_buffer(buffer: ArrayBuffer) {
		this.reserveAdd(buffer.byteLength);
		let newFrame = new DataView(buffer);

		// Copy the new bytes over:
		for (let i = 0; i < buffer.byteLength; ++i)
			this.frame.setUint8(this.size++, newFrame.getUint8(i));
	}

	getPosition() { // return current frame insertion position
		return this.size;
	}

	setPosition(position: number) { // set current frame insertion position
		this.size = position;	// only used to fix up frame going back to fix up a previously pushed value
	}

	setJobID(id: number) {
		if (this.jobIDPosition === null)
			throw(new Error("Tried to send an empty frame"));
		let lastPosition = this.size;
		this.setPosition(this.jobIDPosition);
		this.push_u32(id);
		this.setPosition(lastPosition);
	}
};
