import './explorerpage.css';
import {createElement, createUniqueId}  from '../elements';
import owner                            from '../../owner';
import Page                             from './page';
import assert                           from '../debug';
import ArrowDownIcon                    from '../images/icons/arrow_down.svg';
import TextInput                        from '../components/textinput';
import { DeviceCardFactory } from '../components/devicecard';
import { Device } from '../device';
import LiveDataClient from '../livedataclient';
import User from '../user';

interface ExplorerFilter {
    name:           string;
    filter:         (device: Device)=>boolean;
    defaultSetting: boolean;
}

export default class ExplorerPage extends Page {
    props: {[key: string]: string};
    page: number   = 0;
    deviceMap: Map<string, HTMLElement>      = new Map();
    filterMap: Map<string, HTMLInputElement> = new Map();
    factoryMap: Map<string, DeviceCardFactory>      = new Map();
    detailDevice: boolean = true;
    container: HTMLElement;
    search: TextInput;
    filterList: HTMLElement;
    filters: ExplorerFilter[];
    appliedFilters: ((device: Device)=>boolean)[];
    constructor(parent: HTMLElement, props: {[key: string]: string}) {
        super(parent, props);
        this.props          = {...props}; // create a copy of the properties
        this.container      = createElement('div', 'explorer__container', parent);
        let listWrapper     = createElement('div','explorer__list-wrapper', this.container )
        let listContainer   = createElement('div', 'explorer__list-container', listWrapper);
        let filterContainer = createElement('div', 'explorer__filter-container', listContainer);
        let searchContainer = createElement('div', 'explorer__filter-container__search', filterContainer);
        this.search         = new TextInput(searchContainer, 'Search', 'var(--color-onSurface)', { 'type'              : 'text',
                                                                                                'autocomplete'      : 'off',
                                                                                                'autocapitalize'    : 'off',
                                                                                                'autocorrect'       : 'off',
                                                                                                'spellcheck'        : 'false',
                                                                                                'background'        : 'true'});
        this.search.oninput = () => this.filter()
        let filterButton    = createElement('div', 'explorer__filter-button', filterContainer, 'Filters');
        let filterIcon      = createElement('img', 'explorer__filter__icon', filterButton, '', {'src':ArrowDownIcon});
        this.filterList     = createElement('div', 'explorer__filter-list', listContainer);
        this.filterList.style.height    = '0px';
        this.filterList.setAttribute('is-collapsed', 'true');
        filterIcon.style.transform = 'rotate(-90deg)';

        this.filters = [
            {name:'Active Alarms',  filter:this.filterActiveAlarms, defaultSetting:false},
            {name:'Connected',      filter:this.filterConnected, defaultSetting:false}
        ]
        this.appliedFilters = [this.filterSearch];
        filterButton.onclick = () => {
            this.toggleFilterList(filterIcon, this.filterList)
        }
        let gridContainer   = createElement('div', 'explorer__grid-container', listContainer)
        let deviceGrid      = createElement('div', 'explorer__device-grid', gridContainer);

        this.createDeviceList(deviceGrid);
        this.createFilterList();
        this.filter();
        owner.navBar.setTitle('Explorer');
    };

    filter() {
        let filteredCards = Array.from(this.deviceMap.entries());                                     // start with all our cards
        filteredCards.forEach(card => card[1].style.display = "none");              // hide all cards
        this.appliedFilters.forEach(appliedFilter => {                                      // for each filter
            let filterfunction  = appliedFilter.bind(this)
            filteredCards       = filteredCards.filter(card => filterfunction(User.devices.getByKey(card[0]))) // filter out all of the applied AND filters
            filteredCards       = filteredCards.filter(card => {                            // filter out the groups (OR) filters
                if (owner.appliedGroupFilters.length == 0)
                    return true
                else {
                    for (let i=0;i<owner.appliedGroupFilters.length;i++) {
                        let groupFilterFunction = owner.appliedGroupFilters[i].bind(this);
                        if (groupFilterFunction(User.devices.getByKey(card[0])))
                            return true
                    }
                }
            })

            if (User.isPowerUser() && owner.menuPanel.company.options[owner.menuPanel.company.selectedIndex]) {
                if (owner.menuPanel.company.options[owner.menuPanel.company.selectedIndex].text != 'All') {
                    filteredCards = filteredCards.filter(card => {return User.devices.getByKey(card[0])?.owner == owner.menuPanel.company.options[owner.menuPanel.company.selectedIndex].text})
                }
            }

        });
        filteredCards.forEach(card => card[1].style.display = "block")                 // unhide the remaining cards
    }

    createFilterList() {
        for (let i=0;i<this.filters.length;i++) {
            let filterWrapper   = createElement('div', 'explorer__filter-wrapper', this.filterList);
            let id              = createUniqueId()
            let checkWrapper    = createElement('div', 'se-checkbox', filterWrapper)
            let filterCheck     = createElement('input', '', checkWrapper, '', {'type':'checkbox', 'id':id, checked:this.filters[i].defaultSetting});
            if (filterCheck.checked)
                this.appliedFilters.push(this.filters[i].filter);
            else
                this.appliedFilters = this.appliedFilters.filter((value, index, arr) => {return value != this.filters[i].filter})

            filterCheck.onchange = () => {
                if (filterCheck.checked) this.appliedFilters.push(this.filters[i].filter);
                else this.appliedFilters = this.appliedFilters.filter((value, index, arr) => {return value != this.filters[i].filter})
                this.filter();
            }
            this.filterMap.set(this.filters[i].name, filterCheck);
            let filterName = createElement('label', 'explorer__filter-name', checkWrapper, this.filters[i].name, {'htmlFor':id});
        }

        for (let i=0;i<owner.groupFilters.length;i++) {
            let filterWrapper   = createElement('div', 'explorer__filter-wrapper', this.filterList);
            let id              = createUniqueId()
            let checkWrapper    = createElement('div', 'se-checkbox', filterWrapper);
            let filterCheck     = createElement('input', undefined, checkWrapper, undefined, {'type':'checkbox', 'id':id, checked:owner.appliedGroupFilters.includes(owner.groupFilters[i].filter)});
            //if (filterCheck.checked) owner.appliedGroupFilters.push(this.filters[i].filter);
            //else owner.appliedGroupFilters = owner.appliedGFroupFilters.filter((value, index, arr) => {return value != this.groupFilters[i].filter})

            filterCheck.onchange = () => {
                if (filterCheck.checked) owner.appliedGroupFilters.push(owner.groupFilters[i].filter);
                else owner.appliedGroupFilters = owner.appliedGroupFilters.filter((value, index, arr) => {return value != owner.groupFilters[i].filter})
                this.filter();
            }
            this.filterMap.set(owner.groupFilters[i], filterCheck);
            let filterName = createElement('label', 'explorer__filter-name', checkWrapper, owner.groupFilters[i].name, {'htmlFor':id});
        }
    }

    createDeviceList(listContainer) {
        assert(User.devices.sorted, 'Must have a sorted device array');
        var deviceArray = User.devices.sorted;
        //this.deviceBounds = new google.maps.LatLngBounds();

        for (let i=0; i<deviceArray.length; i++) {
            let parent      = createElement('div',  'explorer__list__card-wrapper', listContainer);
            let placeholder = createElement('div',  'explorer__list__card-container', parent);
            this.deviceMap.set(deviceArray[i].key, parent);

            let observer    = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    if (!this.factoryMap.has(deviceArray[i].key))
                        this.factoryMap.set(deviceArray[i].key, new DeviceCardFactory(placeholder, deviceArray[i]).initialize());
                    else {
                        let factory = this.factoryMap.get(deviceArray[i].key)
                        if (!factory?.fInitialized && !factory?.fInitializing)
                            this.factoryMap.get(deviceArray[i].key)?.initialize();
                    }
                }
                else if (this.factoryMap.has(deviceArray[i].key)) {
                    let factory = this.factoryMap.get(deviceArray[i].key);
                    if (factory?.fInitialized || !factory?.fInitializing) {
                        factory?.uninitialize();
                    }
                }
            }, {
                rootMargin: '-50px',
                threshold: [0] });
            observer.observe(parent);
        }
    }

    toggleFilterList(dropIcon,element) { // toggles whether or not the element is collapsed. See collapse and expand methods in elements.js
        if (!element) return
        let isCollapsed = element.getAttribute('is-collapsed') === 'true';

        if(isCollapsed) {
          element.expand();
          if (dropIcon) dropIcon.style.transform = 'rotate(0deg)';
        } else {
          element.collapse();
          if (dropIcon) dropIcon.style.transform = 'rotate(-90deg)';
        }
    }

    onCompanySelected() {
        this.filter();
    }

    filterConnected(device: Device) : boolean {
        return device._connected
    }

    filterSearch(device: Device): boolean {
        let text    = this.search.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        let regex   = new RegExp(text.replace(/\s/g, ""), 'i');
        let fShow   = text.length == 0 || device.siteName.replace(/\s*/g,"").search(regex) != -1;
		return fShow;
    }

    filterActiveAlarms(device) {
        return device.alarms.active > 0;
    }

    resize() {
        for (let [key, card] of this.factoryMap) {
            card.resize()
        }
    }

    destroy() {
        for (let [key, card] of this.factoryMap) {
            card.destroy();
        }
        this.parent.destroyWidgets(true);	// Destroy all widgets on this page
		this.parent.removeChildren();		// Delete any DOM elements left over
    }
};