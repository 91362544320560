import owner                                    from '../../owner';
import { createElement }                        from '../elements';
import { Node, VType }                          from '../node';
import ResponsiveBarGraph                       from '../responsivebargraph';
import { Widget }                               from '../widget';
import assert                                   from '../debug';
import { Device }                               from '../device';
import TreeView, { GenericTreeable, TransitionType, Treeable }                   from '../views/treeview';
import { TagFilter }                            from '../tagfilter';
import './tagcard.css';

import ArrowDownFilledIcon   from '../images/icons/arrow_down_filled.svg';
import DeviceImage           from '../images/icons/device.svg';
import DisconnectImage       from '../images/icons/disconnected.svg';
import FolderImage           from '../images/icons/folder.svg';
import ArrowForwardIcon      from '../images/icons/arrow_right_filled.svg';
import ViewIcon              from '../images/icons/dashboard.svg';
import RangeSlider from '../rangeslider';
import ColoredHeader from '../coloredheader';
import { TagUnit, UnitsMap } from '../widgets/lib/tagunits';
import { InputSetpoint } from '../widgets/input/setpoint/inputsetpoint';
import { CreateWidget, RoleMap } from '../widgets/lib/createwidget';
import { Role } from '../role';
import { ColorAttribute } from '../widgets/lib/attributes';

export class TagCard {
    parent: HTMLElement;
    tag: Treeable;
    view: TreeView;
    element: HTMLElement;
    wrapper: HTMLElement;
    observer: IntersectionObserver;
    fInitialized: Promise<void>;
    options: HTMLElement;
    checkbox: HTMLInputElement;
    gridWrapper: HTMLElement;
    nameElement: HTMLElement;
    checkWrapper: HTMLElement;
    fInitializing: boolean = false;
    fPretty: boolean;
    backgroundColor: string = 'var(--color-surfaceContainerLowest)'
    private isInitialized: Promise<TagCard>;
    constructor(parent: HTMLElement, tag: Treeable, view: TreeView, fPrepend: boolean = false, fPretty: boolean = false) {
        this.parent             = parent;
        this.tag                = tag;
        this.view               = view;
        this.fPretty            = fPretty;
        this.element            = createElement('div', 'tag-card');
        if (fPrepend)
            parent.prepend(this.element);
        else
            parent.append(this.element);
        let rowWrapper = createElement('div', 'tag-card__row-wrapper', this.element);
        this.checkWrapper       = createElement('div', 'tag-card__check-wrapper', rowWrapper);
        this.gridWrapper        = createElement('div', 'tag-card__grid-wrapper', rowWrapper);
        this.wrapper            = createElement('div', 'tag-card__wrapper', this.gridWrapper);
    }

    initialize() {
        this.element.style.backgroundColor = this.backgroundColor;
        if (!this.isInitialized)
            this.isInitialized = this.buildCard();
        return this.isInitialized;
    }

    buildCard(): Promise<TagCard> {
        let leftWrapper     = createElement('div', 'tag-card__left', this.wrapper);
        let rightWrapper    = createElement('div', 'tag-card__right', this.wrapper);
        this.nameElement    = createElement('p', 'tag-card__left__name', leftWrapper, this.tag.getDisplayName(false, this.fPretty));
        return new Promise((resolve, reject)=>{resolve(this)});
    }

    onSelect() {
        this.element.classList.add('tag-card__selected');
        this.element.style.backgroundColor = 'var(--color-tertiaryFixedDim)';  // change our newly selected item to selected styling
    }

    onDeselect() {
        this.element.classList.remove('tag-card__selected');
        this.element.style.backgroundColor = this.backgroundColor;
        //this.element.style.backgroundColor = '';     // change all selected items back to unselected styling
    }

    show() {
        this.element.style.display = 'flex';
    }

    hide() {
        this.element.style.display = 'none';
    }

    dropDown(fDeep: boolean, fAnimate?: boolean) {
        return;
    }

    refresh() {
        this.wrapper.destroyWidgets(true);
        this.wrapper.removeChildren();
        this.isInitialized = this.buildCard();
    }

    destroy() {
        this.view.unregisterTagCard(this);
        this.element.destroyWidgets(true);
        this.element.removeChildren();
        this.element.remove();
    }
}

export class GenericTagCard extends TagCard {
    tag: GenericTreeable;
    backgroundColor: string = 'var(--color-surfaceContainerLow)';
    buildCard(): Promise<TagCard> {
        createElement('div', 'tag-card__spacer', this.wrapper);
        let titleWrapper    = createElement('div', 'tag-card__top', this.wrapper);
        let iconElement     = createElement('img', 'tag-card__icon', titleWrapper, undefined, {'src':this.tag.icon ?? ViewIcon})

        let rightWrapper    = createElement('div', 'tag-card__right', this.wrapper);
        this.nameElement    = createElement('div', 'tag-card__left__name', titleWrapper, this.tag.getDisplayName(false, this.fPretty));
        rightWrapper.appendChild(this.tag.buildTreeWidget());
        let right = createElement('div', 'tag-card__right', this.wrapper);
        createElement('img', 'tag-card__forward-icon', right, undefined, {'src':ArrowForwardIcon});
        return new Promise((resolve, reject)=>{resolve(this)});
    }
}

export class WidgetCard extends TagCard {
    tag: Node;
    backgroundColor: string = 'var(--color-surfaceContainerLow)';
    constructor(parent: HTMLElement, tag: Node, view: TreeView, fPrepend: boolean = false, fPretty: boolean = false) {
        super(parent, tag, view, fPrepend, fPretty);
    }

    buildCard(): Promise<WidgetCard> {
        let leftWrapper     = createElement('div', 'tag-card__top', this.wrapper);
        let rightWrapper    = createElement('div', 'tag-card__bottom', this.wrapper);
        createElement('tag-name', 'tag-card__left__name', leftWrapper, '', {nameTag: {tag:this.tag}, formatName: this.fPretty, hoverBehavior: 'path'});
        createElement('tag-units', 'tag-card__left__units', leftWrapper, '', {unitsTag: {tag:<Node>this.tag}});
        if (this.tag.vtype > VType.VT_BOOL && this.tag.vtype < VType.VT_STRING && !isNaN(this.tag.engMin) && !isNaN(this.tag.engMax) && (Array.from(this.tag.roles).filter(role => RoleMap.has(role))).length == 0) {
            let color = '';
            if (this.tag.roles.has(Role.ROLE_TOTAL_FLOW))
                color = 'var(--color-flow-1)'
            else if (this.tag.roles.has(Role.ROLE_SEC))
                color = 'var(--color-se-1)'
            else if (this.tag.roles.has(Role.ROLE_DISCHARGE_PRESSURE))
                color = '#800080'
            createElement('tag-linear-gauge', '', rightWrapper, '', {valueTag: {tag: this.tag}, levelColor: new ColorAttribute(color)});
        }
        let widgetWrapper   = createElement('div', 'tag-card__right__value', rightWrapper);
        widgetWrapper.onmousedown = (e) => {
            e.stopImmediatePropagation();
            widgetWrapper.onmouseup = (e) => {
                e.stopImmediatePropagation();
            }
        }
        widgetWrapper.ondblclick = (e) => {
            e.stopImmediatePropagation();
        }
        let widget  = CreateWidget(this.tag);
        if (widget instanceof InputSetpoint)
            widget.showArrows = false;
        widgetWrapper.appendChild(widget);
        return new Promise((resolve, reject)=>{resolve(this)});
    }
}

export interface RangeTreeable extends Treeable {
    units: TagUnit,
    minNode: Node,
    maxNode: Node,
    enableNode?: Node
}

export interface ExerciseTreeable extends Treeable {
    units: TagUnit,
    minScopeNode: Node,
    minTimeNode: Node,
}
export class RangeWidgetTagCard extends TagCard {
    tag: RangeTreeable;
    backgroundColor: string = 'var(--color-surfaceContainerLow)';
    constructor(parent: HTMLElement, tag: RangeTreeable, view: TreeView, fPrepend: boolean = false) {
        super(parent, tag, view,fPrepend);
    }

    buildCard(): Promise<RangeWidgetTagCard> {
        let column = createElement('div', 'flex__column full__width', this.wrapper)
        let topRow = createElement('div', 'flex__row full__width', column)
        this.nameElement  = createElement('div', 'tag-card__left__name', topRow, `${this.tag.getDisplayName(false, false)} (${UnitsMap.get(this.tag.units)?.abbrev})`);
        let bottomRow = createElement('div', '', column);
        if (this.tag.enableNode) {
            new ColoredHeader(this.wrapper, { nodes: [this.tag.enableNode], falseClass: 'tag-viewer__disabled', fPending: true });
            let widgetContainer = createElement('div', '', bottomRow);
            widgetContainer.onclick = (e) => e.stopPropagation();
            createElement('se-tag-checkbox', '', topRow, '', {
                toggleTag: {tag: this.tag.enableNode!},
                onColor: owner.colors.hex('--color-primary')
            });
        }
        let sliderOptions = {
            node: undefined,
            fPending: true,
            units: this.tag.units,
        }
        new RangeSlider(bottomRow, this.tag.maxNode, this.tag.minNode, this.tag.enableNode, sliderOptions).initialize();
        return new Promise((resolve, reject)=>{resolve(this)});
    }
}

export class ExerciseWidgetTagCard extends TagCard {
    tag: ExerciseTreeable;
    backgroundColor: string = 'var(--color-surfaceContainerLow)';
    constructor(parent: HTMLElement, tag: ExerciseTreeable, view: TreeView, indentationLevel: number = 0, fPrepend: boolean = false) {
        super(parent, tag, view, fPrepend);
    }

    buildCard(): Promise<ExerciseWidgetTagCard> {
        let column = createElement('div', 'flex__column full__width', this.wrapper)
        let topRow = createElement('div', 'flex__row full__width', column)
        this.nameElement  = createElement('div', 'tag-card__left__name', topRow, `${this.tag.getDisplayName(false, false)}`);
        let bottomRow = createElement('div', '', column);
        let inputRow = createElement('div', 'full__width flex__row justify__center align__center', bottomRow);
        createElement('div', '', inputRow, 'Run').style.padding = '0 5px 0 5px';
        let minWidget = CreateWidget(this.tag.minTimeNode) as InputSetpoint; //TODO: Don't forget the options here
        createElement('div', '', inputRow).append(minWidget);
        createElement('tag-units', 'tag-card__units', inputRow, '', {unitsTag: {tag: this.tag.minTimeNode}});

        createElement('div', '', inputRow, 'when off for').style.padding = '0 5px 0 5px';
        let offWidget = CreateWidget(this.tag.minScopeNode) as InputSetpoint;
        createElement('div', '', inputRow).append(offWidget) //TODO: options here too
        createElement('tag-units', 'tag-card__units', inputRow, '', {unitsTag: {tag: this.tag.minScopeNode}});
        return new Promise((resolve, reject)=>{resolve(this)});
    }
}

export class FolderTagCard extends TagCard {
    fOpened: boolean;
    arrow: HTMLImageElement;
    subFolderElement: HTMLElement;
    childCards: TagCard[];
    icon: string;
    iconElement: HTMLImageElement;
    id: number;
    fDevice: boolean;
    backgroundColor: string = 'var(--color-surfaceContainerHigh)';
    constructor(parent: HTMLElement, tag: Treeable, view: TreeView, fPrepend: boolean = false) {
        super(parent, tag, view, fPrepend);
        this.subFolderElement       = createElement('div', 'tag-card__sub__wrapper', this.element);
        this.fDevice = tag instanceof Node && tag === tag.tree.nodes[0];
        //this.icon = this.fDevice ? DeviceImage : FolderImage;
        this.backgroundColor = this.fDevice ? 'var(--color-surfaceContainerHighest)' : 'var(--color-surfaceContainerHigh)';
    }

    buildCard(): Promise<FolderTagCard> {
        assert(!this.fInitialized && !this.fInitializing)
        this.fInitializing = true;
        return new Promise<FolderTagCard>((resolve, reject) => {
            if (this.fDevice) {
                let device = (<Node>this.tag).device;
                device.onConnect.set(this, () => {
                    if (this.fOpened)
                        this.dropDown(false, false);
                    this.view.tagMap.delete(this.tag);
                    this.tag = device.tree.nodes[0]!;
                    this.view.tagMap.set(this.tag, this);
                    device.requestNodeTree(()=>{
                        this.view.tagMap.forEach((card, tag) => {
                            //@ts-ignore
                            if (tag.fDestroyed)
                                card.destroy();
                        })
                        this.buildFolderGuts();
                    });
                })
                if (device.connected || device.cachedTree) {
                    device.requestNodeTree(()=>{
                        this.buildFolderGuts();
                        resolve(this);
                    });
                    return;
                }
            }
            this.buildFolderGuts();
            resolve(this);
        });
    }

    buildFolderGuts() {
        this.wrapper.removeChildren();
        let leftWrapper     = createElement('div', 'tag-card__top', this.wrapper);
        let arrowWrapper    = createElement('div', 'tag-card__arrow-wrapper', leftWrapper);
        this.arrow          = createElement('img', 'tag-card__arrow', arrowWrapper, undefined, { 'src': ArrowDownFilledIcon }); // dropdown arrow
        this.arrow.style.rotate  = this.fOpened ? '0deg' : '-90deg';
        this.arrow.style.opacity = this.tag.treeChildren.length > 0 ? '1' : '0.5';
        arrowWrapper.onmousedown = (e) => {
            e.stopPropagation();
            this.dropDown(false, true);
        }
        if (this.fDevice)
            createElement('device-status', 'tag-card__icon', leftWrapper, '', {rootNode: {tag: <Node>this.tag}});
        createElement('tag-name', 'tag-card__left__name', leftWrapper, '', {nameTag: {tag:<Node>this.tag}, formatName: this.fPretty, hoverBehavior: 'path'});

        let right = createElement('div', 'tag-card__bottom', this.wrapper);
        let forward = createElement('img', 'tag-card__forward-icon', leftWrapper, undefined, {'src':ArrowForwardIcon});
        forward.onmousedown = (e) => {
            e.stopPropagation();
            this.view.rebuild(this.tag, TransitionType.TT_FORWARD);
        }


	    if(!this.fDevice && this.tag instanceof Node) {
            if (this.tag.vtype > VType.VT_BOOL && this.tag.vtype < VType.VT_STRING && this.tag.engMin !== undefined && this.tag.engMax !== undefined && this.tag.roles.size == 0)
                createElement('tag-linear-gauge', '', leftWrapper, '', {valueTag: {tag: this.tag}});
            let widgetWrapper = createElement('div', 'tag-card__right__value', right);
            let widget = CreateWidget(this.tag);
            widgetWrapper.appendChild(widget);
        }
    }

    dropDown(fDeep: boolean, fAnimated: boolean = true) { // if we are not on a small screen, this gives us a nice dropdown animation for our nested folder structure
        this.initialize().then(()=> {
            this.arrow.style.opacity    = this.tag.treeChildren.length > 0 ? '1' : '0.5';
            if (this.fOpened) {     // If we're open, we need to close
                if (fAnimated) {    // If we want an animated dropdown, we don't want to destroy our cards until they are hidden
                    let finishCallback = () => this.finishedDropDown();      // callback to remove dom elements once they're no longer visible
                    this.subFolderElement.addEventListener('transitionend', finishCallback, { once: true });    // add the listener for our transition callback
                    this.animate(this.arrow, this.subFolderElement)                                             // reuse filter list toggle for animation
                }
                else {
                    this.subFolderElement.setAttribute('is-collapsed', 'true');
                    this.finishedDropDown();
                }
                this.fOpened = false;                                                                       // mark this folder as unopened
            }
            else {
                this.subFolderElement.setAttribute('is-collapsed', 'true');         // make sure we start out collapsed
                if (fAnimated)
                    this.subFolderElement.style.height = '0px';
                this.view.buildTagList(this.subFolderElement, this.tag, fDeep); // build up all the folder's guts
                if (fAnimated)
                    requestAnimationFrame(()=> {
                        this.animate(this.arrow, this.subFolderElement);
                    })
                else {
                    this.arrow.style.rotate = '0deg';
                    this.subFolderElement.setAttribute('is-collapsed', 'false');
                }
                this.fOpened = true;                                              // remember that we are open now
            }
        })
    }

    animate(dropIcon: HTMLImageElement, element: HTMLElement) { // toggles whether or not the element is collapsed. See collapse and expand methods in elements.js
        if (!element) return
        let isCollapsed = element.getAttribute('is-collapsed') === 'true';

        if (isCollapsed) {
            element.expand();
            if (dropIcon)
                dropIcon.style.rotate = '0deg';
        } else {
            element.collapse();
            if (dropIcon)
                dropIcon.style.rotate = '-90deg';
        }
    }

    finishedDropDown() {
        this.tag.treeChildren.forEach(childTag => {
            let card = this.view.tagMap.get(childTag);
            if (card)
                card.destroy()
        });
    }

    refresh(fRecursive: boolean = false) {
        this.arrow.style.opacity    = this.tag.treeChildren.length > 0 ? '1' : '0.5';
        if (this.fOpened) {
            this.dropDown(fRecursive, false);
        }
    }

    destroy() {
        let device = (<Node>this.tag).tree.device;
        device.onConnect.delete(this);
        device.onDisconnect.delete(this);
        super.destroy();
    }
}

export class SearchTagCard extends TagCard {
    iconElement: HTMLImageElement;
    fDevice: boolean;
    icon: string;
    backgroundColor: string = 'var(--color-surfaceContainerLow)';
    tag: Node;
    constructor(parent: HTMLElement, tag: Treeable, view: TreeView, fPretty: boolean = false) {
        super(parent, tag, view, undefined, fPretty);
        if (tag instanceof Node) {
            if (tag === tag.tree.nodes[0])
                this.icon = DeviceImage;
            else if (tag.children && tag.children.length > 0)
                this.icon = FolderImage;
        }
        this.fDevice = tag instanceof Node && tag === tag.tree.nodes[0]
    }

    buildCard(): Promise<SearchTagCard> {
        let searchWrapper   = createElement('div', 'tag-card__left', this.wrapper);
        searchWrapper.style.cursor = 'pointer';
        let topRow          = createElement('div', 'flex__row', searchWrapper);
        let path            = ''
        if (this.tag instanceof Node) {
            let pathArray = this.tag.getDeviceRelativePath().split('/');
            pathArray.pop();
            path = this.tag.tree.device.key + pathArray.join('/') + '\/';
        }

        createElement('div', 'tag-card__search__path', topRow, path);
        topRow.title = path;
        let bottomRow       = createElement('div', 'full__width flex__row align__center', searchWrapper);
        if (this.icon)
            this.iconElement    = createElement('img', 'tag-card__icon', bottomRow, undefined, {'src':this.icon})
        createElement('tag-name', 'tag-card__left__name', bottomRow, '', {nameTag: {tag:this.tag}, formatName: this.fPretty, hoverBehavior: 'path'});
        let widgetWrapper   = createElement('div', 'tag-card__right__value', bottomRow);
        widgetWrapper.onmousedown = (e) => {
            e.stopImmediatePropagation();
            widgetWrapper.onmouseup = (e) => {
                e.stopImmediatePropagation();
            }
        }
        widgetWrapper.ondblclick = (e) => {
            e.stopImmediatePropagation();
        }
        let widget  = CreateWidget(this.tag);
        if (widget instanceof InputSetpoint)
            widget.showArrows = false;
        widgetWrapper.appendChild(widget);
        return new Promise((resolve, reject)=>{resolve(this)});
    }
}

class TagBarGraph extends Widget {
    parent: HTMLElement;
    props: any;
    constructor(parent: HTMLElement, props: any) {
        super()
        this.parent = parent;
        this.props  = props;
    }

    initialize() {
        let wrapper         = createElement('div', 'tag-bar-graph', this.parent);
        let barWrapper      = createElement('div', 'tag-bar-graph__bar', wrapper);
        let legendWrapper   = createElement('div', 'tag-bar-graph__legend', wrapper);
        new ResponsiveBarGraph(barWrapper, {...this.props, fVertical:false, color: this.props.node.color ?? 'var(--color-primary)'}).initialize();
        if (this.props.node.engMin !== undefined && this.props.node.engMax !== undefined) {
            createElement('div', 'tag-bar-graph__legend__minmax', legendWrapper, `${this.props.node.engMin.toFixed(0)}`);
            createElement('div', 'tag-bar-graph__legend__minmax', legendWrapper, `${this.props.node.engMax.toFixed(0)}`);
        }
        return this;
    }
}
