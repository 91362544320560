import assert from './debug';
import createSVGElement from './svgelements';
import MouseCapture from './mousecapture';
import { Widget } from './widget';
import { NodeQuality, NodeFlags } from './node';

interface Metrics {
    width: number;
    height: number;
    top: number;
    left: number;
    right: number;
    bottom: number;
    radius: number;
}

interface Overhang {
    width: number;
    height: number;
    strokeWidth: number;
    left: number;
    top: number;
    right: number;
    bottom: number;
}

interface Rect {
    left: number;
    top: number;
    height: number;
    width: number;
}

/**
 * Create an svg handle that the user can grab and slide vertically or horizontally along an underlying object
 * Used by bargraph.js, but general-purpose enough to be used elsewhere
 */
export default class Handle extends Widget {
    private parent: SVGElement;
    private node: any; // TODO: Define proper Node type
    private fVertical: boolean;
    private metrics: Metrics;
    private _rect: Rect;
    private _svg: SVGElement;
    private _offset: number;
    private _length: number;
    private _position: number;
    private _value: number;
    private _startPosition: number;
    private capture: MouseCapture | null;

    // Class-level variables:
    private static readonly protrusion: number = 1;    // handle protrudes x pixels beyond active rect on each side
    private static readonly thickness: number = 18;    // handle thickness in the travel direction
    private static readonly radius: number = 4;        // handle radius (CSS pixels)

    constructor(svg: SVGElement, node: any, fVertical: boolean = true) {
        super();
        assert(svg.tagName === 'svg', 'parent must be an SVG canvas element');
        assert(node && node.isWriteable, 'You must provide a writeable node to Handle (but you don\'t need write permission)');

        this.parent = svg;           // parent svg canvas
        this.node = node;            // node connected to handle
        this.fVertical = fVertical;  // true if handle moves vertically, false if horizontally
        this.capture = null;
        this._position = 0;
        this._value = 0;
    }

    /**
     * Get overhang metrics (top, left, right, bottom)
     * @param overhang has read-only and modifiable properties
     */
    getMetrics(overhang: Overhang): Metrics {
        this.metrics = {} as Metrics;

        // Handle should be no thicker than 60% of width:
        if (this.fVertical) {    // handle moves vertically:
            this.metrics.width = overhang.width + overhang.strokeWidth * 2 + Handle.protrusion * 2;
            this.metrics.height = Math.ceil(Math.min(Handle.thickness, 0.6 * this.metrics.width));
            this.metrics.top = this.metrics.bottom = Math.ceil(this.metrics.height / 2);                    // half the handle hangs over the edge
            this.metrics.left = this.metrics.right = Handle.protrusion + overhang.strokeWidth;              // side protrusion
            this.metrics.radius = Math.min(Handle.radius, this.metrics.height / 8);                         // Create radius that's not too big for tiny handles
        } else {                // handle moves horizontally:
            this.metrics.height = overhang.height + overhang.strokeWidth * 2 + Handle.protrusion * 2;
            this.metrics.width = Math.ceil(Math.min(Handle.thickness, 0.6 * this.metrics.height));
            this.metrics.top = this.metrics.bottom = Handle.protrusion + overhang.strokeWidth;              // side protrusion
            this.metrics.left = this.metrics.right = Math.ceil(this.metrics.width / 2);                     // half the handle hangs over the edge
            this.metrics.radius = Math.min(Handle.radius, this.metrics.width / 8);                          // Create radius that's not too big for tiny handles
        }

        // Adjust the overhang metrics to accomodate the handle:
        overhang.left = Math.max(overhang.left, this.metrics.left);
        overhang.top = Math.max(overhang.top, this.metrics.top);
        overhang.right = Math.max(overhang.right, this.metrics.right);
        overhang.bottom = Math.max(overhang.bottom, this.metrics.bottom);

        // Return the scale overhang metrics (in CSS pixels):
        return this.metrics;
    }

    /**
     * Render the handle onto the parent svg canvas.
     *
     * @param inside rect
     * @param outside rect
     */
    render(inside: Rect, outside: Rect): void {
        assert(this.metrics, 'Call getMetrics() before render()');

        this._rect = inside;

        // Create the handle canvas element:
        this._svg = createSVGElement<'svg'>('svg', undefined, this.parent, {
            width: this.metrics.width,
            height: this.metrics.height,
            x: inside.left - this.metrics.left,
            y: inside.top - this.metrics.top
        }, undefined);

        // Register _svg as the widget element:
        this.registerAsWidget(this._svg);

        // Offset pixels to center of handle:
        this._offset = (this.fVertical ? this.metrics.height : this.metrics.width) / 2;
        this._length = this.fVertical ? inside.height : inside.width;

        // Create the movable cursor/handle thingy:
        const handle = createSVGElement<'rect'>('rect', 'handle', this._svg, {
            x: 0,
            y: 0,
            width: this.metrics.width,
            height: this.metrics.height,
            rx: this.metrics.radius,
            ry: this.metrics.radius
        }, undefined);

        // Shrink its size so the full stroke lands inside the svg canvas:
        handle.shrinkByStrokeWidth();

        // Create a gradient fill:
        handle.createGradientFill(this._svg, true, false, '#000000', 0.4);

        // Create a red 'hairline' for the cursor handle (borrowed from sliderule parlance):
        const strokeW = handle.getStrokeWidth();
        createSVGElement<'line'>('line', 'handle-hairline', this._svg, {
            x1: this.fVertical ? (strokeW / 2) : (this.metrics.width / 2),
            x2: this.fVertical ? (this.metrics.width - strokeW) : (this.metrics.width / 2),
            y1: this.fVertical ? (this.metrics.height / 2) : (strokeW / 2),
            y2: this.fVertical ? (this.metrics.height / 2) : (this.metrics.height - strokeW)
        }, undefined);

        // Set a mousedown handler for the handle:
        handle.onmousedown = this.processMouseEvent.bind(this);
        handle.addEventListener('touchstart', this.processMouseEvent.bind(this), false);
        (handle as any)._Handle = this;  // store this object on the handle DOM element

        // Connect the node:
        this.node.subscribe(this);
    }

    private processMouseEvent(evt: MouseEvent | TouchEvent): void {
        switch (evt.type) {
            case 'mousedown':
            case 'touchstart':
                if ((evt.type === 'touchstart' || (evt as MouseEvent).button === 0) && this.node.hasWritePermission()) {
                    this.capture = new MouseCapture(evt.target as Element, evt);
                    this._startPosition = this._position;
                }
                break;

            case 'mousemove':
            case 'touchmove':
                // Track the user's handle movement:
                this.updateHandlePosition(this._startPosition + (this.fVertical ? -this.capture!.deltaY : this.capture!.deltaX));

                // this._position now has the up-to-date position, so use it to compute a new value:
                let value = (this._position / this._length) * (this.node.engMax - this.node.engMin) + this.node.engMin;

                if (this.node.flags & NodeFlags.NF_RESOLUTION) {
                    assert(this.node.resolution > 0);
                    value = Math.round(value / this.node.resolution) * this.node.resolution;
                }

                if (value !== this._value) {
                    this.node.setValue(value);
                    this._value = value;
                }
                break;

            case 'mouseup':
            case 'touchend':
                this.capture = null;

                // Immediately snap to nearest detent of written value
                this.updateHandleValue(this._value);

                // If current node value does not match most recently written handle value, then write the handle value
                // again, so that, if the user paused more than 2 seconds and the values don't match, the handle will
                // eventually reflect the correct node value (within 2 seconds):
                if (this._value !== this.node.getValue())
                    this.node.setValue(this._value);
                break;
        }
    }

    update(node: any): void {
        assert(node === this.node);

        if (node.quality !== NodeQuality.NQ_GOOD)
            return;

        // If the handle is not grabbed or is not waiting for a move response, move the handle to the value:
        if (!this.capture) {
            this.updateHandleValue(node.getValue());
        }
    }

    private updateHandleValue(value: number): void {
        const pixels = Math.min(this._length, Math.max(0, this._length * (value - this.node.engMin) / (this.node.engMax - this.node.engMin)));
        this.updateHandlePosition(pixels);
        this._value = value;
    }

    private updateHandlePosition(pixels: number): void {
        this._position = Math.min(this._length, Math.max(0, pixels));

        if (this.fVertical)
            this._svg.setAttribute('y', (this._rect.top + this._length - this._position - this._offset).toString());
        else
            this._svg.setAttribute('x', (this._rect.left + this._position - this._offset).toString());
    }

    destroy(): void {
        if (this.capture) {
            this.capture.destroy();
            this.capture = null;
        }

        this.node.unsubscribe(this);
        this.unregisterAsWidget();
    }
}