import assert from '../../debug';
import { Node, VType } from '../../node';
import { Shuttle } from '../../shuttle';
import { LiveDataGizmo } from './gizmo';
import { VTypeFilter } from '../../tagfilter';
import createSVGElement from '../../svgelements';
import Scale from '../../scale';
import editorpage from '../../pages/editorpage';
import NodeManager from '../../nodemanager';
import { createElement } from '../../elements';
import { StyleCategories } from '../../views/dashboardstyleview';
import TagSocket from '../tagsocket';
import { TagQuality } from '../../widgets/lib/tag';
import LiveDataClient from '../../livedataclient';

export class TankGizmo extends LiveDataGizmo {
    width: number;
    height: number;
    shuttles: Shuttle[] = [];
    svg: SVGElement;
    svgLevelFill: SVGElement;
    yScale: Scale;
    overhang: any;
    outerhang: any;
    graphWidth: number;
    graphHeight: number;
    graphBackground: SVGElement;
    graph: SVGElement;
    nodeManager: NodeManager;
    levelTag: Node;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles = [StyleCategories.BACKGROUND, StyleCategories.DIMENSIONS, StyleCategories.POSITION, StyleCategories.SPACING]
        this.wrapper    = createElement('div', '_gizmo__wrapper', this);

        this.sockets.set('Level', new TagSocket(LiveDataClient, 'Level', this, [new VTypeFilter([VType.VT_F32, VType.VT_F64, VType.VT_S32, VType.VT_S16, VType.VT_S64, VType.VT_S8, VType.VT_U16, VType.VT_U32, VType.VT_U64, VType.VT_U8], true, false)], [], false));
        this.sockets.set('Setpoint', new TagSocket(LiveDataClient, 'Setpoint', this, [new VTypeFilter([VType.VT_F32, VType.VT_F64, VType.VT_S32, VType.VT_S16, VType.VT_S64, VType.VT_S8, VType.VT_U16, VType.VT_U32, VType.VT_U64, VType.VT_U8], true, false)], [], true));
        this.nodeManager    = new NodeManager(this);

        for (let [name, socket] of this.sockets) {
            socket.refreshTags();
        }

        //this.createElements(div, liftStation);
    };

    populateSettings(editor: editorpage): void {
        super.populateSettings(editor);
    }

    onTreeComplete(socketName: string): void {
        if (socketName == 'Level') {
            let [levelTag]      = this.sockets.get('Level')!.tags // Non-null assertion
            this.levelTag       = levelTag;
            if (!this.levelTag)
                return;
            this.buildTank();
            this.nodeManager.addNode(levelTag);
            this.nodeManager.subscribe();
        }
    }

    buildTank() {
        let setPointTags  = this.sockets.get('Setpoint')!.tags;

        this.width			= this.wrapper.clientWidth;
        this.height			= this.wrapper.clientHeight;

        // Create our SVG element and two 'g' elements. One g is for the graph, the other is for the axis labels
        this.svg			    = createSVGElement('svg', '', this.wrapper, {width: this.width, height: this.height}, undefined);
        this.svg.style.position = 'absolute';
        this.svg.style.top      = '0';
        this.svg.style.left     = '0';
        this.svg.style.height   = '100%';
        this.svg.style.width    = '100%';
        var background 		    = createSVGElement('g', 'tankGraphBase', this.svg, undefined, undefined);
        this.graph 			    = createSVGElement('g', 'tankGraphBase', background, undefined, undefined);
        this.graphBackground	= createSVGElement('rect', 'tankGraphBackground', this.graph, undefined, undefined);


        // Create our background shapes first so they'll appear behind the lines
        this.svgLevelFill	= createSVGElement('rect', 'tankGraphLevelFill', this.graph, undefined, undefined);
        this.shuttles.push(new Shuttle(this.svg, this.levelTag, 'left', false, '', 'level', false, {minimum: this.levelTag.engMin, maximum: this.levelTag.engMax, resolution: this.levelTag.resolution}));
        for (let setpoint of setPointTags) {
            this.shuttles.push(new Shuttle(this.svg, setpoint, 'left', false, '', 'level', false, {minimum: setpoint.engMin, maximum: setpoint.engMax, resolution: setpoint.resolution}))
        }
        this.yScale = new Scale(this.svg, this.levelTag.engMin, this.levelTag.engMax, this.levelTag.resolution, 'left', false, 'blackTics');

        this.overhang	= {		// The overhang for the shuttles
            left:			0,
            top:			0,	// For the y axis label (plus four pixels of padding)
            right:			4,	// For the x axis label (plus four pixels of padding)
            bottom:			0,
            strokeWidth:	1
        };

        // The inner shuttle overhang is also used for the scales
        this.yScale.getMetrics(this.overhang);

        for (var i = 0; i < this.shuttles.length; ++i)		// For each shuttle
            this.shuttles[i].getMetrics(this.overhang);		// Get how much it will overhang


        this.outerhang	= {		// The overhang for the outer shuttles
            left:		0,
            top:		0,
            right:		0,
            bottom:		0
        };

        var graphRect			= {	// This object describes where there graph of flow versus level lives in the SVG space
			width:		this.width - this.overhang.left - 24 /*- this.overhang.right*/ - this.outerhang.left,// - this.outerhang.right,
			height:		this.height - this.overhang.top - this.overhang.bottom - this.outerhang.top,
			left:		this.overhang.left + this.outerhang.left,
			top:		this.overhang.top + this.outerhang.top,
			right:		this.width - this.overhang.right - this.outerhang.right,
			bottom:		this.height - this.overhang.bottom
        };

        // Store the width and height of the graph element alone
        this.graphWidth			= graphRect.width;
        this.graphHeight		= graphRect.height;
        //@ts-ignore
        this.graphBackground.updateFill(0, 0, this.graphWidth, this.graphHeight);

        // Put the necessary amount of whitespace around the graph based on the shuttle overhang
        //@ts-ignore
        this.graph.translate(graphRect.left, graphRect.top);

        // Draw a border one time only
        createSVGElement('line', '', this.graph, undefined, undefined).updateLine(0, 0, 0, graphRect.height);
        createSVGElement('line', '', this.graph, undefined, undefined).updateLine(0, graphRect.height, graphRect.width, graphRect.height);
        createSVGElement('line', '', this.graph, undefined, undefined).updateLine(0, 0, graphRect.width, 0);
        createSVGElement('line', '', this.graph, undefined, undefined).updateLine(graphRect.width, 0, graphRect.width, graphRect.height);

        this.yScale.render(graphRect, graphRect);
        // Call render on each shuttle in the inner circle of shuttles
        for (var i = 0; i < this.shuttles.length; ++i)					// For each shuttle
            this.shuttles[i].render(graphRect, null, graphRect);			// Make it instantiate itself
    }

    update(node: Node) {
        // Based on which node update, recalculate the appropriate point for our curve
        //for (let [name, socket] of this.sockets) {
        //    if (!socket.tags.has(node))
        //    return;
        //}
        if (node.quality != TagQuality.TQ_GOOD) {
            this.showWarning();
            return;
        }
        this.hideWarning();
        switch(node) {	// Switches use the '===' comparator, so should be good for our use case here
            case this.levelTag:
                var y = this.graphHeight * (this.levelTag.engMax - this.levelTag.getValue()) / this.levelTag.engMax;
                this.svgLevelFill.updateFill(0, y, this.graphWidth, this.graphHeight - y);
            break;
            default:
                assert(false, "Unknown node called update on WetWellGraph");
            return;
        }
    }

    buildSetpoints() {
        for (let i=0;i<this.shuttles.length;++i) {
            this.shuttles[i].destroy();
        }
        this.shuttles		= [];

    }

    applyDefaults(): void {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '200px');
        }
        if (this.getStyleSetting('height', 0) === undefined) {
            this.modifyStyleSetting('height', 0, '400px');
        }
    }

    rebuild(): void {
        for (var i = 0; this.shuttles && i < this.shuttles.length; ++i)
            this.shuttles[i]?.destroy();	// Destroy all of our shuttles
        this.shuttles = [];
        this.wrapper.removeChildren();
        if (this.sockets.get('Level')!.tags.size < 1)
            return;

        let [levelTag]      = this.sockets.get('Level')!.tags // Non-null assertion
        this.levelTag       = levelTag;
        this.buildTank();
        this.update(this.levelTag);
    }

    onResize(): void {
        this.rebuild(); //TODO: We can make this more efficient instead of going scorched-earth
    }

    onMove(): void {
        this.rebuild(); //TODO: We can make this more efficient instead of going scorched-earth
    }

    destroy(): void {
        for (var i = 0; this.shuttles && i < this.shuttles.length; ++i)
            this.shuttles[i].destroy();	// Destroy all of our shuttles
        this.nodeManager.destroy();	// Unsubscribe to all the nodes we subscribed to
    };
};