import Icon from '../../../../images/icons/name.svg';
import { RegisterWidget, Widget } from '../../../lib/widget';
import template from './pumptest.html';
import { type Tag, TagAttribute, type TagDefinition } from '../../../lib/tag';
import { Role } from '../../../../role';
import FrameMaker from '../../../../framemaker';
import LiveData from '../../../../livedata';
import FrameParser from '../../../../frameparser';

@RegisterWidget({tag: 'pump-test', template: template, displayName: 'Pump Test', icon: Icon, section: 'Pumps', editor: true})
export class PumpTest extends Widget {
    preCheck: HTMLElement;
    postCheck: HTMLElement;
    preCheckDoneTag: Tag;
    postCheckDoneTag: Tag;
    @TagAttribute({
        displayName: 'DPO Folder',
        supportedRoles: [Role.ROLE_DPO_FOLDER]
    }) dpoFolder: TagDefinition;

    //@TagAttribute({displayName: 'Manual Test Tag',      shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_MANUAL_TEST_MODE]}) manualTestTag: TagDefinition;
    //@TagAttribute({displayName: 'Manual Collect Tag',   shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_MANUAL_TEST_COLLECT_DATA]}) manualCollectTag: TagDefinition;
    //@TagAttribute({displayName: 'Manual End Test Tag',  shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_MANUAL_TEST_END_TEST]}) manualEndTestTag: TagDefinition;
    //@TagAttribute({displayName: 'Test Start Time Tag',  shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_TEST_START_TIME]}) testStartTimeTag: TagDefinition;
    //@TagAttribute({displayName: 'Test Step Time Tag',   shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_TEST_STEP_TIME]}) testStepTimeTag: TagDefinition;
    //@TagAttribute({displayName: 'Test Final Time Tag',  shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_TEST_COMPLETE_TIME]}) testFinTimeTag: TagDefinition;
    //@TagAttribute({displayName: 'Test State Tag',       shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_TEST_STATE]}) testStateTag: TagDefinition;
    //@TagAttribute({displayName: 'Pre Check Done Tag',   shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_PRE_CHECKLIST_DONE]}) preCheckDoneTag: TagDefinition;
    //@TagAttribute({displayName: 'Post Check Done Tag',  shouldSubscribe: true, supportedRoles: [Role.ROLE_TLC_POST_CHECKLIST_DONE]}) postCheckDoneTag: TagDefinition;

    protected connectedCallback(): void {
        this.preCheck = this.shadowRoot?.getElementById('pre-check') as HTMLElement;
        this.postCheck = this.shadowRoot?.getElementById('post-check') as HTMLElement;
    }

    protected enliven(): void {
        let dpoFolder = this.dpoFolder.tag;
        let fm = new FrameMaker();
        fm.buildFrame(LiveData.LDC_TEST_CHECKLISTS, dpoFolder.device.id);
        import('../../../../livedataclient').then(ldc => ldc.default.sendRequest(fm).then(fp => this.onTestChecklists(fp)));
        //this.subscribeToTags([
        //    dpoFolder.findChildByRole(Role.ROLE_TLC_MANUAL_TEST_MODE)!,
        //    dpoFolder.findChildByRole(Role.ROLE_TLC_MANUAL_TEST_COLLECT_DATA)!,
        //    dpoFolder.findChildByRole(Role.ROLE_TLC_MANUAL_TEST_END_TEST)!,
        //    dpoFolder.findChildByRole(Role.ROLE_TLC_TEST_START_TIME)!,
        //    dpoFolder.findChildByRole(Role.ROLE_TLC_MANUAL_TEST_MODE)!,
        //    dpoFolder.findChildByRole(Role.ROLE_TLC_MANUAL_TEST_MODE)!,
        //    dpoFolder.findChildByRole(Role.ROLE_TLC_MANUAL_TEST_MODE)!,
        //    dpoFolder.findChildByRole(Role.ROLE_TLC_MANUAL_TEST_MODE)!,
        //    dpoFolder.findChildByRole(Role.ROLE_TLC_MANUAL_TEST_MODE)!,
//
        //]);
    }

    onTestChecklists(fp: FrameParser) {
		this.createChecklistRow(fp, this.preCheck, this.preCheckDoneTag);
		this.createChecklistRow(fp, this.postCheck, this.postCheckDoneTag);
	}

	createChecklistRow(fp: FrameParser, checklist, node) {
		var itemCount = fp.pop_u8();
		//if (itemCount == 0)

		for (var i = 0; i < itemCount; ++i) {
            let template = this.shadowRoot?.getElementById('check-row') as HTMLTemplateElement;
            let checkRow = document.importNode(template.content, true);
            checklist.appendChild(checkRow);
            let title = checkRow.querySelector('#check-title') as HTMLElement;
            title.textContent = fp.pop_string();
			//var div = createElement('div', 'pumpCurveChecklistItem', checklist);
			//var checkbox = createElement('input', null, div);
			//checkbox.type = 'checkbox';
			//checkbox.onchange = this.onCheckboxChange.bind(this, checklist, checkbox, node);
			//checkbox.checked = node.getValue();
			//checklist.boxes[i] = checkbox;
			//if (!node.couldBeWritten())
			//	checkbox.setAttribute('disabled', true);
			//createElement('div', 'pumpCurveChecklistText', div, fp.pop_string());
		}
	}

	onCheckboxChange(checklist, checkbox, node: Tag, state) {
		if (node.getValue(this))									// If the node is already true
			checkbox.checked = true;							// Leave the box checked. Nothing they should do
		else if (!node.isWriteable)					// If we can't write the node
			checkbox.checked = false;							// Don't check the box
		else {													// We have write permission
			for (var i = 0; i < checklist.boxes.length; ++i) {	// We only write out trues here in case another client sets the box to true
				if (!checklist.boxes[i].checked)				// If a single checkbox isn't checked, don't write anything out
					return;
			}
			node.setPendingValue(true, this);								// Write a new value to the node
		}
	}

	updateCheckboxes(checklist, node, fCorrectState) {	// This sets the test checklist check boxes
		var fTrue = node.getValue();
		for (var i = 0; i < checklist.boxes.length; ++i) {			// Make sure all the check boxes are cleared, just in case
			checklist.boxes[i].checked = fTrue;						// If the node is set, all of the check boxes should be checked
			if (!node.couldBeWritten() || fTrue || !fCorrectState)	// If they can't write the node, if it's already set, or if we aren't waiting on a checklist
				checklist.boxes[i].setAttribute('disabled', true);	// Disable all of the checkboxes
			else if (!fTrue)
				checklist.boxes[i].removeAttribute('disabled');
		}
	}
}