import Page             from "./page";
import owner            from "../../owner";
import {createElement}  from "../elements";
import './reportgeneratorpage.css';
import DashboardView from '../views/dashboardview';
import LiveDataClient from "../livedataclient";

interface ReportInfo {
    name:       string;
    companyKey: string;
    url:        string;
    id:         number;
    size:       number;
}

export default class ReportGeneratorPage extends Page {
    wrapper:    HTMLElement;
    graphID:    number;
    props:      {[key: string]: string};
    constructor(parent: HTMLElement, props: {[key: string]: string}) {
        super(parent);
        this.wrapper                    = createElement('div', 'editor__wrapper', this.parent);     // wrapper element for all page elements
        this.graphID                    = LiveDataClient.registerGraph(this);
        this.props                      = props;
        if (typeof this.props.id == 'undefined')
            return;
        document.body.removeChildren();
        document.body.style.height      = 'max-content';
        document.body.style.minHeight   = '100%';
        document.body.style.maxWidth    = '100%';
        document.body.style.overflowX   = 'hidden';
        console.log(this.props.id);

        let printContainer              = createElement('div', 'report-generation__container', document.body);
        let dashboard                   = new DashboardView(LiveDataClient, parseInt(this.props.id)).initialize(printContainer);
        dashboard.gizmoContainer.style.overflowX = 'hidden';
        dashboard.gizmoContainer.style.overflowY = 'hidden';
        dashboard.gizmoContainer.style.height = '100%';
        setTimeout(()=> {
            requestAnimationFrame(()=>{
                //@ts-ignore
                window.onPrintReady()
            })
        }, 15000)
    }
}