import {createElement} from '../elements';
import { Widget } from '../widget';
import { NodeQuality } from '../node'
import NodeManager from '../nodemanager';
import createWidget from '../createwidget';
import Localization from '../localization';
import View from './view';
import assert from '../debug';
import ColoredHeader from '../coloredheader';
import './assetmanagementview.css';
import { TagUnitQuantity, UnitsMap } from '../widgets/lib/tagunits';
import { Role } from '../role';

export default class AssetManagementView extends View {
    constructor(device, ldc) {
        super();
        this.ldc		= ldc;			// The LiveDataClient
        this.device 	= device;
        assert (this.device.isTreeComplete(), 'Device must already have a complete node tree');
    };

	initialize(parent) {
        super.initialize(parent);
        const rootNode  = this.device.tree.nodes[0];
        this.pumpSys	= rootNode.findChildByRole(Role.ROLE_PUMP_BANK);	// Save a pointer to the pump system node
		var pumpLife	= this.pumpSys.findChild("PumpLife");
		var wearTime	= this.pumpSys.findChildByRole(Role.ROLE_WORN_PUMP_SECONDS);
		var energyCost	= this.pumpSys.findChildByRole(Role.ROLE_ENERGY_COST);
        var currency	= UnitsMap.get((energyCost.units - TagUnitQuantity.TUQ_ENERGYCOST) + TagUnitQuantity.TUQ_CURRENCY).abbrev;
        this.wrapper    = createElement('div', 'asset-view__wrapper', this.parent);

		var topWrapper = createElement('div', 'AssetSection', this.wrapper);
		createElement('div', 'AssetTitle', topWrapper, 'Pump Repair Financial Factors');
		this.createTopIndicator(topWrapper, this.pumpSys.findChild("InterestRate"), 'Annual Discount Rate:');
		this.createTopIndicator(topWrapper, pumpLife, 'Expected Pump Life:');
		this.createTopIndicator(topWrapper, energyCost, 'Average Energy Cost:');

		var middleWrapper = createElement('div', 'AssetSection', this.wrapper);
		createElement('div', 'AssetTitle', middleWrapper, 'Pump Repair Financial Analysis');
		var firstColumn = createElement('div', 'AssetFirstColumn', middleWrapper);
		createElement('div', 'AssetHeaderCell', firstColumn);
		createElement('div', 'AssetCell AssetOddCell', firstColumn, 'Pump Health');
		createElement('div', 'AssetCell', firstColumn, 'Repair As');
		createElement('div', 'AssetCell AssetOddCell', firstColumn, 'Projected Energy Reduction');
		createElement('div', 'AssetCell', firstColumn, 'Present Value of Energy Reduction');
		createElement('div', 'AssetCell AssetOddCell', firstColumn, 'Repair Cost');
		createElement('div', 'AssetCell', firstColumn, 'Net Present Value (NPV)');
		createElement('div', 'AssetCell AssetOddCell', firstColumn, 'Return on Investment (ROI)');
		createElement('div', 'AssetCell', firstColumn, 'Internal Rate of Return (IRR)');

		var pumps = this.pumpSys.findByRole(Role.ROLE_PUMP);
		for (var i = 0; i < pumps.length; ++i) {
			var column = createElement('div', 'AssetColumn', middleWrapper);

			createElement('div', 'AssetHeaderCell', column, pumps[i].getDisplayName());
            createElement('digital-gauge', '', createElement('div', 'AssetCell AssetOddCell', column), '', {valueTag: {tag: (pumps[i].findChildByRole(Role.ROLE_PUMP_HEALTH_METRIC))}, showUnits: true});
            createElement('pump-select', '', createElement('div', 'AssetCell', column), '', {pumpFolder: {tag: this.pumpSys}, selectedPump: {tag: pumps[i]}})

            createElement('pump-wear-cost', 'AssetCell AssetOddCell', column, '', {pumpFolder: {tag: pumps[i]}});
			var wearCost = pumps[i].findChild('WearCost');

            var pvDiv = createElement('div', 'AssetCell', column);
			var cost = pumps[i].findChild('RepairCost');
			var costCell = createElement('div', 'AssetCell AssetRed AssetOddCell', column);
            createElement('tag-units', '', createElement('div', 'AssetCell AssetOddCell', costCell), '', {unitsTag: {tag: cost}});
            createElement('input-setpoint', '', createElement('div', 'AssetCell', costCell), '', {setpoint: {tag: cost}, showUnits: true});

			createElement('div', 'AssetUnderline', createElement('div', null, column));

			var pv = pumps[i].findChild('RepairPV');
			var npvDiv = createElement('div', 'AssetCell', column);
			new PumpRoiWidget(createElement('div', 'AssetCell AssetOddCell', column), pvDiv, npvDiv, pv, cost, currency);
			new PumpIrrWidget(createElement('div', 'AssetCell', column), wearCost, wearTime, pumpLife, cost, energyCost);
		}

		var bottomWrapper = createElement('div', 'AssetSection', this.wrapper);
		createElement('div', 'AssetTitle', bottomWrapper, 'Recommendations');
		var repairsFolder = this.pumpSys.findChild('Repairs');
		var calcRepairs = this.pumpSys.findChild('CalculatingRepairs');
		new RepairText(bottomWrapper, repairsFolder, pumps, calcRepairs, wearTime, energyCost, pumpLife, currency);
        new ColoredHeader(createElement('div', 'AssetRepairWrapper hide', bottomWrapper, 'Recalculating'), {nodes: [calcRepairs], falseClass: 'hide'});

        this.fInitialized = true;
        return this;
	}

	createTopIndicator(parent, node, label) {
		var wrapper = createElement('div', 'AssetNodeWrapper', parent); 					// Wraps the constraint and all of its items/widgets
		createElement('label', 'constraintUnits', wrapper, label === undefined ? (node.getDisplayName() + ':') : label);	// Name
		createElement('label', 'AssetsUnits', wrapper).innerHTML = node.getUnitsText();			// Units
		createWidget(createElement('div', 'AssetsValue', wrapper), node, {fPending: true, fShowUnits: false});	// Widget
	}

	destroy() {
		this.parent.destroyWidgets(true);	// Destroy widgets automagically
        this.parent.removeChildren();		// Delete any DOM elements left over
	}
};

function updateCurrency(value, div, currency) {
	div.classList.toggle('AssetRed', value < 0);
	div.innerHTML = Math.abs(value).toCurrency(currency);
	return div;
};

class PumpRoiWidget extends Widget {
    constructor(roiDiv, pvDiv, npvDiv, npv, cost, currency) {
        super();
        this.npv	= npv;
        this.cost	= cost;
        this.roiDiv = roiDiv;
        this.pvDiv 	= pvDiv;
        this.npvDiv 	= npvDiv;
        this.currency = currency;
        this.registerAsWidget(roiDiv);	// Register with the element like a good little widget
        this.npv.subscribe(this);
        this.cost.subscribe(this);
    };

    update(node) {
        if (this.npv.quality != NodeQuality.NQ_GOOD || this.cost.getValue() == 0)
            this.roiDiv.textContent = this.pvDiv.textContent = '';
        else {
            updateCurrency(this.npv.getValue() + this.cost.getValue(), this.pvDiv, this.currency);
            updateCurrency(this.npv.getValue(), this.npvDiv, this.currency);

            var roi = 100*this.npv.getValue()/this.cost.getValue();
            this.roiDiv.textContent = roi.toFixed(1) + '%';
            this.roiDiv.classList.toggle('AssetRed', roi <= 0);
        }
    };

    destroy = function() {
        this.npv.unsubscribe(this);
        this.cost.unsubscribe(this);
        this.unregisterAsWidget();
    };
};

class PumpIrrWidget extends Widget {
    constructor(irrDiv, wear, seconds, life, cost, enCost) {
        super();
        this.wear	= wear;
        this.sec	= seconds;
        this.life 	= life;
        this.cost 	= cost;
        this.enCost = enCost;
        this.div 	= irrDiv;
        this.registerAsWidget(this.div);	// Register with the element like a good little widget
        this.wear.subscribe(this);
        this.sec.subscribe(this);
        this.life.subscribe(this);
        this.cost.subscribe(this);
        this.enCost.subscribe(this);
    };

    update = function(node) {
        if (this.wear.quality != NodeQuality.NQ_GOOD || this.cost.getValue() == 0 || this.life.getValue() == 0)
            this.div.textContent = '-';
        else {
            var irr = PumpIrrWidget.calculateIRR(this.cost.getValue(), this.wear.getValue()*365*24*3600/this.sec.getValue()*this.enCost.getValue(), this.life.getValue());
            this.div.textContent = isNaN(irr) ? '-' : irr.toFixed(1) + '%';
            this.div.classList.toggle('AssetRed', irr <= 0);
        }
    };

    static calculateIRR(cost, savings, life) {
        var maxIRR = 1, minIRR = -1;
        var npv = 100;
        var count = 0;
        while(Math.abs(npv) > 1) {	// dollar accuracy
            var irr = (maxIRR + minIRR) / 2;
            var npv = -cost;
            for (var i = 1; i <= life; ++i)
                npv += savings/Math.pow(1+irr, i);
            if (npv > 0)
                minIRR = irr;
            else
                maxIRR = irr;
            if (++count > 1000)
                return NaN;
        }
        return irr*100;
    };

    destroy() {
        this.wear.unsubscribe(this);
        this.sec.unsubscribe(this);
        this.life.unsubscribe(this);
        this.cost.unsubscribe(this);
        this.enCost.unsubscribe(this);
        this.unregisterAsWidget();
    };
};

// This is a little helper class to build the recommended statement
class RepairText extends Widget {
    constructor(parent, repairFolder, pumps, calcRepairs, time, energyCost, pumpLife, currency) {
        super();
        this.wrapper		= createElement('div', 'AssetRepairWrapper hide', parent);
        this.registerAsWidget(this.wrapper);	// Register with the element like a good little widget
        this.pumps			= pumps;
        this.nodeManager	= new NodeManager(this);
        this.calcRepairs	= this.nodeManager.addNode(calcRepairs);
        this.wearTime		= this.nodeManager.addNode(time);
        this.energyCost		= this.nodeManager.addNode(energyCost);
        this.pumpLife		= this.nodeManager.addNode(pumpLife);
        this.wears			= [];
        this.configs		= [];
        this.costs			= [];
        this.pvs			= [];
        this.currency		= currency;
        for (var i = 0; i < repairFolder.children.length; ++i) {
            this.wears.push(this.nodeManager.addNodeByName(repairFolder.children[i], 'WearCost'));
            this.configs.push(this.nodeManager.addNodeByName(repairFolder.children[i], 'Config'));
            this.costs.push(this.nodeManager.addNodeByName(repairFolder.children[i], 'Cost'));
            this.pvs.push(this.nodeManager.addNodeByName(repairFolder.children[i], 'PV'));
        }
        this.nodeManager.subscribe();			// Subscribe to the nodes
    };

    update = function(node) {
        if (!this.wrapper.classList.toggle('hide', this.calcRepairs.getValue())) {
            var lastConfig = 0;
            var string = '';
            var hours = this.wearTime.getValue() / 3600;
            var wearRatio = 365*24/hours*this.energyCost.getValue();
            this.wrapper.innerHTML = Localization.toLocal("Using the last ") + hours.toFixed(1) + Localization.toLocal(" hours of historical data, this analysis has concluded that the following repairs are in the owner's best economic interest:");
            for (var i = 0; i < this.configs.length; ++i) {
                if (this.configs[i].quality != NodeQuality.NQ_GOOD || this.pvs[i].getValue() == 0) {
                    if (i == 0)
                        this.wrapper.innerHTML = 'No repairs recommended.';
                    break;
                }
                var repairWrapper = createElement('div', 'AssetRecommendation', this.wrapper);

                var config = this.configs[i].getValue();
                for (var j = 0; j < this.pumps.length; ++j) {
                    if ((config & (1 << j)) && ((lastConfig & (1 << j)) == 0)) {
                        if (i > 0)
                            string += ' then ';
                        string += this.pumps[j].getDisplayName();
                        createElement('div', 'AssetRecommendationName', repairWrapper, (i+1) + ': ' +string);
                        break;
                    }
                }

                var savings = this.wears[i].getValue()*wearRatio;
                var npv = this.pvs[i].getValue();
                var cost = this.costs[i].getValue();
                var dataWrapper = createElement('div', 'AssetPumpWrapper', repairWrapper);
                this.createCurrencyRow(dataWrapper, 'Projected Energy Reduction:', savings).textContent += '/yr';
                this.createCurrencyRow(dataWrapper, 'Present Value of Energy Reduction:', npv+cost);
                this.createCurrencyRow(dataWrapper, 'Total Repair Cost:', -cost);
                createElement('div', 'AssetUnderline', dataWrapper);
                this.createCurrencyRow(dataWrapper, 'Net Present Value (NPV):', npv);
                this.createRow(dataWrapper, 'Return on Investment (ROI):', (100*npv/cost).toFixed(1)+"%");

                var irr = PumpIrrWidget.calculateIRR(cost, savings, this.pumpLife.getValue());
                this.createRow(dataWrapper, 'Internal Rate of Return (IRR):', isNaN(irr) ? "-" : irr.toFixed(1)+"%");
                lastConfig = config;
            }
        }
    };

    createRow = function(parent, label, value) {
        var row = createElement('div', 'AssetPumpDataRow', parent);
        createElement('div', 'AssetPumpDataLabel', row, label);
        return createElement('div', 'AssetPumpDataValue', row, value);
    };

    createCurrencyRow = function(parent, label, value) {
        return updateCurrency(value, this.createRow(parent, label), this.currency);
    };

    destroy = function() {
        this.nodeManager.destroy();
        this.unregisterAsWidget();
    };
};
