import Page  from "./page";
import { createElement } from "../elements";
import './mappage.css';
import './chartpage.css'
import LiveDataClient from "../livedataclient";
import Cruncher from "../cruncher";
import { TagSocketView } from "../views/widgettagview";
import HTMLSanitizer from "../htmlsanitizer";
import { LineChart } from "../widgets/charts/line/linechart";
import { TabManager } from "../components/tabmanager";
import TreeView from "../views/treeview";
import { AttributeEditorView } from '../views/attributeeditorview';
import Loader from "../loader";

import MoreIcon from '../images/icons/more.svg';
import ViewModal from '../viewmodal';
import assert from '../debug';
import Dropdown from '../components/dropdown';
import { Node } from '../node';
import ArrowIcon from '../images/icons/double_arrow_left.svg';
import '../views/chartview.css';

import Dialog from '../dialog';
import { attrMetadataSymbol, ExtendedAttributeMetadata } from '../widgets/lib/attributes';
import { defaultAttrValues } from '../widgets/lib/widget';
import TagIcon from "../images/icons/tags.svg";
import SettingsIcon from "../images/icons/settings.svg";
import FrameMaker from '../framemaker';
import LiveData from '../livedata';
import User from '../user';
import { getHash, getRouteAndProperties } from "../router/router";


interface ChartMetadata {
	id: number;
	creator: string;
	name: string;
	version: number;
	lastModified: number;
}

interface ChartAction {
	name: string,
	icon: string,
	callback: (key: string) => void;
}

export default class ChartPage extends Page {	separator: string;
	formatOptions: Intl.NumberFormatOptions;
	fAxis: boolean;
	fInitResp: boolean;
	controlRow: HTMLElement;
	graphRow: HTMLElement;
	legendRow: HTMLElement;
	utcOffset: number;
	fFullScreen: boolean;
	fullScreenWrapper: HTMLElement;
	cruncher: Cruncher = new Cruncher();
	loaderContainer: HTMLElement;
	titleInput: HTMLInputElement;
	titleText: HTMLElement;
	addPanel: HTMLElement;
	selectedList: HTMLElement;
	settingsList: HTMLElement;
	startTime: HTMLInputElement;
	endTime: HTMLInputElement;
	treeView: TreeView;
	toolTabs: TabManager;
	private chart: ChartMetadata | null = null;
	private chartElement: LineChart;
	sanitizer: HTMLSanitizer = new HTMLSanitizer(undefined, true);
	socketView: TagSocketView;
	private attributeEditor: AttributeEditorView;
	id?: number;
    props: {[key: string]: string};
	resizeListener: () => void;
    wrapper: HTMLElement;
    constructor(parent: HTMLElement, props: {[key: string]: string}) {
		super(parent);
		this.props = props;
		this.separator = (1.1).toLocaleString()[1] === ',' ? ';' : ',';
		this.formatOptions = { useGrouping: false };	// This is the option we pass to 'toLocaleString' so it doesn't use thousand place separators
		this.fAxis = true // flag for managing axis display state
		this.fInitResp = true;

		this.wrapper = createElement('div', 'chart-view__page-wrapper', this.parent);
		this.addPanel = createElement('div', 'chart-view__config', this.wrapper);
		let tagToggleButton = createElement('button', 'se-button chart-view__config__button', this.wrapper);
        let settingsToggleButton = createElement('button', 'se-button chart-view__settings__button', this.wrapper);
		this.loaderContainer = createElement('div', 'chart-view__export__loader__container hide', document.body);
		new Loader(this.loaderContainer);

		this.resizeListener = () => this.resize();
		addEventListener('resize', this.resizeListener);
		createElement('img', 'chart-view__config__button__icon', tagToggleButton, '', { src: ArrowIcon });
		createElement('img', 'chart-view__config__button__icon', settingsToggleButton, '', { src: ArrowIcon });
		this.wrapper.setAttribute('add-open', 'true');
		this.wrapper.setAttribute('settings-open', 'true');
		let addWrapper = createElement('div', 'chart-view__config__wrapper', this.addPanel);

		let tagPanel = createElement('div', 'chart-view__config__tags', addWrapper);

		let graphWrapper = createElement('div', 'chart-view__graph-wrapper', this.wrapper);
		let graphContainer = createElement('div', 'chart-view__graph-wrapper__graph-container', graphWrapper);
		let titleRow = createElement('div', 'chart-view__graph-wrapper__title-container', graphContainer);
		this.titleInput = createElement('input', 'chart-view__graph-wrapper__title-container__title__input', titleRow);
		this.titleInput.style.visibility = 'hidden';
		this.titleInput.onchange = () => this.chart!.name = this.titleInput.value;
		let titleButtons = createElement('div', 'chart-view__graph-wrapper__title-container__title__buttons', titleRow);
		this.graphRow = createElement('div', 'chart-view__graph-wrapper__graph-container__graph-row', graphContainer);
		let settingsWrapper = createElement('div', 'chart-view__graph-wrapper__settings', this.wrapper)

		tagToggleButton.onclick = () => {
			this.addPanel.addEventListener('transitionend', () => dispatchEvent(new Event('resize')), { once: true });
			if (this.wrapper.getAttribute('add-open') == 'true') {
				this.closeAddPanel();
			}
			else {
				this.openAddPanel();
			}
		}
        settingsToggleButton.onclick = () => {
            settingsWrapper.addEventListener('transitionend', () => dispatchEvent(new Event('resize')), { once: true });
            if (this.wrapper.getAttribute('settings-open') == 'true') {
                this.closeSettingsPanel();
                menuButton.style.marginRight = '48px'
            }
            else {
				this.openSettingsPanel();
			    menuButton.style.marginRight = '0px'
            }
        };


		this.toolTabs = new TabManager(settingsWrapper, '', [
			{ name: 'Tags', icon: TagIcon, displayName: 'Tags' },
			{ name: 'Settings', icon: SettingsIcon, displayName: 'Settings' },
		]);

		let menuButton = createElement('div', 'chart-view__graph-wrapper__graph-container__menu__button', titleButtons);
		createElement('img', 'chart-view__graph-wrapper__graph-container__menu__icon', menuButton, undefined, { 'src': MoreIcon });
		menuButton.onclick = (e) => {
			let options: string[] = ['Save','Save As', 'Load', 'Fullscreen', 'Delete'];
            if (innerWidth < 920) {
                options.push('Tags');
                options.push('Settings');
            }
            let disabledFlags: boolean[] = [false, false, false, false, false];
			if (!this.chart) {
                disabledFlags[0] = true;
                disabledFlags[4] = true;
			}
			new Dropdown(e, options, (selection) => this.dropdownSelection(selection), undefined, undefined, disabledFlags);
		};

		this.utcOffset = new Date().getTimezoneOffset() * 60;	// Calculate the time zone offset in seconds once
		this.chartElement = createElement('tc-chart-line', '', this.graphRow);

		this.socketView = new TagSocketView(LiveDataClient).initialize(this.toolTabs.getSectionByName('Tags'), tagPanel);
		this.socketView.element = this.chartElement;
		this.attributeEditor = new AttributeEditorView(attributes => {
			for (let [attrName, metadata] of this.chartElement.constructor[attrMetadataSymbol])
				if (typeof attributes[attrName] !== 'undefined')
					this.chartElement.setAttribute(attrName, attributes[attrName]);
				else
					this.chartElement.removeAttribute(attrName);
		}).initialize(this.toolTabs.getSectionByName('Settings'));

		this.updateChartAttributes(this.attributeEditor);
		if (this.props.id) {
			this.loadChart(parseInt(this.props.id));
		};

		if (new Date().getTime() < 	1744952400000) {
			User.getPreferences().then(prefs => {
				if (prefs['chart-page-has-seen-release-notes-04-2025'] !== 'true') {
					let dialog = document.createElement('dialog');
					document.body.appendChild(dialog);
					dialog.style.width = '400px';
					dialog.style.backgroundColor = 'var(--color-surface)';
					dialog.style.border = '1px solid var(--color-outline)';
					dialog.style.borderRadius = '8px';
					dialog.style.padding = '0';
					dialog.style.position = 'fixed';
					dialog.style.top = '50%';
					dialog.style.left = '50%';
					dialog.style.transform = 'translate(-50%, -50%)';
					dialog.style.margin = '0';
					dialog.innerHTML = `
						<h4 style="color: var(--color-onBrand); margin: 0; background-color: var(--color-brand); padding: 5px; width: 100%; box-sizing: border-box;">Chart Tool Overhaul</h4>
						<ul style="color: var(--color-onSurfaceVariant); margin: 0; padding: 16px 24px;">
							<li style="margin-bottom: 8px;"><strong style="color: var(--color-onSurface)">Multi-Device Data:</strong> Combine data from various devices seamlessly.</li>
							<li style="margin-bottom: 8px;"><strong style="color: var(--color-onSurface)">Line Customization:</strong> Adjust thickness, color, min/max, scaling, and names.</li>
							<li style="margin-bottom: 8px;"><strong style="color: var(--color-onSurface)">Custom Axes:</strong> Add unique minor x and y axis lines.</li>
							<li style="margin-bottom: 8px;"><strong style="color: var(--color-onSurface)">New Date Picker:</strong> Quick, custom date selection.</li>
							<li style="margin-bottom: 8px;"><strong style="color: var(--color-onSurface)">Save &amp; Load:</strong> Persist your charts for later use.</li>
							<li style="margin-bottom: 8px;"><strong style="color: var(--color-onSurface)">Mobile-Optimized:</strong> Smoother experience on any device.</li>
							<li style="margin-bottom: 8px;"><strong style="color: var(--color-onSurface)">Performance Boost:</strong> Faster updates and smoother interactions.</li>
						</ul>
						<div style="padding: 16px; display: flex; flex-direction: column; gap: 8px;">
							<label style="display: flex; align-items: center; gap: 8px; color: var(--color-onSurface); margin-bottom: 16px;">
								<input type="checkbox" id="chart-page-release-notes-checkbox" style="border-radius: 8px; width: 16px; height: 16px; background-color: var(--color-surface); border: 1px solid var(--color-outline);">
								Don't show this message again
							</label>
							<button id="chart-page-release-notes-close-button" style="background-color: var(--color-brand); color: var(--color-onBrand); border: none; padding: 8px 16px; border-radius: 4px; cursor: pointer; max-width: 100px;">Got it!</button>
						</div>
					`;
					dialog.showModal();
					dialog.querySelector<HTMLButtonElement>('#chart-page-release-notes-close-button')!.onclick = () => {
						dialog.close();
						const checkbox = dialog.querySelector<HTMLInputElement>('#chart-page-release-notes-checkbox');
						if (checkbox?.checked) {
							User.setPreferences({['chart-page-has-seen-release-notes-04-2025']: 'true'});
						}
					}
				}
			})
		}
	}

	updateChartAttributes(attributeEditor: AttributeEditorView) {
		let supportedProps: Readonly<Map<string, ExtendedAttributeMetadata>> = this.chartElement.constructor[attrMetadataSymbol];
		// FIXME: this is a hack. People didn't want these attributes editable on the chart page...
		supportedProps.delete('is-interactive');
		supportedProps.delete('date-selection');
		supportedProps.delete('highlight-hover');
		supportedProps.delete('show-export-button');
		supportedProps.delete('show-screenshot-button');
		if (supportedProps) // should have supported properties if this is a Widget
		{
			attributeEditor.populateSettings(supportedProps, this.chartElement).then(() => {
				let attributes: { [key: string]: string } = {};
				let autoAttributes: { [key: string]: string } = {};
				for (let [attrName, metadata] of supportedProps) {
					let setting = this.chartElement.getAttribute(attrName);
					if (setting !== null)
						attributes[attrName] = setting;
					if (this.chartElement[defaultAttrValues].has(attrName)) {
						if (this.chartElement[defaultAttrValues].has(attrName)) {
							let attribute = this.chartElement[defaultAttrValues].get(attrName);
							let stringAttr = '';
							switch (metadata.type) {
								case "String":
									stringAttr = attribute;
									break;
								case "Boolean":
								case "Number":
									stringAttr = attribute.toString();
									break;
								case "Object":
								case "Array":
									stringAttr = JSON.stringify(attribute);
									break;
								default:
									stringAttr = attribute;
							}
							if (stringAttr !== '')
								autoAttributes[attrName] = stringAttr;
						}
					}
				}
				attributeEditor.setSettingsToEdit(attributes, {}, autoAttributes);
			});
		}
	}

	closeAddPanel() {
		this.wrapper.setAttribute('add-open', 'false');
	}

	openAddPanel() {
		this.wrapper.setAttribute('add-open', 'true');
	}

    closeSettingsPanel() {
        this.wrapper.setAttribute('settings-open', 'false');
    }

    openSettingsPanel() {
        this.wrapper.setAttribute('settings-open', 'true');
    }

	createButton(parent: HTMLElement, name: string, icon: string, callback: (name: string) => void) {
		let button = createElement('button', 'chart-view__action-button', parent);
		createElement('img', 'chart-view__action-button__icon', button, '', { src: icon });
		createElement('div', 'chart-view__action-button__title', button, name);
		button.onclick = () => callback(name);
	}

	fullscreenGraph() {
		/*
		this.fFullScreen = true;
		this.fullScreenWrapper 			= createElement('div', 'chart-view__modal', document.body);
		this.fullScreenWrapperContainer	= createElement('div', 'chart-view__modal__container', this.fullScreenWrapper);
		let modalMenu		= createElement('div', 'chart-view__graph-wrapper__graph-container__menu', this.fullScreenWrapperContainer);
		this.fullScreenWrapperControl	= createElement('div', 'chart-view__graph-wrapper__graph-container__menu__control-row', modalMenu);
		let modalButton 	= createElement('button', 'chart-view__modal__button', modalMenu);
		let modalIcon		= createElement('img', 'chart-view__graph-wrapper__graph-container__menu__icon', modalButton, undefined, {'src': CloseIcon});
		this.fullScreenWrapperGraphRow	= createElement('div', 'chart-view__graph-wrapper__graph-container__graph-row', this.fullScreenWrapperContainer);
		this.fullScreenWrapperGraph 	= createElement('div', 'chart-view__graph-wrapper__graph-container__graph-row__graph', this.fullScreenWrapperContainer);
		this.fullScreenWrapperLegend	= createElement('div', 'chart-view__graph-wrapper__graph-container__menu', this.fullScreenWrapperContainer);
		let options = {drawXAxis:true,drawYAxis:true,fRotated:true};
		this.graph.destroy();
		var end 	= new Date();
		let start 	= new Date(new Date().getTime() - 86400000);
		this.graph 	= new DragDropGraph(this.ldc, this.fullScreenWrapperGraph, this.fullScreenWrapperGraph.clientWidth, this.fullScreenWrapperGraph.clientHeight - 25, start, end, true, true, true, this.fullScreenWrapperControl, this.fullScreenWrapperLegend, options);
		this.graphNodes.forEach(node => {
			this.graph.addNode(node, true, undefined, undefined, true);
		});
		modalButton.onclick = () => {
			this.fFullScreen = false;
			this.graph.destroy();
			this.fullScreenWrapper.removeChildren();
			document.body.removeChild(this.fullScreenWrapper);
			this.resize();
		}
		let dateArray 	= this.graph.graph.xAxisRange();	// get our start and end date from our graph
		this.graph.requestDataForAllDevices(dateArray[0], dateArray[1], this.graph._calculateInterval(dateArray[0], dateArray[1]));
		*/
	}
	fixTime(element) {
		if (element.oldValue) {	// Fix the damn arrow keys
			// both are of the form 2017-05-15T16:08
			if (isNaN(element.valueAsNumber)) {
				element.valueAsNumber = element.oldDate + (element.sign == 1 ? -86400000 : 86400000);
			}
			var offset = new Date().getTimezoneOffset() * 60;
			var date = new Date(element.oldDate + offset * 1000);
			if (element.oldValue.substr(0, 4) !== element.value.substr(0, 4))	// If they changed the year
				date.setFullYear(date.getFullYear() + element.sign);
			else if (element.oldValue.substr(5, 2) !== element.value.substr(5, 2))
				date.setMonth(date.getMonth() + element.sign);
			else if (element.oldValue.substr(8, 2) !== element.value.substr(8, 2))
				date.setDate(date.getDate() + element.sign);
			else if (element.oldValue.substr(11, 2) !== element.value.substr(11, 2))
				date.setHours(date.getHours() + element.sign);
			else
				date.setMinutes(date.getMinutes() + element.sign);

			// do not fix/update the time into the future
			let updatedTime = date.getTime() - offset * 1000;
			let currentTime = new Date().getTime() - offset * 1000;
			if (updatedTime <= currentTime) {
				element.valueAsNumber = updatedTime;
			}
			delete element.oldValue;
		}
	}

	//onDateChange(e) {	// Whenever either of the date objects change
	//	this.fixTime(e.target);
	//	if (this.isValidDates(this.startTime.valueAsNumber, this.endTime.valueAsNumber)) {
	//		this.graph.updateWindow(this.startTime.valueAsNumber + this.utcOffset * 1000, this.endTime.valueAsNumber + this.utcOffset * 1000);	// Slide the graph
	//	}
	//}

	/* start: start time in seconds since epoch
	 * end: end time in seconds since epoch
	 * mostly avoids all the asserts buried in the underlying code, mostly
	 */
	isValidDates(start, end) {
		return !(isNaN(start)) && !(isNaN(end)) && start > 1000000000 && end > 0 && end < new Date() && start !== end && start < end;
	}

	async dropdownSelection(selection) {
		switch (selection) {
			case 'Tags':
                let tagView = new TagSocketView(LiveDataClient);
                new ViewModal(tagView, {
                    maxWidth: '400px',
				    title: 'Edit Tags',
				    titleBackgroundColor: 'var(--color-primary)',
				    titleTextColor: 'var(--color-inverseOnSurface)',
                });
                tagView.element = this.chartElement;
                break;
				//new ViewModal(new TreeView({
				//	folder: owner.selectedDevice?.tree.nodes[0] ?? undefined, 		// start at the root tag
				//	type: TreeViewTypes.TVT_MULTISELECT,			// let the user select any number of tags
				//	selectedTags: this.graphNodes.map(graphNode => graphNode.node), // tell the tagView if we already have tags selected
				//	selectCallback: this.selectCallback.bind(this),
				//	deselectCallback: this.deselectCallback.bind(this),
				//	selectFilters: {
				//		andFilters: [new LoggedFilter(true, true)],
				//		orFilters: []
				//	}
				//}), { // ViewModal Options
				//	maxWidth: '400px',
				//	title: 'Export Data',
				//	titleBackgroundColor: 'var(--color-primary)',
				//	titleTextColor: 'var(--color-inverseOnSurface)',
				//});
				//break;
            case 'Settings':
                let settingsView = new AttributeEditorView(attributes => {
                    for (let [attrName, metadata] of this.chartElement.constructor[attrMetadataSymbol])
                        if (typeof attributes[attrName] !== 'undefined')
                            this.chartElement.setAttribute(attrName, attributes[attrName]);
                        else
                            this.chartElement.removeAttribute(attrName);
                });
                new ViewModal(settingsView, {
                    maxWidth: '400px',
				    title: 'Edit Settings',
				    titleBackgroundColor: 'var(--color-primary)',
				    titleTextColor: 'var(--color-inverseOnSurface)',
                });
                this.updateChartAttributes(settingsView)
                break;
            case 'Fullscreen':
                this.graphRow.requestFullscreen().then(()=> {//@ts-ignore
                    if(screen.orientation && screen.orientation.lock) {//@ts-ignore
                        screen.orientation.lock('landscape').catch(err => console.error(err));
                    }
                });
                break;

			case 'Save As':
			case 'Save':
				if (this.chart === null || selection === 'Save As') {
					let saveDialog = new Dialog(document.body, {
						title: selection,
						body: 'Please enter a name for this Chart',
						titleBackground: 'var(--color-brand)',
						titleColor: 'var(--color-onBrand)',
						fText: true,
						callback: async value => {
							let version = 1;
							let fm = new FrameMaker();
							fm.buildFrame(LiveData.WVC_CREATE_GRAPH);
							fm.push_string(value as string);
							fm.push_u16(version);

							let data = this.chartElement.outerHTML;

							const stream = new Blob([data], {
								type: 'text/html',
							}).stream();
							//@ts-ignore
							const compressedReadableStream = stream.pipeThrough(new CompressionStream("gzip"));
							const arrayBuffer = await new Response(compressedReadableStream).blob().then(result => result.arrayBuffer());

							fm.push_u32(arrayBuffer.byteLength);
							fm.push_buffer(arrayBuffer);
							LiveDataClient.sendRequest(fm).then(fp => {
								if (fp.pop_bool()) {
									let id = fp.pop_u32();
									this.chart = {
										id: id,
										creator: User.username,
										name: value as string,
										version: version,
										lastModified: new Date().getTime(),
									}
									saveDialog.destroy();
									this.titleInput.value = this.chart.name;
									this.titleInput.style.visibility = '';
								}
								else {
									//TODO: handle the error
								}
							});
						},
					})
				}
				else { // Update the existing chart
					let fm = new FrameMaker();
					fm.buildFrame(LiveData.WVC_UPDATE_GRAPH);
					fm.push_u32(this.chart.id);
					fm.push_string(this.chart.name);
					fm.push_u16(this.chart.version);

					let data = this.chartElement.outerHTML;

					const stream = new Blob([data], {type: 'text/html'}).stream();
					//@ts-ignore
					const compressedReadableStream = stream.pipeThrough(new CompressionStream("gzip"));
					const arrayBuffer = await new Response(compressedReadableStream).blob().then(result => result.arrayBuffer());

					fm.push_u32(arrayBuffer.byteLength);
					fm.push_buffer(arrayBuffer);
					fm.push_u8(0); // Delete flag
					LiveDataClient.sendRequest(fm).then(fp => {
						if (!fp.pop_bool())
							new Dialog(document.body, {
								title: 'Error',
								body: 'Something went wrong while trying to save your chart. Please wait a moment and try again.',
								titleBackground: 'var(--color-error)',
								titleColor: 'var(--color-onError)'
							})

					})
				}
				break;
			case 'Load':
				let load_fm = new FrameMaker();
				load_fm.buildFrame(LiveData.WVC_GET_GRAPH_INFO);
				LiveDataClient.sendRequest(load_fm).then(fp => {
					let count = fp.pop_u32();
					let chartOptions: HTMLOptionElement[] = [];
					for (let i = 0; i < count; ++i) {
						let metadata: ChartMetadata = {
							id: fp.pop_u32(),
							creator: User.username,
							name: fp.pop_string(),
							version: fp.pop_u16(),
							lastModified: fp.pop_u64()
						}
						let option = document.createElement('option');
						option.textContent = metadata.name;
						option.value = metadata.id.toString();
						chartOptions.push(option)
					};
					new Dialog(document.body, {
						title: 'Load Chart',
						body: 'Select the Chart you would like to load and click the accept button to continue.',
						titleBackground: 'var(--color-brand)',
						titleColor: 'var(--color-onBrand)',
						minWidth: 300,
						fSelect: true,
						options: chartOptions,
						callback: option => {
							let selectedID = (option as HTMLOptionElement).value;
                            location.href    = getHash(...getRouteAndProperties(location.href, {'id':selectedID}));
						}
					})
				});

				break;
			case 'Delete':
				if (this.chart === null)
					throw new Error('Invalid chart during delete');
				let acceptDialog = new Dialog(document.body, {
					title: 'Delete Chart',
					body: `Are you sure you want to delete '${this.chart.name}'?`,
					titleBackground: 'var(--color-error)',
					titleColor: 'var(--color-onError)',
					buttons: [
						{ 'title': 'Delete', callback: () => this.deleteChart()},
						{ 'title': 'Cancel', callback: () => acceptDialog.destroy()}
					]
				})
			default:
				assert(false, 'Bad dropdown selection from Chart View');
		}
	}

	loadChart(chartID: number) {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_GET_GRAPH_DATA);
		fm.push_u32(chartID);
		LiveDataClient.sendRequest(fm).then(fp => {
			if (fp.pop_bool()) {
				this.chart = {
					id: fp.pop_u32(),
					creator: fp.pop_string(),
					name: fp.pop_string(),
					version: fp.pop_u16(),
					lastModified: fp.pop_u64()
				}
				let configSize = fp.pop_u32();
				let config = fp.pop_buffer(configSize);
				const ds = new DecompressionStream("gzip");
				const stream = new Blob([config!]).stream();
				if (config!.byteLength > 0) {
					const decompressedStream = stream.pipeThrough(ds);
					new Response(decompressedStream).text().then((result) => {
						this.graphRow.removeChildren();
						this.sanitizer.setHTML(this.graphRow, result);
						this.chartElement = this.graphRow.firstChild as LineChart
						this.chartElement.isInitialized().then(status => this.socketView.element = this.chartElement);
						this.updateChartAttributes(this.attributeEditor);
						this.titleInput.style.visibility = '';
						this.titleInput.value = this.chart!.name;
					}).catch(reason => {
						new Dialog(document.body, {
							title: 'Error',
							body: `Something went wrong while trying to load your chart: ${reason}. Please wait a moment and try again.`,
							titleBackground: 'var(--color-error)',
							titleColor: 'var(--color-onError)'
						})
					});
				};
			}
			else
				new Dialog(document.body, {
					title: 'Error',
					body: 'Something went wrong while trying to load your chart. Please wait a moment and try again.',
					titleBackground: 'var(--color-error)',
					titleColor: 'var(--color-onError)'
				})
		});
	};

	async deleteChart() {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_UPDATE_GRAPH);
		fm.push_u32(this.chart!.id);
		fm.push_string(this.chart!.name);
		fm.push_u16(this.chart!.version);

		let data = this.chartElement.outerHTML;

		const stream = new Blob([data], {type: 'text/html'}).stream();
		//@ts-ignore
		const compressedReadableStream = stream.pipeThrough(new CompressionStream("gzip"));
		const arrayBuffer = await new Response(compressedReadableStream).blob().then(result => result.arrayBuffer());

		fm.push_u32(arrayBuffer.byteLength);
		fm.push_buffer(arrayBuffer);
		fm.push_u8(1); // Delete flag
		LiveDataClient.sendRequest(fm).then(fp => {
			if (!fp.pop_bool())
				new Dialog(document.body, {
					title: 'Error',
					body: 'Something went wrong while trying to save your chart. Please wait a moment and try again.',
					titleBackground: 'var(--color-error)',
					titleColor: 'var(--color-onError)'
				})
			else {
				new Dialog(document.body, {
					title: 'Deleted',
					body: `Successfully Deleted Chart '${this.chart!.name}'`,
					titleBackground: 'var(--color-brand)',
					titleColor: 'var(--color-onBrand)'
				})
				this.chart = null;
				this.chartElement.remove();
				this.chartElement = createElement('tc-chart-line', '', this.graphRow);
				this.chartElement.isInitialized().then(status => this.socketView.element = this.chartElement);
				this.updateChartAttributes(this.attributeEditor);
				this.titleInput.value = '';
				this.titleInput.style.visibility = 'hidden';
			}
		})
	}

	//downloadData(start: Date, end: Date, interval: DataInterval) {
	//	this.loaderContainer.classList.remove('hide');
//
	//	var pointCount = ((end.getTime() / 1000 - start.getTime() / 1000) / interval) * this.graph.orderedNodes!.length;
	//	if (start >= end || pointCount > 1E6) { 	// Limit of 1M points - will get disconnected by the server if we allow them to make this request
	//		new Dialog(document.body, {
	//			title: 'Error',
	//			body: 'Your request exceeded the maximum amount of data you can request (1 million data points). Please reduce the requested resolution or narrow your timespan.'
	//		})
	//		this.loaderContainer.classList.add('hide');
	//		return;
	//	}
//
	//	//this.cruncher.getFormattedData(start, end, this.graphNodes.map(graphNode => graphNode.node), interval).then((data => {
	//	//	let csv = 'Time' + this.separator;		// Start out with a time column
	//	//	let names = data[0];	// Get the names out of the data
	//	//	for (let i = 0; i < names.length; ++i) {	// For each name
	//	//		csv += names[i] + this.separator;	// Add it to the first line so each column has a heading
	//	//	}
	//	//	csv += '\n';			// Add a carriage return after the header
	//	//	for (let i = 0; i < data[1].length; ++i) {
	//	//		csv += `${new Date(data[1][i]).format('%yyyy/%MM/%dd %HH:%mm:%ss')},`
	//	//		for (let j = 3; j < data.length; j += 4) {
	//	//			csv += `${data[j][i]},`
	//	//		}
	//	//		csv += '\n'
	//	//	}
////
	//	//	// Create an href element that will allow the user to download the data as a CSV
	//	//	var downloadLink = document.createElement('a');	// Chrome allows the link to be clicked without actually adding it to the DOM.
	//	//	downloadLink.download = `Chart_Export_${new Date().toLocaleDateString()}.csv`;		// File name to download as
	//	//	downloadLink.href = URL.createObjectURL(new Blob([csv], { type: 'text/plain' }));	// Make a blob text file URL for the CSV
	//	//	downloadLink.click();							// Simulate clicking on the hyperlink
	//	//	this.loaderContainer.classList.add('hide');
	//	//}));
	//}

	/* This is called every time the user checks a tag */
	selectCallback(tags: Node[]) {
		//for (let i = 0; i < tags.length; i++) {
		//	let nodeArray = this.graphNodes.map(graphNode => graphNode.node);
		//	if (nodeArray.includes(tags[i]))
		//		continue;
		//	let color = owner.colors.hex(`--color-graph-${this.graphNodes.length + 1}`);
		//	this.graphNodes.push(new GraphNode(tags[i], color, undefined));
		//	this.graph.addNode(tags[i], false, undefined, color, true, tags[i].engMax, tags[i].engMin);		// Actually add the node to the graph
		//}
		//this.updateGraph();
	}

	/* This is called every time the user un-checks a tag */
	deselectCallback(tag: Node) {
		//@ts-ignore
		//this.graph._removeNode(tag);
		//for (let i = 0; i < this.graphNodes.length; ++i) {
		//	if (this.graphNodes[i].node === tag) {
		//		this.graphNodes.splice(i, 1);	// Found the node to remove
		//		break;							// No need to look further
		//	}
		//}
		//this.updateGraph();
	}

	//showSettingsModal(graphNode: GraphNode) {
	//	let settings = {
	//		'color': graphNode.color,
	//		'name': graphNode.name,
	//		'min': graphNode.nodeMin?.toString(),
	//		'max': graphNode.nodeMax?.toString(),
	//		'fMinMax': graphNode.fMax ? 'true' : 'false'
	//	}
	//	let modal = new ViewModal(new TagSettingsView(this), {
	//		maxWidth: '400px',
	//		maxHeight: '300px',
	//		title: 'Settings',
	//		titleBackgroundColor: 'var(--color-primary)',
	//		titleTextColor: 'var(--color-inverseOnSurface)',
	//		buttons: [
	//			{
	//				title: 'Accept',
	//				borderColor: 'var(--color-green-8)',
	//				callback: () => {
	//					graphNode.color = settings.color;
	//					graphNode.name = settings.name;
	//					graphNode.nodeMin = parseFloat(settings.min) ?? graphNode.node.engMin;
	//					graphNode.nodeMax = parseFloat(settings.max) ?? graphNode.node.engMax;
	//					graphNode.fMax = settings.fMinMax == '1';
	//					this.refreshNodes();
	//					this.resize();
	//					return true;
	//				}
	//			},
	//			{
	//				title: 'Cancel',
	//				borderColor: 'var(--color-red-8)'
	//			}
	//		]
	//	});
	//	modal.view.setSettingsToEdit(settings, ['CHART']);
	//}

	createSettingButton(parent: HTMLElement, icon: string): HTMLButtonElement {
		let button = createElement('button', 'se-button chart-view__setting-button', parent);
		createElement('img', 'chart-view__setting-button__icon', button, '', { src: icon });
		return button;
	}

    setProps(newProps: {[key: string]: string}) {
        const entries = Object.entries(newProps);
        for (const [key, value] of entries) {
            if (this.props[key] != value) { // check whether each property has changed
                this.props[key] = value;
                switch(key) {
                    case 'id': // if the device key changes, create a new device page
                        this.loadChart(parseInt(value));
                        return;
                    default:
                        break;
                }
            }
        }
    };

	destroy() {
		removeEventListener('resize', this.resizeListener);
		this.cruncher.destroy();
		this.parent.destroyWidgets(true);			// Don't need to destroy our graphs. That will happen here automagically
		this.parent.removeChildren();				// Delete any DOM elements left over
	}
}