import { RegisterWidget, Widget } from "../../lib/widget";
import { Attribute } from "../../lib/attributes";
import { TagSetAttribute, type TagDefinition, type Tag, VType } from '../../lib/tag';
import LineChartIcon from '../../../images/icons/line-chart.svg';
import { ColorInput, SelectInput } from "../../../views/attributeeditorview";
import { RadioButton } from "../../input/radio/radiobutton";
import template from './linechart.html';
import Cruncher, { CalculateInterval, DataInterval, HistoricalData, TagData } from "../../../cruncher";
import { NodeFlags } from "../../../node";
import { Device } from "../../../device";
import ExportIcon from '../../../images/icons/download.svg';
import ScreenshotIcon from '../../../images/icons/photo.svg'
import CloseIcon from '../../../images/icons/close.svg';
import html2canvas from "html2canvas";

const MaxPixelsPerPoint = 8;

class ColorStringArray extends Array<string> {
    constructor(...items: string[]) {
        super(...items);
    }

    get(index: number): string {
        debugger;
        if (index > this.length)
            debugger;
        return this[index % this.length];
    }
}

const ColorStrings = new ColorStringArray(
    "#3CB44B", // Green
    "#0082C8", // Blue
    "#F58231", // Orange
    "#911EB4", // Purple
    "#E6194B", // Red
    "#00CED1", // Dark Turquoise
    "#C71585", // Medium Violet Red
    "#FFD700", // Gold
    "#FF4500", // Orange Red
    "#2E8B57", // Sea Green
    "#800000", // Maroon
    "#000080", // Navy
    "#006400", // Dark Green
    "#8B0000", // Dark Red
    "#483D8B", // Dark Slate Blue
    "#A0522D", // Sienna
    "#B22222", // Firebrick
    "#5F9EA0", // Cadet Blue
    "#DAA520", // Goldenrod
    "#4B0082", // Indigo
    "#228B22", // Forest Green
    "#FF1493", // Deep Pink
    "#DC143C", // Crimson
    "#D2691E", // Chocolate
    "#6A5ACD"  // Slate Blue
);

type Axis = 'left' | 'right';

const separator = (1.1).toLocaleString()[1] === ',' ? ';' : ',';
interface DateRange {
    start: number;
    end: number;
}

interface DataRequest extends DateRange {
    interval: DataInterval;
    tags: TagData[];
}

interface GraphedDeviceInfo extends DateRange {
    tags: TagData[]
    request: DataRequest | null;
}


@RegisterWidget({ tag: 'tc-chart-line', displayName: 'Line Graph', template: template, icon: LineChartIcon, section: 'Charts', editor: true })
export class LineChart extends Widget {
    @Attribute({
        displayName: 'Default Range',
        section: 'Display',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new SelectInput(name, parent, property, getValue, ['Minute', 'Hour', 'Day', 'Week', 'Year'], ['60000', '3600000', '86400000', '604800000', '31540000000'], onSettingChangedCallback)
    }) defaultRange: number = 86400000;
    @Attribute({ displayName: 'Show Legend', section: 'Display' }) showLegend: boolean = true;
    @Attribute({
        section: 'Display',
        displayName: 'Grid Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) gridColor: string = '#000000';
    @Attribute({
        section: 'Display',
        displayName: 'X Axis Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) xAxisColor: string = '#000000';
    @Attribute({
        section: 'Display',
        displayName: 'Y Axis Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) yAxisColor: string = '#000000';
    @Attribute({
        section: 'Display',
        displayName: 'Axis Label Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) axisLabelColor: string = '#000000';
    //@Attribute({section: 'Data', displayName: 'Group Common Units'}) groupCommonUnits: boolean = false;
    @Attribute({ displayName: 'Minor X Lines', section: 'Display',  }) minorXLines: number = 0;
    @Attribute({ displayName: 'Minor Y Lines', section: 'Display' }) minorYLines: number = 0;
    @Attribute({ displayName: 'Make Interactive', section: 'Interaction' }) isInteractive: boolean = true;
    @Attribute({ displayName: 'Date Selection', section: 'Interaction' }) dateSelection: boolean = true;
    @Attribute({ displayName: 'Highlight on Hover', section: 'Interaction' }) highlightHover: boolean = true;
    @Attribute({ displayName: 'Show Export Button', section: 'Interaction' }) showExportButton: boolean = true;
    @Attribute({ displayName: 'Show Screenshot Button', section: 'Interaction' }) showScreenshotButton: boolean = false;
    @TagSetAttribute({
        displayName: 'Live Data Tags', requiresHistorical: true, isOptional: true, attributes: [
            {section: 'Display',    id: 'color',        type: 'String',     displayName: 'Color', getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip), default: (tag, index)=>ColorStrings[index!]},
            {section: 'Display',    id: 'name',         type: 'String',     displayName: 'Display Name'},
            {section: 'Display',    id: 'min',          type: 'Number',     displayName: 'Y-min', default: (tag) => tag.engMin},
            {section: 'Display',    id: 'max',          type: 'Number',     displayName: 'Y-max', default: (tag) => tag.engMax},
            {section: 'Data',       id: 'show-min-max', type: 'Boolean',    displayName: 'Min-Max'},
            {section: 'Display',    id: 'line-width',   type: 'Number',     displayName: 'Line Width',  default: ()=>1}
        ]
    }) liveValueTags: TagDefinition[] = []

    private isLive: boolean = true;
    private endDate: Date = new Date();
    private startDate: Date;
    private graphRow: HTMLElement;
    private graphElement: HTMLElement;
    private legendRow: HTMLElement;
    private dateRadio: RadioButton;
    private liveRadio: RadioButton;
    private exportButton: HTMLElement;
    private screenshotButton: HTMLElement;
    private isAboutToResize: boolean = false;
    private timerID: NodeJS.Timeout | null = null;
    private dygraph: Dygraph;
    private dygraphOptions: any;
    private cruncher = new Cruncher();
    private plottedData = new Map<Device, GraphedDeviceInfo>();
    private interval: DataInterval | -1;
    private intervalIsChanging: boolean = false;
    private fUpdating: boolean = true;
    private leftAxisIndex: number = 0;
    private rightAxisIndex: number = 1;
    private maxAxis: number = 0;
    private fLocked: boolean;
    private startSelector: HTMLInputElement;
    private endSelector: HTMLInputElement;
    protected connectedCallback(): void {
        // Create references to all our shadow DOM elements
        this.graphRow = this.shadowRoot!.querySelector('.graph-row') as HTMLElement;
        this.graphElement = this.shadowRoot!.querySelector('.graph') as HTMLElement;
        this.dateRadio = this.shadowRoot?.getElementById('date-selector') as RadioButton;
        let exportImage = this.shadowRoot!.getElementById('export-image') as HTMLImageElement;
        this.exportButton = this.shadowRoot!.getElementById('export-button') as HTMLElement;
        this.screenshotButton = this.shadowRoot!.getElementById('screenshot-button') as HTMLElement;
        let screenshotImage = this.shadowRoot!.getElementById('screenshot-image') as HTMLImageElement;
        let modalElement = this.shadowRoot!.getElementById('download-modal') as HTMLDialogElement;
        let closeModal = this.shadowRoot!.getElementById('close-modal') as HTMLButtonElement;
        let closeIcon = this.shadowRoot!.getElementById('close-icon') as HTMLImageElement;
        let exportAccept = this.shadowRoot!.getElementById('export-accept') as HTMLButtonElement;
        let exportClose = this.shadowRoot!.getElementById('export-cancel') as HTMLButtonElement;
        let startInput = this.shadowRoot!.getElementById('start-input') as HTMLInputElement;
        let endInput = this.shadowRoot!.getElementById('end-input') as HTMLInputElement;
        let intervalSelect = this.shadowRoot!.getElementById('interval-select') as HTMLSelectElement;
        this.startSelector = this.shadowRoot!.getElementById('start-selector') as HTMLInputElement;
        this.endSelector = this.shadowRoot!.getElementById('end-selector') as HTMLInputElement;

        this.startSelector.oninput = () => {
            this.stopTimer();
            let offset = new Date().getTimezoneOffset() * 60000;
            let maxTime = new Date().getTime() - offset;
            let endTime = this.endSelector.valueAsNumber;
            if (this.startSelector.valueAsNumber > endTime - 60000) {
                this.startSelector.valueAsNumber = endTime - 60000;
            }
        }
        this.endSelector.oninput = () => {
            this.stopTimer();
            let offset = new Date().getTimezoneOffset() * 60000;
            let maxTime = new Date().getTime() - offset;
            this.endSelector.valueAsNumber = Math.min(maxTime, this.endSelector.valueAsNumber);
            let startTime = this.startSelector.valueAsNumber;
            if (this.endSelector.valueAsNumber < startTime + 60000) {
                this.endSelector.valueAsNumber = startTime + 60000;
            }
        }
        this.startSelector.onchange = this.endSelector.onchange = () => {
            let offset = new Date().getTimezoneOffset() * 60000; // Convert to milliseconds
            let startDateLocal = new Date(this.startSelector.valueAsNumber - offset);
            let endDateLocal = new Date(this.endSelector.valueAsNumber - offset);
            this.updateWindow(startDateLocal.getTime(), endDateLocal.getTime());
        }

        // Set all our icon sources
        screenshotImage.src = ScreenshotIcon;
        exportImage.src = ExportIcon;
        closeIcon.src = CloseIcon

        // Set up event listeners
        closeModal.onclick = () => modalElement.close();
        startInput.onchange = endInput.onchange = () => this.checkExport(startInput, endInput, intervalSelect);
        this.exportButton.onclick = () => {
            let offset = new Date().getTimezoneOffset() * 60000; // Convert to milliseconds
            let dates = this.dygraph?.xAxisRange();	// Get how much the window has selected
            let startDateLocal = new Date(dates[0] - offset);
            let endDateLocal = new Date(dates[1] - offset);
            startInput.value = startDateLocal.toISOString().slice(0,16);
            endInput.value = endDateLocal.toISOString().slice(0,16);
            this.checkExport(startInput, endInput, intervalSelect);
            modalElement.showModal();
        }
        exportClose.onclick = () => modalElement.close();
        exportAccept.onclick = () => {
            modalElement.close();
            this.downloadData(new Date(startInput.valueAsNumber), new Date(endInput.valueAsNumber), parseInt(intervalSelect.value) as DataInterval);
        }
        this.screenshotButton.onclick = () => html2canvas(this.graphRow, { useCORS: true }).then(canvas => {
            // Convert canvas to image
            canvas.toBlob(blob => {
                if (blob) {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = "captured-image.png";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }, "image/png", 0.5);
        })
        this.dateRadio.onchange = () => {
            if (this.dateRadio.value != -1) {					// For the custom case
                let dates = this.dygraph?.xAxisRange();	// Get how much the window has selected
                dates[0] = dates[1] - this.dateRadio.value;	// Start at the same end point, but graph the selected interval

                let fUpdating = this.fUpdating;		// Store whether or not the graph was live
                this.updateWindow(dates[0], dates[1]);

                let interval = this.determineInterval();
                this.fUpdating = fUpdating;			    // Reset this variable (that was unset in _onDraw)
                if (fUpdating)									// If we were updating @ts-ignore
                    this.startTimer(interval);	// Start the timer again to call us again to keep updating
            }
            else {
                this.stopTimer();
            }
            //if (this.dateRadio.value != 1000000000000 && !this.graph.live)            // if we click our custom button
            //    this.makeInteractive(true)             // make sure this graph is interactive
        }

        this.legendRow = this.shadowRoot!.querySelector('.legend') as HTMLElement;
        this.liveRadio = this.shadowRoot?.getElementById('live-selector') as RadioButton;
        this.liveRadio.onchange = () => {
            if (this.liveRadio.value == 0) {
                var range = this.dygraph.xAxisRange();		// Get the current range of the graph
                var date = new Date().getTime();			// Current date
                this.updateWindow(date - (range[1] - range[0]), date);	// Look at now (in the same interval they had before)
                this.startTimer(this.determineInterval());	// Start the update timer, giving it the current graph's interval
            }
            else
                this.stopTimer();
        }
    }

    protected enliven() {
        this.dateRadio.value = this.defaultRange;
        if (!this.dateSelection) {
            this.dateRadio.style.display = 'none';
        }
        if (!this.isInteractive)
            this.liveRadio.style.display = 'none';

        if (!this.showExportButton || this.liveValueTags.length < 1)
            this.exportButton.style.display = 'none';
        if (this.showScreenshotButton)
            this.screenshotButton.style.visibility = 'visible';

        this.leftAxisIndex = 0;
        this.rightAxisIndex = 0;
        this.maxAxis = 0;
        this.endDate = this.endDate ?? new Date();
        this.startDate = this.startDate ?? new Date(new Date().getTime() - this.defaultRange);
        this.plottedData.clear();
        this.setOptions();
        if (!this.dygraph)
            this.createDefaultGraph();
        else {
            this.dygraph.maindiv_ = this.graphElement;
            this.graphElement.append(this.dygraph.graphDiv!);
            this.fLocked = true;
            this.dygraph.updateOptions(null, this.dygraphOptions);
            this.fLocked = false;
        }

        //if (this.isInteractive) TODO: Make this work again
        //    this.graph.makeInteractive(true);	// Let them pan and zoom and such

        if (!this.showLegend)
            this.legendRow.style.display = 'none';

        //this.graph.axesCanChange();	TODO: this		// Axis can change between attached nodes

        /**
         * This next section cleans up our stored tag requests and data
         */
        let removeTags = new Set<Tag>();
        this.plottedData.forEach(data => {
            data.tags.forEach(tagData => removeTags.add(tagData.node));
        })
        this.liveValueTags.forEach((tagDef, index) => {
            if (removeTags.has(tagDef.tag)) // We already had this tag in our set
                removeTags.delete(tagDef.tag);
            else
                this.addTag(tagDef.tag, index, tagDef.attributes);
            this.setTagAttributes(tagDef.tag, index, tagDef.attributes);
        });
        removeTags.forEach(removeTag => {
            let plotted = this.plottedData.get(removeTag.device)!;
            let index = plotted.tags.map(tagData => tagData.node).indexOf(removeTag);
            plotted.tags.splice(index, 1);
            if (plotted.tags.length === 0)
                this.plottedData.delete(removeTag.device);
            this.dygraph.removeLine(removeTag.device.key + removeTag.deviceRelativePath)
        });

        this.createLegendItems();
        this.onResize();
        let range = this.dygraph.xAxisRange();
        this.interval = -1;
        this.requestDataForAllDevices(range[0], range[1]);
    }

    createLegendItems() {
        this.legendRow.childNodes.forEach(child => child.remove());
        let nameMap: Map<string, TagDefinition[]> = new Map();
        let tagMap: Map<TagDefinition, string> = new Map();
        let defaultNamedNodes = this.liveValueTags.filter(tagDef => !tagDef.attributes || tagDef.attributes['name'] === undefined); // Only check nodes without overridden names
        defaultNamedNodes.forEach(tagDef => {
            let name = tagDef.tag.name;
            if (nameMap.has(name))
                nameMap.get(name)!.push(tagDef);
            else
                nameMap.set(name, [tagDef]);
        })
        for (let [name, graphNodes] of nameMap) {
            if (graphNodes.length < 2)
                continue;
            let levels = 0;
            let duplicateNodes = graphNodes.map(duplicate => duplicate.tag); // get the graphnode node's
            let getDuplicateParents = (tags: Tag[]) => { // return an array of parents that still have duplicate names
                let parentNodes = tags.filter(tag => tag.parent).map(tag => tag.parent);
                let parentNames = parentNodes.map(tag => tag.name);
                return parentNodes.filter((tag, index) => parentNames.indexOf(tag.name) !== index) // nodes that have a parent
            }
            while(duplicateNodes.length > 0) { // while there are still duplicates
                duplicateNodes = getDuplicateParents(duplicateNodes);
                ++levels // Increment our level
            }
            graphNodes.forEach(duplicate => { // For each of our original duplicate names
                let tag = duplicate.tag;
                for (let j=0;j<levels;++j) { // For each level we need to go up
                    if (!tag.parent) // If this tag doesn't have a parent, just leave it alone. It should be a fully unique path anyways.
                        return;
                    tagMap.set(duplicate, `${tag.parent.getDisplayName()}.${tagMap.get(duplicate) ?? tag.name}`)
                    tag = tag.parent;
                }
            })
        }
        let template = this.shadowRoot?.getElementById('legend-indicator') as HTMLTemplateElement;

        this.liveValueTags.forEach((tagDef, index) => {
            let checkRow = document.importNode(template.content, true);
            let title = checkRow.querySelector('#legend-title') as HTMLElement;
            title.textContent = tagMap.get(tagDef) ?? tagDef.attributes?.name ?? tagDef.tag.name;
            title.style.backgroundColor = tagDef.attributes && tagDef.attributes['color'] !== undefined ? tagDef.attributes['color'] : ColorStrings[index];
            this.legendRow.appendChild(checkRow);
            title.title = tagDef.tag.absolutePath;
        })
    }

    private createDefaultGraph() {
        //this.legendIndicators = [];					// Create an empty array to hold legend indicators
        //this.timerID = null;						// ID of any timer that is currently counting down
        //this.fUpdating = true;						// Once we get data, start trying to update it
        //this.fInteractive = false;					// Not interactive until they tell us to be

        this.intervalIsChanging = false;
        if (this.startDate && this.endDate) {
            // Fix our start and end times for each line. Specifically create a new number for each array so they can be different
            if (this.dygraph)				// If we have an older graph sitting around
                this.dygraph.destroy();	// Kill the old graph right now

            let range = [this.startDate.getTime(), this.endDate.getTime()]
            var legend = this.dygraphOptions.legend;	// Save the legend option
            var mouseover = this.dygraphOptions.nomouseover;
            this.dygraphOptions.legend = false;				// Put the legend display at never (so we don't display empty data that we have graphed)
            this.dygraphOptions.dateWindow = range;
            this.dygraphOptions.nomouseover = true;

            // Create an empty graph looking at the same time interval they gave us
            let data = [['fake'], range, null, null, null];				// Fill with fake data
            //@ts-ignore
            this.dygraph = new Dygraph(this.graphElement, data, this.dygraphOptions);	// Plot the empty graph
            this.dygraph.createUserInterface(true)
            this.intervalIsChanging = false;	// No outstanding interval changing requests, either

            this.dygraphOptions.legend = legend;	// Set the legend option back to how it started
            this.dygraphOptions.nomouseover = mouseover;
        }
    }

    private setOptions() {
        this.dygraphOptions =  {
            width: this.graphRow.clientWidth,							// Set the graph to whatever weight the passed in
            height: this.graphRow.clientHeight,							// Set the graph to whatever height the passed in
            titleHeight: 22,								// Pixel size of any title we add on
            xLabelHeight: 15,								// Pixel size of any x axis title we add on
            yLabelWidth: 14,								// Pixel size of any y axis title we add on
            xAxisLabelWidth: 75,								// Give the x axis labels enough room to be legible
            xAxisLines: false,
            includeZero: true,							// Graph zero instead of say, 40-50 on the y-axis
            rightGap: 0,
            axisLineWidth: 0.5,							// Make axis lines a little thicker
            gridLineWidth: 0.2,							// Make grid lines a little thicker
            highlightCircleSize: 0,								// By default, no circles
            //fillAlpha: 0.20,							    // Make fills a little darker than the default
            drawCallback: (graph: Dygraph, initialDraw: boolean) => this.onDraw(graph, initialDraw),		// This function is called back every time the graph is drawn
            customAxis: [],								// We we calculate this programattically
            digitsAfterDecimal: 1,                      // If we get funky numbers on the right axis, one digit, please.
            fGroupCommonUnits: false,                    // Whether or not we want to plot nodes with common units on a common range
            legend: true
        };

        this.dygraphOptions.axisClickCallback = true ? (axis: 0 | 1) => this.changeAxis(axis) : undefined;
        this.dygraphOptions.xAxisLineColor = this.xAxisColor;	// X axis line color
        this.dygraphOptions.yAxisLineColor = this.yAxisColor;	// Y axis line color
        this.dygraphOptions.gridLineColor = this.gridColor;	    // Grid line color
        this.dygraphOptions.minorYLines = this.minorYLines;								// 1 horizontal minor line between major lines
        this.dygraphOptions.minorXLines = this.minorXLines;								// 3 vertical minor line between major lines, SKUN-109 - 4 minor gridlines requested
        this.dygraphOptions.axisLabelColor = this.axisLabelColor;	// Axis label color -- dark green
    }

    changeAxis(axis: 0 | 1) {
        if (axis == 0) {	// Left axis was clicked
            if (++this.leftAxisIndex >= this.maxAxis)	// Increment left axis index and clamp
                this.leftAxisIndex = 0;
            this.updateAxis('left', this.liveValueTags[this.leftAxisIndex].tag, this.liveValueTags[this.leftAxisIndex].attributes ?? {});	// Update left axis options
        } else {			// Right axis was clicked
            this.dygraphOptions.customAxis[this.rightAxisIndex] = false;
            if (++this.rightAxisIndex >= this.maxAxis)	// Increment right axis index and clamp
                this.rightAxisIndex = 0;
            this.dygraphOptions.customAxis[this.rightAxisIndex] = true;
            this.updateAxis('right', this.liveValueTags[this.rightAxisIndex].tag, this.liveValueTags[this.rightAxisIndex].attributes ?? {});	// Update right axis options
        }
        this.dygraphOptions.dateWindow = this.dygraph.xAxisRange();	// Enter the current graph range to so we don't go too far
        this.dygraph.updateOptions(null, this.dygraphOptions);		// Update the graph
        this.updateTimer();
    };

    private downloadData(start: Date, end: Date, interval: DataInterval) {
        let tags: Tag[] = [];
        for (let [device, deviceData] of this.plottedData)
            tags.push(...deviceData.tags.map(tagData => tagData.node));
        this.cruncher.getFormattedData(start, end, tags, interval).then((data => {
            let offset = new Date().getTimezoneOffset() * 60000; // Convert to milliseconds
			let csv = 'Time' + separator;		// Start out with a time column
			let names = data[0];	// Get the names out of the data
			for (let i = 0; i < names.length; ++i) {	// For each name
				csv += names[i] + separator;	// Add it to the first line so each column has a heading
			}
			csv += '\n';			// Add a carriage return after the header
			for (let i = 0; i < data[1].length; ++i) {
				csv += `${new Date(data[1][i] + offset).format('%yyyy/%MM/%dd %HH:%mm:%ss')},`
				for (let j = 3; j < data.length; j += 4) {
					csv += `${data[j][i]},`
				}
				csv += '\n'
			}

			// Create an href element that will allow the user to download the data as a CSV
			var downloadLink = document.createElement('a');	// Chrome allows the link to be clicked without actually adding it to the DOM.
			downloadLink.download = `Chart_Export_${new Date().toLocaleDateString()}.csv`;		// File name to download as
			downloadLink.href = URL.createObjectURL(new Blob([csv], { type: 'text/plain' }));	// Make a blob text file URL for the CSV
			downloadLink.click();							// Simulate clicking on the hyperlink
		}));
    }

    private checkExport(startInput: HTMLInputElement, endInput: HTMLInputElement, intervalSelect: HTMLSelectElement) {
        let endDate = new Date(endInput.value);
        let startDate = new Date(startInput.value);

        if (endDate > new Date())
            endInput.value = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().slice(0,16);
        endDate = new Date(endInput.value);
        if (endDate <= startDate)
            startInput.value = new Date(endDate.getTime() - parseInt(intervalSelect.value) * 1000 - new Date().getTimezoneOffset() * 60000).toISOString().slice(0,16);
        let tagCount = 0;
        for (let [device, data] of this.plottedData) {
            tagCount += data.tags.length;
        }
        let seconds = (new Date(endInput.value).getTime() - new Date(startInput.value).getTime()) / 1000;
        for (let option of intervalSelect.options) {
            option.disabled = false;
        }
        intervalSelect.selectedIndex = 0;
        while (intervalSelect.options[intervalSelect.selectedIndex] && tagCount * seconds / parseInt(intervalSelect.value) > 1e6) {
            intervalSelect.options[intervalSelect.selectedIndex].disabled = true;
            intervalSelect.selectedIndex = ++intervalSelect.selectedIndex;
        }
    }

    determineInterval() {
        let range = this.dygraph.xAxisRange();
        return CalculateInterval((range[1] / 1000 - range[0] / 1000), Math.min(1000, this.graphRow.clientWidth / MaxPixelsPerPoint));
    }

    addTag(tag: Tag, index: number, attributes: { [key: string]: string } = {}) {
        if (tag.flags & NodeFlags.NF_ALIAS)	// If the node is an alias node
            return this.addTag(tag.device.tree.nodes[tag.sourceID]!, index, attributes);	// Try to add the source node (don't pass in the indicator name)
        if (!(tag.flags & NodeFlags.NF_LOG) && !(tag.flags & NodeFlags.NF_DERIVED))	// Have to be historically logged
            return null;
        if ((tag.vtype < VType.VT_BOOL) || (tag.vtype > VType.VT_F64))	// Have to be a numeric node (no strings or bools)
            return null;

        this.dygraphOptions.customAxis.push(index === 1);	// Only the second tag gets true on the custom axis. We only care that axis is plotted if we have more than one node
        if (index === 0 && this.dygraphOptions.axisClickCallback)		// If this is the first tag
            this.updateAxis('left', tag, attributes);											// Scale it on the left axis
        else if (index === 1 && this.dygraphOptions.axisClickCallback)	// If this is the second node
            this.updateAxis('right', tag, attributes);											// Scale it on the right axis

        if (!this.plottedData.has(tag.device)) {
            this.plottedData.set(tag.device, {
                start: Number.POSITIVE_INFINITY,
                end: Number.NEGATIVE_INFINITY,
                tags: [],
                request: null
            });
        };
        this.plottedData.get(tag.device)?.tags.push({ name: tag.device.key + tag.deviceRelativePath, node: tag, start: Number.POSITIVE_INFINITY, end: Number.NEGATIVE_INFINITY, fMax: attributes['show-min-max'] === 'true', _start: undefined, _end: undefined, path: tag.deviceRelativePath });
    };

    private setTagAttributes(tag: Tag, index: number, attributes: { [key: string]: string } = {}) {
        if (attributes['color'] === undefined)
            attributes['color'] = ColorStrings[index];

        let seriesName = tag.device.key + tag.deviceRelativePath;
        this.dygraphOptions[seriesName] = {
            color: attributes['color'],
            digits: tag.digits,
            units: tag.unitsText,
            strokeWidth: attributes['line-width'] ?? 1
        };

        if (tag.flags & NodeFlags.NF_RANGE) {	// If this node has a range
            let attrMax = parseFloat(attributes['max']);
            let attrMin = parseFloat(attributes['min']);
            let max = isNaN(attrMax) ? tag.engMax : attrMax;
            let min = isNaN(attrMin) ? tag.engMin : attrMin;
            if (this.dygraphOptions.fGroupCommonUnits) {

                this.liveValueTags.forEach(lTag => {
                    if (lTag.tag.units === tag.units) {
                        max = Math.max(max, this.dygraphOptions[lTag.tag.device.key + lTag.tag.deviceRelativePath].seriesRange[1]);
                        min = Math.min(min, this.dygraphOptions[lTag.tag.device.key + lTag.tag.deviceRelativePath].seriesRange[0]);
                        this.dygraphOptions[lTag.tag.device.key + lTag.tag.deviceRelativePath].seriesRange = [min, max];	// Plot it on the existing units range
                    }
                    this.dygraphOptions[seriesName].seriesRange = [min, max];
                    //this.dygraphOptions[tag.absolutePath].fillGraph = true
                })
            }
            else
                this.dygraphOptions[seriesName].seriesRange = [min, max];	// Plot it on a custom range
        }
        else if (tag.vtype == VType.VT_BOOL)
            this.dygraphOptions[seriesName].seriesRange = [attributes['min'] ?? 0, attributes['max'] ?? 1];
        if (attributes['show-axis'] !== 'false')
            ++this.maxAxis;
    }

    /**
     * Request historical data of the live data client. Corrects the start and end  times so
     * that they are and even interval apart. Also updates internal records of  what exactly
     * what data has been queried.
     * @param {Number} [deviceID] The id of the device we want to query for data
     * @param {Number} [start] The time stamp of the beginning of the interval (in milliseconds)
     * @param {Number} [end] The time stamp of the end of the interval (in milliseconds)
     * @param {Number} [interval] The time between points (in seconds)
     * @param {Array}  [nodes] An array of SE nodes that we want data for
     */
    private requestHistoricalData(device: Device, start: number, end: number, interval: DataInterval, fForce: boolean = false) {
        let deviceInfo = this.plottedData.get(device);
        if (!deviceInfo)
            throw new Error('Invalid Device requested');
        if (deviceInfo.request !== null)
            return;
        start = Math.floor(start / 1000 / interval) * interval;	// Convert the time stamps to seconds
        end = Math.floor(end / 1000 / interval) * interval;
        //	end		= start + interval * (Math.floor((end - start) / interval));	// Fix up the end to be an even interval away

        // If you hit either of these, let Danno know and let him look at your stack!
        //assert(start > 1000000, "Bad start date requested");
        //assert(interval > 0, "Bad interval requested");

        let fNewStart = start < deviceInfo.start;	// True if we need to request older data
        var fNewEnd = end > deviceInfo.end;			// True if we need to request more recent data
        var fNewInterval = this.interval != interval;	            // True if this data doesn't match our old data

        // Check a few invariants we want to catch. An interval less than 1 is invalid, the end time must be
        // greater than the start time, and the start time must be a reasonable timestamp. If all of those are
        // true and we actually need new data for some reason, procede with the data request.
        if ((interval > 0) && (end > start) && (start > 1000000) && (fNewStart || fNewEnd || fNewInterval || (fForce === true))) {
            if (fNewStart || fNewInterval)	// If this is the oldest data we have queried, remember that
                deviceInfo.start = start;
            if (fNewEnd || fNewInterval)	// If this is the newest data we have queried, remember that
                deviceInfo.end = end;
            if (fNewInterval)
                this.intervalIsChanging = true;

            let starts: number[] = [], ends: number[] = [], graphNodes: TagData[] = [];
            for (let tagData of deviceInfo.tags) {
                if (fNewInterval) {
                    tagData.start = Number.POSITIVE_INFINITY;
                    tagData.end = Number.NEGATIVE_INFINITY;
                }
                if (start < tagData.start || tagData.end < end) {
                    graphNodes.push(tagData);
                    if (fNewInterval || fForce) {
                        starts.push(start);
                        ends.push(end);
                    } else if (fNewStart) {
                        starts.push(start);
                        ends.push(tagData.start === Number.POSITIVE_INFINITY ? end : tagData.start);
                    } else {
                        starts.push(tagData.end === Number.NEGATIVE_INFINITY ? start : tagData.end);	// Nodes end is the first time we don't have
                        ends.push(end);
                    }
                    if (starts.back() >= ends.back())
                        throw new Error("Bad data request");
                }
            }
            if (graphNodes.length * (ends[0] - starts[0]) / interval >= 1000000)
                throw new Error('Too many data points requested');
            if (graphNodes.length == 0) {	// Actually have all the data
                //if (this.booleanFill) {		// If we have something to fix up boolean data
                //    var data = [[]];
                //    this.booleanFill.fixFillData(data, [], start, end, interval);
                //    this.options.dateWindow = this.graph.xAxisRange();	// Make sure the graph is looking at the right window
                //    this.options.visibility = this.graph.visibility();	// Keep our visibility in sync when giving new data
                //    this.fLocked = true;					// We haven't updated start or end time yet. Prevent an endless loop
                //    this.graph.addData(data, this.options);	// Redraw the graph
                //    this.fLocked = false;					// Unlock
                //}
            } else if (graphNodes.length > 0) {
                deviceInfo.request = {
                    start: start,
                    end: end,
                    interval: interval,
                    tags: graphNodes
                }	// Add this request to our list of requested
                this.cruncher.requestHistoricalData(device.id, interval, starts, ends, graphNodes, fNewInterval || fForce).then(([tags, data]) => {
                    this.onGraphDataResponse(interval, data, deviceInfo);
                })
            }
        }
    };

    /**
     * Request historical data of the live data client for all devices. Request the supplied
     * interval for all nodes on each of those devices.
     * @param {Number} [start] The time stamp of the beginning of the interval (in milliseconds)
     * @param {Number} [end] The time stamp of the end of the interval (in milliseconds)
     * @param {Number} [interval] The time between points (in seconds)
     * @param {Number} [line] The line this query is for
     */
    requestDataForAllDevices(start: number, end: number, interval?: DataInterval) {
        let requestInterval = interval ?? CalculateInterval(end / 1000 - start / 1000, Math.min(1000, this.graphRow.clientWidth / MaxPixelsPerPoint));
        if (this.plottedData.size > 0)
            for (let [device, deviceInfo] of this.plottedData)
                this.requestHistoricalData(device, start, end, requestInterval, true);
        else {
            this.fLocked = true;
            this.dygraph.updateOptions([[]], this.dygraphOptions);
            this.fLocked = false;
        }
    };

    convertArray(array, conversion) {
        if (array)
            for (var i = 0; i < array.length; ++i)
                if (array[i] !== null)
                    array[i] *= conversion;
    };

    /**
     * A response to our historical data request has come back. Parse it. If it matches one of
     * our historical requests, extract data and replot the graph.
     * @param {Number} [interval] The data interval requested in seconds
     * @param {Array} [data] The data -- data[0] is node names, followed by [times], [mins], [avgs], [maxes]
     */
    private onGraphDataResponse(interval: DataInterval, data: HistoricalData, deviceInfo: GraphedDeviceInfo) {
        if (deviceInfo.request?.interval !== interval || deviceInfo.request?.tags.length !== data[0].length)
            return;
        for (let i = 0; i < deviceInfo.request.tags.length; ++i) {			// Check all of our nodes
            let tagData = deviceInfo.request.tags[i];
            if (data[1 + i * 4].length == 0) {//@ts-ignore	// If no data came back
                data[1 + i * 4].push(deviceInfo.request.start * 1000, deviceInfo.request.end * 1000);//@ts-ignore	// Fill the area in with nulls
                data[2 + i * 4].push(null, null);//@ts-ignore
                data[3 + i * 4].push(null, null);//@ts-ignore
                data[4 + i * 4].push(null, null);
            }//@ts-ignore
            if (deviceInfo.request.start * 1000 < tagData.start && data[1 + i * 4][0] > tagData.start * 1000) {//@ts-ignore
                data[1 + i * 4].unshift(data[1 + i * 4][0]);//@ts-ignore
                data[2 + i * 4].unshift(null);//@ts-ignore
                data[3 + i * 4].unshift(null);//@ts-ignore
                data[4 + i * 4].unshift(null);
            }
            if (!tagData.fMax)//@ts-ignore
                data[2 + i * 4] = data[4 + i * 4] = null;

            tagData.start = Math.min(tagData.start, data[1 + i * 4].front() / 1000);
            tagData.end = Math.max(tagData.end, data[1 + i * 4].back() / 1000 + interval);
        }

        //if (this.booleanFill)								// If we have something to fix up boolean data
        //    this.booleanFill.fixFillData(data, devNode.request.nodes, devNode.request.start, devNode.request.end, interval);	// Fix up the boolean data

        var fUpdating = this.fUpdating;			// Need to store if graph is updating. Will change on the graph update callback
        this.dygraphOptions.dateWindow = this.dygraph.xAxisRange();	// Make sure the graph is looking at the right window
        this.dygraphOptions.visibility = this.dygraph.visibility();	// Keep our visibility in sync when giving new data
        deviceInfo.request = null;	    // Done with this request
        if (interval != this.interval) {	// This is an entirely new set of data. Clean house
            this.interval = interval;		// Store what resolution the data is at
            this.intervalIsChanging = false;
            let sortedData = data;				// If we don't need to sort, just pass in the data
            if (this.plottedData.size > 1) {	// If we have more than one device, need to sort the data in order
                sortedData = [[]];			// Build an empty array
                for (let i = 0; i < this.liveValueTags.length; ++i) {	// For each node in order
                    sortedData[0].push(this.liveValueTags[i].tag.device.key + this.liveValueTags[i].tag.deviceRelativePath);		// Ad the name
                    let index = data[0].indexOf(this.liveValueTags[i].tag.device.key + this.liveValueTags[i].tag.deviceRelativePath);	// See if we got data for it
                    if (index === -1)							// @ts-ignore No data received
                        sortedData.push([], null, [], null);	// Just reserve space
                    else										// We have data
                        sortedData.push(data[1 + index * 4], data[2 + index * 4], data[3 + index * 4], data[4 + index * 4]);
                }
            }
            this.dygraph.updateOptions(sortedData, this.dygraphOptions);	// This drops all old data. (Slower call)
        } else {
            this.dygraph.addData(data, this.dygraphOptions);			// Just add data to the graph (Faster call)
        }

        this.fUpdating = fUpdating;							// Reset this variable (that was unset in _onDraw)
        if (fUpdating) {									// If we were updating and they didn't block the redraw
            this.dygraph.hover(this.dygraphOptions.dateWindow[1]);	// Manually hover on the right side to show current values
            this.startTimer(this.interval as DataInterval);				// Start the timer again to call us again
        }
    };

    /**
     * Update the date interval that the Dygraph is plotting.
     * @param {Number} [start] The starting timestamp that should be plotted (in milliseconds)
     * @param {Number} [end] The ending timestamp that should be plotted (in milliseconds)
     */
    updateWindow(start: number, end: number) {
        this.dygraph.doZoomXDates_(start, end);	// Replot at a new data window (no reparsing of data)
    };

    /**
     * The graph has just been drawn. Does nothing on the initial draw. On all redraws, this
     * determine if more data is needed or if the graph needs to be redrawn.
     * @param {Dygraph} [graph] The Dygraph object
     * @param {Boolean} [initialDraw] Whether or not this is the first draw
     * @private
     */
    private onDraw(graph: Dygraph, initialDraw: boolean) {
        if (initialDraw || this.fLocked) 	// Only do stuff on redraws with interactive graphs
            return;
        var date = new Date().getTime();		// Current timestamp in milliseconds
        var range = graph.xAxisRange();		// The range the graph is showing currently

        if (date < range[1]) {	// Before we do anything, make sure they haven't scrolled too far
            this.updateWindow(date - (range[1] - range[0]), date);	// No showing the future, but keep the same interval
            return;	// This method was called again through the updateWindow method. Just leave
        }
        let offset = new Date().getTimezoneOffset() * 60000; // Convert to milliseconds

        this.startSelector.value = new Date(Math.round((range[0] - offset) / 1000) * 1000).toISOString().replace('T', ' ').replace('Z', '');
        this.endSelector.value = new Date(Math.round((range[1] - offset) / 1000) * 1000).toISOString().replace('T', ' ').replace('Z', '');

        var newInterval = this.determineInterval();	// See if they have changed logging table
        if (!this.intervalIsChanging && newInterval > 0) {	// Don't request any more data while we have a request for interval change outstanding
            if (newInterval != this.interval) {			// If the resolution of the data has changed
                this.plottedData.forEach((deviceInfo, device) => {
                    deviceInfo.request = null; // Clear all old requests. No longer want this data at a different resolution
                })
                this.requestDataForAllDevices(range[0], range[1], newInterval);
            } else {	// Check if we need to query more data based on current range plotted
                this.plottedData.forEach((deviceInfo, device) => {
                    var msecStart = deviceInfo.start * 1000;
                    var msecEnd = deviceInfo.end * 1000;
                    // Check if they have done a big slide and gotten completely out of the old range we had plotted.
                    // If this is the case, we don't want to query all the data in between. Just request the new data
                    // that we want. We do this by resetting the interval so the dygraph will clear out the old data
                    if ((range[1] < msecStart) || msecEnd < range[0]) {
                        deviceInfo.request = null;		// Remove any requests for this line
                        this.interval = -1;
                        this.requestHistoricalData(device, range[0], range[1], newInterval);
                    } else { // They are panning or zooming a little bit
                        // FIXME: Check the row count and make sure it isn't too big for the whole series in Dygraph
                        // Notice the next two statements are not mutually exclusive. If they scroll out, we should check both sides
                        if (range[0] < msecStart) 	// They have scrolled the graph back and want more data
                            this.requestHistoricalData(device, range[0], msecStart, newInterval);
                        if (msecEnd < range[1] + newInterval * 1000) 	// They want newer data, but not from the future just yet
                            this.requestHistoricalData(device, msecEnd, range[1], newInterval);
                    }
                })
            }
        }


        if (range[1] - range[0] != this.dateRadio.value)
            this.dateRadio.value = -1;	// Set the custom radio button if the interval doesn't match the selected button

        //if (this.onDateChange)
        //    this.onDateChange(range[0], range[1]);

        // Whatever the case, the user just zoomed or panned. They are no longer interested in live
        // data. Stop the query for updating the graph.
        this.stopTimer();	// Kill the update timer if it is running
    };

    private startTimer(interval: DataInterval) {
        if (this.timerID !== null)	// Have a previously started timer
            this.stopTimer();

        // Should have killed any active timers by now (only one should go at a time)
        if (this.timerID !== null)
            throw new Error('Duplicate data request timers created on line chart')

        this.timerID = setTimeout(() => this.updateTimer(), interval * 1000 / 60);
        this.fUpdating = true;				// We are updating

        this.liveRadio.value = 0;
    }

    private stopTimer() {
        if (this.timerID !== null) {			// If we have a timer running...
            clearTimeout(this.timerID);	// Cancel the timer based upon the ID
            this.timerID = null;				// Remove the stored timer ID (the timer is gone)
            this.fUpdating = false;			// No longer updating
            this.liveRadio.value = 1;
        }
    }

    private updateTimer() {

        if (this.timerID === null)	// Only continue if we are still a live graph
            return;

        this.timerID = null;	// This timer has fired and this ID is no good anymore

        // Request data for the new interval. When it comes back, the replot graph method will move time forward
        let range = this.dygraph.xAxisRange();
        let interval = CalculateInterval((range[1] - range[0]) / 1000, Math.min(1000, this.clientWidth / MaxPixelsPerPoint));
        if (interval > 0) {
            let date = new Date().getTime();		// Current timestamp in milliseconds
            if (this.offsetParent !== null)
                this.updateWindow(date - (range[1] - range[0]), date);	// Jump the graph forward (which will request new data)
            let fRequest = false;								// Assume there's no outstanding request until proven otherwise
            for (let [device, deviceInfo] of this.plottedData) {	// Check all devices
                if (deviceInfo.request) {				// If this node has a request out
                    fRequest = true						// Make a note of it
                    break;								// No need to look further
                }
            }

            if (!fRequest)					// If there's no request out
                this.startTimer(interval);	// Start the timer
        }
    };

    private updateAxis(axis: Axis, tag: Tag, attributes: { [key: string]: string }) {
        let rangeMin = attributes['min'] ?? tag.engMin;
        let rangeMax = attributes['max'] ?? tag.engMax;
        let color = attributes['color'] ?? this.yAxisColor;		// Text color
        if (axis === 'left') {
            this.dygraphOptions.valueRange = [rangeMin, rangeMax];
            this.dygraphOptions.yAxisLineColor = color; // Line color
            this.dygraphOptions.ylabel = (window.innerWidth < 768) ? "" : attributes['name'] ?? tag.name;		// Text label
            this.dygraphOptions.ylabelcolor = color;		// Text color
        }
        else {
            this.dygraphOptions.secondAxisRange = [rangeMin, rangeMax];
            this.dygraphOptions.y2AxisLineColor = color; // Line color
            this.dygraphOptions.y2label = (window.innerWidth < 768) ? "" : attributes['name'] ?? tag.name;		// Text label
            this.dygraphOptions.y2labelcolor = color;		// Text color
        }
    }

    onResize() { // Make sure we are sparingly redrawing the graph
        if (this.isAboutToResize)
            return;
        this.isAboutToResize = true;
        setTimeout(() => {
            requestAnimationFrame(() => {
                this.isAboutToResize = false;
                if (this.dygraph)
                    this.dygraph.resize(this.graphRow.clientWidth, this.graphRow.clientHeight);
            });
        }, 200);
    }

    onRefresh() {
        this.cruncher.crateMap.clear();
        this.cruncher.abortAllRequests();
    }

    protected onDisconnect(): void {
        this.cruncher.destroy();
        this.dygraph?.destroy();
    }
}