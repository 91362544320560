import NodeManager from "./nodemanager";
import {Widget} from "./widget";
import {createElement} from './elements';
import createSVGElement from './svgelements';
import {NodeQuality} from './node';
import {Shuttle} from './shuttle';
import Scale from './scale';
import owner from '../owner';
import './cycledtankgraph.css'
import { Role } from "./role";
import { CreateWidget } from "./widgets/lib/createwidget";

export default class CycledTankGraph extends Widget {
    constructor(parent, tankFolder) {
        super();
		this.element		= createElement('div', 'tank-graph__wrapper', parent);	// Create a wrapper node
        this.svgDiv         = createElement('div', 'tank-graph__svg', this.element);
		//this.description    = createElement('div', 'tank-graph__description', this.element);
		this.width			= this.svgDiv.clientWidth;
        this.height			= this.svgDiv.clientHeight;
        this.name			= tankFolder.getDisplayName() + ": ";
        this.registerAsWidget(parent);	// Register with the element like a good little widget

        // Find all the nodes we need from this CycledTank
        this.nodeManager		= new NodeManager(this);
        this.levelNode			= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_TLC_TANK_LEVEL);
        this.maxLevelNode		= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_CYCLE_TANK_MAX_LEVEL);
        this.minLevelNode		= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_CYCLE_TANK_MIN_LEVEL);
        this.minRangeNode		= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_CYCLE_TANK_MIN_RANGE);
        this.minVolumeNode		= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_CYCLE_TANK_MIN_VOLUME);
        this.volumeSoFarNode	= this.nodeManager.addNodeByRole(tankFolder, Role.ROLE_CYCLE_TANK_VOLUME_SO_FAR);
        this.fFillingNode		= this.nodeManager.addNodeByName(tankFolder, Role.ROLE_MAINTENANCE_TASK_STATE);
        this.messageNode		= this.nodeManager.addNodeByName(tankFolder, Role.ROLE_MAINTENANCE_TASK_STATE_DESC);

        this.createSVGElements(this.width, this.height);	// Create the elements for our graph (but nothing will look interesting yet)

        this.drawLines();	// Actually position everything
        this.nodeManager.subscribe();
    };

	createLabeledWidget(parent, label, node) {
		var wrapper = createElement('div', 'cycledTankWidgetWrapper', parent);
		createElement('label', null, wrapper, label);
		let widget = CreateWidget(node);
		widget.classList.add('cycledTankWidget');
		createElement('label', 'cycledTankWidgetUnits', wrapper, node.getUnitsText());
	};

	update(node) {
		if (node.quality != NodeQuality.NQ_GOOD)	// Bad quality on this node...don't recalculate anything
			return;

		// Based on which node update, recalculate the appropriate point for our curve
		switch(node) {	// Switches use the '===' comparator, so should be good for our use case here
			case this.levelNode:
				this.levelY		= this.height * (this.levelNode.engMax - this.levelNode.getValue()) / this.levelNode.engMax;
			break;

			case this.maxLevelNode:
				this.maxY		= this.height * (this.levelNode.engMax - this.maxLevelNode.getValue()) / this.levelNode.engMax;
			break;

			case this.minLevelNode:
				this.minY		= this.height * (this.levelNode.engMax - this.minLevelNode.getValue()) / this.levelNode.engMax;
				this.rangeRect.bottom	= this.overhangTop + this.minY;
				//this.rangeShuttle.updateInnerRect(this.rangeRect);
			break;

			case this.minRangeNode:
				this.maintainY	= this.height * this.minRangeNode.getValue() / this.levelNode.engMax;
			break;

			case this.minVolumeNode:
				this.minVol		= this.minVolumeNode.getValue();
			break;

			case this.fFillingNode:
				this.fFilling	= this.fFillingNode.getValue()==0;
			break;

			case this.volumeSoFarNode:
				this.volSoFar	= this.volumeSoFarNode.getValue();
			break;

			case this.messageNode:
			break;

			default:
				assert(false, "Unknown node called update on CycledTankGraph");
			return;
		}

		this.fRedraw = true;	// If we get here, the node that updated is forcing a redraw of our graph
		this.onChange();
	};

	onChange() {
		if (this.isRedrawing)
			return;
		this.isRedrawing = true;
		queueMicrotask(() => {
			this.isRedrawing = false;
			this.drawLines();
		})
	}

	createSVGElements(width, height) {
		// Create our SVG element
		var svg			= createSVGElement('svg', 'cycledTankSVG', this.element, {width: width, height: height});
		var g			= createSVGElement('g', 'cycledTankGraph', svg);

		// Create our background shapes first so they'll appear behind the lines
		var backing		= createSVGElement('rect', 'cycledTankBacking', g);

		this.svgLevelFill		= createSVGElement('rect', 'cycledTankLevelFill', g);

		this.svgMaintainRange	= createSVGElement('path', 'cycledTankMaintainRange', g);

		// Create our five lines
		this.svgProgressLine	= createSVGElement('line', 'cycledTankProgressLine cycledTankDottedLine', g);	// This is the line that shows where we are in the cycle
		this.svgFillLine		= createSVGElement('line', null, g);						// This is the line that represents filling tank levels
		this.svgDrainLine		= createSVGElement('line', null, g);						// This is the line that represents draining tank levels
		this.svgMaintainBase	= createSVGElement('line', null, g);						// This is the line that represents full at maintaining tank levels
		this.svgMaintainTop		= createSVGElement('line', 'cycledTankDottedLine', g);	// This is the line that represents stop at maintaining tank levels

		// Create a scale for the y-axis (tank level)
		var yAxisScale	= new Scale(svg, this.levelNode.engMin, this.levelNode.engMax, this.levelNode.resolution, 'left', false, 'blackTics');

		// Create all the shuttles we have
		this.shuttles	= [];
		this.shuttles.push(new Shuttle(svg, this.levelNode, 'left', false, ''));
		//this.shuttles.push(new Shuttle(svg, this.maxLevelNode, 'left', this.maxLevelNode.isWriteable, 'Max Level'));
		//this.shuttles.push(new Shuttle(svg, this.minLevelNode, 'left', this.minLevelNode.isWriteable, 'Min Level'));

		// We have to update the inner rectangle for this shuttle, so he's a little different
		//this.rangeShuttle = new Shuttle(svg, this.minRangeNode, 'right', this.minRangeNode.isWriteable, 'Maintain Range');

		var overhang	= {		// The overhang for the shuttles
				left:			0,
				top:			0,
				right:			0,
				bottom:			0,
				strokeWidth:	1};

		yAxisScale.getMetrics(overhang);
		for (var i = 0; i < this.shuttles.length; ++i)	// For each shuttle
			this.shuttles[i].getMetrics(overhang);		// Get how much it will overhang
		//this.rangeShuttle.getMetrics(overhang);

		overhang.left = overhang.right = Math.max(overhang.left, overhang.right);

		var graphRect	= {	// This object describes where there graph of flow versus level lives in the SVG space
				width:	width - overhang.left - overhang.right,
				height:	height - overhang.top - overhang.bottom,
				left:	overhang.left,
				top:	overhang.top,
				right:	width - overhang.right,
				bottom:	height - overhang.bottom};

		this.overhangTop = overhang.top;

		this.width		= graphRect.width;		// Store the width and height of the graph element alone
		this.height		= graphRect.height;

		g.updateFill(0, 0, this.width, this.height);
		backing.updateFill(0, 0, this.width, this.height);

		// Put the necessary amount of whitespace around the graph based on the shuttle overhang
		g.translate(graphRect.left, graphRect.top);

		var svgRect		= {			// A rectangle representing our canvas as a whole
				width:	width,		// Give them the width
				height:	height,		// Give them the height
				left:	0,
				top:	0,
				right:	width,
				bottom:	height};

		yAxisScale.render(graphRect, graphRect);

		// Call render on each shuttle in the inner circle of shuttles
		for (var i = 0; i < this.shuttles.length; ++i)			// For each shuttle
			this.shuttles[i].render(graphRect, null, svgRect);	// Make it instantiate itself

		this.rangeRect	= {		// This object describes where the range node's shuttle should translate in. This box changes
				height:	graphRect.height * this.minRangeNode.engMax / this.levelNode.engMax,
				right:	graphRect.right,
				bottom:	graphRect.bottom};
		//this.rangeShuttle.render(this.rangeRect, null, svgRect);
	};

	drawLines() {
		if (this.minY === undefined)	// If we haven't updated everything yet, bail out
			return;

		var x1 = this.width/3;
		var x2 = 2*x1;
		var y1 = this.minY - this.maintainY;

		// Thanks to the little triangle of overlap on the maintain range shape and the draining line, the maintain region
		// is a little larger (at the expense of the draining region)
		var maintainWidth = this.minRangeNode.getValue()/(this.maxLevelNode.getValue()-this.minLevelNode.getValue())*x1;

		var xProg;	// Figure out our state message and where our progress line should be
		if (this.fFilling)
			xProg = this.levelY < y1 ? (this.levelY - y1) / (this.maxY - y1) * x1 : 0;	// Clamp to zero if the tank level is below the minLevel plus the maintain range
		else if (this.levelNode.getValue() - this.minLevelNode.getValue() + this.minRangeNode.getValue() > 0.2 && this.volSoFar / this.minVol < 0.05)
			xProg = (this.levelY - this.maxY) / (this.minY - this.maxY) * x1 + x1;
		else
			xProg = this.volSoFar / this.minVol * (x1 + maintainWidth) + x2 - maintainWidth;

		//this.nameDiv.textContent = this.name + this.messageNode.getValue();					// Update our state message
		this.svgProgressLine.updateLine(xProg, 0, xProg, this.height);	// Update our progress line
		this.svgFillLine.updateLine(0, y1, x1, this.maxY);				// Update our state machine lines
		this.svgDrainLine.updateLine(x1, this.maxY, x2, this.minY);
		this.svgMaintainBase.updateLine(x2, this.minY, this.width, this.minY);
		this.svgMaintainTop.updateLine(x2-maintainWidth, y1, this.width, y1);

		// Build the path for the the maintain range
		this.svgMaintainRange.setAttribute('d', 'M ' + x2 + ' ' + this.minY + ' h ' + x1 + ' v ' + (-this.maintainY) + ' h ' + (-maintainWidth-x1));

		// Update the blue fill that represent the tank level
		this.svgLevelFill.updateFill(0, this.levelY, this.width, this.height - this.levelY);
	};

	destroy = function() {
		for (var i = 0; i < this.shuttles.length; ++i)
			this.shuttles[i].destroy();	// Destroy all of our shuttles
		//this.rangeShuttle.destroy();

		owner.removeJobCompletedCallback(this.callback);	// Cancel our onJobCallback
		this.nodeManager.destroy();		// Unsubscribe from all the nodes we subscribed to
		this.unregisterAsWidget();		// Unregister with the element like a good little widget
		this.element.destroyWidgets();	// Destroy all the widgets we created
	};
};
