import { Attribute } from "../../lib/attributes";
import { TagSetAttribute } from "../../lib/tag";
import { RegisterWidget, Widget } from "../../lib/widget";
import RadioIcon from '../../../images/icons/radio-button.svg';
import type { Tag, TagDefinition } from "../../lib/tag";
import template from './hoa.html';

@RegisterWidget({tag: 'hoa-switch', template: template, displayName: 'HOA Switch', icon: RadioIcon, editor: true, section: 'Input'})
export class HOA extends Widget {
    protected wrapper: HTMLElement;
    buttonLimit: number = 12;
    isOverLimit: boolean = false;
    _value: string;
    onchange = (e: Event)=>{};
    _disabled: boolean = false;
    protected buttonMap: Map<number, HTMLInputElement> = new Map();
    protected labelMap: Map<string, number> = new Map();
    @Attribute({displayName: 'Equal Button Widths'}) equalButtonWidths: boolean = false;
    @Attribute({displayName: 'All Off Button'}) allOffButton: boolean = true;
    @Attribute({displayName: 'Off Button Index'}) offIndex: number = 1;
    @TagSetAttribute({displayName: 'HOA Tags', requiredCount: 1, supportedTypes: ['boolean'], shouldSubscribe: true, attributes: [
        {
            section: 'Display',
            id: 'display-name',
            type: 'String',
            displayName: 'Display Name',
        }
    ]}) buttonTags: TagDefinition[] = []

    get disabled() {
        return this._disabled
    }

    set disabled(value: boolean) {
        this._disabled = value;
        this.shadowRoot?.querySelectorAll(this.isOverLimit ? 'option' : 'input').forEach(inputElement => inputElement.disabled = this._disabled);
    }

    get value(): string {
        return this._value;
    }

    set value(v: string) {
        this._value = v;
        let button = this.shadowRoot?.getElementById(v) as HTMLInputElement;
        if (button)
            button.checked = true;
    }

    protected connectedCallback(): void {
        this.wrapper = this.shadowRoot!.getElementById('container')!;
        if (this.equalButtonWidths)
            this.style.setProperty('--label-flex', '1');
    }

    enliven() {
        this.wrapper.childNodes.forEach(child => child.remove());
        this.labelMap.clear();
        this.buttonMap.clear();
        for (let tagDef of this.buttonTags) {
            let tag = tagDef.tag;
            let attributes = tagDef.attributes;
            let inputElement = document.createElement('input');
            inputElement.disabled = !tag.isWriteable
            inputElement.type = 'radio';
            inputElement.name = 'radioName';
            inputElement.id = tag.absolutePath
            this.wrapper.append(inputElement);
            let labelElement = document.createElement('label');	// trim leading and trailing whitespace
            labelElement.htmlFor = tag.absolutePath;
            labelElement.textContent = attributes ? attributes['display-name'] ?? tag.getDisplayName() : tag.getDisplayName();
            labelElement.onclick = () => {
                inputElement.checked = true;
                this._value = tag.absolutePath;
                tag.setPendingValue(true, this);
                this.onchange(new Event('change'));;
            }
            this.wrapper.append(labelElement);
        }
        if (this.allOffButton) {
            let inputElement = document.createElement('input');
            inputElement.disabled = false;
            inputElement.type = 'radio';
            inputElement.name = 'radioName';
            inputElement.id = '_allOFF'
            let labelElement = document.createElement('label');	// trim leading and trailing whitespace
            labelElement.htmlFor = '_allOFF';
            labelElement.textContent = 'OFF';
            labelElement.onclick = () => {
                this.buttonTags.forEach(tagDef => {
                    if (tagDef.tag.getValue())
                        tagDef.tag.setPendingValue(false, this)
                });
                inputElement.checked = true;
                this._value = '_allOFF';
                this.onchange(new Event('change'));;
            }
            this.wrapper.insertChildAt(labelElement, this.offIndex * 2)
            this.wrapper.insertBefore(inputElement, labelElement);
        }
    }

    update(tag: Tag): void {
        if (tag.getValue())
            this.value = tag.absolutePath;
        else {
            let button = this.shadowRoot?.getElementById(tag.absolutePath) as HTMLInputElement;
            if (button)
                button.checked = false;
        }
        if (!Array.from(this.shadowRoot?.querySelectorAll('input')!).some(input => input.checked))
            this.value = '_allOff';
    }
}