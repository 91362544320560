import View from "./view";
import owner from '../../owner';
import { createElement } from "../elements";
import './periodscheduleview.css';
import { Tag } from "../widgets/lib/tag";
import { Role } from "../role";
import NodeManager from "../nodemanager";
import { PeriodColors } from "../widgets/dpo/controls/utils";
import ArrowIcon from '../images/icons/arrow_right_filled.svg';

const startMonths = [
    {value: 0, text: 'January'},
    {value: 1, text: 'February'},
    {value: 2, text: 'March'},
    {value: 3, text: 'April'},
    {value: 4, text: 'May'},
    {value: 5, text: 'June'},
    {value: 6, text: 'July'},
    {value: 7, text: 'August'},
    {value: 8, text: 'September'},
    {value: 9, text: 'October'},
    {value: 10, text:'November'},
    {value: 11, text:'December'},
]

const endMonths = [
    { value: 1, text: 'January' },
    { value: 2, text: 'February' },
    { value: 3, text: 'March' },
    { value: 4, text: 'April' },
    { value: 5, text: 'May' },
    { value: 6, text: 'June' },
    { value: 7, text: 'July' },
    { value: 8, text: 'August' },
    { value: 9, text: 'September' },
    { value: 10, text: 'October' },
    { value: 11, text: 'November' },
    { value: 12, text: 'December' },
];

const startWeekDays = [
    { value: 0, text: 'Monday' },
    { value: 1, text: 'Tuesday' },
    { value: 2, text: 'Wednesday' },
    { value: 3, text: 'Thursday' },
    { value: 4, text: 'Friday' },
    { value: 5, text: 'Saturday' },
    { value: 6, text: 'Sunday' },
];

const endWeekDays = [
    { value: 1, text: 'Monday' },
    { value: 2, text: 'Tuesday' },
    { value: 3, text: 'Wednesday' },
    { value: 4, text: 'Thursday' },
    { value: 5, text: 'Friday' },
    { value: 6, text: 'Saturday' },
    { value: 7, text: 'Sunday' },
];
export default class PeriodScheduleView extends View {
    dpoFolder: Tag;
    constructor(dpoFolder: Tag) {
        super();
        this.dpoFolder = dpoFolder;
    }

    initialize(parent: HTMLElement) {
        super.initialize(parent);
        this.wrapper = createElement('div', 'period__wrapper', this.parent);
        let periods = this.dpoFolder.findByRole(Role.ROLE_TLC_PERIOD);
        for (let i=0;i<periods.length;++i) {
            let period = periods[i];
            let periodElement     = createElement('div', 'period__element', this.wrapper);
            createElement('div', 'period__element__color', periodElement).style.backgroundColor = PeriodColors[i];
            let periodWrapper = createElement('div', 'period__element__wrapper', periodElement);
            let titleWrapper = createElement('div', 'period__title__wrapper', periodWrapper);
            createElement('tag-name', 'period__element__name', titleWrapper, '', {nameTag: {tag: period}});
            let icon = createElement('img', 'period__arrow', titleWrapper, '', {src: ArrowIcon});
            let accordion = createElement('se-accordion', '', periodWrapper);
            titleWrapper.onclick = () => {
                accordion.toggle();
                icon.style.rotate = accordion.isOpen ? '90deg' : ''
            }
            let settingColumn = createElement('div', 'period__settings', accordion);
            if (i !== 0) {
                let calendar = createElement('div', 'period__calendar', settingColumn);
                let startMonth = period.findChild('StartMonth');
                createElement('tag-select', '', calendar, '', {valueTag: {tag: startMonth!}, valueMap: startMonths});

                let startDay = period.findChild('StartMonthDay');
                createElement('input-setpoint', 'period__date', calendar, '',  {setpoint: {tag: startDay!}}).style.minWidth = '';

                createElement('div', 'period__separator', calendar, 'through')
                let endMonth = period.findChild('EndMonth');
                createElement('tag-select', '', calendar, '', {valueTag: {tag: endMonth!}, valueMap: endMonths});
                let endDay = period.findChild('EndMonthDay');
                createElement('input-setpoint', 'period__date', calendar, '',  {setpoint: {tag: endDay!}}).style.minWidth = '';

                let startDayOfWeek = period.findChild('StartDay');
                let endDayOfWeek = period.findChild('EndDay');
                let weekDayWrapper = createElement('div', 'period__calendar', settingColumn);

                createElement('tag-select', '', weekDayWrapper, '', {valueTag: {tag: startDayOfWeek!}, valueMap: startWeekDays});
                createElement('div', 'period__separator', weekDayWrapper, 'through')
                createElement('tag-select', '', weekDayWrapper, '', {valueTag: {tag: endDayOfWeek!}, valueMap: endWeekDays});

                let timeWrapper = createElement('div', 'period__calendar', settingColumn);
                let startHour = period.findChild('StartHour');
                createElement('tag-select', '', timeWrapper, '', {valueTag: {tag: startHour!}, valueMap: [
                    { value: 0, text: '00:00' },
                    { value: 1, text: '01:00' },
                    { value: 2, text: '02:00' },
                    { value: 3, text: '03:00' },
                    { value: 4, text: '04:00' },
                    { value: 5, text: '05:00' },
                    { value: 6, text: '06:00' },
                    { value: 7, text: '07:00' },
                    { value: 8, text: '08:00' },
                    { value: 9, text: '09:00' },
                    { value: 10, text: '10:00' },
                    { value: 11, text: '11:00' },
                    { value: 12, text: '12:00' },
                    { value: 13, text: '13:00' },
                    { value: 14, text: '14:00' },
                    { value: 15, text: '15:00' },
                    { value: 16, text: '16:00' },
                    { value: 17, text: '17:00' },
                    { value: 18, text: '18:00' },
                    { value: 19, text: '19:00' },
                    { value: 20, text: '20:00' },
                    { value: 21, text: '21:00' },
                    { value: 22, text: '22:00' },
                    { value: 23, text: '23:00' },
                ]});
                let endHour = period.findChild('EndHour');
                createElement('div', 'period__separator', timeWrapper, 'to')
                createElement('tag-select', '', timeWrapper, '', {valueTag: {tag: endHour!}, valueMap: [
                    { value: 0, text: '00:00' },
                    { value: 1, text: '01:00' },
                    { value: 2, text: '02:00' },
                    { value: 3, text: '03:00' },
                    { value: 4, text: '04:00' },
                    { value: 5, text: '05:00' },
                    { value: 6, text: '06:00' },
                    { value: 7, text: '07:00' },
                    { value: 8, text: '08:00' },
                    { value: 9, text: '09:00' },
                    { value: 10, text: '10:00' },
                    { value: 11, text: '11:00' },
                    { value: 12, text: '12:00' },
                    { value: 13, text: '13:00' },
                    { value: 14, text: '14:00' },
                    { value: 15, text: '15:00' },
                    { value: 16, text: '16:00' },
                    { value: 17, text: '17:00' },
                    { value: 18, text: '18:00' },
                    { value: 19, text: '19:00' },
                    { value: 20, text: '20:00' },
                    { value: 21, text: '21:00' },
                    { value: 22, text: '22:00' },
                    { value: 23, text: '23:00' },
                    { value: 24, text: '24:00'}
                ]});
            }
            let energyCost = period.findChildByRole(Role.ROLE_ENERGY_COST);
            let wrapper = createElement('div', 'period__calendar', settingColumn)
            createElement('input-setpoint', '', wrapper, '', {setpoint: {tag: energyCost!}});
            createElement('tag-units', '', wrapper, '', {unitsTag: {tag: energyCost!}});
        }
        this.fInitialized   = true;
        return this;
    }

};