import { Attribute } from "../lib/attributes";
import { Widget } from "../lib/widget";
import { SelectInput } from "../../views/attributeeditorview";
import { TagAttribute, type TagDefinition } from "../lib/tag";
import { AlarmTypes, type Alarm } from "../../alarm";
import { type Device } from '../../device';

export abstract class AlarmWidget extends Widget {
    protected fTrend: boolean = false;
    @Attribute({
        displayName: 'Period',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new SelectInput(name, parent, property, getValue, ['Hour','Day','Week','Year'], ['3600','86400','604800','31540000'], onSettingChangedCallback)
    }) period: number = 86400;

    @Attribute({displayName: 'Periods'}) periods: number = 1;

    @TagAttribute({
        displayName: 'Device',
        supportedTypes: ['root']
    }) rootTag: TagDefinition;

    protected enliven(): void {
        let device = this.rootTag.tag.device;
        let end         = new Date();
        let endTime    = end.getTime() * 1000;
        let startTime  = endTime - (1000 * 1000 * this.period * this.periods * (this.fTrend ? 2 : 1));
        this.requestHistorical(device, startTime, endTime).then(({alarms, trendAlarms}) => this.onHistoricalAlarmsFinished(alarms, trendAlarms, startTime, endTime));
    }

    requestHistorical(device: Device, start: number, end: number): Promise<{alarms: Alarm[],trendAlarms: Alarm[]}> {
        let alarms: Alarm[] = [];
        let trendAlarms: Alarm[] = [];
        let resolver: (alarms: {alarms: Alarm[],trendAlarms: Alarm[]}) => void;
        let promise = new Promise<{alarms: Alarm[],trendAlarms: Alarm[]}>((resolve, reject) => {
            resolver = resolve;
        });

        let request = (endTime) => {
            device.alarms.requestHistorical(endTime, true, 200).then(historicalEvents => {
                let currentStart = this.fTrend ? end - (end - start) / 2 : start;
                let newAlarms = historicalEvents.ordered.filter(event=>{return (((event.type == AlarmTypes.ACTIVATED) || (event.type == AlarmTypes.DEACTIVATED)) && event.time! >= currentStart)});
                if (this.fTrend) {
                    let newTrendAlarms = historicalEvents.ordered.filter(event=>{return (((event.type == AlarmTypes.ACTIVATED) || (event.type == AlarmTypes.DEACTIVATED)) && event.time! >= start && event.time! <= currentStart)});
                    trendAlarms.push(...newTrendAlarms)
                }
                alarms.push(...newAlarms);
                if (historicalEvents.ordered.length == 0 || historicalEvents.ordered[historicalEvents.ordered.length - 1].time! <= start)
                    resolver({alarms: alarms, trendAlarms: trendAlarms});
                else
                    request(historicalEvents.ordered[historicalEvents.ordered.length - 1].time);
            }) // Get the most recent 100 events that we care about
        }
        request(end);
        return promise;
    }

    abstract onHistoricalAlarmsFinished(alarms: Alarm[], trendAlarms: Alarm[], start: number, end: number);

}