import { type ConfiguredAlarm } from "../../alarm";
import { type Device } from "../../device";
import type { NodeOperation, NodeSubscriber } from "../../node";
import { AttributeMetadata, AttributeType, Metadata, Serializable } from "./attributes";
import { TagUnit } from "./tagunits";
import { Widget } from "./widget";

export enum VType {
	VT_UNKNOWN = 0,	// unknown type
	VT_BOOL = 1,	// one-byte value (0 or 1)
	VT_U8 = 2,	// unsigned integers
	VT_U16 = 3,
	VT_U32 = 4,
	VT_U64 = 5,
	VT_S8 = 6,	// signed integers
	VT_S16 = 7,
	VT_S32 = 8,
	VT_S64 = 9,
	VT_F32 = 10,	// floating point
	VT_F64 = 11,
	VT_STRING = 12,	// data points to an std::string or array of std::strings
	VT_BLOCK = 13,	// block of data. Not yet implemented.
}

export const typeMap: Map<VType, string> = new Map([
	[VType.VT_UNKNOWN, 'Unknown'],
	[VType.VT_BOOL, 'Boolean'],
	[VType.VT_U8, 'U8 Int'],
	[VType.VT_U16, 'U16 Int'],
	[VType.VT_U32, 'U32 Int'],
	[VType.VT_U64, 'U64 Int'],
	[VType.VT_S8, 'S8 Int'],
	[VType.VT_S16, 'S16 Int'],
	[VType.VT_S32, 'S32 Int'],
	[VType.VT_S64, 'S64 Int'],
	[VType.VT_F32, '32 Float'],
	[VType.VT_F64, '64 Float'],
	[VType.VT_STRING, 'String'],
	[VType.VT_BLOCK, 'Data'],
]);

export enum TagQuality {
	TQ_GOOD = 0,				// just here so you can set quality = NQ_GOOD instead of 0.
	TQ_UNINIT = 0x00000008,		// data is not yet initialized (data is garbage)
	TQ_OFFLINE = 0x00000010,		// data is off line (data is stale)
	TQ_UNREGISTERED = 0x00000020,		// Not registered or UnregisterLiveData() was called
	TQ_MACHINE_NOT_FOUND = 0x00000040,		// could not find host machine
	TQ_PROCESS_NOT_FOUND = 0x00000080,		// could not find process on host machine
	TQ_DATA_NOT_FOUND = 0x00000100,		// could not find data on host process
	TQ_CLOSED = 0x00000200,		// unconnected
	TQ_PROTOCOL_ERROR = 0x00000400,		// error in protocol code
	TQ_PERMISSION = 0x00000800,		// user does not have permission (may be due to LDF_READ LDF_WRITE flags)
	TQ_DISCONNECTED = 0x00001000,		// socket became disconnected -- will retry periodically
	TQ_OUT_OF_RANGE = 0x00002000,		// value is out of range, and therefore invalid
}

export const QualityMap: Map<TagQuality, string> = new Map([
	[TagQuality.TQ_GOOD, 'Good'],
	[TagQuality.TQ_UNINIT, 'Uninitialized'],
	[TagQuality.TQ_OFFLINE, 'Tag Source Offline'],
	[TagQuality.TQ_UNREGISTERED, 'Unregistered'],
	[TagQuality.TQ_MACHINE_NOT_FOUND, 'Machine not Found'],
	[TagQuality.TQ_PROCESS_NOT_FOUND, 'Process not Found'],
	[TagQuality.TQ_DATA_NOT_FOUND, 'Data not Found'],
	[TagQuality.TQ_CLOSED, 'Closed'],
	[TagQuality.TQ_PROTOCOL_ERROR, 'Protocol Error'],
	[TagQuality.TQ_PERMISSION, 'Invalid Permission'],
	[TagQuality.TQ_DISCONNECTED, 'Disconnected'],
	[TagQuality.TQ_OUT_OF_RANGE, 'Out of Range']
]);

export interface Tag {
	name: string;
	parent: Tag;
	device: Device;
	absolutePath: string;
	children: Tag[];
	isLogged: Readonly<boolean>;
	isWriteable: Readonly<boolean>;
	description: string;
	roles: Set<string>;
	deviceRelativePath: string;
	engMin: number;
	engMax: number;
	rawMin: number;
	rawMax: number;
	resolution: number;
	digits: number;
	cacheUnits: TagUnit;
	operations: NodeOperation[];
	units: TagUnit;
	unitsText: string;
	vtype: VType;
	quality: TagQuality;
	flags: number;
	valueText: string;
	configured: ConfiguredAlarm[];
	sourceID: number;
	convertValue: (desiredUnits: TagUnit, widget?: NodeSubscriber) => any;
	getValue: (widget?: NodeSubscriber) => any;
	getDisplayName: (showUnits?: boolean, fPretty?: boolean) => string;
	setPendingValue: (value: any, widget: NodeSubscriber) => void;
	getFormattedTextFromValue: (value: any, showUnits: boolean, newUnits?: TagUnit, newDigits?: number, newFormat?: string) => string;
	convertFromCacheToDisplay: (value: number) => number;
	findChild: (name: string) => Tag | null;
	findChildByRole: (role: string) => Tag | null;
	findByRole: (role: string) => Tag[];
	getFormattedText: (fUnits: boolean, newUnits?: TagUnit, newDigits?: number, newFormat?: string, widget?: NodeSubscriber) => string;
};

export type TagTypeOptions = 'numeric' | 'boolean' | 'string' | 'folder' | 'root';
export type TagPropertyOptions = 'logged' | 'writeable' | 'scaled' | 'subscribeable' | 'alarmConfigured';
export type TagUnitOptions = 'angle' | 'apparent-power' | 'area' | 'bpa' | 'concentration' | 'conductivity' | 'cost-per-volume' | 'currency' | 'current' | 'energy' | 'energy-cost' | 'flow-rate' | 'flux' | 'fraction' | 'frequency' | 'length' | 'mass' | 'mass-flow-rate' | 'power' | 'pressure' | 'reactive-power' | 'specific-capacity' | 'specific-energy' | 'specific-flux' | 'temperature' | 'time' | 'time-span' | 'turbidity' | 'voltage' | 'volume' | 'weight';

/**
 * Definition of all the properties that enable a tag to be used for a specific rendering purpose by a widget
 *
 * @export
 * @interface TagProperties
 */
export interface TagMetadata extends Metadata {
	displayName: string;
	isOptional?: boolean; // Whether or not this tag is required prior to rendering
	supportedPaths?: string[] // If provided, only support tags with a specific device-relative path
	shouldSubscribe?: boolean; // Whether or not to subscribe for live data updates. If true, will not render until all subscribed tags have good quality
	supportedTypes?: TagTypeOptions[]; // Supported Tag Types
	requiredProperties?: TagPropertyOptions[]; // Required Tag Properties
	supportedRoles?: string[]; // Supported Tag Roles
	supportedUnits?: TagUnitOptions[]; // Supported Units
	attributes?: TagAttributeMetadata[], // Optional tag-specific attributes
	requiresHistorical?: boolean // If true, only allow logged tags
}

export interface ExtendedTagMetadata extends TagMetadata {
	type: 'tag'
	privateKey: symbol;
	publicKey: string;
}
export interface ExtendedTagSetMetadata extends TagMetadata {
	type: 'set'
	privateKey: symbol;
	publicKey: string;
}

export interface TagSetMetadata extends TagMetadata {
	requiredCount?: number // Minimum number of tags required to render
}

export interface SerializedTag {
	location: string,
	attributes?: any;
}

export interface TagAttributeMetadata extends AttributeMetadata {
	id: string;
	type: AttributeType;
	default?: (tag: Tag, index?: number) => any;
}

export const tagAttributeMetadataSymbol = Symbol();
export const tagSetAttributeMetadataSymbol = Symbol();

export interface TagDefinition {
	tag: Tag;
	attributes?: { [key: string]: string };
	fromSerialized?: boolean;
};

export const TagAttribute = (properties: TagMetadata) => (target: Widget, key: string) => {
	const privateKey = Symbol();
	return Serializable({ ...properties, privateKey: privateKey, type: 'tag', publicKey: key }, tagAttributeMetadataSymbol, target, key, privateKey);
}

export const TagSetAttribute = (properties: TagSetMetadata) => (target: Widget, key: string) => {
	const privateKey = Symbol();
	return Serializable({ ...properties, privateKey: privateKey, type: 'set', publicKey: key }, tagSetAttributeMetadataSymbol, target, key, privateKey);
}

export const findGlobalTagsFromPath = (absolutePath: string): Promise<Set<Tag>> => {
	let pathComponents = absolutePath.split(':');
	if (pathComponents.length == 1) { // No device key, have to search everywhere
		return new Promise<Set<Tag>>((resolve, reject) => {
			let promises: Promise<Set<Tag>>[] = [];
			import('../../user').then(user => {
				user.default.devices.array.forEach(device => {
					if (device.connected || device.cachedTree)
						promises.push(findGlobalTags(device, pathComponents[0]));
				});
				Promise.all(promises).then(tagSets => {
					resolve(tagSets.reduce((acc, set) => new Set([...acc, ...set]), new Set<Tag>())); // Merge all the sets into one
				})
			});
		});
	}
	else if (pathComponents.length === 2) {
		return new Promise<Set<Tag>>((resolve, reject) => {
			import('../../user').then(user => {
				let device = user.default.devices.getByKey(pathComponents[0]);
				if (!device)
					reject(`Invalid path: '${pathComponents[0]}' is not a valid device key`);
				else
					findGlobalTags(device, pathComponents[1]).then(tags => resolve(tags));
			})
		})
	}
	return new Promise<Set<Tag>>((resolve, reject) => { reject('Invalid absolute path') });
}

export const findGlobalTags = (device: Device, deviceRelativePath: string): Promise<Set<Tag>> => {
	let nodePromise = new Promise<Set<Tag>>((resolve, reject) => {
		let treeCompleteCallback = () => resolve(searchTags(device.tree.nodes[0]!, deviceRelativePath));
		if (device.isTreeComplete())
			treeCompleteCallback();
		else if (device.connected || device.cachedTree)
			device.requestNodeTree(() => treeCompleteCallback());
		else
			reject(`Device '${device.siteName}' is disconnected and does not have a cached tag tree`);
	});
	return nodePromise;
}

// Types for search conditions and steps
interface Condition {
	type: 'name' | 'role' | 'id';
	value: string;
}

interface Step {
	// If true, then search all descendants (instead of only immediate children)
	recursive: boolean;
	// One or more conditions that must be met in this step.
	conditions: Condition[];
}

/**
 * Searches a global tag tree for tags matching the given path.
*
* The path string is in the format:
*
*    TaggerKey:Query
*
* For example:
*
*    "PUMPSTATION1:Folder1.SubFolder*Flow"
*    "PUMPSTATION1:#Pump&#VFD"
*    "PUMPSTATION1:*@luid12"
*
* @param tagTree An array of Tag objects representing root nodes (each with a Tagger key).
* @param path The search path string.
* @returns A Set of Tag objects that match the search.
*/
function searchTags(root: Tag, path: string): Set<Tag> {
	if (path.trim() === "") { // Empty device relative path. They must want the root tag.
		return new Set([root]);
	}

	// Parse the query into a series of steps.
	const steps = parseQuery(path);

	// Start with the Tagger root as the initial set.
	let currentNodes = new Set<Tag>([root]);

	// For each step, search among the children (or descendants if recursive).
	for (const step of steps) {
		const nextNodes = new Set<Tag>();
		for (const node of currentNodes) {
			const matches = step.recursive ? searchDescendants(node, step.conditions) : searchImmediate(node, step.conditions);
			for (const m of matches) {
				nextNodes.add(m);
			}
		}
		currentNodes = nextNodes;
		// If no matches are found at any step, we can stop early.
		if (currentNodes.size === 0) break;
	}

	return currentNodes;
}

/**
 * Searches immediate children of a tag for ones that satisfy all conditions.
*/
function searchImmediate(node: Tag, conditions: Condition[]): Tag[] {
	return node.children.filter(child => matchesConditions(child, conditions));
}

/**
 * Recursively searches all descendants of a tag for ones that satisfy all conditions.
*/
function searchDescendants(node: Tag, conditions: Condition[]): Tag[] {
	const results: Tag[] = [];

	function recurse(current: Tag) {
		for (const child of current.children) {
			if (matchesConditions(child, conditions)) {
				results.push(child);
			}
			recurse(child);
		}
	}
	recurse(node);
	return results;
}

/**
 * Returns true if the tag satisfies every condition.
*/
function matchesConditions(tag: Tag, conditions: Condition[]): boolean {
	return conditions.every(cond => {
		if (cond.type === 'name') {
			return tag.name === cond.value;
		} else if (cond.type === 'role') {
			return tag.roles.has(cond.value);
		} else if (cond.type === 'id') {
			//return tag.luid === cond.value; TODO: When we have tag luids, uncomment this guy
			return false;
		}
		return false;
	});
}

/**
 * Parses the query (the portion after the colon) into a series of steps.
*
* This parser handles:
*   - Hierarchical steps separated by '.'.
*   - An embedded '*' (not at the start) is taken as a break that starts a recursive step.
*   - If a new condition (starting with '#' or '@') is encountered
*     immediately after a previous condition (with no '&'), then a new step is started.
*/
function parseQuery(query: string): Step[] {
	const steps: Step[] = [];
	// We first split on the explicit dot.
	const segments = query.split('.');

	for (const seg of segments) {
		// A segment may contain multiple steps (if, for example, "#Role1#Role2" was used).
		const segSteps = parseSegmentIntoSteps(seg);
		steps.push(...segSteps);
	}
	return steps;
}

/**
 * Parses a segment (which may be something like "Folder1#TotalFlow" or "SubFolder*Flow")
* into one or more steps.
*/
function parseSegmentIntoSteps(segment: string): Step[] {
	const steps: Step[] = [];
	let currentStep = "";
	// This flag is local to the step being built.
	let recursiveFlag = false;
	let i = 0;

	while (i < segment.length) {
		const char = segment[i];
		// If the segment starts with '*' then mark this step as recursive.
		if (i === 0 && char === '*') {
			recursiveFlag = true;
			i++;
			continue;
		}
		// If we encounter a '*' in the middle (and currentStep is non-empty),
		// finish the current step and then start a new one flagged as recursive.
		if (char === '*' && currentStep.length > 0) {
			steps.push(parseStepFromSegment(currentStep, false));
			currentStep = "";
			recursiveFlag = true;
			i++; // skip the '*' character
			continue;
		}
		// If we encounter a new condition indicator ('#' or '@')
		// immediately following a nonempty currentStep (and not after an '&'),
		// we treat this as a boundary between steps.
		if ((char === '#' || char === '@') && currentStep.length > 0) {
			// Check the previous character: if it was '&', then it belongs to the same step.
			if (segment[i - 1] !== '&') {
				steps.push(parseStepFromSegment(currentStep, false));
				currentStep = "";
				// Do not change recursiveFlag here—it applies only if a '*' was seen.
			}
		}
		currentStep += char;
		i++;
	}
	if (currentStep.length > 0) {
		steps.push(parseStepFromSegment(currentStep, recursiveFlag));
	}
	return steps;
}

/**
 * Parses a single step from a segment.
*
* This function splits the segment by the combination operator '&' so that
* a step like "pressure&#DischargePressure" produces two conditions.
*
* @param segment A string for a single step (without any dots)
* @param recursive Whether this step should be performed recursively.
*/
function parseStepFromSegment(segment: string, recursive: boolean): Step {
	const parts = segment.split('&');
	const conditions: Condition[] = [];

	for (const part of parts) {
		let condType: 'name' | 'role' | 'id' = 'name';
		let value = part;
		if (part.startsWith('#')) {
			condType = 'role';
			value = part.substring(1);
		} else if (part.startsWith('@')) {
			condType = 'id';
			value = part.substring(1);
		}
		conditions.push({ type: condType, value });
	}
	return { recursive, conditions };
}
