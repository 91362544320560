/// <reference types="@types/google.maps" />
import Page from "./page";
import owner, { Routes } from '../../owner';
import { createElement } from "../elements";
import assert from "../debug";
import './mappage.css';
import createSVGElement from "../svgelements";
import { DeviceCardFactory } from "../components/devicecard";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { getHash } from "../router/router";
import LiveDataClient from "../livedataclient";
import User from "../user";

/// <reference path="./google.maps.d.ts" />

// Define google namespace for TypeScript
declare global {
  interface Window {
    google: typeof google;
  }
}

// Define a local interface for device in this context
interface Device {
  key: string;
  latitude: number;
  longitude: number;
  connected: boolean;
  requestNodeTree: (callback: () => void) => void;
  // Add other properties that might be needed
  [key: string]: any;
}

interface MapStyle {
  featureType: string;
  elementType: string;
  stylers: Array<Record<string, string | number | boolean>>;
}

export default class MapPage extends Page {
    private ldc: typeof LiveDataClient;
    private props: Record<string, any>;
    private wrapper: HTMLElement;
    private markerMap: Map<string, google.maps.Marker>;
    private deviceBounds: google.maps.LatLngBounds;
    private map: google.maps.Map;
    private infoContainer: HTMLElement;

    constructor(parent: HTMLElement, props: Record<string, any>) {
        super(parent, props);
        this.ldc = LiveDataClient;
        this.props = {...props}; // create a copy of the properties
        this.wrapper = createElement('div', 'map__wrapper', this.parent) as HTMLElement;
        owner.navBar.setTitle('Map');
        this.markerMap = new Map();
        if (typeof google !== 'object' || typeof google.maps !== 'object')
            new Promise<void>((resolve, reject) => { // cool ES6 way to asynchronously load the google maps api. This way we don't load it until we need it.
                createElement('script', '', document.head, '', {'src':'https://maps.googleapis.com/maps/api/js?key=AIzaSyBg8Iq5LMMMIieQPS8Zoz3POihG5nor2us&v=3&amp;&callback=Function.prototype','onload':()=>resolve(),'onerror':reject});
            }).catch((e) => assert(false, e)).then(() => this.createMap(this.wrapper));
        else { // We already have google maps loaded
            this.createMap(this.wrapper);
        }
    }

    createMap(container: HTMLElement): void {
        this.deviceBounds = new google.maps.LatLngBounds();
        this.map = new google.maps.Map(container, {
            gestureHandling: 'greedy', // allows user to zoom with scroll wheel
            center: {lat: -34.397, lng: 150.644},
            zoom: 8,
            disableDefaultUI: true,
            minZoom: 5,
            maxZoom: 20,
            mapTypeId: 'terrain',
            styles: this.getStyles(),
            mapTypeControl: true,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            }
        });
        //this.cluster = new MarkerClusterer(this.map, Array.from(Object.values(this.markerMap)));

        this.infoContainer = createElement('div', 'map__info-container hide', this.wrapper) as HTMLElement;
        let companyKey = owner.menuPanel.getCompanyKey();

        for (let i=0; i<User.devices.sorted.length; i++) {
            let device = User.devices.sorted[i] as Device;
            if (!device.key.includes(companyKey) || (device.latitude == 0 && device.longitude == 0))
                continue;
            let latLng = new google.maps.LatLng(device.latitude, device.longitude);
            this.deviceBounds.extend(latLng);
            if (device.connected)
                device.requestNodeTree(() => this.updateMarker(device));
            else {
                this.updateMarker(device)
            }
        }
        this.map.fitBounds(this.deviceBounds /*, {bottom: 25, right: this.wrapper.clientWidth / 2, left: 25, top: 25}*/);
    }

    updateMarker(device: Device): void {
        let marker = this.markerMap.get(device.key);
        if (marker)
            marker.setMap(null);
        let latLng = new google.maps.LatLng(device.latitude, device.longitude);
        let newMarker = new google.maps.Marker({
            position: latLng,
            map: this.map,
            icon: {                    // Create our icon options once
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: device.connected ? owner.colors.hex('--color-green-8') : owner.colors.hex('--color-red-4'),
                fillOpacity: 1,
                strokeColor: 'transparent',
                strokeOpacity: 1,
                strokeWeight: 1,
                cursor: 'pointer',
                scale: 10
            } as google.maps.Symbol,
        });

        newMarker.addListener("click", () => {
            location.hash = getHash(Routes.Device, {'key':device.key,'tab':'default','index':'0'});
        });
        let container = createElement('div', 'map__info-container') as HTMLElement;
        let factory = new DeviceCardFactory(container, device as any)

        newMarker.addListener('mouseover', (e: google.maps.MapMouseEvent) => {
            let infoWindow = new google.maps.InfoWindow();
            infoWindow.setContent(container);
            infoWindow.open(this.map, newMarker);
            factory.initialize()
            newMarker.addListener('mouseout', () => {
                factory.uninitialize();
                infoWindow.close();
            });
        });
        this.markerMap.set(device.key, newMarker);
    }

    refreshMap(): void {
        (this.wrapper as any).destroyWidgets();
        (this.wrapper as any).removeChildren();
        this.createMap(this.wrapper);
    }

    onCompanySelected(): void {
        this.refreshMap();
    }

    onThemeChanged(): void {
        this.refreshMap();
    }

    createIcon(device: Device): string {
        let group = createSVGElement('svg', 'map__svg', undefined, {width:'24',height:'24'});
        let circle = createSVGElement('circle', 'iso__circle', group, {cx: 10, cy: 10, r: 2 }); // Create an outer circle for our gear
        let path = createSVGElement('path', '', group, {d:"M150 0 L75 200 L225 200 Z"})
        return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(group.toString())}`;
    }

    getStyles(): Array<MapStyle> {
        // Return theme-based map styles
        return [
            {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": owner.colors.getTheme()['--color-onSurface']
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    },
                    {
                        "color": owner.colors.getTheme()['--color-onSurfaceVariant']
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": owner.colors.getTheme()['--color-surfaceContainer']
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": owner.colors.getTheme()['--color-surfaceContainerLow']
                    },
                    {
                        "weight": 1.2
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": owner.colors.getTheme()['--color-surfaceContainerLow']
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": owner.colors.getTheme()['--color-surfaceContainerLow']
                    }
                ]
            },

            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": owner.colors.getTheme()['--color-surface']
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": owner.colors.getTheme()['--color-surfaceContainer']
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": owner.colors.getTheme()['--color-surface']
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": owner.colors.getTheme()['--color-surface']
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": owner.colors.getTheme()['--color-surface']
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": owner.colors.getTheme()['--color-blue-1']
                    }
                ]
            }
        ];
    }
}