import View from "./view";
import { createElement } from "../elements";
import './constraintview.css';
import ColoredHeader from "../coloredheader";
import TankView from "./tankview";
import Helper from "../helper";
import owner from '../../owner';
import ArrowDownIcon from '../images/icons/arrow_down.svg'
import SettingsIcon from '../images/icons/settings.svg'
import NodeManager from '../nodemanager';
import { Role } from "../role";
import ViewModal from "../viewmodal";
import PeriodScheduleView from "./periodscheduleview";
import { convert } from "../widgets/lib/tagunits";
import { CreateWidget } from "../widgets/lib/createwidget";

export default class ConstraintView extends View {
    constructor(device, options, helper) {
        super();
        this.device 	= device;
        this.setpoints  = new Map();
        this.constraintNodeManager = new NodeManager(this);
        this.helper     = helper
        this.copy(options);
    }
    initialize(parent) {
        super.initialize(parent);
        this.rootNode  	= this.device.tree.nodes[0];
        this.pumpSystem	= this.rootNode.findChildByRole(Role.ROLE_PUMP_BANK);	// Save a pointer to the pump system node
        this.dpoFolder	= this.rootNode.findChildByRole(Role.ROLE_DPO_FOLDER);  // Save a pointer to the dpo node
        // Find constraint folder
        this.wrapper        = createElement('div', 'constraint-view__wrapper', this.parent);

        //let helpIcon        = createElement('img', 'constraint-view__help', this.wrapper, undefined, {'src':HelpIcon})
        let gaugeRow        = createElement('div', 'constraint-view__gauge-row', this.wrapper);
        let periodsWrapper   = createElement('div', 'constraint-view__periods-wrapper', this.wrapper);
        this.containerRow    = createElement('div', 'constraint-view__container-row', this.wrapper);
        let containerWrapper= createElement('div', 'constraint-view__container-row__wrapper', this.containerRow);
        this.demandContainer = createElement('div', 'constraint-view__container constraint-view__demand', containerWrapper);
        this.sandbox         = createElement('div', 'constraint-view__sandbox', containerWrapper);
        this.flowMin        = createElement('div', 'constraint-view__sandbox__flow-min', this.sandbox);
        this.flowMax        = createElement('div', 'constraint-view__sandbox__flow-max', this.sandbox);
        this.flowLine       = createElement('div', 'constraint-view__sandbox__flow-line', this.sandbox);
        this.flowDeadbandDiv = createElement('div', 'constraint-view__sandbox__flow-deadband', this.sandbox);
        this.limitContainer  = createElement('div', 'constraint-view__container constraint-view__limit', containerWrapper);
        let demandTitle     = createElement('div', 'constraint-view__container-title', this.demandContainer, 'Demands');
        let limitTitle      = createElement('div', 'constraint-view__container-title', this.limitContainer, 'Limits');
        let bulletRow       = createElement('div', 'constraint-view__bullet-row', this.wrapper);
        let demandBullet    = createElement('div', 'constraint-view__bullet', bulletRow);
        let limitBullet     = createElement('div', 'constraint-view__bullet', bulletRow);
        let globalWrapper   = createElement('div', 'constraint-view__global-row', this.wrapper)
        let globalListTop   = createElement('div', 'constraint-view__global__top',    globalWrapper);
        let globalIcon      = createElement('img', 'constraint-view__collapse-list__icon',   globalListTop, null, {'src':SettingsIcon});
        let globalListTitle = createElement('div', 'menu-panel__collapse-list__title',  globalListTop, 'Advanced Settings');
        let globalDropIcon  = createElement('img', 'constraint-view__collapse-list__icon',   globalListTop, null, {'src':ArrowDownIcon});
        this.globalRow       = createElement('div', 'constraint-view__collapse-list', globalWrapper);

        globalListTop.onclick   = () => this.toggleCollapse(globalDropIcon, this.globalRow);
        this.globalRow.style.height    = '0px';
        globalDropIcon.style.transform = 'rotate(-90deg)';
        this.globalRow.setAttribute('is-collapsed','true');

        // subscribe to all the nodes we need
        this.pumps			= this.pumpSystem.findByRole(Role.ROLE_PUMP);	    // Find all the pump folders
		this.nodeManager	= new NodeManager(this);
		this.lastCurveCheck = this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_SYSTEM_MODEL_TIMESTAMP);
		this.targetSpeeds	= this.nodeManager.addAllNodesWithRole(this.dpoFolder, Role.ROLE_TLC_TARGET_SPEED);
		this.idealSpeeds	= this.nodeManager.addAllNodesWithRole(this.dpoFolder, Role.ROLE_TLC_IDEAL_SPEED);
		this.flowNode		= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_TOTAL_FLOW);
		this.secNode		= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_SEC);
		this.pressureNode	= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_DISCHARGE_PRESSURE);
		this.energyMetric	= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_STATION_EFFICIENCY);
		this.qMin			= this.nodeManager.addNodeByRole(this.dpoFolder, Role.ROLE_TLC_QMIN);
		this.qMax			= this.nodeManager.addNodeByRole(this.dpoFolder, Role.ROLE_TLC_QMAX);

        let periods = this.dpoFolder.findByRole(Role.ROLE_TLC_PERIOD);
        if (periods.length > 0) {
            let ticker = createElement('control-ticker', '', periodsWrapper, '', {dpoFolder: {tag: this.dpoFolder}});
            this.periodSelect = createElement('select', '', periodsWrapper);
            this.periodSelect.onchange = () => {
                this.hasChangedSelect = true;
                for (let period of periods) {
                    if (period.getDisplayName() == this.periodSelect.value) {
                        this.createConstraints(period)
                        break;
                    }
                }
            }
            for (let period of periods) {
                this.nodeManager.addNodeByName(period, 'fActive');
                let thisPeriodName = period.getDisplayName();
                let option = createElement('option', '', this.periodSelect, thisPeriodName, {'value': thisPeriodName});
            }
            this.createConstraints(periods[0]);
        }
        else
            this.createConstraints(this.dpoFolder);


        // set up the digital gauges to display flow, sec, suction, and discharge pressure
		let suction     = this.pumpSystem.findChildByRole(Role.ROLE_SUCTION_PRESSURE);
        let discharge   = this.pumpSystem.findChildByRole(Role.ROLE_DISCHARGE_PRESSURE);
        let costPerVolume = this.pumpSystem.findChildByRole("CostPerVolume");

        createElement('tag-badge', 'constraint-view__gauge', gaugeRow, '', {statusTag: {tag: this.flowNode}, showUnits: true, backgroundColor: 'var(--color-flow-1)'});
        createElement('tag-badge', 'constraint-view__gauge', gaugeRow, '', {statusTag: {tag: this.secNode}, showUnits: true, backgroundColor: 'var(--color-se-1)'});

        if (suction)
            createElement('tag-badge', 'constraint-view__gauge', gaugeRow, '', {statusTag: {tag: suction}, showUnits: true, backgroundColor: 'black'});
        if (discharge)
            createElement('tag-badge', 'constraint-view__gauge', gaugeRow, '', {statusTag: {tag: discharge}, showUnits: true, backgroundColor: '#800080'});
		if (costPerVolume)
            createElement('tag-badge', 'constraint-view__gauge', gaugeRow, '', {statusTag: {tag: costPerVolume}, showUnits: true, backgroundColor: '#85bb65'});

        this.nodeManager.subscribe();
        owner.navBar.registerHelp('Help with Controls Tab', ()=>this.buildHelper())
        this.fInitialized = true;
        return this;
    }

    createConstraints(periodFolder) {
        this.constraintNodeManager.destroy();
        this.setpoints.clear();
        this.demandContainer.destroyWidgets(true);
        this.demandContainer.removeChildren();
        this.limitContainer.destroyWidgets(true);
        this.limitContainer.removeChildren();
        this.globalRow.destroyWidgets(true);
        this.globalRow.removeChildren();
        let constraintFolder = periodFolder.findChildByRole(Role.ROLE_TLC_CONSTRAINT_FOLDER)
		this.flowNodes = [], this.dischargeNodes = [], this.suctionNodes = [];	// Fresh arrays to hold the nodes for each pv
		for (var i = 0; constraintFolder && i < constraintFolder.children.length; ++i)
			this.checkRole(constraintFolder.children[i], Role.ROLE_TLC_CONSTRAINT_PV, false);		// Sort the nodes by process variable

		var folder	    = periodFolder.findChildByRole(Role.ROLE_TLC_POWER_METER_FOLDER);	// Find the folder, if it exists
		this.powerNodes	= folder ? folder.children : [];		// Store the nodes

		this.flowDeadband	= this.dpoFolder.findChild("FlowDeadband");
        this.secDeadband	= this.dpoFolder.findChild("SEC_Deadband");
        this.maxPumps       = this.dpoFolder.findChildByRole(Role.ROLE_MAX_PUMPS);

        this.constraints = [...this.powerNodes, ...this.flowNodes, ...this.dischargeNodes, ...this.suctionNodes];
        let targetFolder = periodFolder.findChildByRole(Role.ROLE_TLC_TARGET_TANK_FOLDER);
		let sourceFolder = periodFolder.findChildByRole(Role.ROLE_TLC_SOURCE_TANK_FOLDER);
		if (targetFolder) {
			this.constraints.push(targetFolder)
		}
		if (sourceFolder) {
			this.constraints.push(sourceFolder)
        }

        let options = {
            width: 96,
            fShowUnits: false,
            fPending: true,
            fCheckbox: true
        }

        // set up the remaining constraints
        for (let i=0;i<this.constraints.length;i++) {       // for each tag in our constraints folder
            let tag        = this.constraints[i];  // convenience reference to the constraint tag
            let fDemand    = tag.roles.has(Role.ROLE_TLC_TARGET_TANK_FOLDER) || tag.roles.has(Role.ROLE_TLC_CONSTRAINT_MIN);
            let constraint = this.createConstraint(fDemand, this.demandContainer, this.limitContainer)
            if (tag.roles.has(Role.ROLE_TLC_TARGET_TANK_FOLDER) || tag.roles.has(Role.ROLE_TLC_SOURCE_TANK_FOLDER)) {
                let tanks           = fDemand? this.dpoFolder.findChild('TargetTankMinFlow') : this.dpoFolder.findChild('SourceTankMaxFlow');
                let enabledNodes    = [];
                let stateNodes      = [];
                let trueClassNames  = [];
                let falseClassNames = [];

                if (tanks) { // if our constraint is a tank max or min flow
                    let sp          = tanks.findChildByRole(Role.ROLE_TLC_CONSTRAINT_SP);
                    let fb          = tanks.findChildByRole(Role.ROLE_TLC_CONSTRAINT_FEEDBACK)
                    let enabled     = tanks.findChildByRole(Role.ROLE_TLC_CONSTRAINT_ENABLED);
                    let active      = tanks.findChild('Active');
                    if (sp)
                        this.createSetpoint(constraint.data, sp, fb, constraint.arrowShaft)

                    if (active) {
                        stateNodes.push(active);
                        trueClassNames.push('constraint-view__active');
                        falseClassNames.push('constraint-view__inactive');
                    }

                    constraint.name = createElement('div', 'constraint-view__constraint__name', constraint.data, this.getCleanName(tag.getDisplayName()))
                    if (enabled) {
                        createElement('se-tag-checkbox', '', constraint.container, '', {toggleTag: {tag: enabled}});
                        enabledNodes.push(enabled);
                    }
                }


                for (let j=0; j<tag.children.length; j++) {
                    let tank                = tag.children[j];
                    let tankContainer       = createElement('div', 'constraint-view__tank-container', constraint.container)
                    new TankView (tank, tank.findChildByRole(Role.ROLE_CYCLE_TANK_VOLUME_SO_FAR)? true : false, fDemand, false, {fPending: true, fShuttles: true}).initialize(tankContainer);
                    let tagListTop          = createElement('div', 'constraint-view__collapse-list__top',    tankContainer);
                    createElement('img', 'constraint-view__collapse-list__icon',   tagListTop, null, {'src':SettingsIcon});
                    createElement('div', 'menu-panel__collapse-list__title',  tagListTop, 'Settings');
                    let tagListDropIcon     = createElement('img', 'constraint-view__collapse-list__icon',   tagListTop, null, {'src':ArrowDownIcon});
                    let tagList             = createElement('div', 'constraint-view__collapse-list',    tankContainer, null);

                    tagListTop.onclick      = () => this.toggleCollapse(tagListDropIcon, tagList)
                    for (let k=0; k<tank.children.length; k++) {
                        let tankRow = createElement('div', 'constraint-view__tank-row', tagList)
                        let tag = tank.children[k];
                        let options = {
                            width: 96,
                            fShowUnits: true,
                            fPushButton: (tag.name == 'StartFail' || tag.name == 'StopFail'),
                            fPending: true,
                            fCheckbox: true
                        }
                        if (tank.children[k].name === 'Enabled') {
                            enabledNodes.push(tank.children[k])
                            createElement('se-tag-checkbox', '', createElement('div', 'constraint-view__constraint__tank-enabled', tankContainer), '', {toggleTag: {tag: tag}});
                        }
                        else {
                            createElement('div', undefined, tankRow).appendChild(CreateWidget(tag));
                            createElement('div', 'constraint-view__constraint__name', tankRow, this.getCleanName(tag.getDisplayName()))
                        }
                    }
                    tagList.style.height    = '0px';
                    tagListDropIcon.style.transform = 'rotate(-90deg)';
                    tagList.setAttribute('is-collapsed','true')
                }

                stateNodes.push(...enabledNodes);
                trueClassNames.push(...Array.from({ length: enabledNodes.length }).fill('constraint-view__enabled'));
                falseClassNames.push(...Array.from({ length: enabledNodes.length }).fill('constraint-view__disabled'))
                new ColoredHeader(constraint, {nodes: stateNodes, trueClass: trueClassNames, falseClass: falseClassNames, fPending: true});

            }

            else if (tag.roles.has(Role.ROLE_TLC_POWER_METER)) {
                let sp          = tag.findChildByRole(Role.ROLE_TLC_CONSTRAINT_SP);
                let fb          = tag.findChildByRole(Role.ROLE_TLC_CONSTRAINT_FEEDBACK)
                let enabled     = tag.findChildByRole(Role.ROLE_TLC_CONSTRAINT_ENABLED);
                let dpo         = tag.tree.nodes[0].findChildByRole(Role.ROLE_DPO_FOLDER);
                let active      = dpo.findChild('PowerLimited');
                let pumps       = tag.findChild('Pumps');
                let stateNodes  = [];
                let trueClasses = [];
                let falseClasses = [];
                if (pumps) {
                    let pumpNames = '';
                    for (let i=0;i<this.pumps.length;++i) {
                        let mask = 1 << i
                        if (pumps.getValue() & mask)
                            pumpNames += `${pumpNames === '' ? '' : ', '}${this.pumps[i].getDisplayName()}`
                    }
                    createElement('p', 'constraint-view__constraint__pumps', constraint.data, pumpNames);
                }
                if (sp) {
                    this.createSetpoint(constraint.data, sp, fb, constraint.arrowShaft)
                }
                if (active) {
                    stateNodes.push(active);
                    trueClasses.push('constraint-view__active');
                    falseClasses.push('constraint-view__inactive');
                }
                if (enabled) {
                    createElement('se-tag-checkbox', '', createElement('div', undefined, constraint.container), '', {toggleTag: {tag: enabled}});
                    //createWidget(createElement('div', undefined, constraint.container), enabled, options);
                    stateNodes.push(enabled);
                    trueClasses.push('constraint-view__enabled');
                    falseClasses.push('constraint-view__disabled');
                }
                if (stateNodes.length > 0) {
                    new ColoredHeader(constraint, {nodes: stateNodes, trueClass: trueClasses, falseClass: falseClasses, fPending: true});
                }
                constraint.name = createElement('div', 'constraint-view__constraint__name', constraint.data, this.getCleanName(tag.getDisplayName()));

            }

            else if (tag.roles.has(Role.ROLE_TLC_CONSTRAINT_RANGE)) {
                let minConstraint = this.createConstraint(true, this.demandContainer, this.limitContainer);
                let cons = [constraint, minConstraint]
                for (let j = 0; j<cons.length;j++) {
                    let con     = cons[j];
                    con.classList.add('constraint-view__range')
                    let sp      = tag.findChildByRole(Role.ROLE_TLC_CONSTRAINT_SP);
                    let fb      = tag.findChildByRole(Role.ROLE_TLC_CONSTRAINT_FEEDBACK)
                    let enabled = tag.findChildByRole(Role.ROLE_TLC_CONSTRAINT_ENABLED);
                    let active  = tag.findChild('Active');
                    let stateNodes  = [];
                    let trueClasses = [];
                    let falseClasses = [];
                    if (sp)
                        this.createSetpoint(con.data, sp, fb, con.arrowShaft, con==minConstraint)
                    if (active){
                        stateNodes.push(active);
                        trueClasses.push('constraint-view__active');
                        falseClasses.push('constraint-view__inactive');
                    }
                    con.name = createElement('div', 'constraint-view__constraint__name',con.data, this.getCleanName(tag.getDisplayName()))
                    if (enabled) {
                        createElement('se-tag-checkbox', '', createElement('div', undefined, con.container), '', {toggleTag: {tag: enabled}});
                        stateNodes.push(enabled);
                        trueClasses.push('constraint-view__enabled');
                        falseClasses.push('constraint-view__disabled');
                    }
                    if (stateNodes.length > 0) {
                        new ColoredHeader(con, {nodes: stateNodes, trueClass: trueClasses, falseClass: falseClasses, fPending: true});
                    }
                }
            }

            else {
                let sp      = tag.findChildByRole(Role.ROLE_TLC_CONSTRAINT_SP);
                let fb      = tag.findChildByRole(Role.ROLE_TLC_CONSTRAINT_FEEDBACK)
                let enabled = tag.findChildByRole(Role.ROLE_TLC_CONSTRAINT_ENABLED);
                let active  = tag.findChild('Active');
                let stateNodes  = [];
                let trueClasses = [];
                let falseClasses = [];
                if (sp) {
                    this.createSetpoint(constraint.data, sp, fb, constraint.arrowShaft)
                }
                if (active) {
                    stateNodes.push(active);
                    trueClasses.push('constraint-view__active');
                    falseClasses.push('constraint-view__inactive');
                }
                if (enabled) {
                    createElement('se-tag-checkbox', '', createElement('div', undefined, constraint.container), '', {toggleTag: {tag: enabled}});
                    //createWidget(createElement('div', undefined, constraint.container), enabled, options);
                    stateNodes.push(enabled);
                    trueClasses.push('constraint-view__enabled');
                    falseClasses.push('constraint-view__disabled');
                }
                if (stateNodes.length > 0) {
                    new ColoredHeader(constraint, {nodes: stateNodes, trueClass: trueClasses, falseClass: falseClasses, fPending: true});
                }
                constraint.name = createElement('div', 'constraint-view__constraint__name', constraint.data, this.getCleanName(tag.getDisplayName()));
            }

        }

        this.constraintNodeManager.subscribe();

        // Set up our global constraints (constraints that don't really fall into the demands or limits ctaegories)
        if (this.flowDeadband) {
            this.createGlobal(this.globalRow, this.flowDeadband)
        }
        if (this.secDeadband) {
            this.createGlobal(this.globalRow, this.secDeadband)
        }
        if (this.maxPumps)
            this.createGlobal(this.globalRow, this.maxPumps)

        if (periodFolder.roles.has(Role.ROLE_TLC_PERIOD)) {
            let scheduleButton = createElement('button', 'se-button constraint-view__global-row__global-constraint', this.globalRow)
            scheduleButton.onclick = () => {
                new ViewModal(new PeriodScheduleView(this.dpoFolder), {
                    title: 'Time of Use Schedule',
                    maxHeight: '600px',
                    maxWidth: '600px',
                    titleBackgroundColor: 'var(--color-primary)',
                    titleTextColor: 'var(--color-inverseOnSurface)',
                });
            };
            createElement('div', 'constraint-view__global-row__title', scheduleButton, 'Edit Time of Use Schedule');
        }
    }

    updateArrowFlows() {
        this.setpoints.forEach((sp, label) => {
            if (!sp.parent.roles.has(Role.ROLE_TLC_POWER_METER))
                label.innerHTML = this.getFlowFromSetpoint(sp) + ' ' + this.qMin.getUnitsText();
        })
    }

    update(tag) {
        switch (tag) {
            case this.qMin:
            case this.qMax:
            case this.flowNode:
                this.flowMin.innerHTML = this.qMin.getFormattedText(false) + ' ' + this.qMin.getUnitsText();
                this.flowMax.innerHTML = this.qMax.getValue() + ' ' + this.qMax.getUnitsText();
                this.flowLine.style.left = (this.flowNode.getValue() - this.qMin.getValue()) / (this.qMax.getValue() - this.qMin.getValue()) * 100 + '%';
                this.flowDeadbandDiv.style.width = this.flowDeadband.getValue(true) / (this.qMax.getValue() - this.qMin.getValue()) * 100 + '%';
            break;
            default:
                if (tag.name == 'fActive' && tag.getValue(false)) {
                    let currentName = tag.parent.getDisplayName();
                    if (!this.hasChangedSelect) {
                        this.periodSelect.value = currentName;
                        this.hasChangedSelect = true;
                    }
                    this.periodSelect.querySelectorAll('option').forEach(option => {
                        option.textContent = `${option.value} ${option.value == currentName ? ' (Active)' : ''}`
                    });
                    //if (!this.hasChangedSelect) { //TODO: Maybe in the future we want to check if they have changed any inputs and if not, show the active constraint?
                    //    this.periodSelect.value = tag.parent.getDisplayName();
                    //    this.createConstraints(tag.parent);
                    //}
                }
        }
        this.updateArrowFlows()
    }

    createConstraint(fDemand, demandContainer, limitContainer) {
        let constraint          = createElement('div', 'constraint-view__constraint', fDemand? demandContainer : limitContainer);
        let arrowWrapper        = createElement('div', 'constraint-view__container__arrow-wrapper', constraint)
        constraint.arrow        = createElement('div', 'constraint-view__container__arrow-container', arrowWrapper);
        constraint.arrowShaft   = createElement('div', 'constraint-view__container__arrow-container__arrow', constraint.arrow);
        let arrowHead           = createElement('div', 'constraint-view__container__arrow-container__arrow-head', constraint.arrow)
        constraint.container    = createElement('div', 'constraint-view__constraint__container', constraint);
        constraint.data         = createElement('div', 'constraint-view__constraint__container__data', constraint.container);
        return constraint
    }

    createGlobal(element, tag) {
        let constraint      = createElement('div', 'constraint-view__global-row__global-constraint', element);
        constraint.name     = createElement('div', 'constraint-view__global-row__global-constraint__name', constraint);
        createElement('div', 'constraint-view__global-row__title', constraint.name, this.getCleanName(tag.getDisplayName()))
        let desc            = createElement('div', 'constraint-view__global-row__description', constraint.name)
        if (tag.name == 'FlowDeadband')
            desc.innerHTML = 'The required overlap with a lower flow regime before the DPO will transition to the lower regime'
        else if (tag.name == 'SEC_Deadband')
            desc.innerHTML = 'Specific energy reduction required before shifting to a more efficient regime';
        else if (tag.name == 'MaxPumps')
            desc.innerHTML = 'The maximum number of simultaneously running pumps'

        this.createSetpoint(constraint, tag);
    }

    createSetpoint(element, sp, fb, arrow, fArrowOnly) {
        let options = {
            width: 72,
            fShowUnits: false,
            fPending: true,
            fCheckbox: true
        }

        let spWrapper = createElement('div', 'constraint-view__setpoint__wrapper', element)

        if (sp.parent.roles.has(Role.ROLE_TLC_CONSTRAINT_MAX))
            if (sp.parent.name == 'MinSuctionPressure')
                createElement('div', 'constraint-view__condition', spWrapper, 'At Least'); // label the setpoint units
            else
                createElement('div', 'constraint-view__condition', spWrapper, 'At Most'); // label the setpoint units
        else if (sp.parent.roles.has(Role.ROLE_TLC_CONSTRAINT_MIN)) {
            if (sp.parent.name == 'MaxSuctionPressure')
                createElement('div', 'constraint-view__condition', spWrapper, 'At Most'); // label the setpoint units
            else
                createElement('div', 'constraint-view__condition', spWrapper, 'At Least'); // label the setpoint units

        }
        let widget = CreateWidget(sp);
        widget.classList.add('constraint-view__setpoint');
        widget.showUnits = true;
        spWrapper.appendChild(widget);

        //createWidget(createElement('div', undefined, spWrapper), sp, options)
        //createElement('div', 'tag-viewer__item__spacer', spWrapper, sp.getUnitsText()); // label the setpoint units

        if (sp.parent.roles.has(Role.ROLE_TLC_CONSTRAINT_RANGE)){
            let db = sp.parent.findChildByRole(Role.ROLE_TLC_CONSTRAINT_DB)
            if (db) {
                createElement('div', 'constraint-view__plus-minus', spWrapper, '\u00B1')
                createElement('div', undefined, spWrapper).appendChild(CreateWidget(db));
                createElement('div', 'tag-viewer__item__spacer', spWrapper, db.getUnitsText()); // label the setpoint units
            }
        }

        if (arrow) {
            let arrowLabel = createElement('div', 'tag-viewer__arrow-label', arrow)         // create the label div for the equivalent flow
            this.setpoints.set(arrowLabel, fb? fb : sp)                                     // set our map so we can reference the arrow label when our setpoint changes
        }
        this.constraintNodeManager.addNode(sp)        // subscribe to setpoint node
        if (fb)
            this.constraintNodeManager.addNode(fb)    // subscribe to feedback node
        if (fArrowOnly)
            element.parentNode.style.opacity = '0';
    }

    getCleanName(name) {
        let newName = name.replace(/(Min )+|(Max )+|(Setpoint)/g,'')
        return newName.replace(/(SEC)/g,'Specific Energy')
    }

    getFlowFromSetpoint(setpoint, feedback) {
        let node = setpoint || feedback;
        let conversion = convert(1, node.units, this.qMin.units);  // get our conversion to station flow
        return (node.getValue(true) * conversion).toFixed(2);       // return our converted setpoint value
    }

    toggleCollapse(dropIcon,element) { // toggles whether or not the element is collapsed. See collapse and expand methods in elements.js
        if (!element) return
        var isCollapsed = element.getAttribute('is-collapsed') === 'true';

        if(isCollapsed) {
          element.expand();
          if (dropIcon) dropIcon.style.transform = 'rotate(0deg)';
        } else {
          element.collapse();
          if (dropIcon) dropIcon.style.transform = 'rotate(-90deg)';
        }
    }

    createArrows(parent) {
        let container = createElement('div', 'constraint-view__arrow-container', parent);
        for (let i=0; i<4; i++) {
            let arrow = createElement('div', 'constraint-view__arrow-container__arrow', container);
            createElement('div', 'constraint-view__arrow-container__arrow__slider', arrow);
        }
    }

    checkRole(node, pvRole) {
		let pv = node.findChildByRole(pvRole);			// The process variable alias node
		let roles = node.tree.nodes[pv.sourceID].roles;	// Find the role of the source node, which will tell us what this constrains
		if (roles.has(Role.ROLE_TOTAL_FLOW))				// Flow constraint
			this.flowNodes.push(node);
		else if (roles.has(Role.ROLE_DISCHARGE_PRESSURE))	// Discharge pressure constraint
			this.dischargeNodes.push(node);
		else
			this.suctionNodes.push(node);				// Suction pressure constraint
    }

    onViewHidden() {
        owner.navBar.unregisterHelper('Help with Controls Tab');
    }

    onViewShown() {
        super.onViewShown();
        owner.navBar.registerHelp('Help with Controls Tab', ()=>this.buildHelper())
    }

    buildHelper() {
        new Helper('Helper', [
            {title:'Controls Tab',  body:'The Controls Tab lets you have complete control over the operation of your pump station. Click the \'Next\' button below to learn more about controlling your station.', element:this.wrapper},
            {title:'Sandbox',       body:'The sandbox contains all of the currently available operating points for the system. The sanbox is bounded by a limit on the right and a demand on the left. By adjusting the limits and demands, you can adjust the size of the sandbox.', element:this.containerRow},
            {title:'Limits',        body:'This column shows all of the limits on the system. Decreasing the demand setpoints will decrease the maximum flow allowed by the system and move the right edge of the sandbox to the left.', element:this.limitContainer},
            {title:'Active Limit',  body:'This is the currently active limit. This limit is setting the maximum allowable flow in the system.', selector: '.constraint-view__limit>.constraint-view__constraint.constraint-view__active>.constraint-view__constraint__container'},
            {title:'Demands',       body:'This column shows all of the demands on the system. Increasing the demand setpoints will raise the minimum flow required by the system and move the left edge of the sandbox to the right.', element:this.demandContainer},
            {title:'Active Demand', body:'This is the currently active demand. This demand is setting the minimum allowable flow in the system.', selector: '.constraint-view__demand>.constraint-view__constraint.constraint-view__active>.constraint-view__constraint__container'}
        ]).initialize();
    }

    destroy() {
        owner.navBar.unregisterHelper(this);
        if (this.nodeManager)
            this.nodeManager.destroy();
        if (this.constraintNodeManager)
            this.constraintNodeManager.destroy();
        this.parent.destroyWidgets(true);	// Don't need to destroy our graph specifically
        this.parent.removeChildren();		// Delete any DOM elements left over
    }
}