import FrameMaker from "./framemaker";
import LiveData from "./livedata";
import LiveDataClient from "./livedataclient";
import User from "./user";

export function uploadFile(file: File, path: string): Promise<[string, string]> {
    return new Promise<[string, string]>((resolve, reject) => {
        getUploadURL(file, path).then(([url, uuid]) => {
            let xhr = new XMLHttpRequest();
            xhr.open("PUT", url);
            xhr.setRequestHeader("Content-Type", file.type);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    let fm = new FrameMaker();
                    fm.buildFrame(LiveData.WVC_CONFIRM_UPLOAD);
                    fm.push_string(uuid);
                    fm.push_u8(xhr.status == 200 ? 1 : 0);
                    LiveDataClient.sendFrame(fm);
                    if (xhr.status != 200)
                        reject('There was an error while trying to upload a file. Please try again.')
                    else
                        resolve([url, uuid]);
                }
            };
            xhr.send(file);
        }).catch((reason: string) => reject(reason));
    });
}

export function getUploadURL(file: File, path: string): Promise<[string, string]> {
    if (file.size > 10000000) 	// They aren't allowed to upload files greater than 10MB (this is enforced by the signed URLs returned)
        return new Promise((resolve, reject) => reject(`Your file [${file.size / 1000000}MB] is too large. The max upload size is 10MB.`))
    else {
        let fm = new FrameMaker();
        fm.buildFrame(LiveData.WVC_GET_UPLOAD_URL);
        fm.push_string(file.name);
        fm.push_string(file.type);
        fm.push_string(path);
        fm.push_string(User.companyKey);
        fm.push_u64(file.size);
        return new Promise<[string, string]>((resolve, reject) => {
            LiveDataClient.sendRequest(fm).then(fp => {
                let success = fp.pop_u8();
                if (success) {
                    let url = fp.pop_string();
                    let uuid = fp.pop_string();
                    resolve([url, uuid]);
                }
                else
                    reject('There was an error while trying to upload a file. Please try again.');
            });
        });
    }
}
