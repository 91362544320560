import DesktopIcon          from "../images/icons/desktop.svg";
import PhoneIcon            from "../images/icons/smartphone.svg";
import TabletIcon           from "../images/icons/tablet.svg";
import { v4 as uuidv4 } from 'uuid';


import View from "./view";
import { createElement } from "../elements";
import './dashboardview.css';
import EditorPage, { Dashboard } from '../pages/widgeteditorpage';
import { Gizmo, GizmoConverterMap, GizmoType, Recipe } from '../dashboard/gizmos/gizmo';
import { Routes } from "../../owner";
import LiveData from "../livedata";
import FrameParser from "../frameparser";
import Dialog from "../dialog";
import HTMLSanitizer from "../htmlsanitizer";
import LiveDataClient from "../livedataclient";
import { getHash } from "../router/router";
interface MediaQuery {
    name: string;
    conditionText: string;
    minW: number;
    maxW: number;
    minH: number;
    maxH: number;
    icon: string;
}

export const MediaQueries: MediaQuery[] = [
    {name: 'Desktop',           conditionText: '', minW: 0,            maxW: Infinity,     minH: 0,            maxH: Infinity,     icon: DesktopIcon},
    {name: 'Portrait Tablet',   conditionText: '(width < 768px)', minW: 0,            maxW: 768,          minH: 0,            maxH: Infinity,     icon: TabletIcon},
    {name: 'Portrait Mobile',   conditionText: '(width < 480px)', minW: 0,            maxW: 480,          minH: 0,            maxH: Infinity,     icon: PhoneIcon},
];

export interface Report {
    name        : string;
    timestamp   : number;
	url			: string;
	element		: HTMLElement;
}

export default class WidgetDashboardView extends View {
    editor: EditorPage | undefined;
    doc: HTMLIFrameElement;
    id: number;
    graphID: number;
    dashboardWrapper: HTMLDivElement;
    currentQueryIndex: number;
    dashboard: Dashboard;
    sanitizer: HTMLSanitizer;
    reportsContainer: HTMLElement;
    reportsList: HTMLElement;
    reportsDisplay: HTMLElement;
    embed: HTMLEmbedElement;
    reports: Report[];
    onLoad: (()=>void) | undefined
    ldc: typeof LiveDataClient;
    constructor(ldc: typeof LiveDataClient, id: number, editor?: EditorPage, onLoad?: ()=>void) {
        super();
        this.editor     = editor;
        this.id         = id;
        this.ldc        = ldc;
        this.graphID    = ldc.registerGraph(this);
        this.onLoad     = onLoad;
        this.sanitizer  = new HTMLSanitizer(undefined, true);
    }

    initialize(parent: HTMLElement) {
        super.initialize(parent);
        this.wrapper            = createElement('div', 'dashboard-view__wrapper', this.parent);
        this.dashboardWrapper   = createElement('div', 'dashboard-view__iframe', this.wrapper);
        this.reportsContainer   = createElement('div', 'dashboard-view__report hide', this.wrapper); // Reports container starts our hidden
        this.reportsList  		= createElement('div', 'dashboard-view__report_list', this.reportsContainer);
        this.reportsDisplay  	= createElement('div', 'dashboard-view__report_display', this.reportsContainer);
        this.embed				= createElement('embed', 'dashboard-view__report_embed', this.reportsDisplay, undefined, {'type':'application/pdf'});
        this.currentQueryIndex  = this.getQuery();
        if (this.editor)
            this.dashboardWrapper.onscroll = () => this.editor?.overlay.resize()

        this.ldc.fm.buildFrame(LiveData.WVC_GET_DASHBOARD, undefined, this.graphID);
        this.ldc.fm.push_u32(this.id);
        this.ldc.send();
        this.fInitialized = true;
        return this;
    }

    onDashboardResponse(fp: FrameParser) {
        let success = fp.pop_u8() == 1;
        if (success) {
            this.dashboard = {
                id:                 fp.pop_u32(),
                creator:            fp.pop_string(),
                name:               fp.pop_string(),
                version:            fp.pop_u16(),
                companyKey:         fp.pop_string(),
                fPrivate:           fp.pop_u8() == 1,
                fWrites:            fp.pop_u8() == 1,
                fReports:           fp.pop_u8() == 1,
                reportFrequency:    fp.pop_u8(),
                reportFrequencyMod: fp.pop_u16(),
                reportHourOffset:   fp.pop_u8(),
                reportSize:         fp.pop_u8(),
                reportTimezone:     fp.pop_string(),
                assets:             new Map(),
                sharedUsers:        new Map(),
                reportUsers:        new Map(),
                devices:            [],
                thumb:              '',
            };
            let dataSize        = fp.pop_u32();
            let jsonString      = '';
            let fCompressed     = this.dashboard.version >= 3;
            let data: ArrayBuffer;
            if (fCompressed)
                data = fp.pop_buffer(dataSize);
            else {
                jsonString = fp.pop_bytes(dataSize);
            }
            let userCount = fp.pop_u16();
            for (let i=0;i<userCount;++i) {
                let username = fp.pop_string();
                this.dashboard.sharedUsers.set(username, {
                    fAccess: fp.pop_u8(),
                    fWrites: fp.pop_u8(),
                });
                this.dashboard.reportUsers.set(username, fp.pop_u8() == 1);
            }
            let assetCount       = fp.pop_u16();
            for (let i=0;i<assetCount;++i) {
                let uuid        = fp.pop_string();
                let url         = fp.pop_string();
                this.dashboard.assets.set(uuid, url);
            }
            let deviceCount = fp.pop_u32();
            for (let i=0;i<deviceCount;++i) {
                let key       = fp.pop_string();
                this.dashboard.devices.push(key)
            }
            //this.buildFromJSON(data);
            if (fCompressed) {
                //@ts-ignore
                const ds = new DecompressionStream("gzip");
                const stream = new Blob([data!]).stream();
                if (data!.byteLength > 0) {
                    const decompressedStream = stream.pipeThrough(ds);
                    new Response(decompressedStream).text().then((result) => {
                        if (this.dashboard.version === 3) {
                            //TODO: Conversion time
                            this.convertGizmos(result);
                        }
                        else {
                            this.sanitizer.setHTML(this.dashboardWrapper, result);
                        }
                        this.onLoad && this.onLoad();
                    })
                }
                else {
                    this.onLoad && this.onLoad();
                }
            }
            else {
                //this.sanitizer.setHTML(this.dashboardWrapper, jsonString);
                this.onLoad && this.onLoad();
            }
        } else {
            let dialogProperties = {
                title:  'Failed to Load Dashboard',
                body:   'The dashboard you requested could not be loaded. Please check your url, and make sure you have permissions to access this dashboard.',
                buttons: [{title:'Return',callback:()=>{window.location.hash = getHash(Routes.Home)}}]
            }
            new Dialog(document.body, dialogProperties);
        }
    }

    convertGizmos(data: string) {
        let guts = JSON.parse(data);                                    // parse out our saved dashboard

        let customStyles = document.createElement('style') as HTMLStyleElement;
        customStyles.appendChild(document.createTextNode('*{}'))
        this.dashboardWrapper.append(customStyles);
        this.buildDOM(guts.recipes, null, this.dashboardWrapper, customStyles.sheet!);
        //this.sanitizer.setHTML(this.dashboardWrapper, content.innerText);
    }

    // Recursively build widgets until there are no more
    buildDOM(recipes: Recipe[], parentRecipe: Recipe | null, parent: HTMLElement, styleSheet: CSSStyleSheet) {
        for (let i = 0; i < recipes.length; ++i) {
            let recipe = recipes[i];
            if (!recipe.children)
                recipe.children = [];

            let newGizmo = this.buildGizmo(recipe, parent, parentRecipe!, styleSheet)
            if (newGizmo)
                this.buildDOM(recipe.children, recipe, newGizmo, styleSheet);
        }
    }

    buildGizmo(recipe: Recipe, element: HTMLElement | DocumentFragment, parentRecipe: Recipe | null, styleSheet: CSSStyleSheet ): HTMLElement | null {
        let conversion = GizmoConverterMap.get(recipe.id);
        if (!conversion)
            return null;
        let tag = conversion.widgetTag;
        let defaultStyles = conversion.defaultStyles;

        let upgradedElement = document.createElement(tag!);
        upgradedElement.id = `_${uuidv4()}`;
        if (recipe.id === GizmoType.WT_CHART) {
            upgradedElement.setAttribute('date-selection', 'false');
            upgradedElement.setAttribute('is-interactive', 'false');
        }

        for (let [key, value] of Object.entries(recipe.settings ?? {})) {
            if (conversion.keyMap.has(key))
                conversion.keyMap.get(key)!(upgradedElement, value);
        }
        let defaultRule = this.getStyles(upgradedElement, 0, styleSheet);
        for (let [key, value] of Object.entries(defaultStyles)) {
            defaultRule.style[key] = value;
        }
        for (let [aspect, styles] of Object.entries(recipe.style ?? {})) {
            let aspectNum = parseInt(aspect);
            if (aspectNum == 2 || aspectNum == 4 || isNaN(aspectNum))
                continue;
            if (aspectNum == 3)
                aspectNum = 2;

            let rule = this.getStyles(upgradedElement, aspectNum, styleSheet);
            for (let [key, value] of Object.entries(styles)) {
                rule.style[key] = value;
            }
        }
        for (let tagDef of recipe.tags) {
            let key = conversion.tagSocketMap.get(tagDef.socket);
            if (key) {
                let tagElement = document.createElement('tag-def');
                tagElement.setAttribute('tag-path', `${tagDef.deviceKey}:${tagDef.path.substring(1).replaceAll('/', '.')}`);
                tagElement.slot = key;

                if (tagDef.settings) {
                    for (let [key, value] of Object.entries(tagDef.settings)) {
                        if (value !== '')
                            tagElement.setAttribute(conversion.tagSettingMap.get(key)!, value)
                    }
                }
                upgradedElement.append(tagElement);
            }
        }
        if (recipe.id === GizmoType.WT_SETPOINT) {
            if (recipe.settings['fUnits']) {
                let wrapper = document.createElement('div');
                wrapper.id = `_${uuidv4()}`;
                let rule = this.getStyles(wrapper, 0, styleSheet);
                rule.style['display'] = 'flex';
                rule.style['flex'] = '1';
                rule.style['align-items'] = 'center';
                rule.style['gap'] = '5px'
                let units = document.createElement('tag-units')
                for (let tagDef of recipe.tags) {
                    let key = conversion.tagSocketMap.get(tagDef.socket);
                    if (key) {
                        let tagElement = document.createElement('tag');
                        tagElement.setAttribute('tag-path', `${tagDef.deviceKey}:${tagDef.path.substring(1)}`);
                        tagElement.slot = 'units-tag';
                        units.append(tagElement);
                    }
                }
                let ogRule = this.getStyles(upgradedElement, 0, styleSheet);
                ogRule.style['flex'] = '1'
                wrapper.append(upgradedElement);
                wrapper.append(units);
                element.append(wrapper)
            }
        }
        else
            element.append(upgradedElement);
        upgradedElement.setAttribute('hmi-name', recipe.name);
        return upgradedElement;
    }

    getStyles(element: Element, aspect: number, styleSheet: CSSStyleSheet ): CSSStyleRule {
        if (aspect !== 0) {
            let query = MediaQueries[aspect];
            for (let rule of styleSheet.cssRules) {
                if (rule instanceof CSSContainerRule && rule.conditionText === query.conditionText) {
                    for (let innerRule of rule.cssRules) {
                        if (innerRule instanceof CSSStyleRule && innerRule.selectorText == `#${element.id}`)
                            return innerRule as CSSStyleRule;
                    }
                    return rule.cssRules[rule.insertRule(`#${element.id}{}`)] as CSSStyleRule;
                }
            }
            let mediaRule = styleSheet.cssRules[styleSheet.insertRule(`@container ${query.conditionText}{}`, aspect)] as CSSMediaRule;
            return mediaRule.cssRules[mediaRule.insertRule(`#${element.id}{}`)] as CSSStyleRule;
        }
        else {
            for (let rule of styleSheet.cssRules) {
                if (rule instanceof CSSStyleRule && rule.selectorText == `#${element.id}`)
                    return rule as CSSStyleRule;
            }
        }
        return styleSheet.cssRules[styleSheet.insertRule(`#${element.id}{}`)] as CSSStyleRule;
    }

    resizeGizmo(gizmo: Gizmo) {
        gizmo.recipe.children?.forEach(childRecipe => {
            this.resizeGizmo(childRecipe.gizmo!);
        })
        gizmo.onResize();
    }

    applyStylesRecursively(gizmo: Gizmo) {
        gizmo.recipe.children && gizmo.recipe.children.forEach(childRecipe => {
            this.applyStylesRecursively(childRecipe.gizmo!);
        })
        gizmo.applyStyles();
    }

    getQuery(): number {
        let width   = this.dashboardWrapper.clientWidth;
        let height  = this.dashboardWrapper.clientHeight;
        let currentQuery = 0;
        for (let i=0;i<MediaQueries.length;++i) {
            let query = MediaQueries[i];
            if (query.maxH >= height && query.maxW >= width && query.minH <= height && query.minW <= width)
                currentQuery = i;
        }
        return currentQuery;
    }

    showReports(): boolean {
        this.dashboardWrapper.classList.toggle('hide');
        let fShowingReports = !this.reportsContainer.classList.toggle('hide');
        if(fShowingReports && this.reports === undefined) {	// If we are now showing the reports container and we don't have history
            this.ldc.fm.buildFrame(LiveData.WVC_GET_USER_REPORTS, undefined, this.graphID);
            this.ldc.fm.push_u32(this.id);
            this.ldc.send();
            this.reports = [];	// We now have history
        }
        return fShowingReports;
    }

    onDashboardReportListResponse(fp: FrameParser) {
        let count = fp.pop_u32();
        for (let i = 0; i < count; ++i) {
            let name = fp.pop_string();
            let timestamp = fp.pop_u64();
            this.reports.push({
                name: name,
                timestamp: timestamp,
                url: fp.pop_string(),
                element: createElement('div', 'dashboard-view__report_label', undefined, name + ' - ' + (new Date(timestamp/1000).format("%yyyy/%MM/%dd %HH:%mm")))
            });
            let report = this.reports.back();
            report.element.onclick = () => fetch(report.url).then(async (response) => {this.embed.src = URL.createObjectURL(await response.blob())});
        }
        this.reports.sort((a, b) => b.timestamp - a.timestamp);	// Sort the reports so the newest reports are first (biggest timestamps first)
        for (const report of this.reports)				// Now attach each sorted element
			this.reportsList.append(report.element);
    }
}
