import {createElement, getHumanReadableTime} from './elements'
import AlarmIcon from './images/icons/alarms.svg';
import './alarmwidget.css';
import { Routes } from '../owner';

export class SiteAlarmSummaries {
	constructor(parent, deviceSet) {
		this.parent				= parent;	// The side panels div
		this.devices			= deviceSet.array;
		this.backButton			= owner.alarmHeaderBack;
		this.alarmTitleContent	= owner.alarmHeaderTitle;
        this.searchBar			= owner.alarmSearchBar;
        this.searchX			= owner.alarmSearchClear;

		this.alarmSection		= createElement('section', 'alarmListSection', this.parent);
		this.alarmContainer		= createElement('div', 'container alarmHomeList', this.alarmSection);
		this.messageThread		= createElement('div', 'alarm-list hide', this.alarmSection);

		this.rowMap				= new Map();	// Map of devices to rows
		this.stubMap			= new Map();	// Map of alarms to stubs
		this.createSiteRow(null);				// Create a row for all sites
		for (var i = 0; i < this.devices.length; ++i)
			this.createSiteRow(this.devices[i]);	// And a row for each device

		this.searchBar.addEventListener('input', this.searchByText.bind(this));	// Search as they type
		this.searchX.addEventListener('click', this.clearSearch.bind(this));
    	this.backButton.addEventListener('click', this.onBackClick.bind(this));	// Go back when they tell us to
    	this.alarmTitleContent.addEventListener('click', this.onTitleClick.bind(this));

		for (var i = 0; i < this.devices.length; ++i) 		// Get notified of all alarms
			this.devices[i].alarms.registerCallback(this);

		this.intervalID = setInterval(this.updateStubTimes.bind(this), 1000*60);	// Update stub timers every 60 seconds
	}

	refresh() {
		this.updateStubTimes();
		for (var [device, siteRow] of this.rowMap)
			siteRow.timeStamp.textContent	= this.getTextMessageTime(siteRow.tsActivated) + ' ';
	}

	show(device) {
		this.rowMap.get(device).click();
	}

	onTitleClick() {
		if (this.selectedSite)
			owner.navigation.path = this.selectedSite.key;	// Navigate to the selected site if we have one
	}

	createSiteRow(device) {
		var siteRow = createElement('div', 'row alarmMessageRow hide', this.alarmContainer);
		siteRow.onclick = this.onSiteClick.bind(this, device, siteRow);
		siteRow.tsActivated		= 0;
		var alarmNumber			= createElement('div', 'col-2 text-center my-auto', 	siteRow);
		siteRow.alarmCircle		= createElement('div', 'alarmCircleNumber', alarmNumber);
		siteRow.alarmCount		= createElement('p', null, siteRow.alarmCircle);
		var alarmInfo			= createElement('div', 'col-10', siteRow);
		var infoHeader			= createElement('div', 'row', alarmInfo);
		siteRow.siteName		= createElement('div', 'col-7 recentAlarmSiteName', infoHeader, device ? device.siteName : "All Sites");
		var timeWrapper			= createElement('div', 'col-5 text-right recentAlarmMessage', infoHeader);
		siteRow.timeStamp		= createElement('span', null, timeWrapper);
		createElement('i', 'fas fa-angle-right', timeWrapper);
		var messageContainer	= createElement('div', 'row', alarmInfo);
		siteRow.message			= createElement('div', 'col-12 recentAlarmMessage', messageContainer);
		var dividerContainer	= createElement('div', 'col-12', siteRow);
		var divider				= createElement('hr', 'alarmMessageDivider', dividerContainer);
		this.rowMap.set(device, siteRow);	// Update our map
	}

	onSiteClick(device, siteRow) {	// A site was clicked
		this.backButton.classList.remove('hide');		// Show the back button
		this.messageThread.classList.remove('hide');	// Show the alarm list
		this.alarmContainer.classList.add('hide');		// Don't show site list
		this.alarmTitleContent.textContent	= siteRow.siteName.textContent;	// Update the header
		this.selectedSite = device;		// Remember the selected site
		if (this.selectedSite)
			this.alarmTitleContent.classList.add('clickable');
		this.clearSearch();								// Clear the search bar
		this.alarmSection.scrollTop = this.alarmSection.scrollHeight;		// Scroll to the bottom list a text message thread
	}

	onBackClick() {					// The back button was clicked
		this.backButton.classList.add('hide');			// Hide the back button
		this.messageThread.classList.add('hide');		// Hide the alarm list
		this.alarmContainer.classList.remove('hide');	// Show the sites
		this.alarmTitleContent.classList.remove('clickable');
		this.alarmTitleContent.textContent = 'Alarms';	// Reset header message
		delete this.selectedSite;						// No selected site
		this.clearSearch();								// Clear the search bar
	}

	setStubTimes(alarm, stub) {
		stub.time.textContent = (getHumanReadableTime(alarm.tsActivated, '', ' ago') + " " + (new Date(alarm.tsActivated/1000)).format("(%HH:%mm:%ss on %yyyy-%MM-%dd)"));
		for (var i = 0; i < stub.replies.length; ++i)
			stub.replies[i].time.textContent = getHumanReadableTime(alarm.tsAcknowledged[i], '', ' ago');
	}

	updateStubTimes() {
		for (var [alarm, stub] of this.stubMap)	// For each alarm we have
			this.setStubTimes(alarm, stub);		// Update the stub times
	}

	clearSearch() {
		this.searchBar.value = '';		// Clear the search bar
		this.searchByText();			// Clear searched messages
	}

	searchByText() {
		var searchText = this.searchBar.value.toLowerCase();	// Convert to lower case once
		this.searchX.classList.toggle('hide', searchText.length == 0);
		if (this.selectedSite === undefined)					// If there's no site selected
			this.searchSitesByText(searchText);					// Search the site rows
		else
			this.searchMessagesByText(searchText)				// Filter messages by the selected site
	}

	searchSitesByText(searchText) {
		for (var [device, siteRow] of this.rowMap) {			// For each row
			var fCollapse = false			// Assume we shouldn't hide the row until proven otherwise
			if (searchText.length != 0) {	// If we have something to search on
				if (!device)				// If there's not a device here (the All Sites row)
					fCollapse = true;		// Collapse it every time
				else if (!device.siteName.toLowerCase().includes(searchText)) {	// If we don't match site name
					fCollapse = true;		// Collapse the row unless we find a matching alarm at the site
					var alarmSet = device.alarms;
					for (var i = 0; i < alarmSet.alarms.length; ++i) {	// But first look to see if they match an alarm here
						var alarm = alarmSet.alarms[i]
						if (alarm.isActive() && alarm.message.toLowerCase().includes(searchText)) {	// If the alarm message matches
							fCollapse = false;	// Don't collapse
							break;				// And stop looking
						}
					}
				}
			}
			siteRow.classList.toggle('collapse', fCollapse);
		}
	}

	searchMessagesByText(searchText) {
		for (var [alarm, stub] of this.stubMap) {	// For each alarm we have
			var fCollapse = false;					// Assume we don't hid until proven otherwise
			if (searchText.length != 0 || this.selectedSite) {			// If we have search text
				if (this.selectedSite && this.selectedSite !== alarm.alarmSet.device){	// If this site is hidden
					fCollapse = true;				// Collapse the alarm
				} else 								// We are the correct site
					fCollapse = !stub._root.innerText.toLowerCase().includes(searchText);	// Show the alarm if it matches
			}
			stub._root.classList.toggle('collapse', fCollapse);
		}
		this.alarmSection.scrollTop = this.alarmSection.scrollHeight;	// Scroll to the bottom like a text message feed
	}

	getTextMessageTime (timestamp) {
		var now 			= (new Date()).getTime();
		var secondsElapsed 	= (now - timestamp/1000) / 1000;	// Convert timestamp to milliseconds, take the diff from current time, then convert to seconds
		timestamp			= new Date(timestamp/1000);			// Conver the timestamp to a JS Date object
		var midnight		= new Date();						// Get a Date for this instant
		midnight.setHours(0,0,0,0);								// And take it back to midnight
		var fYesterday = timestamp < midnight && timestamp > (midnight - 86400000);	// True if the alarm was yesterday
		if (secondsElapsed > 604800)					// Longer than a week
			return timestamp.format("%yyyy/%MM/%dd");
		else if (secondsElapsed > 86400 && !fYesterday)	// Longer than 24 hrs and not yesterday
			return timestamp.format("%DDDD");
		else if (fYesterday)							// Yesterday
			return 'Yesterday';
		else 											// Today
			return timestamp.format('%HH:%mm');
	}

	createPendingReply(alarm, stub){
		if(alarm.severity < 100 || stub.pendReply || (/*!alarm.alarmSet.fAlarmsUpdated &&*/ !alarm.isUnacknowledged()))
			return;         // Not creating secondary replies for now
		var pendReply = owner.appendStub(owner.templates.alarmPendingReply, stub.replyContainer);
		stub.pendReply = pendReply;
		pendReply.submit.onclick = this.onReplySubmit.bind(this, alarm, stub, pendReply);
		pendReply.cancel.onclick = this.destroyPendingReply.bind(this, alarm, stub, pendReply);
//		pendReply.textbox.classList.toggle('hide', !alarm.alarmSet.fAlarmsUpdated);
		pendReply.textbox.classList.add('hide');
	}

	onReplySubmit(alarm, stub, pendReply){
		alarm.ackMessage = pendReply.textbox.value;
		alarm.alarmSet.acknowledge([alarm]);
		this.destroyPendingReply(alarm, stub, pendReply);
	}

	destroyPendingReply(alarm, stub, pendReply){
		stub.replyContainer.removeChild(pendReply._root);
		delete stub.pendReply;
	}

	insertAlarm(a, b)	{return a.tsActivated < b.tsActivated;}	// Alarms go newest last
	insertDevice(a, b)	 {return a !== b && a.tsActivated >= b.tsActivated;}	// Devices go newest first
	insertChild(parent, child, sortFunction) {
		for (var i = 0; i < parent.children.length; ++i)	// Check all children of the parent
			if (sortFunction(child, parent.children[i]))	// If this node comes before this child
				return parent.insertChildAt(child, i);		// Insert the child into the DOM here
		return parent.appendChild(child);					// Only get here if we are the last in the list
	}

	onAlarmAdded(alarm, device) {
		// Insert new message section based on time
		var stub = owner.cloneStub(owner.templates.alarm);
		stub.alarmBox.onclick = this.createPendingReply.bind(this, alarm, stub);
		stub._root.tsActivated = alarm.tsActivated;	// For sorting
		stub.replies = [];							// Start off with no replies
		this.insertChild(this.messageThread, stub._root, this.insertAlarm);	// Insert the child
		this.stubMap.set(alarm, stub);
	}

	onAlarmChanged(alarm, fNew, device) {
  		var stub = this.stubMap.get(alarm);
  		if (!alarm.isActive())	{	// If the alarm isn'a active
  			if (stub) {
  				this.stubMap.delete(alarm);
  				if (stub._root)
  					this.messageThread.removeChild(stub._root)	// Remove the stub
  			}
			return;					// Nothing else to do
  		}

  		if (fNew) {	// These things don't change. Only set them the first time
  			stub.siteName.textContent = device.siteName + ' -- ' + alarm.tag;
  			stub.message .textContent = alarm.message;
  			stub.severity.textContent = alarm.severity;
  			stub._root.classList.toggle('collapse', this.selectedSite && this.selectedSite !== device);
  		}

		if(alarm.severity >= 100) {	// If the alarm can be ack'd
			for (var i = stub.replies.length; i < alarm.userNames.length; ++i) {	// Acknowledged alarms get a reply message generated
				var reply = owner.appendStub(owner.templates.alarmReply, stub.replyContainer);
				reply.alarmReplyBox.onclick = this.createPendingReply.bind(this, alarm, stub);
				reply.userName.textContent = alarm.userNames[i];

				if (!alarm.alarmSet.fAlarmsUpdated || true)
					reply.message.textContent = 'Acknowledged.';
				else
					reply.message.textContent = alarm.messages[i];
				stub.replies.push(reply);

//				if (alarm.userNames[i] !== LiveDataClient.user.username)
//					reply._root.setAttribute('otheruser', true);
			}
		}
		this.setStubTimes(alarm, stub);
  	}

	updateRow(siteRow, alarm, activeCount, fUnAck) {
		if (alarm) {
			siteRow.tsActivated				= alarm.tsActivated;	// Used for sorting
			siteRow.timeStamp.textContent	= this.getTextMessageTime(alarm.tsActivated) + ' ';
			siteRow.message.textContent		= alarm.message;
		}

		siteRow.alarmCircle.classList.toggle('unacknowledgedMessages', fUnAck);
		siteRow.alarmCount.textContent	= activeCount;
  		siteRow.classList.toggle('hide', activeCount == 0);	// Hide the row if no active alarms
		this.insertChild(this.alarmContainer, siteRow, this.insertDevice)
	}

	findMostRecentAlarm(alarmSet) {		// Find the most recent active alarm for a given site
		if (alarmSet.active == 0)		// If the device doesn't have an active alarm
			return null;				// Just return null
		for (var j = alarmSet.alarms.length - 1; j >= 0; --j)	// Check alarms in reverse order
			if (alarmSet.alarms[j].isActive())	// If the alarm is unactive, keep looking
				return alarmSet.alarms[j];
	}

  	endAlarmCommand(device) {
		var mostRecent = {tsActivated: 0}, total = 0, fUnack = false;	// Find the most recent alarm for the All Sites entry
		for (var i = 0; i < this.devices.length; ++i) {		// For all devices
			var alarmSet = this.devices[i].alarms;			// Convenience reference to the alarm set
			total += alarmSet.active;						// Add in the total number of active alarms
			fUnack = fUnack || alarmSet.unackActive > 0;	// Remember if any alarms were not acknowledged
			var alarm = this.findMostRecentAlarm(alarmSet);	// Find the most recent active alarm for this device
			if (alarm && alarm.tsActivated > mostRecent.tsActivated)	// If this the most recent alarm we've seen
				mostRecent = alarm;							// Hold onto the alarm
		}

  		var siteRow = this.rowMap.get(device);	// Get the row for this device
  		this.updateRow(siteRow, this.findMostRecentAlarm(device.alarms), device.alarms.active, device.alarms.unackActive > 0);
  		this.updateRow(this.rowMap.get(null), mostRecent, total, fUnack);	// Get the All Sites entry and update it

		if (this.searchBar.value.length)	// If there's search text
			this.searchByText();			// Research
		this.alarmSection.scrollTop = this.alarmSection.scrollHeight;	// Scroll to the bottom like a text message feed
  	}

  	onAlarmRemoved(alarm, device) {}	// Unused alarm callbacks
  	onHistorical() {}
}

//Connects to the header bar and puts real alarms in it.
export class AlarmHeader {
	constructor() {
		this.container = createElement('div', 'alarm-header__container', document.body);
		this.messageElem = createElement('div', 'alarm-header__message', this.container);
		this.container.addEventListener('click', (event)=>{window.location.hash = owner.router.getRouteHref(Routes.Device, {'key':this.topAlarm.alarmSet.device.key})});
		this.count = owner.alarmHeaderBadge;

		this.devices = LiveDataClient.devices.array;
		for (var i = 0; i < this.devices.length; ++i)
			this.devices[i].alarms.registerCallback(this);

		// Hide the alarm header for your convenience
		this.alarmHeaderArrow = owner.alarmHeaderArrow;;
		this.alarmHeaderArrow.classList.remove('hide');
		this.alarmNipple = owner.alarmHeaderButton;
		this.alarmNipple.classList.remove('hide');
		this.container.isMoving = false;
		this.alarmNipple.addEventListener('click', (e) => this.handleContainerClick(e));

		this.initializeMarquee();	// Call initialize on marquee, and recall it when we resize
		this.container.onresize = this.initializeMarquee.bind(this);
		this.messageElem.addEventListener('transitionend', this.resetScroll.bind(this));  // Reset once the scroll is done
  	}

	resetScroll() {
		this.messageElem.style.transitionDuration = '0s';
		this.messageElem.style.transform = 'translateX(0)';
	}

	reportNominal() {
		this.container.classList.add("alarm-header-nominal");
		this.messageElem.classList.remove('alarm-header-animated');
		this.messageElem.textContent = "\u2713 Status: Nominal."
	}

	initializeMarquee() {	// This function decides if and how much the marquee must scroll
		let offset = this.container.clientWidth - this.count.clientWidth;
		if(this.messageElem.interval)   // Clear the interval if we've already created one
			clearInterval(this.messageElem.interval);
		if(this.messageElem.clientWidth < offset) {
			this.messageElem.style.transitionDuration = '0s';
			this.messageElem.style.transform = '';
		} else {
			let distance = this.messageElem.clientWidth - offset + 10; // How much to scroll by
			let time = distance/100;  // How much time to take so that it's not a blur
			// Set a new interval; add some extra time for safety
			this.messageElem.interval = setInterval(this.scroller.bind(this, distance, time), time*1000 + 4200);
			this.messageElem.style.transitionDuration = time + 's';
		}
	}

	scroller(distance, time) {
		// This guy actually scrolls the marquee: it is set on an interval above
		// When we resize, the interval is cleared and reset to appropraitely fit
		// the new div sizes
		this.messageElem.style.transitionDuration = time + 's';
		this.messageElem.style.transform = 'translateX(-' + distance + 'px)';
	}

	reportAlarm(topAlarm) {
		this.topAlarm = topAlarm;
		this.messageElem.classList.add('alarm-header-animated');
		this.container.classList.remove("alarm-header-nominal");
		const tag = topAlarm.tag[0] === '/' ? topAlarm.tag.substr(1) : topAlarm.tag;
		this.messageElem.textContent = topAlarm.message + ' (' + tag + ' at ' + topAlarm.alarmSet.device.siteName + ', '
										+ getHumanReadableTime(topAlarm.tsActivated, 'for ', '') +')';
	}

    onAlarmAdded() {}
  	onAlarmChanged() {}
  	onAlarmRemoved() {}
  	onHistorical() {}
  	endAlarmCommand() {
		var alarm = {severity: -1};
		var total = 0;
		for (var i = 0; i < this.devices.length; ++i) {
			for (var j = 0; j < this.devices[i].alarms.alarms.length; ++j) {
				var a = this.devices[i].alarms.alarms[j];
				if (a.isActive() && (a.severity > alarm.severity || (a.severity == alarm.severity && a.tsActivated > alarm.tsActivated)))
					alarm = a;
			}
			total += this.devices[i].alarms.active;
		}
		if(total == 0)
			this.reportNominal();
		else {
			assert(alarm.severity > -1);
			this.reportAlarm(alarm);
		}
		this.count.textContent = total;
		this.count.classList.toggle('hide', total === 0);
  	}

	// alarm crawl hide
	handleContainerClick(e) {
		if(!(this.container.classList.contains('alarm-header-peek')) && !this.container.isMoving) {
			this.container.isMoving = true;
			this.alarmHeaderArrow.classList.add('point-up');
			this.container.classList.add('alarm-header-peek');
			setTimeout(() => this.container.isMoving = false, 1000);
		} else if(!this.container.isMovingDown && !this.container.isMovingUp) {
			this.container.isMoving = true;
			this.container.classList.remove('alarm-header-peek');
			this.alarmHeaderArrow.classList.remove('point-up');
			setTimeout(() => this.container.isMoving = false, 1000);
		}
		e.stopPropagation();
	}
}

//An orange Alarm icon with an alarm count beside it
//It can open the alarm slider and set its context when clicked.
export class AlarmBadge {
	constructor(parent, device, alarmSidepanel) {
		this.alarmSidepanel = alarmSidepanel;
		this.parent = parent;
        this.element = createElement('div','alarm-badge__wrapper', parent);
		this.alarmIcon = createElement('div', 'alarm-badge__icon', this.element);
		this.alarmCount = createElement('div', 'alarm-badge__count', this.element);
		this.device = device;
		if (this.alarmSidepanel)
			this.element.onclick = (e) => this.onClick(e)
		device.alarms.registerCallback(this);
		this.constructed = true;
	}

	onClick(event) {
		event.stopPropagation();
		this.alarmSidepanel.open();
		this.alarmSidepanel.alarmSummaries.show(this.device)
	}

    onAlarmAdded() {}
  	onAlarmChanged() {}
  	onAlarmRemoved() {}
  	onHistorical() {}
  	endAlarmCommand(device) {
		this.alarmCount.textContent = device.alarms.active;
		this.element.classList.toggle('hide', device.alarms.active === 0);
  	}

	destroy() {
		if (!this.constructed)
			return;
		this.constructed = false;
		this.parent.removeChild(this.element);
		this.device.alarms.removeCallback(this);
	}
}
