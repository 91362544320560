import './submitticketview.css';
import { createElement, createUniqueId } from '../elements';
import View from './view';
import LiveData from '../livedata';
import FrameParser from '../frameparser';
import CountryCodes from '../assets/countryCodes.json';
import LiveDataClient from '../livedataclient';

export default class SubmitTicketView extends View
{
	container: HTMLElement;
	form: HTMLElement;
	graphID: number;

	constructor(options)
	{
		super();
		this.copy(options);
		this.graphID = LiveDataClient.registerGraph(this);
	}

	initialize(parent: HTMLElement)
	{
		super.initialize(parent);
		this.wrapper = createElement('div', 'submit-ticket__wrapper', this.parent);
		this.container = createElement('div', 'submit-ticket__container', this.wrapper);

		// Create form
		let form = createElement('form', 'submit-ticket__form', this.container);
		let name = createElement('input', 'submit-ticket__form__text', form, undefined, { type: 'text', placeholder: 'Name *', required: true });
		let email = createElement('input', 'submit-ticket__form__text', form, undefined, { type: 'email', placeholder: 'Email *', required: true });

		// Phone + country code
		let phoneWrapper = createElement('div', 'submit-ticket__form__phone__container', form);
		let countryCode = createElement('select', 'submit-ticket__form__phone__select', phoneWrapper, '', { 'name': 'countryCode' });
		let phone = createElement('input', 'submit-ticket__form__text', phoneWrapper, undefined, { type: 'tel', placeholder: 'Phone Number', required: false });
		// Loop through our countries and create an option for each
		const countryInfo = [CountryCodes.us, CountryCodes.ca, CountryCodes.gb, CountryCodes.mx, CountryCodes.qa];
		for (let [code, info] of Object.entries(countryInfo))
		{
			let option = createElement('option', 'account-modify__phone-wrapper__select__option', countryCode);
			createElement('div', 'account-modify__phone-wrapper__select__option__text', option, `${info.flag} ${info.name} (${info.dialCode})`);
			option.value = info.dialCode.substring(1);
		}

		let site = createElement('input', 'submit-ticket__form__text', form, undefined, { type: 'text', placeholder: 'Site Name', required: false });
		let subject = createElement('input', 'submit-ticket__form__text', form, undefined, { type: 'text', placeholder: 'Subject *', required: true });

		let categoryWrapper = createElement('div', 'submit-ticket__form__checkbox__container', form);

		let idGeneralInquiry = createUniqueId();
		let generalInquiryWrapper = createElement('div', 'se-checkbox', categoryWrapper);
		let generalInquiryCheckbox = createElement('input', '', generalInquiryWrapper, '', { type: 'checkbox', id: idGeneralInquiry, value: 'General Inquiry' });
		createElement('label', '', generalInquiryWrapper, 'General Inquiry', { htmlFor: idGeneralInquiry });

		let idFeatureRequest = createUniqueId();
		let featureRequestWrapper = createElement('div', 'se-checkbox', categoryWrapper);
		let featureRequestCheckbox = createElement('input', '', featureRequestWrapper, '', { type: 'checkbox', id: idFeatureRequest, value: 'Feature Request' });
		createElement('label', '', featureRequestWrapper, 'Feature Request', { htmlFor: idFeatureRequest });

		let idDPO = createUniqueId();
		let dpoWrapper = createElement('div', 'se-checkbox', categoryWrapper);
		let dpoCheckbox = createElement('input', '', dpoWrapper, '', { type: 'checkbox', id: idDPO, value: 'DPO' });
		createElement('label', '', dpoWrapper, 'DPO', { htmlFor: idDPO });

		let idTagger = createUniqueId();
		let taggerWrapper = createElement('div', 'se-checkbox', categoryWrapper);
		let taggerCheckbox = createElement('input', '', taggerWrapper, '', { type: 'checkbox', id: idTagger, value: 'Tagger' });
		createElement('label', '', taggerWrapper, 'Tagger', { htmlFor: idTagger });

		let idLiftStationGuardian = createUniqueId();
		let liftStationGuardianWrapper = createElement('div', 'se-checkbox', categoryWrapper);
		let liftStationGuardianCheckbox = createElement('input', '', liftStationGuardianWrapper, '', { type: 'checkbox', id: idLiftStationGuardian, value: 'General Inquiry' });
		createElement('label', '', liftStationGuardianWrapper, 'Lift Station Guardian', { htmlFor: idLiftStationGuardian });

		let idOther = createUniqueId();
		let otherWrapper = createElement('div', 'se-checkbox', categoryWrapper);
		let otherCheckbox = createElement('input', "", otherWrapper, "", { type: 'checkbox', id: idOther, value: 'Other' });
		createElement('label', "", otherWrapper, 'Other', { htmlFor: idOther });

		let categories = [generalInquiryCheckbox, featureRequestCheckbox, dpoCheckbox, taggerCheckbox, liftStationGuardianCheckbox, otherCheckbox];

		let description = createElement('textarea', 'submit-ticket__form__text-area', form, undefined, { rows: 5, placeholder: 'Ticket Description *', required: true });

		createElement('input', 'submit-ticket__form__submit se-button', form, undefined, { type: 'submit', value: 'Submit' });
		form.onsubmit = () => {
			LiveDataClient.fm.buildFrame(LiveData.WVC_POST_HUBSPOT_FORM, undefined, this.graphID);
			LiveDataClient.fm.push_string(name.value);
			LiveDataClient.fm.push_string(email.value);
			LiveDataClient.fm.push_string(`+${countryCode.value} ${phone.value}`);
			LiveDataClient.fm.push_string(site.value);
			LiveDataClient.fm.push_string(subject.value);
			for (let i = 0; i < categories.length; ++i)
				LiveDataClient.fm.push_u8(categories[i].checked);
			LiveDataClient.fm.push_string(description.value);
			LiveDataClient.send();
		};
		this.fInitialized = true;
		return this;
	}

	onHubspotTicket(fp: FrameParser)
	{
		this.container.removeChildren();
		if (fp.pop_u8())  // Submit went through!
		{
			let ticketID = fp.pop_string();
			let successWrapper = createElement('div', 'submit-ticket__success', this.container);
			createElement('div', 'submit-ticket__success__text', successWrapper, `Thanks for submitting a ticket. You should receive an e-mail shortly to reference. Someone will be in touch within 1-2 business days.`);
			createElement('div', 'submit-ticket__success__sub-text bold', successWrapper, `If your need is urgent, please contact our technical support staff directly:`);
			createElement('div', 'submit-ticket__success__sub-text', successWrapper, `+1 (512) 890-2765`);
			createElement('div', 'submit-ticket__success__sub-text', successWrapper, `support@specificenergy.com`);
		}
		else
		{
			let errorWrapper = createElement('div', 'submit-ticket__success', this.container);
			createElement('div', 'submit-ticket__success__text bold', errorWrapper, `Failed.`);
			createElement('div', 'submit-ticket__success__text', errorWrapper, `Please refresh or try again later.`);
		}
	}


}