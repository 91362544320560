import FrameMaker from "./framemaker";
import FrameParser from "./frameparser";
import LiveData from "./livedata";
import LiveDataClient from "./livedataclient";
import User, { CalloutInterval, NotificationMethod, PermissionGroup, SMSSubscriptionStatus } from "./user";

export interface GroupInfo {
    name: string;
    devices: string[];
    children: GroupInfo[];
    parentName: string;
    parent: GroupInfo | undefined;
}

export interface UserInfo {
    userName?: string;				// Give each user's name
    firstName?: string;				// Give each user's first name
    lastName?: string;				// Give each user's last name
    email?: string;				// Give each user's email address
    phone?: string;				// Give each user's phone number
    countryCode?: number;
    permissions?: PermissionGroup[];
    companyKey?: string;
    fEnabled?: boolean
    fWizard?: boolean
    fTagConfig?: boolean
    fDevConfig?: boolean
    fAdmin?: boolean
    callouts?: string;
    failover?: string;
    failMinutes?: number;
    lowSev?: number;
    highSev?: number;
    intervals?: CalloutInterval[];
    fTwoFactorEnabled?: boolean;				// Give each user's two-factor status
    lastLogin?: number;				// Give each user's time of last Login
    loginCount?: number;				// Give each user's login count
    smsSubscriptionStatus?: SMSSubscriptionStatus;
    notificationMethod?: NotificationMethod;
}

export interface UserListInfo {
    username: string;
    firstName: string;
    lastName: string;
}

export interface Company {
	name: string;
	key: string;
}

export function deleteUser(username: string): Promise<boolean> {
    if (!(User.isPowerUser() || User.isAdmin()) || typeof username === 'undefined')	// Power user's have permission to do just about anything
        return new Promise((resolve, reject) => resolve(false));
    let fm = buildAccountManagementFrame(LiveData.ACCOUNT_DELETE_USER);
    fm.push_string(username);						// Add in the user name
    return new Promise<boolean>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(parseAccountManagementResponse(fp))));
}

export function getGroups(companyKey: string): Promise<GroupInfo[]> {	// Get a list of all the users for a company
    let fm = new FrameMaker();
    fm.buildFrame(LiveData.WVC_GET_GROUPS);
    fm.push_string(companyKey);	// Add the company key they wanted users for
    return new Promise((resolve, reject) => { //TODO: add error handling
        LiveDataClient.sendRequest(fm).then(fp => resolve(parseGroups(fp)));
    });
}

function parseGroups(fp: FrameParser): GroupInfo[] {
    let groups: GroupInfo[] = [];
    groups.push({
        name: '',
        devices: [],
        children: [],
        parentName: "",
        parent: undefined
    })
    var count = fp.pop_u16();	// How many groups are attached
    for (let i = 0; i < count; ++i) {
        var group: GroupInfo = {
            name: fp.pop_string(),
            devices: [],
            children: [],
            parentName: "",
            parent: undefined
        };
        var devCount = fp.pop_u16();
        for (var j = 0; j < devCount; ++j) {
            var key = fp.pop_string();
            group.devices.push(key);
        }
        group.parentName = fp.pop_string();
        groups.push(group);		// Add the user object to the array
    }
    for (let i = 0; i < groups.length; i++) {
        groups[i].parent = groups.find((group) => group.name == groups[i].parentName && group !== groups[i]) // find our parent group
        groups[i].children = groups.filter((group) => { return isChildGroupOf(groups[i], group) })
    }
    return groups;
};

export function isChildGroupOf(parentGroup: GroupInfo, childGroup: GroupInfo): boolean {
    if (childGroup.name === "")
        return false;
    if (parentGroup == childGroup || childGroup.parent == undefined)
        return false
    else if (childGroup.parent == parentGroup)
        return true;
    else {
        return isChildGroupOf(parentGroup, childGroup.parent)
    }
};


export function createGroup(group: string, companyKey: string, parent: string): Promise<boolean> {
    if (!User.isPowerUser()) {	// Power user's have permission to do just about anything
        if (!User.isAdmin() || companyKey != User.companyKey)
            return new Promise<boolean>(() => false);
    }
    if (! /^[a-zA-Z0-9 ]+$/.test(group))
        return new Promise<boolean>(() => false);

    let fm = buildAccountManagementFrame(LiveData.ACCOUNT_CREATE_GROUP);
    fm.push_string(group);
    fm.push_string(companyKey);
    fm.push_string(parent);
    return new Promise<boolean>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(parseAccountManagementResponse(fp))));
}

export function deleteGroup(group: string, companyKey: string): Promise<boolean> {
    if (!User.isPowerUser()) {	// Power user's have permission to do just about anything
        if (!User.isAdmin() || companyKey != User.companyKey)
            return new Promise<boolean>(() => false);
    }
    if (! /^[a-zA-Z0-9 ]+$/.test(group))
        return new Promise<boolean>(() => false);

    let fm = buildAccountManagementFrame(LiveData.ACCOUNT_DELETE_GROUP);
    fm.push_string(group);
    fm.push_string(companyKey);
    return new Promise<boolean>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(parseAccountManagementResponse(fp))));
}

export function modifyGroupDevices(group: string, companyKey: string, sites: string[]): Promise<boolean> {
    if (!User.isPowerUser()) {	// Power user's have permission to do just about anything
        if (!User.isAdmin() || companyKey != User.companyKey)
            return new Promise<boolean>(() => false);
    }
    if (! /^[a-zA-Z0-9 ]+$/.test(group))
        return new Promise<boolean>(() => false);

    let fm = buildAccountManagementFrame(LiveData.ACCOUNT_GROUP_DEVICES);
    fm.push_string(group);
    fm.push_string(companyKey);
    fm.push_u16(sites.length);
    for (var i = 0; i < sites.length; ++i)
        fm.push_string(sites[i]);
    return new Promise<boolean>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(parseAccountManagementResponse(fp))));
}

export function updateUserInfo(username: string, first: string, last: string, email: string, phone: string, countryCode: string, permissions: PermissionGroup[], fEnabled: boolean, fWizard: boolean, fTagConfig: boolean, fDevConfig: boolean, fAdmin: boolean, callouts: string, intervals: CalloutInterval[], lowSev: number, highSev: number, fail: string, failTime: number, fEnableTwoFactor: boolean, notificationMethod: NotificationMethod | undefined): Promise<boolean> {
    if (!User.isAdmin())	// Have to be an admin to modify stuff
        return new Promise<boolean>(() => false);

    if (!username)	// Have to have the username of the user to modify
        return new Promise<boolean>(() => false);

    let fm = buildAccountManagementFrame(LiveData.ACCOUNT_MODIFY_USER)

    let flagOffset = fm.getPosition();	// Get the current offset so we can update the flags at the end of the method
    let flags = 0;							// Start off with no modification flags
    fm.push_u16(flags);				// Reserve space in the frame for the flags
    fm.push_string(username);			// Add in the user to modify

    if (first) {							// They provided a new first name
        flags += LiveData.USER_FIRST;		// Set the flag
        fm.push_string(first);
    }
    if (last) {								// They provided a new last name
        flags += LiveData.USER_LAST;		// Set the flag
        fm.push_string(last);
    }
    if (fEnabled !== null && fEnabled != undefined) {	// They provided a new enabled setting
        flags += LiveData.USER_ENABLED;		// Set the flag
        fm.push_u8(fEnabled ? 1 : 0);
    }
    if (email) {							// They provided a new email address
        flags += LiveData.USER_EMAIL;		// Set the flag
        fm.push_string(email);
    }
    if (phone !== null && phone !== undefined) { // New phone number
        flags += LiveData.USER_PHONE;
        fm.push_string(phone);
        fm.push_u16(countryCode);
    }
    if (permissions !== null && permissions !== undefined) {	// They provided a new group
        flags += LiveData.USER_PERMISSIONS;	// Set the flag
        fm.push_u16(permissions.length);
        for (let i = 0; i < permissions.length; ++i) {
            fm.push_string(permissions[i].group!);
            fm.push_u8(permissions[i].fWrites);
        }
    }
    if (fWizard !== null && fWizard !== undefined) {	// They provided new admin parameters
        flags += LiveData.USER_WIZARD;	// Set the flag
        fm.push_u8(fWizard ? 1 : 0);
    }
    if (fTagConfig !== null && fTagConfig !== undefined) {	// They provided new admin parameters
        flags += LiveData.USER_TAG_CONFIG;	// Set the flag
        fm.push_u8(fTagConfig ? 1 : 0);
    }
    if (fDevConfig !== null && fDevConfig !== undefined) {	// They provided new admin parameters
        flags += LiveData.USER_DEV_CONFIG;	// Set the flag
        fm.push_u8(fDevConfig ? 1 : 0);
    }
    if (fAdmin !== null && fAdmin !== undefined) {	// They provided new admin parameters
        flags += LiveData.USER_ADMIN;	// Set the flag
        fm.push_u8(fAdmin ? 1 : 0);
    }
    if (callouts !== null && callouts !== undefined && intervals !== null && intervals !== undefined &&
        lowSev !== null && lowSev !== undefined && highSev !== null && highSev !== undefined &&
        fail !== null && fail !== undefined && failTime !== null && failTime !== undefined && notificationMethod !== undefined) {
        flags += LiveData.USER_CALLOUTS;	// Set the flag
        fm.push_string(callouts);
        fm.push_string(fail);
        fm.push_u8(failTime);
        fm.push_u16(lowSev);
        fm.push_u16(highSev);
        fm.push_u8(notificationMethod);
        fm.push_u16(intervals.length);
        for (var i = 0; i < intervals.length; ++i) {
            fm.push_u8(intervals[i].day);
            fm.push_u16(intervals[i].start);
            fm.push_u16(intervals[i].end);
        };
    }
    if (fEnableTwoFactor == true) { 						// They are making a change to a user's two factor settings
        flags += LiveData.USER_TWOFACTOR;				// Set the flag
        fm.push_u8(fEnableTwoFactor ? 1 : 0);
    }
    // Update our flags in the frame
    var lastPosition = fm.getPosition();	// Record frame so we can zoom back to the end
    fm.setPosition(flagOffset);			// Move back to where the flags are
    fm.push_u16(flags);					// Update the flags
    fm.setPosition(lastPosition);			// Go back to the end of the frame
    return new Promise<boolean>((resolve, reject) => {
        if (flags <= 0)
            reject("Not actually modifying a user.");
        LiveDataClient.sendRequest(fm).then(fp => resolve(parseAccountManagementResponse(fp)));
    });
}

export function createUser(username: string, companyKey: string, firstName: string, lastName: string, email: string, phone: string, countryCode: number, permissions: PermissionGroup[], fEnabled: boolean, fWizard: boolean, fTagConfig: boolean, fDevConfig: boolean, fAdmin: boolean): Promise<boolean> {
    if (!User.isPowerUser()) {	// Power user's have permission to do just about anything
        if (!User.isAdmin())	// Have to be an admin to create a user for your company
            return new Promise<boolean>(() => false);
        if (this.user.companyKey != companyKey)		// Normal admins can only create users for their company
            return new Promise<boolean>(() => false);
    }
    if (!username)	// Username is required to create a user
        return new Promise<boolean>(() => false);

    let fm = buildAccountManagementFrame(LiveData.ACCOUNT_ADD_USER);
    fm.push_u16(LiveData.ACCOUNT_ADD_USER);		// Push what account management command we are doing
    fm.push_string(username);					// Add in the new user name
    fm.push_string(companyKey);					// Company key must be provided
    fm.push_string(firstName);					// First Name must be provided
    fm.push_string(lastName);					// Last Name must be provided
    fm.push_string(email);						// Email must be provided
    fm.push_string(phone);						// Phone must be provided
    fm.push_u16(countryCode);
    if (permissions !== null && permissions !== undefined) {	// They provided a new group
        fm.push_u16(permissions.length);
        for (var i = 0; i < permissions.length; ++i) {
            fm.push_string(permissions[i].group!);
            fm.push_u8(permissions[i].fWrites);
        }
    }
    fm.push_u8(fEnabled);						// Admin status must be provided
    fm.push_u8(fWizard);						// Wizard Status
    fm.push_u8(fTagConfig);						// Tag Configuration
    fm.push_u8(fDevConfig);						// Device Configuration
    fm.push_u8(fAdmin);							// Admin status must be provided
    return new Promise<boolean>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(parseAccountManagementResponse(fp))));
}

function parseAccountManagementResponse(fp: FrameParser): boolean {
    let command = fp.pop_u16();		// This is the account managament command that whoville responded to
    let fResult = fp.pop_u8() === 1;		// Whether or not the change was made
    return fResult;
}

function buildAccountManagementFrame(subCommand: LiveData): FrameMaker {
    let fm = new FrameMaker();
    fm.buildFrame(LiveData.WVC_ACCOUNT_MANAGEMENT);
    fm.push_u16(subCommand);
    return fm;
}

export function getCompanies(): Promise<Company[]>  {		// Get a list of all the companies
    if (!User.isPowerUser())	// Have to be a power user to use this method
        return new Promise<Company[]>((resolve, reject) => reject('User does not have appropriate permissions'));			// Not sending the message
    let fm = new FrameMaker();
    fm.buildFrame(LiveData.WVC_COMPANIES);
    return new Promise<Company[]>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(parseCompaniesResponse(fp))));
}

export function getCompanyUsers(companyKey: string): Promise<UserInfo[]> {	// Get a list of all the users for a company & their attributes
    if (!User.isAdmin())				// Have to be at least an admin to use this method
        return new Promise<UserInfo[]>((resolve, reject) => reject('User does not have appropriate permissions'));			// Not sending the message
    let fm = new FrameMaker();
    fm.buildFrame(LiveData.WVC_USERS);
    fm.push_string(companyKey);	// Add the company key they wanted users for
    return new Promise<UserInfo[]>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(parseCompanyUsersResponse(fp))));
}

export function getUserList(companyKey: string): Promise<UserListInfo[]> {		// Get a list of all the users for a company
    let fm = new FrameMaker();
    fm.buildFrame(LiveData.WVC_GET_USER_LIST);
    fm.push_string(companyKey);	// Add the company key they wanted users for
    return new Promise<UserListInfo[]>(resolve => LiveDataClient.sendRequest(fm).then(fp => resolve(parseUserListResponse(fp))));
}

function parseUserListResponse(fp: FrameParser): UserListInfo[] {
    let userCount = fp.pop_u16();
    let users: UserListInfo[] = [];
    for (let i = 0; i < userCount; ++i) {
        users.push({
            username: fp.pop_string(),
            firstName: fp.pop_string(),
            lastName: fp.pop_string()
        });
    }
    return users;
}

function parseCompaniesResponse(fp: FrameParser): Company[] {
    let companies: Company[] = [];			// Array to hold all of our companies
    let count = fp.pop_u16();	// How many companies are attached
    for (let i = 0; i < count; ++i) {
        companies.push({			// Create a new company object
            name: fp.pop_string(),	// Give each company a name
            key: fp.pop_string(),	// Give each company a key
        });
    }
    return companies;
}

function parseCompanyUsersResponse(fp: FrameParser): UserInfo[] {
    let users: UserInfo[] = [];	// Array to hold all of the users for the company
    let count = fp.pop_u16();	// How many users are attached
    for (let i = 0; i < count; ++i) {
        let user: UserInfo = {};						// Create a new user object
        user.userName = fp.pop_string();				// Give each user's name
        user.firstName = fp.pop_string();				// Give each user's first name
        user.lastName = fp.pop_string();				// Give each user's last name
        user.email = fp.pop_string();				// Give each user's email address
        user.phone = fp.pop_string();				// Give each user's phone number
        user.countryCode = fp.pop_u16();					// Give each user's country code
        let permissionCount = fp.pop_u16();
        user.permissions = [];
        for (var j = 0; j < permissionCount; ++j) {
            let group = fp.pop_string();
            let fWrites = fp.pop_bool();
            user.permissions.push({ group: group, fWrites: fWrites });
        }
        user.fEnabled = fp.pop_u8() ? true : false;	// Give each user's enabled status
        user.fWizard = fp.pop_u8() ? true : false;
        user.fTagConfig = fp.pop_u8() ? true : false;
        user.fDevConfig = fp.pop_u8() ? true : false;
        user.fAdmin = fp.pop_u8() ? true : false;	// Give each user's admin status
        user.callouts = fp.pop_string();
        user.failover = fp.pop_string();
        user.failMinutes = fp.pop_u8();
        user.lowSev = fp.pop_u16();
        user.highSev = fp.pop_u16();

        let intervalCount = fp.pop_u16();
        user.intervals = [];
        for (var j = 0; j < intervalCount; ++j) {
            let interval: CalloutInterval = {
                day: fp.pop_u8(),
                start: fp.pop_u16(),
                end: fp.pop_u16()
            }
            user.intervals.push(interval);
        }
        user.fTwoFactorEnabled = fp.pop_u8() ? true : false;	// Give each user's two-factor status
        user.lastLogin = fp.pop_u64();					// Give each user's time of last Login
        user.loginCount = fp.pop_u64();					// Give each user's login count
        user.smsSubscriptionStatus = fp.pop_u8();
        user.notificationMethod = fp.pop_u8();

        users.push(user);		// Add the user object to the array
    }
    return users;
};
