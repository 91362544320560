import * as elements from '../elements';
import {monthStrings} from '../timezone';
import CloseIcon from '../images/icons/close.svg'
import './reportview.css';
import View from './view';
import FrameMaker from '../framemaker';
import LiveData from '../livedata';
import LiveDataClient from '../livedataclient';

export default class ReportView extends View {
    constructor(device, ldc) {
		super();
		this.device 	= device;
        this.deviceID   = device.id;
        this.ldc        = ldc;
        this.reports    = [];
	}

	initialize(parent) {
		super.initialize(parent);
		this.wrapper		= elements.createElement('div', 'reports-tab__wrapper', this.parent);		// Wrapper for report list and PDF area
		this.listContainer	= elements.createElement('div', 'reports-tab__list-container', this.wrapper)
		this.list			= elements.createElement('div', 'reports-tab__list-container__list', this.listContainer);		// The list we will show the reports we can fetch
		this.embedContainer = elements.createElement('div', 'reports-tab__embed-container', this.wrapper);
		let closecontainer	= elements.createElement('div', 'reports-tab__close', this.embedContainer);
		this.closeButton	= elements.createElement('img', 'reports-tab__close__button', closecontainer, null, {'src':CloseIcon})
		this.embed			= elements.createElement('embed', 'reports-tab__embed-container__iframe', this.embedContainer, undefined, {'type':'application/pdf'});				// This embed element loads the PDF reader

		this.closeButton.onclick = () => this.listContainer.classList.remove('hide');
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_GET_REPORT_LIST, this.deviceID);
		LiveDataClient.sendRequest(fm).then(fp => {
			var siteReports = [];
			var count = fp.pop_u16();		// Count of site specific reports
			for (var i = 0; i < count; ++i) {	// For each report
				var year = fp.pop_u16();		// Extract the year
				var month = fp.pop_u8();		// Extract the month
				var day = fp.pop_u8();		// Extract the day
				siteReports.push({ month: month, year: year, day: day });
			}
			var systemReports = [];
			var count = fp.pop_u16();		// Count of site specific reports
			for (var i = 0; i < count; ++i) {	// For each report
				var year = fp.pop_u16();		// Extract the year
				var month = fp.pop_u8();		// Extract the month
				var day = fp.pop_u8();		// Extract the day
				systemReports.push({ month: month, year: year, day: day });
			}
			this.onReportListData(siteReports, systemReports);
		})
		this.fInitialized = true;
		return this;
	}

	onReportListData(siteReports, summaryReports) {	// Report data list response
		this.list.removeChildren();
		for (var i = 0; i < siteReports.length; ++i)	// For each report
			this.createReportRow(siteReports[i].year, siteReports[i].month, siteReports[i].day, false);	// Create a clickable row
		for (var i = 0; i < summaryReports.length; ++i)	// For each report
			this.createReportRow(summaryReports[i].year, summaryReports[i].month, summaryReports[i].day, true);	// Create a clickable row
		if (siteReports.length ==  0 && summaryReports.length == 0) {
			elements.createElement('div', 'reports-tab__list-container__list__item', this.list, 'No Reports Found');
		}
		/*
		if (this.list.firstChild)
			this.list.firstChild.click();
			*/
	}

	createReportRow(year, month, day, fSystem) {
		var row		= elements.createElement('div', 'reports-tab__list-container__list__item');	// Create a row in the list wrapper
		row.year 	= year;		// Save the details of the report this represents on the HTML element
		row.month	= month;
		row.day		= day;
		row.fSystem	= fSystem;

		//var string = monthStrings[month - 1] + (day == 0 ? ' ' : ' ' + day + ' ') + year + (fSystem ? ' System Summary' : ' Station Report');
		var string = monthStrings[month - 1] + (day == 0 ? ' ' : ' ' + day + ' ') + year + (fSystem ? ' System Summary' : ' Station Report');

		elements.createElement('div', 'reports-tab__list-container__list__item__label', row, string);
		//elements.createElement('img', 'reports-tab__list-container__list__item__icon', row, null, {'src':ArrowIcon});

		row.onclick = () => this.requestReport(row);	// Call the ReportsTab object with a reference to the HTML element

		for (var j = 0; j < this.list.children.length; ++j) {	// Check where we should attach the new row
			var child = this.list.children[j];
			if ((year > child.year) || 										// If this report is an newer year
				(year == child.year && month > child.month) ||				// If this report is same year, newer month
				(year == child.year && month == child.month && day > child.day) ||
				(year == child.year && month == child.month && fSystem)) {	// If this report is same year, same month, but a system summary
				this.list.insertChildAt(row, j);							// Attach our new row before this row
				break;														// Row is attached, no need to look further
			}
		}
		if(!row.parentElement)			// If we didn't attach the new row
			this.list.appendChild(row);	// Just append him at the end
	}

	requestReport(report) {	// This is called in the anonymous functions attached to each row's onclick callback
		if (this.report)									// If we already have a selected report
			this.report.setAttribute('selected', false);	// No longer show it as selected
		this.report = report;
		this.report.setAttribute('selected', true);			// Show our new clicked row as selected

		var fMobile = window.innerWidth < 620;
		if (fMobile) {
			this.listContainer.classList.add('hide');
		}

		for (var i = 0; i < this.reports.length; ++i) {	// Check all our cached reports
			var r = this.reports[i];
			if (r.year == report.year && r.month == report.month && r.day == report.day && r.fSystem == report.fSystem) {	// If all the parameters match
				this.updateEmbeddedReport(r);	// Bring back the report we have already
				return;							// No need to request a new report
			}
		}

		let fm = new FrameMaker();
		fm.buildFrame(LiveData.WVC_GET_REPORT, this.deviceID);
		fm.push_u16(report.year);
		fm.push_u8(report.month);
		fm.push_u8(report.day);
		fm.push_u8(report.fSystem ? 1 : 0);
		LiveDataClient.sendRequest(fm).then(fp => {
			var year = fp.pop_u16();	// Extract the data about this report
			var month = fp.pop_u8();
			var day = fp.pop_u8();
			var fSystem = fp.pop_u8() == 1;
			var blob = new Blob([fp.frame.buffer], { type: 'application/pdf' });	// Create a new PDF blob
			this.onReportData(year, month, day, fSystem, blob);	// Give whoever asked for it the report data
			fp.skip(fp.size());				// Skip the frames so the LDC doesn't complain
		});
	}

	onReportData(year, month, day, fSystem, blob) {
		blob.year		= year;			// Make the blob understand what it represents (for caching)
		blob.month		= month;
		blob.day		= day;
		blob.fSystem	= fSystem;
		this.reports.push(blob);		// Add this report to our list
		this.updateEmbeddedReport(blob);
	}

	updateEmbeddedReport(blob) {
		this.embed.height = this.embed.clientHeight;
		this.embed.width = this.embed.clientWidth;
		this.embed.src = URL.createObjectURL(blob);
	}

	destroy() {
		this.parent.removeChildren();	// Delete all child DOM elements:
	}
}