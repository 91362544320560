import Icon from '../../../../images/icons/name.svg';
import { RegisterWidget, Widget } from '../../../lib/widget';
import template from './pumpcurve.html';
import { Attribute } from '../../../lib/attributes';
import { Tag, TagAttribute, TagQuality, type TagDefinition } from '../../../lib/tag';
import { Role } from '../../../../role';
import { findOperationRanges } from '../../../../pump';
import { type PumpTwin, type PumpCurves, PumpTwins, findBestEfficiencyFlow } from '../../../../curves';
import { StaticGraph } from '../../../../graph';
import { TagUnit, TagUnitQuantity, convert } from '../../../lib/tagunits';
import LiveDataClient from '../../../../livedataclient';

@RegisterWidget({tag: 'pump-curve', template: template, displayName: 'Pump Curve', icon: Icon, section: 'Pumps'})
export class PumpCurve extends Widget {
    // User-defined Tags
    @TagAttribute({displayName: 'Pump Folder'}) pumpFolder: TagDefinition;
    @TagAttribute({displayName: 'Model Folder'}) modelFolder: TagDefinition;

    // User defined maximum speed
    @Attribute({displayName: 'Max Shutoff Head'}) maxShutoffHead: number;
    @Attribute({displayName: 'Max Shutoff Head'}) maxZeroHeadFlow: number;

    flowTag: Tag;
    headTag: Tag;
    powerTag: Tag;
    minBEPTag: Tag;
    maxBEPTag: Tag;
    minAORTag: Tag;
    maxAORTag: Tag;
    fUseAORTag: Tag;
    runningTag: Tag;
    actSpeedTag: Tag;
    minSpeedTag: Tag;
    maxSpeedTag: Tag;
    healthInputTag: Tag;
    efficiencyTag: Tag;

    private width: number;
    private height: number;
    private maxSpeed: number;
    private flowLabel: HTMLElement;
    private headLabel: HTMLElement;
    private effLabel: HTMLElement;
    private flowConversion: number;
    private headConversion: number;
    private factor: number;
    private badFlows: number[]  = [];
    private badHeads: number[]  = [];
    private badMaxes: number[]  = [];	// Outside AOR shaded region
    private aorFlows: number[]  = [];
    private aorHeads: number[]  = [];
    private aorMaxes: number[]  = [];	// AOR shaded region
    private porFlows: number[]  = [];
    private porHeads: number[]  = [];
    private porMaxes: number[]  = [];	// POR shaded region
    private bepFlows: number[]  = [];
    private bepHeads: number[]  = [];	// Line over BEP
    private fsFlows: number[]   = [];
    private fsHeads: number[]   = [];
    private fsPowers: number[]  = [];	// Full speed flow head and power. Used to compute actual speed stuff
    private asFlows: number[]   = [];
    private asHeads: number[]   = [];
    private asEffs: number[]    = [];   // Actual speed flow, head, and efficiency lines
    private cFlow: number[]     = [NaN];
    private cHead: number[]     = [NaN];
    private cEff: number[]      = [NaN];// Current flow and efficieny points
    private headLineFlow: number[]	= [0, 0];
    private headLineHead: number[]  = [0, 0];	    // Horizontal, dashed head line
    private effLineFlow: number[]	= [0, 0];
    private effLineHead: number[]	= [0, 0];	    // Horizontal, dashed efficiency line
    private verFlows: number[]		= [0, 0];
    private verHeads: number[]		= [0, 100];	    // Vertical, dashed flow line
    private data: ((number | string)[] | null)[];
    private options: any;
    private isAboutToRedraw: boolean = false;
    private isAboutToRefresh: boolean = false;

    private curves: PumpCurves;
    private twin: PumpTwin;
    private graphWrapper: HTMLDivElement;
    private graph: StaticGraph; // TODO: Extract this to a widget please
    jobCompleteCallback: ()=>void;

    protected enliven(): void {
        this.width          = this.clientWidth;
        this.height         = this.clientHeight;

        this.flowLabel = this.shadowRoot?.getElementById('flow-label') as HTMLDivElement;
        this.headLabel = this.shadowRoot?.getElementById('head-label') as HTMLDivElement;
        this.effLabel = this.shadowRoot?.getElementById('eff-label') as HTMLDivElement;
        this.graphWrapper = this.shadowRoot?.getElementById('graph') as HTMLDivElement;

        this.flowTag        = this.modelFolder.tag.findChildByRole(Role.ROLE_MODEL_PUMP_FLOW)!
        this.headTag        = this.modelFolder.tag.findChildByRole(Role.ROLE_MODEL_PUMP_HEAD)!
        this.powerTag		= this.pumpFolder.tag.findChildByRole(Role.ROLE_SHAFT_POWER)!
        this.efficiencyTag  = this.modelFolder.tag.findChild("PumpEfficiency")!;
        this.minBEPTag		= this.pumpFolder.tag.findChildByRole(Role.ROLE_PUMP_MIN_BEP_RATIO)!
        this.maxBEPTag		= this.pumpFolder.tag.findChildByRole(Role.ROLE_PUMP_MAX_BEP_RATIO)!
        this.minAORTag		= this.pumpFolder.tag.findChildByRole(Role.ROLE_PUMP_MIN_AOR)!
        this.maxAORTag		= this.pumpFolder.tag.findChildByRole(Role.ROLE_PUMP_MAX_AOR)!
        this.fUseAORTag		= this.pumpFolder.tag.findChild("UseAOR")!
        this.runningTag		= this.pumpFolder.tag.findChildByRole(Role.ROLE_BOOL_RUNNING)!
        this.actSpeedTag	= this.pumpFolder.tag.findChildByRole(Role.ROLE_ACT_SPEED)!			// Might not exist. We'll check later
        this.minSpeedTag	= this.pumpFolder.tag.findChildByRole(Role.ROLE_MIN_SPEED)!
        this.maxSpeedTag	= this.pumpFolder.tag.findChildByRole(Role.ROLE_MAX_SPEED)!
        this.healthInputTag = this.pumpFolder.tag.findChildByRole(Role.ROLE_PUMP_HEALTH_METRIC_INPUT)! // Optional

        this.subscribeToTags([this.flowTag, this.headTag, this.powerTag, this.minBEPTag, this.maxBEPTag, this.minAORTag, this.maxAORTag, this.fUseAORTag, this.runningTag]);
        if (this.healthInputTag)
            this.subscribeToTag(this.healthInputTag);
        if (this.minSpeedTag)
            this.subscribeToTag(this.minSpeedTag);
        if (this.maxSpeedTag)
            this.subscribeToTag(this.maxSpeedTag);
        if (this.actSpeedTag)
            this.subscribeToTag(this.actSpeedTag);
        if (this.efficiencyTag)
            this.subscribeToTag(this.efficiencyTag);

        let pump = this.pumpFolder.tag;

        let maxSpeedValue   = this.maxSpeedTag.getValue();
        this.maxSpeed		= convert(maxSpeedValue, this.maxSpeedTag.units, pump.device.tree.unitsMap.get(TagUnitQuantity.TUQ_FREQUENCY) ?? TagUnit.TU_HZ, maxSpeedValue);
        this.flowConversion	= convert(1, TagUnit.TU_GPM, this.flowTag.units);
        this.headConversion	= convert(1, TagUnit.TU_FEET_HEAD, this.headTag.units);
        this.factor	        = convert(1.8866337e-2 / this.flowConversion / this.headConversion, TagUnit.TU_KW, this.powerTag.units);
        this.badFlows       = [],       this.badHeads = [],   this.badMaxes = [];	// Outside AOR shaded region
        this.aorFlows       = [],       this.aorHeads = [],   this.aorMaxes = [];	// AOR shaded region
        this.porFlows       = [],       this.porHeads = [],   this.porMaxes = [];	// POR shaded region
        this.bepFlows       = [],       this.bepHeads = [];						    // Line over BEP
        this.fsFlows        = [],       this.fsHeads  = [],   this.fsPowers = [];	// Full speed flow head and power. Used to compute actual speed stuff
        this.asFlows        = [],       this.asHeads  = [],   this.asEffs   = [];   // Actual speed flow, head, and efficiency lines
        this.cFlow          = [NaN],    this.cHead    = [NaN], this.cEff     = [NaN];// Current flow and efficieny points
        this.headLineFlow	= [0, 0],   this.headLineHead		= [0, 0];	    // Horizontal, dashed head line
        this.effLineFlow	= [0, 0],   this.effLineHead		= [0, 0];	    // Horizontal, dashed efficiency line
        this.verFlows		= [0, 0],   this.verHeads			= [0, 100];	    // Vertical, dashed flow line

        this.data = [['notPOR', 'AOR', 'POR', 'BEP', 'HeadLine', 'EffLine', 'Vert', 'Head', 'Eff', 'CurrentHead', 'CurrentEff'],
                    this.badFlows,		null,	this.badHeads,		this.badMaxes,
                    this.aorFlows,		null,	this.aorHeads,		this.aorMaxes,
                    this.porFlows,		null,	this.porHeads,		this.porMaxes,
                    this.bepFlows,		null,	this.bepHeads,		null,
                    this.headLineFlow,	null,	this.headLineHead,	null,
                    this.effLineFlow,	null,	this.effLineHead,	null,
                    this.verFlows,		null,	this.verHeads,		null,
                    this.asFlows,		null,	this.asHeads,		null,
                    this.asFlows,		null,	this.asEffs,		null,
                    this.cFlow,		    null,	this.cHead,			null,
                    this.cFlow,		    null,	this.cEff,			null];

        var dottedOptions = {strokeWidth: 1, color: 'red'};	// Thinner line, dashed, and round to nearest half pixel for clarity
        this.options = {								// Dygraph options for all pump curve graphs
            stophighlighting:		true,				// No highlighting
            colors:					['#EF5350', 'gold', '#2E7D32', '#2E7D32', '#555', '#555', '#555', 'dodgerblue', 'red', 'dodgerblue', 'red'],	// Colors
            customAxis: 			[false, false, false, false, false, true, true, false, true, false, true],	// Plot all the efficiencies on the right axis
            pointSize:				0,					// How big to make the points
            xAxisAsNumber: 			true, 				// Interpret the x-axis as numbers, not time stamps
            strokeWidth:			1.5,				// How wide to make the lines
            secondAxisRange:		[0, 100],			// Efficiencies are scaled 0-100
            drawXAxis:				false,				// Don't draw any axes liknes
            drawYAxis:				false,
            drawYGrid:              false,
            drawXGrid:              false,
            rightGap:				0,					// No gap to the right of the graph
            fillAlpha:              0.4,
            HeadLine:				dottedOptions,		// Show these lines as dashed
            EffLine:				dottedOptions,
            Vert:					dottedOptions,
            POR: 					{connectSeparatedPoints: false},	// Show these fills without a line
            AOR:                    {connectSeparatedPoints: false},    // Show these fills without a line
            notPOR: 				{connectSeparatedPoints: false},	// Show these fills without a line
            CurrentHead:			{drawPoints: true, pointSize: 4},	// Show the dot for current head
            CurrentEff:				{drawPoints: true, pointSize: 4}    // Show the dot for current efficiency
        };

        pump.device.requestPumpTwins(this);
    };

    onPumpTwinsComplete(pumpTwins: PumpTwins) {
        this.refreshCurves(pumpTwins);
    }

    refreshCurves(pumpTwins: PumpTwins) {
        if (this.isAboutToRefresh)
            return;
        this.isAboutToRefresh = true;
        queueMicrotask(() => {
            this.isAboutToRefresh = false;
            this.twin   = pumpTwins.getTwin(this.pumpFolder.tag)!;
            this.curves = this.twin.latestCurves;
            let maxShutoffHead = this.maxShutoffHead ?? this.twin.shutoffHead;
            let maxZeroHeadFlow = this.maxZeroHeadFlow ?? this.twin.zeroHeadFlow;
            this.options.valueRange = [0, maxShutoffHead];
            this.options.dateWindow = [0, maxZeroHeadFlow];

            let headPoly: number[] = [];
            let powerPoly: number[] = [];
            if (this.healthInputTag) {
                let WF = this.healthInputTag.getValue() / 100;
                for (let i = 0; i < this.curves.headPolynomial.length; ++i)
                    headPoly.push(this.curves.headPolynomial[i] * Math.pow(WF, 2 - i));
                headPoly[1] -= (1 - WF) * headPoly[0] / maxZeroHeadFlow;
                for (let i = 0; i < this.curves.powerPolynomial.length; ++i)
                    powerPoly.push(this.curves.powerPolynomial[i] * Math.pow(WF, 2 - i));
            }
            else {
                headPoly = this.curves.headPolynomial;
                powerPoly = this.curves.powerPolynomial;
            }


            let bepFlow = findBestEfficiencyFlow(this.twin.zeroHeadFlow, headPoly, powerPoly);  // Calculate the bep flow

            this.fsFlows.length = this.fsHeads.length = this.fsPowers.length = 0;
            for (let flow = 0; flow <= this.twin.zeroHeadFlow*1.01; flow += this.twin.zeroHeadFlow / 100) {	// Compute the full speed curves
                this.fsFlows.push(flow);											    // Save flow
                this.fsHeads.push(headPoly.evaluatePolynomial(flow));		// Calculate the head at this flow
                this.fsPowers.push(powerPoly.evaluatePolynomial(flow));		// Calculate the power at this flow
            }
            this.effLineFlow[1] = maxZeroHeadFlow;

            this.badFlows.length = this.badHeads.length = this.badMaxes.length = 0;	// Clear out any previously calculated regions
            this.aorFlows.length = this.aorHeads.length = this.aorMaxes.length = 0;
            this.porFlows.length = this.porHeads.length = this.porMaxes.length = 0;
            this.bepFlows.length = this.bepHeads.length = 0;

            let minS = this.minSpeedTag ? Math.min(this.minSpeedTag.getValue() / this.maxSpeed, 0.975) : 0.975;	// Min speed as a ratio
            let maxS = this.maxSpeedTag ? this.maxSpeedTag.getValue() / this.maxSpeed : 1.0;						// Max speed as a ratio
            let minP = this.minBEPTag.getValue();
            let maxP = this.maxBEPTag.getValue();
            let fAor = this.fUseAORTag && this.fUseAORTag.getValue();
            let minA = fAor ? Math.min(this.minAORTag.getValue(), minP) : minP;
            let maxA = fAor ? Math.max(this.maxAORTag.getValue(), maxP) : maxP;

            let bepHead = headPoly.evaluatePolynomial(bepFlow);	// Calculate BEP line
            for (let speed = minS; speed <= maxS; speed += 0.01) {
                this.bepFlows.push(speed*bepFlow);
                this.bepHeads.push(speed*speed*bepHead);
            }

            findOperationRanges(this.porFlows, this.porHeads, this.porMaxes, headPoly, powerPoly, this.twin.zeroHeadFlow, minP, maxP, minS, maxS);
            findOperationRanges(this.aorFlows, this.aorHeads, this.aorMaxes, headPoly, powerPoly, this.twin.zeroHeadFlow, minA, minP, minS, maxS);
            findOperationRanges(this.badFlows, this.badHeads, this.badMaxes, headPoly, powerPoly, this.twin.zeroHeadFlow, 0, minA, minS, maxS);
            this.badFlows.push(NaN);	// These are put in each array so the two outside POR sections aren't connected
            this.badHeads.push(NaN);
            this.badMaxes.push(NaN);
            this.aorFlows.push(NaN);
            this.aorHeads.push(NaN);
            this.aorMaxes.push(NaN);
            findOperationRanges(this.aorFlows, this.aorHeads, this.aorMaxes, headPoly, powerPoly, this.twin.zeroHeadFlow, maxP, maxA, minS, maxS);
            findOperationRanges(this.badFlows, this.badHeads, this.badMaxes, headPoly, powerPoly, this.twin.zeroHeadFlow, maxA, this.twin.zeroHeadFlow / bepFlow, minS, maxS);
        })
        this.redraw();
    }

    update(tag: Tag): void {
        if (tag.quality != TagQuality.TQ_GOOD && (tag !== this.flowTag && tag !== this.headTag && tag !== this.powerTag)) {   // Bad quality on this node. Let model nodes fix themselves
            return;
        }
        if (this.fsFlows.length == 0) {			// If we don't have the full speed line yet
            // console.log('pump curve widget, no full speed line')
            return;								// Bail out
        }

        let shouldRedraw = false;

        switch (tag) {	// Switch on node that is updating
            case this.flowTag:
                shouldRedraw = true;
                this.cFlow[0] = this.flowTag.getValue();
                this.verFlows[0] = this.verFlows[1] = this.headLineFlow[1] = this.effLineFlow[0] = this.cFlow[0];
                this.flowLabel.textContent = this.flowTag.getFormattedText(true);
                //this.bepLabel.textContent = (100*this.cFlow[0] * factor / this.pump.pumpCurve.bepFlow).toFixed(1)+'% BEP';
            break;

            case this.headTag:
                shouldRedraw = true;
                this.cHead[0] = this.headTag.getValue();
                this.headLineHead[0] = this.headLineHead[1] = this.cHead[0];
                this.headLabel.textContent = this.headTag.getFormattedText(true);
            break;

            case this.efficiencyTag:
                shouldRedraw = true;
                this.cEff[0] = this.efficiencyTag.getValue();
                this.effLineHead[0] = this.effLineHead[1] = this.cEff[0];
                this.effLabel.textContent = this.efficiencyTag.getFormattedText(true);
            break;

            case this.actSpeedTag:
                shouldRedraw = true;
                break;
            case this.runningTag:
                var flowFactor	= this.actSpeedTag ? this.actSpeedTag.getValue() / this.maxSpeed : (this.runningTag.getValue() ? 1 : 0);	// Calculate the affinity law ratios once
                var headFactor	= flowFactor * flowFactor;
                var powerFactor	= headFactor * flowFactor;

                for (var i = 0; i < this.fsFlows.length; ++i){		// Recompute the points based on the speed factors
                    this.asFlows[i]	= flowFactor * this.fsFlows[i];	// Scale full speed flow by affinity laws
                    this.asHeads[i]	= headFactor * this.fsHeads[i];	// Scale full speed head by affinity laws
                    var power	= powerFactor * this.fsPowers[i];	// Scale full speed power by affinity laws
                    if (this.asFlows[i] > 0 && this.asHeads[i] > 0 && power > 0)	// If we are in a good place, calculate the efficiency
                        this.asEffs[i]	= this.asFlows[i] * this.asHeads[i] / convert(power, TagUnit.TU_KW, this.powerTag.units ) * this.factor;
                    else										// Not in a good place
                        this.asEffs[i]	= 0;						// Clamp efficiency to 0
                }
            break;

            case this.minSpeedTag:	// I expect these nodes to change very, very rarely. Hence last in the switch
            case this.maxSpeedTag:
            case this.minBEPTag:
            case this.maxBEPTag:
            case this.minAORTag:
            case this.maxAORTag:
            case this.fUseAORTag:
            case this.healthInputTag:
                tag.device.requestPumpTwins(this);
            break;
        }
        if (shouldRedraw)
            this.redraw();
    };

    onNodeChanged(tag: Tag): void {
        this.flowConversion	= convert(1, TagUnit.TU_GPM, this.flowTag.units);
        this.headConversion	= convert(1, TagUnit.TU_FEET_HEAD, this.headTag.units);
        this.factor	        = 1.8866337e-2 / this.flowConversion / this.headConversion;
        this.pumpFolder.tag.device.requestPumpTwins(this);
    }

    protected onResize(): void {
        this.resize();
    }

    resize() {
        if (this.clientHeight == 0)
            return;


        this.redraw();
    }

    redraw() {
        if (this.isAboutToRedraw)
            return;

        this.width          = this.clientWidth;
        this.height         = this.clientHeight;
        this.isAboutToRedraw = true;
        queueMicrotask(() => {
            this.isAboutToRedraw = false;
            if (this.graph)	// If we've drawn a graph before
                this.graph.destroy(); // Delete it first

            this.graph = new StaticGraph(LiveDataClient, this.graphWrapper, this.width, this.height, this.data, this.options);		// Redraw the graph
            if (this.graph.graph?.plotter_) {
                this.flowLabel.style.left = (this.graph.graph.toDomXCoord(this.cFlow[0])+3) + 'px';		// Update label poistions
                this.headLabel.style.top = (this.graph.graph.toDomYCoord(this.cHead[0])-17) + 'px';
                //this.bepLabel.style.left = this.flowLabel.style.left;
                this.effLabel.style.top = (this.graph.graph.toDomYCoord(this.cEff[0], 1)-17) + 'px';
            }
        });
    }
};