import './dashboardviewerpage.css';
import Page from "./page";
import DashboardView from '../views/widgetdashboardview';
import Dialog from "../dialog";
import owner, { Routes } from "../../owner";
import { createElement } from "../elements";
import FrameParser from '../frameparser';
import { getHash, getRouteAndProperties } from '../router/router';
import LiveDataClient from '../livedataclient';
import User from '../user';

interface DashboardViewerPageProps {
    id?: number;
}

export default class WidgetViewerPage extends Page {
    view: DashboardView;
    props: DashboardViewerPageProps;
    graphID: number;
    constructor(parent: HTMLElement, props?: DashboardViewerPageProps) {
        super(parent);
        this.props      = props ?? {};
        this.graphID    = LiveDataClient.registerGraph(this);
        if (this.props.id) { // if we are provided a dashboard id from our route, ask for it from wv
            let dashboardPageWrapper 	= createElement('div', 'dashboard-viewer__page__page-wrapper', this.parent);
			let dashboardSubNavbar	 	= createElement('div', 'dashboard-viewer__page__sub-nav', dashboardPageWrapper);
			let dashboardEditButton	 	= createElement('div', 'dashboard-viewer__page__sub-nav__edit-button', dashboardSubNavbar, 'Edit');
			dashboardEditButton.onclick = () => {
				window.location.href 	= getHash(Routes.WidgetCreator, {'id': this.props.id!.toString()});		// Navigate to the secret editor
			}
			let dashboardReportsButton	 = createElement('div', 'dashboard-viewer__page__sub-nav__edit-button', dashboardSubNavbar, 'Reports');
			dashboardReportsButton.onclick = () => {
				dashboardReportsButton.textContent = this.view.showReports() ? 'Dashboard' : 'Reports';
			}
			let dashboardWrapper 	 	= createElement('div', 'dashboard-viewer__page__wrapper', dashboardPageWrapper);
            this.view				 	= new DashboardView(LiveDataClient, this.props.id, undefined, ()=> {
                if (this.view.dashboard && this.view.dashboard.name)
                    owner.navBar.setTitle(this.view.dashboard.name);
            }).initialize(dashboardWrapper);
        }
        else { // if we are not provided a dashboard id
            LiveDataClient.getDashboards(this.graphID, owner.menuPanel.getCompanyKey());
            /*
            let dialogProperties = {
                title: 'Failed to Load Dashboard',
                body:  'Please check your url and try again.',
            }
            new Dialog(document.body, dialogProperties);
            */
        }
        addEventListener('resize', this.onResize.bind(this));
    };

    onDashboardsResponse(fp: FrameParser) {
        let count = fp.pop_u32();
        if (count < 1) {
            let dialogProperties = {
                title: 'No HMIs Yet',
                body:  'You don\'t have access to any dashboards and haven\'t created any of your own. Would you like to create one now?',
                buttons: [
                    {'title':'Create New', callback: location.hash = getHash(Routes.Creator)},
                    {'title':'Return'}
                ]
            }
            new Dialog(document.body, dialogProperties);
            return;
        }
        for (let i=0;i<count;i++) {
            let id          = fp.pop_u32();
            let name        = fp.pop_string();
			let version     = fp.pop_u16();		// version

            fp.pop_string();	// creator
            let deviceCount = fp.pop_u32();
			for (let j=0;j<deviceCount;++j) {
                let key = fp.pop_string();
                let device = User.devices.getByKey(key);
                if (device)
                    device.dashboards.set(name, {version: version, id: id});
            }
            if (version < 2)
                continue;
            location.hash = getHash(...getRouteAndProperties(location.href, {'id': id.toString()}));
            return;
        }
    }

    setProps(props: DashboardViewerPageProps) {
        this.props = props;
        this.refresh();
    }

    onResize() {
        this.view.resize();
    }

    destroy() {
        removeEventListener('resize', this.onResize.bind(this));
        if (this.view)
            this.view.destroy();
        super.destroy();
    }
}
