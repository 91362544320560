import { Attribute } from '../../../lib/attributes';
import { TagAttribute } from '../../../lib/tag';
import { RegisterWidget, Widget } from '../../../lib/widget';
import type { TagDefinition } from "../../../lib/tag";
import template from './pumpbank.html';
import { Role } from '../../../../role';
import { type PumpCard } from '../pumpcard/pumpcard';
import { ColorInput } from '../../../../views/attributeeditorview';

@RegisterWidget({tag: 'pump-bank', displayName: 'Pump Bank', section: 'Pumps', template: template})
export class PumpBank extends Widget {
    cards: HTMLDivElement;
    @Attribute({
        displayName: 'Background Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) backgroundColor: string = '#ffffff';
    @Attribute({
        displayName: 'Text Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip)
    }) textColor: string = '#1D1B20';
    @TagAttribute({displayName: 'Tag'}) pumpFolder: TagDefinition;

    protected connectedCallback(): void {
        this.cards = this.shadowRoot?.getElementById('cards') as HTMLDivElement;
    }

    protected enliven(): void {
        for (let pump of this.pumpFolder.tag.findByRole(Role.ROLE_PUMP)) {
            let card = document.createElement('pump-card') as PumpCard;
            card.pumpFolder = {tag: pump};
            this.cards.appendChild(card);
        }
    }
}