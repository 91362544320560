import Page             from "./page";
import owner            from "../../owner";
import LiveData         from '../livedata';
import './reportgeneratorpage.css';
import type FrameParser      from "../frameparser";
import LiveDataClient from "../livedataclient";

interface ReportInfo {
    name        : string;
    companyKey  : string;
    firstName   : string;
    lastName    : string;
    creator     : string;
    devices     : string[];
    uploadURL   : string;
    downloadURL : string;
    id          : number;
    size        : number;
    uuid        : string;
    frequency   : number;
    frequencyMod: number;
    emails      : string[];
    timezone    : string;
}

export default class ReportRequestPage extends Page {
    wrapper:    HTMLElement;
    graphID:    number;
    props:      {[key: string]: string};
    constructor(parent: HTMLElement, props: {[key: string]: string}) {
        super(parent);
        this.graphID = LiveDataClient.registerGraph(this);
        this.props   = props;
        if (typeof props.timestamp == 'undefined')
            return;
        LiveDataClient.fm.buildFrame(LiveData.WVC_GET_PENDING_REPORTS, undefined, this.graphID);
        LiveDataClient.fm.push_u64(this.props.timestamp);
        LiveDataClient.send();
    }

    onGetPendingReports(fp: FrameParser) {
        let reports: ReportInfo[] = [];
        let numReports  = fp.pop_u32();
        for (let i=0;i<numReports;++i) {
            let creator             = fp.pop_string();
            let companyKey          = fp.pop_string();
            let deviceCount         = fp.pop_u32();
            let devices: string[]   = [];
            for (let j=0;j<deviceCount;++j) {
                let deviceKey = fp.pop_string();
                devices.push(deviceKey);
            }
            let report: ReportInfo = {
                id          : fp.pop_u32(),
                name        : fp.pop_string(),
                firstName   : fp.pop_string(),
                lastName    : fp.pop_string(),
                frequency   : fp.pop_u8(),
                frequencyMod: fp.pop_u16(),
                size        : fp.pop_u8(),
                timezone    : fp.pop_string(),
                uploadURL   : fp.pop_string(),
                downloadURL : fp.pop_string(),
                uuid        : fp.pop_string(),
                creator     : creator,
                devices     : devices,
                companyKey  : companyKey,
                emails      : []
            }
            let emailCount = fp.pop_u32();
            for (let j=0;j<emailCount;++j) {
                report.emails.push(fp.pop_string());
            }
            report.emails.push(fp.pop_string());
            reports.push(report);
        }
        //@ts-ignore
        window.onReportInfoReceived(JSON.stringify(reports));
    }
}