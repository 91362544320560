import owner from '../../owner';
import Page from './page';
import * as elements from '../elements';
import './dashboardpage.css';
import BackArrow from '../images/icons/arrow_back.svg';
import ForwardArrow from '../images/icons/arrow_forward.svg';
import Enlivener from '../enlivener';
import { getRouteAndProperties } from '../router/router';
import LiveDataClient from '../livedataclient';
import User from '../user';

export default class DashboardPage extends Page {
    constructor(parent, props) {
        super(parent, props)

        console.log(parent.getWidth());
        this.parent         = parent;
        this.props          = {...props};
        this.environment    = {};
        this.ldc            = LiveDataClient;
        this.dashboards     = [];
        this.fileName       = this.props['filename'];
        console.log(this.fileName)
        this.fileNames      = this.ldc.customFiles;
        this.id             = this.ldc.registerGraph(this);
        this.companyKey     = User.companyKey;
        this.fileMap		= new Map();
        this.devices        = User.devices.sorted;
        this.index          = this.fileNames.indexOf(this.fileName);

        this.dispatchEvent(new CustomEvent('onNavTitleChanged', {detail: {title: 'Dashboards'}}));

        // Set up the environment variables so that the correct files can be called
	    if (!this.environment.train)	// FIXME: This is fucking hacky
            this.environment.train = '1';

        this.carouselContainer  = elements.createElement('div', 'dashboard__carousel',this.parent)
        this.carousel           = elements.createElement('div', 'dashboard__carousel__bar', this.carouselContainer);
        let leftArrowContainer  = elements.createElement('div', 'dashboard__carousel__bar__arrow-container', this.carousel)
        this.leftArrow          = elements.createElement('img', 'dashboard__carousel__bar__arrow', leftArrowContainer, null, {'src':BackArrow});
        this.selectedFileName   = elements.createElement('div', 'dashboard__carousel__bar__title', this.carousel);
        let rightArrowContainer = elements.createElement('div', 'dashboard__carousel__bar__arrow-container', this.carousel)
        this.rightArrow         = elements.createElement('img', 'dashboard__carousel__bar__arrow', rightArrowContainer, null, {'src':ForwardArrow});
        this.content            = elements.createElement('div', 'dashboard__carousel__content', this.carouselContainer);
        this.svgContainer       = elements.createElement('div', 'dashboard__carousel__content__svg', this.content);
        this.rightArrow.onclick = () => {
            if (this.index == 0) this.leftArrow.classList.remove('hide');
            if (this.index == this.fileNames.length - 1) this.rightArrow.classList.add('hide');
            this.index++;
            console.log(owner.router.currentRoute.name)
            location.hash = getHash(...getRouteAndProperties(location.hash, {'filename':this.fileNames[this.index]}));
        }
        this.leftArrow.onclick = () => {
            if (this.index == 0) return
            else if (this.index == 1) this.leftArrow.classList.add('hide');
            this.index--;
            location.hash = getHash(...getRouteAndProperties(location.hash, {'filename':this.fileNames[this.index]}));
        }
        this.buildSVG();
    }

    setProps(newProps) {
        const entries = Object.entries(newProps);
        for (const [key, value] of entries) {
            if (this.props[key] != value) { // check whether each property has changed
                this.props[key] = value;
                switch(key) {
                    case 'filename': // if the filename changes, destroy the old svg and build the new one
                        this.destroySVG();
                        this.index = this.fileNames.indexOf(this.props['filename']);
                        this.buildSVG();
                }
            }
        }
    };

    	// Refresh the custom file
	refreshFile() {
		if (this) {
			this.destroySVG();
			this.buildSVG();
		}
	}

    onCustomFileReceived(key, name, div) {
        this.fileMap.set(name, div);
        this.buildSVG();
    }

    destroy(softDestroy) {
        this.destroySVG();
		this.parent.destroyWidgets(true);
        this.parent.removeChildren();
	}

    //Expands innerElem to fill but not exceed outerElem's size.
    //Accepts a padding value that will shrink it slightly.
    scaleUpToFill(innerElem,outerElem,pad) {
		// Only used when dealing with a straight SVG
		// Shold ideally not be used for much in the future
        var irect = innerElem.getBoundingClientRect();
        var orect = outerElem.getBoundingClientRect();

        var fMobile = window.innerWidth < 768;


        var width, height;
        if(fMobile)
        {
            height = orect.height - pad;
            width = height * irect.width / irect.height;
        } else if (orect.height/orect.width > irect.height/irect.width){
            width = orect.width - pad;
            height = width * (irect.height / irect.width);
        }   else {
            height = orect.height - pad;
            width = height * irect.width / irect.height;
        }

        innerElem.style.width   = width + 'px';
        innerElem.style.height  = height+ 'px';
    }

    buildSVG() {
        this.destroySVG();
        console.log(this.index)
    	if (!this.fileMap.has(this.fileNames[this.index])) {
            this.ldc.getCustomFile(this.id, this.companyKey, this.fileNames[this.index]);		// Get the custom file
            return;
        }
    	this.selectedFileName.innerHTML = this.fileNames[this.index]
        //var file = this.fileMap.get(this.environment.file);
        var file = this.fileMap.get(this.fileNames[this.index])
        this.svgContainer.innerHTML = file.innerHTML;	// Set the embed's source
        // this.fSideScroll = SE.SVGWidget.readAttribute(file.children[0], "fsidescroll");
//		if (fileType == "image/svg+xml") {
		this.scaleUpToFill(this.svgContainer.children[0], this.svgContainer, 0);
//		}
		this.finalizeSVG(this.svgContainer);

        this.optionChangeEventListener = this.changeEnvironmentAndReload.bind(this);
        this.content.addEventListener('SEESVG-varchange',this.optionChangeEventListener);
    }

    //called when buildSVG is done
    //this is the shared codepath between
    //local file loading and network file loading
    finalizeSVG(newSVG){
        this.enlivener = new Enlivener(this.ldc);
        this.enlivener.enliven(newSVG,undefined);
    }

    destroySVG(){
        // First, detach all LiveData nodes from all DOM elements:
		this.svgContainer.destroyWidgets(true);

		// Second, delete all child DOM elements:
		this.svgContainer.removeChildren();

        this.svgContainer.removeEventListener('SEESVG-varchange',this.optionChangeEventListener);
		this.optionChangeEventListener = null;
    }

    changeEnvironmentAndReload(event){
        //console.log("SEE-SVG: Received event to change environment vars:",event.detail);
        this.destroySVG();
        for (var key in event.detail)
            this.environment[key] = event.detail[key];
        this.buildSVG();
    }

    resize() {
        if(this.svgContainer.children[0]) // if we have recieved the svg file yet
            this.scaleUpToFill(this.svgContainer.children[0], this.svgContainer, 20);
    }
};