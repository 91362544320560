import FrameMaker from './framemaker';
import LiveData from './livedata';
import assert from './debug';
import FrameParser from './frameparser';
import * as Sentry from "@sentry/browser";

export class LDCResponse extends Promise<FrameParser> {
	private abortController: AbortController;
	constructor(executor: (resolve: (value: FrameParser | PromiseLike<FrameParser>) => void, reject: (reason?: any) => void) => void) {
		const abortController = new AbortController();
		const { signal } = abortController;
		super((resolve, reject) => {
			signal.addEventListener('abort', () => reject('Aborted by awaiter'));
			executor(value => { resolve(value) }, reject);
		});
		this.abortController = abortController;
	}

	cancel() {
		this.abortController.abort();
	}
}

export enum LoginStatus {
	//Static variables (0-3 match WVC_LOGIN command status):
	INVALID_PASSWORD = 0,		// Invalid username/password combination submitted to server
	LOGGED_OUT = 1,		// User is logged out of the server
	LOGGED_IN = 2,		// User is logged in to the server
	CONNECT_FAILED = 3,		// Failed to connect to server
	CONNECTION_LOST = 4,		// Connection to server lost
	OUT_OF_DATE = 5,		// Our javascript is old
	TWOFACTOR_INIT = 6,		// User is creating a 2fa token
	TWOFACTOR_VERIFY = 7,		// User is undergoing 2fa
	PASSWORD_RESET = 8,		// User is resetting an expired password
	LOGGED_IN_WITH_WEAK_PASSWORD = 9,		// *Not a WVC_LOGIN command - a client-side only status that signals the client's password does not meet current security requirements and needs changing
	FORGOT_PASSWORD = 10,		// *Not a WVC_LOGIN command - user is submitting their username to request a password reset link be emailed to them
	FORGOT_PASSWORD_RESET = 11,		// *Not a WVC_LOGIN command - user is submitting a password reset token and a new, proposed password
	FORGOT_PASSWORD_RESET_SUCCESS = 12,		// *Not a WVC_LOGIN command - user successfully reset their password with a token
	TIMED_OUT = 13, 		// No response from whoville for a predetermined amount of time
	RECONNECTING = 14
}

// User status messages corresponding to user status:
export const LoginStatusText: string[] = [
	'Invalid user name / password combination.',
	'Logged out.',
	'Logged in.',
	'Cannot connect to server.',
	'Connection lost to server.',
	'WebClient out of date. Please refresh page.',
	'Scan with an authenticator app or manually copy key to app and enter a valid token',
	'Enter a valid 6 digit token',
	'Password requires resetting',
	'Password requires resetting',
	'',
	''
];

// This file defines the LiveDataClient object.
// A LiveDataClient object keeps the device list current.
// To communicate with a device, get your hands on a Device object, and go take a look at
// Device.js.
// It will also support user account maintenance commands.

class LDC {
	pointCount: number;
	status: LoginStatus;
	fm: FrameMaker;
	customFiles: string[];
	graphs: any[];
	socket: WebSocket | null = null;
	fBinarySupport: boolean;
	fOpening: boolean;
	refreshToken: any;
	graphID: number;
	connectionInterval: NodeJS.Timeout;
	pingTimeout: NodeJS.Timeout;
	fMessageReceived: boolean = false;
	private availableIDs: Set<number> = new Set();
	private nextID: number = 0;
	private callbacks: Map<number, Map<number, (fp: FrameParser) => void>> = new Map();
	private commandResponders: Map<LiveData, (fp: FrameParser) => void> = new Map();
	constructor() {
		this.fm = new FrameMaker();
		this.customFiles = [];
		this.graphs = [];	// Empty array where historical data clients will register themselves for callback
		this.graphID = this.registerGraph(this);
	};

	initializeSocket(closeCallback: (e: CloseEvent) => void, server: string): Promise<void> {
		// Create the connection to the Specific Energy Whoville server:
		this.fOpening = true;
		this.socket = new WebSocket(server);
		this.socket.binaryType = 'arraybuffer';	// instead of 'blob'
		this.fBinarySupport = true;				// Assume, for now, that binary messages are supported

		// Attach our handlers to the socket:
		this.socket.onclose = (e: CloseEvent) => {
			if (e) {
				console.log('this.onclose -- ' + e.code);
			}
			if (this.fOpening)
				this.status = LoginStatus.CONNECT_FAILED;
			else if (this.status == LoginStatus.LOGGED_IN)
				this.status = LoginStatus.CONNECTION_LOST;
			dispatchEvent(new CustomEvent('onConnectionStatusChanged', { detail: { status: this.status } }));
			closeCallback(e);
		}

		this.socket.onmessage = (e: MessageEvent) => this.onmessage(e);
		let resolver: () => void;
		let rejector: (e: Event) => void;
		let responsePromise = new Promise<void>((resolve, reject) => {
			resolver = resolve;
			rejector = reject;
		});
		this.socket.onopen = () => {
			this.fOpening = false;
			resolver!();
		}
		this.socket.onerror = (e) => rejector(e);
		return responsePromise;
	}

	// One time request and response
	sendRequest(fm: FrameMaker): LDCResponse {
		let id: number;
		if (this.availableIDs.size > 0) {
			id = this.availableIDs.values().next().value;
			this.availableIDs.delete(id);
		}
		else
			id = this.nextID++;
		let responseCommand = fm.command + LiveData.LDC_RESPONSE;
		let resolver: (fp: FrameParser) => void;
		let promise = new LDCResponse((resolve, reject) => {
			if (this.socket?.readyState != 1)
				reject(new Error('Socket is not ready for message'));
			resolver = resolve;
		})
		if (this.callbacks.has(responseCommand))
			this.callbacks.get(responseCommand)?.set(id, resolver!);
		else
			this.callbacks.set(responseCommand, new Map([[id, resolver!]]));
		fm.setJobID(id);
		this.sendFrame(fm)
		return promise;
	}

	onerror(e: Event) {	// WebSocket callback
		//console.log ('this.onerror');
		//assert (false, 'socket.onerror called. Why?? ' + e);

		// Just do the same thing as if 'close' were called
		//this.socket?.onclose && this.socket?.onclose(e as CloseEvent);
	}

	onmessage(e: MessageEvent) {	// WebSocket callback
		let fp = new FrameParser(e.data);		// Parse the frame header

		if (fp.marker != 0xab13)					// bad marker
			return;

		if (fp.command & LiveData.LDC_PROTOCOL_ERROR) {
			Sentry.captureException(new Error(`Protocol Exception. Command: ${fp.command & 0x000FFFF}`));
			location.hash = ''; // Go home
			location.reload();
		}

		this.fMessageReceived = true;

		if (this.callbacks.has(fp.command) && this.callbacks.get(fp.command)?.has(fp.client_zip)) {
			let pendingCallbacks = this.callbacks.get(fp.command);
			if (!pendingCallbacks)
				throw new Error(`Received a response that we didn't ask for`)
			let callback = pendingCallbacks.get(fp.client_zip);
			callback && callback(fp);
			pendingCallbacks.delete(fp.client_zip); // We're done with this callback now
			return;
		}
		else if (this.commandResponders.has(fp.command))
			this.commandResponders.get(fp.command)!(fp);
		else
			// Call the appropriate command processor:
			switch (fp.command) {
				case LiveData.WVCR_GET_JAVASCRIPT_CRC:						// No longer needed
					break;

				case LiveData.WVCR_GET_DEVICE_INFO:			// Get list of devices visible to this user response:
				case LiveData.WVCR_GET_DEVICE_INFO_V2:
					var graph: any = this.hasGraph(fp);
					if (!graph)
						break;
					graph.onGetDeviceInfo(fp);
					break;

				case LiveData.LDCR_ADD_BASELINE:
					console.log('New baseline was added: ' + (fp.pop_bool() ? 'true' : 'false'));
					break;

				case LiveData.LDCR_SUBMIT_TAG_CONFIG: {
					var graph: any = this.hasGraph(fp);
					if (!graph)
						break;

					graph.onSubmitTagConfigResponse(fp);	// Tell them we set the file
				}
					break;

				case LiveData.WVCR_PING: {
					var graph: any = this.hasGraph(fp);
					if (!graph)
						break;
					graph.onPing();	// Tell them we got data back
				}
					break;

				case LiveData.WVCR_GET_CUSTOM_FILES: {
					var graph: any = this.hasGraph(fp);
					if (!graph)
						break;

					var key = fp.pop_string();	// Key of the file
					var name = fp.pop_string();	// Name of the file
					var size = fp.pop_u32();	// Size of the file
					var array = new Uint8Array(fp.frame.buffer.slice(fp.ptr, fp.ptr + size));	// Pop out the file into a seperate array
					var div = document.createElement('div');							// Orphan div to hold the SVG

					// We can't use .apply on large files - pushing every
					// byte in as an argument will blow the stack!
					//			div.innerHTML = String.fromCharCode.apply(String, array);
					// FIXME: This seems super expensive. Time it
					var characters: string[] = [];
					for (var j = 0; j < array.length; ++j)
						characters.push(String.fromCharCode(array[j]));

					div.innerHTML = characters.join('');						// Join the characters
					fp.skip(size);												// Skip the file we just pooped

					graph.onCustomFileReceived(key, name, div);							// Give them the div
				}
					break;

				case LiveData.WVCR_GET_USER_PREFS: {
					var graph: any = this.hasGraph(fp);
					if (!graph)
						break;
					graph.onUserPreferencesReceived(fp);
				}
					break;

				case LiveData.WVCR_TRIAL_QUERY:
				case LiveData.LDCR_REGIME_CURVES:
				case LiveData.LDCR_POINT_REQUEST:
				case LiveData.WVCR_PUMP_CURVES:
				case LiveData.WVCR_GET_GRAPHS:
				case LiveData.WVCR_CREATE_DASHBOARD:
				case LiveData.WVCR_MODIFY_DASHBOARD:
				case LiveData.WVCR_GET_DASHBOARD:
				case LiveData.WVCR_GET_DASHBOARDS:
				case LiveData.WVCR_DELETE_DASHBOARD:
				case LiveData.LDCR_TEST_CHECKLISTS:	// FIXME: Put all of the parsing in this file
				case LiveData.WVCR_ACCOUNT_MANAGEMENT:
				case LiveData.WVCR_USERS:
				case LiveData.WVCR_COMPANIES:
				case LiveData.WVCR_CREATE_DEVICE_KEY:
				case LiveData.WVCR_GET_USER_LIST:
				case LiveData.WVCR_GET_PENDING_REPORTS:
				case LiveData.WVCR_GET_USER_REPORTS:
					var graph = this.hasGraph(fp);
					if (graph) {
						if (fp.command == LiveData.WVCR_TRIAL_QUERY)
							graph.onTrialDataResponse(fp);
						else if (fp.command == LiveData.LDCR_REGIME_CURVES)
							graph.onRegimeCurvesResponse(fp);
						else if (fp.command == LiveData.LDCR_POINT_REQUEST)
							graph.onPointResponse(fp);
						else if (fp.command == LiveData.WVCR_PUMP_CURVES)
							graph.onPumpCurvesResponse(fp);
						else if (fp.command == LiveData.WVCR_SNAPSHOT_QUERY)
							graph.onSnapshotDataResponse(fp);
						else if (fp.command == LiveData.WVCR_GET_GRAPHS)
							graph.onGraphResponse(fp);
						else if (fp.command == LiveData.WVCR_CREATE_DASHBOARD) {
							graph.onCreateDashboardResponse(fp);
							dispatchEvent(new CustomEvent('onDashboardCreated'));
						} else if (fp.command == LiveData.WVCR_MODIFY_DASHBOARD)
							graph.onModifyDashboardResponse(fp);
						else if (fp.command == LiveData.WVCR_GET_DASHBOARD)
							graph.onDashboardResponse(fp);
						else if (fp.command == LiveData.WVCR_GET_DASHBOARDS)
							graph.onDashboardsResponse(fp);
						else if (fp.command == LiveData.WVCR_DELETE_DASHBOARD)
							graph.onDeleteDashboardResponse(fp);
						else if (fp.command == LiveData.WVCR_CREATE_DEVICE_KEY)
							graph.onCreateDeviceKey(fp);
						else if (fp.command == LiveData.WVCR_GET_PENDING_REPORTS)
							graph.onGetPendingReports(fp);
						else if (fp.command == LiveData.WVCR_GET_USER_REPORTS)
							graph.onDashboardReportListResponse(fp);
						else
							graph.onTestChecklists(fp);
					}
					break;

				case LiveData.WVCR_VERIFY_SERVICE_TAG:
					var graph = this.hasGraph(fp);
					if (!graph)
						break;
					let response: number = fp.pop_u8();
					graph.onConnectServiceTagResponse(response);
					break;

				case LiveData.WVCR_WHAT_IF:
					let subCommand = fp.pop_u8();
					var graph = this.hasGraph(fp);
					switch (subCommand) {
						case LiveData.WHAT_IF_GET_PROJECTS:
							if (graph)
								graph.onGetModels(fp);
							break;
						case LiveData.WHAT_IF_CREATE_PROJECT:
							if (graph)
								graph.onCreateNewModel(fp);
							break;
						case LiveData.WHAT_IF_SAVE_PROJECT:
							if (graph)
								graph.onSave(fp);
							break;
						case LiveData.WHAT_IF_LOAD_PROJECT:
							if (graph)
								graph.onLoadModel(fp);
							break;
						case LiveData.WHAT_IF_DOWNLOAD_PROJECT:
							if (graph)
								graph.onDownloadModel(fp);
							break;
						case LiveData.WHAT_IF_DELETE_PROJECT:
							if (graph)
								graph.onDeleteModel(fp);
							break;
						case LiveData.WHAT_IF_SOLVE_SNAPSHOT:
							if (graph)
								graph.onSolveSnapshot(fp);
							break;
						case LiveData.WHAT_IF_SOLVE_EXTENDED_SIMULATION:
							if (graph)
								graph.onSolveModel(fp);
							break;
						case LiveData.WHAT_IF_MODIFY_OUTSIDE_ORGANIZATION_ACCESS:
							if (graph)
								graph.onModifyOutsideOrganizationAccess(fp);
							break;
						default:
							break;
					}
					break;

				case LiveData.WVCR_POST_HUBSPOT_FORM:
					var graph = this.hasGraph(fp);
					if (!graph)
						break;
					graph.onHubspotTicket(fp);
					break;

				case LiveData.WVCR_PASSKEY:
					var graph = this.hasGraph(fp);
					if (!graph)
						break;
					graph.onPasskeyCommand(fp);
					break;
				default:
					assert(false, 'Invalid command:' + fp.command);
					break;
			}
		assert(fp.size() == 0, `${fp.size()} leftover bytes in command: ${fp.command - 0x80000000} or ${fp.command}`);
	}

	registerCommandResponse(command: LiveData, callback: (FrameParser) => void) {
		assert(!this.commandResponders.has(command));
		this.commandResponders.set(command, callback);
	}

	hasGraph(fp: FrameParser): any {
		var graph = this.graphs[fp.client_zip];
		if (graph)			// Found the graph
			return graph;	// Return that graph

		// No graph
		fp.skip(fp.size());	// We aren't gonna use this frame any more
		return null;
	}

	extractPolynomial(fp: FrameParser) {
		var terms: any[] = [];
		var count = fp.pop_u8();
		for (var i = 0; i < count; ++i)
			terms.push(fp.pop_f64());
		return terms;
	}

	isLoggedIn() {
		return this.status == LoginStatus.LOGGED_IN;
	}

	cleanup(fCloseSocket: boolean) {
		// If WebSocket exists, close and delete it:
		if (fCloseSocket && this.socket) {
			// This socket will not close when we ask it to. It might wait a little while before disconnecting.
			// If we don't kill the callbacks, it might give us a callback later and disconnect a new, connected
			// socket. TODO: Find a better way to make the socket close. Maybe checking socket status? When we are
			// confident on how sockets disconnect, just make the onclose function null, too.
			this.socket.onclose = null;
			this.socket.onerror = null;
			this.socket.onopen = null;
			this.socket.onmessage = null;
			this.socket.close();
		}
		this.socket = null;
	}

	send() {	// send FrameMaker frame 'this.fm' through the WebSocket to Whoville:
		this.sendFrame(this.fm);
	}

	sendFrame(fm: FrameMaker): boolean {	// send FrameMaker frame 'this.fm' through the WebSocket to Whoville:
		if (!this.socket || this.socket.readyState != 1) {
			//assert(false);
			return false;
		}
		assert(this.socket.readyState == 1);	// OPEN

		if (this.fBinarySupport)
			this.socket.send(fm.toArrayBuffer());
		else
			this.socket.send(fm.toString());
		return true;	// Now all the send methods can return this method to return true
	}

	getUserPreferences(clientID: number) {
		this.fm.buildFrame(LiveData.WVC_GET_USER_PREFS, undefined, clientID);
		this.send();
	}

	setUserPreferences(clientID: number, preferences: string) {
		this.fm.buildFrame(LiveData.WVC_SET_USER_PREFS, undefined, clientID);
		this.fm.push_string(preferences);
		this.send();
	}

	getDashboards(clientID: number, companyKey: string) {
		this.fm.buildFrame(LiveData.WVC_GET_DASHBOARDS, undefined, clientID);
		this.fm.push_string(companyKey)
		this.send();
	}

	registerGraph(client: any) {
		var clientID = this.graphs.length;	// Set the clientID as the new graph index
		this.graphs.push(client);			// Add the client pointer on the end of the array
		return clientID;					// Return their new clientID
	}

	unregisterGraph(clientID: number) {
		assert(this.graphs[clientID], "No client registered at the client ID");
		this.graphs[clientID] = null;		// Remove the reference to the graph
		// TODO: Should really clean up the this.graphs array here. That way its size never balloons off forever
	}

	getCustomFile(clientID: number, key: string, fileName: string) {
		this.fm.buildFrame(LiveData.WVC_GET_CUSTOM_FILES, undefined, clientID);
		this.fm.push_string(key);		// Push the device or company so that the backend knows which file we're looking for
		this.fm.push_string(fileName);	// Push the name of the file we're looking for
		this.send();
	}

	sendPasswordResetEmail(clientID: number, username: string) {
		if (!username)
			return false;

		this.fm.buildFrame(LiveData.WVC_FORGOT_PASSWORD, 0, clientID);
		this.fm.push_string(username);						// Username we're triggering the email for
		return this.send();									// Sent (no response expected)
	}
};

let LiveDataClient: LDC = new LDC();

export default LiveDataClient;
