import { type SettingInput } from '../../views/attributeeditorview';
import { ExtendedTagMetadata, ExtendedTagSetMetadata } from './tag';
import { type Widget, type WidgetConstructorType, isAttached, refresh } from './widget';
import 'reflect-metadata';

export enum SettingType {
    DEFAULT = 0,
    INHERITED = 1,
    SET = 2
}

export type AttributeType = 'String' | 'Boolean' | 'Number' | 'Object' | 'Array';

export interface Metadata {
    displayName: string;
}
/**
 * Basic attribute property metadata
 */
export interface AttributeMetadata extends Metadata {
    section?: string,
    tooltip?: string,
    description?: string,
    getInput?: (name: string, parent: HTMLElement, property: string, getValue: (attrName: string) => [string, SettingType], onSettingChangedCallback: (key: string, value: string) => void, tooltip?: string) => SettingInput | Promise<SettingInput>;
}

export interface ExtendedAttributeMetadata extends AttributeMetadata {
    type: AttributeType;
    privateKey: symbol;
    publicKey: string;
}

// Globally unique indexable property keys
export const attrSymbol = Symbol();
export const attrMetadataSymbol = Symbol();

/**
 *
 * @param properties
 * @returns
 */
export const Attribute = (properties: AttributeMetadata) => (target: Widget, key: string) => {
    const privateKey = Symbol();
    const type = Reflect.getMetadata("design:type", target, key);

    const constructor = target.constructor as unknown as WidgetConstructorType;
    let lowerCaseKey = key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`); // Attributes are always lower case, so store what the eventual attribute name will be in the DOM

    constructor.observedAttributes = (constructor.observedAttributes || []).slice();
    if (!constructor.observedAttributes.includes(lowerCaseKey))
        constructor.observedAttributes.push(lowerCaseKey);

    return Serializable({ ...properties, privateKey: privateKey, type: type.name!, publicKey: key }, attrMetadataSymbol, target, key, privateKey);
}

/**
 * Decorator function that returns an object with a custom getter and setter method.
 * Adding this decorator to a Widget property calls the refresh method whenever the
 * property is mutated or reassigned. It also registers a globally unique private key
 * on the Widget that can be used to map between the property and an attribute string
 * for onAttributeChanged callbacks
 *
 * @param properties
 * @param metaDataSymbol
 * @param target
 * @param key
 * @param privateKey
 * @param setter
 */
export const Serializable = (properties: ExtendedAttributeMetadata | ExtendedTagMetadata | ExtendedTagSetMetadata, metaDataSymbol: symbol, target: Widget, key: string, privateKey: symbol) => {
    const constructor = target.constructor as unknown as WidgetConstructorType;
    let lowerCaseKey = key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`); // Attributes are always lower case, so store what the eventual attribute name will be in the DOM
    constructor[attrSymbol] = new Map(constructor[attrSymbol]).set(lowerCaseKey, privateKey); //

    constructor[metaDataSymbol] = new Map(constructor[metaDataSymbol]).set(lowerCaseKey, properties);

    const type = Reflect.getMetadata("design:type", target, key);
    /**
     * Setter method for the property. For primitives, we just need to make sure that we assign the new value to our
     * indexed Symbol private key.For Arrays and Objects, getters and setters only fire when the object is reassigned.
     * For capturing all mutations to the objects, we need to build a proxy object to listen for changes.
     */
    const setter = function (this: Widget, newVal: any) {
        switch (type.name) {
            case 'Number':
            case 'Boolean':
            case 'String':
                this[privateKey] = newVal;
            break;
            case 'Array':
            case 'Object':
                this[privateKey] = buildProxy(newVal, () => {
                    if (this[isAttached])
                        this[refresh]();
                }, false);
            break;

            default:
                debugger;
        }
        if (this[isAttached])
            this[refresh]();
    }
    const getter = function (this: Widget) {
        return this[privateKey];
    }
    Object.defineProperty(target, key, {
        get: getter,
        set: function (this: Widget, newValue: any) {
            setter.bind(this, newValue)();
        }
    });
}

/**
 * Create an optionally nested proxy object to observe mutations on an object. Callback will be called on any mutation.
 *
 * @param observedObj
 * @param callback
 * @param fNested
 * @returns
 */
export const buildProxy = (observedObj: any, callback: () => void, fNested: boolean = true) => {
    if (typeof observedObj === 'undefined')
        return undefined;
    return new Proxy(observedObj, {
        get(target, prop) {
            const value = Reflect.get(target, prop);

            if (fNested && value && typeof value === "object" && ["Array", "Object"].includes(value.constructor.name))
                return buildProxy(value, callback);

            return value;
        },

        set(target, prop, value) {
            let success = Reflect.set(target, prop, value);
            callback();
            return success;
        },

        deleteProperty(target, prop) {
            let success = Reflect.deleteProperty(target, prop);
            callback();
            return success;
        }
    });
}

