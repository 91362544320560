import {Node, NodeQuality, NodeFlags, NodeOperand, VType} from './node';
import LiveData from './livedata';
import assert from './debug';
import { Device } from './device';
import FrameParser from './frameparser';
import User from './user';
import FrameMaker from './framemaker';
import LiveDataClient from './livedataclient';

// This file implements the LiveDataJob object.
// This object allows customers of LiveData to establish a multiple-node read/write command for a particular device,
// to submit the job to commit all written node values, and to read (update the values of) the nodes.

// LiveDataJob constructor is only called by device.createJob(). Should not be called directly by user!!
export default class LiveDataJob {
	device: 	Device;
	ldjnodes: 	LiveDataJobNode[] = [];
	toSub: 		Node[] = [];
	toUnsub: 	Node[] = [];
	derived: 	LiveDataJobNode[] = [];
	subTimer: 	NodeJS.Timer | undefined;
    constructor(device: Device) {
        this.device		= device;	// device associated with this job
    }

    // Define methods through the prototype of LiveDataJob:
	add(node: Node) {		// Add a node to this job.
		assert(node.vtype != VType.VT_UNKNOWN, 'Job requires a node to have a vtype!');
		if ((node.flags & NodeFlags.NF_DERIVED) != 0) {		// If the node is derived
			for (var i = 0; i < this.derived.length; ++i) {
				if (this.derived[i].node === node) {
					++this.derived[i].refCount;
					return node;
				}
			}
			// If we made it here, we aren't subscribed to the derived node yet
			this.derived.push(new LiveDataJobNode(node));
			node.baseNodes = [];
			for (var i = 0; i < node.operations.length; ++i) {
				if (node.operations[i].node) {
					var n = this.add(this.device.tree.getNode(node.operations[i].node!)!);
					node.baseNodes.push(n);
					n.derivativeOf = n.derivativeOf || [];
					n.derivativeOf.push(node);
				}
			}
		} else if ((node.flags & NodeFlags.NF_FINAL) == 0) {	// ignore final nodes
			var index = this._find(node);
			if (index === -1) {	// node not already in job:
				var unSubIndex = this.toUnsub.indexOf(node);	// See if we were trying to unsubscribe to this node
				if (unSubIndex === -1) {						// This is a fresh node
//					console.log ('LiveDataJob.add(' + node.name + ');');
					this.ldjnodes.push(new LiveDataJobNode(node));	// Add it to the list
					this.toSub.push(node);						// Make a note to subscribe to the node in a second
					if (this.subTimer === undefined)			// If we don't have a timer started to send of the subscribe message, start one
						this.subTimer = setTimeout(this.fixSubs.bind(this), 10);	// Wait 10 ms, just long enough to let everything from the click happen
				} else {										// We JUST unsubscribed to this node...just short circuit that
//					console.log ('LiveDataJob, on seconding thought, keeping(' + node.name + ');');
					node.ldNode.refCount = 1;					// Ref count is back to one
					this.ldjnodes.push(node.ldNode);			// Put it back in the list
					this.toUnsub.splice(unSubIndex, 1);			// Remove the node from the unsub list
				}
			} else { // node already in job, so bump the reference count:
				assert (this.ldjnodes[index].node === node);
				++this.ldjnodes[index].refCount;
			}
		}
		return node; // for chaining purposes
	}

	remove(node: Node) {	// Remove node from this job:
		if ((node.flags & NodeFlags.NF_DERIVED) != 0) {		// If the node is derived
			for (var i = 0; i < this.derived.length; ++i) {
				if (this.derived[i].node === node) {
					if (--this.derived[i].refCount == 0) {
						for (var j = 0; j < node.baseNodes.length; ++j) {
							var array = node.baseNodes[j].derivativeOf;
							array.splice(array.indexOf(node.baseNodes[j]), 1);
							this.remove(node.baseNodes[j]);
						}
						this.derived.splice(i, 1);
					}
					return;
				}
			}
		} else {
			var index = this._find(node);
			if (index == -1)
				return;

			if (--this.ldjnodes[index].refCount == 0) {	// decrement reference count. Delete if zero:
				this.ldjnodes.splice(index, 1);	// remove it from the array
				this.toUnsub.push(node);		// Make a note to unsubscribe from the node
//				console.log ('LiveDataJob.remove(' + node.name + ');');
				if (this.subTimer === undefined)			// If we don't have a timer started to send of the subscribe message, start one
					this.subTimer = setTimeout(this.fixSubs.bind(this), 10);	// Wait 10 ms, just long enough to let everything from the click happen
			}
		}
	}

	fixSubs() {
		if (LiveDataClient.socket) {	// If we still have a socket
			this.issueCommand(LiveData.LDC_UNSUBSCRIBE_TAGS, this.toUnsub);	// Unsubscribe!
			this.issueCommand(LiveData.LDC_SUBSCRIBE_TAGS, this.toSub);		// Subscribe!
		}
		delete this.subTimer;			// Delete the old timer ID
	}

	refresh() {
		this.ldjnodes.forEach(ldjnode => {
			ldjnode.lastValue = 0;
			ldjnode.refCount = 0;
			this.toSub.push(ldjnode.node);
		});
		this.fixSubs();
	}

	clear() {
		this.ldjnodes = [];
	}

	issueCommand(command: LiveData, array) {
		if (array.length == 0)	// If no nodes to add/remove, nothing to do
			return;
		//console.log((command == LiveData.LDC_UNSUBSCRIBE_TAGS ? "Unsubbing " : "Subbing ") + "to " + array.length + " tags.");
		let fm = new FrameMaker();
		fm.buildFrame(command, this.device.id);	// Build the frame
		fm.push_u16(array.length);				// Add the length of the array
		for (var i = 0; i < array.length; ++i) {
			fm.push_UVarInt(array[i].id);		// Add each node ID
			if (array === this.toUnsub) {
				delete array[i]._value;
				delete array[i].quality;
			}
		}
		array.length = 0;						// Reset the array
		LiveDataClient.sendFrame(fm);
	}

	_find(node: Node) : number { // Search for node and return its index:
		for (var i = 0; i < this.ldjnodes.length; ++i) {
			if (this.ldjnodes[i].node === node)
				return i;
		}
		return -1;	// not found
	}

	sendWriteCommand(node: Node, value: number | string) {
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.LDC_WRITE_TAG, this.device.id);	// Build a frame for the write
		fm.push_string(User.username);	// Add the user's name
		fm.push_UVarInt(node.id);						// Add the Node id
		if(node.vtype == VType.VT_STRING)				// If the node is a string
			fm.push_string(value as string);						// Add the new string
		else											// Else we are a numeric
			fm.push_SVarInt(Math.round(value as number / node.rawResolution));	// Push the number of resolutions to set the node to
		LiveDataClient.sendFrame(fm); // No response expected, so just send the frame
	}

	onTagUpdate(fp: FrameParser) {			// Called by ldc to let us know we have new node data
		var changed: Node[] = [];			// Array to hold changed nodes so we can call Widgets after eveything is updated
		var count = fp.pop_u16();			// Number of updates
		for (var i = 0; i < count; ++i) {	// For each update
			let id 		= fp.pop_UVarInt();
			let state 	= fp.pop_u8();

			let node = this.device.tree.getNode(id)!;	// Get the node by its ID
			if (node)
				changed.push(node);	// Add the node to the changed list
			else {
				//@ts-ignore
				node = {ldNode: {}};
			}

			if (state & LiveData.LDC_RESET) {
				assert(state == LiveData.LDC_RESET + LiveData.LDC_QUALITY + LiveData.LDC_VALUE); // If one is set, the should all be set
				node.lastValue = 0; // Reset the value. A full value is coming through
			}

			node.fQualityChanged 	= (state & LiveData.LDC_QUALITY) != 0;	// First bit means quality is attached
			node.fValueChanged 		= (state & LiveData.LDC_VALUE) != 0;		// Second bit means value is attached
			if (node.fQualityChanged)				// If there's a new quality
				node.quality = fp.pop_UVarInt();	// Extract the new quality
			if (node.fValueChanged)					// If there's a new value
			{
				if (node.vtype == VType.VT_STRING)	// If we are a string
					node._value = fp.pop_string();	// Pop the string
				else {								// Else, we are a numeric node
					node.ldNode.lastValue += fp.pop_SVarInt();	// Add in the new delta resolution count
					node._value = node.ldNode.lastValue * node.rawResolution;	// Compute the new value
				}
			}
		}

		// All nodes for this job now have new qualities/values. So send out all of the node updates at once:
		for (var i = 0, node; node = changed[i]; ++i) {
			for (var k = 0; node.derivativeOf && k < node.derivativeOf.length; ++k) {
				var derived = node.derivativeOf[k];
				var ops = derived.operations;
				var value = 0, quality = 0;
				for (var j = 0, baseIndex = 0; j < ops.length; ++j) {
					var newVal = ops[j].constant;
					if (ops[j].node !== undefined) {
						var baseNode = derived.baseNodes[baseIndex++];
						newVal = baseNode._value;	// This needs to grab the unsafe value because getValue() can convert the node silently
						if (newVal < ops[j].min || ops[j].max < newVal)
							quality |= NodeQuality.NQ_OUT_OF_RANGE;
						quality |= baseNode.quality;
					}
					switch(ops[j].op) {
						case NodeOperand.DERIVED_ADD:		value += newVal; break;
						case NodeOperand.DERIVED_SUBTRACT:	value -= newVal; break;
						case NodeOperand.DERIVED_MULTIPLY:	value *= newVal; break;
						case NodeOperand.DERIVED_DIVIDE:	if (newVal == 0)quality|=NodeQuality.NQ_OUT_OF_RANGE; else value /= newVal; break;
					}
				}
				derived._value = value;
				derived.quality = quality;
				derived._updateSubscribers();
			}
			node._updateSubscribers();						// Update all objects attached to this node
		}
		let fm = new FrameMaker();
		fm.buildFrame(LiveData.LDCR_TAG_UPDATE, this.device.id);
		LiveDataClient.sendFrame(fm); // No response here
    }

};

// This object is a wrapper for the node so that we can include job-specific previous node value/quality
// to determine whether the node changed.
// Since a node can be used by multiple jobs, the value/quality must be tested for each job
// to determine whether a value/quality changed, triggering an onTagChange().

export class LiveDataJobNode {
	node: 		Node;
	lastValue: 	number = 0;
	refCount: 	number = 1;
	constructor(node: Node) {
		this.node			= node;
		this.node.ldNode	= this;
	}
}
