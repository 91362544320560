import owner from '../../owner';
import { Device } from '../device';
import { Node } from '../node';
import { TagFilter } from '../tagfilter';
import { SerializedTagCategories, DashboardSerializedTagView } from '../views/dashboardstyleview';
import { SerializedTag, LiveDataGizmo } from './gizmos/gizmo';
import View from '../views/view';
import EditorPage from '../pages/editorpage';
import { createElement } from '../elements';
import AddIcon              from "../images/icons/add.svg";
import MinusIcon            from "../images/icons/minus.svg";
import assert from '../debug';
import TreeView, { TreeViewProperties, TreeViewTypes } from '../views/treeview';
import ViewModal, { ViewModalProperties } from '../viewmodal';
import LiveDataClient from '../livedataclient';
import User from '../user';

enum TagStatus {
    PENDING     = 0, // We are still waiting on this node tree
    COMPLETE    = 1, // We have all the information needed to subscribe to this node
    FAILED      = 2  // This node is unavailable to the user
}

/**
 *A little interface to keep track of tags and their associated status and list element
 */
interface SocketTag {
    serializedTag:  SerializedTag,
    node:           Node,
    status:         TagStatus,
    listElement:    HTMLElement
}

// Tag Sockets handle tag retrieval and device management.
export default class TagSocket {
    fMultiple:              boolean;        // can we have more than one tag?
    name:                   string;         // socket's name
    andFilters:             TagFilter[];    // filter which tags we can select (example: if we add a boolean filter and a logged filter to our andFilters array, the tagView will display all tags which are booleans and logged)
    orFilters:              TagFilter[];    // filter which tags we can select (example: if we add a boolean filter and a logged filter to our orFilters array, the tagView will display all boolean tags and all logged tags)
    tagList:                HTMLElement;
    removeButton:           HTMLElement;
    tagItemMap:             Map<HTMLElement, SerializedTag> = new Map();
    unavailableTags:        Set<SerializedTag> = new Set();
    serializedTagTagMap:    Map<SerializedTag, Node> = new Map();
    tagSerializedTagMap:    Map<Node, SerializedTag> = new Map();
    deviceTagMap:           Map<string, Set<SerializedTag>> = new Map();
    tags:                   Set<Node> = new Set();
    gizmo:                  LiveDataGizmo;
    deviceTreeMap:          Map<string, boolean> = new Map();
    sections:               SerializedTagCategories[] = [];
    ldc:                    typeof LiveDataClient;
    constructor(ldc: typeof LiveDataClient, name: string, gizmo: LiveDataGizmo, andFilters: TagFilter[], orFilters: TagFilter[], fMultiple: boolean, sections?: SerializedTagCategories[]) {
        this.ldc            = ldc;
        this.name           = name;
        this.andFilters     = andFilters;
        this.orFilters      = orFilters;
        this.fMultiple      = fMultiple;
        this.gizmo          = gizmo;
        this.sections       = [SerializedTagCategories.PATH]; // By default, make relative tag option available
        if (sections)
            this.sections.push(...sections);
    }

    addTags(tags: Node[]) {
        for (let i=0;i<tags.length;++i) {
            if (this.tags.has(tags[i]))
                continue;
            let serializedTag: SerializedTag = {deviceKey: tags[i].tree.device.key, path: tags[i].getDeviceRelativePath()!, socket: this.name};
            this.gizmo.recipe.tags.push(serializedTag);
        }
        this.refreshTags();
        this.gizmo.onTagsAdded(this);
    }

    removeTags(tags: SerializedTag[]) {
        for (let i=0;i<tags.length;++i) {
            for (let j =0;j< this.gizmo.recipe.tags.length;++j) {
                let serializedTag = this.gizmo.recipe.tags[j];
                if (serializedTag.deviceKey == tags[i].deviceKey && serializedTag.path == tags[i].path && tags[i].socket == this.name)
                    this.gizmo.recipe.tags.splice(j,1);
            }
        }
        this.refreshTags();
        this.gizmo.onTagsRemoved(this);
    }

    removeNodes(nodes: Node[]) {
        let tags: SerializedTag[] = [];
        nodes.forEach(node => {
            tags.push({
                deviceKey: node.tree.device.key,
                path: node.getDeviceRelativePath(),
                socket: this.name
            });
        });
        this.removeTags(tags);
    }

    refreshTags() {
        this.unavailableTags.clear();
        this.deviceTagMap.clear();
        this.serializedTagTagMap.clear();
        this.tagSerializedTagMap.clear();
        this.tags.clear();
        for (let tag of this.gizmo.recipe.tags) {
            if (tag.socket != this.name)
                continue;
            if (this.deviceTagMap.has(tag.deviceKey))                   // if we already have a set of tags for this device in our map
                this.deviceTagMap.get(tag.deviceKey)?.add(tag);         // add this tag to it
            else if (User.devices.getByKey(tag.deviceKey)) {       // if we have access to this device
                this.deviceTagMap.set(tag.deviceKey, new Set([tag]));   // we don't have a set for this device yet, so add it and add our tag to it
            }
            else                                                        // no access to this device - bail!
                this.unavailableTags.add(tag);                          // add the tag to our set of naughty tags
        }
        for (let deviceKey of this.deviceTagMap.keys()) {               // for each device we're concerned with
            this.deviceTreeMap.set(deviceKey, false);                   // record that we have a pending request for this device
            let device = User.devices.getByKey(deviceKey)!;
            device.requestNodeTree(() => this.onNodeTreeComplete(device));      // request the node tree
        }
        //this.gizmo.checkWarning(); //TODO: move this into a setter method on gizmo
    }

    onNodeTreeComplete(device: Device) {
        device.onConnect.delete(this);
        let tags = this.deviceTagMap.get(device.key)!;              // get all the serialized tags for this device
        for (let serializedTag of tags) {                           // for each serialized tag
            let tag = this.resolveTag(serializedTag);               // check if it is a valid Node
            if (tag) {
                this.tags.add(tag);                                 // add it to our set of valid tags
                this.serializedTagTagMap.set(serializedTag, tag);
                this.tagSerializedTagMap.set(tag, serializedTag);
                this.unavailableTags.delete(serializedTag);         // it's not unavailable anymore
            }
            else
                this.unavailableTags.add(serializedTag);            // we couldn't find the corresponding node, mark it as unavailable
        }
        device.onDisconnect.set(this, this.onDeviceDisconnect);     // we know we are connected and have a node tree at this point
        this.deviceTreeMap.set(device.key, true);
        for (let [device, status] of this.deviceTreeMap) {
            if (!status)
                return;
        }
        this.gizmo.onTreeComplete(this.name);
    }

    resolveTag(tag: SerializedTag): Node {
        let key = (tag.settings?.fRelative && owner.selectedDevice) ? owner.selectedDevice.key : tag.deviceKey;
        let device      = User.devices.getByKey(key)!;
        let resolvedTag = device.tree.findNode(tag.path)!;
        return resolvedTag;
    }

    /**
     * Device just disconnected. Listen for reconnect and add device's tags to our list of bad tags
     * @param device
     */
    onDeviceDisconnect(device: Device) {
        let tags = this.deviceTagMap.get(device.key)!;
        if (!tags)
            return;
        for (let tag of tags)
            this.unavailableTags.add(tag);
        this.deviceTreeMap.set(device.key, false);
        device.onDisconnect.delete(this);
        device.onConnect.set(this, ()=>device.requestNodeTree(() => this.onNodeTreeComplete(device)));
    }
}

// A little view that allows the user to select and change tags and their properties
export class TagSocketView extends View {
    tagList: HTMLElement;                                   // A list that allows for tag selection
    removeButton: HTMLButtonElement;                        // Button to remove tag from a socket. Changes its context based on the currently selected tag
    addButton: HTMLButtonElement;                           // Same as above but adds new tags
    gizmo: LiveDataGizmo;                                   // Need to keep track of which gizmo we are editing
    tagItemMap: Map<string, HTMLElement[]> = new Map();     // Map of tag names to their corresponding HTMLElement
    socketSelector: HTMLSelectElement;
    socketMap: Map<string, TagSocket> = new Map();
    settingsView: DashboardSerializedTagView;
    editor: EditorPage;
    tagTextEdit: HTMLInputElement;
    ldc: typeof LiveDataClient;
    constructor(ldc: typeof LiveDataClient, editor: EditorPage) {
        super();
        this.ldc    = ldc;
        this.editor = editor;
    }
    initialize(parent: HTMLElement): TagSocketView {
        super.initialize(parent);
        this.wrapper        = createElement('div', 'socket__wrapper', this.parent)
        this.socketSelector = createElement('select', '', this.wrapper);
        this.socketSelector.onchange = () => {
            let value = this.socketSelector.options[this.socketSelector.selectedIndex].value;
            this.selectSocket(value);
        }
        let listWrapper    = createElement('div', 'socket__list-wrapper', this.wrapper);
        let socketRow       = createElement('div', 'socket__row', listWrapper);
        this.tagList        = createElement('div', 'socket__tags', socketRow);
        let buttonColumn    = createElement('div', 'flex__column', socketRow);
        this.addButton      = createElement('button', 'editor__nav__button', buttonColumn);
        this.removeButton   = createElement('button', 'editor__nav__button', buttonColumn);
        createElement('img', '', this.addButton, '', {'src':AddIcon} );
        createElement('img', '', this.removeButton, '', {'src':MinusIcon});
        this.settingsView   = new DashboardSerializedTagView(this.editor).initialize(this.wrapper);
        this.fInitialized = true;
        return this;
    }
    /**
     *Sets the currently selected gizmo we should be editing
    *
    * @param {LiveDataGizmo} gizmo
    * @memberof TagSocketView
    */
    setGizmo(gizmo: LiveDataGizmo) {                    // have a new gizmo that we want to potentially edit sockets for
        assert(gizmo.sockets.size > 0);         // We shouldn't have a socket view unless we have a socket
        this.gizmo      = gizmo;                // Keep a reference to the selected gizmo
        this.buildLists(this.gizmo.sockets);    // Build up the list of tags
    }
    /**
     *Clears the tags from our local maps and builds up a list of tags in the DOM for each socket
     *
     * @private
     * @param {Map<string, TagSocket>} socketMap
     * @memberof TagSocketView
     */
    private buildLists(socketMap: Map<string, TagSocket>) {
        this.socketMap.clear();
        this.tagItemMap.clear();
        this.socketSelector.removeChildren();
        for (let [name, socket] of socketMap) {
            this.tagItemMap.set(socket.name, []);
            createElement('option', '', this.socketSelector, name, {value: name});
            this.socketMap.set(socket.name, socket);
        }
        this.selectSocket(this.socketSelector.options[this.socketSelector.selectedIndex].value);
    }

    selectSocket(socketName: string) {
        let socket = this.socketMap.get(socketName)!
        this.addButton.onclick      = () => this.selectTags(socket);
        this.refreshTagList(socket);
    }

    refreshTagList(socket: TagSocket) {
        let currentTags = this.tagItemMap.get(socket.name);
        if (!currentTags)
            return;
        this.tagList.removeChildren();
        currentTags = [];
        this.settingsView.hideSections();
        for (const tag of this.gizmo.recipe.tags) {
            if (tag.socket !== socket.name)
                continue;
            let tagItem = createElement('div', 'socket__tags__tag', this.tagList, (tag.deviceKey + tag.path));
            this.tagItemMap.get(socket.name)!.push(tagItem);
            tagItem.onclick = (e) => {

                e.stopPropagation();
                if (this.tagTextEdit)
                    this.tagTextEdit.parentElement?.removeChild(this.tagTextEdit);
                for (let [socketName, tags] of this.tagItemMap) {
                    for (let tagListItem of tags) {
                        tagListItem.classList.remove('socket__tags__tag__selected')
                    }
                }
                tagItem.classList.add('socket__tags__tag__selected')
                this.removeButton.onclick = () => {
                    socket.removeTags([tag]);
                    this.refreshTagList(socket);
                }
                this.tagTextEdit         = createElement('input', 'socket__tags__tag__input', tagItem)
                this.tagTextEdit.value   = (tag.deviceKey + tag.path);
                this.tagTextEdit.onclick = (e: MouseEvent) => e.stopPropagation()
                this.tagTextEdit.onblur  = () => {
                    do {
                        let pathArray = this.tagTextEdit.value.split('/')
                        let deviceKey = pathArray.shift();
                        if (!deviceKey)
                            break;
                        let device = User.devices.getByKey(deviceKey);
                        if (!device)
                            break;
                        tag.deviceKey   = device.key;
                        tag.path        = '/' + pathArray.join('/');
                    } while (false);
                    tagItem.removeChild(this.tagTextEdit);
                    socket.refreshTags();
                }
                if (!tag.settings)
                    tag.settings = {};
                this.settingsView.setSettingsToEdit(tag.settings, socket.sections)
            }
        }
    }

    selectTags(socket: TagSocket) {
        let addProperties : TreeViewProperties  = {
            type:               socket.fMultiple? TreeViewTypes.TVT_MULTISELECT_ACCEPT : TreeViewTypes.TVT_SELECT_ACCEPT,
            selectedTags:       Array.from(socket.tags),
            acceptCallback:     (tags: Node[]) => {
                socket.removeNodes(Array.from(socket.tags));
                socket.addTags(tags);
                this.refreshTagList(socket);
            },
            selectFilters:  {
                andFilters:         socket.andFilters,
                orFilters:          socket.orFilters,
            }
        }
        let modalProperties: ViewModalProperties = {
            maxWidth:               '800px',
            titleBackgroundColor:   'var(--color-primary)',
            titleTextColor:         'var(--color-inverseOnSurface)',
            title:                  socket.name
        }
        new ViewModal(new TreeView(addProperties), modalProperties);
    }
}