import View from "./view";
import {createElement} from '../elements';
import Enlivener from "../enlivener";
import './customtabview.css';
import LiveDataClient from "../livedataclient";
import User from "../user";

export default class CustomTabView extends View {
    constructor(device, ldc, fileName) {
        super();
        this.device 	    = device;
        this.rootNode       = this.device.tree.nodes[0];
        this.environment    = {};
        this.ldc            = LiveDataClient;
        this.index          = 0;
        this.fileNames      = this.device.customFiles
        this.id             = this.ldc.registerGraph(this);
        this.companyKey     = User.companyKey;
        this.fileMap		= new Map();
        this.selectedFile   = fileName !== undefined ? fileName : this.device.customFiles[0];
        // Set up the environment variables so that the correct files can be called
        if (!this.environment.train)	// FIXME: This is fucking hacky
            this.environment.train = '1';
    };

	initialize(parent) {
        super.initialize(parent);
		if (this.fileNames.length == 0)
            return this;

        this.wrapper        = createElement('div', 'custom__page-wrapper', this.parent);
        this.svgContainer   = createElement('div', 'custom__container', this.wrapper);
    	this.environment.file = this.selectedFile;
        this.fInitialized = true;

        this.ldc.getCustomFile(this.id, this.device.key, this.selectedFile);		// Get the custom file
        return this;
    }

    onSelectorChange() {

	}

    // Refresh the custom file
	refreshFile() {
		if (this) {
			this.destroySVG();
			this.buildSVG();
		}
	}

    onCustomFileReceived(key, name, div) {
        this.fileMap.set(name, div);
        this.buildSVG();
    }

    destroy(softDestroy) {
        if (!this.fInitialized)
            return;
		this.destroySVG();
		this.wrapper.destroyWidgets(true);
        this.wrapper.removeChildren();
        this.parent.removeChild(this.wrapper);
	}

    //Expands innerElem to fill but not exceed outerElem's size.
    //Accepts a padding value that will shrink it slightly.
    scaleUpToFill(innerElem,outerElem,pad) {
		// Only used when dealing with a straight SVG
		// Shold ideally not be used for much in the future
        var irect = innerElem.getBoundingClientRect();
        var orect = outerElem.getBoundingClientRect();

        var fMobile = window.innerWidth < 768;


        var width, height;
        if(fMobile)
        {
            height = orect.height - pad;
            width = height * irect.width / irect.height;
        } else if (orect.height/orect.width > irect.height/irect.width){
            width = orect.width - pad;
            height = width * (irect.height / irect.width);
        }   else {
            height = orect.height - pad;
            width = height * irect.width / irect.height;
        }

        innerElem.style.width   = width + 'px';
        innerElem.style.height  = height+ 'px';
    }

    buildSVG() {
        this.destroySVG();
    	if (!this.fileMap.has(this.environment.file)) {
            this.ldc.getCustomFile(this.id, this.device.key, this.environment.file);		// Get the custom file
            return;
        }
        //var file = this.fileMap.get(this.environment.file);
        var file = this.fileMap.get(this.environment.file)
        this.svgContainer.innerHTML = file.innerHTML;	// Set the embed's source
        // this.fSideScroll = SE.SVGWidget.readAttribute(file.children[0], "fsidescroll");
//		if (fileType == "image/svg+xml") {
		if (this.svgContainer.children[0]) this.scaleUpToFill(this.svgContainer.children[0], this.svgContainer, 0);
//		}
		this.finalizeSVG(this.svgContainer);
        this.optionChangeEventListener = this.changeEnvironmentAndReload.bind(this);
        this.svgContainer.addEventListener('SEESVG-varchange',this.optionChangeEventListener);
    }

    //called when buildSVG is done
    //this is the shared codepath between
    //local file loading and network file loading
    finalizeSVG(newSVG){
        this.enlivener = new Enlivener(this.rootNode);
        this.enlivener.enliven(newSVG,undefined,this.environment);
    }

    destroySVG(){
        // First, detach all LiveData nodes from all DOM elements:
		this.svgContainer.destroyWidgets(true);

		// Second, delete all child DOM elements:
		this.svgContainer.removeChildren();

        this.svgContainer.removeEventListener('SEESVG-varchange',this.optionChangeEventListener);
		this.optionChangeEventListener = null;
    }

    changeEnvironmentAndReload(event){
        //console.log("SEE-SVG: Received event to change environment vars:",event.detail);
        this.destroySVG();
        for (var key in event.detail)
            this.environment[key] = event.detail[key];
        this.buildSVG();
    }

    resize() {
        if(this.svgContainer.children[0]) // if we have recieved the svg file yet
            this.scaleUpToFill(this.svgContainer.children[0], this.svgContainer, 20);
    }
};


