import SVGWidget from "./svgwidget";
import ViewModal from "../viewmodal";
import TagView from "../views/treeview";
import LiveDataClient from "../livedataclient";

//      data-se-lens="Modal"
//      data-se-node="FOLDER/NODE/PATH"

export default class Modal extends SVGWidget {
    static attach(nodeRoot, element) {
        return new this(nodeRoot, element);
    }

    constructor(nodeRoot, element) {
		super(nodeRoot,element);
		if(!element.dataset.seNode){
			console.log("Error: element",element,"lacks 'se-node'. Supply a folder.");	// FIXME: This shouldn't be se-node. Should be se-folder or something.
			return;
		}

		this.modal = this.getSVGModal();

		this.element.setAttribute("data-toggle", "modal");
		this.element.setAttribute("data-target", "#SVGWidgetModal");
		this.element.setAttribute("type", "button");
		this.element.classList.add("btn");
		this.element.classList.add("btn-primary");
		this.element.classList.add("custom-tab-hoverable");
		this.element.onclick = this.onElementClick.bind(this);

		let that = this;
        this.treeTimeout = setInterval(function(){
            if(that.treesComplete(that.devices)){
				that.folder = SVGWidget.readAndResolveNodePath(that.element, nodeRoot, "seNode");
                clearInterval(that.treeTimeout);
            }
		}, 250, that);

		this.setupDevices();
		//this.setupNodes();
	}

	onElementClick(){
		let options = {
			folder: this.folder,
			type: 'settings',
		}
		new ViewModal(new TagView(LiveDataClient, options), {
			maxWidth:'500px',
			titleBackgroundColor: owner.colors.hex('--color-primary'),
			titleTextColor: owner.colors.hex('--color-inverseOnSurface'),
		});
	}

	onModal(){
		this.nodeViewer = new TagView(LiveDataClient, this.modal.body, this.folder);
		//this.nodeViewer.expandBranch(this.nodeViewer.treeDiv, this.folder);
	}

	clearModal(){
		this.nodeViewer.destroy();
		delete this.nodeViewer;
		// $(this.modal.element).on('shown.bs.modal', this.createNodeViewer.unbind(this));
	}

	update(node){
		// this.element.textContent = node.getFormattedText(this.showUnits);
	}

	destroy(){
		// $(this.modal.element).off('shown.bs.modal');	// Unbind our click event
	}
};
