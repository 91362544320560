import View from "./view";
import './changeview.css';
import { createElement } from "../elements";
import CancelIcon from '../images/icons/cancel.svg';
import type SettingsManager from '../settingsmanager';
import { CreateWidget } from "../widgets/lib/createwidget";
import { Widget } from "../widgets/lib/widget";
import { Node } from "../node";
import { Checkbox } from "../widgets/input/checkbox/checkbox";

export default class ChangeView extends View {
    id: number;
    settingsManager: typeof SettingsManager;
    cartWidgets: Map<Widget, [HTMLElement, Node, any]> = new Map();
    persistCheck: Checkbox;
    cancelCallback: ()=>void;
    constructor(settingsManager: typeof SettingsManager) {
        super();
        this.settingsManager    = settingsManager;
    }

    initialize(parent: HTMLElement) {
        super.initialize(parent);
        if (!this.cancelCallback) {
            this.cancelCallback = () => {
                if (this.modal)
                    this.modal.destroy();
            }
        }
        this.wrapper    = createElement('div', 'change__wrapper', this.parent)
        let changesList = createElement('div', 'change__list', this.wrapper, '');
        let header = createElement('div', 'change__list__headers', changesList);
        createElement('div', '', header, 'Current Value');
        createElement('div', '', header, 'Proposed Value');

        for (let [node, pendingWrites] of this.settingsManager.pendingWrites) {
            for (let [widget, value] of pendingWrites) {
                let container = createElement('div', 'change__list__container', changesList);
                createElement('tag-path', 'change__list__container__path', container, '', {pathTag: {tag: node}});
                let oldWidgetContainer = createElement('fieldset', 'change__list__container__value', container);
                oldWidgetContainer.appendChild(CreateWidget(node));

                let newWidget = CreateWidget(node) as Widget;
                this.cartWidgets.set(newWidget, [createElement('fieldset', 'change__list__container__value', container), node, value]);
                let remove    = createElement('img', 'change__list__container__remove', container, '', {'src':CancelIcon});
                remove.onclick = () => {
                    this.settingsManager.popPending(node, widget);
                    this.settingsManager.popPending(node, newWidget);
                    this.cartWidgets.delete(newWidget);
                    container.remove();
                }
            }
        }
        let persistWrapper = createElement('div', 'change__list__persist', this.wrapper);
        this.persistCheck = createElement('se-checkbox', '', persistWrapper);
        this.persistCheck.checked = this.settingsManager.fPersistWriteMode;
        let text = createElement('div', '', persistWrapper, 'Enable Immediate Writes for 10 minutes')
        this.cartWidgets.forEach(([container, node, value], widget) => {
            this.settingsManager.pendingWrites.get(node)?.set(widget, value);
            container.appendChild(widget);
        });

        this.fInitialized = true;

        return this;
    }

    destroy() {
        this.cartWidgets.forEach(([container, node, value], widget) => {
            this.settingsManager.popPending(node, widget);
        });
        this.parent.removeChild(this.wrapper);
    }
}
