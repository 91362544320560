import './dashboardviewerpage.css';
import Page from "./page";
import DashboardView from '../views/dashboardview';
import Dialog from "../dialog";
import owner, { Routes } from "../../owner";
import { createElement } from "../elements";
import FrameParser from '../frameparser';
import { getHash } from '../router/router';
import LiveDataClient from '../livedataclient';

interface DashboardViewerPageProps {
    id?: number;
}

export default class DashboardViewerPage extends Page {
    view: DashboardView;
    props: DashboardViewerPageProps;
    resizeCallback: (e: Event) => void;
    constructor(parent: HTMLElement, props?: DashboardViewerPageProps) {
        super(parent);
        this.props      = props ?? {};
        if (this.props.id === undefined)
            window.location.href 	= getHash(Routes.Creator);
        let dashboardPageWrapper 	= createElement('div', 'dashboard-viewer__page__page-wrapper', this.parent);
        let dashboardSubNavbar	 	= createElement('div', 'dashboard-viewer__page__sub-nav', dashboardPageWrapper);
        let dashboardEditButton	 	= createElement('div', 'dashboard-viewer__page__sub-nav__edit-button', dashboardSubNavbar, 'Edit');
        dashboardEditButton.onclick = () => {
            window.location.href 	= getHash(Routes.Creator, {'id': this.props.id!.toString()});		// Navigate to the secret editor
        }
        let dashboardReportsButton	 = createElement('div', 'dashboard-viewer__page__sub-nav__edit-button', dashboardSubNavbar, 'Reports');
        dashboardReportsButton.onclick = () => {
            dashboardReportsButton.textContent = this.view.showReports() ? 'Dashboard' : 'Reports';
        }
        let dashboardWrapper 	 	= createElement('div', 'dashboard-viewer__page__wrapper', dashboardPageWrapper);
        this.view				 	= new DashboardView(LiveDataClient, this.props.id!, undefined, ()=> {
            if (this.view.dashboard && this.view.dashboard.name)
                owner.navBar.setTitle(this.view.dashboard.name);
        }).initialize(dashboardWrapper);
        this.resizeCallback = () => this.onResize();
        addEventListener('resize', this.resizeCallback);
    };

    setProps(props: DashboardViewerPageProps) {
        this.props = props;
        this.refresh();
    }

    onResize() {
        this.view.resize();
    }

    destroy() {
        removeEventListener('resize', this.resizeCallback);
        if (this.view)
            this.view.destroy();
        super.destroy();
    }
}
