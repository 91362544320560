import { type DigitalGauge } from "../status/digitalgauge/digitalgauge";
import { type InputSetpoint } from "../input/setpoint/inputsetpoint";
import { type ToggleSwitch } from "../input/toggle/toggleswitch";
import { type TagPushButton } from "../input/pushbutton/pushbutton";
import { Tag, VType } from "./tag";
import { Widget, getTagMetadata } from "./widget";

export const RoleMap: Map<string, string> = new Map();

export function CreateWidget(tag: Tag) {
    let element: Widget;
    let supportedRoles = tag.roles && Array.from(tag.roles).filter(role => RoleMap.has(role));
    if (supportedRoles && supportedRoles.length > 0) {
        let [firstRole] = supportedRoles;
        let tagName = RoleMap.get(firstRole);
        if (tagName)
            element = document.createElement(RoleMap.get(firstRole)!) as Widget;
    }
    else if (tag.name === 'StartFail' || tag.name === 'StopFail' || tag.name === 'FaultReset') {
        element = document.createElement('tag-push-button') as TagPushButton;
        if (tag.name === 'FaultReset')
            (<TagPushButton>element).pressedValue = 1;
        (<TagPushButton>element).buttonText = 'Reset';
    }
    else {
        switch (tag.vtype) {
            case VType.VT_BOOL:
                element = document.createElement('se-tag-toggle-switch') as ToggleSwitch;
                break;
            case VType.VT_UNKNOWN:
                return document.createElement('div');
            default:	// numeric:
                if (tag.isWriteable)
                    element = document.createElement('input-setpoint') as InputSetpoint;
                else
                    element = document.createElement('digital-gauge') as DigitalGauge;
        }
    }
    let metadata = element![getTagMetadata]();
    if (metadata.size > 0)
        element![metadata.values().next().value.privateKey] = { tag: tag };
    return element!;
}