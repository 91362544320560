import { createElement } from './elements';
import owner from '../owner';
import './dialog.css';
import TextInput from './components/textinput';
import assert from './debug';
import FrameParser from './frameparser';
import PassKeyIcon from './images/icons/passkeys-logo.svg'
import PasswordIcon from './images/icons/password.svg'
import PassKeys, { PasskeyLoginButton } from './passkeys';
import LiveDataClient from './livedataclient';
import User from './user';


interface DialogProperties {
    title: string;
    body?: string;
    titleBackground?: string;
    titleColor?: string;
    minWidth?: number;
    minHeight?: number;
    fButtons?: boolean;
    fSelect?: boolean;
    fText?: boolean;
    buttons?: any[];
    fDontDestroyOnButtonClick?: boolean;
    callback?: (value: string | HTMLOptionElement) => void;
    options?: HTMLOptionElement[];
    cancelCallback?: () => void;
    fImportant?: boolean;
}
export default class Dialog implements DialogProperties {
    parent: HTMLElement;
    properties: DialogProperties;
    buttons: any[];
    title: string;
    body: string;
    fText: boolean;
    fButtons: boolean;
    fImportant: boolean;
    buttonList: HTMLButtonElement[];
    container: HTMLDivElement;
    content: HTMLDivElement;
    titleDiv: HTMLDivElement;
    titleBackground: string;
    titleColor: string;
    bodyDiv: HTMLDivElement;
    fSelect: boolean;
    options: HTMLOptionElement[];
    selector: HTMLSelectElement;
    callbackEntry: any;
    callback: (value: string)=>void;
    cancelCallback: ()=>void;
    textField: TextInput;
    passwordField: TextInput;
    message: HTMLDivElement;
    graphID: number;
    buttonRow: HTMLDivElement;
    fInitialized: boolean;
    keyPressCallback: (e: KeyboardEvent) => void;
    fUseWriteEnabledModeSelector: boolean;
    fDontDestroyOnButtonClick: boolean;
    fDestroyed: boolean = false;

    constructor(parent = document.body, properties: DialogProperties) {
        this.parent = parent;
        this.properties = properties;

        // default properties
        this.buttons = [];       // no buttons by default
        this.title = '';       // blank title
        this.body = '';       // blank body text
        this.fText = false;    // not a text entry dialog by default
        this.fButtons = true;     // display a default button by default
        this.fImportant = false;    // not important by default

        this.copy(this.properties)  // copy over our properties parameter
        this.buttonList = [];       // list to keep track of our added buttons
        if (owner.dialog) {                                 // if we are already displaying a dialog
            if (this.fImportant) {                          // if we need to immediately display this dialog
                owner.pendingDialogs.unshift(owner.dialog); // add the current dialog to the beginning of our list of pending dialogs
                owner.pendingDialogs.unshift(this);         // add our new important dialog to the beginning of our list of pending dialogs
                owner.dialog.destroy();                     // destroy the current dialog, it will be reconstructed when its time has come
            }
            else
                owner.pendingDialogs.push(this);            // if our new dialog isn't important just add it to the queue of pending dialogs
        }
        else {
            this.initialize();                              // if we aren't currently displaying a dialog, go ahead and intitalize
        }
    }

    initialize() {
        this.container = createElement('div', 'dialog__container', this.parent);
        var blur = createElement('div', 'dialog__screen-blur', this.container);
        this.content = createElement('div', 'dialog__content', this.container);
        this.titleDiv = createElement('div', 'dialog__title', this.content, this.title);
        if (this.titleBackground)
            this.titleDiv.style.backgroundColor = this.titleBackground;
        if (this.titleColor)
            this.titleDiv.style.color = this.titleColor;
        this.bodyDiv = createElement('div', 'dialog__body', this.content, this.body);
        if (this.fSelect) {
            assert(this.options, 'Select dialogs must be given an array of options')
            this.selector = createElement('select', 'dialog__select', this.content);
            for (let i = 0; i < this.options.length; i++) {
                this.selector.appendChild(this.options[i]);
            }
            this.callbackEntry = this.selector.options[this.selector.selectedIndex];
            this.selector.onchange = () => this.callbackEntry = this.selector.options[this.selector.selectedIndex];
            if (this.buttons.length == 0) { // have to have a button for a select entry dialog
                this.buttons = [
                    { 'title': 'Submit', callback: this.callback !== undefined ? () => this.callback(this.callbackEntry) : () => console.log('no callback method specified') },
                    { 'title': 'Cancel', callback: () => { if (this.cancelCallback) { this.cancelCallback(); } this.destroy(); } }
                ];
            }
        }

        if (this.fText) {
            this.textField = new TextInput(this.content, 'Name', 'var(--color-onSurface)', {
                'autocomplete': 'off',
                'autocapitalize': 'off',
                'autocorrect': 'off',
                'spellcheck': 'false',
                'type': 'text',
                'background': 'true'
            });
            this.callbackEntry = '';
            this.textField.oninput = () => this.callbackEntry = this.textField.value;

            if (this.buttons.length == 0) { // have to have a button for a text entry dialog
                this.buttons = [
                    { 'title': 'Submit', callback: this.callback !== undefined ? () => this.callback(this.callbackEntry) : () => console.log('no callback method specified') },
                    { 'title': 'Cancel', callback: () => { if (this.cancelCallback) { this.cancelCallback(); } this.destroy(); } }
                ];
            }
        }

        if (this.buttons.length == 0 && this.fButtons) // if we are not provided a button, give the user the option to exit the dialog
            this.buttons = [{ 'title': 'Ok', callback: this.callback !== undefined ? () => { this.callback(this.callbackEntry); } : undefined }]

        this.buttonRow = createElement('div', 'dailog__button-row', this.content)

        for (let i = 0; i < this.buttons.length; i++) {
            let button = this.buttons[i];
            let newButton = createElement('button', button.class != undefined ? button.class : 'se-button', this.buttonRow, button.title ? button.title : 'Default');
            if (button.color)
                newButton.style.border = '2px solid ' + button.color;
            let callback = button.callback;
            newButton.onclick = () => {
                if (callback) {
                    if (this.fText) {
                        callback(this.callbackEntry);
                        this.destroy();
                    }
                    else {
                        callback(this.callbackEntry);
                        if (!this.fDontDestroyOnButtonClick)
                            this.destroy();
                    }
                }
                else
                    this.destroy();
            }
            this.buttonList.push(newButton)
        }
        this.fInitialized = true;
        owner.dialog = this;
        this.keyPressCallback = this.clickDefault.bind(this); // We have to create a reference to clickDefault.bind(this) because removing event listeners with an added bind doesn't seem to work
        window.addEventListener("keypress", this.keyPressCallback);
        if (this.fText)
            this.textField.focus();
    }

    clickDefault(e: KeyboardEvent) {
        if (e.key == "Enter") {
            e.preventDefault();
            if (this.buttonList[0])
                this.buttonList[0].click();
        }
    }

    copy(properties) {	// Copy set of properties over to this widget object
        for (var attr in properties) {
            if (properties.hasOwnProperty(attr) && typeof properties[attr] !== 'undefined')
                this[attr] = properties[attr];
        }
    }

    setTitle(value) {
        this.title = value;
        if (this.fInitialized)
            this.titleDiv.textContent = this.title;
    }

    destroy() {
        if (!this.fDestroyed) {
            this.container.removeChildren();
            this.parent.removeChild(this.container);
            owner.dialog = undefined;
            if (this.graphID)
                LiveDataClient.unregisterGraph(this.graphID);
            window.removeEventListener("keypress", this.keyPressCallback);
            if (owner.pendingDialogs.length > 0) {
                owner.pendingDialogs[0].initialize()
                owner.pendingDialogs.shift();
            }
            this.fDestroyed = true;
        }
    }
}

/*
    WritesEnabler allows you to wrap any actions that result in a protocol message being sent that requires writes to be enabled to succeed.
    You don't need to worry about checking if the user already has writes enabled.

    example:

    new WritesEnabler(()=>{
        LiveDataClient.updateUserInfo(...);
    });
*/
export class WritesEnabler { // Object for use in verifyUser
    static pathStorageName: string = "writes_enabled";
    verifiedCallback: any;
    optionalCancelCallback: any | undefined;
    graphID: number;
    dialog: Dialog;
    passwordField: TextInput;
    statusMessage: HTMLDivElement;
    attemptedToken: string;
    passkeyButton: PasskeyLoginButton;

    options: Map<string, number> = new Map<string, number>([
        ["2 Minutes", 2 * 60],
        ["10 Minutes", 10 * 60],
        ["30 Minutes", 30 * 60],
        ["1 Hour", 60 * 60],
        ["2 Hours", 60 * 60 * 2]
    ]);

    splash: HTMLDivElement;
    optionElements?: HTMLOptionElement[];
    optionalTime: number | null = null;

    constructor(verifiedCallback, optionalCancelCallback?, optionalTime?: number /* Stuff we want to do when the user is successfully verified */) {
        if (User.writesEnabled()) // We're already done!
        {
            verifiedCallback();
            return;
        }

        this.verifiedCallback = verifiedCallback;
        this.optionalCancelCallback = optionalCancelCallback;
        this.graphID = LiveDataClient.registerGraph(this);
        this.optionalTime = optionalTime ?? null;
        this._verifyUserByToken(); // Attempt token verification first - falls back to password verification
    }

    private _verifyUserByToken() {
        User.pathStorage.getItem(WritesEnabler.pathStorageName).then((token) => {
            if (token == undefined) // We don't have a write-enabled token to persist our write-enabled state across sockets
                this._showPasswordReAuthenticationDialog();
            else // We found a token
            {
                this.attemptedToken = token;
                User.verifyUserByToken(token).then(success => {
                    this.splash.classList.add('hide'); // Hide our splashy loader thing
                    if (success) {
                        User.verifiedUntil = JSON.parse(window.atob(this.attemptedToken.split('.')[1]))["exp"]; // Grab the expiration time from the token we used
                        this.verifiedCallback();
                        this._destroy();
                    }
                    else
                        User.pathStorage.deleteItem(WritesEnabler.pathStorageName).then(() => { this._showPasswordReAuthenticationDialog(); }); // Delete the token that didn't work and prompt for re-auth
                }); // Send it
                this.splash = createElement('div', 'page__loader', document.body); // Show some indication we're waiting on a response
            }
        });
    }

    private _verifyUserByPassword(time: number) {
        if (this.passwordField.value != "") {
            assert(this.passwordField != undefined, "Attempted to verifyUserByPassword and password field has not been constructed");
            let tokenLifespan = time; // Token lifespan in seconds
            User.verify(this.passwordField.value, tokenLifespan!).then(({success, token}) => {
                if (success) // Password was correct
                {
                    User.pathStorage.setItem(WritesEnabler.pathStorageName, token).then(() => { // Save the token so we don't need a password next time
                        User.verifiedUntil = JSON.parse(window.atob(token.split('.')[1]))["exp"];
                        this._done();
                    });
                }
                else
                    this.statusMessage.textContent = 'Invalid password';
            });
        }
        else
            this.statusMessage.textContent = 'Password required';
    }

    private _done() {
        this.dialog.destroy(); // Make the dialog box go away
        this.verifiedCallback(); // Do whatever they needed writes for
        this._destroy();
    }

    private _showPasswordReAuthenticationDialog() {
        assert(!User.writesEnabled());

        // The reauth Dialog wants options as elements...
        if (this.optionalTime === null) {
            this.optionElements = [];
            this.options.forEach((_: number, key: string) => {
                this.optionElements!.push(createElement('option', 'UserSiteRow', undefined, key));
            })
        }

        this.dialog = new Dialog(document.body, {
            title: 'Re-authentication required',
            body: 'By default you will have 2 minutes to make additional changes before having to re-authenticate again.',
            titleBackground: 'var(--color-primary)',
            titleColor: 'var(--color-inverseOnSurface)',
            fButtons: false,
            fSelect: this.optionalTime === null,
            fDontDestroyOnButtonClick: true,
            callback: this._verifyUserByPassword.bind(this),
            options: this.optionElements,
            cancelCallback: this.optionalCancelCallback,
            fImportant: true,
        });
        this.dialog.content.removeChild(this.dialog.buttonRow); // We don't need this

        this.dialog.bodyDiv.classList.add("dialog__body-small-margin");
        this.statusMessage = createElement('div', 'dialog__message', this.dialog.content, '');
        this.passwordField = new TextInput(this.dialog.content, 'Password', 'var(--color-onSurface)', {
            'autocomplete': 'off',
            'autocapitalize': 'off',
            'autocorrect': 'off',
            'spellcheck': 'false',
            'type': 'password',
            'background': 'true',
        });
        this.passwordField.container.classList.add("dialog__password-field-large-margin");

        let passwordButtonContainer = createElement('div', 'dialog__passkey-container', this.dialog.content);
        let passwordButton = createElement('div', 'se-button login__button login__passkey-button', passwordButtonContainer, undefined, {
            onclick: () => {
                if (this.optionalTime === null)
                    assert(this.dialog.selector.options[this.dialog.selector.selectedIndex] != undefined, `Could not interpret token lifetime`);
                this._verifyUserByPassword(this.optionalTime ??  this.options.get(this.dialog.selector.options[this.dialog.selector.selectedIndex]!.value)!);
            }
        });
        createElement('img', 'login__button__icon', passwordButton, undefined, { 'src': PasswordIcon });
        createElement('div', 'login__passkey-button-text', passwordButton, 'Verify with a password');

        let passkeyButtonContainer = createElement('div', 'dialog__passkey-container', this.dialog.content);
        new OrDivider(passkeyButtonContainer);

        this.passkeyButton = new PasskeyLoginButton(passkeyButtonContainer, User.passKeys, false, this.optionalTime ?? this.options.get(this.dialog.selector.options[this.dialog.selector.selectedIndex].value) as number, (success: boolean) => {
            if (success)
                this._done();
            else
                this.statusMessage.textContent = 'Failed to verify with passkey.';
        }, "Verify with a passkey");
        this.passkeyButton.activateConditionalUI();

        new OrDivider(passkeyButtonContainer);

        createElement('div', 'se-button login__button login__passkey-button dialog__cancel', passkeyButtonContainer, "Cancel", {
            onclick: () => {
                if (this.optionalCancelCallback) { this.optionalCancelCallback(); }
                this.dialog.destroy();
                this.verifiedCallback = () => { }; // Ensure that we don't perform any actions
            }
        });
        if (this.optionalTime === null)
            this.dialog.selector.onchange = () => { this.passkeyButton.writeModeLifespan = this.options.get(this.dialog.selector.options[this.dialog.selector.selectedIndex].value) as number }; // Refresh the verified action to reflect the selector change
    }

    private _destroy() {
        if (this.graphID)
            LiveDataClient.unregisterGraph(this.graphID); // Remove LiveDataClient's reference to us
    }
}

class OrDivider {
    constructor(parent: HTMLElement) {
        var orDivider = createElement('section', 'dialog__divider-container', parent);
        createElement('div', 'login__divider-line', orDivider);
        createElement('div', 'login__divider-text', orDivider, "or");
        createElement('div', 'login__divider-line', orDivider);
    }
}
