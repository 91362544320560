import { Attribute } from '../../../lib/attributes';
import { TagAttribute } from '../../../lib/tag';
import { RegisterWidget, Widget } from '../../../lib/widget';
import type { TagDefinition } from "../../../lib/tag";
import template from './pumpbadge.html';
import { ColorInput } from '../../../../views/attributeeditorview';

@RegisterWidget({tag: 'pump-badge', displayName: 'Pump Badge', section: 'Pumps', template: template})
export class PumpBadge extends Widget {
    @Attribute({
        displayName: 'Background Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => {
            return new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip);
        }}
    ) backgroundColor: string = '#2C2D79';
    @Attribute({
        displayName: 'Text Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => {
            return new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip);
        }}
    ) textColor: string = '#ffffff';
    @TagAttribute({displayName: 'Tag'}) pumpTag: TagDefinition;
}