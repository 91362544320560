import Icon from '../../../images/icons/card.svg'
import { Attribute } from '../../lib/attributes';
import { RegisterWidget, Widget } from '../../lib/widget';
import template from './tagcard.html';
import { type DigitalGauge } from '../../status/digitalgauge/digitalgauge';
import { type TagUnits } from '../tagunits/tagunits';
import { type TagName } from '../tagname/tagname';
import { CreateWidget } from '../../lib/createwidget';
import { TagAttribute, type TagDefinition } from '../../lib/tag';
import { ColorInput } from '../../../views/attributeeditorview';

@RegisterWidget({tag: 'tag-card', displayName: 'Tag Card', template: template, icon: Icon, editor: true, section: 'Tag Information'})
export class TagCard extends Widget {
    // Elements to enliven
    protected nameWidget: TagName
    protected unitsElement: TagUnits
    protected valueElement: DigitalGauge
    protected topRow: HTMLElement;
    protected bottomRow: HTMLElement;

    // Attributes to let the end user fiddle
    @Attribute({
        displayName: 'Background Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => {
            return new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip);
        }}
    ) backgroundColor: string = '#2196F3';
    @Attribute({
        displayName: 'Background Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => {
            return new ColorInput(name, parent, property, getValue, onSettingChangedCallback, tooltip);
        }}
    ) textColor: string = '#ffffff';
    @Attribute({displayName: 'Pulse Animation'}) isAnimated : boolean = false;
    @Attribute({displayName: 'Show Units'}) showUnits: boolean = false;
    @Attribute({displayName: 'Override Name'}) overrideName: string;

    @TagAttribute({displayName: 'Tag'}) statusTag: TagDefinition;

    protected connectedCallback(): void {
        this.style.setProperty('--background-color', this.backgroundColor);
        this.style.setProperty('--text-color', this.textColor);
        if (this.isAnimated)
            this.style.setProperty('--animated', 'color');
        this.topRow = this.shadowRoot?.getElementById('top') as HTMLElement;
        this.bottomRow = this.shadowRoot?.getElementById('bottom') as HTMLElement;
    }

    protected enliven(): void {
        let name: HTMLElement = document.createElement('div');
        name.textContent = this.overrideName;
        if (typeof this.overrideName === 'undefined') {
            let nameWidget = document.createElement('tag-name') as TagName;
            nameWidget.nameTag = this.statusTag
            nameWidget.classList.add('name');
            nameWidget.formatName = true;
            name = nameWidget;
        }
        this.topRow.appendChild(name);
        if (this.showUnits) {
            let unitsWidget = document.createElement('tag-units') as TagUnits;
            unitsWidget.unitsTag = this.statusTag;
            this.topRow.appendChild(unitsWidget);
        }

        let widgetElement = document.createElement('div');
        widgetElement.classList.add('value');
        widgetElement.appendChild(CreateWidget(this.statusTag.tag));
        this.bottomRow.appendChild(widgetElement);
    }
}