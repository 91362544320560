import { BoxPlotChart } from "@sgratzl/chartjs-chart-boxplot";
import Cruncher, { CalculateInterval, HistoricalData } from "../../../cruncher";
import { ColorInput, SelectInput } from "../../../views/attributeeditorview";
import { Attribute } from "../../lib/attributes";
import { TagAttribute, type TagDefinition } from "../../lib/tag";
import { RegisterWidget, Widget } from "../../lib/widget";
import template from './boxplot.html';
import { UnitsMap } from "../../lib/tagunits";
import Icon from '../../../images/icons/box-plot.svg'

@RegisterWidget({tag:'box-plot', displayName: 'Box Plot', section: 'Charts', icon: Icon, template: template, editor: true})
export class BoxPlot extends Widget {
    private canvas: HTMLCanvasElement;
    private cruncher: Cruncher = new Cruncher();
    private start: Date;
    private end: Date;
    @Attribute({
        displayName: 'Period',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new SelectInput(name, parent, property, getValue, ['Hour','Day','Week','Year'], ['3600','86400','604800','31540000'], onSettingChangedCallback)
    }) period: number = 86400;

    @Attribute({displayName: 'Periods'}) periods: number = 7;

    @TagAttribute({
        displayName: 'Data Tag',
        requiredProperties: ['logged'],
        supportedTypes: ['numeric']
    }) dataTag: TagDefinition;
    @Attribute({
        displayName: 'Background Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback)
    }) backgroundColor: string = '#000000';
    @Attribute({
        displayName: 'Border Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback)
    }) borderColor: string = '#000000';
    @Attribute({
        displayName: 'Outlier Color',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new ColorInput(name, parent, property, getValue, onSettingChangedCallback)
    }) outlierColor: string = '#000000';

    protected connectedCallback(): void {
        this.canvas = this.shadowRoot?.getElementById('canvas') as HTMLCanvasElement;
    }

    protected enliven(): void {
        let period      = this.period;
        let interval    = CalculateInterval(period * this.periods, 10000)
        this.end        = new Date(Math.floor((new Date().getTime() + new Date().getTimezoneOffset()) / 1000 / period) * period * 1000);
        this.start      = new Date((this.end.getTime() / 1000 - this.periods * period) * 1000);
        this.cruncher.requestData(this.start, this.end, [this.dataTag.tag], interval).then(([tags, data]) => this.createBoxPlot(data))
    }

    createBoxPlot(data: HistoricalData) {
        let ctx = this.canvas.getContext('2d')!;
        let tag = this.dataTag.tag;
        const boxplotData = {
            // define label tree
            labels: [''],
            datasets: [{
                label: tag.getDisplayName(),
                backgroundColor: this.backgroundColor,
                borderColor: this.borderColor,
                borderWidth: 2,
                outlierColor: this.outlierColor,
                padding: 0,
                itemRadius: 0,
                data: [data[3]]
            }]
        };

        new BoxPlotChart(ctx, {
            data: boxplotData,
            options: {
                maintainAspectRatio: false,
                animation: {
                    duration: 0
                },
                scales: {
                    y: {
                        title: {
                            display: true,
                            text: UnitsMap.get(tag.units)?.abbrev
                        }
                    }
                }
            }
        });
    }
}