import { Attribute, SettingType } from "../../lib/attributes";
import { RegisterWidget, Widget } from "../../lib/widget";
import template from './link.html'
import LinkIcon from '../../../images/icons/link.svg'
import LiveDataClient from "../../../livedataclient";
import { LinkInput, SettingInput } from "../../../views/attributeeditorview";
import FrameMaker from "../../../framemaker";
import LiveData from "../../../livedata";
import FrameParser from "../../../frameparser";
import User from "../../../user";


async function getInput(name: string, parent: HTMLElement, property: string, getValue: (attrName: string) => [string, SettingType], onSettingChangedCallback: (key: string, value: string) => void, tooltip?: string): Promise<SettingInput> {
    let fm = new FrameMaker();
    fm.buildFrame(LiveData.WVC_GET_DASHBOARDS);
    fm.push_string(User.companyKey);
    let fp = await LiveDataClient.sendRequest(fm);
    let dashboards = parseDashboards(fp);
    let devices: [string, string][] = User.devices.sorted.map(device => [device.key, device.siteName]);
    return new LinkInput(name, parent, property, getValue, onSettingChangedCallback, devices, dashboards);
}

function parseDashboards(fp: FrameParser): [string, string][] {
    let count = fp.pop_u32();
    let idAndName: [string, string][] = [];
    for (let i=0;i<count;i++) {
        let id          = fp.pop_u32();
        let name        = fp.pop_string();
        fp.pop_u16();
        fp.pop_string();
        let deviceCount = fp.pop_u32();
        for (let j=0;j<deviceCount;++j) {
            fp.pop_string();
        }
        idAndName.push([id.toString(), name]);
    }
    return idAndName;
}

@RegisterWidget({tag: 'link-widget', displayName: 'Link', template: template, icon: LinkIcon, editor: true, section: 'Basic'})
export class LinkWidget extends Widget {
    wrapper: HTMLElement;
    @Attribute({displayName: 'Link Text'}) textContent: string = 'Navigate';
    @Attribute({
        displayName: 'Link',
        getInput: getInput
    }) hash: string = '';
    protected connectedCallback(): void {
        this.role = "button"
        this.wrapper = this.shadowRoot?.getElementById('wrapper')!;
    }

    protected enliven(): void {
        this.wrapper!.textContent = this.textContent;
        this.onclick = () => {
            location.hash = this.hash;
        };
    }
}