import { Attribute } from "../../lib/attributes";
import { RegisterWidget, Widget } from "../../lib/widget";
import template from './pie.html';
import { RangeInput, SelectInput } from "../../../views/attributeeditorview";
import { CalculateInterval, HistoricalData } from "../../../cruncher";
import { Chart, ChartData } from "chart.js";
import { TagAttribute, type TagDefinition } from "../../lib/tag";
import Cruncher from '../../../cruncher';
import Icon from '../../../images/icons/pie_chart.svg';

const DefaultRangeSettings = {
    minimum: 0,
    ranges: [{
        name: 'Default',
        upperLimit: 100,
        value: '#000000'
    }]
};

@RegisterWidget({ tag: 'pie-chart', displayName: 'Pie Chart', section: 'Charts', icon: Icon, template: template, editor: true })
export class PieChart extends Widget {
    canvas: HTMLCanvasElement;
    tickInterval: ReturnType<typeof setInterval>;
    cruncher: Cruncher = new Cruncher();
    start: Date;
    end: Date;
    @Attribute({
        displayName: 'Ranges',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new RangeInput(name, parent, property, getValue, onSettingChangedCallback)
    }) rangeSettings: { minimum: number, ranges: { name: string, upperLimit: number, value: string }[] } = DefaultRangeSettings;

    @Attribute({
        displayName: 'Period',
        getInput: (name, parent, property, getValue, onSettingChangedCallback, tooltip) => new SelectInput(name, parent, property, getValue, ['Hour', 'Day', 'Week', 'Year'], ['3600', '86400', '604800', '31540000'], onSettingChangedCallback)
    }) period: number = 86400;

    @TagAttribute({
        displayName: 'Data Tag',
        requiredProperties: ['logged'],
        supportedTypes: ['numeric']
    }) dataTag: TagDefinition;

    protected connectedCallback(): void {
        this.canvas = this.shadowRoot?.getElementById('canvas') as HTMLCanvasElement;
    }

    protected enliven(): void {
        let period = this.period;
        let interval = CalculateInterval(period, 10000)
        this.end = new Date(Math.floor((new Date().getTime() + new Date().getTimezoneOffset()) / 1000 / period) * period * 1000);
        this.start = new Date((this.end.getTime() / 1000 - period) * 1000);
        this.cruncher.getFormattedData(this.start, this.end, [this.dataTag.tag], interval).then(data => this.createPieChart(data, interval))
        //this.cruncher.requestData(this.start, this.end, [this.dataTag.tag], interval).then(([tags, data]) => this.createPieChart(data, interval))
    }

    createPieChart(data: HistoricalData, interval: number) {
        let ranges = this.rangeSettings.ranges;
        let cumulative = new Array(ranges.length + 1).fill(0);
        for (let i = 0; i < data[3].length; ++i) {
            if (data[3][i] === null) {
                cumulative[cumulative.length - 1] += interval / 60
                continue;
            }
            let found = false;
            for (let j = 0; j < ranges.length; ++j) {
                if (data[3][i] <= ranges[j].upperLimit) {
                    cumulative[j] += interval / 60;
                    found = true
                    break;
                }
            }
            if (!found)
                cumulative[cumulative.length - 1] += interval / 60
        }
        let ctx = this.canvas.getContext('2d')!;
        let dataSet: ChartData = {
            labels: [],
            datasets: [{
                label: '',
                data: cumulative,
                backgroundColor: [],
                borderWidth: 0,
            }]
        }
        for (let i = 0; i < ranges.length; ++i) {
            dataSet.labels?.push(ranges[i].name);
            //@ts-ignore
            dataSet.datasets[0]!.backgroundColor.push(ranges[i].value)
        }
        //@ts-ignore
        dataSet.datasets[0]!.backgroundColor.push('#ffffff');


        new Chart(ctx, {
            type: 'pie',
            data: dataSet,
            options: {
                animation: {
                    duration: 0
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (tooltipItem) {
                                const label = tooltipItem.label || '';
                                const value = Math.round(tooltipItem.raw as number);
                                return `${label}: ${value} minutes`;
                            }
                        }
                    },
                    legend: {
                        position: 'top',
                    }
                }
            }
        })
    }
}